import {
  CLAIM_STATUS_ALERTED,
  CLAIM_STATUS_ALL,
  CLAIM_STATUS_PENDING,
  CLAIM_STATUS_TEXT_MAPPER,
  CLAIM_STATUS_VALIDATED,
  getClaimExportStatusOptions,
} from "@constants/claims/claim-status";
import { ORGANIZATION_ROLE_SUBMITTER } from "@constants/organizations/organizations";
import {
  searchQueryDescribeFilter,
  TIME_DELETED_ISNULL_FILTER,
  TIME_UPDATED_FILTER,
  timeDeletedIsNullApplyFilter,
  timeDeletedIsNullDescribeFilter,
  timeUpdatedApplyFilter,
  timeUpdatedDescribeFilter,
} from "@constants/static/export";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { parseDate, parseIsoDatetime } from "@helpers/parse/time";
import pluralize from "pluralize";

export const initialFilterState = {
  visit_date: [null, null],
  time_updated: [null, null],
  amount_requested: ["", ""],
  time_deleted__isnull: [],
  status__in: [],
};

export const getFilterOptions = ({
  claimMode,
  organizationRole,
  statusTab,
  currency,
}) => {
  const statuses = getClaimExportStatusOptions({
    claimMode,
    organizationRole,
  });
  return {
    visit_date: { title: "Visit date", type: "date-range" },
    amount_requested: { title: "Billed amount", type: "range", unit: currency },
    time_updated: TIME_UPDATED_FILTER,
    time_deleted__isnull: TIME_DELETED_ISNULL_FILTER,
    status__in: {
      title: "Adjudicated status",
      type: "options",
      options: statuses.map((status) => ({
        key: status,
        value: CLAIM_STATUS_TEXT_MAPPER[status],
      })),
      useCustomGrid: false,
      disabled: statusTab !== CLAIM_STATUS_ALL,
    },
  };
};

export const formatFilters = (filters = {}) => {
  let formattedFilters = {};
  const {
    visit_date = [null, null],
    amount_requested = ["", ""],
    time_updated = [null, null],
    time_deleted__isnull = [],
    status__in = [],
  } = filters;

  if (visit_date[0])
    formattedFilters["visit_date__gte"] = parseIsoDatetime(visit_date[0]);

  if (visit_date[1])
    formattedFilters["visit_date__lt"] = parseIsoDatetime(visit_date[1]);

  if (amount_requested[0])
    formattedFilters["amount_requested__gte"] = amount_requested[0];

  if (amount_requested[1])
    formattedFilters["amount_requested__lt"] = amount_requested[1];

  if (status__in.length > 0)
    formattedFilters["status__in"] = status__in.join(",");

  formattedFilters = timeUpdatedApplyFilter({
    formattedFilters,
    time_updated,
  });

  formattedFilters = timeDeletedIsNullApplyFilter({
    formattedFilters,
    time_deleted__isnull,
  });

  return formattedFilters;
};

export const describeFilters = ({
  searchQuery = "",
  filters = {},
  currency,
}) => {
  let formattedOutput = [];
  const {
    visit_date__gte = "",
    visit_date__lt = "",
    amount_requested__gte = "",
    amount_requested__lt = "",
    time_updated__gte = "",
    time_updated__lt = "",
    time_deleted__isnull = "",
    status__in = "",
    claim_submission__id__in = "",
  } = filters;

  if (claim_submission__id__in) {
    const label = "Submissions";
    const count = claim_submission__id__in.split(",").length;
    const value =
      count === 1
        ? "Within 1 claim submission"
        : `Across ${pluralize("claim submission", count, true)}`;
    formattedOutput.push({ label, value });
  }

  formattedOutput = searchQueryDescribeFilter({ formattedOutput, searchQuery });

  if (visit_date__gte || visit_date__lt) {
    const label = "Visit date";
    const from = parseDate(visit_date__gte);
    const to = parseDate(visit_date__lt);

    if (from && to) formattedOutput.push({ label, from, to });
    else if (from) formattedOutput.push({ label, from });
    else if (to) formattedOutput.push({ label, to });
  }

  if (amount_requested__gte || amount_requested__lt) {
    const label = "Billed amount";
    const from = getIntOrFloat(amount_requested__gte);
    const to = getIntOrFloat(amount_requested__lt);
    const unit = currency;

    if (from && to) formattedOutput.push({ label, from, to, unit });
    else if (from) formattedOutput.push({ label, from, unit });
    else if (to) formattedOutput.push({ label, to, unit });
  }

  if (status__in) {
    const values = status__in
      ?.split(",")
      .map((status) => CLAIM_STATUS_TEXT_MAPPER[status]);
    formattedOutput.push({ label: "Adjudicated status", values });
  }

  formattedOutput = timeUpdatedDescribeFilter({
    formattedOutput,
    time_updated__gte,
    time_updated__lt,
  });
  formattedOutput = timeDeletedIsNullDescribeFilter({
    formattedOutput,
    time_deleted__isnull,
  });

  return formattedOutput;
};

export const getUseQueryFnPropsClaims = ({
  searchQuery = "",
  sortQuery = "",
  organizationRole,
  organization__slug,
  claim_submission__id = undefined,
  claim_submissions = [],
  statusTab = CLAIM_STATUS_ALL,
  filters = {},
}) => {
  const { status__in, ...otherFilters } = filters || {};
  const useQueryFnProps = {
    searchQuery,
    sortQuery,
    filters: {
      organization__slug,
      newer_version__isnull: true,
      ...otherFilters,
    },
  };
  const statuses = [];

  if (claim_submission__id)
    useQueryFnProps.filters.claim_submission__id = claim_submission__id;
  else if (claim_submissions.length > 0)
    useQueryFnProps.filters.claim_submission__id__in = claim_submissions
      .map(({ id }) => id)
      .join(",");

  if (statusTab !== CLAIM_STATUS_ALL) {
    if (
      organizationRole === ORGANIZATION_ROLE_SUBMITTER &&
      statusTab === CLAIM_STATUS_PENDING
    ) {
      [
        CLAIM_STATUS_PENDING,
        CLAIM_STATUS_ALERTED,
        CLAIM_STATUS_VALIDATED,
      ].forEach((status) => statuses.push(status));
    } else {
      statuses.push(statusTab);
    }
  } else if (status__in) statuses.push(...status__in.split(","));

  if (statuses.length > 0)
    useQueryFnProps.filters.status__in = Array.from(new Set(statuses)).join(
      ","
    );

  return useQueryFnProps;
};
