import React from "react";
import CustomButton from "@components/Custom/CustomButton";
import CustomCircularProgress from "@components/Custom/CustomCircularProgress";
import { CustomTypographyLink } from "@components/Custom/CustomLink";
import LoginFormData from "@components/Unauthenticated/LoginForm/LoginFormData";
import { FORGOT_PASSWORD_HREF } from "@constants/static/routing";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ReactQueryClient } from "@pages/index";
import { login } from "@redux/actions/users/auth";
import { useDispatch } from "react-redux";

const initialFormData = {
  email: "",
  password: "",
};

const initialError = "";

export default function LoginForm() {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(initialFormData);
  const [error, setError] = React.useState(initialError);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setError(initialError);
    dispatch(login(formData))
      .then(() => ReactQueryClient.clear())
      .catch(({ payload }) => setError(payload))
      .finally(() => setIsProcessing(false));
  };

  return (
    <>
      <CustomCircularProgress open={isProcessing} invisible={false} />
      <Stack
        spacing={3}
        component="form"
        textAlign="center"
        onSubmit={handleSubmit}
      >
        <Typography variant="h5" fontWeight="bold">
          {"Log in"}
        </Typography>
        <LoginFormData
          error={error}
          formData={formData}
          setFormData={setFormData}
        />
        <Stack spacing={2}>
          <CustomButton
            fullWidth
            disabled={isProcessing}
            type="submit"
            variant="contained"
            color="primary"
            label="Log in"
          />
          <CustomTypographyLink href={FORGOT_PASSWORD_HREF}>
            {"Forgot password?"}
          </CustomTypographyLink>
        </Stack>
      </Stack>
    </>
  );
}
