import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import Typography from "@mui/material/Typography";

export default function OrganizationBundleRelatedObjectData({
  label = "",
  frequency,
  title,
  description,
}) {
  return (
    <CustomGrid>
      <CustomLabeledText label={label} value={`${frequency} x ${title}`} />
      <CustomLabeledText
        label="Description"
        useChildren={true}
        useCustomTooltip={true}
        title={description}
      >
        <Typography variant="body2" className="ellipsis-1">
          {description || "-"}
        </Typography>
      </CustomLabeledText>
    </CustomGrid>
  );
}
