import React from "react";
import CustomNotProvidedText from "@components/Custom/CustomNotProvidedText";
import CustomSection, {
  customSectionBodyPadding,
} from "@components/Custom/CustomSection";
import { CLAIM_SECTION_ATTACHMENTS_TITLE } from "@constants/claims/claims";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useClaimContext } from "../Claim/context";
import ClaimAttachment from "./ClaimAttachment";
import ClaimAttachmentCreateForm from "./ClaimAttachmentCreateForm";
import ClaimAttachmentUpdateForm from "./ClaimAttachmentUpdateForm";

export default function ClaimAttachments() {
  const {
    isCreateOrUpdateMode = false,
    claim = {},
    setFormData = () => {},
  } = useClaimContext();
  const { claim_attachments = [] } = claim;
  const numAttachments = claim_attachments.length;
  const subtitle = numAttachments > 0 && numAttachments;
  const hasAttachments = numAttachments > 0;
  const [state, setState] = React.useState({
    isOpen: false,
    object: null,
    index: 0,
  });

  const handleSelectClaimAttachment = React.useCallback(
    ({ object, index }) => setState({ isOpen: true, object, index }),
    []
  );

  const handleAddClaimAttachment = React.useCallback(
    ({ payload }) => {
      setFormData((prev) => ({
        ...prev,
        claim_attachments: [...prev.claim_attachments, payload],
      }));
    },
    [setFormData]
  );

  const handleUpdateClaimAttachment = React.useCallback(
    ({ payload, index }) => {
      setFormData((prev) => ({
        ...prev,
        claim_attachments: prev.claim_attachments.map((object, position) =>
          index === position ? payload : object
        ),
      }));
    },
    [setFormData]
  );

  const handleRemoveClaimAttachment = React.useCallback(
    ({ index }) => {
      setFormData((prev) => ({
        ...prev,
        claim_attachments: prev.claim_attachments.filter(
          (_, position) => index !== position
        ),
      }));
    },
    [setFormData]
  );

  return (
    <>
      <CustomSection
        title={CLAIM_SECTION_ATTACHMENTS_TITLE}
        subtitle={subtitle}
        bodyProps={{ p: 0 }}
        isCollapsable={!isCreateOrUpdateMode}
      >
        {claim_attachments.map((object, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <ClaimAttachment
              index={index}
              object={object}
              isCreateOrUpdateMode={isCreateOrUpdateMode}
              handleSelectClaimAttachment={handleSelectClaimAttachment}
              handleRemoveClaimAttachment={handleRemoveClaimAttachment}
            />
          </div>
        ))}
        {!hasAttachments && (
          <Box p={customSectionBodyPadding}>
            <CustomNotProvidedText title={CLAIM_SECTION_ATTACHMENTS_TITLE} />
          </Box>
        )}
        {isCreateOrUpdateMode && (
          <>
            <Divider />
            <Box p={customSectionBodyPadding}>
              <ClaimAttachmentCreateForm
                handleAddClaimAttachment={handleAddClaimAttachment}
              />
            </Box>
          </>
        )}
      </CustomSection>
      {isCreateOrUpdateMode && (
        <ClaimAttachmentUpdateForm
          state={state}
          setState={setState}
          handleUpdateClaimAttachment={handleUpdateClaimAttachment}
        />
      )}
    </>
  );
}
