import { useQueryFetchClaimSubmission } from "@redux/actions/claims/claim-submissions";
import { useParams } from "react-router-dom";

export default function useFetchClaimSubmission() {
  const { organization__slug, claim_submission__id } = useParams();
  const { data, isLoading } = useQueryFetchClaimSubmission({
    id: claim_submission__id,
    organization__slug,
  });
  const hasData = Boolean(data);

  return { data, hasData, isLoading, organization__slug, claim_submission__id };
}
