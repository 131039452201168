const WS_NOTIFICATION_TYPE_INCOMING_SYMPTOMS =
  "WS_NOTIFICATION_TYPE_INCOMING_SYMPTOMS";
const WS_NOTIFICATION_TYPE_END_SESSION = "WS_NOTIFICATION_TYPE_END_SESSION";
const WS_NOTIFICATION_TYPE_ERROR = "WS_NOTIFICATION_TYPE_ERROR";

module.exports = {
  WS_NOTIFICATION_TYPE_INCOMING_SYMPTOMS,
  WS_NOTIFICATION_TYPE_END_SESSION,
  WS_NOTIFICATION_TYPE_ERROR,
};
