import React from "react";
import CustomPaymentCardLogo from "@components/Custom/CustomPaymentCardLogo";
import {
  anonymizeCardNumber,
  ORGANIZATION_CARD_ACTION_DEACTIVATE,
  ORGANIZATION_CARD_ACTION_MAKE_PRIMARY,
  ORGANIZATION_CARD_ACTION_REPLACE,
  ORGANIZATION_CARD_STATUS_MAPPER,
  ORGANIZATION_CARD_STATUS_VALID,
} from "@constants/organizations/organization-cards";
import { parseMMYY } from "@helpers/parse/time";
import Typography from "@mui/material/Typography";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { HeartSelectedIcon, HeartUnselectedIcon } from "@rimads/assets";
import { AVEY_RED } from "@styles/theme";

const getActions = ({ row, setState = () => {} }) => {
  const { status, is_primary } = row;
  const handleReplaceCard = () =>
    setState({
      object: row,
      action: ORGANIZATION_CARD_ACTION_REPLACE,
      isOpen: true,
    });
  const handleDisabledCard = () =>
    setState({
      object: row,
      action: ORGANIZATION_CARD_ACTION_DEACTIVATE,
      isOpen: true,
    });
  const handleMakeCardPrimary = () =>
    setState({
      object: row,
      action: ORGANIZATION_CARD_ACTION_MAKE_PRIMARY,
      isOpen: true,
    });

  if (status === ORGANIZATION_CARD_STATUS_VALID) {
    if (is_primary)
      return [
        <GridActionsCellItem
          showInMenu
          label="Replace card"
          onClick={handleReplaceCard}
        />,
        <GridActionsCellItem
          showInMenu
          label="Disable card"
          onClick={handleDisabledCard}
        />,
      ];
    return [
      <GridActionsCellItem
        showInMenu
        label="Replace card"
        onClick={handleReplaceCard}
      />,
      <GridActionsCellItem
        showInMenu
        label="Disable card"
        onClick={handleDisabledCard}
      />,
      <GridActionsCellItem
        showInMenu
        label="Make card primary"
        onClick={handleMakeCardPrimary}
      />,
    ];
  }

  return [];
};

export default function OrganizationCardsGridProps({ setState = () => {} }) {
  return {
    columns: [
      {
        flex: 1,
        field: "card_type",
        headerName: "Type",
        renderCell: ({ value }) => <CustomPaymentCardLogo card_type={value} />,
      },
      {
        flex: 1,
        field: "card_last_4_digits",
        headerName: "Card",
        valueGetter: ({ value }) => anonymizeCardNumber(value),
      },
      {
        flex: 1,
        field: "card_expiry_date",
        headerName: "Expiry date",
        valueGetter: ({ value }) => parseMMYY(value),
      },
      {
        flex: 1,
        field: "status",
        headerName: "Status",
        renderCell: ({ value }) => {
          const color =
            value === ORGANIZATION_CARD_STATUS_VALID
              ? "textPrimary"
              : "textSecondary";
          return (
            <Typography variant="body2" color={color}>
              {ORGANIZATION_CARD_STATUS_MAPPER[value]}
            </Typography>
          );
        },
      },
      {
        flex: 0.5,
        field: "is_primary",
        headerName: "Main",
        renderCell: ({ value }) =>
          value ? (
            <HeartSelectedIcon primary_fill={AVEY_RED} />
          ) : (
            <HeartUnselectedIcon primary_fill={AVEY_RED} />
          ),
      },
      {
        flex: 0.5,
        field: "actions",
        type: "actions",
        getActions: ({ row }) => getActions({ row, setState }),
      },
    ],
    disableRowSelectionOnClick: true,
  };
}
