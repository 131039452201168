export function apiWrapper({ fn, stallDuration = 0 }) {
  return async (...args) => {
    if (stallDuration > 0) {
      await new Promise((resolve) => setTimeout(resolve, stallDuration));
    }

    const result = await fn(...args);

    return result;
  };
}
