import React from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import OrganizationPatientDetailsData from "../OrganizationPatientData/OrganizationPatientDetailsData";
import OrganizationPatientPolicyDetailsData from "./OrganizationPatientPolicyDetailsData";

export default function OrganizationPatientData({
  object = {},
  isMinified = false,
  useCustomSection = true,
}) {
  const spacing = isMinified ? 2 : 3;

  if (!Boolean(object)) return null;

  return (
    <Stack spacing={spacing}>
      <OrganizationPatientDetailsData
        object={object}
        useCustomSection={useCustomSection}
        isMinified={isMinified}
      />
      {!useCustomSection && <Divider />}
      <OrganizationPatientPolicyDetailsData
        object={object}
        useCustomSection={useCustomSection}
        isMinified={isMinified}
      />
    </Stack>
  );
}
