import React from "react";
import { TabPanel } from "@mui/lab";

export default function CustomTabPanel({ children, ...otherProps }) {
  return (
    <TabPanel sx={{ p: 0 }} {...otherProps}>
      {children}
    </TabPanel>
  );
}
