import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { CloseIcon, SearchIcon } from "@rimads/assets";
import CustomContextSearchField from "../CustomContextSearchField";
import { CustomOutlinedField } from "../CustomOutlinedField";

export default function CustomAutocomplete({
  placeholder = "Search...",
  value = null,
  handleChange = () => {},
  getOptionLabel = (option) => option?.name,
  isOptionEqualToValue = () => false,
  textFieldProps = {},
  withSearchIcon = true,
  loading = false,
  options = [],
  setSearchQuery = () => {},
  label = "",
  error = false,
  helperText = "",
  wrapperProps = {},
  ...otherProps
}) {
  return (
    <CustomOutlinedField
      label={label}
      error={error}
      helperText={helperText}
      wrapperProps={wrapperProps}
    >
      <Autocomplete
        options={options || []}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => {
          const {
            InputProps: paramInputProps = {},
            InputLabelProps: paramsInputLabelProps = {},
            ...otherParams
          } = params;
          const {
            InputProps: textFieldInputProps = {},
            InputLabelProps: textFieldInputLabelProps = {},
            ...otherTextFieldProps
          } = textFieldProps;
          return (
            <CustomContextSearchField
              placeholder={placeholder}
              setSearchQuery={setSearchQuery}
              useDefaultTextField={true}
              variant="standard"
              fullWidth
              InputProps={{
                ...paramInputProps,
                disableUnderline: true,
                startAdornment: withSearchIcon ? (
                  <InputAdornment position="start">
                    <SearchIcon dimension={18} />
                  </InputAdornment>
                ) : null,
                ...textFieldInputProps,
              }}
              InputLabelProps={{
                ...paramsInputLabelProps,
                ...textFieldInputLabelProps,
              }}
              debounceDuration={400}
              {...otherTextFieldProps}
              {...otherParams}
            />
          );
        }}
        loading={loading}
        onChange={(e, newValue, reason) => {
          reason === "clear" && setSearchQuery("");
          handleChange(newValue);
        }}
        filterOptions={(options) => options}
        value={value}
        slotProps={{ paper: { className: "elevated-8" } }}
        noOptionsText={<Typography variant="body2">{"No options"}</Typography>}
        clearIcon={<CloseIcon />}
        popupIcon={null}
        {...otherProps}
      />
    </CustomOutlinedField>
  );
}
