import React from "react";
import { getMedicationOptionLabel } from "@components/Custom/CustomAutocomplete/MedicationAutocomplete/MedicationAutocompleteComponents";
import CustomChip from "@components/Custom/CustomChip";

export default function OrganizationBundleMedicationSummary({
  organization_bundle_medication = {},
}) {
  const { medication = null } = organization_bundle_medication || {};
  const { code } = medication || {};
  const description = getMedicationOptionLabel(medication, true, false);

  return <CustomChip name={code} title={description} color="lightBlue" />;
}
