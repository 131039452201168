export const useRotateClassName = ({
  isOpen,
  useRotate90 = false,
  useRotate180 = true,
  useRotate = true,
}) => {
  if (!useRotate) return "";
  let className = "fullTransition ";
  if (isOpen) {
    if (useRotate90) className += "rotate-90";
    else if (useRotate180) className += "rotate-180";
  } else {
    className += "rotate-0";
  }
  return className;
};
