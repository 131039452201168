import React from "react";
import OrganizationBundleAutocomplete from "@components/Custom/CustomAutocomplete/OrganizationBundleAutocomplete";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import CustomMetaFormData from "@components/Custom/CustomMetaData/CustomMetaFormData";
import {
  CustomDatePicker,
  CustomOutlinedTextField,
} from "@components/Custom/CustomOutlinedField";
import CustomRadioForm from "@components/Custom/CustomRadioForm";
import {
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_INTERNAL_CODE,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_INVOICE_NUMBER,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_LAST_MENSTRUAL_PERIOD,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_NATURE_OF_CONCEPTION,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_ORGANIZATION_BUNDLE,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_PREAUTHORIZATION_NUMBER,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_PROCEDURE_TYPE,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_QUANTITY,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_RELATED_DATE,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_TOOTH_NUMBER,
} from "@constants/claims/claim-evaluation-results";
import {
  CLAIM_PROCEDURE_TYPE_OPTIONS,
  isProcedureTypeDental,
  isProcedureTypeMaternal,
} from "@constants/claims/claim-procedures";
import { getOrganizationBundleBilledAmount } from "@constants/claims/claims";
import { ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES } from "@constants/organizations/organization-bundles";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import SubclaimBillingDetailsFormData from "../Subclaim/SubclaimBillingDetailsFormData";

export default function ClaimProcedureFormData({
  formData,
  setFormData = () => {},
  visit_type,
  errorFieldnames = {},
}) {
  const {
    organization_bundle,
    quantity,
    related_date,
    procedure_type,
    tooth_number,
    nature_of_conception,
    last_menstrual_period,
    preauthorization_number,
    invoice_number,
    meta_data = {},
    ...otherFormData
  } = formData;

  const {
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_ORGANIZATION_BUNDLE]:
      organization_bundle_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_QUANTITY]: quantity_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_RELATED_DATE]: related_date_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_PREAUTHORIZATION_NUMBER]:
      preauthorization_number_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_INTERNAL_CODE]:
      internal_code_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_INVOICE_NUMBER]:
      invoice_number_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_TOOTH_NUMBER]: tooth_number_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_NATURE_OF_CONCEPTION]:
      nature_of_conception_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_LAST_MENSTRUAL_PERIOD]:
      last_menstrual_period_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_PROCEDURE_TYPE]:
      procedure_type_error = false,
  } = errorFieldnames;

  const isMaternal = isProcedureTypeMaternal(procedure_type);
  const isDental = isProcedureTypeDental(procedure_type);
  const hasMetaData = Object.keys(meta_data).length > 0;

  const handleChangeOrganizationBundle = (newValue) => {
    const default_amount_requested = newValue
      ? getOrganizationBundleBilledAmount({
          organization_bundle: newValue,
          visit_type,
          quantity,
        })
      : "";

    setFormData((prev) => ({
      ...prev,
      organization_bundle: newValue,
      amount_requested: prev?.amount_requested || default_amount_requested,
    }));
  };

  const handleChangeValue = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeField = ({ fieldname, newValue }) =>
    setFormData((prev) => ({ ...prev, [fieldname]: newValue }));

  return (
    <Stack spacing={3}>
      <CustomListItemCollapse primary="Procedure details" useCollapse={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OrganizationBundleAutocomplete
              label="Procedure"
              value={organization_bundle}
              handleChange={handleChangeOrganizationBundle}
              error={organization_bundle_error || internal_code_error}
              bundle_type={ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomOutlinedTextField
              type="number"
              label="Quantity"
              name="quantity"
              value={quantity}
              error={quantity_error}
              onChange={handleChangeValue}
              inputProps={{ min: 1, step: 1 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomDatePicker
              label="Procedure date"
              wrapperProps={{ error: related_date_error }}
              value={related_date}
              onChange={(newValue) =>
                handleChangeField({ fieldname: "related_date", newValue })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomOutlinedTextField
              label="Invoice number"
              name="invoice_number"
              value={invoice_number || ""}
              error={invoice_number_error}
              onChange={handleChangeValue}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomOutlinedTextField
              label="Preauthorization number"
              name="preauthorization_number"
              value={preauthorization_number || ""}
              error={preauthorization_number_error}
              onChange={handleChangeValue}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomRadioForm
              options={CLAIM_PROCEDURE_TYPE_OPTIONS}
              value={procedure_type}
              error={procedure_type_error}
              onChange={(newValue) =>
                handleChangeField({ fieldname: "procedure_type", newValue })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomOutlinedTextField
              disabled={!isDental}
              type="number"
              label="Tooth number"
              name="tooth_number"
              value={isDental ? tooth_number || "" : ""}
              error={tooth_number_error}
              onChange={handleChangeValue}
              inputProps={{ min: 1, max: 32, step: 1 }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomOutlinedTextField
              disabled={!isMaternal}
              label="Nature of conception"
              name="nature_of_conception"
              value={isMaternal ? nature_of_conception || "" : ""}
              error={nature_of_conception_error}
              onChange={handleChangeValue}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomDatePicker
              disabled={!isMaternal}
              label="Last menstrual period"
              value={isMaternal ? last_menstrual_period : null}
              wrapperProps={{ error: last_menstrual_period_error }}
              onChange={(newValue) =>
                handleChangeField({
                  fieldname: "last_menstrual_period",
                  newValue,
                })
              }
            />
          </Grid>
        </Grid>
      </CustomListItemCollapse>
      <Divider />
      <SubclaimBillingDetailsFormData
        formData={otherFormData}
        setFormData={setFormData}
      />
      {hasMetaData && (
        <>
          <Divider />
          <CustomMetaFormData
            meta_data={meta_data}
            handleChange={(newValue) =>
              handleChangeField({ fieldname: "meta_data", newValue })
            }
          />
        </>
      )}
    </Stack>
  );
}
