import React from "react";
import CustomButton from "@components/Custom/CustomButton";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomFilters from "@components/Custom/CustomFilters";
import { AVEY_WITH_AVEY_COS } from "@constants/static/globals";
import {
  AVEY_COWRITER_TITLE,
  getClaimDemoHref,
} from "@constants/static/routing";
import { encodeURLParams } from "@helpers/api/getQueryParams";
import useSort from "@helpers/hooks/useSort";
import Grid from "@mui/material/Grid";
import { useQueryFetchClaims } from "@redux/actions/claims/claims";
import {
  selectorAuthClaimMode,
  selectorAuthCurrentUserOrganizationCurrency,
  selectorAuthCurrentUserOrganizationRole,
  selectorAuthHasSubmitterPrivileges,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import ClaimsExportForm from "../../ClaimExports/ClaimExportForm";
import ClaimsCreateForms from "./ClaimsCreateForms";
import {
  formatFilters,
  getFilterOptions,
  getUseQueryFnPropsClaims,
  initialFilterState,
} from "./ClaimsFilters";
import ClaimsGridProps from "./ClaimsGridProps";
import ClaimsStatusFilters from "./ClaimsStatusFilters";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

const ClaimsGridDemoLink = () => {
  const { organization__slug, claim_submission__id } = useParams();

  if (!AVEY_WITH_AVEY_COS) return null;

  return (
    <Grid item>
      <NavLink
        to={getClaimDemoHref({ organization__slug, claim_submission__id })}
      >
        <CustomButton label={AVEY_COWRITER_TITLE} />
      </NavLink>
    </Grid>
  );
};

export default function ClaimsGrid() {
  const [statusTab, setStatusTab] = React.useState("");
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const { organization__slug, claim_submission__id } = useParams();
  const hasSubmitterPrivileges = useSelector(
    selectorAuthHasSubmitterPrivileges
  );
  const currency = useSelector(selectorAuthCurrentUserOrganizationCurrency);
  const claimMode = useSelector(selectorAuthClaimMode);
  const organizationRole = useSelector(selectorAuthCurrentUserOrganizationRole);
  const useSortObject = useSort({ initialSortModel });
  const { sortQuery = "" } = useSortObject || {};
  const useQueryFnPropsClaims = getUseQueryFnPropsClaims({
    searchQuery,
    sortQuery,
    organizationRole,
    organization__slug,
    claim_submission__id,
    statusTab,
    filters,
  });
  const urlSearch = encodeURLParams(useQueryFnPropsClaims);
  const dataGridProps = React.useMemo(
    () => ClaimsGridProps({ organization__slug, urlSearch }),
    [organization__slug, urlSearch]
  );
  const filterOptions = React.useMemo(
    () =>
      getFilterOptions({ claimMode, organizationRole, statusTab, currency }),
    [claimMode, organizationRole, statusTab, currency]
  );

  return (
    <div>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item flexGrow={1}>
          <CustomContextSearchField setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item>
          <CustomFilters
            initialFilterState={initialFilterState}
            filterOptions={filterOptions}
            formatFilters={formatFilters}
            setFilters={setFilters}
          />
        </Grid>
        {hasSubmitterPrivileges && (
          <>
            <Grid item>
              <ClaimsCreateForms />
            </Grid>
            <ClaimsGridDemoLink />
          </>
        )}
        <Grid item>
          <ClaimsExportForm useQueryFnPropsClaims={useQueryFnPropsClaims} />
        </Grid>
        <Grid item xs={12}>
          <ClaimsStatusFilters setStatusTab={setStatusTab} />
        </Grid>
        <Grid item xs={12}>
          <CustomInfiniteDataGrid
            useSortObject={useSortObject}
            useQueryFn={useQueryFetchClaims}
            useQueryFnProps={useQueryFnPropsClaims}
            dataGridProps={dataGridProps}
            initialSortModel={initialSortModel}
          />
        </Grid>
      </Grid>
    </div>
  );
}
