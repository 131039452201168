import React from "react";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import Grid from "@mui/material/Grid";
import { useQueryFetchUploads } from "@redux/actions/utils/uploads";
import { useParams } from "react-router-dom";
import ConfigurationsBulkUploadsCreateForm from "./ConfigurationsBulkUploadsCreateForm";
import ConfigurationsBulkUploadsGridProps from "./ConfigurationsBulkUploadsGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function ConfigurationsBulkUploadsGrid({
  model_type,
  bundle_type,
}) {
  const { organization__slug } = useParams();
  const [searchQuery, setSearchQuery] = React.useState("");
  const useQueryFnProps = {
    model_type,
    searchQuery,
    filters: {
      bundle_type,
      processor_organization__slug: organization__slug,
    },
  };
  const dataGridProps = React.useMemo(
    () =>
      ConfigurationsBulkUploadsGridProps({
        model_type,
        bundle_type,
        organization__slug,
      }),
    [model_type, bundle_type, organization__slug]
  );

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
      <Grid item flexGrow={1}>
        <CustomContextSearchField setSearchQuery={setSearchQuery} />
      </Grid>
      <Grid item>
        <ConfigurationsBulkUploadsCreateForm
          model_type={model_type}
          bundle_type={bundle_type}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomInfiniteDataGrid
          useQueryFn={useQueryFetchUploads}
          useQueryFnProps={useQueryFnProps}
          dataGridProps={dataGridProps}
          initialSortModel={initialSortModel}
        />
      </Grid>
    </Grid>
  );
}
