import { getRowValue } from "@helpers/parse/csv";
import { v4 as uuidv4 } from "uuid";
import {
  ORGANIZATION_PATIENT_VISIT_TYPE_BOTH,
  ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT,
  isInpatientVisitType,
  isOutpatientVisitType,
} from "./organization-patients";

export const ORGANIZATION_BUNDLE_IS_PREAUTHORIZATION_REQUIRED_MAPPER = {
  [true]: "Yes",
  [false]: "No",
};

export const ORGANIZATION_BUNDLE_IS_SECOND_PROCEDURE_DISCOUNT_MAPPER = {
  [true]: "Yes",
  [false]: "No",
};

export const getEmptyOrganizationBundleFormData = ({
  currency = "",
  bundle_type,
}) => ({
  internal_code: "",
  description: "",
  undiscounted_price: "",
  inpatient_discount_rate: "",
  outpatient_discount_rate: "",
  is_preauthorization_required: false,
  is_second_procedure_discount: false,
  visit_type: ORGANIZATION_PATIENT_VISIT_TYPE_BOTH,
  organization_bundle_cpts: [],
  organization_bundle_consumables: [],
  organization_bundle_medication: {},
  currency,
  bundle_type,
});

export const emptyOrganizationBundleCptFormData = {
  cpt: null,
  frequency: 1,
};

export const emptyOrganizationBundleConsumableFormData = {
  consumable: null,
  frequency: 1,
};

export const formatOrganizationBundleFormData = ({
  isCheck,
  processor_organization__slug,
  formData,
  bundle_type,
}) => {
  const {
    id = undefined,
    currency = undefined,
    internal_code,
    description,
    undiscounted_price,
    visit_type,
    inpatient_discount_rate,
    outpatient_discount_rate,
    is_preauthorization_required,
    is_second_procedure_discount,
    organization_bundle_cpts,
    organization_bundle_consumables,
    organization_bundle_medication,
  } = formData;

  const isInpatientOnly =
    visit_type === ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT;
  const isInpatient = isInpatientVisitType(visit_type);
  const isOutpatient = isOutpatientVisitType(visit_type);
  const isProcedureAndConsumable =
    isOrganizationBundleTypeProcedure(bundle_type);

  return {
    id,
    bundle_type,
    is_check: isCheck,
    processor_organization__slug,
    internal_code: internal_code?.trim(),
    description: description?.trim(),
    undiscounted_price,
    visit_type,
    inpatient_discount_rate: isInpatient ? inpatient_discount_rate : null,
    outpatient_discount_rate: isOutpatient ? outpatient_discount_rate : null,
    is_preauthorization_required: isInpatientOnly
      ? true
      : is_preauthorization_required,
    is_second_procedure_discount: isProcedureAndConsumable
      ? is_second_procedure_discount
      : false,
    organization_bundle_cpts,
    organization_bundle_consumables,
    organization_bundle_medication,
    currency,
  };
};

const organizationBundleRowToObject = ({ row, bundle_type }) => {
  const parsedRow = getRowValue({
    row,
    columns: [
      { headerName: "INTERNAL CODE", fieldname: "internal_code" },
      { headerName: "DESCRIPTION", fieldname: "description" },
      { headerName: "UNDISCOUNTED PRICE", fieldname: "undiscounted_price" },
      { headerName: "VISIT TYPE", fieldname: "visit_type" },
      {
        headerName: "INPATIENT DISCOUNT",
        fieldname: "inpatient_discount_rate",
      },
      {
        headerName: "OUTPATIENT DISCOUNT",
        fieldname: "outpatient_discount_rate",
      },
      {
        headerName: "REQUIRES PREAUTHORIZATION",
        fieldname: "is_preauthorization_required",
        valueGetter: (value) => value === "1",
      },
      {
        headerName: "CPT CODE",
        fieldname: "cpt_code",
      },
      {
        headerName: "CONSUMABLE CODE",
        fieldname: "consumable_code",
      },
      {
        headerName: "FREQUENCY",
        fieldname: "frequency",
      },
      {
        headerName: "SECOND PROCEDURE DISCOUNT",
        fieldname: "is_second_procedure_discount",
        valueGetter: (value) =>
          bundle_type === ORGANIZATION_BUNDLE_TYPE_MEDICATIONS
            ? false
            : value === "1",
      },
      {
        headerName: "MEDICATION CODE",
        fieldname: "medication_code",
      },
    ],
  });

  const {
    internal_code,
    description,
    undiscounted_price,
    visit_type,
    inpatient_discount_rate,
    outpatient_discount_rate,
    is_preauthorization_required,
    cpt_code,
    consumable_code,
    frequency,
    is_second_procedure_discount,
    medication_code,
  } = parsedRow;

  const isRowEmpty = !(
    internal_code ||
    undiscounted_price ||
    description ||
    cpt_code ||
    consumable_code ||
    medication_code ||
    frequency ||
    visit_type ||
    inpatient_discount_rate ||
    outpatient_discount_rate
  );
  return {
    id: isRowEmpty ? null : uuidv4(),
    internal_code,
    undiscounted_price,
    description,
    cpt_code,
    consumable_code,
    medication_code,
    frequency,
    visit_type,
    inpatient_discount_rate,
    outpatient_discount_rate,
    is_preauthorization_required,
    is_second_procedure_discount,
  };
};

export const organizationBundleRowsToObjects = ({
  rows,
  currency,
  bundle_type,
}) => {
  const finalDict = {};
  const emptyBundleFormData = getEmptyOrganizationBundleFormData({
    currency,
    bundle_type,
  });
  rows
    .map((row) => organizationBundleRowToObject({ row, bundle_type }))
    .filter(({ id }) => Boolean(id))
    .forEach(
      ({
        id,
        internal_code,
        undiscounted_price,
        description,
        cpt_code,
        consumable_code,
        medication_code,
        frequency,
        visit_type,
        inpatient_discount_rate,
        outpatient_discount_rate,
        is_preauthorization_required,
        is_second_procedure_discount,
      }) => {
        const prevInstance = {
          ...(finalDict[internal_code] || emptyBundleFormData),
        };
        const newInstance = {
          id,
          internal_code: internal_code || prevInstance?.internal_code,
          description: description || prevInstance?.description,
          undiscounted_price:
            undiscounted_price || prevInstance?.undiscounted_price,
          inpatient_discount_rate:
            inpatient_discount_rate || prevInstance?.inpatient_discount_rate,
          outpatient_discount_rate:
            outpatient_discount_rate || prevInstance?.outpatient_discount_rate,
          visit_type: visit_type || prevInstance?.visit_type,
          is_preauthorization_required,
          is_second_procedure_discount,
          bundle_type,
          currency,
        };
        const object = { ...prevInstance, ...newInstance };
        if (Boolean(cpt_code)) {
          finalDict[internal_code] = {
            ...object,
            organization_bundle_cpts: object.organization_bundle_cpts.concat({
              cpt: { code: cpt_code },
              frequency: frequency,
            }),
          };
        } else if (Boolean(consumable_code)) {
          finalDict[internal_code] = {
            ...object,
            organization_bundle_consumables:
              object.organization_bundle_consumables.concat({
                consumable: { code: consumable_code },
                frequency: frequency,
              }),
          };
        } else if (Boolean(medication_code)) {
          finalDict[internal_code] = {
            ...object,
            organization_bundle_medication: {
              medication: { code: medication_code },
            },
          };
        } else {
          finalDict[internal_code] = object;
        }
      }
    );

  return Object.values(finalDict);
};

export const ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES = "P";
export const ORGANIZATION_BUNDLE_TYPE_MEDICATIONS = "M";
export const ORGANIZATION_BUNDLE_SUBTYPE_CONSULTATION = "C";

export const isOrganizationBundleTypeProcedure = (bundle_type) =>
  bundle_type === ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES;
export const isOrganizationBundleTypeMedication = (bundle_type) =>
  bundle_type === ORGANIZATION_BUNDLE_TYPE_MEDICATIONS;
