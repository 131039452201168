import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomSection from "@components/Custom/CustomSection";
import CustomValueWithUnit from "@components/Custom/CustomValueWithUnit";
import {
  ORGANIZATION_POLICY_COVERAGE_TYPE_MAPPER,
  ORGANIZATION_POLICY_COVERAGE_VISIT_MAPPER,
  hasCoverageTypeCoverageRate,
  hasCoverageTypeFixedAmount,
} from "@constants/organizations/organization-policies";
import { getIntOrFloat } from "@helpers/parse/numbers";

export default function OrganizationPolicyCoverageData({
  object = {},
  isMinified = false,
}) {
  const {
    visit_type,
    coverage_type,
    fixed_amount,
    coverage_rate,
    preauthorization_limit,
    currency,
  } = object;

  const title = ORGANIZATION_POLICY_COVERAGE_VISIT_MAPPER[visit_type];
  const hasFixedAmount = hasCoverageTypeFixedAmount(coverage_type);
  const hasCoverageRate = hasCoverageTypeCoverageRate(coverage_type);

  return (
    <CustomSection title={title} isMinified={isMinified}>
      <CustomGrid>
        <CustomLabeledText
          label="Coverage type"
          value={ORGANIZATION_POLICY_COVERAGE_TYPE_MAPPER[coverage_type]}
        />
        {hasCoverageRate && (
          <CustomLabeledText label="Coverage rate" useChildren={true}>
            <CustomValueWithUnit
              value={getIntOrFloat(coverage_rate)}
              unit="%"
            />
          </CustomLabeledText>
        )}
        {hasFixedAmount && (
          <CustomLabeledText label="Fixed amount" useChildren={true}>
            <CustomValueWithUnit
              value={getIntOrFloat(fixed_amount)}
              unit={currency}
            />
          </CustomLabeledText>
        )}
        <CustomLabeledText label="Preauthorization limit" useChildren={true}>
          <CustomValueWithUnit
            value={getIntOrFloat(preauthorization_limit)}
            unit={currency}
          />
        </CustomLabeledText>
      </CustomGrid>
    </CustomSection>
  );
}
