import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import ClaimAttachmentFormData from "./ClaimAttachmentFormData";

export default function ClaimAttachmentUpdateForm({
  state,
  setState = () => {},
  handleUpdateClaimAttachment = () => {},
}) {
  const { isOpen, object, index } = state;
  const [formData, setFormData] = React.useState(object);

  const handleCloseClaimAttachment = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setState((prev) => ({ ...prev, isOpen: false }));
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    handleUpdateClaimAttachment({ payload: formData, index });
    handleCloseClaimAttachment(e);
  };

  React.useEffect(() => {
    isOpen && setFormData(object);
  }, [isOpen, object]);

  return (
    <CustomActionDialog
      maxWidth="xs"
      isOpen={isOpen}
      handleClose={handleCloseClaimAttachment}
      handleSubmit={handleSubmit}
      titleComponentProps={{ title: "Update attachment" }}
    >
      <ClaimAttachmentFormData formData={formData} setFormData={setFormData} />
    </CustomActionDialog>
  );
}
