export const getRowValue = ({ row, columns }) => {
  const output = {};
  columns.forEach(({ headerName, fieldname, valueGetter = undefined }) => {
    const outputValue = (row[headerName] || "")?.toString()?.trim();
    output[fieldname] = Boolean(valueGetter)
      ? valueGetter(outputValue)
      : outputValue;
  });
  return output;
};
