import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomDeactivateObject from "@components/Custom/CustomDeactivateObject";
import {
  deleteOrganizationBundle,
  invalidateOrganizationBundlesTSQ,
  updateOrganizationBundleObjectTSQ,
} from "@redux/actions/organizations/organization-bundles";

export default function OrganizationBundleActivateForm({ object }) {
  const handleDeleteFn = ({ payload }) => {
    invalidateOrganizationBundlesTSQ();
    updateOrganizationBundleObjectTSQ({ object: payload });
  };

  return (
    <CustomDeactivateObject
      object={object}
      label="code"
      deleteFn={deleteOrganizationBundle}
      handleDeleteFn={handleDeleteFn}
      TitleComponent={InsuranceOrganizationActionDialogTitle}
    />
  );
}
