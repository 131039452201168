import React from "react";
import { isClaimAttachmentImageUrl } from "@constants/claims/claim-attachments";
import { parseFileSize } from "@helpers/parse/numbers";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DocumentIcon } from "@rimads/assets";

export default function ClaimAttachmentData({
  object,
  outerStackProps = {},
  innerStackProps = {},
  primaryTypographyProps = {},
  withFileSize = true,
  useLink = false,
}) {
  const { file, name } = object;
  const { name: fileName, size } = typeof file === "object" ? file : {};
  const finalName = fileName || name || "-";
  const [imgSrc, setImageSrc] = React.useState(null);

  React.useEffect(() => {
    const reader = new FileReader();
    if (file?.type?.startsWith("image/")) {
      reader.readAsDataURL(file);
      reader.onloadend = () => setImageSrc(reader.result);
    } else if (isClaimAttachmentImageUrl(file)) {
      setImageSrc(file);
    }
  }, [file]);

  return (
    <Stack spacing={3} direction="row" alignItems="center" {...outerStackProps}>
      {imgSrc ? (
        <img
          src={imgSrc}
          alt={finalName}
          height={24}
          style={{ borderRadius: "4px" }}
        />
      ) : useLink ? (
        <a href={file}>
          <DocumentIcon dimension={24} />
        </a>
      ) : (
        <DocumentIcon dimension={24} />
      )}
      <Stack {...innerStackProps}>
        <Typography
          variant="body2"
          fontWeight="bold"
          className="ellipsis-1"
          title={name}
          {...primaryTypographyProps}
        >
          {finalName}
        </Typography>
        {withFileSize && size && (
          <Typography variant="body2" color="textSecondary">
            {parseFileSize(size)}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
