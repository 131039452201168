import { createTheme } from "@mui/material/styles";

export const AVEY_BLUE = "#77E0FF";
export const AVEY_BLUE_DARK = "#67D0EF";
export const AVEY_BLUE_LIGHT = "#E6FBFF";
export const AVEY_RED = "#ED7273";
export const AVEY_RED_DARK = "#DD6263";
export const AVEY_RED_LIGHT = "#FDF1F1";
export const AVEY_YELLOW = "#FFDE6F";
export const AVEY_BLACK = "#000000";
export const AVEY_BLACK_LIGHT = "#333333";
export const AVEY_GREY = "#CCCCCC";
export const AVEY_GREY_DARK = "#9E9E9E";
export const AVEY_GREY_F2 = "#F2F2F2";
export const AVEY_GREY_F9 = "#F9F9F9";
export const AVEY_WHITE = "#FFFFFF";
export const AVEY_WHITE_LIGHT = "#FFFFFF66";
export const AVEY_WHITE_DARK = "#F7F7F7";

const defaultTheme = createTheme();
const { breakpoints } = defaultTheme;

const shadows = [
  "none",
  "rgb(0, 0, 0, 0.01) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.02) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.03) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.04) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.05) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.06) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.07) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.08) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.09) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.10) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.11) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.12) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.13) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.14) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.15) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.16) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.17) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.18) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.19) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.20) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.21) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.22) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.23) 0px 2px 11px 0px",
  "rgb(0, 0, 0, 0.24) 0px 2px 11px 0px",
];

const theme = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: AVEY_BLACK,
          color: AVEY_WHITE,
        },
        deleteIcon: {
          color: AVEY_WHITE,
        },
        icon: {
          color: AVEY_WHITE,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: 0,
          fontSize: 14,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        select: {
          minHeight: 40,
          display: "grid",
          alignItems: "center",
          padding: "0px 8px",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorInfo: {
          color: AVEY_BLACK,
          backgroundColor: AVEY_YELLOW,
          borderRadius: "4px",
          fontWeight: "bold",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: AVEY_BLACK,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.outlined": {
            border: `1px solid ${AVEY_GREY_F2}`,
          },
          "&.square": {
            borderRadius: "8px",
          },
        },
        colorPrimary: {
          backgroundColor: AVEY_BLUE_LIGHT,
        },
        colorSecondary: {
          backgroundColor: AVEY_RED_LIGHT,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          "&.thick": {
            height: "8px",
            borderRadius: "20px",
          },
        },
        colorPrimary: {
          backgroundColor: AVEY_GREY_F2,
        },
        barColorPrimary: {
          backgroundColor: AVEY_BLACK,
        },
        colorSuccess: {
          backgroundColor: AVEY_BLUE_LIGHT,
        },
        barColorSuccess: {
          backgroundColor: AVEY_BLUE,
        },
        colorError: {
          backgroundColor: AVEY_RED_LIGHT,
        },
        barColorError: {
          backgroundColor: AVEY_RED,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "8px 24px",
          fontSize: "14px",
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        focusHighlight: {
          display: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          whiteSpace: "nowrap",
          padding: "0px 20px",
          minHeight: 40,
          minWidth: 40,
          ":disabled": {
            color: AVEY_WHITE,
          },
        },
        contained: {
          color: AVEY_WHITE,
        },
        outlined: {
          borderWidth: "1px",
          ":hover": { borderWidth: "1px" },
        },
        containedPrimary: {
          backgroundColor: AVEY_BLACK,
          color: AVEY_WHITE,
          ":hover": { backgroundColor: AVEY_BLACK_LIGHT },
        },
        containedSecondary: {
          backgroundColor: AVEY_RED,
          color: AVEY_WHITE,
          ":hover": { backgroundColor: AVEY_RED_DARK },
        },
        containedSuccess: {
          backgroundColor: AVEY_BLUE,
          color: AVEY_WHITE,
          ":hover": { backgroundColor: AVEY_BLUE_DARK },
        },
        containedInfo: {
          backgroundColor: AVEY_GREY_F2,
          color: AVEY_BLACK,
          ":hover": { backgroundColor: AVEY_GREY },
        },
        outlinedPrimary: {
          ":disabled": {
            color: AVEY_GREY,
          },
          borderColor: AVEY_GREY_DARK,
          color: AVEY_BLACK,
          backgroundColor: AVEY_WHITE,
          ":hover": {
            borderColor: AVEY_BLACK,
            backgroundColor: AVEY_WHITE_DARK,
          },
        },
        outlinedSecondary: {
          borderColor: AVEY_RED_LIGHT,
          color: AVEY_BLACK,
          backgroundColor: AVEY_WHITE,
          ":hover": {
            borderColor: AVEY_RED_LIGHT,
            backgroundColor: AVEY_WHITE_DARK,
          },
        },
        outlinedSuccess: {
          borderColor: AVEY_BLUE_LIGHT,
          color: AVEY_BLACK,
          backgroundColor: AVEY_WHITE,
          ":hover": {
            borderColor: AVEY_BLUE,
            backgroundColor: AVEY_WHITE_DARK,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: AVEY_BLUE_LIGHT,
          "&.sizeXs": {
            height: 24,
            width: 24,
          },
          "&.sizeSm": {
            height: 32,
            width: 32,
          },
          "&.sizeMd": {
            height: 48,
            width: 48,
          },
          "&.sizeLg": {
            height: 56,
            width: 56,
          },
          "&.sizeXl": {
            height: 64,
            width: 64,
          },
          "&.sizeXxl": {
            height: 200,
            width: 200,
          },
        },
        square: {
          borderRadius: 8,
        },
      },
    },
  },
  typography: {
    fontFamily: "Nunito, sans-serif",
    h1: {
      fontWeight: 600,
      fontSize: 64,
      letterSpacing: "-0.24px",
      [breakpoints.down("md")]: {
        fontSize: 48,
      },
    },
    h2: {
      fontWeight: 600,
      fontSize: 48,
      letterSpacing: "-0.24px",
      [breakpoints.down("md")]: {
        fontSize: 32,
      },
    },
    h3: {
      fontWeight: 600,
      fontSize: 32,
      letterSpacing: "-0.06px",
      [breakpoints.down("md")]: {
        fontSize: 24,
      },
    },
    h4: {
      fontWeight: 600,
      fontSize: 24,
      letterSpacing: "-0.06px",
      [breakpoints.down("md")]: {
        fontSize: 20,
      },
    },
    h5: {
      fontWeight: 600,
      fontSize: 20,
      letterSpacing: "-0.05px",
      [breakpoints.down("md")]: {
        fontSize: 18,
      },
    },
    h6: {
      fontWeight: 600,
      fontSize: 18,
      letterSpacing: "-0.05px",
      [breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: "-0.05px",
      [breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: "-0.05px",
      [breakpoints.down("md")]: {
        fontSize: 12,
      },
    },
    body1: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: "-0.05px",
      [breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
    body2: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: "-0.05px",
      [breakpoints.down("md")]: {
        fontSize: 12,
      },
    },
    caption: {
      fontWeight: 500,
      fontSize: 12,
      letterSpacing: "-0.05px",
      [breakpoints.down("md")]: {
        fontSize: 10,
      },
      lineHeight: 1.3,
    },
    overline: {
      fontWeight: 700,
      fontSize: 12,
      letterSpacing: "1.2px",
      [breakpoints.down("md")]: {
        fontSize: 10,
      },
    },
    button: {
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: "-0.05px",
      textTransform: "none",
      [breakpoints.down("md")]: {
        fontSize: 12,
      },
    },
  },
  palette: {
    primary: { main: AVEY_BLUE },
    secondary: { main: AVEY_RED },
    tertiary: { main: AVEY_YELLOW },
    text: { primary: AVEY_BLACK, secondary: AVEY_GREY_DARK },
    common: { white: AVEY_WHITE },
  },
  shadows,
});

export default theme;
