import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomBox from "@components/Custom/CustomBox";
import {
  AVEY_PHONE_NUMBER,
  AVEY_SUPPORT_EMAIL,
  AVEY_WHATSAPP_NUMBER,
} from "@constants/static/globals";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CallStartIcon, EmailIcon, WhatsAppLogo } from "@rimads/assets";
import { AVEY_BLACK } from "@styles/theme";

const removeWhiteSpace = (str) => str.replace(/\s+/g, "");

const items = [
  AVEY_SUPPORT_EMAIL && {
    IconComponent: EmailIcon,
    label: AVEY_SUPPORT_EMAIL,
    href: `mailto:${AVEY_SUPPORT_EMAIL}`,
  },
  AVEY_PHONE_NUMBER && {
    IconComponent: CallStartIcon,
    label: AVEY_PHONE_NUMBER,
    href: `tel:${AVEY_PHONE_NUMBER}`,
  },
  AVEY_WHATSAPP_NUMBER && {
    IconComponent: WhatsAppLogo,
    label: AVEY_WHATSAPP_NUMBER,
    href: `tel:${AVEY_WHATSAPP_NUMBER}`,
  },
]
  .filter(Boolean)
  .map(({ href, ...other }) => ({ ...other, href: removeWhiteSpace(href) }));

export default function NavigationTopAppBarHelpDialog() {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ListItemButton onClick={handleClose} sx={{ px: 3 }}>
        <ListItemText
          primary="Help"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </ListItemButton>
      <CustomActionDialog
        isOpen={isOpen}
        bodyProps={{ p: { xs: "32px", sm: "40px" } }}
        displayActions={false}
        handleClose={handleClose}
        handleSubmit={handleClose}
        titleComponentProps={{ title: "Help" }}
        subtitleComponentProps={{
          title: "Reach out to our customer service for any inquiries.",
        }}
        closeOnClickAway={true}
      >
        {items.map(({ IconComponent, label, href }, index) => (
          <a key={index} href={href} target="_blank" rel="noreferrer">
            <CustomBox
              className="elevated-8 elevateOnHover borderRadius-8 button"
              p={2}
            >
              <Stack direction="row" alignItems="center" spacing={1.5}>
                <IconComponent dimension={14} primary_fill={AVEY_BLACK} />
                <Typography variant="body2">{label}</Typography>
              </Stack>
            </CustomBox>
          </a>
        ))}
      </CustomActionDialog>
    </>
  );
}
