import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomSection from "@components/Custom/CustomSection";
import { parseDate } from "@helpers/parse/time";

export default function OrganizationPolicyDetailsData({
  object = {},
  isMinified = false,
}) {
  const { number, name, description, insurer_name, expiry_date } = object;
  const expiryDate = parseDate(expiry_date);
  const hasInsurerName = Boolean(insurer_name);

  return (
    <CustomSection title="Policy details" isMinified={isMinified}>
      <CustomGrid>
        <CustomLabeledText label="Policy number" value={number} />
        <CustomLabeledText label="Policy name" value={name} />
        {hasInsurerName && (
          <CustomLabeledText label="Insurer name" value={insurer_name} />
        )}
        <CustomLabeledText label="Expiry date" value={expiryDate} />
        <CustomLabeledText label="Scheme" value={description} />
      </CustomGrid>
    </CustomSection>
  );
}
