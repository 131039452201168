import React from "react";
import List from "@mui/material/List";
import Popper from "@mui/material/Popper";
import CustomClickAwayListener from "./CustomClickAwayListener";

export default function CustomPopper({
  anchorEl,
  handleAnchorEl,
  placement = "bottom-end",
  children,
  listProps = {},
  cardStyleProps = {},
}) {
  return (
    Boolean(anchorEl) && (
      <CustomClickAwayListener onClickAway={() => handleAnchorEl(null)}>
        <Popper
          open={true}
          anchorEl={anchorEl}
          placement={placement}
          style={{ zIndex: 1_300 }}
        >
          <div
            className="elevated-8 elevateOnHover borderRadius-8 whiteBg"
            style={{ margin: "16px 0px", ...cardStyleProps }}
          >
            <List {...listProps}>{children}</List>
          </div>
        </Popper>
      </CustomClickAwayListener>
    )
  );
}
