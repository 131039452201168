import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import { CustomSummaryDialog } from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { formatUploadRawJson } from "@constants/static/upload";
import Stack from "@mui/material/Stack";
import { useQueryFetchOrganization } from "@redux/actions/organizations/organizations";
import {
  ignoreUploadEntry,
  updateUploadEntry,
  updateUploadEntryObjectTSQ,
  useQueryFetchUploadEntries,
} from "@redux/actions/utils/uploads";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import HealthcareOrganizationActionDialogTitle from "../../HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import ConfigurationsBulkUploadEntriesGridProps from "./ConfigurationsBulkUploadEntriesGridProps";
import {
  getConfigurationsBulkUploadEntryComponent,
  getConfigurationsBulkUploadEntryDialogProps,
} from "./ConfigurationsBulkUploadEntryComponents";
import ConfigurationsBulkUploadStatusFilters from "./ConfigurationsBulkUploadStatusFilters";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];
const emptyEntryState = { entry: null, isOpen: false, action: null };

const UPLOAD_ACTION_UPDATE = "UPLOAD_ACTION_UPDATE";
const UPLOAD_ACTION_IGNORE = "UPLOAD_ACTION_IGNORE";

export default function ConfigurationsBulkUploadEntriesGrid({
  object,
  model_type,
  bundle_type,
}) {
  const { organization__slug } = useParams();
  const { data: processor_organization } = useQueryFetchOrganization({
    organization__slug,
  });
  const { id: upload__id, use_processing_status } = object;
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [statusTab, setStatusTab] = React.useState("");
  const [entryState, setEntryState] = React.useState(emptyEntryState);
  const { entry, isOpen, action } = entryState;
  const isIgnoreAction = action === UPLOAD_ACTION_IGNORE;
  const isUpdateAction = action === UPLOAD_ACTION_UPDATE;
  const { summaries = [], exceptions = [] } = entry || {};
  const is_processor_tpa = processor_organization?.is_processor_tpa || false;

  const handleCloseObject = (e) => {
    e?.preventDefault();
    setEntryState(emptyEntryState);
  };

  const handleEditRow = (row) => {
    setFormData(row?.payload_object);
    setEntryState({ entry: row, isOpen: true, action: UPLOAD_ACTION_UPDATE });
  };

  const handleIgnoreRow = (row) => {
    setFormData(row?.payload_object);
    setEntryState({ entry: row, isOpen: true, action: UPLOAD_ACTION_IGNORE });
  };

  const handleIgnoreObject = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    ignoreUploadEntry({ id: entry.id, model_type })
      .then(({ payload }) => {
        updateUploadEntryObjectTSQ({ object: payload });
        setEntryState(emptyEntryState);
        enqueueSnackbar({
          variant: "success",
          message: "Entry successfully ignored",
        });
      })
      .catch(({ payload }) =>
        enqueueSnackbar({ variant: "error", message: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  const handleUpdateObject = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    updateUploadEntry({
      data: {
        id: entry.id,
        raw_json: formatUploadRawJson({
          rawJson: formData,
          model_type,
          bundle_type,
          organization__slug,
          isSingleton: true,
        }),
      },
      model_type,
    })
      .then(({ payload }) => {
        updateUploadEntryObjectTSQ({ object: payload });
        setEntryState(emptyEntryState);
        enqueueSnackbar({
          variant: "success",
          message: "Entry successfully updated",
        });
      })
      .catch(({ payload }) =>
        enqueueSnackbar({ variant: "error", message: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  const MemoizedContentComponent = React.useMemo(
    () =>
      getConfigurationsBulkUploadEntryComponent({
        model_type,
        use_processing_status,
      }),
    [model_type, use_processing_status]
  );

  const memoizedSummaryDialogProps = React.useMemo(
    () =>
      getConfigurationsBulkUploadEntryDialogProps({
        model_type,
        use_processing_status,
        handleCloseObject,
      }),
    [model_type, use_processing_status]
  );

  const contentComponentProps = use_processing_status
    ? { object: formData }
    : { bundle_type, is_processor_tpa, formData, setFormData };

  const useQueryFnProps = {
    model_type,
    filters: {
      upload__id,
      newer_version__isnull: true,
      ...(use_processing_status
        ? { processing_status: statusTab }
        : { preprocessing_status: statusTab }),
    },
  };

  const dataGridProps = React.useMemo(
    () =>
      ConfigurationsBulkUploadEntriesGridProps({
        model_type,
        use_processing_status,
        handleEditRow,
        handleIgnoreRow,
      }),
    [model_type, use_processing_status]
  );

  return (
    <>
      <Stack spacing={2}>
        <ConfigurationsBulkUploadStatusFilters
          object={object}
          setStatusTab={setStatusTab}
        />
        <CustomInfiniteDataGrid
          dataGridProps={dataGridProps}
          useQueryFn={useQueryFetchUploadEntries}
          useQueryFnProps={useQueryFnProps}
          initialSortModel={initialSortModel}
        />
      </Stack>
      <CustomSummaryDialog
        isOpen={isOpen && isUpdateAction}
        summaries={summaries}
        exceptions={exceptions}
        isProcessing={isProcessing}
        handleClose={handleCloseObject}
        handleSubmit={handleUpdateObject}
        ContentComponent={MemoizedContentComponent}
        contentComponentProps={contentComponentProps}
        summaryDialogProps={memoizedSummaryDialogProps}
      />
      <CustomActionDialog
        isOpen={isOpen && isIgnoreAction}
        isProcessing={isProcessing}
        handleClose={handleCloseObject}
        handleSubmit={handleIgnoreObject}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
        titleComponentProps={{ title: "Ignore entry" }}
        subtitleComponentProps={{
          title: "Are you sure you want to ignore this entry?",
        }}
        yesText="Yes, ignore"
        noText="No, cancel"
        isBodyEmpty={true}
      />
    </>
  );
}
