import { AmexCard, MasterCard, UnknownCard, VisaCard } from "@rimads/assets";
import {
  PAYMENT_CARD_TYPE_AMEX,
  PAYMENT_CARD_TYPE_MASTERCARD,
  PAYMENT_CARD_TYPE_OTHER,
  PAYMENT_CARD_TYPE_VISA,
} from "@constants/payments/payments";

export const PAYMENT_CARD_TYPE_MAPPER = {
  [PAYMENT_CARD_TYPE_MASTERCARD]: <MasterCard dimension={22} />,
  [PAYMENT_CARD_TYPE_VISA]: <VisaCard dimension={32} />,
  [PAYMENT_CARD_TYPE_AMEX]: <AmexCard dimension={32} />,
  [PAYMENT_CARD_TYPE_OTHER]: <UnknownCard dimension={22} />,
};

export default function CustomPaymentCardLogo({ card_type }) {
  const CardComponent = PAYMENT_CARD_TYPE_MAPPER[card_type];
  return (
    <div
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        height: 40,
        minWidth: 40,
      }}
    >
      {CardComponent}
    </div>
  );
}
