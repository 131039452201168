import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomDeactivateObject from "@components/Custom/CustomDeactivateObject";
import {
  deleteOrganizationPatient,
  invalidateOrganizationPatientsTSQ,
  updateOrganizationPatientObjectTSQ,
} from "@redux/actions/organizations/organization-patients";

export default function OrganizationPatientActivateForm({ object }) {
  const handleDeleteFn = ({ payload }) => {
    invalidateOrganizationPatientsTSQ();
    updateOrganizationPatientObjectTSQ({ object: payload });
  };
  return (
    <CustomDeactivateObject
      object={object}
      label="patient"
      deleteFn={deleteOrganizationPatient}
      handleDeleteFn={handleDeleteFn}
      TitleComponent={InsuranceOrganizationActionDialogTitle}
    />
  );
}
