import React from "react";
import Typography from "@mui/material/Typography";

export default function CustomTimeElapsed({
  startTime,
  endTime,
  typographyProps = {},
}) {
  const [elapsedTime, setElapsedTime] = React.useState("00:00");

  React.useEffect(() => {
    if (Boolean(startTime) && !Boolean(endTime)) {
      const interval = setInterval(() => {
        const now = new Date();
        const elapsed = Math.floor((now - new Date(startTime)) / 1000);
        const minutes = Math.floor(elapsed / 60)
          .toString()
          .padStart(2, "0");
        const seconds = (elapsed % 60).toString().padStart(2, "0");
        setElapsedTime(`${minutes}:${seconds}`);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [startTime, endTime]);

  return (
    <Typography variant="body2" {...typographyProps}>
      {elapsedTime}
    </Typography>
  );
}
