import React from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { EyeFilledCrossedIcon, EyeFilledIcon } from "@rimads/assets";
import { AVEY_GREY_DARK } from "@styles/theme";
import { CustomOutlinedTextField } from "./CustomOutlinedField";

export default function CustomPasswordTextField(props) {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClick = () => setShowPassword(!showPassword);
  const type = showPassword ? "text" : "password";

  return (
    <CustomOutlinedTextField
      type={type}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClick}>
              {showPassword ? (
                <EyeFilledIcon dimension={18} primary_fill={AVEY_GREY_DARK} />
              ) : (
                <EyeFilledCrossedIcon
                  dimension={18}
                  primary_fill={AVEY_GREY_DARK}
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}
