import React from "react";
import CustomSummaryCards from "@components/Custom/CustomSummaryCards";
import { TOKEN_SHORT_NAME_PLURAL } from "@constants/static/payment";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { getOrganizationPaymentCycleConsumption } from "@helpers/parse/tokens";
import { selectorAuthBillingConversion } from "@redux/selectors/auth";
import { useSelector } from "react-redux";

export default function OrganizationPaymentCycleSummaries({ object }) {
  const billing_conversion = useSelector(selectorAuthBillingConversion);
  const {
    payment_plan_type,
    num_tokens_consumed = 0,
    num_claims_processed = 0,
    num_tokens = 0,
    num_tokens_initial = 0,
    pay_as_you_go_rate_per_token,
    pay_as_you_go_usage_token_threshold,
  } = object;

  const {
    amountNumerator,
    amountDenominator,
    balanceNumerator,
    balanceDenominator,
  } = getOrganizationPaymentCycleConsumption({
    num_tokens,
    num_tokens_consumed,
    pay_as_you_go_rate_per_token,
    pay_as_you_go_usage_token_threshold,
    payment_plan_type,
    billing_conversion,
  });

  const summaries = [
    {
      title: `Consumed ${TOKEN_SHORT_NAME_PLURAL}`,
      primary: balanceNumerator,
      secondary: balanceDenominator,
    },
    {
      title: "Amount due",
      primary: amountNumerator,
      secondary: amountDenominator,
      predicate: Boolean(amountNumerator),
    },
    {
      title: `Initial ${TOKEN_SHORT_NAME_PLURAL}`,
      primary: getIntOrFloat(num_tokens_initial),
      predicate: Boolean(num_tokens_initial),
    },
    {
      title: "Processed claims",
      primary: getIntOrFloat(num_claims_processed || "0"),
    },
  ].filter(({ predicate = true }) => predicate);

  if (summaries.length === 0) return null;

  return <CustomSummaryCards summaries={summaries} />;
}
