import React from "react";
import { parseTimestamp } from "@helpers/parse/time";
import ListItemButton from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AVEY_GREY_F2 } from "@styles/theme";
import CustomVersionTag from "../CustomVersionTag";

export default function CustomVersionHistoryObject({
  object,
  selectedVersion,
  handleChangeVersion = () => {},
  VersionHistoryObjectTabComponent = () => {},
}) {
  const { id, version, time_updated } = object;
  const timestamp = parseTimestamp({ value: time_updated });
  const isSelected = selectedVersion?.id === id;
  const handleClick = () => handleChangeVersion(object);
  const style = isSelected ? { backgroundColor: AVEY_GREY_F2 } : {};

  return (
    <ListItemButton onClick={handleClick} style={style}>
      <Stack spacing={1} py={1} width="100%">
        <Stack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <VersionHistoryObjectTabComponent object={object} />
          <CustomVersionTag version={version} />
        </Stack>
        <Typography variant="body2" textAlign="end" fontWeight="light">
          {timestamp}
        </Typography>
      </Stack>
    </ListItemButton>
  );
}
