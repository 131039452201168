import React from "react";
import HealthcareOrganizationConfigurations from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations";
import Consumable from "@components/Authenticated/Configurations/Consumables/Consumable/Consumable";
import Cpt from "@components/Authenticated/Configurations/Cpts/Cpt";
import Icd from "@components/Authenticated/Configurations/Icds/Icd";
import Medication from "@components/Authenticated/Configurations/Medications/Medication/Medication";
import OrganizationClinician from "@components/Authenticated/Configurations/OrganizationClinicians/OrganizationClinician/OrganizationClinician";
import {
  MODEL_TYPE_CONSUMABLE,
  MODEL_TYPE_MEDICATION,
  MODEL_TYPE_ORGANIZATION_CLINICIAN,
} from "@constants/static/globals";
import {
  CONSUMABLE_UPLOADS_HREF,
  CONSUMABLES_HREF,
  CPTS_HREF,
  ICDS_HREF,
  MEDICATION_UPLOADS_HREF,
  MEDICATIONS_HREF,
  ORGANIZATION_CLINICIAN_UPLOADS_HREF,
  ORGANIZATION_CLINICIANS_HREF,
} from "@constants/static/routing";
import { Navigate, Route, Routes } from "react-router-dom";
import UploadRoutes from "../upload-routes";

export default function HealthcareConfigurationsRoutes() {
  return (
    <Routes>
      <Route path="" Component={HealthcareOrganizationConfigurations} />
      <Route path={`${CPTS_HREF}/:cpt__id/*`} Component={Cpt} />
      <Route path={`${ICDS_HREF}/:icd__id/*`} Component={Icd} />

      {/* CONSUMABLES */}
      <Route
        path={`${CONSUMABLES_HREF}/:consumable__id/*`}
        Component={Consumable}
      />
      <Route
        path={`${CONSUMABLE_UPLOADS_HREF}/*`}
        element={<UploadRoutes model_type={MODEL_TYPE_CONSUMABLE} />}
      />

      {/* MEDICATIONS */}
      <Route
        path={`${MEDICATIONS_HREF}/:medication__id/*`}
        Component={Medication}
      />
      <Route
        path={`${MEDICATION_UPLOADS_HREF}/*`}
        element={<UploadRoutes model_type={MODEL_TYPE_MEDICATION} />}
      />

      {/* ORGANIZATION CLINICIANS */}
      <Route
        path={`${ORGANIZATION_CLINICIANS_HREF}/:organization_clinician__id/*`}
        Component={OrganizationClinician}
      />
      <Route
        path={`${ORGANIZATION_CLINICIAN_UPLOADS_HREF}/*`}
        element={
          <UploadRoutes model_type={MODEL_TYPE_ORGANIZATION_CLINICIAN} />
        }
      />

      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
