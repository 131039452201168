import React from "react";
import CustomActionDialog from ".";
import CustomBulletPoints from "../CustomBulletPoints";
import CustomSection from "../CustomSection";

export const initialSummaryState = {
  isOpen: false,
  summaries: [],
};

export function CustomSummaryDialog({
  isOpen = false,
  summaries = [],
  exceptions = [],
  isProcessing = false,
  setSummaryState = () => {},
  handleSubmit = () => {},
  handleClose = undefined,
  ContentComponent = () => {},
  contentComponentProps = {},
  summaryDialogProps = {},
  TitleComponent = undefined,
  titleComponentProps = { title: "Review & Confirm" },
}) {
  const hasSummary = summaries?.length > 0;
  const hasExceptions = exceptions?.length > 0;

  const handleCloseSummary = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    Boolean(handleClose)
      ? handleClose()
      : setSummaryState((prev) => ({ ...prev, isOpen: false }));
  };

  if (!isOpen) return null;

  return (
    <CustomActionDialog
      isOpen={isOpen}
      isProcessing={isProcessing}
      handleClose={handleCloseSummary}
      handleSubmit={handleSubmit}
      TitleComponent={TitleComponent}
      titleComponentProps={titleComponentProps}
      {...summaryDialogProps}
    >
      {hasExceptions && (
        <CustomSection color="red" isMinified={true} title="Errors">
          <CustomBulletPoints bulletpoints={exceptions} />
        </CustomSection>
      )}
      {hasSummary && (
        <CustomSection color="yellow" isMinified={true} title="Action summary">
          <CustomBulletPoints bulletpoints={summaries} />
        </CustomSection>
      )}
      <div>
        <ContentComponent {...contentComponentProps} />
      </div>
    </CustomActionDialog>
  );
}
