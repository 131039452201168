import React from "react";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import InsuranceOrganizationConfigurationsBody from "./InsuranceOrganizationConfigurationsBody";
import InsuranceOrganizationConfigurationsHeader from "./InsuranceOrganizationConfigurationsHeader";

export default function InsuranceOrganizationsConfigurations() {
  return (
    <>
      <InsuranceOrganizationConfigurationsHeader />
      <CustomPage mt={0}>
        <InsuranceOrganizationConfigurationsBody />
      </CustomPage>
    </>
  );
}
