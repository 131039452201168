import { EES_ENABLED } from "@constants/static/globals";
import {
  AveyBloomApi,
  PayloadError,
  ServerApi,
  apiWrapper,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedEesResponse,
  parsePaginatedResponse,
} from "@helpers/api";
import {
  invalidateTSQ,
  updateObjectTSQ,
  updatePaginatedTSQ,
  useQueryWrapper,
} from "@helpers/tanstack";

const endpointUrl = "/organizations/organization_clinicians/";
const exportEndpointUrl = "/organizations/organization_clinician_exports/";
const eesEndpointUrl = "/api/ees/organization-clinicians";
const queryKeySingular = "organization_clinician";
const queryKeyExports = `${queryKeySingular}_exports`;
const queryKeyPlural = "organization_clinicians";
const queryKeySingularHistory = `${queryKeySingular}_history`;

export const useQueryFetchOrganizationClinicians = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = false,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchOrganizationClinicians,
        ...apiWrapperProps,
      })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 300_000,
    ...reactQueryProps,
  });
};

async function fetchOrganizationClinicians({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    if (EES_ENABLED) {
      const response = await ServerApi().post(eesEndpointUrl, {
        page,
        page_size,
        search: searchQuery,
        ordering: sortQuery,
        filters,
      });
      return parsePaginatedEesResponse(response.data);
    } else {
      const queryParams = getQueryParams({
        page,
        page_size,
        search: searchQuery,
        ordering: sortQuery,
        ...filters,
      });
      const response = await AveyBloomApi().get(
        `${endpointUrl}?${queryParams}`
      );
      return parsePaginatedResponse(response.data);
    }
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchOrganizationClinicianExports = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [
      queryKeyExports,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchOrganizationClinicianExports, ...apiWrapperProps })(
        {
          page: useInfiniteWrapper ? pageParam : page,
          page_size,
          searchQuery,
          sortQuery,
          filters,
        }
      ),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchOrganizationClinicianExports({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(
      `${exportEndpointUrl}?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchOrganizationClinicianHistory = ({
  id,
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [queryKeySingularHistory, { id, filters }],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchOrganizationClinicianHistory,
        ...apiWrapperProps,
      })({ page: pageParam, id, filters }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchOrganizationClinicianHistory({
  page = 1,
  id,
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({ page, ...filters });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${id}/history/?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchOrganizationClinician = ({
  organization_clinician__id = "",
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeySingular, { id: organization_clinician__id }],
    queryFn: () =>
      apiWrapper({
        fn: fetchOrganizationClinician,
        ...apiWrapperProps,
      })({ organization_clinician__id }),
    staleTime: 300_000,
    cacheTime: 30_000,
    ...reactQueryProps,
  });
};

async function fetchOrganizationClinician({ organization_clinician__id = "" }) {
  try {
    const response = await AveyBloomApi().get(
      `${endpointUrl}${organization_clinician__id}/`
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function createOrganizationClinician(data) {
  try {
    const response = await AveyBloomApi().post(endpointUrl, data);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function updateOrganizationClinician(data) {
  try {
    const response = await AveyBloomApi().put(
      `${endpointUrl}${data.id}/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function deleteOrganizationClinician(data) {
  try {
    const response = await AveyBloomApi().post(
      `${endpointUrl}${data.id}/activate/`,
      data
    );

    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function uploadOrganizationClinicians(data) {
  try {
    const response = await AveyBloomApi().post(`${endpointUrl}upload/`, data);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function exportOrganizationClinicians({
  data = {},
  searchQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({ search: searchQuery, ...filters });
    const response = await AveyBloomApi().post(
      `${endpointUrl}export/?${queryParams}`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export function updateOrganizationClinicianObjectTSQ({ object }) {
  updateObjectTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeySingular && queryKey[1].id === object.id,
    object,
  });
}

export function invalidateOrganizationCliniciansTSQ() {
  invalidateTSQ({
    predicate: ({ queryKey }) =>
      [queryKeySingular, queryKeyPlural, queryKeySingularHistory].includes(
        queryKey[0]
      ),
  });
}

export function updateOrganizationClinicianExportObjectTSQ({ object }) {
  updatePaginatedTSQ({
    predicate: ({ queryKey }) => queryKey[0] === queryKeyExports,
    object,
  });
}
