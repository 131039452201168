import React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CustomTooltip from "@components/Custom/CustomTooltip";
import {
  SYMPTOM_STATUS_ICON_MAPPER,
  SYMPTOM_STATUS_PRESENT,
  SYMPTOM_STATUS_PRESENT_NEXT_STATUS_MAPPER,
  SYMPTOM_STATUS_TOOLTIP_MAPPER,
  SYMPTOM_TYPE_SUBJECTIVE,
} from "@constants/avey-cowriter";
import { CloseIcon } from "@rimads/assets";
import { TypeAnimation } from "react-type-animation";

export default function ClaimDemoSymptom({
  type,
  object,
  handleRemoveSymptom = () => {},
  handleUpdateSymptom = () => {},
}) {
  const { _id, name, status, is_chief, is_searchable } = object;
  const isSubjective = type === SYMPTOM_TYPE_SUBJECTIVE;
  const isPresent = status === SYMPTOM_STATUS_PRESENT;
  const handleRemove = () => handleRemoveSymptom(_id);

  const isChiefTogglable = isSubjective && isPresent && is_searchable;

  const handleChangeIsChief = () =>
    isChiefTogglable && handleUpdateSymptom({ ...object, is_chief: !is_chief });

  const handleChangeStatus = () =>
    handleUpdateSymptom({
      ...object,
      status: SYMPTOM_STATUS_PRESENT_NEXT_STATUS_MAPPER[status],
      is_chief: false,
    });

  return (
    <Stack direction="row" alignItems="center" className="hoverOpacityParent">
      <CustomTooltip
        title={SYMPTOM_STATUS_TOOLTIP_MAPPER[status]}
        placement="left"
      >
        <IconButton
          style={{ transform: "translateX(-8px)" }}
          onClick={handleChangeStatus}
        >
          {SYMPTOM_STATUS_ICON_MAPPER[status]}
        </IconButton>
      </CustomTooltip>
      <div
        style={{ flexGrow: 1 }}
        className={isChiefTogglable ? "button opacityOnHover-50" : ""}
        onClick={handleChangeIsChief}
      >
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography title={name} variant="body2">
            <TypeAnimation
              key={name}
              sequence={[name]}
              wrapper="span"
              speed={50}
              repeat={false}
              cursor={false}
            />
          </Typography>
          {is_chief && (
            <Typography
              variant="caption"
              px={2}
              py={0.5}
              className="greyBgF9 borderRadius-8"
            >
              {"Chief"}
            </Typography>
          )}
        </Stack>
      </div>
      <IconButton
        className="opacity-0 hoverOpacityChild"
        onClick={handleRemove}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );
}
