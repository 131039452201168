import React from "react";
import ReactDOM from "react-dom";
import Index from "./pages";
import "@styles/index.css";
import { LicenseInfo } from "@mui/x-license-pro";
import { MUI_LICENSE_KEY } from "@constants/static/globals";

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById("root")
);
