import React from "react";
import CustomSection, {
  customSectionMinifiedBodyPadding,
} from "@components/Custom/CustomSection";
import CustomThickDivider from "@components/Custom/CustomThickDivider";
import { CLAIM_SECTION_PROCEDURES_TITLE } from "@constants/claims/claims";
import Box from "@mui/material/Box";
import ClaimProcedureData from "./ClaimProcedureData";

export default function ClaimProceduresReviewAndConfirm({
  claim_procedures = [],
}) {
  if (claim_procedures.length === 0) return null;

  return (
    <CustomSection
      title={CLAIM_SECTION_PROCEDURES_TITLE}
      isMinified={true}
      bodyProps={{ p: 0 }}
    >
      {claim_procedures.map((object, index) => (
        <React.Fragment key={index}>
          {index !== 0 && <CustomThickDivider />}
          <Box p={customSectionMinifiedBodyPadding}>
            <ClaimProcedureData object={object} useErrorFieldnames={false} />
          </Box>
        </React.Fragment>
      ))}
    </CustomSection>
  );
}
