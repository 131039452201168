import React from "react";
import CustomSearchField from "./CustomSearchField";
import TextField from "@mui/material/TextField";

export default function CustomContextSearchField({
  placeholder = "Search",
  setSearchQuery = () => {},
  useDefaultTextField = false,
  debounceDuration = 250,
  className = "",
  ...props
}) {
  const isInitialMount = React.useRef(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    setIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      setSearchQuery(value);
      setIsLoading(false);
    }, debounceDuration);
    return () => {
      setIsLoading(false);
      clearTimeout(delayDebounceFn);
    };
  }, [value, debounceDuration, setSearchQuery]);

  return useDefaultTextField ? (
    <TextField
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className={className}
      {...props}
    />
  ) : (
    <CustomSearchField
      placeholder={placeholder}
      value={value}
      setValue={setValue}
      isLoading={isLoading}
      className={className}
    />
  );
}
