import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PublishIcon from "@mui/icons-material/Publish";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import Box from "@mui/material/Box";
import {
  ArrowCenterLeftIcon,
  ArrowCenterRightIcon,
  ArrowDownIcon,
  BoostIcon,
  CloseIcon,
  PlusIcon,
  RadioButtonSelectedIcon,
  SendBackIcon,
  TickIcon,
  DeleteIcon,
} from "@rimads/assets";
import { AVEY_BLACK, AVEY_GREY, AVEY_WHITE } from "@styles/theme";

const RimadsIconMapper = {
  BoostIcon,
  CloseIcon,
  PlusIcon,
  RadioButtonSelectedIcon,
  SendBackIcon,
  TickIcon,
  ArrowCenterLeftIcon,
  ArrowCenterRightIcon,
  ArrowDownIcon,
  DeleteIcon,
};

const MaterialUiIconMapper = {
  DownloadIcon,
  EditIcon,
  MoreHorizIcon,
  PublishIcon,
  SettingsIcon: SettingsOutlinedIcon,
  TuneIcon,
};

const RIMADS_ICON_DIMENSION = 12;
const MUI_ICON_DIMENSION = 16;

const getPrimaryFill = ({ variant, disabled }) => {
  switch (variant) {
    case "outlined":
      return disabled ? AVEY_GREY : AVEY_BLACK;

    case "contained":
    default:
      return AVEY_WHITE;
  }
};

export default function CustomIconComponent({
  icon,
  variant,
  disabled,
  iconProps = {},
  iconWrapperProps = {},
}) {
  const primary_fill = getPrimaryFill({ variant, disabled });
  const colorProps = disabled ? { color: "disabled" } : {};
  const RimadsIconComponent = RimadsIconMapper[icon];
  const MaterialUiIconComponent = MaterialUiIconMapper[icon];

  if (RimadsIconComponent)
    return (
      <Box display="grid" alignItems="center" {...iconWrapperProps}>
        <RimadsIconComponent
          primary_fill={primary_fill}
          dimension={RIMADS_ICON_DIMENSION}
          {...iconProps}
        />
      </Box>
    );

  if (MaterialUiIconComponent)
    return (
      <Box display="grid" alignItems="center" {...iconWrapperProps}>
        <MaterialUiIconComponent
          style={{ height: MUI_ICON_DIMENSION, width: MUI_ICON_DIMENSION }}
          {...colorProps}
          {...iconProps}
        />
      </Box>
    );

  return null;
}
