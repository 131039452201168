import React from "react";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import { MODEL_TYPE_ORGANIZATION_CLINICIAN } from "@constants/static/globals";
import { getOrganizationClinicianUploadsHref } from "@constants/static/routing";
import OrganizationClinicianCreateForm from "../OrganizationClinician/OrganizationClinicianForms/OrganizationClinicianCreateForm";

export default function OrganizationCliniciansCreateForms() {
  const uploadHistoryHref = getOrganizationClinicianUploadsHref({});
  return (
    <CustomAddOrUploadWrapper
      label="Add clinicians"
      uploadHistoryHref={uploadHistoryHref}
      model_type={MODEL_TYPE_ORGANIZATION_CLINICIAN}
    >
      <OrganizationClinicianCreateForm isTypographyButton={false} />
    </CustomAddOrUploadWrapper>
  );
}
