import React from "react";
import { useSelector } from "react-redux";
import { selectorAuthCurrentUserOrganizationRole } from "@redux/selectors/auth";
import UserManagementReassignClaimSubmissionFormData from "./UserManagementReassignClaimSubmissionFormData";

export default function UserManagementReassignClaimSubmissionsForm({
  reassignments = [],
  setReassignments = () => {},
  user = {},
}) {
  const organizationRole = useSelector(selectorAuthCurrentUserOrganizationRole);
  const handleChangeReassignment = ({ payload, index }) => {
    const newReassignments = reassignments?.map((reassignment, position) =>
      index === position ? { ...reassignment, user: payload } : reassignment
    );
    setReassignments(newReassignments);
  };

  return reassignments?.map((reassignment, index) => (
    <UserManagementReassignClaimSubmissionFormData
      key={index}
      reassignment={reassignment}
      handleChange={(payload) => handleChangeReassignment({ payload, index })}
      organizationRole={organizationRole}
      from_user={user}
    />
  ));
}
