import {
  UPLOAD_STATUS_COMMITTED,
  UPLOAD_STATUS_COMMITTING,
  UPLOAD_STATUS_FAILURE,
  UPLOAD_STATUS_PENDING,
  UPLOAD_STATUS_SUCCESS,
} from "@constants/static/upload";

export const getBulkUploadSnackbarKey = ({ id, status, model_type }) =>
  `${model_type}-${id}-${status}`;

export const parseBulkUploadSnackbar = (upload_object) => {
  const { id, status, model_type } = upload_object;
  const key = getBulkUploadSnackbarKey({ id, status, model_type });
  const context = getSnackbarContext(upload_object);
  const variant = "CustomSnackbar";
  const anchorOrigin = { vertical: "bottom", horizontal: "right" };

  return { key, variant, context, anchorOrigin, persist: true };
};

const getSnackbarContext = (object) => {
  const {
    id,
    status,
    name,
    submitter_organization = {},
    processor_organization = {},
    exception_str,
    model_type,
  } = object;
  const image = processor_organization?.image || submitter_organization?.image;
  const key = getBulkUploadSnackbarKey({ id, status, model_type });
  const context = { key, image };

  switch (status) {
    case UPLOAD_STATUS_PENDING:
      return {
        ...context,
        isLoadingVariant: true,
        title: (
          <>
            {"Validating "}
            <b>{name}</b>
          </>
        ),
      };

    case UPLOAD_STATUS_FAILURE:
      return {
        ...context,
        isErrorVariant: true,
        title: (
          <>
            <b>{name}</b> {" could not be validated"}
          </>
        ),
        subtitle: exception_str,
      };

    case UPLOAD_STATUS_SUCCESS:
      return {
        ...context,
        isSuccessVariant: true,
        title: (
          <>
            <b>{name}</b>
            {" successfully validated"}
          </>
        ),
      };

    case UPLOAD_STATUS_COMMITTING:
      return {
        ...context,
        isLoadingVariant: true,
        title: (
          <>
            {"Applying entries for "}
            <b>{name}</b>
          </>
        ),
      };

    case UPLOAD_STATUS_COMMITTED:
      return {
        ...context,
        isSuccessVariant: true,
        title: (
          <>
            <b>{name}</b>
            {" successfully applied"}
          </>
        ),
      };

    default:
      return context;
  }
};
