import React from "react";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import useFetchOrganization from "@helpers/hooks/useFetchObjects/useFetchOrganization";
import Stack from "@mui/material/Stack";
import { selectorAuthIsSubmitterOrganization } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import OrganizationAgreementSummary from "../../../Configurations/OrganizationAgreements/OrganizationAgreement/OrganizationAgreement/OrganizationAgreementSummary";
import ClaimSubmissionsGrid from "./ClaimSubmissionsGrid";
import ClaimSubmissionsHeader from "./ClaimSubmissionsHeader";

export default function ClaimSubmissions() {
  const { isLoading, data, hasData } = useFetchOrganization();
  const isSubmitterOrganization = useSelector(
    selectorAuthIsSubmitterOrganization
  );

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  return (
    <>
      <ClaimSubmissionsHeader object={data} />
      <CustomPage mt={0}>
        <Stack spacing={2}>
          {isSubmitterOrganization && <OrganizationAgreementSummary />}
          <ClaimSubmissionsGrid />
        </Stack>
      </CustomPage>
    </>
  );
}
