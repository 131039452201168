import React from "react";
import CustomOption from "@components/Custom/CustomOption";
import { TOKEN_SHORT_NAME_SINGULAR } from "@constants/static/payment";
import { tokensToMoney } from "@helpers/parse/tokens";
import pluralize from "pluralize";

export default function RechargeTokenPackOption({
  recharge_token_pack,
  billing_conversion,
  isChecked = () => {},
  handleChange = () => {},
}) {
  const { id, name, num_tokens, rate_per_token } = recharge_token_pack;
  const checked = isChecked(id);
  const handleClick = () => handleChange(recharge_token_pack);

  return (
    <CustomOption
      title={name}
      subtitle={
        <>
          {tokensToMoney({ num_tokens, rate_per_token, billing_conversion })}
          <br />
          {pluralize(TOKEN_SHORT_NAME_SINGULAR, num_tokens, true)}
        </>
      }
      checked={checked}
      handleClick={handleClick}
    />
  );
}
