import { DISABLED_ROW_CLASSNAME } from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { getConsumablesHref } from "@constants/static/routing";
import { parseTimeSince } from "@helpers/parse/time";

export default function ConsumablesGridProps({ hiddenColumns = [] }) {
  return {
    columns: [
      {
        flex: 1,
        field: "code",
        headerName: "Code",
        valueGetter: ({ value }) => value || "-",
      },
      {
        flex: 1,
        field: "name",
        headerName: "Name",
      },
      {
        flex: 1,
        field: "description",
        headerName: "Description",
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ].filter(({ field }) => !hiddenColumns.includes(field)),
    getRowClassName: ({ row }) => row?.time_deleted && DISABLED_ROW_CLASSNAME,
    disableRowSelectionOnClick: true,
    navigateTo: ({ id }) => getConsumablesHref({ consumable__id: id }),
  };
}
