import React from "react";
import { useQueryFetchOrganizationPatients } from "@redux/actions/organizations/organization-patients";
import { selectorAuthCurrentUserOrganizationSlug } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import {
  getOrganizationPatientOptionLabel,
  isOrganizationPatientOptionEqualToValue,
  renderOrganizationPatientOption,
} from "./OrganizationPatientAutocompleteComponent";

export default function OrganizationPatientAutocomplete({
  value = null,
  handleChange = () => {},
}) {
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const { organization__slug: processor_organization__slug } = useParams();
  const useQueryFnProps = {
    filters: {
      time_deleted__isnull: true,
      newer_version__isnull: true,
      submitter_organization__slug,
      processor_organization__slug,
    },
    useInfiniteWrapper: true,
  };
  return (
    <CustomInfiniteAutocomplete
      autocompleteProps={{ renderOption: renderOrganizationPatientOption }}
      useQueryFn={useQueryFetchOrganizationPatients}
      useQueryFnProps={useQueryFnProps}
      getOptionLabel={getOrganizationPatientOptionLabel}
      isOptionEqualToValue={isOrganizationPatientOptionEqualToValue}
      value={value}
      handleChange={handleChange}
    />
  );
}
