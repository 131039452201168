import React from "react";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { PAYMENT_CYCLES_TITLE } from "@constants/static/routing";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useQueryFetchOrganizationPaymentCycles } from "@redux/actions/payments/organization-payment-cycles";
import OrganizationPaymentCyclesGridProps from "./OrganizationPaymentCyclesGridProps";

const initialSortModel = [{ sort: "desc", field: "start_date" }];

export default function OrganizationPaymentCyclesGrid() {
  const useQueryFnProps = { filters: { newer_version__isnull: true } };

  return (
    <Stack spacing={2}>
      <Typography fontWeight="bold">{PAYMENT_CYCLES_TITLE}</Typography>
      <CustomInfiniteDataGrid
        useQueryFn={useQueryFetchOrganizationPaymentCycles}
        useQueryFnProps={useQueryFnProps}
        dataGridProps={OrganizationPaymentCyclesGridProps}
        initialSortModel={initialSortModel}
      />
    </Stack>
  );
}
