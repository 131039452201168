import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomBox from "../CustomBox";
import { Droppable } from "react-beautiful-dnd";

export default function CustomDndList({ droppableId, title = "", children }) {
  return (
    <Stack spacing={2}>
      {Boolean(title) && (
        <Typography variant="body2" color="textSecondary">
          {title}
        </Typography>
      )}
      <Droppable droppableId={droppableId?.toString()}>
        {(provided) => (
          <CustomBox
            className="button"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {children}
            {provided.placeholder}
          </CustomBox>
        )}
      </Droppable>
    </Stack>
  );
}
