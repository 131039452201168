import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { ReactQueryClient } from "../../pages";

const useInfiniteQueryWrapper = (props) =>
  useInfiniteQuery({
    keepPreviousData: true,
    getNextPageParam: (lastPage) =>
      lastPage?.next ? lastPage?.page + 1 : undefined,
    ...props,
  });

export const useQueryWrapper = (props) => {
  const { useInfiniteWrapper = false, ...otherProps } = props;
  const queryWrapper = useInfiniteWrapper ? useInfiniteQueryWrapper : useQuery;
  return queryWrapper(otherProps);
};

export function updateObjectTSQ({
  predicate = () => false,
  object,
  newQueryKey = null,
  enforceSequentiality = true,
}) {
  ReactQueryClient.getQueryCache()
    .findAll({
      predicate: ({ queryKey }) =>
        Array.isArray(queryKey) && predicate({ queryKey }),
    })
    .forEach((query) => {
      const queryKey = query.queryKey;
      const currentData = query.state.data;

      if (
        enforceSequentiality &&
        new Date(currentData?.time_updated) > new Date(object?.time_updated)
      )
        return;

      ReactQueryClient.setQueryData(queryKey, object);
    });

  if (Boolean(newQueryKey)) {
    invalidateTSQ({
      predicate: ({ queryKey }) =>
        Array.isArray(queryKey) && predicate({ queryKey }),
    });
    ReactQueryClient.setQueryData(newQueryKey, object);
  }
}

export function updatePaginatedTSQ({
  object,
  predicate = () => false,
  isObjectPredicate = undefined,
  doInsertPredicate = () => true,
  doUpdatePredicate = () => true,
  doRemovePredicate = () => false,
  enforceSequentiality = true,
}) {
  const isOptionPredicate = ({ object, result }) =>
    isObjectPredicate
      ? isObjectPredicate({ object, result })
      : object.id === result?.id;

  const handleUpdateResults = (results, queryKey) => {
    let objectExists = false;

    const updatedResults = (results || [])
      ?.map((result) => {
        if (isOptionPredicate({ object, result })) {
          objectExists = true;

          if (
            enforceSequentiality &&
            new Date(result?.time_updated) > new Date(object?.time_updated)
          ) {
            return result;
          }

          if (
            doRemovePredicate({
              queryKey,
              newObject: object,
              oldObject: result,
            })
          ) {
            return null;
          }

          if (
            doUpdatePredicate({
              queryKey,
              newObject: object,
              oldObject: result,
            })
          ) {
            return object;
          }
        }
        return result;
      })
      .filter(Boolean);

    return { updatedResults, objectExists };
  };

  ReactQueryClient.getQueryCache()
    .findAll({
      predicate: ({ queryKey }) =>
        Array.isArray(queryKey) && predicate({ queryKey }),
    })
    .forEach((query) => {
      const queryKey = query.queryKey;
      const currentData = query.state.data;

      if (typeof currentData?.pages === "object") {
        if (currentData?.pages?.length === 0) return;
        const newPages = currentData.pages.map((page) => {
          const { updatedResults, objectExists } = handleUpdateResults(
            page.results,
            queryKey
          );

          if (
            !objectExists &&
            doInsertPredicate({ queryKey, object, pages: currentData.pages })
          ) {
            updatedResults.push(object);
          }

          return { ...page, results: updatedResults };
        });
        ReactQueryClient.setQueryData(queryKey, {
          ...currentData,
          pages: newPages,
        });
      } else if (Boolean(currentData?.results)) {
        const { updatedResults, objectExists } = handleUpdateResults(
          currentData.results,
          queryKey
        );

        if (
          !objectExists &&
          doInsertPredicate({
            queryKey,
            object,
            pages: [{ results: currentData.results }],
          })
        ) {
          updatedResults.push(object);
        }

        ReactQueryClient.setQueryData(queryKey, {
          ...currentData,
          results: updatedResults,
        });
      }
    });
}

export function invalidateTSQ({ predicate = () => false }) {
  ReactQueryClient.invalidateQueries({
    predicate: ({ queryKey }) =>
      Array.isArray(queryKey) && predicate({ queryKey }),
  });
}
