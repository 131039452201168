import {
  AVEY_BLOOM_BACKEND_API_URL,
  DIBSY_VAULT_API_URL,
  DIBSY_VAULT_PUBLIC_KEY,
  SERVER_API_URL,
} from "@constants/static/globals";
import { refreshToken } from "@redux/actions/users/auth";
import { ACCESS_TOKEN_KEY, getAccessTokens } from "@redux/actions/users/tokens";
import axios from "axios";

export const AveyBloomApi = (props = {}) => {
  const { retry = true, headers = {} } = props;
  const { [ACCESS_TOKEN_KEY]: access } = getAccessTokens();

  const apiInstance = axios.create({
    baseURL: AVEY_BLOOM_BACKEND_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(Boolean(access) ? { Authorization: `JWT ${access}` } : {}),
      ...headers,
    },
  });

  apiInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        retry &&
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        try {
          await refreshToken();
          const { [ACCESS_TOKEN_KEY]: newAccess } = getAccessTokens();
          originalRequest.headers["Authorization"] = `JWT ${newAccess}`;
          return apiInstance(originalRequest);
        } catch (refreshError) {
          throw refreshError;
        }
      }
      return Promise.reject(error);
    }
  );

  return apiInstance;
};

export const DibsyApi = () => {
  return axios.create({
    baseURL: DIBSY_VAULT_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${DIBSY_VAULT_PUBLIC_KEY}`,
    },
  });
};

export const ServerApi = (props = {}) => {
  const { headers = {} } = props;
  return axios.create({
    baseURL: SERVER_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
  });
};
