import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import { getEmptyClaimMedicationFormData } from "@constants/claims/claim-medications";
import ClaimMedicationFormData from "./ClaimMedicationFormData";

export default function ClaimMedicationCreateForm({
  handleAddClaimMedication = () => {},
  currency,
  visit_type,
  meta_mds_cols = [],
}) {
  const emptyClaimMedicationFormData = React.useMemo(
    () => getEmptyClaimMedicationFormData({ currency, meta_mds_cols }),
    [currency, meta_mds_cols]
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [formData, setFormData] = React.useState(emptyClaimMedicationFormData);

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    handleAddClaimMedication({ payload: formData });
    setIsOpen(false);
  };

  React.useEffect(() => {
    isOpen && setFormData(emptyClaimMedicationFormData);
  }, [isOpen, emptyClaimMedicationFormData]);

  return (
    <>
      <CustomTypographyButton value="+ Add medication" onClick={handleIsOpen} />
      <CustomActionDialog
        maxWidth="sm"
        isOpen={isOpen}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        titleComponentProps={{ title: "New medication" }}
        useDivider={true}
      >
        <ClaimMedicationFormData
          formData={formData}
          setFormData={setFormData}
          visit_type={visit_type}
        />
      </CustomActionDialog>
    </>
  );
}
