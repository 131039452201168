import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomMoreInformationIcon from "@components/Custom/CustomMoreInformationIcon";
import CustomPopper from "@components/Custom/CustomPopper";
import CustomCircularProgress from "@components/Custom/CustomCircularProgress";

export default function ClaimDemoDiagnosisDisease({
  instanceId,
  diseaseName = "",
  likelihood = null,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleAnchorEl = (e) =>
    setAnchorEl((prev) => (prev ? null : e.currentTarget));

  React.useEffect(() => {
    if (Boolean(anchorEl)) {
      setIsLoading(true);
      const timeout = setTimeout(() => setIsLoading(false), 1_000);
      return () => clearTimeout(timeout);
    }
  }, [anchorEl]);

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography
          variant="body2"
          minHeight={30}
          display="grid"
          alignItems="center"
          noWrap
        >
          {diseaseName}
        </Typography>
        <div onClick={handleAnchorEl} style={{ paddingRight: 12 }}>
          <CustomMoreInformationIcon placement="right">
            {"Preview disease"}
          </CustomMoreInformationIcon>
        </div>
        <CustomPopper
          anchorEl={anchorEl}
          handleAnchorEl={handleAnchorEl}
          cardStyleProps={{ padding: 0, marginBottom: 100 }}
          listProps={{ disablePadding: true }}
          placement="right"
        >
          <iframe
            src={`https://avey.ai/medical-library/${instanceId}`}
            title="Instance page"
            style={{ height: 640, width: 400, border: "none" }}
          />
          <CustomCircularProgress invisible={false} open={isLoading} />
        </CustomPopper>
      </Stack>
      {likelihood && (
        <Typography variant="caption">{`${likelihood} %`}</Typography>
      )}
    </Stack>
  );
}
