import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function SetPasswordFormSuccess({ title }) {
  return (
    <Stack spacing={3}>
      <Typography variant="h5" fontWeight="bold">
        {title}
      </Typography>
      <Typography variant="body2">
        {"We’re redirecting you to the homepage."}
      </Typography>
      <LinearProgress color="success" />
    </Stack>
  );
}
