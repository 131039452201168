import React from "react";
import Stack from "@mui/material/Stack";
import MedicationActiveIngredientsData from "../MedicationData/MedicationActiveIngredientsData";
import MedicationDetailsData from "../MedicationData/MedicationDetailsData";

export default function MedicationData({ object = {}, isMinified = false }) {
  const spacing = isMinified ? 2 : 4;
  const { medication_active_ingredients = [], ...otherData } = object || {};

  return (
    <Stack spacing={spacing}>
      <MedicationDetailsData object={otherData} isMinified={isMinified} />
      <MedicationActiveIngredientsData
        medication_active_ingredients={medication_active_ingredients}
        isMinified={isMinified}
      />
    </Stack>
  );
}

export const MedicationDataReviewAndConfirm = ({ object }) => (
  <MedicationData object={object} isMinified={true} />
);
