import { AveyBloomApi, apiWrapper } from "@helpers/api";
import { useQueryWrapper } from "@helpers/tanstack";

const endpointUrl = "/payments/";
const subscriptionPlanQueryKey = "subscription_plans";
const rechargeTokenPackQueryKey = "recharge_token_packs";

export const useQueryFetchSubscriptionPlans = ({
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [subscriptionPlanQueryKey],
    queryFn: () =>
      apiWrapper({
        fn: fetchSubscriptionPlans,

        ...apiWrapperProps,
      })(),
    staleTime: 300_000,
    cacheTime: 60_000,
    ...reactQueryProps,
  });
};

async function fetchSubscriptionPlans() {
  try {
    const response = await AveyBloomApi().get(
      `${endpointUrl}subscription_plans/`
    );
    return response.data;
  } catch (error) {
    return [];
  }
}

export const useQueryFetchRechargeTokenPacks = ({
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [rechargeTokenPackQueryKey],
    queryFn: () =>
      apiWrapper({
        fn: fetchRechargeTokenPacks,

        ...apiWrapperProps,
      })(),
    staleTime: 300_000,
    cacheTime: 60_000,
    ...reactQueryProps,
  });
};

async function fetchRechargeTokenPacks() {
  try {
    const response = await AveyBloomApi().get(
      `${endpointUrl}recharge_token_packs/`
    );
    return response.data;
  } catch (error) {
    return [];
  }
}
