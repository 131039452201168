import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import { getEmptyClaimProcedureFormData } from "@constants/claims/claim-procedures";
import ClaimProcedureFormData from "./ClaimProcedureFormData";

export default function ClaimProcedureCreateForm({
  handleAddClaimProcedure = () => {},
  currency,
  visit_type,
  meta_mds_cols = [],
}) {
  const emptyClaimProcedureFormData = React.useMemo(
    () => getEmptyClaimProcedureFormData({ currency, meta_mds_cols }),
    [currency, meta_mds_cols]
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [formData, setFormData] = React.useState(emptyClaimProcedureFormData);

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    handleAddClaimProcedure({ payload: formData });
    setIsOpen(false);
  };

  React.useEffect(() => {
    isOpen && setFormData(emptyClaimProcedureFormData);
  }, [isOpen, emptyClaimProcedureFormData]);

  return (
    <>
      <CustomTypographyButton value="+ Add procedure" onClick={handleIsOpen} />
      <CustomActionDialog
        maxWidth="sm"
        isOpen={isOpen}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        titleComponentProps={{ title: "New procedure" }}
        useDivider={true}
      >
        <ClaimProcedureFormData
          formData={formData}
          setFormData={setFormData}
          visit_type={visit_type}
        />
      </CustomActionDialog>
    </>
  );
}
