import React from "react";
import CustomButton from "@components/Custom/CustomButton";
import CustomTooltip from "@components/Custom/CustomTooltip";
import { getClaimHref } from "@constants/static/routing";
import { useURLSearch } from "@helpers/api/getQueryParams";
import Stack from "@mui/material/Stack";
import { AVEY_BLACK, AVEY_GREY } from "@styles/theme";
import { NavLink, useParams } from "react-router-dom";

const ArrowLink = ({ instance_id = null, isPrev = true, urlSearch = "" }) => {
  const { organization__slug, claim_submission__id } = useParams();
  const href = instance_id
    ? getClaimHref({
        organization__slug,
        claim_submission__id,
        claim__id: instance_id,
        urlSearch,
      })
    : null;

  const title = isPrev ? "Previous claim" : "Next claim";
  const icon = isPrev ? "ArrowCenterLeftIcon" : "ArrowCenterRightIcon";

  if (!href)
    return (
      <CustomButton
        isSquare={true}
        useIconAsLabel={true}
        disabled={true}
        color="primary"
        variant="outlined"
        icon={icon}
        iconProps={{ dimension: 12, primary_fill: AVEY_GREY }}
      />
    );

  return (
    <CustomTooltip title={title}>
      <NavLink to={href}>
        <CustomButton
          isSquare={true}
          useIconAsLabel={true}
          color="info"
          variant="contained"
          icon={icon}
          iconProps={{ dimension: 12, primary_fill: AVEY_BLACK }}
        />
      </NavLink>
    </CustomTooltip>
  );
};

export default function ClaimHeaderPrevNextLink({
  prev_instance_id = null,
  next_instance_id = null,
}) {
  const urlSearch = useURLSearch();

  return (
    <Stack direction="row" spacing={2}>
      <ArrowLink
        instance_id={prev_instance_id}
        urlSearch={urlSearch}
        isPrev={true}
      />
      <ArrowLink
        instance_id={next_instance_id}
        urlSearch={urlSearch}
        isPrev={false}
      />
    </Stack>
  );
}
