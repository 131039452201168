import React from "react";
import { SnackbarProvider as Provider } from "notistack";
import CustomSnackbar from "./CustomSnackbar";
import {
  ErrorIconVariant,
  StyledMaterialDesignContent,
  SuccessIconVariant,
} from "./CustomSnackbarContent";

const Components = {
  success: StyledMaterialDesignContent,
  error: StyledMaterialDesignContent,
  info: StyledMaterialDesignContent,
  CustomSnackbar,
};

export default function SnackbarProvider({ children }) {
  return (
    <Provider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      Components={Components}
      iconVariant={{
        success: <SuccessIconVariant style={{ marginRight: 12 }} />,
        error: <ErrorIconVariant style={{ marginRight: 12 }} />,
        info: null,
      }}
      autoHideDuration={5_000}
      maxSnack={5}
    >
      {children}
    </Provider>
  );
}
