import { DEFAULT_ERROR_MESSAGE } from "@constants/static/text";

export const parsePaginatedResponse = (data) => {
  const { next, page, ...other } = data;
  return {
    ...other,
    page,
    next: next ? page + 1 : null,
  };
};

export const parsePaginatedEesResponse = (data) => {
  const { meta, results = [] } = data;
  const { current = 1, total_pages = 1, total_results = 0 } = meta.page;
  const next = current < total_pages ? current + 1 : null;

  return {
    results: results
      .map((result) => JSON.parse(result?.source?.raw || null))
      .filter(Boolean),
    page: current,
    next,
    count: total_results,
  };
};

export const emptyPaginatedResponse = {
  results: [],
  page: 1,
  next: null,
  count: 0,
};

export class PayloadError extends Error {
  constructor({ message = "", payload }) {
    super(message);
    this.payload = payload || DEFAULT_ERROR_MESSAGE;
  }
}
