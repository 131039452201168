import React from "react";
import CustomActionDialog, {
  customActionDialogBodyPx,
} from "@components/Custom/CustomActionDialog";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteGrid from "@components/Custom/CustomDataGrid/CustomInfiniteGrid";
import Box from "@mui/material/Box";
import { useQueryFetchAllProcessorOrganizations } from "@redux/actions/organizations/organizations";
import HealthcareOrganizationActionDialogTitle from "../Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import OrganizationAffiliationsOrganization from "./OrganizationAffiliationsOrganization";
import OrganizationAffiliationsSelectedOrganizations from "./OrganizationAffiliationsSelectedOrganizations";

export default function OrganizationAffiliationsOrganizations({
  isOpen = true,
  isProcessing = true,
  formData = [],
  handleClose = () => {},
  handleSubmit = () => {},
  handleAddOrganization = () => {},
  handleRemoveOrganization = () => {},
}) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const infiniteQuery = useQueryFetchAllProcessorOrganizations({
    searchQuery,
    sortQuery: "name",
  });
  const formDataIds = formData.map(({ id }) => id);
  const getIsAffiliate = (id) => formDataIds.includes(id);

  return (
    <CustomActionDialog
      isOpen={isOpen}
      isProcessing={isProcessing}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      TitleComponent={HealthcareOrganizationActionDialogTitle}
      titleComponentProps={{ title: "Select your partner insurers" }}
      bodyProps={{
        py: 0,
        px: 0,
        minHeight: 450,
        maxHeight: 450,
        overflowy: "scroll",
        spacing: 0,
      }}
      yesText="Apply changes"
    >
      <Box
        px={customActionDialogBodyPx}
        pt={3}
        position="sticky"
        top={88}
        zIndex={100}
        className="gradient-to-top"
      >
        <CustomContextSearchField
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          className="whiteBg"
        />
      </Box>
      <Box flexGrow={1} py={2}>
        <CustomInfiniteGrid
          infiniteQuery={infiniteQuery}
          ObjectComponent={OrganizationAffiliationsOrganization}
          objectComponentProps={{
            handleAddOrganization,
            getIsAffiliate,
          }}
          gridContainerProps={{ spacing: 0 }}
          hiddenIds={formDataIds}
          noResultsFoundProps={{
            wrapperProps: { px: customActionDialogBodyPx, textAlign: "center" },
            subtitle: "No more insurers found.",
            src: "/assets/images/placeholders/no-objects.png",
          }}
        />
      </Box>
      <OrganizationAffiliationsSelectedOrganizations
        formData={formData}
        handleRemoveOrganization={handleRemoveOrganization}
      />
    </CustomActionDialog>
  );
}
