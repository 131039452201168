import { ICD_TYPE_OPTIONS } from "@constants/avey/icds";
import {
  TIME_DELETED_ISNULL_FILTER,
  TIME_UPDATED_FILTER,
  timeDeletedIsNullApplyFilter,
  timeUpdatedApplyFilter,
} from "@constants/static/export";

export const initialFilterState = {
  icd_type__in: [],
  time_updated: [null, null],
  time_deleted__isnull: [],
};

export const filterOptions = {
  icd_type__in: {
    title: "ICD Type",
    type: "option",
    options: ICD_TYPE_OPTIONS,
  },
  time_updated: TIME_UPDATED_FILTER,
  time_deleted__isnull: TIME_DELETED_ISNULL_FILTER,
};

export const formatFilters = (filters = {}) => {
  let formattedFilters = {};
  const {
    icd_type__in = [],
    time_updated = [null, null],
    time_deleted__isnull = [],
  } = filters;

  if (icd_type__in.length > 0)
    formattedFilters["icd_type__in"] = icd_type__in.join(",");

  formattedFilters = timeUpdatedApplyFilter({
    formattedFilters,
    time_updated,
  });

  formattedFilters = timeDeletedIsNullApplyFilter({
    formattedFilters,
    time_deleted__isnull,
  });

  return formattedFilters;
};
