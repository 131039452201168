import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomSection from "@components/Custom/CustomSection";
import CustomValueWithUnit from "@components/Custom/CustomValueWithUnit";
import {
  getConcentrationUnitStr,
  getDosageFormUnitStr,
  getDrugName,
  getPackageSizeUnitStr,
} from "@constants/avey/medications";

export default function MedicationDetailsData({ object, isMinified = false }) {
  const {
    code,
    concentration,
    concentration_unit = null,
    dosage_form,
    generic_name,
    package_size,
    package_size_unit,
    package_type,
    trade_name,
  } = object;

  const hasCode = Boolean(code);
  const concentrationUnitStr = getConcentrationUnitStr({
    concentration_unit,
  });
  const packageSizeUnitStr = getPackageSizeUnitStr({
    package_size_unit,
    package_type,
  });
  const dosageFormUnitStr = getDosageFormUnitStr({ dosage_form });
  const tradeName = getDrugName({ drug_name: trade_name });
  const genericName = getDrugName({ drug_name: generic_name });

  return (
    <CustomSection title="Medication details" isMinified={isMinified}>
      <CustomGrid>
        {hasCode && <CustomLabeledText label="Code" value={code} />}
        <CustomLabeledText label="Generic name" value={genericName} />
        <CustomLabeledText label="Trade name" value={tradeName} />
        <CustomLabeledText label="Concentration" useChildren={true}>
          <CustomValueWithUnit
            value={concentration || "-"}
            unit={concentrationUnitStr}
          />
        </CustomLabeledText>
        <CustomLabeledText label="Package size" useChildren={true}>
          <CustomValueWithUnit
            value={package_size || "-"}
            unit={packageSizeUnitStr}
          />
        </CustomLabeledText>
        <CustomLabeledText label="Dosage form" value={dosageFormUnitStr} />
      </CustomGrid>
    </CustomSection>
  );
}
