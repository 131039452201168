import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import {
  HEALTHCARE_CONFIGURATIONS_BASE_HREFS,
  HEALTHCARE_CONFIGURATIONS_TITLE,
} from "@constants/static/routing";

export default function HealthcareOrganizationConfigurationsHeader() {
  return (
    <CustomHeader
      titleComponentProps={{ title: HEALTHCARE_CONFIGURATIONS_TITLE }}
      hrefs={HEALTHCARE_CONFIGURATIONS_BASE_HREFS}
    />
  );
}
