import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { PieChart } from "@mui/x-charts/PieChart";
import CustomValueWithUnit from "./CustomValueWithUnit";

const height = 100;
const margin = 11;
const outerRadius = height / 2;
const innerRadius = outerRadius - margin;
const innerDiameter = innerRadius * 2;
const boxHeight = height - margin;

const seriesProps = {
  innerRadius,
  outerRadius,
  paddingAngle: 0,
  cornerRadius: 4,
};

const partialSeriesProps = {
  startAngle: -135,
  endAngle: 135,
};

const wholeSeriesProps = {
  startAngle: 0,
  endAngle: 360,
};

export default function CustomPie({
  value = "",
  unit = "",
  caption = "",
  useWholePie = false,
  data = [],
}) {
  const props = useWholePie ? wholeSeriesProps : partialSeriesProps;
  const series = [{ data, ...seriesProps, ...props }];

  return (
    <Box textAlign="center" position="relative" height={boxHeight}>
      <Box
        margin={`${margin}px`}
        height={innerDiameter}
        width={innerDiameter}
        borderRadius="100%"
        position="absolute"
        top={0}
        display="grid"
        justifyContent="center"
        alignContent="center"
      >
        <CustomValueWithUnit
          wrapperProps={{ justifyContent: "center" }}
          value={value}
          unit={unit}
          valueTypographyProps={{ variant: "h5" }}
          unitTypographyProps={{ variant: "caption", color: "textPrimary" }}
        />
        {caption && (
          <Typography
            className="ellipsis-1"
            variant="caption"
            color="textSecondary"
          >
            {caption}
          </Typography>
        )}
      </Box>
      <PieChart
        slotProps={{ legend: { hidden: true } }}
        height={height}
        width={height}
        series={series}
        margin={{ right: 5 }}
      />
    </Box>
  );
}
