import React from "react";
import { ICD_TYPE_10_CM } from "@constants/avey/icds";

export const ClaimContext = React.createContext({
  claim: {},
  setFormData: () => {},
  isCreateOrUpdateMode: false,
  meta_mds_cols: [],
  icd_type: ICD_TYPE_10_CM,
});

export const useClaimContext = () => React.useContext(ClaimContext);
