import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import { ICD_TYPE_10_CM } from "@constants/avey/icds";
import { isErrorFieldnames } from "@constants/claims/claim-evaluation-results";
import { getIcdTtitle } from "@constants/claims/claims";
import ClaimIcdFormData from "./ClaimIcdFormData";

export default function ClaimIcdUpdateForm({
  type,
  state,
  setState = () => {},
  handleUpdateClaimIcd = () => {},
  icd_type = ICD_TYPE_10_CM,
}) {
  const { isOpen, object, index } = state;
  const [formData, setFormData] = React.useState(object);
  const { error_fieldnames = [] } = object || {};
  const errorFieldnames = isErrorFieldnames(error_fieldnames || []);
  const { capitalized, lowercased } = getIcdTtitle({ index, type });

  const handleCloseClaimIcd = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setState((prev) => ({ ...prev, isOpen: false }));
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    handleUpdateClaimIcd({ payload: formData, index });
    handleCloseClaimIcd(e);
  };

  React.useEffect(() => {
    isOpen && setFormData(object);
  }, [isOpen, object]);

  return (
    <CustomActionDialog
      isOpen={isOpen}
      handleClose={handleCloseClaimIcd}
      handleSubmit={handleSubmit}
      titleComponentProps={{ title: `Update ${lowercased}` }}
    >
      <ClaimIcdFormData
        label={capitalized}
        formData={formData}
        setFormData={setFormData}
        errorFieldnames={errorFieldnames}
        icd_type={icd_type}
      />
    </CustomActionDialog>
  );
}
