import React from "react";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import Typography from "@mui/material/Typography";
import { useQueryFetchOrganizationClinicianHistory } from "@redux/actions/organizations/organization-clinicians";
import { useParams } from "react-router-dom";
import OrganizationClinicianData from "../OrganizationClinicianData";
import { OrganizationClinicianHeaderTitle } from "./OrganizationClinicianHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { first_name, last_name, clinician_id } = object;
  const name = `${first_name} ${last_name}`.trim();
  return (
    <Typography variant="body2" className="ellipsis-1">
      <b>{clinician_id}</b> {name}
    </Typography>
  );
};

export default function OrganizationClinicianVersionHistory() {
  const { organization_clinician__id: id } = useParams();
  const useQueryFnProps = { id };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchOrganizationClinicianHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={OrganizationClinicianData}
      VersionHistoryObjectTitleComponent={OrganizationClinicianHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
