import React from "react";
import Organizations from "@components/Authenticated/Organizations/Organizations";
import CustomBox from "@components/Custom/CustomBox";
import { HOME_HREF } from "@constants/static/routing";
import {
  CLAIM_MODE_AVEY_LOGO_MAPPER,
  CLAIM_MODE_COLOR_MAPPER,
} from "@constants/static/system-modes";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { ReactQueryClient } from "@pages/index";
import { selectorAuthClaimMode } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import NavigationDrawerClaimModeToggle from "./NavigationDrawerClaimModeToggle";
import NavigationDrawerFixedIcon from "./NavigationDrawerFixedIcon";

const drawerWidthClosed = 132;
const drawerWidthOpen = 300;

const inputFieldId = "NavigationDrawerHeaderInputField";

const drawerWidthMixing = ({ isOpen, claimMode }) => ({
  transition: "all 300ms ease",
  width: isOpen ? drawerWidthOpen : drawerWidthClosed,
  backgroundColor: CLAIM_MODE_COLOR_MAPPER[claimMode],
  color: "white",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => !["isOpen", "claimMode"].includes(prop),
})(({ isOpen, claimMode }) => {
  const mixinProps = drawerWidthMixing({ isOpen, claimMode });
  return {
    ...mixinProps,
    "& .MuiDrawer-paper": { ...mixinProps, borderRight: 0 },
  };
});

export default function NavigationDrawer({ useClaimModeToggle = false }) {
  const claimMode = useSelector(selectorAuthClaimMode);
  const aveyLogo = CLAIM_MODE_AVEY_LOGO_MAPPER[claimMode];
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    isOpen && document.getElementById(inputFieldId)?.focus();
  }, [isOpen]);

  const handleOpen = React.useCallback(() => setIsOpen(true), []);
  const handleClose = React.useCallback(() => setIsOpen(false), []);
  const handleIsOpen = React.useCallback(() => setIsOpen((prev) => !prev), []);
  const handleClearCache = () => ReactQueryClient.clear();

  return (
    <div>
      <Drawer variant="permanent" isOpen={isOpen} claimMode={claimMode}>
        <NavLink
          onClick={handleClearCache}
          to={HOME_HREF}
          className="opacityOnHover-50"
          style={{ display: "grid", userSelect: "none", marginBottom: "44px" }}
        >
          <CustomBox
            py="20px"
            src={aveyLogo}
            component="img"
            width="100%"
            minHeight={32}
            maxHeight={32}
          />
        </NavLink>
        <Organizations
          isOpen={isOpen}
          handleClose={handleClose}
          handleOpen={handleOpen}
          inputFieldId={inputFieldId}
          useClaimModeToggle={useClaimModeToggle}
        />
        {useClaimModeToggle && <NavigationDrawerClaimModeToggle />}
      </Drawer>
      <NavigationDrawerFixedIcon
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        drawerWidthClosed={drawerWidthClosed}
        drawerWidthOpen={drawerWidthOpen}
      />
    </div>
  );
}
