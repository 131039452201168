import React from "react";
import CustomSkeleton from "@components/Custom/CustomSkeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { TypeAnimation } from "react-type-animation";

export default function CustomAnimatedCode({
  name,
  code,
  icon = null,
  isLoading = false,
}) {
  const [isProcessing, setIsProcessing] = React.useState(true);

  React.useEffect(() => {
    if (!isLoading) {
      setIsProcessing(true);
      const timeout = setTimeout(
        () => setIsProcessing(false),
        1000 + 1000 * Math.random()
      );
      return () => clearTimeout(timeout);
    }
  }, [isLoading]);

  return (
    <Stack
      spacing={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack direction="row" alignItems="center" spacing={1} maxWidth="75%">
        {icon}
        <Typography
          component="div"
          title={name}
          className="ellipsis-1"
          variant="body2"
        >
          {name}
        </Typography>
      </Stack>
      {Boolean(code) &&
        (isProcessing ? (
          <CustomSkeleton style={{ maxWidth: "70px" }} />
        ) : (
          <Typography
            title={code}
            variant="body2"
            fontWeight="bold"
            className="ellipsis-1"
          >
            <TypeAnimation
              key={code}
              sequence={[code]}
              wrapper="span"
              speed={30}
              repeat={false}
              cursor={false}
            />
          </Typography>
        ))}
    </Stack>
  );
}
