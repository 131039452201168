import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import { AVEY_WHITE } from "@styles/theme";

export const CustomToggleOption = styled(Tab, {
  shouldForwardProp: (prop) =>
    !["minHeight", "fontSize", "selectedTextColor"].includes(prop),
})(({ theme, minHeight = 0, fontSize = 12, selectedTextColor }) => ({
  flex: 1,
  minWidth: 0,
  zIndex: 2,
  minHeight,
  padding: 4,
  transition: "all 0.3s ease",
  fontSize,
  backgroundColor: "transparent",
  color: theme.palette.text.secondary,
  "&.Mui-selected": { color: selectedTextColor || AVEY_WHITE },
}));

export const CustomToggle = styled(Tabs, {
  shouldForwardProp: (prop) => !["borderRadius", "color"].includes(prop),
})(({ color, borderRadius = 8 }) => ({
  width: "auto",
  margin: "0px auto",
  cursor: "pointer",
  backgroundColor: AVEY_WHITE,
  padding: 4,
  borderRadius: borderRadius,
  minHeight: 0,
  "& .MuiTabs-indicator": {
    height: "100%",
    zIndex: 1,
    borderRadius: borderRadius,
    backgroundColor: color,
  },
}));
