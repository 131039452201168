import {
  MODEL_TYPE_CONSUMABLE,
  MODEL_TYPE_MEDICATION,
  MODEL_TYPE_ORGANIZATION_BUNDLE,
  MODEL_TYPE_ORGANIZATION_CLINICIAN,
  MODEL_TYPE_ORGANIZATION_PATIENT,
  MODEL_TYPE_ORGANIZATION_POLICY,
} from "@constants/static/globals";
import {
  apiWrapper,
  AveyBloomApi,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedResponse,
  PayloadError,
} from "@helpers/api";
import {
  updateObjectTSQ,
  updatePaginatedTSQ,
  useQueryWrapper,
} from "@helpers/tanstack";

const PROPS_GIVEN_MODEL_TYPE_MAPPER = {
  [MODEL_TYPE_CONSUMABLE]: {
    endpointUrl: "/avey/consumable_uploads/",
    endpointUrlEntries: "/avey/consumable_upload_entries/",
    queryKeySingular: "consumable_upload",
    queryKeyPlural: "consumable_uploads",
    queryKeyPluralEntries: "consumable_upload_entries",
  },
  [MODEL_TYPE_MEDICATION]: {
    endpointUrl: "/avey/medication_uploads/",
    endpointUrlEntries: "/avey/medication_upload_entries/",
    queryKeySingular: "medication_upload",
    queryKeyPlural: "medication_uploads",
    queryKeyPluralEntries: "medication_upload_entries",
  },
  [MODEL_TYPE_ORGANIZATION_BUNDLE]: {
    endpointUrl: "/organizations/organization_bundle_uploads/",
    endpointUrlEntries: "/organizations/organization_bundle_upload_entries/",
    queryKeySingular: "organization_bundle_upload",
    queryKeyPlural: "organization_bundle_uploads",
    queryKeyPluralEntries: "organization_bundle_upload_entries",
  },
  [MODEL_TYPE_ORGANIZATION_CLINICIAN]: {
    endpointUrl: "/organizations/organization_clinician_uploads/",
    endpointUrlEntries: "/organizations/organization_clinician_upload_entries/",
    queryKeySingular: "organization_clinician_upload",
    queryKeyPlural: "organization_clinician_uploads",
    queryKeyPluralEntries: "organization_clinician_upload_entries",
  },
  [MODEL_TYPE_ORGANIZATION_PATIENT]: {
    endpointUrl: "/organizations/organization_patient_uploads/",
    endpointUrlEntries: "/organizations/organization_patient_upload_entries/",
    queryKeySingular: "organization_patient_upload",
    queryKeyPlural: "organization_patient_uploads",
    queryKeyPluralEntries: "organization_patient_upload_entries",
  },
  [MODEL_TYPE_ORGANIZATION_POLICY]: {
    endpointUrl: "/organizations/organization_policy_uploads/",
    endpointUrlEntries: "/organizations/organization_policy_upload_entries/",
    queryKeySingular: "organization_policy_upload",
    queryKeyPlural: "organization_policy_uploads",
    queryKeyPluralEntries: "organization_policy_upload_entries",
  },
};

export const useQueryFetchUploads = ({
  model_type,
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = false,
}) => {
  const { queryKeyPlural, endpointUrl } =
    PROPS_GIVEN_MODEL_TYPE_MAPPER[model_type];

  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchUploads, ...apiWrapperProps })({
        endpointUrl,
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
      }),
    staleTime: 30_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchUploads({
  endpointUrl = "",
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(`${endpointUrl}?${queryParams}`);
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchUpload = ({
  model_type,
  id = "",
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  const { queryKeySingular, endpointUrl } =
    PROPS_GIVEN_MODEL_TYPE_MAPPER[model_type];
  return useQueryWrapper({
    queryKey: [queryKeySingular, { id }],
    queryFn: () =>
      apiWrapper({ fn: fetchUpload, ...apiWrapperProps })({ id, endpointUrl }),
    staleTime: 300_000,
    cacheTime: 30_000,
    ...reactQueryProps,
  });
};

async function fetchUpload({ id = "", endpointUrl }) {
  try {
    const response = await AveyBloomApi().get(`${endpointUrl}${id}/`);
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function createUpload({ model_type, data }) {
  try {
    const { endpointUrl } = PROPS_GIVEN_MODEL_TYPE_MAPPER[model_type];
    const response = await AveyBloomApi().post(endpointUrl, data);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function commitUpload({ model_type, id, is_check }) {
  try {
    const { endpointUrl } = PROPS_GIVEN_MODEL_TYPE_MAPPER[model_type];
    const response = await AveyBloomApi().post(`${endpointUrl}${id}/commit/`, {
      is_check,
    });
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function updateUploadEntry({ model_type, data }) {
  try {
    const { endpointUrlEntries } = PROPS_GIVEN_MODEL_TYPE_MAPPER[model_type];
    const response = await AveyBloomApi().put(
      `${endpointUrlEntries}${data?.id}/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function ignoreUploadEntry({ model_type, id }) {
  try {
    const { endpointUrlEntries } = PROPS_GIVEN_MODEL_TYPE_MAPPER[model_type];
    const response = await AveyBloomApi().put(
      `${endpointUrlEntries}${id}/ignore/`,
      {}
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export const useQueryFetchUploadEntries = ({
  model_type,
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = false,
}) => {
  const { queryKeyPluralEntries, endpointUrlEntries } =
    PROPS_GIVEN_MODEL_TYPE_MAPPER[model_type];
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [
      queryKeyPluralEntries,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchUploadEntries, ...apiWrapperProps })({
        endpointUrlEntries,
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
      }),
    staleTime: 30_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchUploadEntries({
  endpointUrlEntries,
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(
      `${endpointUrlEntries}?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

function doInsertPredicateForUploadEntry({ queryKey, object, pages }) {
  const currentPage = queryKey[1]?.page;
  const pageSize = queryKey[1]?.page_size;
  const preprocessing_status = queryKey[1]?.filters?.preprocessing_status;
  const processing_status = queryKey[1]?.filters?.processing_status;
  let isSameFilter = true;

  if (Boolean(processing_status)) {
    isSameFilter = processing_status === object?.processing_status;
  } else if (Boolean(preprocessing_status)) {
    isSameFilter = preprocessing_status === object?.preprocessing_status;
  }

  const isCurrentPageFull = pages[currentPage - 1]?.results?.length >= pageSize;
  return isSameFilter && !isCurrentPageFull;
}

function doRemovePredicateForUploadEntry({ queryKey, oldObject, newObject }) {
  const isOldPreprocessingStatusFilter =
    queryKey[1]?.filters?.preprocessing_status ===
    oldObject.preprocessing_status;
  const isNewPreprocessingStatusFilter =
    queryKey[1]?.filters?.preprocessing_status ===
    newObject.preprocessing_status;
  const isPreprocessingStatusChange =
    isOldPreprocessingStatusFilter && !isNewPreprocessingStatusFilter;

  const isOldProcessingStatusFilter =
    queryKey[1]?.filters?.processing_status === oldObject.processing_status;
  const isNewProcessingStatusFilter =
    queryKey[1]?.filters?.processing_status === newObject.processing_status;
  const isProcessingStatusChange =
    isOldProcessingStatusFilter && !isNewProcessingStatusFilter;

  return isPreprocessingStatusChange || isProcessingStatusChange;
}

export function updateUploadEntryObjectTSQ({ object }) {
  const { upload, model_type } = object;
  const { queryKeyPluralEntries } = PROPS_GIVEN_MODEL_TYPE_MAPPER[model_type];
  const isUploadObject = typeof upload === "object";
  const uploadId = isUploadObject ? upload?.id : upload;

  updatePaginatedTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeyPluralEntries &&
      queryKey[1]?.filters?.upload__id === uploadId,
    isObjectPredicate: ({ object, result }) =>
      [object.id, object?.older_version_id].includes(result.id),
    doInsertPredicate: doInsertPredicateForUploadEntry,
    doRemovePredicate: doRemovePredicateForUploadEntry,
    object,
  });

  if (isUploadObject) updateUploadObjectTSQ({ object: upload });
}

export function updateUploadObjectTSQ({ object }) {
  const { model_type } = object;
  const { queryKeySingular, queryKeyPlural } =
    PROPS_GIVEN_MODEL_TYPE_MAPPER[model_type];
  updateObjectTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeySingular && queryKey[1]?.id === object.id,
    object,
  });
  updatePaginatedTSQ({
    predicate: ({ queryKey }) => queryKey[0] === queryKeyPlural,
    object,
  });
}

export function updateUploadEntryObjectsTSQ({ objects }) {
  objects.forEach((object) => updateUploadEntryObjectTSQ({ object }));
}
