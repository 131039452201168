const CPT_62322 = {
  code: "62322",
  description:
    "Injection(s), Of Diagnostic Or Therapeutic Substance(S) (Eg, Anesthetic, Antispasmodic, Opioid, Steroid, Other Solution), Not Including Neurolytic Substances, Including Needle Or Catheter Placement, Interlaminar Epidural Or Subarachnoid, Lumbar Or Sacral (Caudal); Without Imaging Guidance",
};
const CPT_65220 = {
  code: "65220",
  description:
    "Removal Of Foreign Body, External Eye; Corneal, Without Slit Lamp",
};
const CPT_65222 = {
  code: "65222",
  description: "Removal Of Foreign Body, External Eye; Corneal, With Slit Lamp",
};
const CPT_72100 = {
  code: "72100",
  description: "Radiologic Examination, Spine, Lumbosacral; 2 Or 3 Views",
};
const CPT_72131 = {
  code: "72131",
  description: "Computed Tomography, Lumbar Spine; Without Contrast Material",
};
const CPT_72148 = {
  code: "72148",
  description:
    "Magnetic Resonance (Eg, Proton) Imaging, Spinal Canal And Contents, Lumbar; Without Contrast Material",
};
const CPT_92002 = {
  code: "92002",
  description:
    "Ophthalmological Services: Medical Examination And Evaluation With Initiation Of Diagnostic And Treatment Program; Intermediate, New Patient",
};
const CPT_92230 = {
  code: "92230",
  description: "Fluorescein Angioscopy With Interpretation And Report",
};
const CPT_92250 = {
  code: "92250",
  description: "Fundus Photography With Interpretation And Report",
};
const CPT_95886 = {
  code: "95886",
  description:
    "Needle Electromyography, Each Extremity, With Related Paraspinal Areas, When Performed, Done With Nerve Conduction, Amplitude And Latency/Velocity Study; Complete, Five Or More Muscles Studied, Innervated By Three Or More Nerves Or Four Or More Spinal Levels (List Separately In Addition To Code For Primary Procedure)",
};
const CPT_97760 = {
  code: "97760",
  description:
    "Orthotic(s) Management And Training (Including Assessment And Fitting When Not Otherwise Reported), Upper Extremity(Ies), Lower Extremity(Ies) And/Or Trunk, Initial Orthotic(S) Encounter, Each 15 Minutes",
};

export const CLAIM_PROCEDURE_62322 = {
  organization_bundle: {
    organization_bundle_cpts: [
      {
        cpt: CPT_62322,
        frequency: 1,
      },
    ],
  },
};
export const CLAIM_PROCEDURE_65220 = {
  organization_bundle: {
    organization_bundle_cpts: [
      {
        cpt: CPT_65220,
        frequency: 1,
      },
    ],
  },
};
export const CLAIM_PROCEDURE_65222 = {
  organization_bundle: {
    organization_bundle_cpts: [
      {
        cpt: CPT_65222,
        frequency: 1,
      },
    ],
  },
};
export const CLAIM_PROCEDURE_72100 = {
  organization_bundle: {
    organization_bundle_cpts: [
      {
        cpt: CPT_72100,
        frequency: 1,
      },
    ],
  },
};
export const CLAIM_PROCEDURE_72131 = {
  organization_bundle: {
    organization_bundle_cpts: [
      {
        cpt: CPT_72131,
        frequency: 1,
      },
    ],
  },
};
export const CLAIM_PROCEDURE_72148 = {
  organization_bundle: {
    organization_bundle_cpts: [
      {
        cpt: CPT_72148,
        frequency: 1,
      },
    ],
  },
};
export const CLAIM_PROCEDURE_92002 = {
  organization_bundle: {
    organization_bundle_cpts: [
      {
        cpt: CPT_92002,
        frequency: 1,
      },
    ],
  },
};
export const CLAIM_PROCEDURE_92230 = {
  organization_bundle: {
    organization_bundle_cpts: [
      {
        cpt: CPT_92230,
        frequency: 1,
      },
    ],
  },
};
export const CLAIM_PROCEDURE_92250 = {
  organization_bundle: {
    organization_bundle_cpts: [
      {
        cpt: CPT_92250,
        frequency: 1,
      },
    ],
  },
};
export const CLAIM_PROCEDURE_95886 = {
  organization_bundle: {
    organization_bundle_cpts: [
      {
        cpt: CPT_95886,
        frequency: 1,
      },
    ],
  },
};
export const CLAIM_PROCEDURE_97760 = {
  organization_bundle: {
    organization_bundle_cpts: [
      {
        cpt: CPT_97760,
        frequency: 1,
      },
    ],
  },
};
