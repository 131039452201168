import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import {
  INSURANCE_CONFIGURATIONS_BASE_HREFS,
  INSURANCE_CONFIGURATIONS_TITLE,
} from "@constants/static/routing";

export default function OrganizationAgreementsHeader() {
  return (
    <CustomHeader
      hrefs={INSURANCE_CONFIGURATIONS_BASE_HREFS}
      titleComponentProps={{ title: INSURANCE_CONFIGURATIONS_TITLE }}
    />
  );
}
