import React from "react";
import { parseFileSize } from "@helpers/parse/numbers";
import CardActionArea from "@mui/material/CardActionArea";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CloseIcon, DocumentIcon } from "@rimads/assets";
import { useDropzone } from "react-dropzone";
import CustomBox from "./CustomBox";

const baseStyle = {
  cursor: "pointer",
  padding: "34px 20px",
  borderRadius: "10px",
};

export default function CustomFileDropzone({
  value,
  accept = {},
  onChange = () => {},
  fileType = "",
}) {
  const [isInvalid, setIsInvalid] = React.useState(false);
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        if (typeof file !== "undefined") onChange(file);
      }
    },
    [onChange]
  );

  const onDropRejected = () => setIsInvalid(true);

  const onClear = () => onChange(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: onDrop,
    onDropRejected: onDropRejected,
    maxFiles: 1,
  });

  React.useEffect(() => {
    setIsInvalid(false);
    return () => setIsInvalid(false);
  }, [value]);

  return Boolean(value) ? (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      py={1}
      pr={1}
      pl={2}
      className="outlined-only borderRadius-8"
    >
      <DocumentIcon dimension={16} />
      <div style={{ flexGrow: 1 }}>
        <Typography variant="body2" fontWeight="bold" className="ellipsis-1">
          {value?.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {parseFileSize(value?.size)}
        </Typography>
      </div>
      <IconButton onClick={onClear}>
        <CloseIcon />
      </IconButton>
    </Stack>
  ) : (
    <CardActionArea className="outlined">
      <CustomBox {...getRootProps({ style: baseStyle })}>
        <input {...getInputProps()} />
        <CustomBox
          display="grid"
          textAlign="center"
          alignItems="center"
          minHeight={40}
        >
          {isInvalid ? (
            <Typography color="secondary" variant="body2">
              {"Please provide a valid "}
              {fileType && <b>{fileType}</b>}
              {" file."}
            </Typography>
          ) : (
            <>
              <Typography variant="body2" fontWeight="light">
                {"Drag & drop your file here"}
              </Typography>
              <div>
                <Typography variant="body2" display="inline" fontWeight="light">
                  {"or "}
                </Typography>
                <Typography
                  variant="body2"
                  className="underlined"
                  display="inline"
                  fontWeight="light"
                >
                  {"click to upload"}
                </Typography>
              </div>
            </>
          )}
        </CustomBox>
      </CustomBox>
    </CardActionArea>
  );
}
