import React from "react";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import { getOrganizationCliniciansHref } from "@constants/static/routing";
import { useQueryFetchOrganizationClinician } from "@redux/actions/organizations/organization-clinicians";
import { Navigate, useParams } from "react-router-dom";
import OrganizationClinicianData from "../OrganizationClinicianData";
import OrganizationClinicianHeader from "./OrganizationClinicianHeader";

export default function OrganizationClinician() {
  const { organization_clinician__id } = useParams();
  const { isLoading, data } = useQueryFetchOrganizationClinician({
    organization_clinician__id,
  });

  const hasData = Boolean(data);

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  const { latest_version_id = null } = data || {};
  if (
    Boolean(latest_version_id) &&
    latest_version_id !== organization_clinician__id
  ) {
    const latestVersionHref = getOrganizationCliniciansHref({
      organization_clinician__id: latest_version_id,
    });
    return <Navigate to={latestVersionHref} />;
  }

  return (
    <>
      <OrganizationClinicianHeader object={data} />
      <CustomPage>
        <OrganizationClinicianData
          object={data}
          useCustomSection={true}
          isMinified={false}
        />
      </CustomPage>
    </>
  );
}
