import React from "react";
import { DEFAULT_ERROR_MESSAGE } from "@constants/static/text";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomBox from "../CustomBox";
import CustomCircularProgress from "../CustomCircularProgress";
import CustomNoResultsFound from "../CustomNoResultsFound";
import CustomInfiniteScroll from "./CustomInfiniteScroll";

export default function CustomInfiniteGrid({
  infiniteQuery = {},
  ObjectComponent,
  objectComponentProps = {},
  gridContainerProps = { spacing: 0 },
  gridItemProps = { xs: 12 },
  noResultsFoundProps = {},
  displayNoResultsFound = true,
  inViewProps = {},
  errorProps = {},
  children,
  customLoadingIndicator = undefined,
  hiddenIds = [],
  wrapperProps = {},
}) {
  const {
    data = {},
    hasNextPage,
    isError,
    isFetchingNextPage,
    isFetching,
    isFetched,
    fetchNextPage,
    isLoading,
  } = infiniteQuery;
  const hasChildren = Boolean(children);

  if (isLoading || (isFetching && !isFetched)) {
    if (customLoadingIndicator === undefined)
      return <CustomCircularProgress position="relative" />;
    return customLoadingIndicator;
  }

  if (isError || !Boolean(data)) {
    const { color = "textSecondary", ...otherErrorProps } = errorProps;
    return (
      <CustomBox {...otherErrorProps}>
        <Typography variant="caption" color={color}>
          {DEFAULT_ERROR_MESSAGE}
        </Typography>
      </CustomBox>
    );
  }

  const { pages = [] } = data;
  let results = pages?.map((page) => page?.results || []).flat();
  results =
    hiddenIds.length > 0
      ? results.filter(({ id }) => !hiddenIds.includes(id))
      : results;
  const count = results.length;

  if (count === 0)
    return (
      displayNoResultsFound && <CustomNoResultsFound {...noResultsFoundProps} />
    );

  return (
    <Stack spacing={3} {...wrapperProps}>
      <div>
        <Grid container {...gridContainerProps}>
          {hasChildren && (
            <Grid item {...gridItemProps}>
              {children}
            </Grid>
          )}
          {results.map((object, index) => (
            <Grid item key={object.id} {...gridItemProps}>
              <ObjectComponent
                object={object}
                index={index}
                {...objectComponentProps}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      {hasNextPage && (
        <div>
          <CustomInfiniteScroll
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            inViewProps={inViewProps}
          />
        </div>
      )}
    </Stack>
  );
}
