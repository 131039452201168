import React from "react";
import CustomTooltip from "@components/Custom/CustomTooltip";
import { parseTimeSince } from "@helpers/parse/time";
import Typography from "@mui/material/Typography";

export function CustomDisabledLabeledTag({ title, time_deleted }) {
  const isDeleted = Boolean(time_deleted);
  const className = isDeleted ? "lightRedBg" : "lightBlueBg";
  const color = isDeleted ? "secondary" : "textPrimary";
  const timeDeleted = isDeleted
    ? parseTimeSince({ value: time_deleted, suffix: "ago" })
    : false;

  const content = (
    <div
      title={title}
      className={`borderRadius-8 ${className}`}
      style={{ padding: "8px 16px" }}
    >
      <Typography variant="body2" color={color} className="ellipsis-1">
        {title || "-"}
      </Typography>
    </div>
  );

  if (timeDeleted)
    return (
      <CustomTooltip title={`Disabled ${timeDeleted}`} placement="right">
        {content}
      </CustomTooltip>
    );

  return content;
}

export function CustomDisabledTag({ time_deleted }) {
  if (!Boolean(time_deleted)) return null;

  const timeDeleted = parseTimeSince({ value: time_deleted, suffix: "ago" });
  return (
    <CustomTooltip title={timeDeleted} placement="right">
      <Typography
        variant="body2"
        fontWeight="bold"
        color="secondary"
        className="borderRadius-8 lightRedBg"
        px={2}
        py={1}
      >
        {"Disabled"}
      </Typography>
    </CustomTooltip>
  );
}
