import {
  TIME_UPDATED_FILTER,
  timeUpdatedApplyFilter,
} from "@constants/static/export";

export const initialFilterState = {
  time_updated: [null, null],
  time_locked: [],
};

export const filterOptions = {
  time_updated: TIME_UPDATED_FILTER,
  time_locked: {
    title: "Submission status",
    type: "boolean",
    options: [
      { key: "true", value: "In progress" },
      { key: "false", value: "Locked" },
    ],
  },
};

export const formatFilters = (filters = {}) => {
  let formattedFilters = {};
  const { time_updated = [null, null], time_locked = [] } = filters;

  formattedFilters = timeUpdatedApplyFilter({
    formattedFilters,
    time_updated,
  });

  if (time_locked.length === 1)
    formattedFilters["time_locked__isnull"] = time_locked[0];

  return formattedFilters;
};
