import React from "react";
import CustomAnimatedCode from "@components/Custom/CustomAnimatedCode";
import CustomSection from "@components/Custom/CustomSection";
import { CLAIM_SECTION_PROCEDURES_TITLE } from "@constants/claims/claims";
import Stack from "@mui/material/Stack";

export default function ClaimDemoClaimProceduresReviewAndConfirm({
  claim_procedures = [],
}) {
  const numObjects = claim_procedures.length;

  if (numObjects === 0) return null;

  return (
    <CustomSection title={CLAIM_SECTION_PROCEDURES_TITLE} isMinified={true}>
      <Stack spacing={2}>
        {claim_procedures.map((claim_procedure, index) => {
          const { organization_bundle_cpts = [] } =
            claim_procedure?.organization_bundle;
          const { cpt } = organization_bundle_cpts[0] || {};
          const { code, description } = cpt;
          return (
            <CustomAnimatedCode key={index} name={description} code={code} />
          );
        })}
      </Stack>
    </CustomSection>
  );
}
