import React from "react";
import {
  CustomDatePicker,
  CustomOutlinedTextField,
} from "@components/Custom/CustomOutlinedField";
import CustomRadioForm from "@components/Custom/CustomRadioForm";
import {
  CLAIM_ERROR_FIELDNAMES_CLAIM_FORM_NUMBER,
  CLAIM_ERROR_FIELDNAMES_ENCOUNTER_TYPE,
  CLAIM_ERROR_FIELDNAMES_VISIT_DATE,
  CLAIM_ERROR_FIELDNAMES_VISIT_TYPE,
  isErrorFieldnames,
} from "@constants/claims/claim-evaluation-results";
import {
  CLAIM_ADMISSION_TYPE_OPTIONS,
  CLAIM_ENCOUNTER_TYPE_OPTIONS,
  CLAIM_VISIT_TYPE_OPTIONS,
  isEncounterTypeOther,
} from "@constants/claims/claims";
import { isInpatientVisitType } from "@constants/organizations/organization-patients";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useClaimContext } from "../Claim/context";

const getInitialFormData = (claim) => {
  const {
    claim_form_number,
    visit_type,
    encounter_type,
    encounter_type_description,
    visit_date,
    discharge_date,
    admission_type,
  } = claim;
  return {
    claim_form_number,
    visit_type,
    encounter_type,
    encounter_type_description,
    visit_date,
    discharge_date,
    admission_type,
  };
};

export default function ClaimVisitDetailsFormData() {
  const { claim = {}, setFormData: setClaimFormData = () => {} } =
    useClaimContext();
  const { error_fieldnames = [] } = claim;
  const initialFormData = getInitialFormData(claim);
  const errorFieldnames = isErrorFieldnames(error_fieldnames || []);
  const stringifiedInitialFormData = JSON.stringify(initialFormData);
  const [formData, setFormData] = React.useState(initialFormData);
  const {
    claim_form_number,
    visit_type,
    encounter_type,
    encounter_type_description,
    visit_date,
    discharge_date,
    admission_type,
  } = formData;

  const {
    [CLAIM_ERROR_FIELDNAMES_CLAIM_FORM_NUMBER]: claim_form_number_error = false,
    [CLAIM_ERROR_FIELDNAMES_VISIT_TYPE]: visit_type_error = false,
    [CLAIM_ERROR_FIELDNAMES_ENCOUNTER_TYPE]: encounter_type_error = false,
    [CLAIM_ERROR_FIELDNAMES_VISIT_DATE]: visit_date_error = false,
  } = errorFieldnames;

  React.useEffect(() => {
    setFormData(JSON.parse(stringifiedInitialFormData));
  }, [stringifiedInitialFormData]);

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeField = ({ fieldname, newValue }) =>
    setFormData({ ...formData, [fieldname]: newValue });

  const handleBlur = (e) => {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      !currentTarget?.contains(document.activeElement) &&
        setClaimFormData((prev) => ({ ...prev, ...formData }));
    }, 0);
  };

  const isOtherEncounter = isEncounterTypeOther(encounter_type);
  const isInpatient = isInpatientVisitType(visit_type);

  return (
    <Box flexGrow={1} tabIndex={-1} onBlur={handleBlur}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={8}>
              <CustomOutlinedTextField
                required
                label="Claim form number"
                name="claim_form_number"
                value={claim_form_number || ""}
                onChange={handleChange}
                error={claim_form_number_error}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomDatePicker
                label="Visit date"
                value={visit_date}
                onChange={(newValue) =>
                  handleChangeField({ fieldname: "visit_date", newValue })
                }
                error={visit_date_error}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <CustomRadioForm
                value={visit_type || ""}
                label="Visit type"
                options={CLAIM_VISIT_TYPE_OPTIONS}
                onChange={(newValue) =>
                  handleChangeField({ fieldname: "visit_type", newValue })
                }
                error={visit_type_error}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomRadioForm
                value={isInpatient ? admission_type : null}
                label="Admission type"
                options={CLAIM_ADMISSION_TYPE_OPTIONS}
                onChange={(newValue) =>
                  handleChangeField({
                    fieldname: "admission_type",
                    newValue,
                  })
                }
                disabled={!isInpatient}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <CustomDatePicker
                label="Discharge date"
                value={isInpatient ? discharge_date : null}
                onChange={(newValue) =>
                  handleChangeField({
                    fieldname: "discharge_date",
                    newValue,
                  })
                }
                disabled={!isInpatient}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <CustomRadioForm
                value={encounter_type || ""}
                label="Encounter type"
                options={CLAIM_ENCOUNTER_TYPE_OPTIONS}
                onChange={(newValue) =>
                  handleChangeField({ fieldname: "encounter_type", newValue })
                }
                error={encounter_type_error}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomOutlinedTextField
                name="encounter_type_description"
                label="Encounter type description"
                value={isOtherEncounter ? encounter_type_description || "" : ""}
                onChange={handleChange}
                disabled={!isOtherEncounter}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
