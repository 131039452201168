import React from "react";
import OrganizationPatientData from "@components/Authenticated/Configurations/OrganizationPatients/OrganizationPatient/OrganizationPatientData";
import OrganizationPatientCreateForm from "@components/Authenticated/Configurations/OrganizationPatients/OrganizationPatient/OrganizationPatientForms/OrganizationPatientCreateForm";
import OrganizationPatientUpdateForm from "@components/Authenticated/Configurations/OrganizationPatients/OrganizationPatient/OrganizationPatientForms/OrganizationPatientUpdateForm";
import CustomActionButtons from "@components/Custom/CustomActionButtons";
import OrganizationPatientAutocomplete from "@components/Custom/CustomAutocomplete/OrganizationPatientAutocomplete";
import CustomBox from "@components/Custom/CustomBox";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

export default function ClaimOrganizationPatientFormData({
  formData,
  handleChangeOrganizationPatient = () => {},
}) {
  const handleRemove = () => handleChangeOrganizationPatient(null);
  if (Boolean(formData)) {
    return (
      <Stack spacing={3} direction="row">
        <CustomBox flexGrow={1}>
          <OrganizationPatientData object={formData} useCustomSection={false} />
        </CustomBox>
        <CustomActionButtons
          direction="column"
          handleRemove={handleRemove}
          CustomEditComponent={OrganizationPatientUpdateForm}
          customEditComponentProps={{
            object: formData,
            handleSelectPatient: handleChangeOrganizationPatient,
            isSquare: true,
            withNavigate: false,
            isFullScreen: false,
          }}
        />
      </Stack>
    );
  }

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <OrganizationPatientAutocomplete
            handleChange={handleChangeOrganizationPatient}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display="grid"
          justifyContent={{ xs: "flex-end", md: "flex-start" }}
        >
          <OrganizationPatientCreateForm
            handleSelectPatient={handleChangeOrganizationPatient}
            withNavigate={false}
            isFullScreen={false}
            isTypographyButton={true}
          />
        </Grid>
      </Grid>
    </div>
  );
}
