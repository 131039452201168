import React from "react";
import CustomBox from "@components/Custom/CustomBox";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { selectorAuthCurrentUser } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import AccountDetails from "../AccountDetails";
import AccountInformation from "../AccountInformation";
import AccountAvatar from "./AccountAvatar";

const SectionWrapper = ({ children }) => (
  <CustomBox p={{ xs: 3, sm: 6 }}>{children}</CustomBox>
);

export default function AccountBody() {
  const user = useSelector(selectorAuthCurrentUser);

  return (
    <Stack spacing={3}>
      <AccountAvatar user={user} />
      <div>
        <CustomBox
          className="borderRadius-20 elevated-8"
          maxWidth={656}
          m="auto"
        >
          <SectionWrapper>
            <AccountInformation user={user} />
          </SectionWrapper>
          <Divider />
          <SectionWrapper>
            <AccountDetails user={user} />
          </SectionWrapper>
        </CustomBox>
      </div>
    </Stack>
  );
}
