import React from "react";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import useFetchClaimSubmission from "@helpers/hooks/useFetchObjects/useFetchClaimSubmission";
import ClaimSubmissionUploadsGrid from "./ClaimSubmissionUploadsGrid";
import ClaimSubmissionUploadsHeader from "./ClaimSubmissionUploadsHeader";

export default function ClaimSubmissionUploads() {
  const { isLoading, data, hasData } = useFetchClaimSubmission();

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  return (
    <>
      <ClaimSubmissionUploadsHeader object={data} />
      <CustomPage mt={0}>
        <ClaimSubmissionUploadsGrid object={data} />
      </CustomPage>
    </>
  );
}
