import React from "react";
import CustomInputAdornment from "@components/Custom/CustomInputAdornment";
import CustomMoreInformationIcon from "@components/Custom/CustomMoreInformationIcon";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import { TOKEN_FULL_NAME } from "@constants/static/payment";
import { tokensToMoney } from "@helpers/parse/tokens";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { selectorAuthBillingConversion } from "@redux/selectors/auth";
import { useSelector } from "react-redux";

export default function OrganizationPaymentCyclenPayAsYouGoFormData({
  num_tokens = 0,
  rate_per_token = 0,
  handleChange = () => {},
}) {
  const billing_conversion = useSelector(selectorAuthBillingConversion);
  const amount = tokensToMoney({
    num_tokens,
    rate_per_token,
    billing_conversion,
  });

  return (
    <div
      style={{ margin: "24px auto 0px auto", maxWidth: 300, textAlign: "left" }}
    >
      <Stack spacing={2} direction="row" alignItems="center">
        <CustomOutlinedTextField
          value={num_tokens}
          InputProps={{
            endAdornment: <CustomInputAdornment value={TOKEN_FULL_NAME} />,
          }}
          inputProps={{ min: 1, step: 1 }}
          onChange={handleChange}
          type="number"
          required
        />
        <CustomMoreInformationIcon>
          {
            "This limit ensures that your spending stays within your budget until the next billing cycle."
          }
        </CustomMoreInformationIcon>
      </Stack>
      <Typography variant="caption" color="textSecondary">
        {amount}
      </Typography>
    </div>
  );
}
