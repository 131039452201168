import React from "react";
import Claim from "@components/Authenticated/ClaimSubmissions/Claims/Claim/Claim";
import Custom404 from "@components/Custom/Custom404";
import { Route, Routes } from "react-router-dom";

export default function ClaimsRoutes() {
  return (
    <Routes>
      <Route path=":claim__id" Component={Claim} />
      <Route path="*" Component={Custom404} />
    </Routes>
  );
}
