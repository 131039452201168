import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

export default function CustomLoader({ label = "" }) {
  return (
    <Stack spacing={2} direction="row" alignItems="center" minHeight={28}>
      <Typography variant="body2" color="textSecondary" noWrap>
        {label}
      </Typography>
      <CircularProgress size={14} />
    </Stack>
  );
}
