import React from "react";
import OrganizationAgreementTag from "@components/Authenticated/Configurations/OrganizationAgreements/OrganizationAgreement/OrganizationAgreement/OrganizationAgreementTag";
import OrganizationAgreementActivateMaintenanceForm from "@components/Authenticated/Configurations/OrganizationAgreements/OrganizationAgreement/OrganizationAgreementForms/OrganizationAgreementActivateMaintenanceForm";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  getClaimSubmissionHref,
  HOME_HREF,
  HOME_TITLE,
} from "@constants/static/routing";
import useFetchOrganizationAgreement from "@helpers/hooks/useFetchObjects/useFetchOrganizationAgreement";
import {
  selectorAuthHasAdminPrivileges,
  selectorAuthHasSubmitterPrivileges,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";

export default function ClaimSubmissionsHeader({ object }) {
  const hasAdminPriviledges = useSelector(selectorAuthHasAdminPrivileges);
  const hasSubmitterPrivileges = useSelector(
    selectorAuthHasSubmitterPrivileges
  );
  const {
    data: organization_agreement,
    hasData,
    organization__slug,
  } = useFetchOrganizationAgreement();
  const { name } = object;
  const { time_maintenance } = organization_agreement || {};

  const hrefs = [
    {
      label: HOME_TITLE,
      href: HOME_HREF,
    },
    {
      label: name || organization__slug,
      href: getClaimSubmissionHref({ organization__slug }),
    },
  ];

  return (
    <CustomHeader
      TitleComponent={CustomHeaderTitle}
      titleComponentProps={{
        title: name,
        time_maintenance,
        children: hasData && (
          <OrganizationAgreementTag
            time_maintenance={time_maintenance}
            useClaimMode={true}
          />
        ),
      }}
      hrefs={hrefs}
    >
      {hasAdminPriviledges && hasSubmitterPrivileges && hasData && (
        <CustomMoreActions>
          <OrganizationAgreementActivateMaintenanceForm
            object={organization_agreement}
          />
        </CustomMoreActions>
      )}
    </CustomHeader>
  );
}
