import React from "react";
import Typography from "@mui/material/Typography";

export default function CustomTitleComponent({
  TitleComponent = undefined,
  titleComponentProps = {},
}) {
  const {
    variant = "h5",
    disableTypography = false,
    title,
    ...otherTitleComponentProps
  } = titleComponentProps || {};

  if (typeof TitleComponent === "function")
    return <TitleComponent {...titleComponentProps} />;

  if (disableTypography) return TitleComponent;

  if (!title) return null;

  return (
    <Typography variant={variant} {...otherTitleComponentProps}>
      {title}
    </Typography>
  );
}
