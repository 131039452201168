import React from "react";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  exportMedications,
  useQueryFetchMedicationExports,
} from "@redux/actions/avey/medications";
import ConfigurationsBulkExportForm from "../../Configurations/ConfigurationsBulkExportForm";
import ConfigurationsBulkExportHistory from "../../Configurations/ConfigurationsBulkExportForm/ConfigurationsBulkExportHistory";
import HealthcareOrganizationActionDialogTitle from "../../Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import { describeFilters } from "./MedicationsFilters";

export default function MedicationsExports({ useQueryFnProps }) {
  return (
    <CustomMoreActions
      icon="DownloadIcon"
      useRotateProps={{ useRotate: false }}
    >
      <ConfigurationsBulkExportForm
        label="medications"
        submitFn={exportMedications}
        useQueryFnProps={useQueryFnProps}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
        describeFilters={describeFilters}
      />
      <ConfigurationsBulkExportHistory
        drawerProps={{
          TitleComponent: HealthcareOrganizationActionDialogTitle,
          titleComponentProps: { title: "Medication exports" },
        }}
        useQueryFn={useQueryFetchMedicationExports}
        describeFilters={describeFilters}
      />
    </CustomMoreActions>
  );
}
