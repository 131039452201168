export const ACCESS_TOKEN_KEY = "access";
export const REFRESH_TOKEN_KEY = "refresh";

export const storeAccessTokens = ({ access, refresh }) => {
  if (access !== undefined) localStorage.setItem(ACCESS_TOKEN_KEY, access);
  if (refresh !== undefined) localStorage.setItem(REFRESH_TOKEN_KEY, refresh);
};

export const getAccessTokens = () => ({
  [ACCESS_TOKEN_KEY]: localStorage.getItem(ACCESS_TOKEN_KEY),
  [REFRESH_TOKEN_KEY]: localStorage.getItem(REFRESH_TOKEN_KEY),
});

export const removeAccessTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};
