import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const StyledToolTip = styled(
  ({ className, bgColor, padding, borderRadius, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme, padding, borderRadius = 6, bgColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: bgColor || theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[8],
    fontSize: 12,
    borderRadius,
    padding,
  },
}));

export default function CustomTooltip({
  title = "",
  children,
  className = "button",
  wrapperProps = {},
  ...props
}) {
  if (!Boolean(title)) return <div {...wrapperProps}>{children}</div>;

  return (
    <StyledToolTip title={title} {...props}>
      <span className={className} {...wrapperProps}>
        {children}
      </span>
    </StyledToolTip>
  );
}
