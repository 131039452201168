import React from "react";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import Typography from "@mui/material/Typography";
import { useQueryFetchIcdHistory } from "@redux/actions/avey/icds";
import { useParams } from "react-router-dom";
import IcdData from "./IcdData";
import { IcdHeaderTitle } from "./IcdHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { code } = object;
  return (
    <Typography variant="body2" fontWeight="bold" className="ellipsis-1">
      {code}
    </Typography>
  );
};

export default function IcdVersionHistory() {
  const { icd__id: id } = useParams();
  const useQueryFnProps = { id };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchIcdHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={IcdData}
      VersionHistoryObjectTitleComponent={IcdHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
