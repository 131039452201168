import React from "react";
import { getIntOrFloat } from "@helpers/parse/numbers";
import OrganizationAgreementUpdateForm from "../OrganizationAgreementUpdateForm";
import OrganizationAgreementSecondProcedureDiscountFormData from "./OrganizationAgreementSecondProcedureDiscountFormData";

export default function OrganizationAgreementSecondProcedureDiscountForm({
  object,
}) {
  const formatFormData = (formData) => {
    const { id, second_procedure_discount_rate } = formData;
    return {
      id,
      second_procedure_discount_rate: second_procedure_discount_rate || 0,
    };
  };
  const getSubtitle = ({ name }) =>
    `Set the rate for the second procedure discount as agreed upong with ${name}`;

  const { second_procedure_discount_rate } = object || {};

  return (
    <OrganizationAgreementUpdateForm
      object={object}
      formatFormData={formatFormData}
      successMessage="Second procedure discount successfully updated"
      title="Second procedure discount"
      getSubtitle={getSubtitle}
      FormDataComponent={OrganizationAgreementSecondProcedureDiscountFormData}
      summaryCardProps={{
        primary: getIntOrFloat(second_procedure_discount_rate) || 0,
        secondary: "%",
      }}
    />
  );
}
