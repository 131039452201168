import React from "react";
import CustomInputAdornment from "@components/Custom/CustomInputAdornment";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";

export default function OrganizationAgreementSecondProcedureDiscountFormData({
  formData = {},
  setFormData = () => {},
}) {
  const { second_procedure_discount_rate } = formData;
  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  return (
    <CustomOutlinedTextField
      label="Discount rate"
      value={Number(second_procedure_discount_rate) || ""}
      onChange={handleChange}
      type="number"
      name="second_procedure_discount_rate"
      inputProps={{ min: 0, max: 100, step: 0.000001 }}
      InputProps={{
        endAdornment: <CustomInputAdornment value="%" />,
      }}
    />
  );
}
