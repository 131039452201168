import React from "react";
import CustomInfiniteGrid from "@components/Custom/CustomDataGrid/CustomInfiniteGrid";
import CustomProgressBar from "@components/Custom/CustomProgressBar";
import { CustomSummaryCard } from "@components/Custom/CustomSummaryCards";
import {
  CLAIM_SUBMISSION_UPLOAD_STATUS_COLOR_MAPPER,
  CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING,
} from "@constants/claims/claim-submission-uploads";
import { parseTimestamp } from "@helpers/parse/time";
import { useQueryFetchClaimSubmissionUploads } from "@redux/actions/claims/claim-submission-uploads";
import { useParams } from "react-router-dom";

export default function ClaimSubmissionUploadPending() {
  const { organization__slug, claim_submission__id } = useParams();
  const infiniteQuery = useQueryFetchClaimSubmissionUploads({
    filters: {
      organization__slug,
      claim_submission__id,
      preprocessing_status: CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING,
    },
  });

  return (
    <CustomInfiniteGrid
      customLoadingIndicator={null}
      displayNoResultsFound={false}
      infiniteQuery={infiniteQuery}
      ObjectComponent={ClaimSubmissionUploadPendingObjectComponent}
      gridContainerProps={{ spacing: 2 }}
      gridItemProps={{}}
    />
  );
}

const ClaimSubmissionUploadPendingObjectComponent = ({ object }) => {
  const {
    preprocessing_time_start,
    num_claims,
    num_claims_created,
    preprocessing_status,
  } = object;
  const title =
    parseTimestamp({ value: preprocessing_time_start }) || "Pending";
  const color =
    CLAIM_SUBMISSION_UPLOAD_STATUS_COLOR_MAPPER[preprocessing_status];

  return (
    <CustomSummaryCard
      wrapperProps={{ maxWidth: 300 }}
      title={title}
      useChildren={true}
    >
      <CustomProgressBar
        color={color}
        nominator={num_claims_created}
        denominator={num_claims}
        wrapperProps={{ minHeight: 30 }}
      />
    </CustomSummaryCard>
  );
};
