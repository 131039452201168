import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  HEALTHCARE_CONFIGURATIONS_BASE_HREFS,
  ICDS_TITLE,
  getIcdsHref,
} from "@constants/static/routing";
import { useParams } from "react-router-dom";
import IcdVersionHistory from "./IcdVersionHistory";

export function IcdHeaderTitle({ object, variant = "h3" }) {
  const { version, code, time_deleted } = object;

  return (
    <CustomHeaderTitle
      title={code}
      variant={variant}
      version={version}
      time_deleted={time_deleted}
    />
  );
}

export default function IcdHeader({ object }) {
  const { icd__id } = useParams();
  const title = object?.code || icd__id;

  const hrefs = [
    ...HEALTHCARE_CONFIGURATIONS_BASE_HREFS,
    {
      label: ICDS_TITLE,
      href: getIcdsHref({}),
    },
    {
      label: title,
      href: getIcdsHref({ icd__id }),
    },
  ];

  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={IcdHeaderTitle}
      titleComponentProps={{ object }}
    >
      <CustomMoreActions>
        <IcdVersionHistory />
      </CustomMoreActions>
    </CustomHeader>
  );
}
