import React from "react";
import CustomSection from "@components/Custom/CustomSection";
import { CLAIM_SECTION_ORGANIZATION_CLINICIAN_TITLE } from "@constants/claims/claims";
import ClaimOrganizationClinicianFormData from "../Claim/ClaimOrganizationClinician/ClaimOrganizationClinicianFormData";
import {
  selectorClaimDemoOrganizationClinician,
  useClaimDemoContext,
} from "./context";

export default function ClaimDemoOrganizationClinician() {
  const { state, handleChangeOrganizationClinician } = useClaimDemoContext();
  const organization_clinician = selectorClaimDemoOrganizationClinician(state);

  return (
    <CustomSection
      title={CLAIM_SECTION_ORGANIZATION_CLINICIAN_TITLE}
      isCollapsable={false}
    >
      <ClaimOrganizationClinicianFormData
        formData={organization_clinician}
        handleChangeOrganizationClinician={handleChangeOrganizationClinician}
      />
    </CustomSection>
  );
}
