import React from "react";
import { DEFAULT_ERROR_MESSAGE } from "@constants/static/text";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import CardActionArea from "@mui/material/CardActionArea";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { CameraIcon } from "@rimads/assets";
import { enqueueSnackbar } from "notistack";
import Dropzone from "react-dropzone";
import CustomActionDialog from "./CustomActionDialog";
import CustomAvatar from "./CustomAvatar";
import CustomBox from "./CustomBox";
import CustomCircularProgress from "./CustomCircularProgress";
import CustomImageCropper from "./CustomImageCropper";
import canvasPreview from "./CustomImageCropper/canvasPreview";
import CustomPopper from "./CustomPopper";

const emptyFormData = {
  isOpen: false,
  imageObj: null,
  image: null,
};

const useEffectCustomImageDropzone = ({ image, submitFn }) => {
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [originalImage, setOriginalImage] = React.useState(image);
  const [currentImage, setCurrentImage] = React.useState({
    ...emptyFormData,
    image,
  });
  const imgRef = React.useRef(null);
  const [completedCrop, setCompletedCrop] = React.useState(false);

  const hasImage = Boolean(image);

  React.useEffect(() => setOriginalImage(image), [image]);

  React.useEffect(
    () => setCurrentImage({ ...emptyFormData, image: originalImage }),
    [originalImage]
  );

  const handleDrop = (acceptedFiles) =>
    acceptedFiles.forEach((acceptedFile) => {
      if (typeof acceptedFile !== "undefined") {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFile);
        reader.onloadend = () =>
          setCurrentImage({
            isOpen: true,
            imageObj: acceptedFile,
            image: reader.result,
          });
      } else {
        setCurrentImage(emptyFormData);
      }
    });

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentImage((prevFormData) => ({ ...prevFormData, isOpen: false }));
  };

  const handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentImage({ ...emptyFormData, isOpen: true });
  };

  const uploadFn = (file) =>
    submitFn(file)
      .then(() => {
        setIsProcessing(false);
        enqueueSnackbar({
          message: "Image successfully updated",
          variant: "success",
        });
      })
      .catch(() => {
        setIsProcessing(false);
        enqueueSnackbar({
          message:
            "The file you uploaded was either not an image or a corrupted image",
          variant: "error",
        });
      });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    if (currentImage?.image) {
      canvasPreview(imgRef?.current, completedCrop)
        .then(({ file }) => {
          if (Boolean(file)) {
            uploadFn(file);
          } else {
            setIsProcessing(false);
            enqueueSnackbar({
              message: "Unable to crop image",
              variant: "error",
            });
          }
        })
        .catch(() => {
          setIsProcessing(false);
          enqueueSnackbar({
            message: DEFAULT_ERROR_MESSAGE,
            variant: "error",
          });
        });
    } else {
      uploadFn(null);
    }
  };

  return {
    handleDrop,
    handleClose,
    handleClear,
    handleSubmit,
    setCompletedCrop,
    imgRef,
    isProcessing,
    hasImage,
    ...currentImage,
  };
};

const useAnchorHook = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenAnchorEl = (e) => setAnchorEl(e.currentTarget);

  return {
    anchorEl,
    setAnchorEl,
    handleOpenAnchorEl,
  };
};

export default function CustomImageDropzone({
  image,
  isLoading = false,
  borderRadius = "100%",
  submitFn = () => {},
  placeholder = "",
  className = "sizeXxl",
  TitleComponent = () => null,
  titleComponentProps = {},
}) {
  const { anchorEl, setAnchorEl, handleOpenAnchorEl } = useAnchorHook();
  const {
    handleDrop,
    handleClose,
    handleClear,
    handleSubmit,
    setCompletedCrop,
    imgRef,
    isProcessing,
    hasImage,
    image: currentImage,
    isOpen,
  } = useEffectCustomImageDropzone({ image, submitFn });

  const isDelete = !Boolean(currentImage);
  const title = isDelete ? "Delete current image?" : "Update image";

  const dialogProps = isDelete
    ? {
        isBodyEmpty: true,
        subtitleComponentProps: { title: "This action can’t be undone." },
        noText: "No, keep it",
        yesText: "Yes, delete",
        yesButtonProps: { color: "secondary" },
      }
    : {};

  return (
    <div style={{ display: "grid", justifyContent: "flex-start" }}>
      <CardActionArea
        className={hasImage ? "elevated-8" : undefined}
        style={{ borderRadius }}
        onClick={handleOpenAnchorEl}
      >
        <CustomBox className="hoverOpacityParent">
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <Avatar className="hoverOpacityChild blackBg button opacity-0">
                <CameraIcon primary_fill="white" dimension={16} />
              </Avatar>
            }
          >
            <CustomCircularProgress open={isLoading} invisible={false} />
            <CustomAvatar
              src={image}
              title="Click to update image"
              className={className}
              style={{ borderRadius }}
              placeholder={placeholder}
            />
          </Badge>
        </CustomBox>
      </CardActionArea>
      <CustomPopper
        anchorEl={anchorEl}
        handleAnchorEl={setAnchorEl}
        placement="bottom"
      >
        <Dropzone
          onDrop={handleDrop}
          maxFiles={1}
          accept={{ "image/*": [".png", ".jpeg", ".jpg"] }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <ListItemButton sx={{ px: 3 }}>
                <ListItemText
                  primary="Choose from library"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItemButton>
            </div>
          )}
        </Dropzone>
        {hasImage && (
          <ListItemButton sx={{ px: 3 }} onClick={handleClear}>
            <ListItemText
              primary="Delete current picture"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </ListItemButton>
        )}
      </CustomPopper>
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        TitleComponent={TitleComponent}
        titleComponentProps={{ title, ...titleComponentProps }}
        {...dialogProps}
      >
        {currentImage && (
          <CustomBox
            width="100%"
            display="grid"
            style={{ alignSelf: "center" }}
            className="borderRadius-8 elevated-8"
          >
            <CustomImageCropper
              src={currentImage}
              imgRef={imgRef}
              setCompletedCrop={setCompletedCrop}
            />
          </CustomBox>
        )}
      </CustomActionDialog>
    </div>
  );
}
