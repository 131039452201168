import React from "react";
import ConfigurationsBulkExportGrid from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkExportForm/ConfigurationsBulkExportGrid";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import { MODEL_TYPE_CLAIM } from "@constants/static/globals";
import { useQueryFetchClaimsExports } from "@redux/actions/claims/claims";
import { useParams } from "react-router-dom";
import { describeFilters } from "../../Claims/Claims/ClaimsFilters";

export default function ClaimExportsGrid() {
  const { organization__slug, claim_submission__id } = useParams();
  const useQueryFnProps = {
    filters: {
      organization__slug,
      ...(claim_submission__id ? { claim_submission__id } : {}),
    },
  };

  return (
    <ConfigurationsBulkExportGrid
      useQueryFn={useQueryFetchClaimsExports}
      useQueryFnProps={useQueryFnProps}
      TitleComponent={InsuranceOrganizationActionDialogTitle}
      describeFilters={describeFilters}
      model_type={MODEL_TYPE_CLAIM}
    />
  );
}
