import React from "react";
import MedicationAutocomplete from "@components/Custom/CustomAutocomplete/MedicationAutocomplete";
import CustomInputAdornment from "@components/Custom/CustomInputAdornment";
import CustomLabeledCheckbox from "@components/Custom/CustomLabeledCheckbox";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import CustomRadioForm from "@components/Custom/CustomRadioForm";
import CustomSection from "@components/Custom/CustomSection";
import {
  isOrganizationBundleTypeMedication,
  isOrganizationBundleTypeProcedure,
} from "@constants/organizations/organization-bundles";
import {
  isInpatientVisitType,
  isOutpatientVisitType,
  ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT_SHORTHAND,
  ORGANIZATION_PATIENT_VISIT_TYPE_SHORTHAND_OPTIONS,
  ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT_SHORTHAND,
} from "@constants/organizations/organization-patients";
import Grid from "@mui/material/Grid";

export default function OrganizationBundleDetailsFormData({
  formData = {},
  setFormData = () => {},
}) {
  const {
    bundle_type,
    internal_code,
    description,
    undiscounted_price,
    currency,
    visit_type,
    inpatient_discount_rate,
    outpatient_discount_rate,
    is_preauthorization_required,
    is_second_procedure_discount,
    organization_bundle_medication,
  } = formData;

  const { medication = null } = organization_bundle_medication || {};

  const isProcedureAndConsumable =
    isOrganizationBundleTypeProcedure(bundle_type);
  const isMedication = isOrganizationBundleTypeMedication(bundle_type);

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeBoolean = ({ fieldname, e }) =>
    setFormData((prev) => ({ ...prev, [fieldname]: e.target.checked }));

  const handleChangeVisitType = (newValue) => {
    const isValueInpatient = isInpatientVisitType(newValue);
    setFormData((prev) => ({
      ...prev,
      visit_type: newValue,
      is_preauthorization_required: isValueInpatient
        ? true
        : prev.is_preauthorization_required,
    }));
  };

  const handleChangeMedication = (payload) =>
    setFormData((prev) => ({
      ...prev,
      organization_bundle_medication: { medication: payload },
    }));

  const isInpatient = isInpatientVisitType(visit_type);
  const isOutpatient = isOutpatientVisitType(visit_type);

  return (
    <div>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12}>
          <CustomSection title="Code details" isCollapsable={false}>
            <div>
              <Grid container spacing={3} alignItems="flex-start">
                <Grid item xs={12} md={4}>
                  <CustomOutlinedTextField
                    required
                    autoFocus
                    label="Internal code"
                    name="internal_code"
                    value={internal_code}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <CustomOutlinedTextField
                    label="Description"
                    name="description"
                    value={description}
                    onChange={handleChange}
                  />
                </Grid>
                {isMedication && (
                  <Grid item xs={12}>
                    <MedicationAutocomplete
                      value={medication}
                      wrapperProps={{ flex: 1 }}
                      textFieldProps={{ required: true }}
                      handleChange={handleChangeMedication}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          </CustomSection>
        </Grid>
        <Grid item xs={12}>
          <CustomSection title="Pricing details" isCollapsable={false}>
            <div>
              <Grid container spacing={3} alignItems="flex-start">
                <Grid item xs={12} lg={4}>
                  <CustomRadioForm
                    label="Visit type"
                    value={visit_type}
                    options={ORGANIZATION_PATIENT_VISIT_TYPE_SHORTHAND_OPTIONS}
                    onChange={handleChangeVisitType}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3} alignItems="flex-start">
                    <Grid item xs={12} lg={4}>
                      <CustomOutlinedTextField
                        required
                        label="Undiscounted price"
                        name="undiscounted_price"
                        value={undiscounted_price}
                        onChange={handleChange}
                        type="number"
                        inputProps={{ min: 0, step: 0.000001 }}
                        InputProps={{
                          endAdornment: (
                            <CustomInputAdornment value={currency} />
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <CustomOutlinedTextField
                        required={isInpatient}
                        disabled={!isInpatient}
                        label={`${ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT_SHORTHAND} discount`}
                        name="inpatient_discount_rate"
                        value={inpatient_discount_rate}
                        onChange={handleChange}
                        type="number"
                        inputProps={{ min: 0, max: 100, step: 0.000001 }}
                        InputProps={{
                          endAdornment: <CustomInputAdornment value="%" />,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <CustomOutlinedTextField
                        required={isOutpatient}
                        disabled={!isOutpatient}
                        label={`${ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT_SHORTHAND} discount`}
                        name="outpatient_discount_rate"
                        value={outpatient_discount_rate}
                        onChange={handleChange}
                        type="number"
                        inputProps={{ min: 0, max: 100, step: 0.000001 }}
                        InputProps={{
                          endAdornment: <CustomInputAdornment value="%" />,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <CustomLabeledCheckbox
                    disabled={!isOutpatient}
                    checked={is_preauthorization_required}
                    name="is_preauthorization_required"
                    label="Require preauthorization?"
                    onChange={(e) =>
                      handleChangeBoolean({
                        fieldname: "is_preauthorization_required",
                        e,
                      })
                    }
                  />
                </Grid>
                {isProcedureAndConsumable && (
                  <Grid item>
                    <CustomLabeledCheckbox
                      checked={is_second_procedure_discount}
                      name="is_second_procedure_discount"
                      label="Second procedure discount?"
                      onChange={(e) =>
                        handleChangeBoolean({
                          fieldname: "is_second_procedure_discount",
                          e,
                        })
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          </CustomSection>
        </Grid>
      </Grid>
    </div>
  );
}
