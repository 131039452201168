import React from "react";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import Typography from "@mui/material/Typography";

export const isConsumableOptionEqualToValue = (option, value) =>
  option?.id === value?.id;

export const getConsumableOptionLabel = (option) => {
  if (!Boolean(option)) return "";
  const { name = "", description = "" } = option;
  return `${name} ${description}`.trim();
};

export const renderConsumableOption = (props, option) => {
  const { name, description } = option;
  return (
    <div {...props}>
      <div>
        <Typography variant="body2" fontWeight="bold">
          {name}
        </Typography>
        <Typography variant="body2" fontWeight="light">
          {description}
        </Typography>
      </div>
    </div>
  );
};

export const NoConsumableOption = ({ handleIsOpen = () => {} }) => {
  return (
    <div>
      <Typography variant="body2" display="inline" mr>
        {"Not in the list?"}
      </Typography>
      <CustomTypographyButton
        value="+ Create new consumable"
        color="textPrimary"
        onClick={handleIsOpen}
        display="inline"
      />
    </div>
  );
};
