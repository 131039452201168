import React from "react";
import { useQueryFetchCurrencies } from "@redux/actions/avey/currencies";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import {
  getCurrencyOptionLabel,
  isCurrencyOptionEqualToValue,
  renderCurrencyOption,
} from "./CurrencyAutocompleteComponents";

export default function CurrencyAutocomplete({
  value = null,
  handleChange = () => {},
  autocompleteProps = {},
  ...props
}) {
  return (
    <CustomInfiniteAutocomplete
      autocompleteProps={{
        renderOption: renderCurrencyOption,
        disableClearable: true,
        ...autocompleteProps,
      }}
      useQueryFn={useQueryFetchCurrencies}
      getOptionLabel={getCurrencyOptionLabel}
      isOptionEqualToValue={isCurrencyOptionEqualToValue}
      value={value}
      handleChange={handleChange}
      {...props}
    />
  );
}
