import React from "react";
import { CLAIM_MODE_ACTIVE_INDICATOR_COLOR_MAPPER } from "@constants/static/system-modes";

export default function OrganizationActiveIndicator({ isActive, claimMode }) {
  return (
    <div
      style={{
        left: 0,
        position: "absolute",
        width: isActive ? "8px" : 0,
        height: "100%",
        backgroundColor: CLAIM_MODE_ACTIVE_INDICATOR_COLOR_MAPPER[claimMode],
        borderRadius: "0px 24px 24px 0px",
      }}
    />
  );
}
