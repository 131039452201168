import React from "react";
import CustomButton from "@components/Custom/CustomButton";
import CustomCard from "@components/Custom/CustomCard";
import { PAYMENT_METHODS_FULL_HREF } from "@constants/static/routing";
import Typography from "@mui/material/Typography";
import { useQueryFetchPrimaryOrganizationCard } from "@redux/actions/organizations/organization-cards";
import { NavLink } from "react-router-dom";
import OrganizationCardContent from "./OrganizationCardContent";

export default function OrganizationCardPrimary() {
  const { isLoading, data } = useQueryFetchPrimaryOrganizationCard({});
  const hasPrimaryCard = Boolean(data);

  return (
    <CustomCard
      title="Payment method"
      action={
        <NavLink to={PAYMENT_METHODS_FULL_HREF}>
          <CustomButton label="Manage" />
        </NavLink>
      }
    >
      {!isLoading &&
        (hasPrimaryCard ? (
          <OrganizationCardContent object={data} withTooltip={true} />
        ) : (
          <Typography variant="body2">
            {"You currently have no primary payment methods."}
          </Typography>
        ))}
    </CustomCard>
  );
}
