import React from "react";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import CustomSection from "@components/Custom/CustomSection";
import {
  CLAIM_SECTION_ICD_ICD_CODE_SUBTITLE,
  CLAIM_SECTION_ICD_TITLE_MAPPER,
  CLAIM_SECTION_SYMPTOMS,
} from "@constants/claims/claims";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ClaimIcdData from "./ClaimIcdData";
import ClaimIcdsTextData from "./ClaimIcdsText/ClaimIcdsTextData";

export default function ClaimIcdsReviewAndConfirm({
  type = CLAIM_SECTION_SYMPTOMS,
  claim_icds = [],
  claim_icds_text = "",
}) {
  const title = CLAIM_SECTION_ICD_TITLE_MAPPER[type];
  const hasClaimIcds = claim_icds.length > 0;

  if (!(hasClaimIcds || claim_icds_text)) return null;

  return (
    <CustomSection title={title} isMinified={true}>
      <Stack spacing={2}>
        <CustomListItemCollapse primary={CLAIM_SECTION_ICD_ICD_CODE_SUBTITLE}>
          <Stack spacing={2}>
            {claim_icds.map((icd, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <Divider />}
                <ClaimIcdData index={index} type={type} object={icd} />
              </React.Fragment>
            ))}
          </Stack>
        </CustomListItemCollapse>
        <Divider />
        <ClaimIcdsTextData type={type} claim_icds_text={claim_icds_text} />
      </Stack>
    </CustomSection>
  );
}
