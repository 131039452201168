import React from "react";
import CustomPillButton from "@components/Custom/CustomPillButton";
import { customSectionBodyPadding } from "@components/Custom/CustomSection";
import {
  PLAN_TAG_GASTRIC_ULCERS,
  PLAN_TAG_PREGNANT,
  PLAN_TAG_PREGNANT_THIRD_TRIMESTER,
} from "@constants/avey-cowriter/scenarios";
import { PHOENIX_GENDER_FEMALE } from "@constants/organizations/organization-patients";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import {
  selectorClaimDemoParsedOrganizationPatient,
  selectorClaimDemoRecommendedMedications,
  selectorClaimDemoRecommendedProcedures,
  selectorClaimDemoSymptomTags,
  useClaimDemoContext,
} from "../context";

export default function ClaimDemoPlanTags() {
  const { state, handleUpdateClaimSymptomTags } = useClaimDemoContext();
  const claimSymptomTags = selectorClaimDemoSymptomTags(state);
  const parsedOrganizationPatient =
    selectorClaimDemoParsedOrganizationPatient(state);
  const { phoenixGender } = parsedOrganizationPatient;
  const recommendedProcedures = selectorClaimDemoRecommendedProcedures(state);
  const recommendedMedications = selectorClaimDemoRecommendedMedications(state);

  const isPregnant = claimSymptomTags.includes(PLAN_TAG_PREGNANT);
  const isThirdTrimester = claimSymptomTags.includes(
    PLAN_TAG_PREGNANT_THIRD_TRIMESTER
  );
  const hasGastricUlcers = claimSymptomTags.includes(PLAN_TAG_GASTRIC_ULCERS);
  const isFemale = phoenixGender === PHOENIX_GENDER_FEMALE;
  const hasRecommendations =
    recommendedProcedures.length > 0 && recommendedMedications.length > 0;

  return (
    <Collapse in={hasRecommendations}>
      <div>
        <div>
          <Stack spacing={2} direction="row" alignItems="center">
            {isFemale && (
              <>
                <CustomPillButton
                  label="Pregnant"
                  className={isPregnant ? "blackBg" : "greyBgF9 textPrimary"}
                  onClick={() =>
                    handleUpdateClaimSymptomTags(PLAN_TAG_PREGNANT)
                  }
                />
                {isPregnant && (
                  <CustomPillButton
                    label="Third trimester"
                    className={
                      isThirdTrimester ? "blackBg" : "greyBgF9 textPrimary"
                    }
                    onClick={() =>
                      handleUpdateClaimSymptomTags(
                        PLAN_TAG_PREGNANT_THIRD_TRIMESTER
                      )
                    }
                  />
                )}
              </>
            )}
            <CustomPillButton
              label="Gastric Ulcers"
              className={hasGastricUlcers ? "blackBg" : "greyBgF9 textPrimary"}
              onClick={() =>
                handleUpdateClaimSymptomTags(PLAN_TAG_GASTRIC_ULCERS)
              }
            />
          </Stack>
        </div>
        <Box py={customSectionBodyPadding}>
          <Divider />
        </Box>
      </div>
    </Collapse>
  );
}
