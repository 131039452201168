import React from "react";
import CustomTabPanel from "@components/Custom/CustomTabpanel";
import {
  CustomToggle,
  CustomToggleOption,
} from "@components/Custom/CustomToggle";
import {
  PAYMENT_PLAN_TYPE_OPTIONS,
  PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO,
  PAYMENT_PLAN_TYPE_SUBSCRIPTION,
  PAYMENT_PLAN_TYPE_TOGGLE_COLOR_MAPPER,
} from "@constants/payments/payments";
import { TabContext } from "@mui/lab";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import OrganizationPaymentCycleSubscriptionFormData from "./OrganizationPaymentCycleSubscriptionFormData";
import OrganizationPaymentCyclePayAsYouGoFormData from "./OrganizationPaymentCyclenPayAsYouGoFormData";

const PAYMENT_PLAN_TYPE_TITLE_MAPPER = {
  [PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO]: "Your budget",
  [PAYMENT_PLAN_TYPE_SUBSCRIPTION]: "Your plan",
};

export default function OrganizationPaymentCycleFormData({
  formData,
  setFormData = () => {},
}) {
  const {
    payment_plan_type,
    subscription_plan,
    pay_as_you_go_usage_token_threshold,
    pay_as_you_go_rate_per_token,
  } = formData;

  const handleChangePaymentPlanType = () =>
    setFormData((prevFormData) => ({
      ...prevFormData,
      payment_plan_type:
        payment_plan_type === PAYMENT_PLAN_TYPE_SUBSCRIPTION
          ? PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO
          : PAYMENT_PLAN_TYPE_SUBSCRIPTION,
    }));

  const handleChangePayAsYouGoUsageTokenThreshold = (e) =>
    setFormData((prev) => ({
      ...prev,
      pay_as_you_go_usage_token_threshold: e.target.value,
    }));

  const handleChangeSubscriptionPlan = (newValue) =>
    setFormData((prevFormData) => ({
      ...prevFormData,
      subscription_plan: newValue,
    }));

  return (
    <Stack spacing={3}>
      <PaymentPlanTypeToggle
        value={payment_plan_type}
        handleChange={handleChangePaymentPlanType}
      />
      <Typography fontWeight="bold" variant="h6" textAlign="center">
        {PAYMENT_PLAN_TYPE_TITLE_MAPPER[payment_plan_type]}
      </Typography>
      <div style={{ minHeight: 114 }}>
        <TabContext value={payment_plan_type}>
          <CustomTabPanel value={PAYMENT_PLAN_TYPE_SUBSCRIPTION}>
            <OrganizationPaymentCycleSubscriptionFormData
              subscription_plan={subscription_plan}
              handleChange={handleChangeSubscriptionPlan}
            />
          </CustomTabPanel>
          <CustomTabPanel value={PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO}>
            <OrganizationPaymentCyclePayAsYouGoFormData
              num_tokens={pay_as_you_go_usage_token_threshold}
              rate_per_token={pay_as_you_go_rate_per_token}
              handleChange={handleChangePayAsYouGoUsageTokenThreshold}
            />
          </CustomTabPanel>
        </TabContext>
      </div>
    </Stack>
  );
}

const PaymentPlanTypeToggle = ({ value, handleChange = () => {} }) => {
  return (
    <div>
      <CustomToggle
        color={PAYMENT_PLAN_TYPE_TOGGLE_COLOR_MAPPER[value]}
        className="outlined"
        value={value}
        onClick={handleChange}
      >
        {PAYMENT_PLAN_TYPE_OPTIONS.map(({ key, value }, index) => (
          <CustomToggleOption
            key={index}
            disableRipple
            label={value}
            value={key}
            minHeight={30}
            fontSize={14}
            selectedTextColor="black"
          />
        ))}
      </CustomToggle>
    </div>
  );
};
