import React from "react";
import { getDrugNameOptionLabel } from "@components/Custom/CustomAutocomplete/DrugNameAutocomplete/DrugNameAutocompleteComponents";
import { getMedicationOptionLabel } from "@components/Custom/CustomAutocomplete/MedicationAutocomplete/MedicationAutocompleteComponents";
import { DISABLED_ROW_CLASSNAME } from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { getMedicationsHref } from "@constants/static/routing";
import { parseTimeSince } from "@helpers/parse/time";
import Typography from "@mui/material/Typography";

export default function MedicationsGridProps({ hiddenColumns = [] }) {
  return {
    columns: [
      {
        flex: 1,
        field: "code",
        headerName: "Code",
        valueGetter: ({ value }) => value || "-",
      },
      {
        flex: 2,
        field: "generic_name__name",
        headerName: "Medication",
        renderCell: ({ row }) => (
          <Typography variant="body2" className="ellipsis-2">
            {getMedicationOptionLabel(row, false, false)}
          </Typography>
        ),
      },
      {
        flex: 2,
        sortable: false,
        field: "medication_active_ingredients",
        headerName: "Active ingredients",
        renderCell: ({ value }) => (
          <Typography variant="body2" className="ellipsis-2">
            {value
              ?.map((medication_active_ingredient) =>
                getDrugNameOptionLabel(
                  medication_active_ingredient?.generic_name
                )
              )
              .join(", ")}
          </Typography>
        ),
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ].filter(({ field }) => !hiddenColumns.includes(field)),
    getRowClassName: ({ row }) => row?.time_deleted && DISABLED_ROW_CLASSNAME,
    disableRowSelectionOnClick: true,
    navigateTo: ({ id }) => getMedicationsHref({ medication__id: id }),
  };
}
