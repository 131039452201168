import React from "react";
import CustomInputAdornment from "@components/Custom/CustomInputAdornment";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import CustomRadioForm from "@components/Custom/CustomRadioForm";
import { isInpatientVisitType } from "@constants/organizations/organization-patients";
import {
  ORGANIZATION_POLICY_COVERAGE_TYPE_OPTIONS,
  hasCoverageTypeCoverageRate,
  hasCoverageTypeFixedAmount,
} from "@constants/organizations/organization-policies";
import Grid from "@mui/material/Grid";

export default function OrganizationPolicyCoverageFormData({
  formData = {},
  handleChange = () => {},
}) {
  const {
    visit_type,
    coverage_type,
    fixed_amount,
    coverage_rate,
    preauthorization_limit,
    currency,
  } = formData;

  const isInpatient = isInpatientVisitType(visit_type);
  const hasFixedAmount = hasCoverageTypeFixedAmount(coverage_type);
  const hasCoverageRate = hasCoverageTypeCoverageRate(coverage_type);

  const handleChangeValue = (e) =>
    handleChange({ [e.target.name]: e.target.value });

  const handleChangeCoverageType = (newValue) =>
    handleChange({ coverage_type: newValue });

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomRadioForm
            label="Coverage type"
            value={coverage_type}
            options={ORGANIZATION_POLICY_COVERAGE_TYPE_OPTIONS}
            onChange={handleChangeCoverageType}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CustomOutlinedTextField
            required={hasCoverageRate}
            disabled={!hasCoverageRate}
            label="Coverage rate"
            name="coverage_rate"
            value={coverage_rate}
            onChange={handleChangeValue}
            type="number"
            inputProps={{ min: 0, max: 100, step: 0.000001 }}
            InputProps={{
              endAdornment: <CustomInputAdornment value="%" />,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CustomOutlinedTextField
            required={hasFixedAmount}
            disabled={!hasFixedAmount}
            label="Fixed amount"
            name="fixed_amount"
            value={fixed_amount}
            onChange={handleChangeValue}
            type="number"
            inputProps={{ min: 0, step: 0.000001 }}
            InputProps={{
              endAdornment: <CustomInputAdornment value={currency} />,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomOutlinedTextField
            required={!isInpatient}
            disabled={isInpatient}
            label="Preauthorization limit"
            name="preauthorization_limit"
            value={preauthorization_limit}
            onChange={handleChangeValue}
            type="number"
            inputProps={{ min: 0, step: 0.000001 }}
            InputProps={{
              endAdornment: <CustomInputAdornment value={currency} />,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
