import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import {
  HOME_HREF,
  HOME_TITLE,
  YOUR_ORGANIZATION_FULL_HREF,
  YOUR_ORGANIZATION_TITLE,
} from "@constants/static/routing";
import { selectorAuthCurrentUserOrganization } from "@redux/selectors/auth";
import { useSelector } from "react-redux";

const YOUR_ORGANIZATION_BASE_HREFS = [
  {
    label: HOME_TITLE,
    href: HOME_HREF,
  },
  {
    label: YOUR_ORGANIZATION_TITLE,
    href: YOUR_ORGANIZATION_FULL_HREF,
  },
];

export default function YourOrganizationHeader() {
  const organization = useSelector(selectorAuthCurrentUserOrganization);
  const { name } = organization;

  return (
    <CustomHeader
      titleComponentProps={{ title: name }}
      hrefs={YOUR_ORGANIZATION_BASE_HREFS}
    />
  );
}
