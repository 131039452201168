import React from "react";

export default function useWindowPopupHook({
  url,
  title = "",
  handleCloseCallback = () => {},
}) {
  React.useEffect(() => {
    let newWindow = null;
    const w = 500;
    const h = 550;
    const x = window.outerWidth / 2 + window.screenX - w / 2;
    const y = window.outerHeight / 2 + window.screenY - h / 2;

    if (url) {
      newWindow = window.open(
        url,
        title,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
      );
      newWindow?.focus();

      const checkPopupClose = setInterval(function () {
        if (newWindow?.closed) {
          clearInterval(checkPopupClose);
          handleCloseCallback("");
        }
      }, 250);
    }

    return () => newWindow && !newWindow.closed && newWindow.close();
  }, [url, title, handleCloseCallback]);
}
