import React from "react";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { useQueryFetchOrganizationConsumedTokens } from "@redux/actions/organizations/organization-consumed-tokens";
import OrganizationConsumedTokensGridProps from "./OrganizationConsumedTokensGridProps";

export default function OrganizationConsumedTokens({
  organization_payment_cycle__id,
}) {
  const useQueryFnProps = { filters: { organization_payment_cycle__id } };

  return (
    <CustomInfiniteDataGrid
      useQueryFn={useQueryFetchOrganizationConsumedTokens}
      useQueryFnProps={useQueryFnProps}
      dataGridProps={OrganizationConsumedTokensGridProps}
    />
  );
}
