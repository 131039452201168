import React from "react";
import HealthcareOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import CustomButton from "@components/Custom/CustomButton";
import { TOKEN_SHORT_NAME_SINGULAR } from "@constants/static/payment";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { tokensToMoney } from "@helpers/parse/tokens";
import { invalidateOrganizationCardsTSQ } from "@redux/actions/organizations/organization-cards";
import {
  invalidateOrganizationPaymentCyclesTSQ,
  settleAmountOrganizationPaymentCycle,
  updateOrganizationCurrentPaymentCycleObjectTSQ,
} from "@redux/actions/payments/organization-payment-cycles";
import { selectorAuthBillingConversion } from "@redux/selectors/auth";
import { enqueueSnackbar } from "notistack";
import pluralize from "pluralize";
import { useSelector } from "react-redux";

export default function OrganizationPaymentCycleBalanceFormActionPayAsYouGo({
  object = {},
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const { id, num_tokens, pay_as_you_go_rate_per_token } = object;
  const absNumTokens = Math.abs(num_tokens);
  const { isOpen: isOpenAlert } = alertState;
  const to_settle = {
    num_tokens: absNumTokens,
    rate_per_token: pay_as_you_go_rate_per_token,
  };
  const billing_conversion = useSelector(selectorAuthBillingConversion);

  const isDialogOpen = isOpen && !isOpenAlert;
  const amountToSettle = (
    <>
      {"Your current bill to settle is "}
      <b>
        {getIntOrFloat(absNumTokens)}{" "}
        {pluralize(TOKEN_SHORT_NAME_SINGULAR, absNumTokens, false)}
      </b>
      {", equivalent to "}
      <b>{tokensToMoney({ ...to_settle, billing_conversion })}</b>
      {"."}
    </>
  );

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    settleAmountOrganizationPaymentCycle({ id, to_settle })
      .then(({ payload }) => {
        enqueueSnackbar({
          variant: "success",
          message: "Bill successfully settled",
        });
        updateOrganizationCurrentPaymentCycleObjectTSQ({
          object: payload,
        });
        setIsOpen(false);
      })
      .catch(({ payload }) => {
        const { reason, latest_version } = payload;
        if (Boolean(latest_version)) {
          updateOrganizationCurrentPaymentCycleObjectTSQ({
            object: latest_version,
          });
          setAlertState({
            isOpen: true,
            errorStr:
              "A new payment cycle is in place, please verify before proceeding with this purchase.",
          });
        } else {
          setAlertState({ isOpen: true, errorStr: reason });
        }
      })
      .finally(() => {
        setIsProcessing(false);
        invalidateOrganizationPaymentCyclesTSQ();
        invalidateOrganizationCardsTSQ();
      });
  };

  React.useEffect(() => {
    if (isOpen) setAlertState(initialAlertState);
  }, [isOpen]);

  return (
    <>
      <CustomButton label="Settle bill" onClick={handleIsOpen} />
      <CustomActionDialog
        isOpen={isDialogOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
        titleComponentProps={{ title: "Outstanding bill" }}
        subtitleComponentProps={{ title: amountToSettle }}
        isBodyEmpty={true}
        noText="Cancel"
        yesText="Settle bill"
      />
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
    </>
  );
}
