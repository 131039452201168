import {
  apiWrapper,
  AveyBloomApi,
  emptyPaginatedResponse,
  getFormData,
  getQueryParams,
  parsePaginatedResponse,
  PayloadError,
} from "@helpers/api";
import {
  invalidateTSQ,
  updateObjectTSQ,
  updatePaginatedTSQ,
  useQueryWrapper,
} from "@helpers/tanstack";
import { updateClaimSubmissionUploadObjectTSQ } from "./claim-submission-uploads";

const endpointUrl = "/claims/claims/";
const exportEndpointUrl = "/claims/claim_exports/";
const queryKeySingular = "claim";
const queryKeyExports = `${queryKeySingular}_exports`;
const queryKeyPlural = "claims";
const queryKeySingularHistory = `${queryKeySingular}_history`;

export const useQueryFetchClaims = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = false,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchClaims,
        ...apiWrapperProps,
      })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchClaims({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(`${endpointUrl}?${queryParams}`);
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchClaimsExports = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = false,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [
      queryKeyExports,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchClaimExports, ...apiWrapperProps })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchClaimExports({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(
      `${exportEndpointUrl}?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchClaimHistory = ({
  id,
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [queryKeySingularHistory, { id, filters }],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchClaimHistory, ...apiWrapperProps })({
        page: pageParam,
        id,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchClaimHistory({ page = 1, id, filters = {} }) {
  try {
    const queryParams = getQueryParams({ page, ...filters });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${id}/history/?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchClaim = ({
  id = "",
  claim_submission__id = "",
  organization__slug = "",
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeySingular, { id, searchQuery, sortQuery, filters }],
    queryFn: () =>
      apiWrapper({
        fn: fetchClaim,
        ...apiWrapperProps,
      })({
        id,
        searchQuery,
        sortQuery,
        filters,
        claim_submission__id,
        organization__slug,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchClaim({
  id = "",
  searchQuery = "",
  sortQuery = "",
  filters = {},
  claim_submission__id = "",
  organization__slug = "",
}) {
  try {
    const queryParams = getQueryParams({
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
      claim_submission__id,
      organization__slug,
    });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${id}/?${queryParams}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function submitClaim(data) {
  try {
    const { claim_attachments, ...otherData } = data;
    let response;
    if (Boolean(data?.id)) {
      response = await AveyBloomApi().put(
        `${endpointUrl}${data?.id}/`,
        otherData
      );
    } else {
      response = await AveyBloomApi().post(endpointUrl, otherData);
    }

    const responseData = response.data;
    const hasAttachments = claim_attachments.length > 0;
    const newInstanceId = responseData?.instance?.id;
    if (newInstanceId && hasAttachments)
      return await uploadClaimAttachments({
        id: newInstanceId,
        data: claim_attachments,
      })
        .then(() => ({ payload: responseData }))
        .catch(({ payload }) => ({
          payload: responseData,
          errorStr: payload,
        }));

    return { payload: responseData };
  } catch (error) {
    throw new PayloadError({ payload: error?.response?.data?.reason });
  }
}

export async function uploadClaimAttachments({ id, data }) {
  try {
    const formData = getFormData(data);
    const response = await AveyBloomApi({
      headers: { "Content-Type": "multipart/form-data" },
    }).post(`${endpointUrl}${id}/upload_attachments/`, formData);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({ payload: error?.response?.data?.reason });
  }
}

export async function processClaim(data) {
  try {
    const response = await AveyBloomApi().post(
      `${endpointUrl}${data?.id}/process/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: {
        reason: error?.response?.data?.reason,
        latest_version_id: error?.response?.data?.latest_version_id,
      },
    });
  }
}

export async function deleteClaim(id) {
  try {
    const response = await AveyBloomApi().delete(`${endpointUrl}${id}/`);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: {
        reason: error?.response?.data?.reason,
        latest_version_id: error?.response?.data?.latest_version_id,
      },
    });
  }
}

export async function lockClaim(id) {
  try {
    const response = await AveyBloomApi().put(
      `${endpointUrl}${id}/toggle_lock/`,
      {}
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export function updateClaimObjectTSQ({ object }) {
  const {
    submitter_organization,
    processor_organization,
    tpa_organization,
    claim_submission_upload,
  } = object;
  const slugs = [
    submitter_organization?.slug,
    processor_organization?.slug,
    tpa_organization?.slug,
  ].filter(Boolean);

  updateObjectTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeySingular &&
      [object.id, object?.older_version_id].includes(queryKey[1].id),
    newQueryKey: [queryKeySingular, { id: object?.id }],
    object,
  });
  updatePaginatedTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeyPlural &&
      slugs.includes(queryKey[1]?.filters?.organization__slug),
    isObjectPredicate: ({ object, result }) =>
      [object.id, object?.older_version_id].includes(result.id),
    doInsertPredicate: doInsertPredicateForClaim,
    doRemovePredicate: doRemovePredicateForClaim,
    object,
  });
  updateClaimSubmissionUploadObjectTSQ({
    object: claim_submission_upload,
  });
}

export function updateClaimObjectsTSQ({ objects }) {
  objects.forEach((object) => {
    const { submitter_organization, processor_organization, tpa_organization } =
      object;
    const slugs = [
      submitter_organization?.slug,
      processor_organization?.slug,
      tpa_organization?.slug,
    ].filter(Boolean);
    updatePaginatedTSQ({
      predicate: ({ queryKey }) =>
        queryKey[0] === queryKeyPlural &&
        slugs.includes(queryKey[1]?.filters?.organization__slug),
      isObjectPredicate: ({ object, result }) =>
        [object.id, object?.older_version_id].includes(result.id),
      doInsertPredicate: doInsertPredicateForClaim,
      doRemovePredicate: doRemovePredicateForClaim,
      object,
    });
  });
}

function doInsertPredicateForClaim({ queryKey, object, pages }) {
  const isAllOrStatusFilter =
    queryKey[1]?.filters?.status == null ||
    queryKey[1]?.filters?.status === object.status;
  const currentPage = queryKey[1]?.page;
  const pageSize = queryKey[1]?.page_size;
  const isCurrentPageFull = pages[currentPage - 1]?.results?.length >= pageSize;
  return isAllOrStatusFilter && !isCurrentPageFull;
}

function doRemovePredicateForClaim({ queryKey, oldObject, newObject }) {
  const isOldStatusFilter = queryKey[1]?.filters?.status === oldObject.status;
  const isNewStatusFilter = queryKey[1]?.filters?.status === newObject.status;
  return isOldStatusFilter && !isNewStatusFilter;
}

export function invalidateClaimsTSQ({ organization__slug }) {
  invalidateTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeyPlural &&
      queryKey[1]?.filters?.organization__slug === organization__slug,
  });
}

export async function exportClaims({
  data = {},
  searchQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({ search: searchQuery, ...filters });
    const response = await AveyBloomApi().post(
      `${endpointUrl}export/?${queryParams}`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export function updateClaimExportObjectTSQ({ object }) {
  updatePaginatedTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeyExports &&
      (object?.claim_submission__id ===
        queryKey[1]?.filters?.claim_submission__id ||
        [
          object?.submitter_organization?.slug,
          object?.processor_organization?.slug,
        ].includes(queryKey[1].filters?.organization__slug)),
    object,
  });
}
