import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomSection from "@components/Custom/CustomSection";
import { CLAIM_SECTION_ORGANIZATION_CLINICIAN_TITLE } from "@constants/claims/claims";
import OrganizationClinicianSpecialitiesData from "./OrganizationClinicianSpecialitiesData";

export default function OrganizationClinicianData({
  object = {},
  useCustomSection = true,
  isMinified = false,
}) {
  if (!object) return null;

  const {
    first_name,
    last_name,
    clinician_id,
    specialities = [],
  } = object || {};

  return (
    <CustomSection
      title={CLAIM_SECTION_ORGANIZATION_CLINICIAN_TITLE}
      useCustomSection={useCustomSection}
      isMinified={isMinified}
    >
      <CustomGrid>
        <CustomLabeledText label="Clinician ID" value={clinician_id} />
        <CustomLabeledText
          label="Name"
          value={`${first_name} ${last_name}`.trim()}
        />
        <CustomLabeledText label="Specialities" useChildren={true}>
          <OrganizationClinicianSpecialitiesData
            specialities={specialities}
            divideAt={5}
          />
        </CustomLabeledText>
      </CustomGrid>
    </CustomSection>
  );
}
