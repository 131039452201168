import { getOptions } from "@helpers/hooks/objects";
import { getIntOrFloat } from "@helpers/parse/numbers";
import pluralize from "pluralize";
import { mdsMetaColsToMetaData } from "./claims";

export const CLAIM_MEDICATION_FREQUERCY_HOURS = "H";
export const CLAIM_MEDICATION_FREQUERCY_DAYS = "D";
export const CLAIM_MEDICATION_FREQUERCY_WEEKS = "W";
export const CLAIM_MEDICATION_FREQUERCY_MONTHS = "M";

const CLAIM_MEDICATION_FREQUENCY_MAPPER = {
  [CLAIM_MEDICATION_FREQUERCY_HOURS]: "Hour",
  [CLAIM_MEDICATION_FREQUERCY_DAYS]: "Day",
  [CLAIM_MEDICATION_FREQUERCY_WEEKS]: "Week",
  [CLAIM_MEDICATION_FREQUERCY_MONTHS]: "Month",
};

export const CLAIM_MEDICATION_FREQUENCY_OPTIONS = getOptions(
  CLAIM_MEDICATION_FREQUENCY_MAPPER
);

export const CLAIM_MEDICATION_DURATION_DAYS = "D";
export const CLAIM_MEDICATION_DURATION_WEEKS = "W";
export const CLAIM_MEDICATION_DURATION_MONTHS = "M";
export const CLAIM_MEDICATION_DURATION_YEARS = "Y";

const CLAIM_MEDICATION_DURATION_MAPPER = {
  [CLAIM_MEDICATION_DURATION_DAYS]: "Day",
  [CLAIM_MEDICATION_DURATION_WEEKS]: "Week",
  [CLAIM_MEDICATION_DURATION_MONTHS]: "Month",
  [CLAIM_MEDICATION_DURATION_YEARS]: "Year",
};

export const CLAIM_MEDICATION_DURATION_OPTIONS = getOptions(
  CLAIM_MEDICATION_DURATION_MAPPER
);

export const getClaimMedicationDosage = ({
  dosage_quantity,
  frequency,
  frequency_unit,
  duration,
  duration_unit,
  concentration_unit = "unit",
}) => {
  let dosage = pluralize(
    concentration_unit || "unit",
    getIntOrFloat(dosage_quantity || 0),
    true
  ); // x unit(s)

  if (Boolean(frequency) && Boolean(frequency_unit)) {
    const frequencyUnitStr =
      CLAIM_MEDICATION_FREQUENCY_MAPPER[frequency_unit]?.toLowerCase();
    const frequencyInt = Number(frequency || 0);
    const frequencyStr = pluralize(
      frequencyUnitStr,
      frequencyInt,
      frequencyInt !== 1
    );
    dosage += `, every ${frequencyStr}`; // every y hour(s)/day(s)/week(s)/month(s)
  }

  if (Boolean(duration) && Boolean(duration_unit)) {
    const durationUnitStr =
      CLAIM_MEDICATION_DURATION_MAPPER[duration_unit]?.toLowerCase();
    const durationStr = pluralize(durationUnitStr, Number(duration || 0), true);
    dosage += `, for ${durationStr}`; // for z day(s)/week(s)/month(s)/year(s)
  }

  // x unit(s)
  // x unit(s), every y hour(s)/day(s)/week(s)/month(s)
  // x unit(s), every y hour(s)/day(s)/week(s)/month(s), for z day(s)/week(s)/month(s)/year(s)
  return dosage;
};

export const getEmptyClaimMedicationFormData = ({
  currency = "",
  meta_mds_cols = [],
}) => ({
  currency,
  organization_bundle: null,
  amount_gross: 0,
  amount_discount: 0,
  amount_copayment: 0,
  amount_requested: 0,
  quantity: 1,
  related_date: new Date(),
  dosage_quantity: 1,
  frequency: 1,
  frequency_unit: CLAIM_MEDICATION_FREQUERCY_HOURS,
  duration: 1,
  duration_unit: CLAIM_MEDICATION_DURATION_DAYS,
  preauthorization_number: "",
  invoice_number: "",
  meta_data: mdsMetaColsToMetaData(meta_mds_cols),
});

export const claimProcedureToClaimMedication = ({
  claim_procedure,
  currency = "",
  meta_mds_cols = [],
}) => ({
  ...getEmptyClaimMedicationFormData({ currency, meta_mds_cols }),
  ...claim_procedure,
});
