import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomAvatar from "./CustomAvatar";

export default function CustomListAvatarItem({
  wrapperProps = {},
  imageProps = {},
  textProps = {},
}) {
  return (
    <Stack spacing={1.5} direction="row" alignItems="center" {...wrapperProps}>
      <CustomAvatar {...imageProps} />
      <Typography variant="body2" {...textProps} />
    </Stack>
  );
}
