import React from "react";
import CustomIconedAvatar from "@components/Custom/CustomIconedAvatar";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import { CustomExternalLink } from "@components/Custom/CustomLink";
import { SnackbarWrapper } from "@components/SnackbarProvider/CustomSnackbarContent";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CloseIcon } from "@rimads/assets";
import { closeSnackbar, SnackbarContent } from "notistack";

const CustomSnackbar = React.forwardRef((props, ref) => {
  const { id = undefined, context } = props;
  const {
    key = undefined,
    title,
    subtitle,
    image,
    file = undefined,
    isLoadingVariant = false,
    isErrorVariant = false,
    isSuccessVariant = false,
  } = context || {};
  const hasFile = Boolean(file);

  const onClose = () => closeSnackbar(key || id);

  return (
    <SnackbarContent ref={ref}>
      <SnackbarWrapper>
        <CustomIconedAvatar
          src={image}
          isErrorVariant={isErrorVariant}
          isSuccessVariant={isSuccessVariant}
        />
        <Stack spacing={1} flexGrow={1}>
          <div>
            <CustomGrid rowSpacing={0} columnSpacing={1} alignItems="baseline">
              {Boolean(title) && (
                <Typography variant="body2">{title}</Typography>
              )}
              {hasFile && (
                <CustomExternalLink
                  href={file}
                  label="Download file"
                  isTypography={true}
                  wrapperProps={{ download: true }}
                />
              )}
              {Boolean(subtitle) && (
                <Typography variant="body2" color="textSecondary">
                  {subtitle}
                </Typography>
              )}
            </CustomGrid>
          </div>
          {isLoadingVariant && (
            <LinearProgress className="thick" color="primary" />
          )}
        </Stack>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </SnackbarWrapper>
    </SnackbarContent>
  );
});

CustomSnackbar.displayName = "CustomSnackbar";

export default CustomSnackbar;
