import React from "react";
import ConfigurationsBulkUpload from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkUpload/ConfigurationsBulkUpload";
import ConfigurationsBulkUploads from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkUpload/ConfigurationsBulkUploads";
import { Navigate, Route, Routes } from "react-router-dom";

export default function UploadRoutes({ model_type, bundle_type }) {
  return (
    <Routes>
      <Route
        path=""
        element={
          <ConfigurationsBulkUploads
            model_type={model_type}
            bundle_type={bundle_type}
          />
        }
      />
      <Route
        path={":upload__id/*"}
        element={
          <ConfigurationsBulkUpload
            model_type={model_type}
            bundle_type={bundle_type}
          />
        }
      />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
