import React from "react";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  exportOrganizationPolicies,
  useQueryFetchOrganizationPolicyExports,
} from "@redux/actions/organizations/organization-policies";
import { useParams } from "react-router-dom";
import ConfigurationsBulkExportForm from "../../Configurations/ConfigurationsBulkExportForm";
import ConfigurationsBulkExportHistory from "../../Configurations/ConfigurationsBulkExportForm/ConfigurationsBulkExportHistory";
import InsuranceOrganizationActionDialogTitle from "../../Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import { describeFilters } from "./OrganizationPoliciesFilters";

export default function OrganizationPoliciesExports({ useQueryFnProps }) {
  const { organization__slug: processor_organization__slug } = useParams();
  return (
    <CustomMoreActions
      icon="DownloadIcon"
      useRotateProps={{ useRotate: false }}
    >
      <ConfigurationsBulkExportForm
        label="policies"
        submitFn={exportOrganizationPolicies}
        useQueryFnProps={useQueryFnProps}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        describeFilters={describeFilters}
      />
      <ConfigurationsBulkExportHistory
        drawerProps={{
          TitleComponent: InsuranceOrganizationActionDialogTitle,
          titleComponentProps: { title: "Policy exports" },
        }}
        useQueryFn={useQueryFetchOrganizationPolicyExports}
        useQueryFnProps={{ filters: { processor_organization__slug } }}
        describeFilters={describeFilters}
      />
    </CustomMoreActions>
  );
}
