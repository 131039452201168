import { getRowValue } from "@helpers/parse/csv";
import { v4 as uuidv4 } from "uuid";

export const emptyMedicationFormData = {
  code: "",
  trade_name: null,
  generic_name: null,
  concentration: "",
  concentration_unit: null,
  dosage_form: null,
  package_size: "",
  package_size_unit: null,
  package_type: null,
  medication_active_ingredients: [],
};

export const emptyMedicationActiveIngredientFormData = {
  generic_name: null,
  concentration: "",
  concentration_unit: null,
  package_size: "",
  package_size_unit: null,
};

export const formatMedicationFormData = ({ isCheck, formData }) => {
  const {
    id = undefined,
    code,
    trade_name,
    generic_name,
    concentration,
    concentration_unit,
    dosage_form,
    package_size,
    package_size_unit,
    package_type,
    medication_active_ingredients = [],
  } = formData;

  return {
    id,
    is_check: isCheck,
    code: code?.trim(),
    trade_name,
    generic_name,
    concentration,
    package_size,
    concentration_unit,
    dosage_form,
    package_size_unit,
    package_type,
    medication_active_ingredients,
  };
};

const medicationRowToObject = (row) => {
  const {
    index,
    trade_name,
    generic_name,
    concentration,
    concentration_unit,
    dosage_form,
    package_size,
    package_size_unit,
    package_type,
    medication_active_ingredient_generic_name,
    medication_active_ingredient_concentration,
    medication_active_ingredient_concentration_unit,
    medication_active_ingredient_package_size,
    medication_active_ingredient_package_size_unit,
  } = getRowValue({
    row,
    columns: [
      { headerName: "INDEX", fieldname: "index" },
      { headerName: "TRADE NAME", fieldname: "trade_name" },
      { headerName: "GENERIC NAME", fieldname: "generic_name" },
      { headerName: "CONCENTRATION", fieldname: "concentration" },
      { headerName: "CONCENTRATION UNIT", fieldname: "concentration_unit" },
      { headerName: "DOSAGE FORM", fieldname: "dosage_form" },
      { headerName: "PACKAGE SIZE", fieldname: "package_size" },
      { headerName: "PACKAGE SIZE UNIT", fieldname: "package_size_unit" },
      { headerName: "PACKAGE TYPE", fieldname: "package_type" },
      {
        headerName: "ACTIVE INGREDIENT GENERIC NAME",
        fieldname: "medication_active_ingredient_generic_name",
      },
      {
        headerName: "ACTIVE INGREDIENT CONCENTRATION",
        fieldname: "medication_active_ingredient_concentration",
      },
      {
        headerName: "ACTIVE INGREDIENT CONCENTRATION UNIT",
        fieldname: "medication_active_ingredient_concentration_unit",
      },
      {
        headerName: "ACTIVE INGREDIENT PACKAGE SIZE",
        fieldname: "medication_active_ingredient_package_size",
      },
      {
        headerName: "ACTIVE INGREDIENT PACKAGE SIZE UNIT",
        fieldname: "medication_active_ingredient_package_size_unit",
      },
    ],
  });

  let medication_active_ingredient = null;

  if (
    Boolean(medication_active_ingredient_generic_name) ||
    Boolean(medication_active_ingredient_concentration) ||
    Boolean(medication_active_ingredient_concentration_unit) ||
    Boolean(medication_active_ingredient_package_size) ||
    Boolean(medication_active_ingredient_package_size_unit)
  ) {
    medication_active_ingredient = {
      generic_name: {
        name: medication_active_ingredient_generic_name,
      },
      concentration: medication_active_ingredient_concentration,
      concentration_unit: {
        name: medication_active_ingredient_concentration_unit,
      },
      package_size: medication_active_ingredient_package_size,
      package_size_unit: {
        name: medication_active_ingredient_package_size_unit,
      },
    };
  }

  const isRowEmpty = !(
    trade_name ||
    generic_name ||
    concentration ||
    concentration_unit ||
    dosage_form ||
    package_size ||
    package_size_unit ||
    package_type ||
    medication_active_ingredient
  );

  const packageType = Boolean(package_type) ? { name: package_type } : null;
  const dosageForm = Boolean(dosage_form) ? { name: dosage_form } : null;
  const concentrationUnit = Boolean(concentration_unit)
    ? { name: concentration_unit }
    : null;
  const packageSizeUnit = Boolean(package_size_unit)
    ? { name: package_size_unit }
    : null;

  const tradeName = Boolean(trade_name) ? { name: trade_name } : null;
  const genericName = Boolean(generic_name) ? { name: generic_name } : null;

  return {
    id: isRowEmpty ? null : uuidv4(),
    index,
    trade_name: tradeName,
    generic_name: genericName,
    concentration,
    package_size,
    concentration_unit: concentrationUnit,
    dosage_form: dosageForm,
    package_size_unit: packageSizeUnit,
    package_type: packageType,
    medication_active_ingredient,
  };
};

export const medicationsRowsToObjects = (rows) => {
  const finalDict = {};
  rows
    .map((row) => medicationRowToObject(row))
    .filter(({ id }) => Boolean(id))
    .forEach(
      ({
        id,
        index,
        trade_name,
        generic_name,
        concentration,
        concentration_unit,
        dosage_form,
        package_size,
        package_size_unit,
        package_type,
        medication_active_ingredient,
      }) => {
        const prevInstance = {
          ...(finalDict[index] || emptyMedicationFormData),
        };
        const newInstance = {
          id,
          trade_name: trade_name || prevInstance.trade_name,
          generic_name: generic_name || prevInstance.generic_name,
          concentration: concentration || prevInstance.concentration,
          concentration_unit:
            concentration_unit || prevInstance.concentration_unit,
          dosage_form: dosage_form || prevInstance.dosage_form,
          package_size: package_size || prevInstance.package_size,
          package_size_unit:
            package_size_unit || prevInstance.package_size_unit,
          package_type: package_type || prevInstance.package_type,
        };
        const object = { ...prevInstance, ...newInstance };
        if (Boolean(medication_active_ingredient)) {
          finalDict[index] = {
            ...object,
            medication_active_ingredients: [
              ...object.medication_active_ingredients,
              medication_active_ingredient,
            ],
          };
        } else {
          finalDict[index] = object;
        }
      }
    );

  return Object.values(finalDict);
};

export const getConcentrationUnitStr = ({
  concentration,
  concentration_unit,
}) => `${concentration || ""} ${concentration_unit?.name || ""}`.trim();

export const getPackageSizeUnitStr = ({
  package_size,
  package_size_unit,
  package_type,
}) => {
  if (
    !Boolean(package_size) &&
    !Boolean(package_size_unit) &&
    !Boolean(package_type)
  )
    return "";

  let output = `${package_size || ""} ${package_size_unit?.name || "-"}`.trim();

  if (Boolean(package_type)) output += ` / ${package_type?.name || "-"}`.trim();

  return output;
};

export const getDosageFormUnitStr = ({ dosage_form }) =>
  dosage_form?.name || "";

export const getDrugName = ({ drug_name }) => drug_name?.name || "";
