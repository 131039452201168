import dayjs from "dayjs";
import pluralize from "pluralize";

const secondsInMinute = 60;
const secondsInHour = 60 * secondsInMinute;
const secondsInDay = 24 * secondsInHour;
const secondsInMonth = 30 * secondsInDay;
const secondsInYear = 365 * secondsInDay;

const getTimeSince = (value) => {
  const now = dayjs();
  const newDate = dayjs.utc(value); // If the date is in UTC
  const diffInSeconds = now.diff(newDate, "second");

  const units = [
    { name: "year", seconds: secondsInYear },
    { name: "month", seconds: secondsInMonth },
    { name: "day", seconds: secondsInDay },
    { name: "hour", seconds: secondsInHour },
    { name: "minute", seconds: secondsInMinute },
  ];

  for (const unit of units) {
    const { name, seconds } = unit;
    const interval = Math.floor(diffInSeconds / seconds);
    if (interval >= 1) return pluralize(name, interval, true);
  }

  return "a few seconds";
};

export const isValidDate = (value) => {
  if (!value) return false;
  const date = new Date(value);
  return date instanceof Date && !isNaN(date);
};

export const parseTimeSince = ({ prefix = "", value, suffix = "" }) => {
  if (!isValidDate(value)) return value;
  const isMoreThanADay = dayjs().diff(dayjs.utc(value), "day") > 1;
  if (isMoreThanADay) return parseDate(value);
  return [prefix, getTimeSince(value), suffix].join(" ").trim();
};

export const parseTime = ({
  value,
  format = "dddd, MMMM Do, YYYY [at] h:mm A",
}) => (isValidDate(value) ? dayjs(value).format(format) : value);

export const parseMMYY = (value) => parseTime({ value, format: "MM/YY" });

export const parseDate = (value) =>
  parseTime({ value, format: "MMMM Do YYYY" });

export const parseIsoDate = (value) =>
  parseTime({ value, format: "YYYY-MM-DD" });

export const parseIsoDatetime = (value) =>
  parseTime({ value, format: "YYYY-MM-DDTHH:mm:ss[Z]" });

export const parseTimestamp = ({ value, useSeconds = false }) => {
  if (!isValidDate(value)) return value;
  const currentYear = new Date().getFullYear();
  const timestampYear = new Date(value).getFullYear();
  const isSameYear = currentYear === timestampYear;
  let format = isSameYear ? "Do MMMM" : "Do MMMM YYYY";
  format += useSeconds ? ", h:mm:ss A" : ", h:mm A";
  return parseTime({ value, format });
};

export const parseMonthRange = ({ start_date, end_date }) => {
  const startDate = parseTime({ value: start_date, format: "MMM Do" });
  const endDate = parseTime({ value: end_date, format: "MMM Do, YYYY" });
  return `${startDate} - ${endDate}`;
};

export const parseDob = (dob) => {
  if (!dob) return "Unknown";

  const birthDate = dayjs(dob);
  const currentDate = dayjs();
  const years = currentDate.diff(birthDate, "year");
  const months = currentDate.diff(birthDate.add(years, "year"), "month");

  return {
    ageStr: `${years}y ${months}m`,
    ageInt: years,
    dobStr: parseDate(dob),
  };
};

export const formatDateRange = ({ start_date, end_date }) => {
  let startDate = start_date ? dayjs(start_date) : null;
  let endDate = end_date ? dayjs(end_date) : null;

  if (!(startDate || endDate)) return null;
  if (!startDate) return "Up to " + parseDate(endDate);
  if (!endDate) return "From " + parseDate(startDate);
  if (startDate.year() === endDate.year()) {
    if (startDate.month() === endDate.month()) {
      startDate = parseTime({ value: startDate, format: "MMMM Do" });
      endDate = parseTime({ value: endDate, format: "Do YYYY" });
    } else {
      startDate = parseTime({ value: startDate, format: "MMMM Do" });
      endDate = parseDate(endDate);
    }
  } else {
    startDate = parseDate(startDate);
    endDate = parseDate(endDate);
  }

  return `${startDate} to ${endDate}`;
};
