import React from "react";
import { useQueryFetchCpts } from "@redux/actions/avey/cpts";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import {
  getCptOptionLabel,
  isCptOptionEqualToValue,
  renderCptOption,
} from "./CptAutocompleteComponents";

const useQueryFnProps = {
  filters: {
    time_deleted__isnull: true,
    newer_version__isnull: true,
  },
  useInfiniteWrapper: true,
};

export default function CptAutocomplete({
  label = "CPT code",
  error = false,
  value,
  handleChange = () => {},
  wrapperProps = {},
  textFieldProps = {},
  autocompleteProps = {},
}) {
  return (
    <CustomInfiniteAutocomplete
      label={label}
      error={error}
      wrapperProps={wrapperProps}
      textFieldProps={{ required: true, ...textFieldProps }}
      autocompleteProps={{
        disableClearable: true,
        renderOption: (props, option) => renderCptOption(props, option),
        ...autocompleteProps,
      }}
      useQueryFn={useQueryFetchCpts}
      useQueryFnProps={useQueryFnProps}
      getOptionLabel={getCptOptionLabel}
      isOptionEqualToValue={isCptOptionEqualToValue}
      value={value}
      handleChange={handleChange}
    />
  );
}
