import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomBox from "./CustomBox";

export default function CustomCard({ title = "", action = null, children }) {
  return (
    <div style={{ height: "100%", display: "grid" }}>
      <CustomBox
        className="elevated-8 borderRadius-8 elevateOnHover-12"
        px={4}
        py={3}
      >
        <Stack spacing={1} display="flex" flexDirection="column" height="100%">
          <div style={{ flexGrow: 1 }}>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight="bold"
                mr={2}
                title={title}
                className="ellipsis-1"
              >
                {title}
              </Typography>
              {action}
            </Stack>
          </div>
          <div style={{ flexGrow: 1 }}>{children}</div>
        </Stack>
      </CustomBox>
    </div>
  );
}
