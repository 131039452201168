import React from "react";
import CustomInputAdornment from "@components/Custom/CustomInputAdornment";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import {
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_COPAYMENT,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_DISCOUNT,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_GROSS,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_REQUESTED,
} from "@constants/claims/claim-evaluation-results";
import Grid from "@mui/material/Grid";

export default function SubclaimBillingDetailsFormData({
  formData,
  setFormData = () => {},
  errorFieldnames = {},
}) {
  const {
    currency,
    amount_gross,
    amount_discount,
    amount_copayment,
    amount_requested,
  } = formData;

  const {
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_GROSS]: amount_gross_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_DISCOUNT]:
      amount_discount_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_COPAYMENT]:
      amount_copayment_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_REQUESTED]:
      amount_requested_error = false,
  } = errorFieldnames;

  const handleChangeValue = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const amounts = [
    {
      label: "Gross amount",
      name: "amount_gross",
      value: amount_gross,
      error: amount_gross_error,
    },
    {
      label: "Discount amount",
      name: "amount_discount",
      value: amount_discount,
      error: amount_discount_error,
    },
    {
      label: "Copayment amount",
      name: "amount_copayment",
      value: amount_copayment,
      error: amount_copayment_error,
    },
    {
      label: "Requested amount",
      name: "amount_requested",
      value: amount_requested,
      error: amount_requested_error,
    },
  ];

  return (
    <CustomListItemCollapse primary="Billing details" useCollapse={false}>
      <Grid container spacing={3}>
        {amounts.map(({ label, name, value, error }) => (
          <Grid item key={name} xs={12} sm={6}>
            <CustomOutlinedTextField
              label={label}
              name={name}
              value={value}
              error={error}
              type="number"
              inputProps={{ min: 0, step: 0.000001 }}
              onChange={handleChangeValue}
              InputProps={{
                endAdornment: <CustomInputAdornment value={currency} />,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </CustomListItemCollapse>
  );
}
