import { getOptions } from "@helpers/hooks/objects";
import { getRowValue } from "@helpers/parse/csv";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import {
  ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT,
  ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT,
  isInpatientVisitType,
} from "./organization-patients";

const ORGANIZATION_POLICY_COVERAGE_TYPE_COPAYMENT = "C";
const ORGANIZATION_POLICY_COVERAGE_TYPE_VARIED_COPAYMENT = "V";
const ORGANIZATION_POLICY_COVERAGE_TYPE_COINSURANCE = "I";

export const ORGANIZATION_POLICY_COVERAGE_VISIT_MAPPER = {
  [ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT]: "Inpatient benefits",
  [ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT]: "Outpatient benefits",
};

export const ORGANIZATION_POLICY_COVERAGE_TYPE_MAPPER = {
  [ORGANIZATION_POLICY_COVERAGE_TYPE_COPAYMENT]: "Copayment",
  [ORGANIZATION_POLICY_COVERAGE_TYPE_VARIED_COPAYMENT]: "Varied copayment",
  [ORGANIZATION_POLICY_COVERAGE_TYPE_COINSURANCE]: "Coinsurance",
};

export const ORGANIZATION_POLICY_COVERAGE_TYPE_OPTIONS = getOptions(
  ORGANIZATION_POLICY_COVERAGE_TYPE_MAPPER
);

const emptyOrganizationPolicyFormData = {
  number: "",
  name: "",
  description: "",
  insurer_name: "",
  expiry_date: null,
};

export const emptyInpatientOrganizationPolicyCoverageFormData = {
  visit_type: ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT,
  coverage_type: ORGANIZATION_POLICY_COVERAGE_TYPE_COPAYMENT,
  fixed_amount: 0,
  coverage_rate: 0,
  preauthorization_limit: 0,
};

export const emptyOutpatientOrganizationPolicyCoverageFormData = {
  visit_type: ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT,
  coverage_type: ORGANIZATION_POLICY_COVERAGE_TYPE_COPAYMENT,
  fixed_amount: 0,
  coverage_rate: 0,
  preauthorization_limit: 0,
};

export const getEmptyOrganizationPolicyFormData = ({ currency = "" }) => ({
  ...emptyOrganizationPolicyFormData,
  organization_policy_coverages: [
    { ...emptyInpatientOrganizationPolicyCoverageFormData, currency },
    { ...emptyOutpatientOrganizationPolicyCoverageFormData, currency },
  ],
});

export const formatOrganizationPolicyFormData = ({
  isCheck,
  processor_organization__slug,
  formData,
}) => {
  const {
    id = undefined,
    number = "",
    name = "",
    description = "",
    insurer_name = "",
    expiry_date = null,
    organization_policy_coverages = [],
  } = formData;

  return {
    id,
    is_check: isCheck,
    processor_organization__slug,
    number: number?.trim(),
    name: name?.trim(),
    description: description?.trim(),
    insurer_name: insurer_name?.trim(),
    expiry_date: expiry_date ? dayjs(expiry_date).format("YYYY-MM-DD") : null,
    organization_policy_coverages: organization_policy_coverages.map(
      (organizationPolicyCoverageFormData) => {
        const {
          coverage_type,
          visit_type,
          fixed_amount,
          coverage_rate,
          preauthorization_limit,
        } = organizationPolicyCoverageFormData;

        const isInpatient = isInpatientVisitType(visit_type);
        const hasFixedAmount = hasCoverageTypeFixedAmount(coverage_type);
        const hasCoverageRate = hasCoverageTypeCoverageRate(coverage_type);

        return {
          coverage_type,
          visit_type,
          fixed_amount: hasFixedAmount ? parseFloat(fixed_amount) || 0 : 0,
          coverage_rate: hasCoverageRate ? parseFloat(coverage_rate) || 0 : 0,
          preauthorization_limit: isInpatient
            ? 0
            : parseFloat(preauthorization_limit) || 0,
        };
      }
    ),
  };
};

const organizationPolicyRowToObject = ({ row, currency }) => {
  const {
    number,
    name,
    description,
    insurer_name,
    expiry_date,
    inpatient_policy_coverage_type,
    inpatient_policy_fixed_amount,
    inpatient_policy_coverage_rate,
    outpatient_policty_coverage_type,
    outpatient_policty_fixed_amount,
    outpatient_policty_coverage_rate,
    outpatient_policty_preauthorization_limit,
  } = getRowValue({
    row,
    columns: [
      { headerName: "NUMBER", fieldname: "number" },
      { headerName: "NAME", fieldname: "name" },
      { headerName: "SCHEME", fieldname: "description" },
      { headerName: "INSURER NAME", fieldname: "insurer_name" },
      {
        headerName: "EXPIRY DATE",
        fieldname: "expiry_date",
        valueGetter: (value) =>
          value ? dayjs(value).format("YYYY-MM-DD") : null,
      },
      {
        headerName: "INPATIENT COVERAGE TYPE",
        fieldname: "inpatient_policy_coverage_type",
      },
      {
        headerName: "INPATIENT FIXED AMOUNT",
        fieldname: "inpatient_policy_fixed_amount",
      },
      {
        headerName: "INPATIENT COVERAGE RATE",
        fieldname: "inpatient_policy_coverage_rate",
      },
      {
        headerName: "OUTPATIENT COVERAGE TYPE",
        fieldname: "outpatient_policty_coverage_type",
      },
      {
        headerName: "OUTPATIENT FIXED AMOUNT",
        fieldname: "outpatient_policty_fixed_amount",
      },
      {
        headerName: "OUTPATIENT COVERAGE RATE",
        fieldname: "outpatient_policty_coverage_rate",
      },
      {
        headerName: "OUTPATIENT PREAUTHORIZATION LIMIT",
        fieldname: "outpatient_policty_preauthorization_limit",
      },
    ],
  });

  const isRowEmpty = !(
    number ||
    name ||
    description ||
    insurer_name ||
    expiry_date ||
    inpatient_policy_coverage_type ||
    inpatient_policy_fixed_amount ||
    inpatient_policy_coverage_rate ||
    outpatient_policty_coverage_type ||
    outpatient_policty_fixed_amount ||
    outpatient_policty_coverage_rate ||
    outpatient_policty_preauthorization_limit
  );
  return {
    id: isRowEmpty ? null : uuidv4(),
    number,
    name,
    description,
    insurer_name,
    expiry_date,
    organization_policy_coverages: [
      {
        visit_type: ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT,
        coverage_type: inpatient_policy_coverage_type,
        fixed_amount: inpatient_policy_fixed_amount,
        coverage_rate: inpatient_policy_coverage_rate,
        preauthorization_limit: "0",
        currency,
      },
      {
        visit_type: ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT,
        coverage_type: outpatient_policty_coverage_type,
        fixed_amount: outpatient_policty_fixed_amount,
        coverage_rate: outpatient_policty_coverage_rate,
        preauthorization_limit: outpatient_policty_preauthorization_limit,
        currency,
      },
    ],
  };
};

export const organizationPolicyRowsToObjects = ({ rows, currency }) => {
  return rows
    .map((row) => organizationPolicyRowToObject({ row, currency }))
    .filter(({ id }) => Boolean(id));
};

export const hasCoverageTypeFixedAmount = (coverage_type) =>
  [
    ORGANIZATION_POLICY_COVERAGE_TYPE_COPAYMENT,
    ORGANIZATION_POLICY_COVERAGE_TYPE_VARIED_COPAYMENT,
  ].includes(coverage_type);

export const hasCoverageTypeCoverageRate = (coverage_type) =>
  [
    ORGANIZATION_POLICY_COVERAGE_TYPE_VARIED_COPAYMENT,
    ORGANIZATION_POLICY_COVERAGE_TYPE_COINSURANCE,
  ].includes(coverage_type);
