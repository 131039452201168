import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  BILLING_MANAGEMENT_FULL_HREF,
  BILLING_MANAGEMENT_TITLE,
  getOrganizationPaymentCycleHref,
  HOME_HREF,
  HOME_TITLE,
} from "@constants/static/routing";
import { parseMonthRange } from "@helpers/parse/time";
import BillingManagementCurrencyPicker from "../../BillingManagement/BillingManagementCurrencyPicker";
import OrganizationPaymentCyclePaymentPlanType from "../../OrganizationWallet/OrganizationPaymentCycle/OrganizationPaymentCyclePaymentPlanType";
import OrganizationPaymentCycleVersionHistory from "./OrganizationPaymentCycleVersionHistory";

export function OrganizationPaymentCycleHeaderTitle({
  variant = "h3",
  object,
}) {
  const { payment_plan_type, version, start_date, end_date } = object;
  const title = parseMonthRange({ start_date, end_date });

  return (
    <CustomHeaderTitle title={title} variant={variant} version={version}>
      <OrganizationPaymentCyclePaymentPlanType
        payment_plan_type={payment_plan_type}
        withTooltip={false}
      />
    </CustomHeaderTitle>
  );
}

export default function OrganizationPaymentCycleHeader({ object }) {
  const { id, start_date, end_date } = object || {};
  const title = parseMonthRange({ start_date, end_date });

  const hrefs = [
    {
      label: HOME_TITLE,
      href: HOME_HREF,
    },
    {
      label: BILLING_MANAGEMENT_TITLE,
      href: BILLING_MANAGEMENT_FULL_HREF,
    },
    {
      label: title,
      href: getOrganizationPaymentCycleHref({
        organization_payment_cycle__id: id,
      }),
    },
  ];

  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={OrganizationPaymentCycleHeaderTitle}
      titleComponentProps={{ object }}
    >
      <BillingManagementCurrencyPicker />
      <CustomMoreActions>
        <OrganizationPaymentCycleVersionHistory />
      </CustomMoreActions>
    </CustomHeader>
  );
}
