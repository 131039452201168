import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import CustomMetaData from "@components/Custom/CustomMetaData";
import {
  CLAIM_MEDICATION_ERROR_FIELDNAMES_INTERNAL_CODE,
  CLAIM_MEDICATION_ERROR_FIELDNAMES_INVOICE_NUMBER,
  CLAIM_MEDICATION_ERROR_FIELDNAMES_ORGANIZATION_BUNDLE,
  CLAIM_MEDICATION_ERROR_FIELDNAMES_PREAUTHORIZATION_NUMBER,
  CLAIM_MEDICATION_ERROR_FIELDNAMES_QUANTITY,
  CLAIM_MEDICATION_ERROR_FIELDNAMES_RELATED_DATE,
  isErrorFieldnames,
} from "@constants/claims/claim-evaluation-results";
import { getClaimMedicationDosage } from "@constants/claims/claim-medications";
import { parseDate } from "@helpers/parse/time";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import SubclaimBillingDetailsData from "../Subclaim/SubclaimBillingDetailsData";

export default function ClaimMedicationData({
  object,
  useErrorFieldnames = true,
}) {
  const {
    organization_bundle,
    quantity,
    dosage_quantity,
    frequency,
    frequency_unit,
    duration,
    duration_unit,
    related_date,
    preauthorization_number,
    invoice_number,
    meta_data = {},
    error_fieldnames = [],
    ...otherObjectProps
  } = object;

  const errorFieldnames = isErrorFieldnames(
    useErrorFieldnames ? error_fieldnames : []
  );

  const {
    [CLAIM_MEDICATION_ERROR_FIELDNAMES_ORGANIZATION_BUNDLE]:
      organization_bundle_error = false,
    [CLAIM_MEDICATION_ERROR_FIELDNAMES_QUANTITY]: quantity_error = false,
    [CLAIM_MEDICATION_ERROR_FIELDNAMES_RELATED_DATE]:
      related_date_error = false,
    [CLAIM_MEDICATION_ERROR_FIELDNAMES_PREAUTHORIZATION_NUMBER]:
      preauthorization_number_error = false,
    [CLAIM_MEDICATION_ERROR_FIELDNAMES_INTERNAL_CODE]:
      internal_code_error = false,
    [CLAIM_MEDICATION_ERROR_FIELDNAMES_INVOICE_NUMBER]:
      invoice_number_error = false,
  } = errorFieldnames;

  const { internal_code, description } = organization_bundle || {};
  const hasDescription = Boolean(description);
  const relatedDate = parseDate(related_date);

  const medicationDosage = getClaimMedicationDosage({
    dosage_quantity,
    frequency,
    frequency_unit,
    duration,
    duration_unit,
  });

  return (
    <Stack spacing={3} flexGrow={1}>
      <CustomListItemCollapse primary="Medication details">
        <CustomGrid>
          <CustomLabeledText
            label="Internal code"
            value={internal_code}
            isErrorField={organization_bundle_error || internal_code_error}
          />
          {hasDescription && (
            <CustomLabeledText
              label="Internal code description"
              value={description}
            />
          )}
          <CustomLabeledText
            label="Quantity"
            value={quantity}
            isErrorField={quantity_error}
          />
          <CustomLabeledText
            label="Dispensed date"
            value={relatedDate}
            isErrorField={related_date_error}
          />
          <CustomLabeledText label="Dosage" value={medicationDosage} />
          <CustomLabeledText
            label="Invoice number"
            value={invoice_number}
            isErrorField={invoice_number_error}
          />
          <CustomLabeledText
            label="Preauthorization number"
            value={preauthorization_number}
            isErrorField={preauthorization_number_error}
          />
          <CustomMetaData meta_data={meta_data} />
        </CustomGrid>
      </CustomListItemCollapse>
      <Divider />
      <SubclaimBillingDetailsData
        object={otherObjectProps}
        errorFieldnames={errorFieldnames}
      />
    </Stack>
  );
}
