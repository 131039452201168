import React from "react";
import CustomBox from "@components/Custom/CustomBox";
import { parseTimestamp } from "@helpers/parse/time";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useClaimContext } from "../Claim/context";

export default function ClaimNotes() {
  const { claim = {} } = useClaimContext();
  const { claim_notes = [] } = claim;

  if (claim_notes.length === 0) return null;

  return (
    <div>
      <Grid container spacing={3}>
        {claim_notes.map((claim_note, index) => (
          <Grid item xs={12} md={6} key={index}>
            <ClaimNote claim_note={claim_note} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

const ClaimNote = ({ claim_note }) => {
  const { organization, note, is_creator, time_created } = claim_note;
  const { name } = organization || {};
  const title = is_creator ? "Your note" : `Note by ${name}`;
  const timestamp = parseTimestamp({ value: time_created });

  return (
    <CustomBox className="greyBgF9 borderRadius-8" p="16px 24px">
      <Stack
        spacing={2}
        direction="row"
        alignItems="cener"
        justifyContent="space-between"
      >
        <Typography variant="body2" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {timestamp}
        </Typography>
      </Stack>
      <Typography fontStyle="italic">{`"${note}"`}</Typography>
    </CustomBox>
  );
};
