import React from "react";
import OrganizationClinicianData from "@components/Authenticated/Configurations/OrganizationClinicians/OrganizationClinician/OrganizationClinicianData";
import OrganizationClinicianCreateForm from "@components/Authenticated/Configurations/OrganizationClinicians/OrganizationClinician/OrganizationClinicianForms/OrganizationClinicianCreateForm";
import OrganizationClinicianUpdateForm from "@components/Authenticated/Configurations/OrganizationClinicians/OrganizationClinician/OrganizationClinicianForms/OrganizationClinicianUpdateForm";
import CustomActionButtons from "@components/Custom/CustomActionButtons";
import OrganizationClinicianAutocomplete from "@components/Custom/CustomAutocomplete/OrganizationClinicianAutocomplete";
import CustomBox from "@components/Custom/CustomBox";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { selectorAuthHasAdminPrivileges } from "@redux/selectors/auth";
import { useSelector } from "react-redux";

export default function ClaimOrganizationClinicianFormData({
  formData,
  handleChangeOrganizationClinician = () => {},
}) {
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const handleRemove = () => handleChangeOrganizationClinician(null);

  if (Boolean(formData)) {
    return (
      <Stack direction="row" spacing={3}>
        <CustomBox flexGrow={1}>
          <OrganizationClinicianData
            object={formData}
            useCustomSection={false}
          />
        </CustomBox>
        <CustomActionButtons
          direction="row-reverse"
          handleRemove={handleRemove}
          CustomEditComponent={OrganizationClinicianUpdateForm}
          customEditComponentProps={{
            object: formData,
            handleSelectClinician: handleChangeOrganizationClinician,
            isSquare: true,
            withNavigate: false,
          }}
        />
      </Stack>
    );
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={6}>
        <OrganizationClinicianAutocomplete
          handleChange={handleChangeOrganizationClinician}
        />
      </Grid>
      {hasAdminPrivileges && (
        <Grid
          item
          xs={12}
          md={6}
          display="grid"
          justifyContent={{ xs: "flex-end", md: "flex-start" }}
        >
          <OrganizationClinicianCreateForm
            handleSelectClinician={handleChangeOrganizationClinician}
            isTypographyButton={true}
          />
        </Grid>
      )}
    </Grid>
  );
}
