import React from "react";
import CustomActionDialog from ".";
import CustomBulletPoints from "../CustomBulletPoints";

export const initialAlertState = {
  errorStr: "",
  errors: [],
  isOpen: false,
};

export function CustomAlertDialog({ state = {}, setAlertState = () => {} }) {
  const { errorStr = "", errors = [], isOpen = false } = state;
  const handleClose = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    setAlertState((prev) => ({ ...prev, isOpen: false }));
  };
  const allErrors = [errorStr, ...errors].filter(Boolean);

  return (
    <CustomActionDialog
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleClose}
      titleComponentProps={{ title: "Alert" }}
      isOk={true}
      okText="Ok"
    >
      <CustomBulletPoints bulletpoints={allErrors} />
    </CustomActionDialog>
  );
}
