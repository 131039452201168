import React from "react";
import Typography from "@mui/material/Typography";
import ClaimDemoSymptom from "./ClaimDemoSymptom";
import { useClaimDemoContext } from "../context";

export default function ClaimDemoSymptomsList({ type, symptoms, emptyLabel }) {
  const { handleUpdateClaimSymptom, handleRemoveClaimSymptom } =
    useClaimDemoContext();

  if (symptoms.length === 0)
    return (
      <Typography
        variant="body2"
        color="textSecondary"
        minHeight={30}
        display="grid"
        alignItems="center"
      >
        {emptyLabel}
      </Typography>
    );

  return (
    <div>
      {symptoms.map((object, index) => (
        <ClaimDemoSymptom
          key={object?._id}
          object={object}
          type={type}
          handleRemoveSymptom={handleRemoveClaimSymptom}
          handleUpdateSymptom={handleUpdateClaimSymptom}
        />
      ))}
    </div>
  );
}
