import React from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const aspect = 1;

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) =>
  centerCrop(
    makeAspectCrop({ unit: "%", width: 100 }, aspect, mediaWidth, mediaHeight),
    mediaWidth,
    mediaHeight
  );

export default function CustomImageCropper({
  src = "",
  imgRef = null,
  setCompletedCrop = () => {},
}) {
  const [crop, setCrop] = React.useState(undefined);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
  };

  const handleChangeCrop = (_, newCrop) => setCrop(newCrop);

  return (
    <ReactCrop
      crop={crop}
      onChange={handleChangeCrop}
      onComplete={setCompletedCrop}
      aspect={aspect}
      minHeight={100}
      style={{ width: "100%" }}
    >
      <img
        ref={imgRef}
        alt="Cropped instance"
        width="100%"
        src={src}
        onLoad={onImageLoad}
      />
    </ReactCrop>
  );
}
