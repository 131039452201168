import React from "react";
import CustomLoader from "@components/Custom/CustomLoader";
import CustomPillButton from "@components/Custom/CustomPillButton";
import CustomSection from "@components/Custom/CustomSection";
import CustomSyncButton from "@components/Custom/CustomSyncButton";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {
  CLAIM_DEMO_ASSESSMENT_TITLE,
  selectorClaimDemoHasSymptoms,
  selectorClaimDemoHasOrganizationPatient,
  selectorClaimDemoIsLoadingAveyDiagnoses,
  selectorClaimDemoIsSessionDone,
  useClaimDemoContext,
} from "../context";
import ClaimDemoDiagnosisAvey from "./ClaimDemoDiagnosisAvey";
import ClaimDemoDiagnosisClinician from "./ClaimDemoDiagnosisClinician";

export default function ClaimDemoDiagnosis() {
  const { state, handleSyncClaimDiagnoses, handleUpdateClaimAveyDiagnoses } =
    useClaimDemoContext();
  const isDownMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const hasClaimSymptoms = selectorClaimDemoHasSymptoms(state);
  const hasOrganizationPatient = selectorClaimDemoHasOrganizationPatient(state);
  const isSessionDone = selectorClaimDemoIsSessionDone(state);
  const isLoadingAveyDiagnoses = selectorClaimDemoIsLoadingAveyDiagnoses(state);

  return (
    <CustomSection
      title={CLAIM_DEMO_ASSESSMENT_TITLE}
      isOpenDefault={hasOrganizationPatient && isSessionDone}
      isCollapsable={false}
      action={
        isSessionDone &&
        (isLoadingAveyDiagnoses ? (
          <CustomLoader label="Analyzing..." />
        ) : (
          hasClaimSymptoms && (
            <CustomPillButton
              label="Assess with Avey"
              onClick={handleUpdateClaimAveyDiagnoses}
            />
          )
        ))
      }
    >
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} md={5.5}>
          <ClaimDemoDiagnosisClinician />
        </Grid>
        <Grid item xs={12} md={1}>
          {isDownMd ? (
            <Divider>
              <CustomSyncButton
                style={{ transform: "rotate(90deg)" }}
                onClick={handleSyncClaimDiagnoses}
              />
            </Divider>
          ) : (
            <div
              style={{
                height: "100%",
                display: "grid",
                justifyContent: "center",
              }}
            >
              <Divider orientation="vertical">
                <CustomSyncButton onClick={handleSyncClaimDiagnoses} />
              </Divider>
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={5.5}>
          <ClaimDemoDiagnosisAvey />
        </Grid>
      </Grid>
    </CustomSection>
  );
}
