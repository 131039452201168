export const emptyOrganizationAgreementFormData = {
  second_procedure_discount_rate: "",
  followup_consultation_num_days: "",
};

export const formatOrganizationAgreementFormData = ({
  isCheck,
  processor_organization__slug,
  formData,
}) => {
  const {
    id = undefined,
    second_procedure_discount_rate,
    followup_consultation_num_days,
  } = formData;

  return {
    id,
    is_check: isCheck,
    processor_organization__slug,
    second_procedure_discount_rate,
    followup_consultation_num_days,
  };
};

export const ORGANIZATION_AGREEMENT_ACTION_TOGGLE_ACTIVATE_MAINTENANCE =
  "ORGANIZATION_AGREEMENT_ACTION_TOGGLE_ACTIVATE_MAINTENANCE";
export const ORGANIZATION_AGREEMENT_ACTION_TOGGLE_ACTIVATE_AFFILIATION =
  "ORGANIZATION_AGREEMENT_ACTION_TOGGLE_ACTIVATE_AFFILIATION";
