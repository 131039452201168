import React from "react";
import UserManagement from "@components/Authenticated/UserManagement/UserManagement";
import { Navigate, Route, Routes } from "react-router-dom";

export default function UserManagementRoutes() {
  return (
    <Routes>
      <Route path="" Component={UserManagement} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
