import React from "react";
import CustomSkeleton from "@components/Custom/CustomSkeleton";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AVEY_BLUE, AVEY_WHITE } from "@styles/theme";
import { TypeAnimation } from "react-type-animation";

export default function ClaimDemoQuestion({
  index,
  question,
  numQuestions,
  isSessionDone,
}) {
  const isLastQuestion = !isSessionDone && index === numQuestions - 1;

  if (isLastQuestion)
    return (
      <Stack spacing={1} direction="row" alignItems="center" minHeight={20}>
        <CustomSkeleton variant="circular" backgroundColor={AVEY_BLUE} />
        <Typography variant="body2" color="white">
          <TypeAnimation
            key={question}
            sequence={[question]}
            wrapper="span"
            speed={50}
            repeat={false}
            cursor={false}
          />
        </Typography>
      </Stack>
    );

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <Stack spacing={1} direction="row" alignItems="center" minHeight={20}>
        <CustomSkeleton
          variant="circular"
          animation={false}
          backgroundColor={AVEY_WHITE}
        />
        <Typography variant="body2" color="white">
          {question}
        </Typography>
      </Stack>
    </Slide>
  );
}
