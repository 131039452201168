import React from "react";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import { useQueryFetchOrganizationPaymentCycle } from "@redux/actions/payments/organization-payment-cycles";
import { useParams } from "react-router-dom";
import OrganizationPaymentCycleBody from "./OrganizationPaymentCycleBody";
import OrganizationPaymentCycleHeader from "./OrganizationPaymentCycleHeader";

export default function OrganizationPaymentCycle() {
  const { organization_payment_cycle__id: id } = useParams();
  const { isLoading, data } = useQueryFetchOrganizationPaymentCycle({ id });
  const hasData = Boolean(data);

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  return (
    <>
      <OrganizationPaymentCycleHeader object={data} />
      <CustomPage>
        <OrganizationPaymentCycleBody object={data} />
      </CustomPage>
    </>
  );
}
