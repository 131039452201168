import React from "react";
import { CustomTab, CustomTabs } from "@components/Custom/CustomTabs";
import Stack from "@mui/material/Stack";
import OrganizationConsumedTokens from "../../OrganizationConsumedTokens";
import OrganizationPayments from "../../OrganizationPayments";
import OrganizationPaymentCycleSummaries from "./OrganizationPaymentCycleSummaries";

export default function OrganizationPaymentCycleBody({ object }) {
  const { id } = object;
  return (
    <Stack spacing={3}>
      <OrganizationPaymentCycleSummaries object={object} />
      <OrganizationPaymentCycleBodyContent id={id} />
    </Stack>
  );
}

const tabs = [
  { value: "Payments", Component: OrganizationPayments },
  { value: "Consumption", Component: OrganizationConsumedTokens },
];

function OrganizationPaymentCycleBodyContent({ id }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (_, newValue) => setValue(newValue);
  const { Component } = tabs[value];

  return (
    <Stack spacing={2}>
      <CustomTabs value={value} onChange={handleChange}>
        {tabs.map(({ value }, index) => (
          <CustomTab key={index} value={index} label={value} />
        ))}
      </CustomTabs>
      <Component organization_payment_cycle__id={id} />
    </Stack>
  );
}
