import React from "react";
import CustomInfiniteAutocomplete from "@components/Custom/CustomAutocomplete/CustomInfiniteAutocomplete";
import CustomChip from "@components/Custom/CustomChip";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useQueryFetchSpecialities } from "@redux/actions/avey/specialities";
import {
  getSpecialityOptionLabel,
  isSpecialityOptionEqualToValue,
  specialityRenderOption,
} from "./SpecialityAutocompleteComponents";

const useQueryFnProps = {
  filters: {
    time_deleted__isnull: true,
    newer_version__isnull: true,
  },
};

export default function SpecialitiesAutocomplete({
  label = "Specialities",
  error = false,
  value = [],
  handleChange = () => {},
  textFieldProps = {},
  autocompleteProps = {},
}) {
  const handleRemoveOption = (option) =>
    handleChange(value.filter(({ code }) => code !== option?.code));

  return (
    <Stack spacing={2}>
      <CustomInfiniteAutocomplete
        label={label}
        error={error}
        textFieldProps={{ ...textFieldProps }}
        autocompleteProps={{
          multiple: true,
          filterSelectedOptions: true,
          renderOption: specialityRenderOption,
          ...autocompleteProps,
        }}
        useQueryFn={useQueryFetchSpecialities}
        useQueryFnProps={useQueryFnProps}
        getOptionLabel={getSpecialityOptionLabel}
        isOptionEqualToValue={isSpecialityOptionEqualToValue}
        value={value}
        handleChange={handleChange}
      />
      {value.length > 0 && (
        <div>
          <Grid container spacing={1}>
            {value.map((option, index) => (
              <Grid item key={index}>
                <CustomChip
                  name={getSpecialityOptionLabel(option)}
                  title={option.description}
                  handleClick={() => handleRemoveOption(option)}
                  color="grey"
                  hasCloseIcon={true}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </Stack>
  );
}
