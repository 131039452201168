import React from "react";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import CustomNotProvidedText from "@components/Custom/CustomNotProvidedText";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import {
  CLAIM_SECTION_ICD_TEXT_FIELDNAME_LABEL_MAPPER,
  CLAIM_SECTION_SYMPTOMS,
} from "@constants/claims/claims";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

export default function ClaimIcdsTextFormData({
  type = CLAIM_SECTION_SYMPTOMS,
  claim_icds_text = "",
  handleBlur = () => {},
}) {
  const uppercased = CLAIM_SECTION_ICD_TEXT_FIELDNAME_LABEL_MAPPER[type];
  const lowercased = uppercased?.toLowerCase();

  return (
    <CustomListItemCollapse primary={uppercased}>
      <ClaimIcdsTextFormDataContent
        claim_icds_text={claim_icds_text}
        handleBlur={handleBlur}
        label={lowercased}
      />
    </CustomListItemCollapse>
  );
}

const ClaimIcdsTextFormDataContent = ({
  label,
  claim_icds_text = "",
  handleBlur = () => {},
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleIsOpen = () => setIsOpen((prev) => !prev);
  const onBlur = (e) => {
    handleBlur(e);
    setIsOpen(false);
  };

  const hasClaimIcdsText = Boolean(claim_icds_text);

  if (!isOpen) {
    if (hasClaimIcdsText)
      return (
        <Stack
          spacing={3}
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <CustomTypographyButton
            variant="body2"
            value={claim_icds_text}
            fontWeight="normal"
            onClick={handleIsOpen}
            isUnderlined={false}
          />
          <CustomMoreActions useIconButton={true}>
            <MenuItem onClick={handleIsOpen}>{`Update ${label}`}</MenuItem>
          </CustomMoreActions>
        </Stack>
      );

    return (
      <Stack spacing={3}>
        <CustomNotProvidedText title="Text" />
        <CustomTypographyButton
          value={`+ Add ${label}`}
          onClick={handleIsOpen}
        />
      </Stack>
    );
  }

  return (
    <CustomOutlinedTextField
      autoFocus
      defaultValue={claim_icds_text || ""}
      onBlur={onBlur}
      multiline
      minRows={2}
    />
  );
};
