import {
  searchQueryDescribeFilter,
  TIME_DELETED_ISNULL_FILTER,
  TIME_UPDATED_FILTER,
  timeDeletedIsNullApplyFilter,
  timeDeletedIsNullDescribeFilter,
  timeUpdatedApplyFilter,
  timeUpdatedDescribeFilter,
} from "@constants/static/export";
import { parseDate, parseIsoDatetime } from "@helpers/parse/time";

export const initialFilterState = {
  expiry_date: [null, null],
  time_updated: [null, null],
  time_deleted__isnull: [],
};

export const filterOptions = {
  expiry_date: { title: "Expiry date", type: "date-range" },
  time_updated: TIME_UPDATED_FILTER,
  time_deleted__isnull: TIME_DELETED_ISNULL_FILTER,
};

export const formatFilters = (filters = {}) => {
  let formattedFilters = {};
  const {
    expiry_date = [null, null],
    time_updated = [null, null],
    time_deleted__isnull = [],
  } = filters;

  if (expiry_date[0])
    formattedFilters["expiry_date__gte"] = parseIsoDatetime(expiry_date[0]);

  if (expiry_date[1])
    formattedFilters["expiry_date__lt"] = parseIsoDatetime(expiry_date[1]);

  formattedFilters = timeUpdatedApplyFilter({
    formattedFilters,
    time_updated,
  });

  formattedFilters = timeDeletedIsNullApplyFilter({
    formattedFilters,
    time_deleted__isnull,
  });

  return formattedFilters;
};

export const describeFilters = ({ searchQuery = "", filters = {} }) => {
  let formattedOutput = [];
  const {
    expiry_date__gte = "",
    expiry_date__lt = "",
    time_updated__gte = "",
    time_updated__lt = "",
    time_deleted__isnull = "",
  } = filters;

  formattedOutput = searchQueryDescribeFilter({ formattedOutput, searchQuery });

  if (expiry_date__gte || expiry_date__lt) {
    const label = "Expiry date";
    const from = parseDate(expiry_date__gte);
    const to = parseDate(expiry_date__lt);

    if (from && to) formattedOutput.push({ label, from, to });
    else if (from) formattedOutput.push({ label, from });
    else if (to) formattedOutput.push({ label, to });
  }

  formattedOutput = timeUpdatedDescribeFilter({
    formattedOutput,
    time_updated__gte,
    time_updated__lt,
  });
  formattedOutput = timeDeletedIsNullDescribeFilter({
    formattedOutput,
    time_deleted__isnull,
  });

  return formattedOutput;
};
