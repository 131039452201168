import React from "react";
import AccountActionDialogTitle from "@components/Authenticated/Account/Account/AccountActionDialogTitle";
import AccountInformationFormData from "@components/Authenticated/Account/AccountInformation/AccountInformationFormData";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import { updateUser } from "@redux/actions/users/auth";
import { invalidateUsersTSQ } from "@redux/actions/users/users";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";

const initialErrors = {};

export default function UserManagementEditProfile({
  isOpen,
  object,
  handleClose = () => {},
}) {
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [errors, setErrors] = React.useState(initialErrors);

  React.useEffect(() => {
    isOpen && object && setFormData(object);
  }, [isOpen, object]);

  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    setErrors(initialErrors);
    const { id, first_name, last_name, role } = formData;
    const formattedData = {
      id,
      role,
      first_name: first_name?.trim(),
      last_name: last_name?.trim(),
    };
    dispatch(updateUser(formattedData, false))
      .then(({ payload }) => {
        setIsProcessing(false);
        handleClose();
        enqueueSnackbar({
          message: "User profile successfully updated",
          variant: "success",
        });
        invalidateUsersTSQ();
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        setErrors(payload);
      });
  };

  return (
    <CustomActionDialog
      isOpen={isOpen}
      isProcessing={isProcessing}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      TitleComponent={AccountActionDialogTitle}
      titleComponentProps={{ title: "Edit profile", user: object }}
    >
      <AccountInformationFormData
        errors={errors}
        formData={formData}
        setFormData={setFormData}
        withRole={true}
      />
    </CustomActionDialog>
  );
}
