import React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { NavLink } from "react-router-dom";
import { ArrowCenterRightIcon } from "@rimads/assets";
import { AVEY_GREY_DARK } from "@styles/theme";

const separator = (
  <ArrowCenterRightIcon dimension={8} primary_fill={AVEY_GREY_DARK} />
);

export default function NavigationBreadcrumbs({ hrefs = [] }) {
  const hrefsLength = hrefs.length;

  if (hrefsLength === 0) return null;

  return (
    <Breadcrumbs separator={separator}>
      {hrefs.map(({ href, label }, index) => (
        <NavLink
          to={href}
          key={href}
          className="opacityOnHover-50"
          end={index + 1 < hrefsLength}
        >
          {({ isActive }) => (
            <Typography
              variant="body2"
              color={isActive ? "textPrimary" : "textSecondary"}
              fontWeight={isActive ? "normal" : "light"}
            >
              {label}
            </Typography>
          )}
        </NavLink>
      ))}
    </Breadcrumbs>
  );
}
