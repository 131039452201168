import React from "react";
import Typography from "@mui/material/Typography";

export default function CustomBulletPoints({ bulletpoints = [] }) {
  const count = bulletpoints.length;

  if (count === 0) return null;

  return (
    <div>
      <ul style={{ paddingLeft: 17, margin: 0 }}>
        {bulletpoints?.map((bulletpoint, index) => (
          <Typography key={index} variant="body2" component="li" py="4px">
            {bulletpoint}
          </Typography>
        ))}
      </ul>
    </div>
  );
}
