import React from "react";
import { useRotateClassName } from "@helpers/hooks/useRotateClassName";
import IconButton from "@mui/material/IconButton";
import CustomButton from "./CustomButton";
import CustomIconComponent from "./CustomIconComponent";
import CustomPopper from "./CustomPopper";

export default function CustomMoreActions({
  children,
  useIconButton = false,
  buttonProps = {},
  popperProps = {},
  useRotateProps = {},
  icon = "MoreHorizIcon",
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const rotateClassName = useRotateClassName({
    isOpen: Boolean(anchorEl),
    useRotate90: true,
    ...useRotateProps,
  });
  const handleMouseEnter = (e) => setAnchorEl(e.currentTarget);

  const isOpen = Boolean(anchorEl);
  const handleIsOpen = (e) => setAnchorEl(isOpen ? null : e?.currentTarget);

  return (
    <div onClick={handleMouseEnter}>
      {useIconButton ? (
        <IconButton onClick={handleIsOpen} size="small" {...buttonProps}>
          <CustomIconComponent
            icon={icon}
            iconWrapperProps={{ className: rotateClassName }}
          />
        </IconButton>
      ) : (
        <CustomButton
          variant="outlined"
          onClick={handleIsOpen}
          isSquare={true}
          icon={icon}
          useIconAsLabel={true}
          iconWrapperProps={{ className: rotateClassName }}
          {...buttonProps}
        />
      )}
      <CustomPopper
        anchorEl={anchorEl}
        handleAnchorEl={setAnchorEl}
        {...popperProps}
      >
        {children}
      </CustomPopper>
    </div>
  );
}
