import {
  PAYMENT_PURPOSE_MAPPER,
  PAYMENT_STATUS_MAPPER,
} from "@constants/payments/payments";
import { parseTimestamp } from "@helpers/parse/time";
import { moneyWithCurrency } from "@helpers/parse/tokens";

const OrganizationPaymentsGridProps = ({ billing_conversion }) => ({
  columns: [
    {
      flex: 1,
      field: "payment_purpose",
      headerName: "Payment purpose",
      valueGetter: ({ value }) => PAYMENT_PURPOSE_MAPPER[value],
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      valueGetter: ({ value }) => PAYMENT_STATUS_MAPPER[value],
    },
    {
      flex: 1,
      field: "amount",
      headerName: "Billed amount",
      valueGetter: ({ value }) =>
        moneyWithCurrency({ amount: value, billing_conversion }),
    },
    {
      flex: 1,
      field: "time_updated",
      headerName: "Time updated",
      valueGetter: ({ value }) => parseTimestamp({ value }),
    },
  ],
  disableRowSelectionOnClick: true,
});

export default OrganizationPaymentsGridProps;
