import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import {
  getClaimHref,
  getClaimSubmissionHref,
  HOME_HREF,
  HOME_TITLE,
} from "@constants/static/routing";
import { useURLSearch } from "@helpers/api/getQueryParams";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import ClaimStatus from "../ClaimStatus";
import { useClaimContext } from "../context";
import ClaimHeaderActions from "./ClaimHeaderActions";

export function ClaimHeaderTitle({ variant = "h3", object }) {
  const {
    claim_form_number,
    status,
    time_deleted,
    time_locked,
    version,
    num_success_evaluation_results,
    num_failure_evaluation_results,
    num_alert_evaluation_results,
  } = object;
  const hasClaimFormNumber = Boolean(claim_form_number);

  return (
    <CustomHeaderTitle
      title={claim_form_number}
      variant={variant}
      version={version}
    >
      {!hasClaimFormNumber && (
        <Typography
          variant={variant}
          className="ellipsis-1"
          color="textSecondary"
          fontStyle="italic"
          fontWeight="light"
        >
          {"Untitled Claim"}
        </Typography>
      )}
      <ClaimStatus
        withTooltip={true}
        status={status}
        time_deleted={time_deleted}
        time_locked={time_locked}
        num_success_evaluation_results={num_success_evaluation_results}
        num_failure_evaluation_results={num_failure_evaluation_results}
        num_alert_evaluation_results={num_alert_evaluation_results}
      />
    </CustomHeaderTitle>
  );
}

export default function ClaimHeader() {
  const { organization__slug } = useParams();
  const urlSearch = useURLSearch();
  const { claim: object } = useClaimContext();
  const {
    id: claim__id,
    claim_submission,
    claim_form_number,
    tpa_organization,
    submitter_organization,
    processor_organization,
  } = object || {};

  const organization = [
    tpa_organization,
    submitter_organization,
    processor_organization,
  ]
    .filter(Boolean)
    .find(({ slug }) => slug === organization__slug);
  const { id: claim_submission__id, name } = claim_submission || {};
  const hrefs = [
    {
      label: HOME_TITLE,
      href: HOME_HREF,
    },
    {
      label: organization?.name || organization__slug,
      href: getClaimSubmissionHref({ organization__slug }),
    },
    {
      label: name,
      href: getClaimSubmissionHref({
        organization__slug,
        claim_submission__id,
      }),
    },
    {
      label: claim_form_number || "-",
      href: getClaimHref({
        organization__slug,
        claim_submission__id,
        claim__id,
        urlSearch,
      }),
    },
  ];

  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={ClaimHeaderTitle}
      titleComponentProps={{ object }}
    >
      <ClaimHeaderActions object={object} />
    </CustomHeader>
  );
}
