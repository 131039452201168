import React from "react";
import CustomBox from "@components/Custom/CustomBox";
import { styled } from "@mui/material";
import Stack from "@mui/material/Stack";
import { IncorrectIcon, VerifiedIcon } from "@rimads/assets";
import { AVEY_BLACK, AVEY_BLUE, AVEY_RED, AVEY_WHITE } from "@styles/theme";
import { MaterialDesignContent } from "notistack";

export const SuccessIconVariant = (props = {}) => (
  <VerifiedIcon dimension={18} primary_fill={AVEY_BLUE} {...props} />
);

export const ErrorIconVariant = (props = {}) => (
  <IncorrectIcon dimension={18} primary_fill={AVEY_RED} {...props} />
);

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    "&.notistack-MuiContent": {
      boxShadow: `${theme.shadows[12]} !important`,
      borderRadius: "8px",
      backgroundColor: AVEY_WHITE,
      color: AVEY_BLACK,
      padding: "6px 16px",
    },
  })
);

export const SnackbarWrapper = ({ children, ...props }) => (
  <CustomBox
    className="elevated-12 borderRadius-8 whiteBg noMinWidth"
    p="16px"
    color={AVEY_BLACK}
    minWidth={{ sm: 350 }}
    maxWidth={{ sm: 350 }}
    width="100%"
    {...props}
  >
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {children}
    </Stack>
  </CustomBox>
);
