import React from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { useQueryFetchRechargeTokenPacks } from "@redux/actions/payments/payments";
import RechargeTokenPackOption from "./RechargeTokenPackOption";

export default function OrganizationPaymentCycleBalanceFormData({
  value,
  billing_conversion,
  handleChange = () => {},
}) {
  const { isLoading, data } = useQueryFetchRechargeTokenPacks({});
  const isChecked = (rechargeTokenPackId) => rechargeTokenPackId === value?.id;

  if (isLoading) return <Skeleton variant="rectangular" height={114} />;

  if (!Boolean(data) || data?.length === 0) return null;

  return (
    <div>
      <Grid container spacing={1}>
        {data?.map((recharge_token_pack, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <RechargeTokenPackOption
              recharge_token_pack={recharge_token_pack}
              isChecked={isChecked}
              handleChange={handleChange}
              billing_conversion={billing_conversion}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
