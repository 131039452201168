import React from "react";
import CustomTooltip from "@components/Custom/CustomTooltip";
import Typography from "@mui/material/Typography";

const TagContent = (
  <Typography
    variant="body2"
    fontWeight="bold"
    color="white"
    className="blackBg borderRadius-8"
    py={0.5}
    px={1}
  >
    {"S"}
  </Typography>
);

export const OrganizationBundleSecondProcedureDiscountTag = ({
  is_second_procedure_discount,
  withTooltip = true,
}) => {
  if (!is_second_procedure_discount) return null;

  if (withTooltip)
    return (
      <CustomTooltip title="Second procedure discount">
        {TagContent}
      </CustomTooltip>
    );

  return TagContent;
};
