import React from "react";
import Typography from "@mui/material/Typography";

export const isOrganizationBundleOptionEqualToValue = (option, value) =>
  option?.id === value?.id;

export const getOrganizationBundleOptionLabel = (option) => {
  if (!Boolean(option)) return "";
  const { internal_code = "", description = "" } = option;
  return `${internal_code} ${description}`.trim();
};

export const renderOrganizationBundleOption = (props, option) => {
  const { internal_code, description } = option;
  return (
    <div {...props}>
      <div>
        <Typography variant="body2" fontWeight="bold">
          {internal_code}
        </Typography>
        <Typography variant="body2" fontWeight="light">
          {description}
        </Typography>
      </div>
    </div>
  );
};
