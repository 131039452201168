import React from "react";
import { CLAIM_SECTION_PROCEDURES_TITLE } from "@constants/claims/claims";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { selectorClaimDemoProcedures, useClaimDemoContext } from "../context";
import ClaimDemoProcedure from "./ClaimDemoProcedure";
import ClaimDemoProceduresAddForm from "./ClaimDemoProceduresAddForm";

export default function ClaimDemoClinicianProcedures() {
  const { state, handleRemoveClaimProcedure } = useClaimDemoContext();
  const claimProcedures = selectorClaimDemoProcedures(state);
  const hasClaimProcedures = claimProcedures.length > 0;

  return (
    <Stack spacing={2}>
      <Typography fontWeight="bold">
        {CLAIM_SECTION_PROCEDURES_TITLE}
      </Typography>
      {hasClaimProcedures && (
        <div>
          {claimProcedures.map((claim_procedure, index) => (
            <ClaimDemoProcedure
              key={index}
              claim_procedure={claim_procedure}
              handleRemove={() => handleRemoveClaimProcedure(index)}
            />
          ))}
        </div>
      )}
      <ClaimDemoProceduresAddForm />
    </Stack>
  );
}
