import React from "react";
import {
  CLAIM_SECTION_DIAGNOSES,
  CLAIM_SECTION_SYMPTOMS,
} from "@constants/claims/claims";
import Stack from "@mui/material/Stack";
import ClaimAttachments from "../ClaimAttachments";
import ClaimIcds from "../ClaimIcds";
import ClaimMedications from "../ClaimMedications";
import ClaimNotes from "../ClaimNotes";
import ClaimOrganizationClinician from "../ClaimOrganizationClinician";
import ClaimOrganizationPatient from "../ClaimOrganizationPatient";
import ClaimProcedures from "../ClaimProcedures";
import ClaimVisit from "../ClaimVisit";
import ClaimGeneralEvaluationResults from "./ClaimGeneralEvaluationResults";

export default function ClaimBody() {
  return (
    <Stack spacing={3}>
      <ClaimNotes />
      <ClaimGeneralEvaluationResults />
      <ClaimVisit />
      <ClaimOrganizationPatient />
      <ClaimOrganizationClinician />
      <ClaimIcds type={CLAIM_SECTION_SYMPTOMS} />
      <ClaimIcds type={CLAIM_SECTION_DIAGNOSES} />
      <ClaimProcedures />
      <ClaimMedications />
      <ClaimAttachments />
    </Stack>
  );
}
