import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import { emptyClaimAttachmentFormData } from "@constants/claims/claim-attachments";
import ClaimAttachmentFormData from "./ClaimAttachmentFormData";

export default function ClaimAttachmentCreateForm({
  handleAddClaimAttachment = () => {},
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [formData, setFormData] = React.useState(emptyClaimAttachmentFormData);

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    handleAddClaimAttachment({ payload: formData });
    setIsOpen(false);
  };

  React.useEffect(() => {
    isOpen && setFormData(emptyClaimAttachmentFormData);
  }, [isOpen]);

  return (
    <>
      <CustomTypographyButton value="+ Add attachment" onClick={handleIsOpen} />
      <CustomActionDialog
        isOpen={isOpen}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        maxWidth="xs"
        titleComponentProps={{ title: "New attachment" }}
        yesText="Add attachment"
      >
        <ClaimAttachmentFormData
          formData={formData}
          setFormData={setFormData}
        />
      </CustomActionDialog>
    </>
  );
}
