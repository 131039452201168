import React from "react";
import CustomFileDropzone from "@components/Custom/CustomFileDropzone";

export default function ClaimAttachmentFormData({
  formData = {},
  setFormData = () => {},
}) {
  const { file } = formData;

  const handleChangeFile = (newValue) =>
    setFormData((prev) => ({ ...prev, file: newValue, id: null }));

  return <CustomFileDropzone value={file} onChange={handleChangeFile} />;
}
