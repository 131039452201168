import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import { USER_ORGANIZATION_ROLE_MAPPER } from "@constants/users/users";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AccountDetailsUpdateForm from "./AccountDetailsUpdateForm";

export default function AccountDetails({ user }) {
  const { role } = user;
  const roleStr = USER_ORGANIZATION_ROLE_MAPPER[role];

  return (
    <Stack spacing={2}>
      <Typography fontWeight="bold">{"Your account details"}</Typography>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CustomLabeledText label="Role" value={roleStr} />
          </Grid>
          <Grid item xs={12} md={6}>
            <AccountDetailsUpdateForm user={user} />
          </Grid>
        </Grid>
      </div>
    </Stack>
  );
}
