import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import {
  INSURANCE_CONFIGURATIONS_BASE_HREFS,
  getOrganizationSetupDefaultHref,
} from "@constants/static/routing";
import useFetchOrganization from "@helpers/hooks/useFetchObjects/useFetchOrganization";

export default function InsuranceOrganizationConfigurationsHeader() {
  const { data, organization__slug } = useFetchOrganization();
  const title = data?.name || organization__slug;

  const hrefs = [
    ...INSURANCE_CONFIGURATIONS_BASE_HREFS,
    {
      label: title,
      href: getOrganizationSetupDefaultHref({ organization__slug }),
    },
  ];

  return <CustomHeader titleComponentProps={{ title }} hrefs={hrefs} />;
}
