import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import CustomInfiniteScroll from "../CustomDataGrid/CustomInfiniteScroll";

const CustomInfiniteAutocompleteScroll = React.forwardRef(
  function CustomInfiniteAutocompleteScroll(props, ref) {
    const {
      children,
      ListboxComponent,
      hasNextPage = false,
      isFetching = false,
      isFetchingNextPage = false,
      fetchNextPage = () => {},
      ...other
    } = props;

    return (
      <div ref={ref}>
        {isFetching && <LinearProgress color="primary" />}
        <div {...other}>
          {children}
          <CustomInfiniteScroll
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            useCircularProgress={false}
          />
        </div>
        {isFetchingNextPage && <LinearProgress color="primary" />}
      </div>
    );
  }
);

export default CustomInfiniteAutocompleteScroll;
