import React from "react";
import { DISABLED_ROW_CLASSNAME } from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import {
  ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT,
  ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT,
} from "@constants/organizations/organization-patients";
import { getOrganizationPoliciesHref } from "@constants/static/routing";
import { parseDate, parseTimeSince } from "@helpers/parse/time";
import OrganizationPolicyCoverageDataSummary from "../OrganizationPolicy/OrganizationPolicyData/OrganizationPolicyCoveragesData/OrganizationPolicyCoverageDataSummary";

export default function OrganizationPoliciesGridProps({
  processor_organization__slug,
  hiddenColumns = [],
  is_processor_tpa,
}) {
  const finalHiddenColumns = is_processor_tpa
    ? hiddenColumns
    : [...hiddenColumns, "insurer_name"];
  return {
    columns: [
      {
        flex: 1,
        field: "number",
        headerName: "Policy number",
      },
      {
        flex: 1,
        field: "name",
        headerName: "Policy name",
      },
      {
        flex: 1,
        field: "expiry_date",
        headerName: "Expiry date",
        valueGetter: ({ value }) => parseDate(value) || "-",
      },
      {
        flex: 1,
        field: "insurer_name",
        headerName: "Insurer",
        valueGetter: ({ value }) => value || "-",
      },
      {
        flex: 1,
        sortable: false,
        field: "inpatient_organization_policy_coverage",
        headerName: "IP benefits",
        valueGetter: (params) =>
          params.row.organization_policy_coverages.find(
            ({ visit_type }) =>
              visit_type === ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT
          ),
        renderCell: ({ value }) => (
          <OrganizationPolicyCoverageDataSummary object={value} />
        ),
      },
      {
        flex: 1,
        sortable: false,
        field: "outpatient_organization_policy_coverage",
        headerName: "OP benefits",
        valueGetter: (params) =>
          params.row.organization_policy_coverages.find(
            ({ visit_type }) =>
              visit_type === ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT
          ),
        renderCell: ({ value }) => (
          <OrganizationPolicyCoverageDataSummary object={value} />
        ),
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ].filter(({ field }) => !finalHiddenColumns.includes(field)),
    getRowClassName: ({ row }) => row?.time_deleted && DISABLED_ROW_CLASSNAME,
    disableRowSelectionOnClick: true,
    navigateTo: ({ id }) =>
      processor_organization__slug &&
      getOrganizationPoliciesHref({
        organization__slug: processor_organization__slug,
        organization_policy__id: id,
      }),
  };
}
