import { useQueryFetchOrganizationAgreement } from "@redux/actions/organizations/organization-agreements";
import { selectorAuthIsSubmitterOrganization } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function useFetchOrganizationAgreement() {
  const { organization__slug } = useParams();
  const isSubmitterOrganization = useSelector(
    selectorAuthIsSubmitterOrganization
  );
  const { isLoading, data } = useQueryFetchOrganizationAgreement({
    processor_organization__slug: organization__slug,
    isSubmitterOrganization,
  });
  const hasData = Boolean(data);

  return { isLoading, data, hasData, organization__slug };
}

export const useOrganizationAgreementIsUnderMaintenance = () => {
  const { data, isLoading } = useFetchOrganizationAgreement();
  const { time_maintenance } = data || { time_deleted: true };
  return { isAgreementDisabled: Boolean(time_maintenance), isLoading };
};
