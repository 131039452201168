import React from "react";
import {
  convertClaimMetaDict,
  getMetaDataFilledKeys,
  getMetaDataAllKeys,
} from "@constants/claims/claim-meta-data";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { AVEY_GREY_F9 } from "@styles/theme";
import pluralize from "pluralize";
import CustomLabeledText from "../CustomLabeledText";
import CustomValueWithUnit from "../CustomValueWithUnit";

export default function CustomMetaData({ meta_data = {} }) {
  const metaDataDict = convertClaimMetaDict(meta_data);
  const allKeys = getMetaDataAllKeys(metaDataDict);
  const filledKeys = getMetaDataFilledKeys(metaDataDict);
  const value = filledKeys.length;
  const unit = `/ ${pluralize("column", allKeys.length, true)}`;
  const hasObjects = value > 0;

  if (!hasObjects) return null;

  return (
    <CustomLabeledText
      label="Additional information"
      useChildren={true}
      tooltipProps={{ bgColor: AVEY_GREY_F9, padding: 0, borderRadius: 12 }}
      title={
        <div style={{ overflowY: "scroll", maxHeight: 300 }}>
          <Stack spacing={1} p={2}>
            {filledKeys.map(({ key, value }, index) => (
              <Box
                p={1}
                className="elevated-8 borderRadius-8 whiteBg"
                key={index}
              >
                <CustomLabeledText
                  label={key}
                  value={value || "-"}
                  labelTypographyProps={{ fontWeight: "bold" }}
                />
              </Box>
            ))}
          </Stack>
        </div>
      }
      useCustomTooltip={true}
    >
      <CustomValueWithUnit
        wrapperProps={{ className: "underlined" }}
        value={value}
        unit={unit}
      />
    </CustomLabeledText>
  );
}
