import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CustomBox from "./CustomBox";
import pluralize from "pluralize";

export default function CustomNoResultsFound({
  type = "result",
  subtitle = null,
  src = "",
  wrapperProps = {},
  children,
  disableSubtitleTypography = false,
}) {
  return (
    <Stack spacing={2} {...wrapperProps}>
      {Boolean(src) && (
        <div>
          <CustomBox component="img" src={src} height={160} />
        </div>
      )}
      {disableSubtitleTypography ? (
        subtitle
      ) : (
        <Typography variant="body2">
          {subtitle || `No ${pluralize(type)} found.`}
        </Typography>
      )}
      {Boolean(children) && (
        <div>
          <CustomBox maxWidth={240} m="auto">
            {children}
          </CustomBox>
        </div>
      )}
    </Stack>
  );
}
