import { getOptions } from "@helpers/hooks/objects";
import { mdsMetaColsToMetaData } from "./claims";

export const CLAIM_PROCEDURE_TYPE_BASIC = "B";
export const CLAIM_PROCEDURE_TYPE_OPTICAL = "O";
export const CLAIM_PROCEDURE_TYPE_DENTAL = "D";
export const CLAIM_PROCEDURE_TYPE_MATERNAL = "M";

export const CLAIM_PROCEDURE_TYPE_MAPPER = {
  [CLAIM_PROCEDURE_TYPE_BASIC]: "Basic",
  [CLAIM_PROCEDURE_TYPE_OPTICAL]: "Optical",
  [CLAIM_PROCEDURE_TYPE_DENTAL]: "Dental",
  [CLAIM_PROCEDURE_TYPE_MATERNAL]: "Maternal",
};

export const CLAIM_PROCEDURE_TYPE_OPTIONS = getOptions(
  CLAIM_PROCEDURE_TYPE_MAPPER
);

export const isProcedureTypeMaternal = (procedure_type) =>
  procedure_type === CLAIM_PROCEDURE_TYPE_MATERNAL;

export const isProcedureTypeDental = (procedure_type) =>
  procedure_type === CLAIM_PROCEDURE_TYPE_DENTAL;

export const getEmptyClaimProcedureFormData = ({
  currency = "",
  meta_mds_cols = [],
}) => ({
  currency,
  organization_bundle: null,
  amount_gross: 0,
  amount_discount: 0,
  amount_copayment: 0,
  amount_requested: 0,
  quantity: 1,
  related_date: new Date(),
  procedure_type: CLAIM_PROCEDURE_TYPE_BASIC,
  tooth_number: "",
  nature_of_conception: "",
  last_menstrual_period: null,
  preauthorization_number: "",
  invoice_number: "",
  meta_data: mdsMetaColsToMetaData(meta_mds_cols),
});

export const claimMedicationToClaimProcedure = ({
  claim_medication,
  currency = "",
  meta_mds_cols = [],
}) => ({
  ...getEmptyClaimProcedureFormData({ currency, meta_mds_cols }),
  ...claim_medication,
});
