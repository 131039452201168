import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import CustomButton from "@components/Custom/CustomButton";
import { TOKEN_SHORT_NAME_SINGULAR } from "@constants/static/payment";
import { tokensToMoney } from "@helpers/parse/tokens";
import Typography from "@mui/material/Typography";
import { invalidateOrganizationCardsTSQ } from "@redux/actions/organizations/organization-cards";
import {
  invalidateOrganizationPaymentCyclesTSQ,
  topUpOrganizationPaymentCycle,
  updateOrganizationCurrentPaymentCycleObjectTSQ,
} from "@redux/actions/payments/organization-payment-cycles";
import { selectorAuthBillingConversion } from "@redux/selectors/auth";
import { enqueueSnackbar } from "notistack";
import pluralize from "pluralize";
import { useSelector } from "react-redux";
import OrganizationPaymentCycleBalanceFormData from "./OrganizationPaymentCycleBalanceFormData";

export default function OrganizationPaymentCycleBalanceFormActionSubscription({
  object = {},
}) {
  const { id } = object;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [rechargeTokenPack, setRechargeTokenPack] = React.useState(null);
  const [isConfirm, setIsConfirm] = React.useState(false);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const { isOpen: isOpenAlert } = alertState;
  const isDialogOpen = isOpen && !isOpenAlert;
  const billing_conversion = useSelector(selectorAuthBillingConversion);

  const handleOpen = (e) => {
    e?.preventDefault();
    setIsOpen(true);
  };

  const handleClose = (e) => {
    e?.preventDefault();
    isConfirm ? setIsConfirm(false) : setIsOpen(!isOpen);
  };

  const handleChangeRechargeTokenPack = (newValue) =>
    setRechargeTokenPack(newValue);

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (!Boolean(rechargeTokenPack))
      return setAlertState({
        isOpen: true,
        errorStr: "Please select a top up pack to proceed.",
      });

    if (!isConfirm) {
      setIsConfirm(true);
    } else {
      setIsProcessing(true);
      topUpOrganizationPaymentCycle({
        id,
        recharge_token_pack: rechargeTokenPack?.id,
      })
        .then(({ payload }) => {
          enqueueSnackbar({
            variant: "success",
            message: "Plan successfully updated",
          });
          updateOrganizationCurrentPaymentCycleObjectTSQ({
            object: payload,
          });
          setIsOpen(false);
        })
        .catch(({ payload }) => {
          const { reason, latest_version } = payload;
          if (Boolean(latest_version)) {
            updateOrganizationCurrentPaymentCycleObjectTSQ({
              object: latest_version,
            });
            setAlertState({
              isOpen: true,
              errorStr:
                "A new payment cycle is in place, please verify before proceeding with this purchase.",
            });
          } else {
            setAlertState({ isOpen: true, errorStr: reason });
          }
        })
        .finally(() => {
          setIsProcessing(false);
          invalidateOrganizationPaymentCyclesTSQ();
          invalidateOrganizationCardsTSQ();
        });
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      setRechargeTokenPack(null);
      setIsConfirm(false);
      setAlertState(initialAlertState);
    }
  }, [isOpen]);

  const dialogProps = isConfirm
    ? {
        maxWidth: "xs",
        titleComponentProps: { title: "Review & confirm" },
        noText: "Change pack",
        yesText: "Confirm",
      }
    : {
        maxWidth: "sm",
        titleComponentProps: { title: "Top up wallet" },
        isOk: true,
        okText: "Proceed to confirmation",
      };

  return (
    <>
      <CustomButton label="Top up" onClick={handleOpen} />
      <CustomActionDialog
        isOpen={isDialogOpen}
        isProcessing={isProcessing}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        {...dialogProps}
      >
        {isConfirm ? (
          <RechargeTokenPackOptionConfirmData
            recharge_token_pack={rechargeTokenPack}
            billing_conversion={billing_conversion}
          />
        ) : (
          <OrganizationPaymentCycleBalanceFormData
            value={rechargeTokenPack}
            billing_conversion={billing_conversion}
            handleChange={handleChangeRechargeTokenPack}
          />
        )}
      </CustomActionDialog>
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
    </>
  );
}

const RechargeTokenPackOptionConfirmData = ({
  recharge_token_pack,
  billing_conversion,
}) => {
  const { name, num_tokens, rate_per_token } = recharge_token_pack;
  const numTokens = pluralize(TOKEN_SHORT_NAME_SINGULAR, num_tokens, true);
  const amount = tokensToMoney({
    num_tokens,
    rate_per_token,
    billing_conversion,
  });

  return (
    <Typography variant="body2">
      {`Recharge pack “${name}“ will top up your balance by `}
      <b>{numTokens}</b>
      {", equivalent to "}
      <b>{amount}</b>
      {"."}
    </Typography>
  );
};
