import React from "react";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import Grid from "@mui/material/Grid";
import { useQueryFetchOrganizationCards } from "@redux/actions/organizations/organization-cards";
import OrganizationCardAction from "./OrganizationCardAction";
import OrganizationCardCreateOrReplaceForm from "./OrganizationCardCreateOrReplaceForm";
import OrganizationCardsGridProps from "./OrganizationCardsGridProps";

const initialSortModel = [{ sort: "desc", field: "card_last_4_digits" }];

export default function OrganizationCardsGrid() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [state, setState] = React.useState({
    object: null,
    action: null,
    isOpen: false,
  });
  const useQueryFnProps = { searchQuery };
  const dataGridProps = React.useMemo(
    () => OrganizationCardsGridProps({ setState }),
    [setState]
  );

  return (
    <>
      <div>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item flexGrow={1}>
            <CustomContextSearchField setSearchQuery={setSearchQuery} />
          </Grid>
          <Grid item>
            <OrganizationCardCreateOrReplaceForm />
          </Grid>
          <Grid item xs={12}>
            <CustomInfiniteDataGrid
              useQueryFn={useQueryFetchOrganizationCards}
              useQueryFnProps={useQueryFnProps}
              dataGridProps={dataGridProps}
              initialSortModel={initialSortModel}
            />
          </Grid>
        </Grid>
      </div>
      <OrganizationCardAction state={state} setState={setState} />
    </>
  );
}
