import React from "react";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import { getClaimEvaluationResultReportSummary } from "@constants/claims/claim-evaluation-results";
import ClaimEvaluationResultMessages from "./ClaimEvaluationResultMessages";

export default function ClaimEvaluationResults({
  results = [],
  isCreateOrUpdateMode = false,
  type,
  useCollapse = true,
}) {
  const memoizedReportSummary = React.useMemo(
    () => getClaimEvaluationResultReportSummary({ results }),
    [results]
  );
  const memoizedClaimEvaluationResults = React.useMemo(
    () => (
      <ClaimEvaluationResultMessages
        type={type}
        isCreateOrUpdateMode={isCreateOrUpdateMode}
        results={results}
      />
    ),
    [results, isCreateOrUpdateMode, type]
  );

  if (!useCollapse) return memoizedClaimEvaluationResults;

  return (
    <CustomListItemCollapse primary="Report" secondary={memoizedReportSummary}>
      {memoizedClaimEvaluationResults}
    </CustomListItemCollapse>
  );
}
