import React from "react";
import HealthcareOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import CustomAvatar from "@components/Custom/CustomAvatar";
import CustomBox from "@components/Custom/CustomBox";
import CustomImageDropzone from "@components/Custom/CustomImageDropzone";
import { updateOrganizationImage } from "@redux/actions/organizations/organizations";
import {
  selectorAuthCurrentUserOrganization,
  selectorAuthHasAdminPrivileges,
} from "@redux/selectors/auth";
import { useDispatch, useSelector } from "react-redux";

const className = "sizeXxl";
const borderRadius = "20px";

export default function YourOrganizationAvatar() {
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const organization = useSelector(selectorAuthCurrentUserOrganization);
  const dispatch = useDispatch();
  const { slug, image } = organization;

  const submitFn = (newImage) =>
    dispatch(updateOrganizationImage({ slug, image: newImage || "" }));

  return (
    <CustomBox display="grid" justifyContent="center">
      {hasAdminPrivileges ? (
        <CustomImageDropzone
          TitleComponent={HealthcareOrganizationActionDialogTitle}
          borderRadius={borderRadius}
          image={image}
          submitFn={submitFn}
          className={className}
        />
      ) : (
        <CustomAvatar
          src={image}
          title="Click to update image"
          className={className}
          style={{ borderRadius }}
        />
      )}
    </CustomBox>
  );
}
