import React from "react";
import {
  DataGridPro,
  GridActionsCellItem,
  GridRowModes,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { CloseIcon, EditIcon, TickIcon } from "@rimads/assets";

const getColumns = ({
  rowModesModel = {},
  columns = [],
  handleSaveClick = () => {},
  handleEditClick = () => {},
  handleCancelClick = () => {},
}) => [
  ...columns,
  {
    field: "actions",
    type: "actions",
    headerName: "",
    width: 80,
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<TickIcon dimension={14} />}
            label="Save"
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CloseIcon dimension={14} />}
            label="Cancel"
            onClick={handleCancelClick(id)}
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={handleEditClick(id)}
        />,
      ];
    },
  },
];

export default function CustomEditableDataGrid({
  columns = [],
  formData = [],
  setFormData = () => {},
  autoFocusField,
}) {
  const apiRef = useGridApiRef();
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleCellClick = (params) => {
    const { id, field, isEditable } = params || {};
    if (!isEditable) return;

    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: GridRowModes.Edit },
    }));

    // Set focus to the clicked cell
    apiRef.current.setCellFocus(id, field);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    if (autoFocusField) apiRef.current.setCellFocus(id, autoFocusField);
  };

  const handleSaveClick = (id) => () =>
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

  const handleCancelClick = (id) => () =>
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

  const handleProcessRowUpdate = (newRow) => {
    setFormData((prev) =>
      prev.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  const gridColumns = getColumns({
    rowModesModel,
    columns,
    handleSaveClick,
    handleEditClick,
    handleCancelClick,
  });

  return (
    <DataGridPro
      apiRef={apiRef}
      autoHeight
      rows={formData.filter(({ hidden }) => !hidden)}
      columns={gridColumns}
      editMode="row"
      rowModesModel={rowModesModel}
      onRowModesModelChange={setRowModesModel}
      processRowUpdate={handleProcessRowUpdate}
      disableRowSelectionOnClick
      onCellClick={handleCellClick}
      hideFooter
    />
  );
}
