import React from "react";
import CustomChip from "@components/Custom/CustomChip";
import CustomDividedList from "@components/Custom/CustomDividedList";

export default function OrganizationClinicianSpecialitiesData({
  specialities = [],
  divideAt = 3,
}) {
  const list = specialities.map(({ code, name, description }) => ({
    name: name || code,
    title: description,
    color: "grey",
  }));

  return (
    <CustomDividedList
      list={list}
      divideAt={divideAt}
      TooltipObjectComponent={CustomChip}
    />
  );
}
