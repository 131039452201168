import React from "react";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { CloseIcon, SearchIcon } from "@rimads/assets";

export default function CustomSearchField({
  placeholder = "",
  value,
  setValue = () => {},
  isLoading = false,
  className = "",
}) {
  const handleChangeValue = (e) => setValue(e.target.value);
  const handleResetValue = () => setValue("");
  const hasValue = Boolean(value.length);
  const finalClassname = `outlined ${className}`.trim();

  return (
    <Input
      className={finalClassname}
      variant="standard"
      placeholder={placeholder}
      value={value}
      onChange={handleChangeValue}
      startAdornment={startAdornment}
      endAdornment={
        isLoading
          ? loadingIndicator
          : hasValue && (
              <IconButton onClick={handleResetValue}>
                <CloseIcon dimension={14} />
              </IconButton>
            )
      }
      disableUnderline={true}
      fullWidth
      style={{ padding: "0px 8px", minHeight: 40 }}
    />
  );
}

const startAdornment = (
  <IconButton>
    <SearchIcon dimension={18} />
  </IconButton>
);

const loadingIndicator = (
  <IconButton>
    <CircularProgress size={14} />
  </IconButton>
);
