import React from "react";
import DrugNameAutocomplete from "@components/Custom/CustomAutocomplete/DrugNameAutocomplete";
import UnitAutocomplete from "@components/Custom/CustomAutocomplete/UnitAutocomplete";
import CustomButton from "@components/Custom/CustomButton";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import { DRUG_NAME_TYPE_GENERIC } from "@constants/avey/drug-names";
import {
  UNIT_TYPE_CONCENTRATION,
  UNIT_TYPE_PACKAGE_SIZE,
} from "@constants/avey/units";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export default function MedicationActiveIngredientFormData({
  index,
  medication_active_ingredient,
  handleChangeMedicationActiveIngredient = () => {},
  handleRemoveMedicationActiveIngredient = () => {},
}) {
  const [formData, setFormData] = React.useState(medication_active_ingredient);
  const {
    generic_name,
    concentration,
    concentration_unit,
    package_size,
    package_size_unit,
  } = formData;

  React.useEffect(() => {
    setFormData(medication_active_ingredient);
  }, [medication_active_ingredient]);

  const handleRemoveSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleRemoveMedicationActiveIngredient({ index });
  };

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeField = ({ fieldname, newValue }) =>
    setFormData((prev) => ({ ...prev, [fieldname]: newValue }));

  const handleBlur = (e) => {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      if (!currentTarget?.contains(document.activeElement)) {
        handleChangeMedicationActiveIngredient({
          payload: { ...medication_active_ingredient, ...formData },
          index,
        });
      }
    }, 0);
  };

  return (
    <Box flexGrow={1} tabIndex={-1} onBlur={handleBlur}>
      <Grid
        container
        spacing={3}
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs flexGrow={1}>
              <DrugNameAutocomplete
                label="Active ingredient"
                value={generic_name}
                handleChange={(newValue) =>
                  handleChangeField({ fieldname: "generic_name", newValue })
                }
                textFieldProps={{ required: true }}
                drug_name_type={DRUG_NAME_TYPE_GENERIC}
              />
            </Grid>
            <Grid item>
              <CustomButton
                icon="CloseIcon"
                variant="outlined"
                isSquare={true}
                useIconAsLabel={true}
                onClick={handleRemoveSubmit}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomOutlinedTextField
            required
            type="number"
            label="Concentration"
            name="concentration"
            value={concentration}
            onChange={handleChange}
            inputProps={{ min: 0, step: 0.000001 }}
            placeholder="e.g: 500"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <UnitAutocomplete
            label="Concentration unit"
            value={concentration_unit}
            handleChange={(newValue) =>
              handleChangeField({ fieldname: "concentration_unit", newValue })
            }
            textFieldProps={{ placeholder: "e.g. mg" }}
            unit_type={UNIT_TYPE_CONCENTRATION}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomOutlinedTextField
            label="Package size"
            name="package_size"
            value={package_size}
            onChange={handleChange}
            placeholder="e.g. 10"
            wrapperProps={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <UnitAutocomplete
            label="Package size unit"
            value={package_size_unit}
            handleChange={(newValue) =>
              handleChangeField({ fieldname: "package_size_unit", newValue })
            }
            textFieldProps={{ required: false, placeholder: "e.g. pcs" }}
            unit_type={UNIT_TYPE_PACKAGE_SIZE}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
