import React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ClaimDemoDiagnosisDisease from "./ClaimDemoDiagnosisDisease";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { CloseIcon } from "@rimads/assets";

export default function ClaimDemoDiagnosisClinicianDisease({
  object,
  handleRemove = () => {},
}) {
  const { id, name } = object;

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      className="hoverOpacityParent"
    >
      <div
        title="Grab and reorder"
        style={{ display: "grid", alignItems: "center" }}
      >
        <DragIndicatorIcon style={{ height: 16 }} />
      </div>
      <div style={{ flexGrow: 1 }}>
        <ClaimDemoDiagnosisDisease instanceId={id} diseaseName={name} />
      </div>
      <IconButton
        className="opacity-0 hoverOpacityChild"
        onClick={handleRemove}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );
}
