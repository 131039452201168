import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  CLAIM_DEMO_RECOMMENDED_MEDICATIONS_TITLE,
  selectorClaimDemoRecommendedMedications,
  useClaimDemoContext,
} from "../context";
import ClaimDemoMedication from "./ClaimDemoMedication";

export default function ClaimDemoRecommendedMedications() {
  const { state } = useClaimDemoContext();
  const recommendedMedications = selectorClaimDemoRecommendedMedications(state);
  const hasRecommendedMedications = recommendedMedications.length > 0;

  return (
    <Stack spacing={2}>
      <Typography fontWeight="bold">
        {CLAIM_DEMO_RECOMMENDED_MEDICATIONS_TITLE}
      </Typography>
      {hasRecommendedMedications ? (
        <div>
          {recommendedMedications.map((claim_medication, index) => (
            <ClaimDemoMedication
              key={index}
              claim_medication={claim_medication}
              isEditable={false}
              withTypeAnimation={true}
            />
          ))}
        </div>
      ) : (
        <Typography
          variant="body2"
          color="textSecondary"
          display="grid"
          alignItems="center"
          minHeight={28}
        >
          {"No recommendations."}
        </Typography>
      )}
    </Stack>
  );
}
