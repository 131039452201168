import { EES_ENABLED } from "@constants/static/globals";
import {
  apiWrapper,
  AveyBloomApi,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedEesResponse,
  parsePaginatedResponse,
  PayloadError,
  ServerApi,
} from "@helpers/api";
import {
  invalidateTSQ,
  updateObjectTSQ,
  useQueryWrapper,
} from "@helpers/tanstack";

const endpointUrl = "/avey/units/";
const eesEndpointUrl = "/api/ees/units/";
const queryKeySingular = "unit";
const queryKeyPlural = "units";
const queryKeySingularHistory = `${queryKeySingular}_history`;

export const useQueryFetchUnits = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = false,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchUnits, ...apiWrapperProps })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchUnits({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const { submitter_organization__slug, ...otherFilters } = filters;
    if (EES_ENABLED) {
      const response = await ServerApi().post(eesEndpointUrl, {
        page,
        page_size,
        search: searchQuery,
        ordering: sortQuery,
        filters,
      });
      return parsePaginatedEesResponse(response.data);
    } else {
      const queryParams = getQueryParams({
        page,
        page_size,
        search: searchQuery,
        ordering: sortQuery,
        ...otherFilters,
      });
      const response = await AveyBloomApi().get(
        `${endpointUrl}?${queryParams}`
      );
      return parsePaginatedResponse(response.data);
    }
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchUnit = ({
  unit__id = "",
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeySingular, { id: unit__id }],
    queryFn: () =>
      apiWrapper({ fn: fetchUnit, ...apiWrapperProps })({
        unit__id,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchUnit({ unit__id = "" }) {
  try {
    const response = await AveyBloomApi().get(`${endpointUrl}${unit__id}/`);
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function createUnit(data) {
  try {
    const response = await AveyBloomApi().post(endpointUrl, data);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function updateUnit(data) {
  try {
    const response = await AveyBloomApi().put(
      `${endpointUrl}${data.id}/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function deleteUnit(data) {
  try {
    const response = await AveyBloomApi().post(
      `${endpointUrl}${data.id}/activate/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function mergeUnits(data) {
  try {
    const response = await AveyBloomApi().post(`${endpointUrl}merge/`, data);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export function updateUnitObjectTSQ({ object }) {
  updateObjectTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeySingular && queryKey[1].id === object.id,
    object,
  });
}

export function invalidateUnitsTSQ() {
  invalidateTSQ({
    predicate: ({ queryKey }) =>
      [queryKeySingular, queryKeyPlural, queryKeySingularHistory].includes(
        queryKey[0]
      ),
  });
}

export const useQueryFetchUnitHistory = ({
  id,
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [queryKeySingularHistory, { id, filters }],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchUnitHistory,
        ...apiWrapperProps,
      })({ page: pageParam, id, filters }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchUnitHistory({ page = 1, id, filters = {} }) {
  try {
    const queryParams = getQueryParams({ page, ...filters });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${id}/history/?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}
