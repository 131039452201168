import React from "react";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import AccountHeader from "./AccountHeader";
import AccountBody from "./AccountBody";

export default function Account() {
  return (
    <>
      <AccountHeader />
      <CustomPage>
        <AccountBody />
      </CustomPage>
    </>
  );
}
