import React from "react";
import CustomButton from "@components/Custom/CustomButton";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import MenuItem from "@mui/material/MenuItem";

export default function CustomButtonVariant({
  onClick,
  label,
  isTypographyButton = false,
  isMenuItem = false,
  typographyProps = {},
  buttonProps = {},
  disabled = false,
}) {
  const handleClick = (e) => !disabled && onClick?.(e);

  if (isTypographyButton) {
    const { prefix = "", ...otherTypographyProps } = typographyProps;
    const finalLabel = `${prefix} ${label}`.trim();
    return (
      <CustomTypographyButton
        value={finalLabel}
        onClick={handleClick}
        noWrap
        minWidth="min-content"
        {...otherTypographyProps}
      />
    );
  }

  if (isMenuItem)
    return (
      <MenuItem onClick={handleClick} disabled={disabled}>
        {label}
      </MenuItem>
    );

  return (
    <CustomButton
      onClick={handleClick}
      label={label}
      disabled={disabled}
      {...buttonProps}
    />
  );
}
