import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import MenuItem from "@mui/material/MenuItem";
import {
  lockClaimSubmission,
  updateClaimSubmissionObjectTSQ,
} from "@redux/actions/claims/claim-submissions";
import { enqueueSnackbar } from "notistack";

export default function ClaimSubmissionLockForm({ object }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { id, time_locked = null } = object || {};

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    lockClaimSubmission(id)
      .then(({ payload }) => {
        setIsProcessing(false);
        setIsOpen(false);
        enqueueSnackbar({
          variant: "success",
          message: "Claim submission successfully updated",
        });
        updateClaimSubmissionObjectTSQ({ object: payload });
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        enqueueSnackbar({ variant: "error", message: payload });
      });
  };

  const isLocked = Boolean(time_locked);
  const dialogActionProps = {
    isOpen,
    isProcessing,
    handleClose: handleIsOpen,
    handleSubmit: handleSubmit,
    isBodyEmpty: true,
    ...(isLocked
      ? {
          titleComponentProps: { title: "Unlock claim submission" },
          subtitleComponentProps: { title: "Claims will be editable." },
          yesText: "Unlock",
        }
      : {
          titleComponentProps: { title: "Lock claim submission" },
          subtitleComponentProps: {
            title: "Claims will no longer be editable.",
          },
          yesText: "Lock",
        }),
    yesButtonProps: { variant: "contained", color: "secondary" },
  };

  const { title } = dialogActionProps.titleComponentProps;

  return (
    <>
      <MenuItem onClick={handleIsOpen}>{title}</MenuItem>
      <CustomActionDialog {...dialogActionProps} />
    </>
  );
}
