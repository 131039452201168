import CustomStatusTag from "@components/Custom/CustomStatusTag";
import { getUploadsHref } from "@constants/static/routing";
import {
  UPLOAD_STATUS_PROPS_MAPPER,
  UPLOAD_STATUS_TEXT_MAPPER,
} from "@constants/static/upload";
import { parseTimeSince } from "@helpers/parse/time";

export default function ConfigurationsBulkUploadsGridProps({
  model_type,
  bundle_type,
  organization__slug,
}) {
  return {
    columns: [
      {
        flex: 2,
        field: "name",
        headerName: "Name",
      },
      {
        flex: 1,
        field: "status",
        headerName: "Status",
        renderCell: ({ value }) => (
          <CustomStatusTag
            status={value}
            textMapper={UPLOAD_STATUS_TEXT_MAPPER}
            propsMapper={UPLOAD_STATUS_PROPS_MAPPER}
          />
        ),
      },
      {
        flex: 1,
        field: "requesting_user__first_name",
        headerName: "Uploaded by",
        valueGetter: ({ row }) => {
          const { first_name, last_name } = row?.requesting_user;
          return `${first_name} ${last_name}`.trim();
        },
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ],
    disableRowSelectionOnClick: true,
    navigateTo: ({ id }) =>
      getUploadsHref({
        upload__id: id,
        model_type,
        bundle_type,
        organization__slug,
      }),
  };
}
