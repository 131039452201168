import React from "react";
import CustomTooltip from "@components/Custom/CustomTooltip";
import Typography from "@mui/material/Typography";

export const InvalidCardTag = ({ withTooltip = false }) => {
  const title = withTooltip && (
    <>
      <b>{"Your card is invalid"}</b>
      <br />
      {"Please update it to ensure successful payment."}
    </>
  );
  return (
    <CustomTooltip title={title}>
      <Typography
        fontWeight="bold"
        variant="caption"
        color="secondary"
        className="underlined"
      >
        {"Invalid card"}
      </Typography>
    </CustomTooltip>
  );
};

export const ExpiredCardTag = ({ withTooltip = false }) => {
  const title = withTooltip && (
    <>
      <b>{"Your card has expired"}</b>
      <br />
      {"Please update it to ensure successful payment."}
    </>
  );
  return (
    <CustomTooltip title={title}>
      <Typography
        fontWeight="bold"
        variant="caption"
        color="secondary"
        className="underlined"
      >
        {"Card expired"}
      </Typography>
    </CustomTooltip>
  );
};
