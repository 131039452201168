import React from "react";
import { ORGANIZATIONS_FULL_HREF } from "@constants/static/routing";
import { useMatch } from "react-router-dom";

export const getOrganizationTagId = (organization__slug) =>
  `organization-${organization__slug}`;

export default function useScrollOrganizationIntoView({ isFetching }) {
  const match = useMatch(`${ORGANIZATIONS_FULL_HREF}/:organization__slug/*`);
  const organization__slug = match?.params?.organization__slug;

  React.useEffect(() => {
    if (organization__slug && !isFetching) {
      const tagId = getOrganizationTagId(organization__slug);
      const element = document.getElementById(tagId);
      element && element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [organization__slug, isFetching]);

  return null;
}
