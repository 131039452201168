import React from "react";
import CustomSection from "@components/Custom/CustomSection";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ClaimAttachmentData from "./ClaimAttachmentData";

export default function ClaimAttachmentsReviewAndConfirm({
  claim_attachments = [],
}) {
  if (claim_attachments.length === 0) return null;

  return (
    <CustomSection title="Attachments" isMinified={true}>
      <Stack spacing={2}>
        {claim_attachments.map((object, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <Divider />}
            <ClaimAttachmentData
              useLink={false}
              object={object}
              withFileSize={false}
              outerStackProps={{ spacing: 2, alignItems: "center" }}
              innerStackProps={{
                direction: "row",
                spacing: 2,
                alignItems: "center",
              }}
              primaryTypographyProps={{ fontWeight: "normal" }}
            />
          </React.Fragment>
        ))}
      </Stack>
    </CustomSection>
  );
}
