import React from "react";
import OrganizationPatientVisitType from "@components/Authenticated/Configurations/OrganizationPatients/OrganizationPatient/OrganizationPatientData/OrganizationPatientVisitType";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomSection from "@components/Custom/CustomSection";
import CustomValueWithUnit from "@components/Custom/CustomValueWithUnit";
import {
  isOrganizationBundleTypeProcedure,
  ORGANIZATION_BUNDLE_IS_PREAUTHORIZATION_REQUIRED_MAPPER,
  ORGANIZATION_BUNDLE_IS_SECOND_PROCEDURE_DISCOUNT_MAPPER,
} from "@constants/organizations/organization-bundles";
import {
  isInpatientVisitType,
  isOutpatientVisitType,
  ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT_SHORTHAND,
  ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT_SHORTHAND,
} from "@constants/organizations/organization-patients";
import { getIntOrFloat } from "@helpers/parse/numbers";

export default function OrganizationBundlePricingDetailsData({
  object,
  isMinified = false,
}) {
  const {
    bundle_type,
    visit_type,
    inpatient_discount_rate,
    outpatient_discount_rate,
    is_preauthorization_required,
    is_second_procedure_discount,
    undiscounted_price,
    currency,
  } = object;

  const isInpatient = isInpatientVisitType(visit_type);
  const isOutpatient = isOutpatientVisitType(visit_type);
  const isProcedureAndConsumable =
    isOrganizationBundleTypeProcedure(bundle_type);

  return (
    <CustomSection title="Pricing details" isMinified={isMinified}>
      <CustomGrid>
        <CustomLabeledText label="Visit type" useChildren={true}>
          <OrganizationPatientVisitType visit_type={visit_type} />
        </CustomLabeledText>
        <CustomLabeledText label="Undiscounted price" useChildren={true}>
          <CustomValueWithUnit
            value={getIntOrFloat(undiscounted_price)}
            unit={currency}
          />
        </CustomLabeledText>
        {isInpatient && (
          <CustomLabeledText
            label={`${ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT_SHORTHAND} discount`}
            useChildren={true}
          >
            <CustomValueWithUnit
              value={getIntOrFloat(inpatient_discount_rate)}
              unit="%"
            />
          </CustomLabeledText>
        )}
        {isOutpatient && (
          <CustomLabeledText
            label={`${ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT_SHORTHAND} discount`}
            useChildren={true}
          >
            <CustomValueWithUnit
              value={getIntOrFloat(outpatient_discount_rate)}
              unit="%"
            />
          </CustomLabeledText>
        )}
        <CustomLabeledText
          label="Preauthorization"
          value={
            ORGANIZATION_BUNDLE_IS_PREAUTHORIZATION_REQUIRED_MAPPER[
              is_preauthorization_required
            ]
          }
        />
        {isProcedureAndConsumable && (
          <CustomLabeledText
            label="Second procedure discount"
            value={
              ORGANIZATION_BUNDLE_IS_SECOND_PROCEDURE_DISCOUNT_MAPPER[
                is_second_procedure_discount
              ]
            }
          />
        )}
      </CustomGrid>
    </CustomSection>
  );
}
