import React from "react";
import CustomLoader from "@components/Custom/CustomLoader";
import CustomPillButton from "@components/Custom/CustomPillButton";
import CustomSection from "@components/Custom/CustomSection";
import {
  CLAIM_DEMO_OBJECTIVE_SYMPTOMS_TITLE,
  selectorClaimDemoHasObjectiveSymptoms,
  selectorClaimDemoHasOrganizationPatient,
  selectorClaimDemoIsSessionDone,
  selectorClaimDemoObjectiveSummary,
  useClaimDemoContext,
} from "../context";
import ClaimDemoSummarySharedContent from "./ClaimDemoSummarySharedContent";

export default function ClaimDemoObjectiveSummary() {
  const { state, handleUpdateSummary, handleUpdateObjectiveSummary } =
    useClaimDemoContext();
  const hasOrganizationPatient = selectorClaimDemoHasOrganizationPatient(state);
  const hasObjectiveSymptoms = selectorClaimDemoHasObjectiveSymptoms(state);
  const isSessionDone = selectorClaimDemoIsSessionDone(state);
  const objective = selectorClaimDemoObjectiveSummary(state);
  const { isLoading } = objective;

  return (
    <CustomSection
      title={CLAIM_DEMO_OBJECTIVE_SYMPTOMS_TITLE}
      isOpenDefault={hasOrganizationPatient && isSessionDone}
      isCollapsable={false}
      action={
        isSessionDone &&
        (isLoading ? (
          <CustomLoader label="Generating..." />
        ) : (
          hasObjectiveSymptoms && (
            <CustomPillButton
              label="Generate"
              onClick={handleUpdateObjectiveSummary}
            />
          )
        ))
      }
    >
      <ClaimDemoSummarySharedContent
        name="objective"
        object={objective}
        handleUpdateSummary={handleUpdateSummary}
      />
    </CustomSection>
  );
}
