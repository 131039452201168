import { getClaimSubmissionRevelantOrganization } from "@constants/claims/claim-submissions";
import {
  apiWrapper,
  AveyBloomApi,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedResponse,
  PayloadError,
} from "@helpers/api";
import {
  updateObjectTSQ,
  updatePaginatedTSQ,
  useQueryWrapper,
} from "@helpers/tanstack";
import { updateOrganizationTSQ } from "../organizations/organizations";

const endpointUrl = "/claims/claim_submissions/";
const queryKeySingular = "claim_submission";
const queryKeyPlural = "claim_submissions";

export const useQueryFetchClaimSubmissions = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchClaimSubmissions,
        ...apiWrapperProps,
      })({
        page: useInfiniteWrapper ? pageParam : page,
        searchQuery,
        sortQuery,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchClaimSubmissions({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(`${endpointUrl}?${queryParams}`);
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchClaimSubmission = ({
  id = "",
  organization__slug = "",
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeySingular, { id, organization__slug }],
    queryFn: () =>
      apiWrapper({
        fn: fetchClaimSubmission,
        ...apiWrapperProps,
      })({ id, organization__slug }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchClaimSubmission({ id = "", organization__slug = "" }) {
  try {
    if (!id) return null;
    const queryParams = getQueryParams({ organization__slug });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${id}/?${queryParams}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function clearUserClaimSubmissionNotifications({
  id = "",
  organizationRole,
}) {
  try {
    const response = await AveyBloomApi().put(
      `${endpointUrl}${id}/clear_user_claim_submission_notifications/`,
      {}
    );
    const organization = getClaimSubmissionRevelantOrganization({
      object: response.data,
      organizationRole,
    });
    updateOrganizationTSQ({ object: organization });
    return { payload: null };
  } catch (error) {
    return { payload: null };
  }
}

export async function createClaimSubmission(data) {
  try {
    const response = await AveyBloomApi().post(endpointUrl, data);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function updateClaimSubmission(data) {
  try {
    const response = await AveyBloomApi().put(
      `${endpointUrl}${data.id}/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function lockClaimSubmission(id) {
  try {
    const response = await AveyBloomApi().put(
      `${endpointUrl}${id}/toggle_lock/`,
      {}
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export function updateClaimSubmissionObjectTSQ({ object }) {
  const { submitter_organization, processor_organization, tpa_organization } =
    object;
  const slugs = [
    submitter_organization?.slug,
    processor_organization?.slug,
    tpa_organization?.slug,
  ].filter(Boolean);
  updateObjectTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeySingular && queryKey[1]?.id === object.id,
    object,
  });
  updatePaginatedTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeyPlural &&
      slugs.includes(queryKey[1]?.filters?.organization__slug),
    object,
  });
}
