import React from "react";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import UserManagementGrid from "./UserManagementGrid";
import UserManagementHeader from "./UserManagementHeader";

export default function UserManagement() {
  return (
    <>
      <UserManagementHeader />
      <CustomPage>
        <UserManagementGrid />
      </CustomPage>
    </>
  );
}
