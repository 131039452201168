import React from "react";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteGrid from "@components/Custom/CustomDataGrid/CustomInfiniteGrid";
import CustomFilters from "@components/Custom/CustomFilters";
import { getClaimSubmissionClaimMapper } from "@constants/claims/claim-status";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { useQueryFetchClaimSubmissions } from "@redux/actions/claims/claim-submissions";
import {
  selectorAuthCurrentUserOrganizationRole,
  selectorAuthHasSubmitterPrivileges,
  selectorAuthIsSubmitterOrganization,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ClaimsExportForm from "../../ClaimExports/ClaimExportForm";
import ClaimSubmissionCreateForm from "../ClaimSubmission/ClaimSubmissionForms/ClaimSubmissionCreateForm";
import ClaimSubmissionObject from "../ClaimSubmission/ClaimSubmissionObject";
import {
  filterOptions,
  formatFilters,
  initialFilterState,
} from "./ClaimSubmissionsFilters";

export default function ClaimSubmissionsGrid() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const { organization__slug } = useParams();
  const organizationRole = useSelector(selectorAuthCurrentUserOrganizationRole);
  const hasSubmitterPrivileges = useSelector(
    selectorAuthHasSubmitterPrivileges
  );
  const isSubmitterOrganization = useSelector(
    selectorAuthIsSubmitterOrganization
  );
  const { colorMapper, textMapper } = React.useMemo(
    () => getClaimSubmissionClaimMapper({ organizationRole }),
    [organizationRole]
  );
  const infiniteQuery = useQueryFetchClaimSubmissions({
    filters: { organization__slug, ...filters },
    searchQuery,
    sortQuery: "-time_locked,-time_updated,-name",
  });

  return (
    <div>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item flexGrow={1}>
          <CustomContextSearchField setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item>
          <CustomFilters
            initialFilterState={initialFilterState}
            filterOptions={filterOptions}
            formatFilters={formatFilters}
            setFilters={setFilters}
          />
        </Grid>
        {hasSubmitterPrivileges && (
          <>
            <Grid item>
              <ClaimSubmissionCreateForm />
            </Grid>
            <Grid item>
              <ClaimsExportForm />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <CustomInfiniteGrid
            infiniteQuery={infiniteQuery}
            ObjectComponent={ClaimSubmissionObject}
            objectComponentProps={{
              colorMapper,
              textMapper,
              isSubmitterOrganization,
              organizationRole,
            }}
            gridContainerProps={{ spacing: 2 }}
            gridItemProps={{ xs: 12, md: 6, lg: 4, xl: 3 }}
            noResultsFoundProps={{
              src: "/assets/images/placeholders/no-results.png",
              wrapperProps: { spacing: 2, textAlign: "center", py: 8 },
              subtitle: "No submissions found.",
            }}
            customLoadingIndicator={<LinearProgress />}
          />
        </Grid>
      </Grid>
    </div>
  );
}
