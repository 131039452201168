import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import OrganizationBundleConsumablesData from "../OrganizationBundleData/OrganizationBundleConsumablesData";
import OrganizationBundleCptsData from "../OrganizationBundleData/OrganizationBundleCptsData";
import OrganizationBundleCodeDetailsData from "./OrganizationBundleCodeDetailsData";
import OrganizationBundlePricingDetailsData from "./OrganizationBundlePricingDetailsData";

export default function OrganizationBundleData({ object, isMinified = false }) {
  const spacing = isMinified ? 2 : 4;
  const {
    organization_bundle_medication = {},
    organization_bundle_cpts = [],
    organization_bundle_consumables = [],
    internal_code,
    description,
    ...otherProps
  } = object || {};
  const hasCpts = organization_bundle_cpts.length > 0;
  const hasConsumables = organization_bundle_consumables.length > 0;
  const hasSection = hasCpts || hasConsumables;

  return (
    <Stack spacing={spacing}>
      <OrganizationBundleCodeDetailsData
        object={{ internal_code, description, organization_bundle_medication }}
        isMinified={isMinified}
      />
      <OrganizationBundlePricingDetailsData
        object={otherProps}
        isMinified={isMinified}
      />
      {hasSection && (
        <div>
          <Grid container spacing={spacing}>
            {hasCpts && (
              <Grid item xs={12} md={isMinified ? 12 : hasConsumables ? 6 : 12}>
                <OrganizationBundleCptsData
                  organization_bundle_cpts={organization_bundle_cpts}
                  isMinified={isMinified}
                />
              </Grid>
            )}
            {hasConsumables && (
              <Grid item xs={12} md={isMinified ? 12 : hasCpts ? 6 : 12}>
                <OrganizationBundleConsumablesData
                  organization_bundle_consumables={
                    organization_bundle_consumables
                  }
                  isMinified={isMinified}
                />
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </Stack>
  );
}

export const OrganizationBundleDataReviewAndConfirm = ({ object }) => (
  <OrganizationBundleData object={object} isMinified={true} />
);
