import React from "react";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  exportConsumables,
  useQueryFetchConsumableExports,
} from "@redux/actions/avey/consumables";
import ConfigurationsBulkExportForm from "../../Configurations/ConfigurationsBulkExportForm";
import ConfigurationsBulkExportHistory from "../../Configurations/ConfigurationsBulkExportForm/ConfigurationsBulkExportHistory";
import HealthcareOrganizationActionDialogTitle from "../../Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import { describeFilters } from "./ConsumablesFilters";

export default function ConsumablesExports({ useQueryFnProps }) {
  return (
    <CustomMoreActions
      icon="DownloadIcon"
      useRotateProps={{ useRotate: false }}
    >
      <ConfigurationsBulkExportForm
        label="consumables"
        submitFn={exportConsumables}
        useQueryFnProps={useQueryFnProps}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
        describeFilters={describeFilters}
      />
      <ConfigurationsBulkExportHistory
        drawerProps={{
          TitleComponent: HealthcareOrganizationActionDialogTitle,
          titleComponentProps: { title: "Consumable exports" },
        }}
        useQueryFn={useQueryFetchConsumableExports}
        describeFilters={describeFilters}
      />
    </CustomMoreActions>
  );
}
