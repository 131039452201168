import React from "react";
import { useQueryFetchDrugNames } from "@redux/actions/avey/drug-names";
import { selectorAuthCurrentUserOrganizationSlug } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import {
  getDrugNameOptionLabel,
  isDrugNameOptionEqualToValue,
  renderDrugNameOption,
} from "./DrugNameAutocompleteComponents";

export default function DrugNameAutocomplete({
  label = "Drug",
  error = false,
  value,
  drug_name_type = null,
  handleChange = () => {},
  wrapperProps = {},
  textFieldProps = {},
  autocompleteProps = {},
}) {
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const useQueryFnProps = {
    filters: {
      time_deleted__isnull: true,
      newer_version__isnull: true,
      submitter_organization__slug,
      drug_name_type,
    },
    useInfiniteWrapper: true,
  };
  return (
    <CustomInfiniteAutocomplete
      label={label}
      error={error}
      wrapperProps={wrapperProps}
      textFieldProps={{ required: true, ...textFieldProps }}
      autocompleteProps={{
        renderOption: renderDrugNameOption,
        ...autocompleteProps,
      }}
      useQueryFn={useQueryFetchDrugNames}
      useQueryFnProps={useQueryFnProps}
      getOptionLabel={getDrugNameOptionLabel}
      isOptionEqualToValue={isDrugNameOptionEqualToValue}
      value={value}
      handleChange={handleChange}
    />
  );
}
