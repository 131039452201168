import {
  BLUE_MARKED_PILL,
  PENDING_STATUS,
  RED_MARKED_PILL,
} from "@components/Custom/CustomPills";

export const CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING = "P";
export const CLAIM_SUBMISSION_UPLOAD_STATUS_SUCCESS = "S";
export const CLAIM_SUBMISSION_UPLOAD_STATUS_FAILURE = "F";

export const CLAIM_SUBMISSION_UPLOAD_STATUS_TEXT_MAPPER = {
  [CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING]: "Processing",
  [CLAIM_SUBMISSION_UPLOAD_STATUS_SUCCESS]: "Success",
  [CLAIM_SUBMISSION_UPLOAD_STATUS_FAILURE]: "Failure",
};

export const CLAIM_SUBMISSION_UPLOAD_STATUS_PROPS_MAPPER = {
  [CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING]: PENDING_STATUS,
  [CLAIM_SUBMISSION_UPLOAD_STATUS_SUCCESS]: BLUE_MARKED_PILL,
  [CLAIM_SUBMISSION_UPLOAD_STATUS_FAILURE]: RED_MARKED_PILL,
};

export const CLAIM_SUBMISSION_UPLOAD_STATUS_COLOR_MAPPER = {
  [CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING]: "primary",
  [CLAIM_SUBMISSION_UPLOAD_STATUS_SUCCESS]: "success",
  [CLAIM_SUBMISSION_UPLOAD_STATUS_FAILURE]: "error",
};
