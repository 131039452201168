import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import { isErrorFieldnames } from "@constants/claims/claim-evaluation-results";
import ClaimMedicationFormData from "./ClaimMedicationFormData";

export default function ClaimMedicationUpdateForm({
  visit_type,
  state,
  setState = () => {},
  handleUpdateClaimMedication = () => {},
}) {
  const { isOpen, object, index } = state;
  const [formData, setFormData] = React.useState(object);
  const { error_fieldnames = [] } = object || {};
  const errorFieldnames = isErrorFieldnames(error_fieldnames || []);

  const handleCloseClaimMedication = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setState((prev) => ({ ...prev, isOpen: false }));
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    handleUpdateClaimMedication({ payload: formData, index });
    handleCloseClaimMedication(e);
  };

  React.useEffect(() => {
    isOpen && setFormData(object);
  }, [isOpen, object]);

  return (
    <CustomActionDialog
      maxWidth="sm"
      isOpen={isOpen}
      handleClose={handleCloseClaimMedication}
      handleSubmit={handleSubmit}
      titleComponentProps={{ title: "Update medication" }}
      useDivider={true}
    >
      <ClaimMedicationFormData
        formData={formData}
        setFormData={setFormData}
        visit_type={visit_type}
        errorFieldnames={errorFieldnames}
      />
    </CustomActionDialog>
  );
}
