import React from "react";
import CustomButton from "@components/Custom/CustomButton";
import { FORGOT_PASSWORD_HREF } from "@constants/static/routing";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";

export default function SetPasswordFormTokenError({
  tokenError,
  isNewUser = false,
}) {
  return (
    <Stack spacing={6}>
      <Stack spacing={3}>
        <Typography variant="h5" fontWeight="bold">
          {"Invalid link"}
        </Typography>
        <Typography variant="body2" whiteSpace="pre-line">
          {tokenError}
        </Typography>
      </Stack>
      {!isNewUser && (
        <CustomButton
          variant="contained"
          color="primary"
          component={NavLink}
          to={FORGOT_PASSWORD_HREF}
          label="Reset password"
        />
      )}
    </Stack>
  );
}
