import React from "react";
import OrganizationCardContent from "../OrganizationCard/OrganizationCardContent";
import OrganizationCardCreateOrReplaceForm from "../OrganizationCardCreateOrReplaceForm";

export default function OrganizationCardReplaceCard({
  isOpen,
  object,
  handleClose = () => {},
}) {
  const { id } = object;
  const dialogProps = {
    titleComponentProps: { title: "Replace card" },
    SubtitleComponent: OrganizationCardContent,
    subtitleComponentProps: { object, isElevatedVersion: true },
    okText: "Replace",
  };

  return (
    <OrganizationCardCreateOrReplaceForm
      isOpenReplace={isOpen}
      withCreateButton={false}
      objectIdToReplace={id}
      handleCloseCallback={handleClose}
      dialogProps={dialogProps}
    />
  );
}
