import React from "react";
import Typography from "@mui/material/Typography";
import { CustomDisabledTag } from "../CustomDisabledTag";
import CustomVersionTag from "../CustomVersionTag";
import CustomGrid from "./CustomGrid";

export default function CustomHeaderTitle({
  title = undefined,
  subtitle = undefined,
  variant = "h3",
  version = undefined,
  time_deleted = undefined,
  children,
}) {
  const hasTitle = Boolean(title);
  const hasSubtitle = Boolean(subtitle);

  return (
    <CustomGrid rowSpacing={3} columnSpacing={3} alignItems="center">
      {(hasTitle || hasSubtitle) && (
        <div>
          {hasTitle && (
            <Typography variant={variant} className="ellipsis-1" title={title}>
              {title}
            </Typography>
          )}
          {hasSubtitle && (
            <Typography
              variant="body2"
              fontWeight="light"
              className="ellipsis-1"
              title={subtitle}
            >
              {subtitle}
            </Typography>
          )}
        </div>
      )}
      {children}
      {Boolean(time_deleted) && (
        <CustomDisabledTag time_deleted={time_deleted} />
      )}
      {Boolean(version) && <CustomVersionTag version={version} />}
    </CustomGrid>
  );
}
