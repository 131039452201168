import React from "react";
import { DISABLED_ROW_CLASSNAME } from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { ORGANIZATION_PATIENT_GENDER_MAPPER } from "@constants/organizations/organization-patients";
import { getOrganizationPatientsHref } from "@constants/static/routing";
import { parseDob, parseTimeSince } from "@helpers/parse/time";
import Typography from "@mui/material/Typography";
import OrganizationPatientIdentitiesDataSummary from "../OrganizationPatient/OrganizationPatientData/OrganizationPatientIdentitiesDataSummary";

export default function OrganizationPatientsGridProps({
  processor_organization__slug,
  hiddenColumns = [],
}) {
  return {
    columns: [
      {
        flex: 1,
        field: "patient_id",
        headerName: "Member ID",
      },
      {
        flex: 1,
        field: "provider_file_number",
        headerName: "Provider File Number",
      },
      {
        flex: 1.5,
        field: "first_name",
        headerName: "Name",
        renderCell: ({ row }) => (
          <div>
            <Typography variant="body2">
              {`${row?.first_name} ${row?.last_name}`.trim()}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {`${row?.localized_first_name || ""} ${
                row?.localized_last_name || ""
              }`.trim()}
            </Typography>
          </div>
        ),
      },
      {
        flex: 1,
        field: "gender",
        headerName: "Gender",
        valueGetter: (params) =>
          ORGANIZATION_PATIENT_GENDER_MAPPER[params?.value],
      },
      {
        flex: 1.5,
        field: "dob",
        headerName: "Date of birth",
        valueGetter: (params) => parseDob(params?.value)?.dobStr || "-",
      },
      {
        flex: 1.5,
        sortable: false,
        field: "organization_patient_identities",
        headerName: "Identities",
        renderCell: ({ value }) => (
          <OrganizationPatientIdentitiesDataSummary
            organization_patient_identities={value}
          />
        ),
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ].filter(({ field }) => !hiddenColumns.includes(field)),
    getRowClassName: ({ row }) => row?.time_deleted && DISABLED_ROW_CLASSNAME,
    disableRowSelectionOnClick: true,
    navigateTo: ({ id }) =>
      Boolean(processor_organization__slug) &&
      getOrganizationPatientsHref({
        organization__slug: processor_organization__slug,
        organization_patient__id: id,
      }),
  };
}
