import React from "react";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import CustomBox from "@components/Custom/CustomBox";
import CircularProgress from "@mui/material/CircularProgress";
import { CloseIcon, SearchIcon } from "@rimads/assets";
import { AVEY_WHITE } from "@styles/theme";

export default function OrganizationsSearchField({
  isOpen,
  handleOpen,
  inputFieldId,
  setParentSearchQuery = () => {},
  isFetching = false,
}) {
  const isInitialMount = React.useRef(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const isSearching = isFetching || isLoading;

  const border = isOpen ? `1px solid ${AVEY_WHITE}` : "none";
  const color = isOpen ? AVEY_WHITE : "transparent";
  const position = isOpen ? "start" : "end";
  const dimension = isOpen ? 18 : 32;
  const hasEndAdornment = isOpen && (isSearching || Boolean(searchQuery));
  const handleChange = (e) => setSearchQuery(e.target.value);
  const handleReset = () => setSearchQuery("");

  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    setIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      setParentSearchQuery(searchQuery);
      setIsLoading(false);
    }, 250);
    return () => {
      setIsLoading(false);
      clearTimeout(delayDebounceFn);
    };
  }, [searchQuery, setParentSearchQuery]);

  React.useEffect(() => {
    if (!isOpen) {
      const timeout = setTimeout(() => setSearchQuery(""), 150);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  return (
    <CustomBox
      className="button fullTransition"
      onClick={handleOpen}
      display="grid"
    >
      <Input
        id={inputFieldId}
        disableUnderline
        fullWidth
        style={{
          color,
          border,
          cursor: "pointer",
          borderRadius: "8px",
          padding: "4px 10px",
          minHeight: 40,
        }}
        placeholder="Search"
        startAdornment={
          <InputAdornment position={position}>
            <IconButton>
              <SearchIcon primary_fill={AVEY_WHITE} dimension={dimension} />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          hasEndAdornment && (
            <InputAdornment position="end">
              {isSearching ? (
                <CircularProgress size={18} style={{ color: AVEY_WHITE }} />
              ) : (
                <IconButton onClick={handleReset}>
                  <CloseIcon dimension={18} primary_fill={AVEY_WHITE} />
                </IconButton>
              )}
            </InputAdornment>
          )
        }
        value={searchQuery}
        onChange={handleChange}
        autoComplete="off"
        autoCorrect="off"
      />
    </CustomBox>
  );
}
