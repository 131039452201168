import React from "react";
import { CLAIM_SECTION_MEDICATIONS_TITLE } from "@constants/claims/claims";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { selectorClaimDemoMedications, useClaimDemoContext } from "../context";
import ClaimDemoMedication from "./ClaimDemoMedication";
import ClaimDemoMedicationsAddForm from "./ClaimDemoMedicationsAddForm";

export default function ClaimDemoClinicianMedications() {
  const { state, handleRemoveClaimMedication } = useClaimDemoContext();
  const claimMedications = selectorClaimDemoMedications(state);
  const hasClaimMedications = claimMedications.length > 0;

  return (
    <Stack spacing={2}>
      <Typography fontWeight="bold">
        {CLAIM_SECTION_MEDICATIONS_TITLE}
      </Typography>
      {hasClaimMedications && (
        <div>
          {claimMedications.map((claim_medication, index) => (
            <ClaimDemoMedication
              key={index}
              claim_medication={claim_medication}
              handleRemove={() => handleRemoveClaimMedication(index)}
            />
          ))}
        </div>
      )}
      <ClaimDemoMedicationsAddForm />
    </Stack>
  );
}
