import React from "react";
import Stack from "@mui/material/Stack";
import OrganizationPolicyCoveragesData from "../OrganizationPolicyData/OrganizationPolicyCoveragesData";
import OrganizationPolicyDetailsData from "../OrganizationPolicyData/OrganizationPolicyDetailsData";

export default function OrganizationPolicyData({
  object = {},
  isMinified = false,
}) {
  const spacing = isMinified ? 2 : 4;
  const { organization_policy_coverages = [], ...otherData } = object || {};

  return (
    <Stack spacing={spacing}>
      <OrganizationPolicyDetailsData
        object={otherData}
        isMinified={isMinified}
      />
      <OrganizationPolicyCoveragesData
        organization_policy_coverages={organization_policy_coverages}
        isMinified={isMinified}
      />
    </Stack>
  );
}

export const OrganizationPolicyDataReviewAndConfirm = ({ object }) => (
  <OrganizationPolicyData object={object} isMinified={true} />
);
