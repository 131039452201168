import React from "react";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";

export function CustomExternalLink({
  href = "",
  label = "",
  wrapperProps = {},
  children = null,
  isTypography = true,
}) {
  const onClick = (e) => e?.stopPropagation();

  if (isTypography)
    return (
      <Typography
        component="a"
        href={href}
        target="_blank"
        rel="noreferrer"
        fontWeight="bold"
        variant="body2"
        className="opacityOnHover-50 underlined"
        onClick={onClick}
        {...wrapperProps}
      >
        {label}
      </Typography>
    );

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="opacityOnHover-50"
      onClick={onClick}
      {...wrapperProps}
    >
      {children}
    </a>
  );
}

export function CustomTypographyLink({ href = "", children, ...props }) {
  return (
    <Typography
      className="underlined"
      variant="body2"
      fontWeight="bold"
      {...props}
    >
      <NavLink to={href} className="opacityOnHover-50">
        {children}
      </NavLink>
    </Typography>
  );
}
