import React from "react";
import { TOKEN_SHORT_NAME_PLURAL } from "@constants/static/payment";
import { getOrganizationPaymentCycleHref } from "@constants/static/routing";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { parseMonthRange } from "@helpers/parse/time";
import { getOrganizationPaymentCycleConsumption } from "@helpers/parse/tokens";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import OrganizationPaymentCyclePaymentPlanType from "../../OrganizationWallet/OrganizationPaymentCycle/OrganizationPaymentCyclePaymentPlanType";

const OrganizationPaymentCyclesGridProps = {
  columns: [
    {
      flex: 0.5,
      field: "version",
      headerName: "Version",
    },
    {
      flex: 1,
      field: "start_date",
      headerName: "Interval",
      valueGetter: ({ row }) => {
        const { start_date, end_date } = row;
        return parseMonthRange({ start_date, end_date });
      },
    },
    {
      flex: 1,
      field: "payment_plan_type",
      headerName: "Payment plan",
      renderCell: ({ value }) => (
        <OrganizationPaymentCyclePaymentPlanType
          payment_plan_type={value}
          withTooltip={false}
        />
      ),
    },
    {
      flex: 1,
      field: "num_tokens_consumed",
      headerName: `Consumed ${TOKEN_SHORT_NAME_PLURAL}`,
      renderCell: ({ row }) => {
        const { balanceNumerator, balanceDenominator } =
          getOrganizationPaymentCycleConsumption(row);

        return (
          <Stack spacing={0.5} alignItems="baseline" direction="row">
            <Typography variant="h6">{balanceNumerator}</Typography>
            <Typography variant="caption">{balanceDenominator}</Typography>
          </Stack>
        );
      },
    },
    {
      flex: 1,
      field: "num_claims_processed",
      headerName: "Processed claims",
      renderCell: ({ value }) => (
        <Typography variant="h6">{getIntOrFloat(value || "0")}</Typography>
      ),
    },
  ],
  disableRowSelectionOnClick: true,
  navigateTo: ({ id }) =>
    getOrganizationPaymentCycleHref({ organization_payment_cycle__id: id }),
};

export default OrganizationPaymentCyclesGridProps;
