import {
  ORGANIZATION_ROLE_PROCESSOR,
  ORGANIZATION_ROLE_SUBMITTER,
  ORGANIZATION_ROLE_TPA,
} from "@constants/organizations/organizations";
import { parseIsoDate } from "@helpers/parse/time";

export const emptyClaimSubmissionFormData = {
  name: "",
  start_date: null,
  end_date: null,
};

export const formatClaimSubmissionFormData = ({
  formData,
  processor_organization,
}) => {
  const { id = undefined, name, start_date, end_date } = formData;
  return {
    id,
    name: name?.trim(),
    start_date: parseIsoDate(start_date),
    end_date: parseIsoDate(end_date),
    processor_organization: processor_organization?.id,
  };
};

export const getClaimSubmissionRevelantOrganization = ({
  object,
  organizationRole,
}) => {
  const { submitter_organization, processor_organization } = object;
  switch (organizationRole) {
    case ORGANIZATION_ROLE_SUBMITTER:
      return processor_organization;

    case ORGANIZATION_ROLE_PROCESSOR:
    case ORGANIZATION_ROLE_TPA:
      return submitter_organization;

    default:
      return null;
  }
};
