import React from "react";
import Typography from "@mui/material/Typography";

export default function CustomVersionTag({ version = undefined }) {
  if (!version) return null;
  const versionStr = `Version ${version}`;

  return (
    <Typography
      variant="body2"
      className="borderRadius-8 elevated-8 whiteBg"
      p="6px 12px"
      noWrap
      minWidth="min-content"
    >
      {versionStr}
    </Typography>
  );
}
