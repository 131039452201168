import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  deleteOrganizationCard,
  invalidateOrganizationCardsTSQ,
} from "@redux/actions/organizations/organization-cards";
import { enqueueSnackbar } from "notistack";
import OrganizationCardContent from "../OrganizationCard/OrganizationCardContent";

export default function OrganizationCardDeactivateCard({
  isOpen,
  object,
  handleClose = () => {},
}) {
  const { id } = object;
  const [isProcessing, setIsProcessing] = React.useState(false);
  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    deleteOrganizationCard(id)
      .then(() => {
        setIsProcessing(false);
        enqueueSnackbar({
          variant: "success",
          message: "Card successfully disabled",
        });
        handleClose();
        invalidateOrganizationCardsTSQ();
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        enqueueSnackbar({ variant: "error", message: payload });
      });
  };

  return (
    <CustomActionDialog
      isOpen={isOpen}
      isProcessing={isProcessing}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      titleComponentProps={{ title: "Disable card?" }}
      subtitleComponentProps={{ title: "This action cannot be undone." }}
      noText="Keep it"
      yesText="Disable card"
      yesButtonProps={{ color: "secondary", variant: "contained" }}
    >
      <OrganizationCardContent object={object} isElevatedVersion={true} />
    </CustomActionDialog>
  );
}
