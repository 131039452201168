import { EES_ENABLED } from "@constants/static/globals";
import {
  apiWrapper,
  AveyBloomApi,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedEesResponse,
  parsePaginatedResponse,
  ServerApi,
} from "@helpers/api";
import { useQueryWrapper } from "@helpers/tanstack";

const endpointUrl = "/avey/identity_types/";
const eesEndpointUrl = "/api/ees/identity-types/";
const queryKeyPlural = "identity_types";

export const useQueryFetchIdentityTypes = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchIdentityTypes, ...apiWrapperProps })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 300_000,
    ...reactQueryProps,
  });
};

async function fetchIdentityTypes({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const { country_id, ...otherFilters } = filters;
    if (EES_ENABLED) {
      const response = await ServerApi().post(eesEndpointUrl, {
        page,
        page_size,
        search: searchQuery,
        ordering: sortQuery,
        filters,
      });
      return parsePaginatedEesResponse(response.data);
    } else {
      const queryParams = getQueryParams({
        page,
        page_size,
        search: searchQuery,
        ordering: sortQuery,
        ...otherFilters,
      });
      const response = await AveyBloomApi().get(
        `${endpointUrl}?${queryParams}`
      );
      return parsePaginatedResponse(response.data);
    }
  } catch (error) {
    return emptyPaginatedResponse;
  }
}
