import React from "react";
import Typography from "@mui/material/Typography";

export const isIdentityTypeOptionEqualToValue = (option, value) =>
  option?.id === value?.id;

export const getIdentityTypeOptionLabel = (option) => {
  if (!Boolean(option)) return "";
  const { name = "", description = "" } = option;
  return `${name} ${description}`.trim();
};

export const renderIdentityTypeOption = (props, option) => {
  const { name, description } = option;
  return (
    <div {...props}>
      <div>
        <Typography variant="body2" fontWeight="bold">
          {name}
        </Typography>
        <Typography variant="body2" fontWeight="light">
          {description}
        </Typography>
      </div>
    </div>
  );
};
