import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import {
  CustomSummaryDialog,
  initialSummaryState,
} from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import CustomButtonVariant from "@components/Custom/CustomButtonVariant";
import {
  emptyOrganizationPatientFormData,
  formatOrganizationPatientFormData,
} from "@constants/organizations/organization-patients";
import { getOrganizationPatientsHref } from "@constants/static/routing";
import {
  createOrganizationPatient,
  invalidateOrganizationPatientsTSQ,
} from "@redux/actions/organizations/organization-patients";
import { enqueueSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import OrganizationPatientData from "../OrganizationPatientData";
import OrganizationPatientFormData from "../OrganizationPatientFormData";

export default function OrganizationPatientCreateForm({
  handleSelectPatient = () => {},
  withNavigate = false,
  isFullScreen = false,
  isTypographyButton = false,
}) {
  const { organization__slug: processor_organization__slug } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(
    emptyOrganizationPatientFormData
  );
  const [summaryState, setSummaryState] = React.useState(initialSummaryState);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const { isOpen: isOpenAlert } = alertState;
  const { isOpen: isOpenSummary, summaries } = summaryState;
  const isOpenSummaryDialog = isOpenSummary && !isOpenAlert;

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e, isCheck = true) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProcessing(true);
    const formattedFormData = formatOrganizationPatientFormData({
      isCheck,
      processor_organization__slug,
      formData,
    });
    createOrganizationPatient(formattedFormData)
      .then(({ payload }) => {
        const { instance, summaries, exceptions } = payload;
        if (Boolean(instance)) {
          setIsOpen(false);
          enqueueSnackbar({
            variant: "success",
            message: "Patient successfully created",
          });
          invalidateOrganizationPatientsTSQ();
          handleSelectPatient(instance);
          withNavigate &&
            navigate(
              getOrganizationPatientsHref({
                organization__slug: processor_organization__slug,
                organization_patient__id: instance.id,
              })
            );
        } else if (exceptions?.length > 0) {
          setAlertState({ isOpen: true, errors: exceptions });
        } else {
          setSummaryState({ isOpen: true, summaries });
        }
      })
      .catch(({ payload }) => {
        setAlertState({ isOpen: true, errorStr: payload });
        setSummaryState((prev) => ({ ...prev, isOpen: false }));
      })
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitData = (e) => handleSubmit(e, false);
  const handleCheckData = (e) => handleSubmit(e, true);

  React.useEffect(() => {
    isOpen && setFormData(emptyOrganizationPatientFormData);
  }, [isOpen]);

  const dialogProps = isFullScreen
    ? { fullScreen: true }
    : { fullScreen: false, maxWidth: "lg" };

  return (
    <>
      <CustomButtonVariant
        onClick={handleIsOpen}
        label="Add a patient"
        isTypographyButton={isTypographyButton}
        isMenuItem={!isTypographyButton}
        typographyProps={{ prefix: "+" }}
        buttonProps={{ icon: "PlusIcon" }}
      />
      <CustomActionDialog
        useCustomCloseIcon={true}
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleCheckData}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title: "New patient", fullScreen: true }}
        isOk={true}
        okText="Save"
        {...dialogProps}
      >
        <OrganizationPatientFormData
          formData={formData}
          setFormData={setFormData}
        />
      </CustomActionDialog>
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
      <CustomSummaryDialog
        summaries={summaries}
        isOpen={isOpenSummaryDialog}
        isProcessing={isProcessing}
        setSummaryState={setSummaryState}
        handleSubmit={handleSubmitData}
        ContentComponent={OrganizationPatientData}
        contentComponentProps={{
          object: formData,
          isMinified: true,
          useCustomSection: true,
        }}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
      />
    </>
  );
}
