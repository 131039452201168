import React from "react";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import { getClaimExportHref } from "@constants/static/routing";
import { MenuItem } from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import ClaimExportClaimSubmissionForm from "./ClaimExportClaimSubmissionForm";
import ClaimExportClaimSubmissionsForm from "./ClaimExportClaimSubmissionsForm";

export default function ClaimsExportForm({ useQueryFnPropsClaims = {} }) {
  const { organization__slug, claim_submission__id } = useParams();
  const isSingletonClaimSubmission = Boolean(claim_submission__id);
  const exportHref = getClaimExportHref({
    organization__slug,
    claim_submission__id,
  });

  return (
    <CustomMoreActions
      icon="DownloadIcon"
      useRotateProps={{ useRotate: false }}
    >
      {isSingletonClaimSubmission ? (
        <ClaimExportClaimSubmissionForm
          useQueryFnPropsClaims={useQueryFnPropsClaims}
        />
      ) : (
        <ClaimExportClaimSubmissionsForm />
      )}
      <NavLink to={exportHref}>
        <MenuItem>{"View export history"}</MenuItem>
      </NavLink>
    </CustomMoreActions>
  );
}
