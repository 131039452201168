import {
  BLUE_MARKED_PILL,
  PENDING_STATUS,
  RED_MARKED_PILL,
} from "@components/Custom/CustomPills";
import { getOptions } from "@helpers/hooks/objects";
import { parseDate, parseIsoDatetime } from "@helpers/parse/time";

export const TIME_DELETED__ISNULL_MAPPER = {
  true: "Active",
  false: "Deactivated",
};

const TIME_DELETED__ISNULL_OPTIONS = getOptions(TIME_DELETED__ISNULL_MAPPER);

export const TIME_DELETED_ISNULL_FILTER = {
  title: "Status",
  type: "boolean",
  options: TIME_DELETED__ISNULL_OPTIONS,
};

export const TIME_UPDATED_FILTER = {
  title: "Last updated",
  type: "date-range",
};

export const timeUpdatedApplyFilter = ({
  formattedFilters = {},
  time_updated = [null, null],
}) => {
  if (time_updated[0])
    formattedFilters["time_updated__gte"] = parseIsoDatetime(time_updated[0]);

  if (time_updated[1])
    formattedFilters["time_updated__lt"] = parseIsoDatetime(time_updated[1]);

  return formattedFilters;
};

export const timeDeletedIsNullApplyFilter = ({
  formattedFilters = {},
  time_deleted__isnull = [],
}) => {
  if (time_deleted__isnull.length === 1)
    formattedFilters["time_deleted__isnull"] = time_deleted__isnull[0];

  return formattedFilters;
};

export const searchQueryDescribeFilter = ({
  formattedOutput = [],
  searchQuery = "",
}) => {
  if (searchQuery)
    formattedOutput.push({ label: "Search term", value: `“${searchQuery}”` });
  return formattedOutput;
};

export const timeUpdatedDescribeFilter = ({
  formattedOutput = [],
  time_updated__gte = "",
  time_updated__lt = "",
}) => {
  if (time_updated__gte || time_updated__lt) {
    const label = "Last updated";
    const from = parseDate(time_updated__gte);
    const to = parseDate(time_updated__lt);

    if (from && to) formattedOutput.push({ label, from, to });
    else if (from) formattedOutput.push({ label, from });
    else if (to) formattedOutput.push({ label, to });
  }
  return formattedOutput;
};

export const timeDeletedIsNullDescribeFilter = ({
  formattedOutput = [],
  time_deleted__isnull = "",
}) => {
  if (time_deleted__isnull)
    formattedOutput.push({
      label: "Status",
      value: time_deleted__isnull === "true" ? "Active" : "Deactivated",
    });

  return formattedOutput;
};

export const EXPORT_STATUS_PENDING = "P";
export const EXPORT_STATUS_SUCCESS = "S";
export const EXPORT_STATUS_FAILURE = "F";

export const EXPORT_STATUS_TEXT_MAPPER = {
  [EXPORT_STATUS_PENDING]: "Pending",
  [EXPORT_STATUS_SUCCESS]: "Success",
  [EXPORT_STATUS_FAILURE]: "Failure",
};

export const EXPORT_STATUS_PROPS_MAPPER = {
  [EXPORT_STATUS_PENDING]: PENDING_STATUS,
  [EXPORT_STATUS_SUCCESS]: BLUE_MARKED_PILL,
  [EXPORT_STATUS_FAILURE]: RED_MARKED_PILL,
};
