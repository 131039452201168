import React from "react";
import CustomAvatar from "@components/Custom/CustomAvatar";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CloseIcon, PlusIcon } from "@rimads/assets";
import { AVEY_GREY_DARK } from "@styles/theme";
import CustomTooltip from "./CustomTooltip";

const COLOR_CLASSNAME_MAPPER = {
  white: "whiteBg elevated-8",
  grey: "greyBgF2",
  lightGrey: "greyBgF9",
  lightYellow: "lightYellowBg",
  lightRed: "lightRedBg",
  lightBlue: "lightBlueBg",
  blue: "blueBg",
  yellow: "yellowBg",
  red: "redBg",
  outlined: "outlined whiteBg",
};

const wrapperProps = {
  padding: "6px 12px",
  minHeight: 36,
  height: "100%",
  borderRadius: "8px",
};

const ListItemButtonWrapper = ({
  children,
  disableRipple = false,
  wrapperStyleProps = {},
}) => {
  if (disableRipple)
    return <ListItem style={wrapperProps}>{children}</ListItem>;

  return (
    <ListItemButton
      disableGutters
      style={{ ...wrapperProps, ...wrapperStyleProps }}
    >
      {children}
    </ListItemButton>
  );
};

export default function CustomChip({
  title,
  name,
  image,
  imageProps = {},
  color = "white",
  handleClick = undefined,
  hasCloseIcon = false,
  hasPlusIcon = false,
  placement = "bottom",
  children,
  wrapperStyleProps = {},
}) {
  const hasImage = Boolean(image);
  const className = (
    (COLOR_CLASSNAME_MAPPER[color] || "") + " borderRadius-8"
  ).trim();
  const disableRipple = !Boolean(handleClick || title);

  return (
    <CustomTooltip title={title} placement={placement}>
      <div
        className={className}
        style={{ height: "100%" }}
        onClick={handleClick}
      >
        <ListItemButtonWrapper
          disableRipple={disableRipple}
          wrapperStyleProps={wrapperStyleProps}
        >
          <Stack spacing={1} direction="row" alignItems="center" width="100%">
            {hasImage && (
              <CustomAvatar
                src={image}
                variant="square"
                className="sizeXs"
                {...imageProps}
              />
            )}
            <div style={{ flexGrow: 1 }}>
              {Boolean(name) && <Typography variant="body2">{name}</Typography>}
              {children}
            </div>
            {hasCloseIcon && (
              <CloseIcon
                style={{ paddingLeft: "4px" }}
                primary_fill={AVEY_GREY_DARK}
                dimension={14}
              />
            )}
            {hasPlusIcon && (
              <PlusIcon
                style={{ paddingLeft: "4px" }}
                primary_fill={AVEY_GREY_DARK}
                dimension={10}
              />
            )}
          </Stack>
        </ListItemButtonWrapper>
      </div>
    </CustomTooltip>
  );
}
