import React from "react";
import Typography from "@mui/material/Typography";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import { useQueryFetchInstances } from "@redux/actions/server/phoenix";

const isOptionEqualToValue = (option, value) => option?.id === value?.id;

export const getInstanceOptionLabel = (option) => {
  const { name, disease_name } = option;
  return name.includes("#") ? disease_name : name;
};

const renderOption = (props, option) => (
  <div {...props}>
    <Typography variant="body2">{getInstanceOptionLabel(option)}</Typography>
  </div>
);

export default function InstanceAutocomplete({
  value = null,
  handleChange = () => {},
  useQueryFnProps = {},
  textFieldProps = {},
}) {
  return (
    <CustomInfiniteAutocomplete
      withSearchIcon={false}
      autocompleteProps={{
        disableClearable: true,
        style: { width: "100%" },
        renderOption,
      }}
      textFieldProps={textFieldProps}
      useQueryFn={useQueryFetchInstances}
      getOptionLabel={getInstanceOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      value={value}
      handleChange={handleChange}
      useQueryFnProps={useQueryFnProps}
    />
  );
}
