import React from "react";
import HealthcareOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import {
  CustomSummaryDialog,
  initialSummaryState,
} from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import CustomButton from "@components/Custom/CustomButton";
import { formatMedicationFormData } from "@constants/avey/medications";
import { getMedicationsHref } from "@constants/static/routing";
import {
  invalidateMedicationsTSQ,
  updateMedication,
} from "@redux/actions/avey/medications";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { MedicationDataReviewAndConfirm } from "../MedicationData";
import MedicationFormData from "../MedicationFormData";

export default function MedicationUpdateForm({ object }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(object);
  const [summaryState, setSummaryState] = React.useState(initialSummaryState);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const { isOpen: isOpenSummary, summaries } = summaryState;
  const { isOpen: isOpenAlert } = alertState;

  const isOpenSummaryDialog = isOpenSummary && !isOpenAlert;

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e, isCheck = true) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProcessing(true);
    const formattedFormData = formatMedicationFormData({
      isCheck,
      formData,
    });
    updateMedication(formattedFormData)
      .then(({ payload }) => {
        const { instance, summaries, exceptions } = payload;
        if (Boolean(instance)) {
          setIsOpen(false);
          enqueueSnackbar({
            variant: "success",
            message: "Medication successfully updated",
          });
          invalidateMedicationsTSQ();
          navigate(getMedicationsHref({ medication__id: instance.id }));
        } else if (exceptions?.length > 0) {
          setAlertState({ isOpen: true, errors: exceptions });
        } else {
          setSummaryState({ isOpen: true, summaries });
        }
      })
      .catch(({ payload }) => {
        setAlertState({ isOpen: true, errorStr: payload });
        setSummaryState((prev) => ({ ...prev, isOpen: false }));
      })
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitData = (e) => handleSubmit(e, false);
  const handleCheckData = (e) => handleSubmit(e, true);

  React.useEffect(() => {
    if (isOpen) {
      setFormData(object);
      setAlertState(initialAlertState);
    }
    setSummaryState(initialSummaryState);
  }, [isOpen, object]);

  return (
    <>
      <CustomButton
        variant="contained"
        label="Edit medication"
        icon="EditIcon"
        onClick={handleIsOpen}
      />
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleCheckData}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
        titleComponentProps={{ title: "Edit medication" }}
        maxWidth="md"
      >
        <MedicationFormData formData={formData} setFormData={setFormData} />
      </CustomActionDialog>
      <CustomSummaryDialog
        summaries={summaries}
        isOpen={isOpenSummaryDialog}
        isProcessing={isProcessing}
        setSummaryState={setSummaryState}
        handleSubmit={handleSubmitData}
        ContentComponent={MedicationDataReviewAndConfirm}
        contentComponentProps={{ object: formData }}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
        summaryDialogProps={{ maxWidth: "sm" }}
      />
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
    </>
  );
}
