import React from "react";
import CustomInputAdornment from "@components/Custom/CustomInputAdornment";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function ClaimProcessorUpdateClaimMedicationFormData({
  claim_medication,
  handleChangeClaimMedication = () => {},
}) {
  const { organization_bundle, amount_requested, amount_approved, currency } =
    claim_medication;
  const { internal_code } = organization_bundle || {};

  const handleChange = (e) =>
    handleChangeClaimMedication({
      ...claim_medication,
      amount_approved: e.target.value,
    });

  return (
    <div>
      <Grid container spacing={3} alignItems="center" columns={3}>
        <Grid item xs={1}>
          <Typography variant="body2">{internal_code}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="body2">{`${amount_requested} ${currency}`}</Typography>
        </Grid>
        <Grid item xs={1}>
          <CustomOutlinedTextField
            required
            autoComplete="off"
            type="number"
            value={amount_approved}
            onChange={handleChange}
            inputProps={{ min: 0, max: amount_requested, step: 0.000001 }}
            InputProps={{
              endAdornment: <CustomInputAdornment value={currency} />,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
