import React from "react";
import CustomNotProvidedText from "@components/Custom/CustomNotProvidedText";
import CustomSection, {
  customSectionBodyPadding,
} from "@components/Custom/CustomSection";
import CustomThickDivider from "@components/Custom/CustomThickDivider";
import { claimMedicationToClaimProcedure } from "@constants/claims/claim-procedures";
import { CLAIM_SECTION_MEDICATIONS_TITLE } from "@constants/claims/claims";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useClaimContext } from "../Claim/context";
import ClaimMedication from "./ClaimMedication";
import ClaimMedicationCreateForm from "./ClaimMedicationCreateForm";
import ClaimMedicationUpdateForm from "./ClaimMedicationUpdateForm";

export default function ClaimMedications() {
  const {
    isCreateOrUpdateMode = false,
    claim = {},
    setFormData = () => {},
    meta_mds_cols = [],
  } = useClaimContext();
  const { claim_medications = [], visit_type, currency } = claim;
  const numMedications = claim_medications.length;
  const subtitle = numMedications > 0 && numMedications;
  const hasMedications = numMedications > 0;
  const [state, setState] = React.useState({
    isOpen: false,
    object: null,
    index: 0,
  });

  const handleSelectClaimMedication = React.useCallback(
    ({ object, index }) => setState({ isOpen: true, object, index }),
    []
  );

  const handleAddClaimMedication = React.useCallback(
    ({ payload }) => {
      setFormData((prev) => ({
        ...prev,
        claim_medications: [...prev.claim_medications, payload],
      }));
    },
    [setFormData]
  );

  const handleUpdateClaimMedication = React.useCallback(
    ({ payload, index }) => {
      setFormData((prev) => ({
        ...prev,
        claim_medications: prev.claim_medications.map((object, position) =>
          index === position ? payload : object
        ),
      }));
    },
    [setFormData]
  );

  const handleRemoveClaimMedication = React.useCallback(
    ({ index }) =>
      setFormData((prev) => ({
        ...prev,
        claim_medications: prev.claim_medications.filter(
          (_, position) => index !== position
        ),
      })),
    [setFormData]
  );

  const handleMigrateClaimMedication = React.useCallback(
    ({ index }) => {
      const new_claim_medications = [...claim_medications];
      const [claim_medication] = new_claim_medications.splice(index, 1);
      const claim_procedure = claimMedicationToClaimProcedure({
        claim_medication,
        currency,
        meta_mds_cols,
      });
      setFormData((prev) => ({
        ...prev,
        claim_medications: new_claim_medications,
        claim_procedures: [...(prev.claim_procedures || []), claim_procedure],
      }));
    },
    [claim_medications, currency, meta_mds_cols, setFormData]
  );

  return (
    <>
      <CustomSection
        title={CLAIM_SECTION_MEDICATIONS_TITLE}
        subtitle={subtitle}
        bodyProps={{ p: 0 }}
        isCollapsable={!isCreateOrUpdateMode}
      >
        {claim_medications.map((claim_medication, index) => (
          <div key={index}>
            {index !== 0 && <CustomThickDivider />}
            <ClaimMedication
              index={index}
              object={claim_medication}
              isCreateOrUpdateMode={isCreateOrUpdateMode}
              handleSelectClaimMedication={handleSelectClaimMedication}
              handleRemoveClaimMedication={handleRemoveClaimMedication}
              handleMigrateClaimMedication={handleMigrateClaimMedication}
            />
          </div>
        ))}
        {!hasMedications && (
          <Box p={customSectionBodyPadding}>
            <CustomNotProvidedText title={CLAIM_SECTION_MEDICATIONS_TITLE} />
          </Box>
        )}
        {isCreateOrUpdateMode && (
          <>
            <Divider />
            <Box p={customSectionBodyPadding}>
              <ClaimMedicationCreateForm
                handleAddClaimMedication={handleAddClaimMedication}
                currency={currency}
                visit_type={visit_type}
                meta_mds_cols={meta_mds_cols}
              />
            </Box>
          </>
        )}
      </CustomSection>
      {isCreateOrUpdateMode && (
        <ClaimMedicationUpdateForm
          visit_type={visit_type}
          state={state}
          setState={setState}
          handleUpdateClaimMedication={handleUpdateClaimMedication}
        />
      )}
    </>
  );
}
