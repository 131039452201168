import React from "react";
import Account from "@components/Authenticated/Account/Account";
import NavigationDrawer from "@components/Authenticated/Navigation/NavigationDrawer";
import NavigationTopAppBarMenu from "@components/Authenticated/Navigation/NavigationTopAppBar/NavigationTopAppBarMenu";
import NavigationTopAppBarUserAvatar from "@components/Authenticated/Navigation/NavigationTopAppBar/NavigationTopAppBarUserAvatar";
import CustomCircularProgress from "@components/Custom/CustomCircularProgress";
import UnauthenticatedWrapper from "@components/Unauthenticated/UnauthenticatedWrapper";
import {
  ACCOUNT_HREF,
  BILLING_MANAGEMENT_HREF,
  HEALTHCARE_CONFIGURATIONS_HREF,
  INSURANCE_CONFIGURATIONS_HREF,
  LOGIN_HREF,
  LOGOUT_HREF,
  ORGANIZATIONS_HREF,
  USER_MANAGEMENT_HREF,
  YOUR_ORGANIZATION_HREF,
} from "@constants/static/routing";
import useIsAuthenticatedHook from "@helpers/hooks/useIsAuthenticatedHook";
import useWebsocketHook from "@helpers/hooks/useWebsocketHook";
import Stack from "@mui/material/Stack";
import { Navigate, Route, Routes } from "react-router-dom";
import BillingManagementRoutes from "./billing-management-routes";
import HealthcareConfigurationsRoutes from "./healthcare-configurations-routes";
import InsuranceConfigurationsRoutes from "./insurance-configurations-routes";
import OrganizationsRoutes from "./organizations-routes";
import UserManagementRoutes from "./user-management-routes";
import YourOrganizationRoutes from "./your-organization-routes";

export default function AuthenticatedRoutes() {
  const {
    isAuthenticated,
    isLoadingAuth,
    isSubmitterOrganization,
    hasSubmitterPrivileges,
    hasAdminPrivileges,
    hasUser,
    useClaimModeToggle,
  } = useIsAuthenticatedHook();
  useWebsocketHook();

  if (isLoadingAuth)
    return (
      <UnauthenticatedWrapper backgroundColor="white">
        <CustomCircularProgress />
      </UnauthenticatedWrapper>
    );

  if (!isAuthenticated) return <Navigate to={LOGIN_HREF} />;

  if (!hasUser) return <Navigate to={LOGOUT_HREF} />;

  return (
    <div className="flex">
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        position="fixed"
        right={0}
        p="12px 24px"
        zIndex={3}
      >
        <NavigationTopAppBarUserAvatar />
        <NavigationTopAppBarMenu />
      </Stack>
      <NavigationDrawer useClaimModeToggle={useClaimModeToggle} />
      <div className="routes-wrapper">
        <Routes>
          <Route
            path={`${ORGANIZATIONS_HREF}/*`}
            Component={OrganizationsRoutes}
          />
          <Route
            path={`${YOUR_ORGANIZATION_HREF}/*`}
            Component={YourOrganizationRoutes}
          />
          <Route path={`${ACCOUNT_HREF}/*`} Component={Account} />
          {hasSubmitterPrivileges && hasAdminPrivileges && (
            <Route
              path={`${BILLING_MANAGEMENT_HREF}/*`}
              Component={BillingManagementRoutes}
            />
          )}
          {hasAdminPrivileges && (
            <Route
              path={`${USER_MANAGEMENT_HREF}/*`}
              Component={UserManagementRoutes}
            />
          )}
          {isSubmitterOrganization && (
            <>
              <Route
                path={`${HEALTHCARE_CONFIGURATIONS_HREF}/*`}
                Component={HealthcareConfigurationsRoutes}
              />
              <Route
                path={`${INSURANCE_CONFIGURATIONS_HREF}/*`}
                Component={InsuranceConfigurationsRoutes}
              />
            </>
          )}
          <Route path="*" element={<Navigate to={ORGANIZATIONS_HREF} />} />
        </Routes>
      </div>
    </div>
  );
}
