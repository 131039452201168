import React from "react";
import Stack from "@mui/material/Stack";
import CustomDndList from "./CustomDndList";
import CustomDndListItem from "./CustomDndListItem";
import { DragDropContext } from "react-beautiful-dnd";

export function CustomDndListOfLists({
  lists,
  setLists,
  getListTitle = () => {},
  renderObject = () => {},
  renderAddObject = () => {},
  renderAddList = () => {},
}) {
  function onDragEnd(result) {
    const { source, destination } = result;
    if (!destination) return;
    const newList = Array.from(lists);
    const [item] = newList[source.droppableId].splice(source.index, 1);
    item && newList[destination.droppableId].splice(destination.index, 0, item);
    setLists(newList);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Stack spacing={3}>
        {lists.map((list, horizontal_index) => (
          <CustomDndList
            key={horizontal_index}
            index={horizontal_index}
            droppableId={horizontal_index}
            title={getListTitle(horizontal_index)}
          >
            {list.map((object, vertical_index) => (
              <CustomDndListItem
                key={vertical_index}
                index={vertical_index}
                draggableId={`${horizontal_index}-${vertical_index}`}
              >
                {renderObject({
                  object,
                  horizontal_index,
                  vertical_index,
                })}
              </CustomDndListItem>
            ))}
            <CustomDndListItem
              key={list.length}
              index={list.length}
              draggableId={`${horizontal_index}-add`}
            >
              {renderAddObject({ horizontal_index })}
            </CustomDndListItem>
          </CustomDndList>
        ))}
        {renderAddList({ horizontal_index: lists.length })}
      </Stack>
    </DragDropContext>
  );
}

export function CustomDndListSingleton({
  list,
  setList,
  title = "",
  renderObject = () => {},
}) {
  function onDragEnd(result) {
    const { source, destination } = result;
    if (!destination) return;
    const newList = Array.from(list);
    const [item] = newList.splice(source.index, 1);
    item && newList.splice(destination.index, 0, item);
    setList(newList);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <CustomDndList index="0" droppableId="0" title={title}>
        {list.map((object, index) => (
          <CustomDndListItem
            key={object?.id}
            index={index}
            draggableId={`0-${object?.id}`}
          >
            {renderObject({ object, index })}
          </CustomDndListItem>
        ))}
      </CustomDndList>
    </DragDropContext>
  );
}
