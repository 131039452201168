import React from "react";
import Lottie from "lottie-react";
import groovyWalkAnimation from "./lottie.json";

export default function CustomLottieAnimation({
  loop = false,
  className = "",
  style = {},
}) {
  return (
    <Lottie
      loop={loop}
      animationData={groovyWalkAnimation}
      style={{ position: "absolute", width: "100%", ...style }}
      className={className}
    />
  );
}
