import {
  PHOENIX_GENDER_FEMALE,
  PHOENIX_GENDER_MALE,
} from "@constants/organizations/organization-patients";
import {
  INSTANCE_CORNEAL_ABRASION,
  INSTANCE_CORNEAL_LACERATION,
  INSTANCE_SCIATICA,
} from "./instances";
import {
  CLAIM_MEDICATION_CIPROFLOXACIN,
  CLAIM_MEDICATION_ERYTHROMYCIN,
  CLAIM_MEDICATION_GABAPENTIN,
  CLAIM_MEDICATION_IBUPROFEN,
  CLAIM_MEDICATION_NAPROXEN,
  CLAIM_MEDICATION_OFLOXACIN,
  CLAIM_MEDICATION_PANADOL,
  CLAIM_MEDICATION_SOLFACETAMIDE_SODIUM,
  CLAIM_MEDICATION_TIZANIDINE_2,
  CLAIM_MEDICATION_TIZANIDINE_4,
} from "./medications";
import {
  CLAIM_PROCEDURE_62322,
  CLAIM_PROCEDURE_65220,
  CLAIM_PROCEDURE_65222,
  CLAIM_PROCEDURE_72100,
  CLAIM_PROCEDURE_72131,
  CLAIM_PROCEDURE_72148,
  CLAIM_PROCEDURE_92002,
  CLAIM_PROCEDURE_92230,
  CLAIM_PROCEDURE_92250,
  CLAIM_PROCEDURE_95886,
  CLAIM_PROCEDURE_97760,
} from "./procedures";

export const PLAN_TAG_PREGNANT = "PLAN_TAG_PREGNANT";
export const PLAN_TAG_PREGNANT_THIRD_TRIMESTER =
  "PLAN_TAG_PREGNANT_THIRD_TRIMESTER";
export const PLAN_TAG_GASTRIC_ULCERS = "PLAN_TAG_GASTRIC_ULCERS";

const hasIntersection = (claim_diagnoses_ids, instances) =>
  getIds(instances).some((instanceId) =>
    claim_diagnoses_ids.includes(instanceId)
  );

const getIds = (list) => list.map(({ id }) => id);

const RECOMMENDED_PLANS = [
  // CASE 3
  {
    getIsValidAge: (ageInt) => true,
    getIsValidGender: (phoenixGender) => true,
    getIsValidTags: (claim_symptom_tags) =>
      claim_symptom_tags.includes(PLAN_TAG_GASTRIC_ULCERS),
    getIsValidClaimDiagnoses: (claim_diagnoses_ids) =>
      hasIntersection(claim_diagnoses_ids, [
        INSTANCE_CORNEAL_ABRASION,
        INSTANCE_CORNEAL_LACERATION,
      ]),
    recommendations: {
      claim_medications: [
        CLAIM_MEDICATION_ERYTHROMYCIN,
        CLAIM_MEDICATION_SOLFACETAMIDE_SODIUM,
        CLAIM_MEDICATION_CIPROFLOXACIN,
        CLAIM_MEDICATION_OFLOXACIN,
        CLAIM_MEDICATION_IBUPROFEN,
        CLAIM_MEDICATION_NAPROXEN,
      ],
      claim_procedures: [
        CLAIM_PROCEDURE_92002,
        CLAIM_PROCEDURE_92250,
        CLAIM_PROCEDURE_92230,
        CLAIM_PROCEDURE_65220,
        CLAIM_PROCEDURE_65222,
      ],
    },
  },
  {
    getIsValidAge: (ageInt) => true,
    getIsValidGender: (phoenixGender) => true,
    getIsValidTags: (claim_symptom_tags) =>
      !claim_symptom_tags.includes(PLAN_TAG_GASTRIC_ULCERS),
    getIsValidClaimDiagnoses: (claim_diagnoses_ids) =>
      hasIntersection(claim_diagnoses_ids, [
        INSTANCE_CORNEAL_ABRASION,
        INSTANCE_CORNEAL_LACERATION,
      ]),
    recommendations: {
      claim_medications: [
        CLAIM_MEDICATION_ERYTHROMYCIN,
        CLAIM_MEDICATION_SOLFACETAMIDE_SODIUM,
        CLAIM_MEDICATION_CIPROFLOXACIN,
        CLAIM_MEDICATION_OFLOXACIN,
        CLAIM_MEDICATION_PANADOL,
      ],
      claim_procedures: [
        CLAIM_PROCEDURE_92002,
        CLAIM_PROCEDURE_92250,
        CLAIM_PROCEDURE_92230,
        CLAIM_PROCEDURE_65220,
        CLAIM_PROCEDURE_65222,
      ],
    },
  },

  // CASE 1 & 2
  {
    getIsValidAge: (ageInt) => true,
    getIsValidGender: (phoenixGender) => true,
    getIsValidTags: (claim_symptom_tags) => claim_symptom_tags.length !== 0,
    getIsValidClaimDiagnoses: (claim_diagnoses_ids) =>
      hasIntersection(claim_diagnoses_ids, [INSTANCE_SCIATICA]),
    recommendations: {
      claim_medications: [
        CLAIM_MEDICATION_IBUPROFEN,
        CLAIM_MEDICATION_NAPROXEN,
        CLAIM_MEDICATION_PANADOL,
        CLAIM_MEDICATION_TIZANIDINE_2,
        CLAIM_MEDICATION_GABAPENTIN,
      ],
      claim_procedures: [
        CLAIM_PROCEDURE_72131,
        CLAIM_PROCEDURE_72100,
        CLAIM_PROCEDURE_72148,
        CLAIM_PROCEDURE_95886,
        CLAIM_PROCEDURE_97760,
        CLAIM_PROCEDURE_62322,
      ],
    },
  },
  {
    getIsValidAge: (ageInt) => true,
    getIsValidGender: (phoenixGender) => true,
    getIsValidTags: (claim_symptom_tags) =>
      claim_symptom_tags.length === 1 &&
      claim_symptom_tags[0] === PLAN_TAG_GASTRIC_ULCERS,
    getIsValidClaimDiagnoses: (claim_diagnoses_ids) =>
      hasIntersection(claim_diagnoses_ids, [INSTANCE_SCIATICA]),
    recommendations: {
      claim_medications: [
        CLAIM_MEDICATION_PANADOL,
        CLAIM_MEDICATION_GABAPENTIN,
        CLAIM_MEDICATION_TIZANIDINE_4,
      ],
      claim_procedures: [
        CLAIM_PROCEDURE_72131,
        CLAIM_PROCEDURE_72100,
        CLAIM_PROCEDURE_72148,
        CLAIM_PROCEDURE_95886,
        CLAIM_PROCEDURE_97760,
        CLAIM_PROCEDURE_62322,
      ],
    },
  },
  {
    getIsValidAge: (ageInt) => true,
    getIsValidGender: (phoenixGender) => true,
    getIsValidTags: (claim_symptom_tags) =>
      claim_symptom_tags.length === 1 &&
      claim_symptom_tags[0] === PLAN_TAG_GASTRIC_ULCERS,
    getIsValidClaimDiagnoses: (claim_diagnoses_ids) =>
      hasIntersection(claim_diagnoses_ids, [INSTANCE_SCIATICA]),
    recommendations: {
      claim_medications: [],
      claim_procedures: [],
    },
  },
  {
    getIsValidAge: (ageInt) => true,
    getIsValidGender: (phoenixGender) =>
      phoenixGender === PHOENIX_GENDER_FEMALE,
    getIsValidTags: (claim_symptom_tags) =>
      claim_symptom_tags.length === 1 &&
      claim_symptom_tags[0] === PLAN_TAG_PREGNANT,
    getIsValidClaimDiagnoses: (claim_diagnoses_ids) =>
      hasIntersection(claim_diagnoses_ids, [INSTANCE_SCIATICA]),
    recommendations: {
      claim_medications: [
        CLAIM_MEDICATION_PANADOL,
        CLAIM_MEDICATION_GABAPENTIN,
        CLAIM_MEDICATION_IBUPROFEN,
        CLAIM_MEDICATION_NAPROXEN,
      ],
      claim_procedures: [
        CLAIM_PROCEDURE_72148,
        CLAIM_PROCEDURE_95886,
        CLAIM_PROCEDURE_97760,
        CLAIM_PROCEDURE_62322,
      ],
    },
  },
  {
    getIsValidAge: (ageInt) => true,
    getIsValidGender: (phoenixGender) =>
      phoenixGender === PHOENIX_GENDER_FEMALE,
    getIsValidTags: (claim_symptom_tags) =>
      claim_symptom_tags.length === 2 &&
      claim_symptom_tags.includes(PLAN_TAG_PREGNANT) &&
      claim_symptom_tags.includes(PLAN_TAG_PREGNANT_THIRD_TRIMESTER),
    getIsValidClaimDiagnoses: (claim_diagnoses_ids) =>
      hasIntersection(claim_diagnoses_ids, [INSTANCE_SCIATICA]),
    recommendations: {
      claim_medications: [
        CLAIM_MEDICATION_PANADOL,
        CLAIM_MEDICATION_GABAPENTIN,
      ],
      claim_procedures: [
        CLAIM_PROCEDURE_72148,
        CLAIM_PROCEDURE_95886,
        CLAIM_PROCEDURE_97760,
        CLAIM_PROCEDURE_62322,
      ],
    },
  },
];

export const getRecommendedPlan = ({
  ageInt = 25,
  phoenixGender = PHOENIX_GENDER_MALE,
  claim_diagnoses = [],
  claim_symptom_tags = [],
}) => {
  const plan = RECOMMENDED_PLANS.find(
    ({
      getIsValidAge,
      getIsValidGender,
      getIsValidClaimDiagnoses,
      getIsValidTags,
    }) => {
      const isValidAge = getIsValidAge(ageInt);
      const isValidGender = getIsValidGender(phoenixGender);
      const isValidClaimDiagnoses = getIsValidClaimDiagnoses(
        getIds(claim_diagnoses)
      );
      const isValidTags = getIsValidTags(claim_symptom_tags);
      return (
        isValidAge && isValidGender && isValidClaimDiagnoses && isValidTags
      );
    }
  );

  if (Boolean(plan)) return plan.recommendations;

  return { claim_medications: [], claim_procedures: [] };
};
