import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import {
  CustomSummaryDialog,
  initialSummaryState,
} from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import CustomButton from "@components/Custom/CustomButton";
import {
  formatOrganizationPolicyFormData,
  getEmptyOrganizationPolicyFormData,
} from "@constants/organizations/organization-policies";
import { getOrganizationPoliciesHref } from "@constants/static/routing";
import useFetchOrganization from "@helpers/hooks/useFetchObjects/useFetchOrganization";
import {
  invalidateOrganizationPoliciesTSQ,
  updateOrganizationPolicy,
} from "@redux/actions/organizations/organization-policies";
import { selectorAuthCurrentUserOrganizationCurrency } from "@redux/selectors/auth";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { OrganizationPolicyDataReviewAndConfirm } from "../OrganizationPolicyData";
import OrganizationPolicyFormData from "../OrganizationPolicyFormData";

export default function OrganizationPolicyUpdateForm({ object }) {
  const navigate = useNavigate();
  const currency = useSelector(selectorAuthCurrentUserOrganizationCurrency);
  const { organization__slug: processor_organization__slug } = useParams();
  const { data: processor_organization } = useFetchOrganization();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(object);
  const [summaryState, setSummaryState] = React.useState(initialSummaryState);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const { isOpen: isOpenSummary, summaries } = summaryState;
  const { isOpen: isOpenAlert } = alertState;
  const isOpenSummaryDialog = isOpenSummary && !isOpenAlert;
  const initialFormData = React.useMemo(
    () => getEmptyOrganizationPolicyFormData({ currency }),
    [currency]
  );
  const { is_processor_tpa = false } = processor_organization || {};

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e, isCheck = true) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProcessing(true);
    const formattedFormData = formatOrganizationPolicyFormData({
      isCheck,
      processor_organization__slug,
      formData,
    });
    updateOrganizationPolicy(formattedFormData)
      .then(({ payload }) => {
        const { instance, summaries, exceptions } = payload;
        if (Boolean(instance)) {
          enqueueSnackbar({
            variant: "success",
            message: "Policy successfully updated",
          });
          invalidateOrganizationPoliciesTSQ();
          navigate(
            getOrganizationPoliciesHref({
              organization__slug: processor_organization__slug,
              organization_policy__id: instance.id,
            })
          );
        } else if (exceptions?.length > 0) {
          setAlertState({ isOpen: true, errors: exceptions });
        } else {
          setSummaryState({ isOpen: true, summaries });
        }
      })
      .catch(({ payload }) => {
        setAlertState({ isOpen: true, errorStr: payload });
        setSummaryState((prev) => ({ ...prev, isOpen: false }));
      })
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitData = (e) => handleSubmit(e, false);
  const handleCheckData = (e) => handleSubmit(e, true);

  React.useEffect(() => {
    if (isOpen) {
      const { organization_policy_coverages = [], ...otherProps } = object;
      setFormData(
        organization_policy_coverages.length === 0
          ? { ...initialFormData, ...otherProps }
          : object
      );
      setAlertState(initialAlertState);
    }
    setSummaryState(initialSummaryState);
  }, [isOpen, object, initialFormData]);

  return (
    <>
      <CustomButton
        variant="contained"
        label="Edit policy"
        icon="EditIcon"
        onClick={handleIsOpen}
      />
      <CustomActionDialog
        fullScreen={true}
        useCustomCloseIcon={true}
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleCheckData}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title: "Edit policy", fullScreen: true }}
        isOk={true}
        okText="Save"
      >
        <OrganizationPolicyFormData
          is_processor_tpa={is_processor_tpa}
          formData={formData}
          setFormData={setFormData}
        />
      </CustomActionDialog>
      <CustomSummaryDialog
        summaries={summaries}
        isOpen={isOpenSummaryDialog}
        isProcessing={isProcessing}
        setSummaryState={setSummaryState}
        handleSubmit={handleSubmitData}
        ContentComponent={OrganizationPolicyDataReviewAndConfirm}
        contentComponentProps={{ object: formData }}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
      />
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
    </>
  );
}
