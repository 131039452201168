import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import OrganizationCardPrimary from "../OrganizationCards/OrganizationCard/OrganizationCardPrimary";
import OrganizationPaymentCyclesGrid from "../OrganizationPaymentCycles/OrganizationPaymentCyclesGrid";
import OrganizationPaymentCycleForm from "../OrganizationWallet/OrganizationPaymentCycle/OrganizationPaymentCycleForm";
import OrganizationPaymentCycleBalanceForm from "../OrganizationWallet/OrganizationPaymentCycleBalance/OrganizationPaymentCycleBalanceForm";

export default function BillingManagementBody({ object }) {
  return (
    <Stack spacing={5}>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <OrganizationPaymentCycleBalanceForm object={object} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrganizationPaymentCycleForm object={object} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <OrganizationCardPrimary />
          </Grid>
        </Grid>
      </div>
      <OrganizationPaymentCyclesGrid />
    </Stack>
  );
}
