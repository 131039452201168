import React from "react";
import { useQueryFetchUnits } from "@redux/actions/avey/units";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import {
  getUnitOptionLabel,
  isUnitOptionEqualToValue,
  renderUnitOption,
} from "./UnitAutocompleteComponents";
import { useSelector } from "react-redux";
import { selectorAuthCurrentUserOrganizationSlug } from "@redux/selectors/auth";

export default function UnitAutocomplete({
  label = "Unit",
  error = false,
  value,
  unit_type = null,
  handleChange = () => {},
  wrapperProps = {},
  textFieldProps = {},
  autocompleteProps = {},
}) {
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const useQueryFnProps = {
    filters: {
      time_deleted__isnull: true,
      newer_version__isnull: true,
      submitter_organization__slug,
      unit_type,
    },
    useInfiniteWrapper: true,
  };
  return (
    <CustomInfiniteAutocomplete
      label={label}
      error={error}
      wrapperProps={wrapperProps}
      textFieldProps={{ required: true, ...textFieldProps }}
      autocompleteProps={{
        renderOption: renderUnitOption,
        ...autocompleteProps,
      }}
      useQueryFn={useQueryFetchUnits}
      useQueryFnProps={useQueryFnProps}
      getOptionLabel={getUnitOptionLabel}
      isOptionEqualToValue={isUnitOptionEqualToValue}
      value={value}
      handleChange={handleChange}
    />
  );
}
