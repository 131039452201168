import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  USER_STATUS_ACTIVATED,
  USER_STATUS_DEACTIVATED,
  USER_STATUS_MAPPER,
  USER_STATUS_PENDING,
  USER_STATUS_PROPS_MAPPER,
} from "@constants/users/users";

export function getStatus({ is_active, activation_date }) {
  return Boolean(activation_date)
    ? is_active
      ? USER_STATUS_ACTIVATED
      : USER_STATUS_DEACTIVATED
    : USER_STATUS_PENDING;
}

export default function UserManagementUserStatus({
  is_active,
  activation_date,
}) {
  const status = getStatus({ activation_date, is_active });
  const { icon = null, ...props } = USER_STATUS_PROPS_MAPPER[status];

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      {icon}
      <Typography variant="body2" {...props}>
        {USER_STATUS_MAPPER[status]}
      </Typography>
    </Stack>
  );
}
