import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomButton from "@components/Custom/CustomButton";
import {
  emptyClaimSubmissionFormData,
  formatClaimSubmissionFormData,
} from "@constants/claims/claim-submissions";
import { getClaimSubmissionHref } from "@constants/static/routing";
import { CLAIM_MODE_SUBMISSION_MAPPER } from "@constants/static/system-modes";
import useFetchOrganization from "@helpers/hooks/useFetchObjects/useFetchOrganization";
import { useOrganizationAgreementIsUnderMaintenance } from "@helpers/hooks/useFetchObjects/useFetchOrganizationAgreement";
import { createClaimSubmission } from "@redux/actions/claims/claim-submissions";
import { selectorAuthClaimMode } from "@redux/selectors/auth";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ClaimSubmissionFormData from "./ClaimSubmissionFormData";

export default function ClaimSubmissionCreateForm() {
  const navigate = useNavigate();
  const claimMode = useSelector(selectorAuthClaimMode);
  const label = `New ${CLAIM_MODE_SUBMISSION_MAPPER[claimMode]?.toLowerCase()}`;
  const { data: processor_organization, organization__slug } =
    useFetchOrganization();
  const { isAgreementDisabled, isLoading } =
    useOrganizationAgreementIsUnderMaintenance();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(emptyClaimSubmissionFormData);

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    const formattedFormData = formatClaimSubmissionFormData({
      formData,
      processor_organization,
    });
    createClaimSubmission(formattedFormData)
      .then(({ payload }) => {
        setIsProcessing(false);
        setIsOpen(false);
        navigate(
          getClaimSubmissionHref({
            organization__slug,
            claim_submission__id: payload.id,
          })
        );
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        enqueueSnackbar({ message: payload, variant: "error" });
      });
  };

  React.useEffect(() => {
    isOpen && setFormData(emptyClaimSubmissionFormData);
  }, [isOpen]);

  if (isAgreementDisabled || isLoading)
    return (
      <CustomButton
        variant="contained"
        icon="PlusIcon"
        fullWidth
        label={label}
        disabled={true}
      />
    );

  return (
    <>
      <CustomButton
        variant="contained"
        icon="PlusIcon"
        onClick={handleIsOpen}
        fullWidth
        label={label}
      />
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title: label }}
      >
        <ClaimSubmissionFormData
          formData={formData}
          setFormData={setFormData}
        />
      </CustomActionDialog>
    </>
  );
}
