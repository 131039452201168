import React from "react";
import CustomBox from "./CustomBox";

export default function CustomRotatingCircle({
  speed = 10_000,
  isClockwise = true,
  style = {},
  variant = "1",
  ...otherProps
}) {
  return (
    <CustomBox
      className="rotate"
      position="absolute"
      component="img"
      src={`/assets/svg/circle-${variant}.svg`}
      style={{
        "--rotation-speed": `${speed}ms`,
        "--rotation-direction": isClockwise ? "360deg" : "-360deg",
        ...style,
      }}
      {...otherProps}
    />
  );
}
