import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import { formatClaimSubmissionFormData } from "@constants/claims/claim-submissions";
import { CLAIM_MODE_SUBMISSION_MAPPER } from "@constants/static/system-modes";
import useFetchOrganization from "@helpers/hooks/useFetchObjects/useFetchOrganization";
import MenuItem from "@mui/material/MenuItem";
import {
  updateClaimSubmission,
  updateClaimSubmissionObjectTSQ,
} from "@redux/actions/claims/claim-submissions";
import { selectorAuthClaimMode } from "@redux/selectors/auth";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import ClaimSubmissionFormData from "./ClaimSubmissionFormData";

export default function ClaimSubmissionUpdateForm({ object }) {
  const claimMode = useSelector(selectorAuthClaimMode);
  const label = `Update ${CLAIM_MODE_SUBMISSION_MAPPER[
    claimMode
  ]?.toLowerCase()}`;
  const { data: processor_organization } = useFetchOrganization();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(object);

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    const formattedFormData = formatClaimSubmissionFormData({
      formData,
      processor_organization,
    });
    updateClaimSubmission(formattedFormData)
      .then(({ payload }) => {
        setIsProcessing(false);
        setIsOpen(false);
        updateClaimSubmissionObjectTSQ({ object: payload });
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        enqueueSnackbar({ message: payload, variant: "error" });
      });
  };

  React.useEffect(() => {
    isOpen && setFormData(object);
  }, [isOpen, object]);

  return (
    <>
      <MenuItem onClick={handleIsOpen}>{label}</MenuItem>
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title: label }}
      >
        <ClaimSubmissionFormData
          formData={formData}
          setFormData={setFormData}
        />
      </CustomActionDialog>
    </>
  );
}
