import React from "react";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import { MODEL_TYPE_ORGANIZATION_BUNDLE } from "@constants/static/globals";
import { getOrganizationBundlesUploadsHref } from "@constants/static/routing";
import { useParams } from "react-router-dom";
import OrganizationBundleCreateForm from "../OrganizationBundle/OrganizationBundleForms/OrganizationBundleCreateForm";

export default function OrganizationBundlesCreateForms({ bundle_type }) {
  const { organization__slug } = useParams();
  const uploadHistoryHref = getOrganizationBundlesUploadsHref({
    bundle_type,
    organization__slug,
  });

  return (
    <CustomAddOrUploadWrapper
      label="Add codes"
      uploadHistoryHref={uploadHistoryHref}
      model_type={MODEL_TYPE_ORGANIZATION_BUNDLE}
      bundle_type={bundle_type}
    >
      <OrganizationBundleCreateForm bundle_type={bundle_type} />
    </CustomAddOrUploadWrapper>
  );
}
