import React from "react";
import Typography from "@mui/material/Typography";

export default function CustomTypographyButton({
  value = "",
  variant = "body2",
  fontWeight = "bold",
  isUnderlined = true,
  ...otherProps
}) {
  const className = isUnderlined
    ? "underlined button opacityOnHover-50"
    : "button opacityOnHover-50";
  return (
    <Typography
      variant={variant}
      fontWeight={fontWeight}
      className={className}
      maxWidth="max-content"
      {...otherProps}
    >
      {value}
    </Typography>
  );
}
