import React from "react";
import CustomSection from "@components/Custom/CustomSection";
import { ORGANIZATION_POLICY_COVERAGE_VISIT_MAPPER } from "@constants/organizations/organization-policies";
import Grid from "@mui/material/Grid";
import OrganizationPolicyCoverageFormData from "./OrganizationPolicyCoverageFormData";

export default function OrganizationPolicyCoveragesFormData({
  formData = [],
  handleChange = () => {},
  gridItemProps = { xs: 12, lg: 6 },
}) {
  const handleUpdate = ({ payload, index }) => {
    const newFormData = [...formData];
    newFormData[index] = {
      ...newFormData[index],
      ...payload,
    };
    handleChange(newFormData);
  };
  return (
    <div>
      <Grid container spacing={3}>
        {formData.map((object, index) => (
          <Grid item key={index} {...gridItemProps}>
            <CustomSection
              isCollapsable={false}
              title={
                ORGANIZATION_POLICY_COVERAGE_VISIT_MAPPER[object?.visit_type]
              }
            >
              <OrganizationPolicyCoverageFormData
                formData={object}
                handleChange={(payload) => handleUpdate({ payload, index })}
              />
            </CustomSection>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
