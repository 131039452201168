import React from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { useQueryFetchSubscriptionPlans } from "@redux/actions/payments/payments";
import { selectorAuthBillingConversion } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import SubscriptionPlanOption from "./SubscriptionPlanOption";

export default function OrganizationPaymentCycleSubscriptionFormData({
  subscription_plan,
  handleChange = () => {},
}) {
  const billing_conversion = useSelector(selectorAuthBillingConversion);
  const { isLoading, data } = useQueryFetchSubscriptionPlans({});
  const isChecked = (subscriptionPlanId) =>
    subscriptionPlanId === subscription_plan?.id;

  if (isLoading) return <Skeleton variant="rectangular" height={114} />;

  if (!Boolean(data) || data?.length === 0) return null;

  return (
    <div>
      <Grid container spacing={1}>
        {data?.map((subscription_plan, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <SubscriptionPlanOption
              subscription_plan={subscription_plan}
              billing_conversion={billing_conversion}
              isChecked={isChecked}
              handleChange={handleChange}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
