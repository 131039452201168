import React from "react";
import CustomTooltip from "@components/Custom/CustomTooltip";
import { LockIcon } from "@rimads/assets";
import { AVEY_BLACK } from "@styles/theme";

export default function ClaimLockedTag({
  time_locked = null,
  withTooltip = true,
}) {
  if (!time_locked) return null;

  if (!withTooltip)
    return <LockIcon dimension={12} primary_fill={AVEY_BLACK} />;

  return (
    <CustomTooltip
      placement="right"
      title={
        <>
          <b>{"This claim is locked"}</b>
          <br />
          {"No more changes can be made to this claim."}
        </>
      }
    >
      <div style={{ display: "grid" }}>
        <LockIcon dimension={16} primary_fill={AVEY_BLACK} />
      </div>
    </CustomTooltip>
  );
}
