import React from "react";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import { MODEL_TYPE_ORGANIZATION_POLICY } from "@constants/static/globals";
import { getOrganizationPolicyUploadsHref } from "@constants/static/routing";
import { useParams } from "react-router-dom";
import OrganizationPolicyCreateForm from "../OrganizationPolicy/OrganizationPolicyForms/OrganizationPolicyCreateForm";

export default function OrganizationPoliciesCreateForms() {
  const { organization__slug } = useParams();
  const uploadHistoryHref = getOrganizationPolicyUploadsHref({
    organization__slug,
  });

  return (
    <CustomAddOrUploadWrapper
      label="Add policies"
      uploadHistoryHref={uploadHistoryHref}
      model_type={MODEL_TYPE_ORGANIZATION_POLICY}
    >
      <OrganizationPolicyCreateForm />
    </CustomAddOrUploadWrapper>
  );
}
