import React from "react";
import CustomSummaryCards from "@components/Custom/CustomSummaryCards";
import { getIntOrFloat } from "@helpers/parse/numbers";

export default function ClaimSubmissionClaimsSummary({ claims_summary }) {
  const {
    num_claims = 0,
    progress = 0,
    total_amount_approved = 0,
    total_amount_requested = 0,
    total_amount_rejected = 0,
    currency = "",
  } = claims_summary;

  const summaries = [
    {
      title: "Total claims",
      primary: getIntOrFloat(num_claims),
    },
    {
      title: "Progress",
      primary: getIntOrFloat(progress),
      secondary: "%",
    },
    {
      title: "Amount approved",
      primary: getIntOrFloat(total_amount_approved),
      secondary: `/ ${getIntOrFloat(total_amount_requested)} ${currency}`,
    },
    {
      title: "Amount rejected",
      primary: getIntOrFloat(total_amount_rejected),
      secondary: currency,
    },
  ];
  return <CustomSummaryCards summaries={summaries} />;
}
