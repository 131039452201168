import React from "react";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import { useQueryFetchOrganizationPaymentCycleHistory } from "@redux/actions/payments/organization-payment-cycles";
import { useParams } from "react-router-dom";
import OrganizationPaymentCyclePaymentPlanType from "../../OrganizationWallet/OrganizationPaymentCycle/OrganizationPaymentCyclePaymentPlanType";
import OrganizationPaymentCycleBody from "./OrganizationPaymentCycleBody";
import { OrganizationPaymentCycleHeaderTitle } from "./OrganizationPaymentCycleHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { payment_plan_type } = object;
  return (
    <OrganizationPaymentCyclePaymentPlanType
      payment_plan_type={payment_plan_type}
      withTooltip={false}
    />
  );
};

export default function OrganizationPaymentCycleVersionHistory() {
  const { organization_payment_cycle__id: id } = useParams();
  const useQueryFnProps = { id };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchOrganizationPaymentCycleHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={OrganizationPaymentCycleBody}
      VersionHistoryObjectTitleComponent={OrganizationPaymentCycleHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
