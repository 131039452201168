import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import { capitalizeFirstLetter } from "@helpers/parse/string";
import MenuItem from "@mui/material/MenuItem";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { v4 as uuidv4 } from "uuid";

export default function ClaimRawJson({ raw_json = [] }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const getRowId = () => uuidv4();

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const columns = React.useMemo(() => {
    if (raw_json?.length === 0) return [];
    return Object.keys(raw_json[0]).map((key) => ({
      field: key,
      headerName: capitalizeFirstLetter(key),
      width: 150,
    }));
  }, [raw_json]);

  if (raw_json?.length === 0) return null;

  return (
    <>
      <MenuItem onClick={handleIsOpen}>{"View original claim"}</MenuItem>
      <CustomActionDialog
        isOpen={isOpen}
        titleComponentProps={{ title: "Original claim" }}
        handleClose={handleIsOpen}
        maxWidth="md"
        displayActions={false}
        disableEscapeKeyDown={true}
        closeOnClickAway={true}
      >
        <DataGridPro
          className="stickyHeaders"
          rows={raw_json}
          columns={columns}
          getRowId={getRowId}
          disableRowSelectionOnClick
        />
      </CustomActionDialog>
    </>
  );
}
