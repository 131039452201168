import React from "react";
import CustomLoader from "@components/Custom/CustomLoader";
import CustomPillButton from "@components/Custom/CustomPillButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  CLAIM_DEMO_PLAN_SUMMARY_TITLE,
  selectorClaimDemoMedications,
  selectorClaimDemoProcedures,
  selectorClaimDemoPlanSummary,
  useClaimDemoContext,
} from "../context";
import ClaimDemoSummarySharedContent from "./ClaimDemoSummarySharedContent";

export default function ClaimDemoPlanSummary() {
  const { state, handleUpdateSummary, handleUpdatePlanSummary } =
    useClaimDemoContext();
  const claim_medications = selectorClaimDemoMedications(state);
  const claim_procedures = selectorClaimDemoProcedures(state);
  const plan = selectorClaimDemoPlanSummary(state);
  const { isLoading } = plan;

  const hasContent =
    claim_procedures.length > 0 || claim_medications.length > 0;

  return (
    <Stack spacing={2}>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontWeight="bold">
          {CLAIM_DEMO_PLAN_SUMMARY_TITLE}
        </Typography>
        {isLoading ? (
          <CustomLoader label="Generating..." />
        ) : (
          hasContent && (
            <CustomPillButton
              label="Generate"
              onClick={handleUpdatePlanSummary}
            />
          )
        )}
      </Stack>
      <ClaimDemoSummarySharedContent
        name="plan"
        object={plan}
        handleUpdateSummary={handleUpdateSummary}
      />
    </Stack>
  );
}
