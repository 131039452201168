import React from "react";
import CustomSection from "@components/Custom/CustomSection";
import { CLAIM_SECTION_ORGANIZATION_PATIENT_TITLE } from "@constants/claims/claims";
import ClaimOrganizationPatientFormData from "../Claim/ClaimOrganizationPatient/ClaimOrganizationPatientFormData";
import {
  selectorClaimDemoOrganizationPatient,
  useClaimDemoContext,
} from "./context";

export default function ClaimDemoOrganizationPatient() {
  const { state, handleChangeOrganizationPatient } = useClaimDemoContext();
  const organization_patient = selectorClaimDemoOrganizationPatient(state);

  return (
    <CustomSection
      title={CLAIM_SECTION_ORGANIZATION_PATIENT_TITLE}
      isCollapsable={false}
    >
      <ClaimOrganizationPatientFormData
        formData={organization_patient}
        handleChangeOrganizationPatient={handleChangeOrganizationPatient}
      />
    </CustomSection>
  );
}
