import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";

function UseEventListener(eventName, handler, element = window) {
  const savedHandler = React.useRef();

  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event) => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

export default function CustomClickAwayListener({
  onClickAway,
  children,
  childrenWrapperProps,
}) {
  const handler = React.useCallback(
    (e) => e.key === "Escape" && onClickAway(),
    [onClickAway]
  );

  UseEventListener("keydown", handler);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div style={{ width: "100%" }}>{children}</div>
    </ClickAwayListener>
  );
}
