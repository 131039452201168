import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import {
  BILLING_MANAGEMENT_FULL_HREF,
  BILLING_MANAGEMENT_TITLE,
  HOME_HREF,
  HOME_TITLE,
} from "@constants/static/routing";
import BillingManagementCurrencyPicker from "./BillingManagementCurrencyPicker";

const hrefs = [
  {
    label: HOME_TITLE,
    href: HOME_HREF,
  },
  {
    label: BILLING_MANAGEMENT_TITLE,
    href: BILLING_MANAGEMENT_FULL_HREF,
  },
];

export default function BillingManagementHeader() {
  return (
    <CustomHeader
      titleComponentProps={{ title: BILLING_MANAGEMENT_TITLE }}
      hrefs={hrefs}
    >
      <BillingManagementCurrencyPicker />
    </CustomHeader>
  );
}
