import React from "react";
import { useQueryFetchUsers } from "@redux/actions/users/users";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import {
  getUserOptionLabel,
  isUserOptionEqualToValue,
  renderUserOption,
} from "./UserAutocompleteComponents";

export default function UserAutocomplete({
  value = null,
  handleChange = () => {},
  useQueryFnProps = {},
}) {
  return (
    <CustomInfiniteAutocomplete
      withSearchIcon={false}
      autocompleteProps={{
        disableClearable: true,
        style: { width: "100%" },
        renderOption: renderUserOption,
      }}
      getOptionLabel={getUserOptionLabel}
      isOptionEqualToValue={isUserOptionEqualToValue}
      value={value}
      handleChange={handleChange}
      useQueryFn={useQueryFetchUsers}
      useQueryFnProps={{
        ...useQueryFnProps,
        useInfiniteWrapper: true,
      }}
    />
  );
}
