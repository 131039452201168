import React from "react";

const getSortQuery = ({ sortModel = [] }) =>
  sortModel
    .map(({ sort, field }) => `${sort === "asc" ? "" : "-"}${field}`)
    .join(",");

export default function useSort({
  initialSortModel = [],
  useSortObject = undefined,
}) {
  const [sortModel, setSortModel] = React.useState(initialSortModel);
  const onSortModelChange = React.useCallback(
    (newSortModel) => setSortModel(newSortModel),
    []
  );
  const sortQuery = getSortQuery({ sortModel });

  if (Boolean(useSortObject)) return useSortObject;

  return { sortQuery, sortModel, onSortModelChange };
}
