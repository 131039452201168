import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  CLAIM_DEMO_RECOMMENDED_PROCEDURES_TITLE,
  selectorClaimDemoRecommendedProcedures,
  useClaimDemoContext,
} from "../context";
import ClaimDemoProcedure from "./ClaimDemoProcedure";

export default function ClaimDemoRecommendedProcedures() {
  const { state } = useClaimDemoContext();
  const recommendedProcedures = selectorClaimDemoRecommendedProcedures(state);
  const hasRecommendedProcedures = recommendedProcedures.length > 0;

  return (
    <Stack spacing={2}>
      <Typography fontWeight="bold">
        {CLAIM_DEMO_RECOMMENDED_PROCEDURES_TITLE}
      </Typography>
      {hasRecommendedProcedures ? (
        <div>
          {recommendedProcedures.map((claim_procedure, index) => (
            <ClaimDemoProcedure
              key={index}
              claim_procedure={claim_procedure}
              isEditable={false}
              withTypeAnimation={true}
            />
          ))}
        </div>
      ) : (
        <Typography
          variant="body2"
          color="textSecondary"
          display="grid"
          alignItems="center"
          minHeight={28}
        >
          {"No recommendations."}
        </Typography>
      )}
    </Stack>
  );
}
