import React from "react";
import {
  CustomDateRangePicker,
  CustomOutlinedTextField,
} from "@components/Custom/CustomOutlinedField";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";

export default function ClaimSubmissionFormData({
  formData,
  setFormData = () => {},
}) {
  const { name, start_date, end_date } = formData;

  const dateRange = [
    start_date ? dayjs(start_date) : null,
    end_date ? dayjs(end_date) : null,
  ];

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleChangeDateRange = (newValue) =>
    setFormData({
      ...formData,
      start_date: newValue[0],
      end_date: newValue[1],
    });

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomOutlinedTextField
            autoFocus
            required
            label="Name"
            name="name"
            value={name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomDateRangePicker
            value={dateRange}
            onChange={handleChangeDateRange}
          />
        </Grid>
      </Grid>
    </div>
  );
}
