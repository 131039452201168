import React from "react";
import IcdAutocomplete from "@components/Custom/CustomAutocomplete/IcdAutocomplete";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import { ICD_TYPE_10_CM } from "@constants/avey/icds";
import {
  CLAIM_ICD_CODE_ERROR_FIELDNAMES_DESCRIPTION,
  CLAIM_ICD_CODE_ERROR_FIELDNAMES_ICD,
} from "@constants/claims/claim-evaluation-results";
import Stack from "@mui/material/Stack";

export default function ClaimIcdFormData({
  label = "",
  formData = {},
  setFormData = () => {},
  errorFieldnames = {},
  icd_type = ICD_TYPE_10_CM,
}) {
  const { icd = {}, note = "", description } = formData;
  const {
    [CLAIM_ICD_CODE_ERROR_FIELDNAMES_ICD]: icd_error,
    [CLAIM_ICD_CODE_ERROR_FIELDNAMES_DESCRIPTION]: description_error,
  } = errorFieldnames;

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeIcd = (newValue) =>
    setFormData((prev) => ({ ...prev, icd: newValue }));

  return (
    <Stack spacing={2}>
      <IcdAutocomplete
        label={label}
        textFieldProps={{ autoFocus: true }}
        value={icd}
        handleChange={handleChangeIcd}
        error={icd_error}
        icd_type={icd_type}
      />
      <CustomOutlinedTextField
        label="Description"
        name="description"
        value={description || ""}
        onChange={handleChange}
        placeholder="Description"
        multiline
        minRows={3}
        error={description_error}
      />
      <CustomOutlinedTextField
        label="Note"
        name="note"
        value={note || ""}
        onChange={handleChange}
        placeholder="Additional attributes"
        multiline
        minRows={3}
      />
    </Stack>
  );
}
