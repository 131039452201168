import React from "react";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import { ORGANIZATION_BUNDLE_EXPORT_DRAWER_TITLE_MAPPER } from "@constants/static/routing";
import {
  exportOrganizationBundles,
  useQueryFetchOrganizationBundleExports,
} from "@redux/actions/organizations/organization-bundles";
import { useParams } from "react-router-dom";
import ConfigurationsBulkExportForm from "../../Configurations/ConfigurationsBulkExportForm";
import ConfigurationsBulkExportHistory from "../../Configurations/ConfigurationsBulkExportForm/ConfigurationsBulkExportHistory";
import InsuranceOrganizationActionDialogTitle from "../../Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import { describeFilters } from "./OrganizationBundlesFilters";

export default function OrganizationBundlesExports({
  useQueryFnProps,
  bundle_type,
}) {
  const { organization__slug: processor_organization__slug } = useParams();
  const title = ORGANIZATION_BUNDLE_EXPORT_DRAWER_TITLE_MAPPER[bundle_type];

  return (
    <CustomMoreActions
      icon="DownloadIcon"
      useRotateProps={{ useRotate: false }}
    >
      <ConfigurationsBulkExportForm
        label="codes"
        submitFn={exportOrganizationBundles}
        useQueryFnProps={useQueryFnProps}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        describeFilters={describeFilters}
      />
      <ConfigurationsBulkExportHistory
        drawerProps={{
          TitleComponent: InsuranceOrganizationActionDialogTitle,
          titleComponentProps: { title },
        }}
        useQueryFn={useQueryFetchOrganizationBundleExports}
        useQueryFnProps={{
          filters: { processor_organization__slug, bundle_type },
        }}
        describeFilters={describeFilters}
      />
    </CustomMoreActions>
  );
}
