import React from "react";
import {
  CustomOutlinedField,
  CustomOutlinedTextField,
} from "@components/Custom/CustomOutlinedField";
import {
  formatCardNumber,
  unformatCardNumber,
} from "@constants/organizations/organization-cards";
import Stack from "@mui/material/Stack";

export default function OrganizationCardTokenFormData({
  errors = {},
  formData = {},
  setFormData = () => {},
  setErrors = () => {},
}) {
  const { cardNumber, cardHolder, cardExpiryMonth, cardExpiryYear, cardCVC } =
    formData;
  const {
    cardNumber: cardNumberError,
    cardHolder: cardHolderError,
    cardExpiry: cardExpiryError,
    cardCVC: cardCVCError,
  } = errors;
  const formattedCardNumber = formatCardNumber(cardNumber);

  const handleChangeCardNumber = (e) => {
    const newCardNumber = unformatCardNumber(e.target.value);
    if (newCardNumber.length <= 16) {
      setFormData((prev) => ({ ...prev, cardNumber: newCardNumber }));
      setErrors((prev) => ({ ...prev, cardNumber: "" }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({
      ...prev,
      ...(["cardExpiryMonth", "cardExpiryYear"].includes(name)
        ? { cardExpiry: "" }
        : { [name]: "" }),
    }));
  };

  return (
    <>
      <CustomOutlinedTextField
        label="Card number"
        value={formattedCardNumber}
        onChange={handleChangeCardNumber}
        required
        helperText={cardNumberError}
        error={Boolean(cardNumberError)}
        placeholder="1234 5678 9123 4567"
      />
      <CustomOutlinedTextField
        label="Card name"
        name="cardHolder"
        value={cardHolder}
        onChange={handleChange}
        required
        helperText={cardHolderError}
        error={Boolean(cardHolderError)}
        placeholder="John Smith"
      />
      <div>
        <CustomOutlinedField
          label="Expiration date"
          childProps={{ className: "", px: 0 }}
          helperText={cardExpiryError}
          error={Boolean(cardExpiryError)}
        >
          <Stack spacing={2} direction="row" alignItems="center">
            <CustomOutlinedTextField
              name="cardExpiryMonth"
              value={cardExpiryMonth}
              onChange={handleChange}
              placeholder="MM"
              required
              error={Boolean(cardExpiryError)}
              inputProps={{ maxLength: 2 }}
            />
            <CustomOutlinedTextField
              name="cardExpiryYear"
              value={cardExpiryYear}
              onChange={handleChange}
              placeholder="YY"
              required
              error={Boolean(cardExpiryError)}
              inputProps={{ maxLength: 2 }}
            />
          </Stack>
        </CustomOutlinedField>
      </div>
      <CustomOutlinedTextField
        label="CVC"
        name="cardCVC"
        value={cardCVC}
        onChange={handleChange}
        placeholder="743"
        required
        helperText={cardCVCError}
        error={Boolean(cardCVCError)}
        inputProps={{ minLength: 3, maxLength: 4 }}
      />
    </>
  );
}
