import React from "react";
import {
  getClaimDiagnosesSectionId,
  getClaimMedicationSectionId,
} from "@constants/claims/claim-evaluation-results";
import { getIntOrFloat } from "@helpers/parse/numbers";
import Typography from "@mui/material/Typography";
import ClaimEvaluationResultLink from "../ClaimEvaluationResultLink";

const INTERNAL_CODE_KEY = "{internal_code}";
const INTERNAL_CODES_KEY = "{internal_codes}";
const ICD_CODES_KEY = "{icd_codes}";
const AMOUNT_KEY = "{amount}";
const AMOUNT_COMPUTED_KEY = "{amount_computed}";
const CURRENCY_KEY = "{currency}";
const ERROR_MESSAGE_KEY = "{error_message}";

export default function ClaimMedicationEvaluationResultContext({
  result = {},
  isCreateOrUpdateMode = false,
}) {
  const {
    claim_medications,
    error_message,
    currency,
    organization_bundle_internal_code,
    amount,
    amount_computed,
  } = result || {};

  return {
    [ERROR_MESSAGE_KEY]: (
      <Typography variant="body2">{error_message}</Typography>
    ),
    [AMOUNT_KEY]: (
      <Typography variant="body2" display="inline" fontWeight="bold">
        {getIntOrFloat(amount) || "-"}
      </Typography>
    ),
    [AMOUNT_COMPUTED_KEY]: (
      <Typography variant="body2" display="inline" fontWeight="bold">
        {getIntOrFloat(amount_computed) || "-"}
      </Typography>
    ),
    [CURRENCY_KEY]: (
      <Typography variant="caption" display="inline">
        {` ${currency || "-"}`}
      </Typography>
    ),
    [INTERNAL_CODE_KEY]: (
      <Typography variant="body2" display="inline" fontWeight="bold">
        {organization_bundle_internal_code}
      </Typography>
    ),
    [INTERNAL_CODES_KEY]: (
      <span>
        {claim_medications?.map((claim_medication, index) => {
          const { id, organization_bundle } = claim_medication || {};
          const { internal_code, description } = organization_bundle || {};
          const href = getClaimMedicationSectionId({
            isCreateOrUpdateMode,
            id,
          });
          const delimiter = index < claim_medications.length - 1 ? ", " : "";
          return (
            <React.Fragment key={id}>
              <ClaimEvaluationResultLink
                to={href}
                value={internal_code}
                title={description}
              />
              {delimiter}
            </React.Fragment>
          );
        })}
      </span>
    ),
    [ICD_CODES_KEY]: (
      <ClaimEvaluationResultLink
        to={getClaimDiagnosesSectionId({ isCreateOrUpdateMode })}
        value="ICD codes"
      />
    ),
  };
}
