import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import MenuItem from "@mui/material/MenuItem";
import {
  deleteClaim,
  updateClaimObjectTSQ,
} from "@redux/actions/claims/claims";
import { enqueueSnackbar } from "notistack";
import ClaimRedirectToLatestVersion from "../ClaimRedirectToLatestVersion";

export default function ClaimSubmitterDeleteClaimForm({ id }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [latestVersionId, setLatestVersionId] = React.useState(null);

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    deleteClaim(id)
      .then(({ payload }) => {
        setIsProcessing(false);
        setIsOpen(false);
        updateClaimObjectTSQ({ object: payload });
        enqueueSnackbar({
          message: "Claim successfully deleted",
          variant: "success",
        });
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        const { reason, latest_version_id } = payload;
        Boolean(latest_version_id)
          ? setLatestVersionId(latest_version_id)
          : enqueueSnackbar({ variant: "error", message: reason });
      });
  };

  return (
    <>
      <MenuItem onClick={handleIsOpen}>{"Delete claim"}</MenuItem>
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        titleComponentProps={{ title: "Delete this claim?" }}
        subtitleComponentProps={{ title: "This action can’t be undone." }}
        isBodyEmpty={true}
        noText="Keep claim"
        yesText="Delete claim"
        yesButtonProps={{ color: "secondary", variant: "contained" }}
      />
      <ClaimRedirectToLatestVersion latestVersionId={latestVersionId} />
    </>
  );
}
