import React from "react";
import HealthcareOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import CustomDeactivateObject from "@components/Custom/CustomDeactivateObject";
import {
  deleteMedication,
  invalidateMedicationsTSQ,
  updateMedicationObjectTSQ,
} from "@redux/actions/avey/medications";

export default function MedicationActivateForm({ object }) {
  const handleDeleteFn = ({ payload }) => {
    invalidateMedicationsTSQ();
    updateMedicationObjectTSQ({ object: payload });
  };
  return (
    <CustomDeactivateObject
      object={object}
      label="medication"
      deleteFn={deleteMedication}
      handleDeleteFn={handleDeleteFn}
      TitleComponent={HealthcareOrganizationActionDialogTitle}
    />
  );
}
