import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomDeactivateObject from "@components/Custom/CustomDeactivateObject";
import {
  deleteOrganizationPolicy,
  invalidateOrganizationPoliciesTSQ,
  updateOrganizationPolicyObjectTSQ,
} from "@redux/actions/organizations/organization-policies";

export default function OrganizationPolicyActivateForm({ object }) {
  const handleDeleteFn = ({ payload }) => {
    invalidateOrganizationPoliciesTSQ();
    updateOrganizationPolicyObjectTSQ({ object: payload });
  };

  return (
    <CustomDeactivateObject
      object={object}
      label="policy"
      deleteFn={deleteOrganizationPolicy}
      handleDeleteFn={handleDeleteFn}
      TitleComponent={InsuranceOrganizationActionDialogTitle}
    />
  );
}
