import React from "react";
import CustomSection, {
  getCustomSectionBodyPadding,
} from "@components/Custom/CustomSection";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import OrganizationBundleRelatedObjectData from "./OrganizationBundleRelatedObjectData";

export default function OrganizationBundleCptsData({
  organization_bundle_cpts = [],
  isMinified = false,
}) {
  const p = getCustomSectionBodyPadding(isMinified);
  if (organization_bundle_cpts.length === 0) return null;

  return (
    <CustomSection
      title="Procedures"
      bodyProps={{ p: 0 }}
      isMinified={isMinified}
    >
      {organization_bundle_cpts.map(({ frequency, cpt }, index) => (
        <div key={index}>
          {index !== 0 && <Divider />}
          <Box p={p}>
            <OrganizationBundleRelatedObjectData
              label="CPT code"
              frequency={frequency}
              title={cpt.code}
              description={cpt.description}
            />
          </Box>
        </div>
      ))}
    </CustomSection>
  );
}
