import React from "react";
import CustomSection, {
  customSectionBodyPadding,
} from "@components/Custom/CustomSection";
import {
  CLAIM_SECTION_RESULTS_MAPPER,
  CLAIM_SECTION_VISIT_DETAILS,
  CLAIM_SECTION_VISIT_DETAILS_TITLE,
} from "@constants/claims/claims";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ClaimEvaluationResults from "../Claim/ClaimEvaluationResults";
import { useClaimContext } from "../Claim/context";
import ClaimVisitData from "./ClaimVisitData";
import ClaimVisitFormData from "./ClaimVisitFormData";

const section = CLAIM_SECTION_VISIT_DETAILS;
const title = CLAIM_SECTION_VISIT_DETAILS_TITLE;

export default function ClaimVisit() {
  const { isCreateOrUpdateMode = false, claim } = useClaimContext();
  const results = claim?.[CLAIM_SECTION_RESULTS_MAPPER[section]] || [];
  const hasResults = results.length > 0;

  return (
    <CustomSection
      title={title}
      isCollapsable={!isCreateOrUpdateMode}
      bodyProps={{ p: 0 }}
    >
      <Box p={customSectionBodyPadding}>
        {isCreateOrUpdateMode ? (
          <ClaimVisitFormData />
        ) : (
          <ClaimVisitData
            object={claim}
            useCustomSection={false}
            useErrorFieldnames={true}
          />
        )}
      </Box>
      {hasResults && (
        <>
          <Divider />
          <Box p={customSectionBodyPadding}>
            <ClaimEvaluationResults
              results={results}
              isCreateOrUpdateMode={isCreateOrUpdateMode}
              type={section}
            />
          </Box>
        </>
      )}
    </CustomSection>
  );
}
