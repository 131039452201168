import React from "react";
import CustomSection, {
  customSectionBodyPadding,
} from "@components/Custom/CustomSection";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ClaimDemoMedications from "../ClaimDemoMedications";
import ClaimDemoProcedures from "../ClaimDemoProcedures";
import ClaimDemoPlanSummary from "../ClaimDemoSummaries/ClaimDemoPlanSummary";
import {
  CLAIM_DEMO_PLAN_TITLE,
  selectorClaimDemoHasOrganizationPatient,
  selectorClaimDemoIsSessionDone,
  useClaimDemoContext,
} from "../context";
import ClaimDemoPlanTags from "./ClaimDemoPlanTags";

export default function ClaimDemoPlan() {
  const { state } = useClaimDemoContext();
  const isSessionDone = selectorClaimDemoIsSessionDone(state);
  const hasOrganizationPatient = selectorClaimDemoHasOrganizationPatient(state);

  return (
    <CustomSection
      title={CLAIM_DEMO_PLAN_TITLE}
      isOpenDefault={hasOrganizationPatient && isSessionDone}
      isCollapsable={false}
    >
      <div>
        <ClaimDemoPlanTags />
        <ClaimDemoProcedures />
        <Box py={customSectionBodyPadding}>
          <Divider />
        </Box>
        <ClaimDemoMedications />
        <Box py={customSectionBodyPadding}>
          <Divider />
        </Box>
        <ClaimDemoPlanSummary />
      </div>
    </CustomSection>
  );
}
