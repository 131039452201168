import React from "react";
import OrganizationBundleAutocomplete from "@components/Custom/CustomAutocomplete/OrganizationBundleAutocomplete";
import CustomButton from "@components/Custom/CustomButton";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import { ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES } from "@constants/organizations/organization-bundles";
import Stack from "@mui/material/Stack";
import { useClaimDemoContext } from "../context";

export default function ClaimDemoProceduresAddForm() {
  const { handleAddClaimProcedure } = useClaimDemoContext();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleIsOpen = () => setIsOpen(!isOpen);

  const handleAdd = React.useCallback(
    (newValue) => {
      handleAddClaimProcedure(newValue);
      setIsOpen(false);
    },
    [handleAddClaimProcedure]
  );

  if (isOpen)
    return (
      <Stack spacing={2} direction="row" alignItems="end">
        <OrganizationBundleAutocomplete
          label=""
          handleChange={handleAdd}
          textFieldProps={{ autoFocus: true }}
          wrapperProps={{ width: "100%" }}
          bundle_type={ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES}
        />
        <CustomButton
          icon="CloseIcon"
          variant="outlined"
          isSquare={true}
          useIconAsLabel={true}
          onClick={handleIsOpen}
        />
      </Stack>
    );

  return (
    <CustomTypographyButton
      value="+ Add procedure"
      onClick={handleIsOpen}
      style={{ minHeight: 40, display: "grid", alignItems: "center" }}
    />
  );
}
