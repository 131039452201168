import React from "react";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker, DateRangePicker } from "@mui/x-date-pickers-pro";
import { CloseIcon } from "@rimads/assets";
import dayjs from "dayjs";
import CustomBox from "./CustomBox";

const HelperText = ({ text, error }) =>
  Boolean(text) && (
    <Typography variant="caption" color={error ? "secondary" : "textSecondary"}>
      {text}
    </Typography>
  );

export const CustomOutlinedField = ({
  label = "",
  error = false,
  disabled = false,
  helperText = "",
  wrapperProps = {},
  children,
  childProps = {},
}) => {
  const outlinedClassName = error
    ? "outlined-error"
    : disabled
    ? "outlined-only greyBgF9"
    : "outlined";
  return (
    <Stack spacing={0.5} width="100%" {...wrapperProps}>
      <HelperText error={error} text={label} />
      <CustomBox
        px={1}
        className={`whiteBg borderRadius-8 ${outlinedClassName}`}
        flexGrow={1}
        minHeight={38}
        display="grid"
        alignItems="center"
        {...childProps}
      >
        {children}
      </CustomBox>
      <HelperText error={error} text={helperText} />
    </Stack>
  );
};

export const CustomOutlinedTextField = ({
  label = "",
  error = false,
  wrapperProps = {},
  options = [],
  disabled = false,
  ...otherProps
}) => {
  const outlinedClassName = error
    ? "outlined-error"
    : disabled
    ? "outlined-only greyBgF9"
    : "outlined";
  const {
    select = false,
    helperText = "",
    InputProps = {},
    ...otherTextFieldProps
  } = otherProps;
  const { style = {}, ...otherInputProps } = InputProps;
  return (
    <Stack spacing={0.5} {...wrapperProps}>
      <HelperText error={error} text={label} />
      <div className={`${outlinedClassName} borderRadius-8`}>
        <TextField
          fullWidth
          select={select}
          variant="standard"
          disabled={disabled}
          InputProps={{
            disableUnderline: true,
            style: { padding: select ? 0 : 8, minHeight: 38, ...style },
            ...otherInputProps,
          }}
          {...otherTextFieldProps}
        >
          {options.map(({ key, value }) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <HelperText error={error} text={helperText} />
    </Stack>
  );
};

export const CustomDatePicker = ({
  label = "Date",
  value,
  onChange = () => {},
  textFieldProps = {},
  wrapperProps = {},
  disabled = false,
  error = false,
}) => {
  return (
    <CustomOutlinedField
      label={label}
      disabled={disabled}
      error={error}
      {...wrapperProps}
    >
      <DatePicker
        disabled={disabled}
        format="DD/MM/YYYY"
        slotProps={{
          textField: {
            variant: "standard",
            fullWidth: true,
            InputLabelProps: { shrink: true, required: false },
            InputProps: { disableUnderline: true },
            ...textFieldProps,
          },
        }}
        value={value ? dayjs(value) : null}
        onChange={(newValue) => onChange(newValue)}
      />
    </CustomOutlinedField>
  );
};

export const CustomDateRangePicker = ({
  label = "Period",
  value = [null, null],
  onChange = () => {},
  wrapperProps = {},
  disabled = false,
}) => {
  const hasValue = value.map((x) => Boolean(x)).filter((x) => x).length > 0;
  const handleReset = () => onChange([null, null]);
  return (
    <CustomOutlinedField label={label} disabled={disabled} {...wrapperProps}>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        className={hasValue ? "hoverOpacityParent" : ""}
      >
        <DateRangePicker
          format="DD/MM/YYYY"
          calendars={2}
          value={value}
          slotProps={{
            textField: {
              variant: "standard",
              label: "",
              InputProps: { disableUnderline: true },
            },
          }}
          onChange={onChange}
        />
        <IconButton
          onClick={handleReset}
          size="small"
          className="opacity-0 hoverOpacityChild"
          disabled={!hasValue}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </CustomOutlinedField>
  );
};
