import React from "react";
import OrganizationClinicianData from "@components/Authenticated/Configurations/OrganizationClinicians/OrganizationClinician/OrganizationClinicianData";
import OrganizationPatientData from "@components/Authenticated/Configurations/OrganizationPatients/OrganizationPatient/OrganizationPatientData";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import CustomButton from "@components/Custom/CustomButton";
import CustomLottieAnimation from "@components/Custom/CustomLottieAnimation";
import { SYMPTOM_STATUS_PRESENT } from "@constants/avey-cowriter";
import {
  CLAIM_SECTION_DIAGNOSES,
  CLAIM_SECTION_SYMPTOMS,
  formatClaimFormData,
} from "@constants/claims/claims";
import { getClaimHref } from "@constants/static/routing";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { submitClaim } from "@redux/actions/claims/claims";
import {
  fetchDiseaseInstanceIcdCode,
  fetchIcdCodeConceptFinding,
} from "@redux/actions/server/server";
import { useParams } from "react-router-dom";
import {
  selectorClaimDemoClinicianDiagnoses,
  selectorClaimDemoMedications,
  selectorClaimDemoOrganizationClinician,
  selectorClaimDemoOrganizationPatient,
  selectorClaimDemoProcedures,
  selectorClaimDemoSymptoms,
  useClaimDemoContext,
} from "../context";
import ClaimDemoClaimIcdsReviewAndConfirm from "./ClaimDemoClaimIcdsReviewAndConfirm";
import ClaimDemoClaimMedicationsReviewAndConfirm from "./ClaimDemoClaimMedicationsReviewAndConfirm";
import ClaimDemoClaimProceduresReviewAndConfirm from "./ClaimDemoClaimProceduresReviewAndConfirm";
import { useURLSearch } from "@helpers/api/getQueryParams";

export default function ClaimDemoReviewAndConfirm() {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <>
      <CustomButton
        label="Generate claim"
        fullWidth
        color="success"
        onClick={handleIsOpen}
      />
      {isOpen && (
        <ClaimDemoReviewAndConfirmDialog handleIsOpen={handleIsOpen} />
      )}
    </>
  );
}

const ClaimDemoReviewAndConfirmDialog = ({ handleIsOpen = () => {} }) => {
  const { organization__slug, claim_submission__id } = useParams();
  const { state } = useClaimDemoContext();
  const urlSearch = useURLSearch();
  const organization_patient = selectorClaimDemoOrganizationPatient(state);
  const organization_clinician = selectorClaimDemoOrganizationClinician(state);
  const claim_medications = selectorClaimDemoMedications(state);
  const claim_symptoms = selectorClaimDemoSymptoms(state);
  const claim_diagnoses = selectorClaimDemoClinicianDiagnoses(state);
  const claim_procedures = selectorClaimDemoProcedures(state);

  const [isProcessing, setIsProcessing] = React.useState(false);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const [symptomsFormData, setSymptomsFormData] = React.useState([]);
  const [assessmentFormData, setAssessmentFormData] = React.useState([]);
  const [claimHref, setClaimHref] = React.useState(null);

  const { isOpen } = alertState;
  const hasClaimHref = Boolean(claimHref);
  const isOpenMainDialog = !hasClaimHref && !isOpen;

  const handleCloseAlert = (e) => {
    e?.preventDefault();
    setAlertState((prev) => ({ ...prev, isOpen: false }));
  };

  const handleCloseClaimHref = () => {
    setClaimHref(null);
    handleIsOpen();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const formData = {
      organization_patient,
      organization_clinician,
      claim_procedures,
      claim_medications,
      claim_symptoms: symptomsFormData
        .filter(
          ({ icd__code, status }) =>
            Boolean(icd__code) && status === SYMPTOM_STATUS_PRESENT
        )
        .map(({ icd__code }) => ({ icd__code })),
      claim_diagnoses: assessmentFormData.map(({ icd__code }) => ({
        icd__code,
      })),
    };
    const formattedFormData = formatClaimFormData({
      formData,
      claim_submission__id,
      processor_organization__slug: organization__slug,
      is_demo: true,
    });

    submitClaim(formattedFormData)
      .then(({ payload }) =>
        setClaimHref(
          getClaimHref({
            organization__slug,
            claim_submission__id,
            claim__id: payload.id,
            urlSearch,
          })
        )
      )
      .catch(({ payload }) =>
        setAlertState({ isOpen: true, errorStr: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  React.useEffect(() => {
    setSymptomsFormData(
      claim_symptoms.map((object) => ({ ...object, isLoading: true }))
    );
    Promise.all(
      claim_symptoms.map((object) =>
        fetchIcdCodeConceptFinding({ id: object.id })
          .then(({ payload }) => ({
            ...object,
            icd__code: payload.icd__code,
            isLoading: false,
          }))
          .catch(() => ({
            ...object,
            icd__code: null,
            isLoading: false,
          }))
      )
    )
      .then((payload) => setSymptomsFormData(payload))
      .catch(() =>
        setSymptomsFormData(
          claim_symptoms.map((object) => ({ ...object, isLoading: false }))
        )
      );
  }, [claim_symptoms]);

  React.useEffect(() => {
    setAssessmentFormData(
      claim_diagnoses.map((object) => ({ ...object, isLoading: true }))
    );
    Promise.all(
      claim_diagnoses.map((object) =>
        fetchDiseaseInstanceIcdCode({ id: object.id })
          .then(({ payload }) => ({
            ...object,
            icd__code: payload.icd__code,
            isLoading: false,
          }))
          .catch((x) => ({
            ...object,
            icd__code: null,
            isLoading: false,
          }))
      )
    )
      .then((payload) => setAssessmentFormData(payload))
      .catch(() =>
        setAssessmentFormData(
          claim_diagnoses.map((object) => ({ ...object, isLoading: false }))
        )
      );
  }, [claim_diagnoses]);

  return (
    <>
      <CustomActionDialog
        isOpen={isOpenMainDialog}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        titleComponentProps={{ title: "Review & confirm" }}
        isOk={true}
        okText="Generate claim"
        okButtonProps={{ color: "success" }}
      >
        <OrganizationPatientData
          object={organization_patient}
          useCustomSection={true}
          isMinified={true}
        />
        <OrganizationClinicianData
          object={organization_clinician}
          useCustomSection={true}
          isMinified={true}
        />
        <ClaimDemoClaimIcdsReviewAndConfirm
          objects={symptomsFormData}
          type={CLAIM_SECTION_SYMPTOMS}
        />
        <ClaimDemoClaimIcdsReviewAndConfirm
          objects={assessmentFormData}
          type={CLAIM_SECTION_DIAGNOSES}
        />
        <ClaimDemoClaimProceduresReviewAndConfirm
          claim_procedures={claim_procedures}
        />
        <ClaimDemoClaimMedicationsReviewAndConfirm
          claim_medications={claim_medications}
        />
      </CustomActionDialog>
      <CustomAlertDialog state={alertState} handleClose={handleCloseAlert} />
      <CustomActionDialog
        isOpen={hasClaimHref}
        handleClose={handleCloseClaimHref}
        displayActions={false}
        displayDialogContent={false}
      >
        <Stack spacing={10} pt={8} pb={4} px={5}>
          <Typography
            variant="h5"
            position="relative"
            textAlign="center"
            fontWeight="bold"
          >
            <CustomLottieAnimation loop={false} />
            {"Claim generated successfully!"}
          </Typography>
          <a
            target="_blank"
            rel="noreferrer"
            style={{ width: "100%" }}
            href={claimHref}
          >
            <CustomButton color="success" label="View claim" fullWidth />
          </a>
        </Stack>
      </CustomActionDialog>
    </>
  );
};
