import React from "react";
import Avatar from "@mui/material/Avatar";

export default function CustomAvatar({ src = "", placeholder = "", ...props }) {
  const { className = "", ...otherProps } = props || {};
  const defaultSrc =
    placeholder || "/assets/images/placeholders/no-picture.png";
  return (
    <Avatar
      className={className + " elevated-4"}
      src={src || defaultSrc}
      {...otherProps}
    />
  );
}
