import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import CustomValueWithUnit from "@components/Custom/CustomValueWithUnit";
import {
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_COPAYMENT,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_DISCOUNT,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_GROSS,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_REQUESTED,
} from "@constants/claims/claim-evaluation-results";
import { getIntOrFloat } from "@helpers/parse/numbers";

export default function SubclaimBillingDetailsData({
  object = {},
  errorFieldnames = {},
}) {
  const {
    currency,
    amount_gross,
    amount_discount,
    amount_copayment,
    amount_requested,
    amount_approved,
  } = object;

  const {
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_GROSS]: amount_gross_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_DISCOUNT]:
      amount_discount_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_COPAYMENT]:
      amount_copayment_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_REQUESTED]:
      amount_requested_error = false,
  } = errorFieldnames;

  const amountGross = getIntOrFloat(amount_gross);
  const amountDiscount = getIntOrFloat(amount_discount);
  const amountRequested = getIntOrFloat(amount_requested);
  const amountCopayment = getIntOrFloat(amount_copayment);
  const amountApproved = getIntOrFloat(amount_approved);

  const amounts = [
    {
      label: "Gross amount",
      value: amountGross,
      isErrorField: amount_gross_error,
    },
    {
      label: "Discount",
      value: amountDiscount,
      isErrorField: amount_discount_error,
    },
    {
      label: "Patient share",
      value: amountCopayment,
      isErrorField: amount_copayment_error,
    },
    {
      label: "Billed amount",
      value: amountRequested,
      isErrorField: amount_requested_error,
    },
    {
      label: "Approved amount",
      value: amountApproved,
      isPredicate: typeof amountApproved !== "undefined",
    },
  ].filter(({ isPredicate = true }) => isPredicate);

  return (
    <CustomListItemCollapse primary="Billing details">
      <CustomGrid>
        {amounts.map(({ label, value, isErrorField }) => (
          <CustomLabeledText
            key={label}
            label={label}
            useChildren={true}
            isErrorField={isErrorField}
          >
            <CustomValueWithUnit value={value || "-"} unit={currency} />
          </CustomLabeledText>
        ))}
      </CustomGrid>
    </CustomListItemCollapse>
  );
}
