import { AVEY_BLACK, AVEY_BLUE, AVEY_WHITE } from "@styles/theme";
import { getOptions } from "../../helpers/hooks/objects";
import { SITE_LOGO_BLUE, SITE_LOGO_WHITE } from "./globals";

export const CLAIM_MODE_AUDIT = "A";
export const CLAIM_MODE_SUBMIT = "S";

export const CLAIM_MODE_AVEY_LOGO_MAPPER = {
  [CLAIM_MODE_AUDIT]: SITE_LOGO_WHITE,
  [CLAIM_MODE_SUBMIT]: SITE_LOGO_BLUE,
};

export const CLAIM_MODE_BUTTON_COLOR_MAPPER = {
  [CLAIM_MODE_AUDIT]: "success",
  [CLAIM_MODE_SUBMIT]: "primary",
};

export const CLAIM_MODE_COLOR_MAPPER = {
  [CLAIM_MODE_AUDIT]: AVEY_BLUE,
  [CLAIM_MODE_SUBMIT]: AVEY_BLACK,
};

export const CLAIM_MODE_ACTIVE_INDICATOR_COLOR_MAPPER = {
  [CLAIM_MODE_AUDIT]: AVEY_WHITE,
  [CLAIM_MODE_SUBMIT]: AVEY_BLUE,
};

export const CLAIM_MODE_SUBMISSION_MAPPER = {
  [CLAIM_MODE_AUDIT]: "Audit",
  [CLAIM_MODE_SUBMIT]: "Submission",
};

export const CLAIM_MODE_MAPPER = {
  [CLAIM_MODE_AUDIT]: "Audit",
  [CLAIM_MODE_SUBMIT]: "Submit",
};

export const CLAIM_MODE_OPTIONS = getOptions(CLAIM_MODE_MAPPER);

export const CLAIM_MODE_DESCRIPTION_MAPPER = {
  [CLAIM_MODE_AUDIT]: (
    <>
      <b>{"Audit mode"}</b>
      <br />
      {"Verify claims for accuracy"}
    </>
  ),
  [CLAIM_MODE_SUBMIT]: (
    <>
      <b>{"Submit mode"}</b>
      <br />
      {"Send claims for processing"}
    </>
  ),
};
