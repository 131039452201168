import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import { getConfigurationsBulkUploadsHeader } from "@constants/static/routing";
import { useQueryFetchOrganization } from "@redux/actions/organizations/organizations";
import { useParams } from "react-router-dom";

export default function ConfigurationsBulkUploadsHeader({
  model_type,
  bundle_type,
}) {
  const { organization__slug } = useParams();
  const { data: organization } = useQueryFetchOrganization({
    organization__slug,
  });
  const { title, hrefs } = getConfigurationsBulkUploadsHeader({
    model_type,
    bundle_type,
    organization,
  });
  return <CustomHeader titleComponentProps={{ title }} hrefs={hrefs} />;
}
