import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import {
  CustomSummaryDialog,
  initialSummaryState,
} from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import {
  formatOrganizationBundleFormData,
  getEmptyOrganizationBundleFormData,
} from "@constants/organizations/organization-bundles";
import { getOrganizationBundlesHref } from "@constants/static/routing";
import MenuItem from "@mui/material/MenuItem";
import {
  createOrganizationBundle,
  invalidateOrganizationBundlesTSQ,
} from "@redux/actions/organizations/organization-bundles";
import { selectorAuthCurrentUserOrganizationCurrency } from "@redux/selectors/auth";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { OrganizationBundleDataReviewAndConfirm } from "../OrganizationBundleData";
import OrganizationBundleFormData from "../OrganizationBundleFormData";

export default function OrganizationBundleCreateForm({ bundle_type }) {
  const navigate = useNavigate();
  const { organization__slug: processor_organization__slug } = useParams();
  const currency = useSelector(selectorAuthCurrentUserOrganizationCurrency);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const initialFormData = React.useMemo(
    () => getEmptyOrganizationBundleFormData({ currency, bundle_type }),
    [currency, bundle_type]
  );
  const [formData, setFormData] = React.useState(initialFormData);
  const [summaryState, setSummaryState] = React.useState(initialSummaryState);
  const { isOpen: isOpenSummary, summaries } = summaryState;
  const [alertState, setAlertState] = React.useState(initialAlertState);

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e, isCheck = true) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProcessing(true);
    const formattedFormData = formatOrganizationBundleFormData({
      isCheck,
      formData,
      bundle_type,
      processor_organization__slug,
    });
    createOrganizationBundle(formattedFormData)
      .then(({ payload }) => {
        const { instance, summaries, exceptions } = payload;
        if (Boolean(instance)) {
          setIsOpen(false);
          enqueueSnackbar({
            variant: "success",
            message: "Code successfully created",
          });
          invalidateOrganizationBundlesTSQ();
          navigate(
            getOrganizationBundlesHref({
              bundle_type,
              organization__slug: processor_organization__slug,
              organization_bundle__id: instance.id,
            })
          );
        } else if (exceptions?.length > 0) {
          setAlertState({ isOpen: true, errors: exceptions });
        } else {
          setSummaryState({ isOpen: true, summaries });
        }
      })
      .catch(({ payload }) => {
        setAlertState({ isOpen: true, errorStr: payload });
        setSummaryState((prev) => ({ ...prev, isOpen: false }));
      })
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitData = (e) => handleSubmit(e, false);
  const handleCheckData = (e) => handleSubmit(e, true);

  React.useEffect(() => {
    isOpen && setFormData(initialFormData);
  }, [isOpen, initialFormData]);

  return (
    <>
      <MenuItem onClick={handleIsOpen}>{"Add a code"}</MenuItem>
      <CustomActionDialog
        fullScreen={true}
        useCustomCloseIcon={true}
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleCheckData}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title: "New code", fullScreen: true }}
        isOk={true}
        okText="Save"
      >
        <OrganizationBundleFormData
          bundle_type={bundle_type}
          formData={formData}
          setFormData={setFormData}
        />
      </CustomActionDialog>
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
      <CustomSummaryDialog
        summaries={summaries}
        isOpen={isOpenSummary}
        isProcessing={isProcessing}
        setSummaryState={setSummaryState}
        handleSubmit={handleSubmitData}
        ContentComponent={OrganizationBundleDataReviewAndConfirm}
        contentComponentProps={{ object: formData }}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
      />
    </>
  );
}
