import { getRowValue } from "@helpers/parse/csv";
import { parseDob } from "@helpers/parse/time";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { getOptions } from "../../helpers/hooks/objects";

export const ORGANIZATION_PATIENT_GENDER_MALE = "M";
export const ORGANIZATION_PATIENT_GENDER_FEMALE = "F";

export const ORGANIZATION_PATIENT_GENDER_MAPPER = {
  [ORGANIZATION_PATIENT_GENDER_MALE]: "Male",
  [ORGANIZATION_PATIENT_GENDER_FEMALE]: "Female",
};

export const ORGANIZATION_PATIENT_GENDER_OPTIONS = getOptions(
  ORGANIZATION_PATIENT_GENDER_MAPPER
);

export const PHOENIX_GENDER_MALE = "male";
export const PHOENIX_GENDER_FEMALE = "female";
export const PHOENIX_GENDER_BOTH = "both";

export const PHOENIX_GENDER_MAPPER = {
  [ORGANIZATION_PATIENT_GENDER_MALE]: PHOENIX_GENDER_MALE,
  [ORGANIZATION_PATIENT_GENDER_FEMALE]: PHOENIX_GENDER_FEMALE,
};

export const ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT = "I";
export const ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT = "O";
export const ORGANIZATION_PATIENT_VISIT_TYPE_BOTH = "B";

export const ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT_SHORTHAND = "IP";
export const ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT_SHORTHAND = "OP";

export const ORGANIZATION_PATIENT_VISIT_TYPE_SHORTHAND_TEXT_MAPPER = {
  [ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT]:
    ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT_SHORTHAND,
  [ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT]:
    ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT_SHORTHAND,
  [ORGANIZATION_PATIENT_VISIT_TYPE_BOTH]: `${ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT_SHORTHAND} & ${ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT_SHORTHAND}`,
};

export const ORGANIZATION_PATIENT_VISIT_TYPE_TEXT_MAPPER = {
  [ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT]: "Inpatient",
  [ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT]: "Outpatient",
  [ORGANIZATION_PATIENT_VISIT_TYPE_BOTH]: "Inpatient & Outpatient",
};

export const ORGANIZATION_PATIENT_VISIT_TYPE_SHORTHAND_OPTIONS = getOptions(
  ORGANIZATION_PATIENT_VISIT_TYPE_SHORTHAND_TEXT_MAPPER
);

export const isInpatientVisitType = (visit_type) =>
  [
    ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT,
    ORGANIZATION_PATIENT_VISIT_TYPE_BOTH,
  ].includes(visit_type);

export const isOutpatientVisitType = (visit_type) =>
  [
    ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT,
    ORGANIZATION_PATIENT_VISIT_TYPE_BOTH,
  ].includes(visit_type);

export function getOrganizationPatientIdentitiesDescription(
  organization_patient_identities = []
) {
  return organization_patient_identities
    .map(({ identity_type, number }) => `${identity_type?.name}: ${number}`)
    .join(", ");
}

export const emptyOrganizationPatientIdentity = {
  identity_type: null,
  number: "",
};

export const emptyOrganizationPatientFormData = {
  first_name: "",
  last_name: "",
  localized_first_name: "",
  localized_last_name: "",
  gender: "",
  dob: "",
  patient_id: "",
  provider_file_number: "",
  organization_patient_identities: [],
  organization_policy: null,
};

export const formatOrganizationPatientFormData = ({
  isCheck,
  processor_organization__slug,
  formData,
}) => {
  const {
    id = undefined,
    first_name = "",
    last_name = "",
    localized_first_name = "",
    localized_last_name = "",
    gender = ORGANIZATION_PATIENT_GENDER_MALE,
    dob = "",
    patient_id = "",
    provider_file_number = "",
    organization_patient_identities = [],
    organization_policy,
  } = formData;

  return {
    id,
    is_check: isCheck,
    processor_organization__slug,
    first_name: first_name?.trim(),
    last_name: last_name?.trim(),
    localized_first_name: localized_first_name?.trim(),
    localized_last_name: localized_last_name?.trim(),
    provider_file_number: provider_file_number?.trim(),
    gender: [
      ORGANIZATION_PATIENT_GENDER_MALE,
      ORGANIZATION_PATIENT_GENDER_FEMALE,
    ].includes(gender)
      ? gender
      : null,
    dob: dob ? dayjs(dob).format("YYYY-MM-DD") : null,
    patient_id: patient_id?.trim(),
    organization_patient_identities,
    organization_policy,
  };
};

const organizationPatientRowToObject = ({ row }) => {
  const {
    first_name,
    last_name,
    localized_first_name,
    localized_last_name,
    gender,
    dob,
    patient_id,
    provider_file_number,
    organization_policy_number,
    identity_type__code,
    identity_number,
  } = getRowValue({
    row,
    columns: [
      { headerName: "FIRST NAME", fieldname: "first_name" },
      { headerName: "LAST NAME", fieldname: "last_name" },
      {
        headerName: "LOCALIZED FIRST NAME",
        fieldname: "localized_first_name",
      },
      { headerName: "LOCALIZED LAST NAME", fieldname: "localized_last_name" },
      {
        headerName: "GENDER",
        fieldname: "gender",
        valueGetter: (value) => (value || "")?.toUpperCase(),
      },
      {
        headerName: "DATE OF BIRTH",
        fieldname: "dob",
        valueGetter: (value) =>
          value ? dayjs(value).format("YYYY-MM-DD") : null,
      },
      { headerName: "MEMBER ID", fieldname: "patient_id" },
      {
        headerName: "PROVIDER FILE NUMBER",
        fieldname: "provider_file_number",
      },
      {
        headerName: "POLICY NUMBER",
        fieldname: "organization_policy_number",
      },
      { headerName: "IDENTITY TYPE", fieldname: "identity_type__code" },
      { headerName: "IDENTITY NUMBER", fieldname: "identity_number" },
    ],
  });

  let organization_patient_identity = null;

  if (Boolean(identity_type__code) || Boolean(identity_number)) {
    organization_patient_identity = {
      identity_type: { code: identity_type__code },
      number: identity_number,
    };
  }

  const isRowEmpty = !(
    first_name ||
    last_name ||
    localized_first_name ||
    localized_last_name ||
    gender ||
    dob ||
    patient_id ||
    provider_file_number ||
    organization_policy_number ||
    identity_type__code ||
    identity_number
  );
  return {
    id: isRowEmpty ? null : uuidv4(),
    first_name,
    last_name,
    localized_first_name,
    localized_last_name,
    gender,
    dob,
    patient_id,
    provider_file_number,
    organization_policy: {
      number: organization_policy_number,
    },
    organization_patient_identity,
  };
};

export const organizationPatientRowsToObjects = (rows) => {
  const finalDict = {};
  rows
    .map((row) => organizationPatientRowToObject({ row }))
    .filter(({ id }) => Boolean(id))
    .forEach(
      ({
        id,
        first_name,
        last_name,
        localized_first_name,
        localized_last_name,
        gender,
        dob,
        patient_id,
        provider_file_number,
        organization_policy,
        organization_patient_identity,
      }) => {
        const prevInstance = {
          ...(finalDict[patient_id] || emptyOrganizationPatientFormData),
        };
        const newInstance = {
          id,
          first_name: first_name || prevInstance.first_name,
          last_name: last_name || prevInstance.last_name,
          localized_first_name:
            localized_first_name || prevInstance.localized_first_name,
          localized_last_name:
            localized_last_name || prevInstance.localized_last_name,
          gender: gender || prevInstance.gender,
          dob: dob || prevInstance.dob,
          patient_id: patient_id || prevInstance.patient_id,
          provider_file_number:
            provider_file_number || prevInstance.provider_file_number,
          organization_policy:
            organization_policy || prevInstance.organization_policy,
        };
        const object = { ...prevInstance, ...newInstance };
        if (Boolean(organization_patient_identity)) {
          finalDict[patient_id] = {
            ...object,
            organization_patient_identities:
              object.organization_patient_identities.concat(
                organization_patient_identity
              ),
          };
        } else {
          finalDict[patient_id] = object;
        }
      }
    );

  return Object.values(finalDict);
};

export const parseOrganizationPatient = (organization_patient) => {
  if (Boolean(organization_patient)) {
    const {
      patient_id = "",
      first_name,
      last_name,
      dob,
      gender,
    } = organization_patient;
    const full_name = `${first_name} ${last_name}`.trim();
    const { ageStr, ageInt } = dob
      ? parseDob(dob)
      : { ageStr: "Unknown Age", ageInt: 25 };
    const genderStr = ORGANIZATION_PATIENT_GENDER_MAPPER[gender];
    const phoenixGender = PHOENIX_GENDER_MAPPER[gender];
    return { patient_id, full_name, ageInt, ageStr, genderStr, phoenixGender };
  }
  return {
    patient_id: "",
    full_name: "",
    ageInt: 25,
    ageStr: "Unknown Age",
    genderStr:
      ORGANIZATION_PATIENT_GENDER_MAPPER[ORGANIZATION_PATIENT_GENDER_MALE],
    phoenixGender: PHOENIX_GENDER_MALE,
  };
};
