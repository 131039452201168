import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { TypeAnimation } from "react-type-animation";
import { CloseIcon, RadioButtonSelectedIcon } from "@rimads/assets";
import { AVEY_BLACK } from "@styles/theme";

export default function ClaimDemoProcedure({
  claim_procedure,
  handleRemove = () => {},
  isEditable = true,
  withTypeAnimation = false,
}) {
  const { organization_bundle } = claim_procedure;
  const { organization_bundle_cpts = [] } = organization_bundle || {};
  const { cpt } = organization_bundle_cpts[0] || {};
  const { description } = cpt;

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      className="hoverOpacityParent"
      minHeight={28}
    >
      <div
        style={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          minWidth: 24,
        }}
      >
        <RadioButtonSelectedIcon dimension={7} primary_fill={AVEY_BLACK} />
      </div>
      <div style={{ flexGrow: 1 }}>
        <Typography variant="body2" className="ellipsis-1" title={description}>
          {withTypeAnimation ? (
            <TypeAnimation
              key={description}
              sequence={[description]}
              wrapper="span"
              speed={50}
              repeat={false}
              cursor={false}
            />
          ) : (
            description
          )}
        </Typography>
      </div>
      {isEditable && (
        <IconButton
          className="opacity-0 hoverOpacityChild"
          onClick={handleRemove}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
}
