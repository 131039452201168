import React from "react";
import pluralize from "pluralize";
import OrganizationAgreementUpdateForm from "../OrganizationAgreementUpdateForm";
import OrganizationAgreementFollowupDiscountFormData from "./OrganizationAgreementFollowupDiscountFormData";
import { getIntOrFloat } from "@helpers/parse/numbers";

export default function OrganizationAgreementFollowupDiscountForm({ object }) {
  const formatFormData = (formData) => {
    const { id, followup_consultation_num_days } = formData;
    return {
      id,
      followup_consultation_num_days: followup_consultation_num_days || 0,
    };
  };
  const getSubtitle = ({ name }) =>
    `Set the follow-up discount duration as agreed upon with ${name}`;

  const { followup_consultation_num_days } = object || {};

  return (
    <OrganizationAgreementUpdateForm
      object={object}
      formatFormData={formatFormData}
      successMessage="Follow-up discount successfully updated"
      title="Follow-up discount"
      getSubtitle={getSubtitle}
      FormDataComponent={OrganizationAgreementFollowupDiscountFormData}
      summaryCardProps={{
        primary: getIntOrFloat(followup_consultation_num_days) || 0,
        secondary: pluralize("day", followup_consultation_num_days || 0),
      }}
    />
  );
}
