import React from "react";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import { getOrganizationPoliciesHref } from "@constants/static/routing";
import { useQueryFetchOrganizationPolicy } from "@redux/actions/organizations/organization-policies";
import { useQueryFetchOrganization } from "@redux/actions/organizations/organizations";
import { Navigate, useParams } from "react-router-dom";
import OrganizationPolicyData from "../OrganizationPolicyData";
import OrganizationPolicyHeader from "./OrganizationPolicyHeader";

export default function OrganizationPolicy() {
  const { organization__slug, organization_policy__id } = useParams();
  const { isLoading: isLoadingPolicy, data } = useQueryFetchOrganizationPolicy({
    organization_policy__id,
  });
  const { isLoading: isLoadingOrganization, data: organization } =
    useQueryFetchOrganization({
      organization__slug,
    });

  const isLoading = isLoadingPolicy || isLoadingOrganization;
  const hasData = Boolean(data) && Boolean(organization);

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  const { latest_version_id = null } = data || {};
  if (
    Boolean(latest_version_id) &&
    latest_version_id !== organization_policy__id
  ) {
    const latestVersionHref = getOrganizationPoliciesHref({
      organization__slug,
      organization_policy__id: latest_version_id,
    });
    return <Navigate to={latestVersionHref} />;
  }

  return (
    <>
      <OrganizationPolicyHeader object={data} />
      <CustomPage>
        <OrganizationPolicyData object={data} />
      </CustomPage>
    </>
  );
}
