import React from "react";
import BillingManagement from "@components/Authenticated/BillingManagement/BillingManagement";
import OrganizationCards from "@components/Authenticated/BillingManagement/OrganizationCards";
import OrganizationPaymentCycle from "@components/Authenticated/BillingManagement/OrganizationPaymentCycles/OrganizationPaymentCycle";
import {
  PAYMENT_CYCLES_HREF,
  PAYMENT_METHODS_HREF,
} from "@constants/static/routing";
import { Navigate, Route, Routes } from "react-router-dom";

export default function BillingManagementRoutes() {
  return (
    <Routes>
      <Route path="" Component={BillingManagement} />
      <Route path={PAYMENT_METHODS_HREF} Component={OrganizationCards} />
      <Route
        path={`${PAYMENT_CYCLES_HREF}/:organization_payment_cycle__id`}
        Component={OrganizationPaymentCycle}
      />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
