import React from "react";
import {
  CustomDatePicker,
  CustomOutlinedTextField,
} from "@components/Custom/CustomOutlinedField";
import CustomSection from "@components/Custom/CustomSection";
import Grid from "@mui/material/Grid";

export default function OrganizationPolicyDetailsFormData({
  is_processor_tpa = false,
  formData = {},
  setFormData = () => {},
}) {
  const { insurer_name, number, name, description, expiry_date } = formData;

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeExpiryDate = (newValue) =>
    setFormData((prev) => ({ ...prev, expiry_date: newValue }));

  return (
    <CustomSection title="Policy details" isCollapsable={false}>
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <CustomOutlinedTextField
            required
            label="Policy number"
            name="number"
            value={number}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomOutlinedTextField
            required
            label="Policy name"
            name="name"
            value={name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomDatePicker
            label="Expiry date"
            value={expiry_date}
            onChange={handleChangeExpiryDate}
          />
        </Grid>
        {is_processor_tpa && (
          <Grid item xs={12} md={3}>
            <CustomOutlinedTextField
              label="Insurer name"
              name="insurer_name"
              value={insurer_name || ""}
              onChange={handleChange}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomOutlinedTextField
            label="Scheme"
            name="description"
            value={description}
            onChange={handleChange}
            multiline
            minRows={2}
          />
        </Grid>
      </Grid>
    </CustomSection>
  );
}
