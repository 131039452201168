import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function CustomLabeledRow({
  label,
  value,
  disableValueTypography = false,
  labelTypographyProps = {},
  valueTypographyProps = {},
}) {
  return (
    <Stack
      spacing={3}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="body2" {...labelTypographyProps}>
        {label}
      </Typography>
      {disableValueTypography ? (
        value
      ) : (
        <Typography variant="body2" {...valueTypographyProps}>
          {value}
        </Typography>
      )}
    </Stack>
  );
}
