import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import MenuItem from "@mui/material/MenuItem";
import { lockClaim, updateClaimObjectTSQ } from "@redux/actions/claims/claims";
import { enqueueSnackbar } from "notistack";

export default function ClaimProcessorLockForm({ id, time_locked = null }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    lockClaim(id)
      .then(({ payload }) => {
        setIsProcessing(false);
        setIsOpen(false);
        updateClaimObjectTSQ({ object: payload });
        enqueueSnackbar({
          variant: "success",
          message: "Claim successfully updated",
        });
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        enqueueSnackbar({ variant: "error", message: payload });
      });
  };

  const isLocked = Boolean(time_locked);
  const dialogActionProps = {
    isOpen,
    isProcessing,
    handleClose: handleIsOpen,
    handleSubmit,
    isBodyEmpty: true,
    yesButtonProps: { variant: "contained", color: "secondary" },
    ...(isLocked
      ? {
          titleComponentProps: { title: "Unlock claim" },
          subtitleComponentProps: { title: "This claim will be editable." },
          yesText: "Unlock",
        }
      : {
          titleComponentProps: { title: "Lock claim" },
          subtitleComponentProps: {
            title: "This claim will no longer be editable.",
          },
          yesText: "Lock",
        }),
  };
  const { TitleComponent } = dialogActionProps;

  return (
    <>
      <MenuItem onClick={handleIsOpen}>{TitleComponent}</MenuItem>
      <CustomActionDialog {...dialogActionProps} />
    </>
  );
}
