import React from "react";
import {
  convertClaimMetaDict,
  getMetaDataAllKeys,
  getMetaDataFilledKeys,
  revertClaimMetaDict,
} from "@constants/claims/claim-meta-data";
import Box from "@mui/material/Box";
import pluralize from "pluralize";
import CustomEditableDataGrid from "../CustomDataGrid/CustomEditableDataGrid";
import CustomListItemCollapse from "../CustomListItemCollapse";

const columns = [
  {
    field: "key",
    headerName: "Column name",
    editable: false,
    flex: 1,
  },
  {
    field: "value",
    headerName: "Column value",
    flex: 1,
    editable: true,
  },
];

const autoFocusField = "value";

export default function CustomMetaFormData({
  meta_data = {},
  handleChange = () => {},
}) {
  const [formData, setFormData] = React.useState([]);
  const allKeys = getMetaDataAllKeys(formData);
  const filledKeys = getMetaDataFilledKeys(formData);
  const secondary = `${filledKeys.length} / ${pluralize(
    "column",
    allKeys.length,
    true
  )}`;

  const handleBlur = (e) => {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      if (!currentTarget?.contains(document.activeElement)) {
        handleChange(revertClaimMetaDict(formData));
      }
    }, 0);
  };

  React.useEffect(() => {
    setFormData(convertClaimMetaDict(meta_data));
  }, [meta_data]);

  return (
    <Box tabIndex={-1} onBlur={handleBlur}>
      <CustomListItemCollapse
        primary="Additional information"
        secondary={secondary}
      >
        <CustomEditableDataGrid
          formData={formData}
          setFormData={setFormData}
          columns={columns}
          autoFocusField={autoFocusField}
        />
      </CustomListItemCollapse>
    </Box>
  );
}
