import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  invalidateOrganizationCardsTSQ,
  makePrimaryOrganizationCard,
} from "@redux/actions/organizations/organization-cards";
import { enqueueSnackbar } from "notistack";
import OrganizationCardContent from "../OrganizationCard/OrganizationCardContent";

export default function OrganizationCardMakePrimaryCard({
  isOpen,
  object,
  handleClose = () => {},
}) {
  const { id } = object;
  const [isProcessing, setIsProcessing] = React.useState(false);
  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    makePrimaryOrganizationCard(id)
      .then(() => {
        setIsProcessing(false);
        enqueueSnackbar({
          variant: "success",
          message: "Primary card successfully updated",
        });
        handleClose();
        invalidateOrganizationCardsTSQ();
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        enqueueSnackbar({ variant: "error", message: payload });
      });
  };

  return (
    <CustomActionDialog
      isOpen={isOpen}
      isProcessing={isProcessing}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      titleComponentProps={{ title: "Make primary card" }}
      noText="No, go back"
      yesText="Yes, proceed"
    >
      <OrganizationCardContent object={object} isElevatedVersion={true} />
    </CustomActionDialog>
  );
}
