import {
  USER_ORGANIZATION_ROLE_OPTIONS,
  USER_STATUS_ACTIVATED,
  USER_STATUS_DEACTIVATED,
  USER_STATUS_OPTIONS,
  USER_STATUS_PENDING,
} from "@constants/users/users";

export const initialFilterState = {
  is_active: [],
  role__in: [],
};

export const filterOptions = {
  is_active: {
    title: "Activity status",
    type: "boolean",
    options: USER_STATUS_OPTIONS,
  },
  role__in: {
    title: "Roles",
    type: "options",
    options: USER_ORGANIZATION_ROLE_OPTIONS,
  },
};

export const formatFilters = (filters = {}) => {
  let formattedFilters = {};
  const { is_active = [], role__in = [] } = filters;

  if (is_active.length === 1) {
    const is_active_status = is_active[0];
    if (is_active_status === USER_STATUS_ACTIVATED) {
      formattedFilters["is_active"] = "true";
      formattedFilters["activation_date__isnull"] = "false";
    } else if (is_active_status === USER_STATUS_DEACTIVATED) {
      formattedFilters["is_active"] = "false";
    } else if (is_active_status === USER_STATUS_PENDING) {
      formattedFilters["is_active"] = "false";
      formattedFilters["activation_date__isnull"] = "true";
    }
  }

  if (role__in) formattedFilters["role__in"] = role__in.join(",");

  return formattedFilters;
};
