import React from "react";
import OrganizationClinicianData from "@components/Authenticated/Configurations/OrganizationClinicians/OrganizationClinician/OrganizationClinicianData";
import CustomNotProvidedText from "@components/Custom/CustomNotProvidedText";
import CustomSection from "@components/Custom/CustomSection";
import { CLAIM_SECTION_ORGANIZATION_CLINICIAN_TITLE } from "@constants/claims/claims";
import { useClaimContext } from "../Claim/context";
import ClaimOrganizationClinicianFormData from "./ClaimOrganizationClinicianFormData";

export default function ClaimOrganizationClinician() {
  const {
    claim = {},
    isCreateOrUpdateMode = false,
    setFormData = () => {},
  } = useClaimContext();
  const { organization_clinician = {} } = claim;
  const handleChangeOrganizationClinician = React.useCallback(
    (newValue) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        organization_clinician: newValue,
      }));
    },
    [setFormData]
  );

  return (
    <CustomSection
      title={CLAIM_SECTION_ORGANIZATION_CLINICIAN_TITLE}
      isCollapsable={!isCreateOrUpdateMode}
    >
      {isCreateOrUpdateMode ? (
        <ClaimOrganizationClinicianFormData
          formData={organization_clinician}
          handleChangeOrganizationClinician={handleChangeOrganizationClinician}
        />
      ) : Boolean(organization_clinician) ? (
        <OrganizationClinicianData
          object={organization_clinician}
          useCustomSection={false}
        />
      ) : (
        <CustomNotProvidedText
          title={CLAIM_SECTION_ORGANIZATION_CLINICIAN_TITLE}
        />
      )}
    </CustomSection>
  );
}
