import React from "react";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomStatusTag from "@components/Custom/CustomStatusTag";
import { getClaimStatusMapper } from "@constants/claims/claim-status";
import { selectorAuthCurrentUserOrganizationRole } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import ClaimDeletedTag from "./ClaimDeletedTag";
import ClaimLockedTag from "./ClaimLockedTag";
import ClaimNumEvaluationResultsTag from "./ClaimNumEvaluationResultsTag";

export default function ClaimStatus({
  status,
  time_deleted = null,
  time_locked = null,
  withTooltip = true,
  num_success_evaluation_results = 0,
  num_failure_evaluation_results = 0,
  num_alert_evaluation_results = 0,
  rowSpacing = 3,
  columnSpacing = 2,
}) {
  const organizationRole = useSelector(selectorAuthCurrentUserOrganizationRole);
  const { propsMapper = {}, textMapper = {} } = React.useMemo(
    () =>
      getClaimStatusMapper({
        organizationRole,
      }),
    [organizationRole]
  );
  const hasCounts =
    num_success_evaluation_results +
      num_failure_evaluation_results +
      num_alert_evaluation_results >
    0;

  return (
    <CustomGrid
      alignItems="center"
      rowSpacing={rowSpacing}
      columnSpacing={columnSpacing}
    >
      <CustomStatusTag
        status={status}
        textMapper={textMapper}
        propsMapper={propsMapper}
      />
      {hasCounts && (
        <ClaimNumEvaluationResultsTag
          num_success_evaluation_results={num_success_evaluation_results}
          num_failure_evaluation_results={num_failure_evaluation_results}
          num_alert_evaluation_results={num_alert_evaluation_results}
        />
      )}
      {time_deleted && (
        <ClaimDeletedTag
          time_deleted={time_deleted}
          withTooltip={withTooltip}
        />
      )}
      {time_locked && (
        <ClaimLockedTag time_locked={time_locked} withTooltip={withTooltip} />
      )}
    </CustomGrid>
  );
}
