import React from "react";
import { getInstanceOptionLabel } from "@components/Custom/CustomAutocomplete/PhoenixAutocomplete/InstanceAutocomplete";
import {
  SYMPTOM_STATUS_FINAL_MAPPER,
  SYMPTOM_STATUS_PRESENT,
  SYMPTOM_TYPE_OBJECTIVE,
  SYMPTOM_TYPE_SUBJECTIVE,
} from "@constants/avey-cowriter";
import {
  getRecommendedPlan,
  PLAN_TAG_GASTRIC_ULCERS,
  PLAN_TAG_PREGNANT,
  PLAN_TAG_PREGNANT_THIRD_TRIMESTER,
} from "@constants/avey-cowriter/scenarios";
import { getEmptyClaimMedicationFormData } from "@constants/claims/claim-medications";
import { getEmptyClaimProcedureFormData } from "@constants/claims/claim-procedures";
import { parseOrganizationPatient } from "@constants/organizations/organization-patients";
import {
  WS_NOTIFICATION_TYPE_END_SESSION,
  WS_NOTIFICATION_TYPE_ERROR,
  WS_NOTIFICATION_TYPE_INCOMING_SYMPTOMS,
} from "@constants/static/api";
import { SERVER_WS_URL } from "@constants/static/globals";
import { mergeAndOverrideSymptoms } from "@helpers/parse/list";
import {
  fetchDiagnosis,
  fetchNextQuestionWithAttribute,
  fetchNextQuestionWithoutAttribute,
  fetchObjectiveSummary,
  fetchPlanSummary,
  fetchSubjectiveSummary,
} from "@redux/actions/server/server";
import ReconnectingWebSocket from "reconnecting-websocket";
import { v4 as uuidv4 } from "uuid";

export const CLAIM_DEMO_LIVE_TRANSCRIPTION_TITLE = "Live transcription";
export const CLAIM_DEMO_AVEY_QUESTIONS_TITLE = "Avey Collaborator";
export const CLAIM_DEMO_SYMPTOMS_TITLE = "Signs & symptoms";
export const CLAIM_DEMO_SUBJECTIVE_SYMPTOMS_TITLE = "Subjective";
export const CLAIM_DEMO_OBJECTIVE_SYMPTOMS_TITLE = "Objective";
export const CLAIM_DEMO_ASSESSMENT_TITLE = "Assessment";
export const CLAIM_DEMO_AVEY_ASSESSMENT_TITLE = "Avey assessment";
export const CLAIM_DEMO_CLINICIAN_ASSESSMENT_TITLE = "Doctor assessment";
export const CLAIM_DEMO_RECOMMENDED_PROCEDURES_TITLE = "Recommended procedures";
export const CLAIM_DEMO_RECOMMENDED_MEDICATIONS_TITLE =
  "Recommended medications";
export const CLAIM_DEMO_PLAN_TITLE = "Plan";
export const CLAIM_DEMO_PLAN_SUMMARY_TITLE = "Summary";

const emptyOrganizationPatientState = {
  hasOrganizationPatient: false,
  organization_patient: null,
  parsedOrganizationPatient: parseOrganizationPatient(null),
};

const emptyOrganizationClinicianState = {
  organization_clinician: null,
};

const emptySessionState = {
  isSessionDone: false,
  hasTriggeredPostSessionAutomation: false,
  questions: [],
};

const emptyClaimSymptomsState = {
  claim_symptoms: [],
  claim_symptom_tags: [],
};

const emptyClaimDiagnosesState = {
  avey_claim_diagnoses: [],
  claim_diagnoses: [],
  isLoading: false,
};

const emptyClaimProceduresState = {
  claim_procedures: [],
  recommended_claim_procedures: [],
};

const emptyClaimMedicationsState = {
  claim_medications: [],
  recommended_claim_medications: [],
};

const emptyClaimSummariesState = {
  subjective: {
    summary: "",
    isLoading: false,
    isTypeAnimation: true,
  },
  objective: {
    summary: "",
    isLoading: false,
    isTypeAnimation: true,
  },
  plan: {
    summary: "",
    isLoading: false,
    isTypeAnimation: true,
  },
};

const emptyState = {
  organizationPatientState: emptyOrganizationPatientState,
  organizationClinicianState: emptyOrganizationClinicianState,
  sessionState: emptySessionState,
  claimSymptomsState: emptyClaimSymptomsState,
  claimDiagnosesState: emptyClaimDiagnosesState,
  claimProceduresState: emptyClaimProceduresState,
  claimMedicationsState: emptyClaimMedicationsState,
  claimSummariesState: emptyClaimSummariesState,
};

export const emptySymptomFormData = {
  _id: null, // used by the frontend only (for removing by _id instead of index)
  id: "",
  name: "",
  status: SYMPTOM_STATUS_PRESENT,
  type: "",
  is_chief: false,
  is_searchable: false,
};

export const ClaimDemoContext = React.createContext({
  state: emptyState,
  currency: "-",
  handleChangeOrganizationPatient: () => {},
  handleChangeOrganizationClinician: () => {},
  handleChangeEndSession: () => {},
  handleAddClaimSymptom: () => {},
  handleRemoveClaimSymptom: () => {},
  handleUpdateClaimSymptom: () => {},
  handleUpdateClaimSymptoms: () => {},
  handleUpdateClaimSymptomTags: () => {},
  handleAddClaimDiagnosis: () => {},
  handleRemoveClaimDiagnosis: () => {},
  handleReorderClaimDiagnoses: () => {},
  handleSyncClaimDiagnoses: () => {},
  handleUpdateClaimAveyDiagnoses: () => {},
  handleAddClaimProcedure: () => {},
  handleRemoveClaimProcedure: () => {},
  handleSyncClaimProcedures: () => {},
  handleAddClaimMedication: () => {},
  handleRemoveClaimMedication: () => {},
  handleSyncClaimMedications: () => {},
  handleUpdateSubjectiveSummary: () => {},
  handleUpdateObjectiveSummary: () => {},
  handleUpdatePlanSummary: () => {},
  handleUpdateSummary: () => {},
});

export const useClaimDemoContext = () => React.useContext(ClaimDemoContext);

const parseQuestion = (payload) => {
  const { question = "", interactive_images = [] } = payload;
  let nextQuestion = null;
  if (Boolean(question)) nextQuestion = question;
  else if ((interactive_images || [])?.length > 0) {
    const imageQuestion = interactive_images.find(({ image }) =>
      Boolean(image.question)
    );
    if (imageQuestion) nextQuestion = imageQuestion.image.question;
  }
  if (nextQuestion) nextQuestion = nextQuestion?.replace(/<\/?b>/g, "");
  return nextQuestion;
};

export const selectorClaimDemoOrganizationPatient = (state) =>
  state.organizationPatientState.organization_patient;

export const selectorClaimDemoParsedOrganizationPatient = (state) =>
  state.organizationPatientState.parsedOrganizationPatient;

export const selectorClaimDemoHasOrganizationPatient = (state) =>
  state.organizationPatientState.hasOrganizationPatient;

export const selectorClaimDemoOrganizationClinician = (state) =>
  state.organizationClinicianState.organization_clinician;

export const selectorClaimDemoIsSessionDone = (state) =>
  state.sessionState.isSessionDone;

export const selectorClaimDemoSymptoms = (state) =>
  state.claimSymptomsState.claim_symptoms;

export const selectorClaimDemoHasSymptoms = (state) =>
  selectorClaimDemoSymptoms(state).length > 0;

export const selectorClaimDemoSubjectiveSymptoms = (state) =>
  selectorClaimDemoSymptoms(state).filter(
    ({ type }) => type === SYMPTOM_TYPE_SUBJECTIVE
  );

export const selectorClaimDemoHasSubjectiveSymptoms = (state) =>
  selectorClaimDemoSubjectiveSymptoms(state).length > 0;

export const selectorClaimDemoObjectiveSymptoms = (state) =>
  selectorClaimDemoSymptoms(state).filter(
    ({ type }) => type === SYMPTOM_TYPE_OBJECTIVE
  );

export const selectorClaimDemoHasObjectiveSymptoms = (state) =>
  selectorClaimDemoObjectiveSymptoms(state).length > 0;

export const selectorClaimDemoSymptomTags = (state) =>
  state.claimSymptomsState.claim_symptom_tags;

export const selectorClaimDemoQuestions = (state) =>
  state.sessionState.questions;

export const selectorClaimDemoClinicianDiagnoses = (state) =>
  state.claimDiagnosesState.claim_diagnoses;

export const selectorClaimDemoAveyDiagnoses = (state) =>
  state.claimDiagnosesState.avey_claim_diagnoses;

export const selectorClaimDemoIsLoadingAveyDiagnoses = (state) =>
  state.claimDiagnosesState.isLoading;

export const selectorClaimDemoProcedures = (state) =>
  state.claimProceduresState.claim_procedures;

export const selectorClaimDemoMedications = (state) =>
  state.claimMedicationsState.claim_medications;

export const selectorClaimDemoRecommendedProcedures = (state) =>
  state.claimProceduresState.recommended_claim_procedures;

export const selectorClaimDemoRecommendedMedications = (state) =>
  state.claimMedicationsState.recommended_claim_medications;

export const selectorClaimDemoHasTriggeredPostSessionAutomation = (state) =>
  state.sessionState.hasTriggeredPostSessionAutomation;

export const selectorClaimDemoObjectiveSummary = (state) =>
  state.claimSummariesState.objective;

export const selectorClaimDemoSubjectiveSummary = (state) =>
  state.claimSummariesState.subjective;

export const selectorClaimDemoPlanSummary = (state) =>
  state.claimSummariesState.plan;

export default function ClaimDemoContextWrapper({ children, object }) {
  const { currency } = object;
  const emptyClaimProcedureFormData = React.useMemo(
    () => getEmptyClaimProcedureFormData({ currency }),
    [currency]
  );
  const emptyClaimMedicationFormData = React.useMemo(
    () => getEmptyClaimMedicationFormData({ currency }),
    [currency]
  );
  const [state, setState] = React.useState(emptyState);
  const parsedOrganizationPatient =
    selectorClaimDemoParsedOrganizationPatient(state);
  const isSessionDone = selectorClaimDemoIsSessionDone(state);
  const claimSymptoms = selectorClaimDemoSymptoms(state);
  const claimDiagnoses = selectorClaimDemoClinicianDiagnoses(state);
  const claimSymptomTags = selectorClaimDemoSymptomTags(state);
  const claimProcedures = selectorClaimDemoProcedures(state);
  const claimMedications = selectorClaimDemoMedications(state);
  const subjectiveClaimSymptoms = selectorClaimDemoSubjectiveSymptoms(state);
  const objectiveClaimSymptoms = selectorClaimDemoObjectiveSymptoms(state);
  const hasTriggeredPostSessionAutomation =
    selectorClaimDemoHasTriggeredPostSessionAutomation(state);

  const assessmentBody = React.useMemo(() => {
    const { ageInt, phoenixGender } = parsedOrganizationPatient;
    const findings = claimSymptoms
      ?.filter(({ id, is_chief }) => Boolean(id) && !is_chief)
      .reduce((acc, entry) => {
        const { name, status } = entry;
        acc[name] = SYMPTOM_STATUS_FINAL_MAPPER[status];
        return acc;
      }, {});
    const chief_findings = claimSymptoms
      ?.filter(({ id, is_chief }) => Boolean(id) && is_chief)
      .map(({ id }) => id);

    return {
      age: ageInt,
      chief_findings,
      sex: phoenixGender,
      findings,
    };
  }, [parsedOrganizationPatient, claimSymptoms]);

  const handleChangeOrganizationPatient = React.useCallback(
    (payload) =>
      setState((prev) => ({
        ...prev,
        organizationPatientState: {
          hasOrganizationPatient: Boolean(payload),
          organization_patient: payload,
          parsedOrganizationPatient: parseOrganizationPatient(payload),
        },
      })),
    []
  );

  const handleChangeOrganizationClinician = React.useCallback(
    (payload) =>
      setState((prev) => ({
        ...prev,
        organizationClinicianState: { organization_clinician: payload },
      })),
    []
  );

  const handleChangeEndSession = React.useCallback(() => {
    setState((prev) => ({
      ...prev,
      sessionState: { ...prev.sessionState, isSessionDone: true },
    }));
  }, []);

  const handleAddClaimSymptom = React.useCallback(
    ({ object, type, is_chief, status }) => {
      const isSubjective = type === SYMPTOM_TYPE_SUBJECTIVE;
      const isPresent = status === SYMPTOM_STATUS_PRESENT;
      const isChief = isSubjective && isPresent ? is_chief : false;
      const { id, name } = object;
      const payload = {
        _id: uuidv4(),
        id,
        name,
        status,
        type,
        is_chief: isChief,
      };
      setState((prev) => ({
        ...prev,
        claimSymptomsState: {
          ...prev.claimSymptomsState,
          claim_symptoms: [...prev.claimSymptomsState.claim_symptoms, payload],
        },
      }));
    },
    []
  );

  const handleRemoveClaimSymptom = React.useCallback(
    (objectId) =>
      setState((prev) => ({
        ...prev,
        claimSymptomsState: {
          ...prev.claimSymptomsState,
          claim_symptoms: prev.claimSymptomsState.claim_symptoms.filter(
            ({ _id }) => _id !== objectId
          ),
        },
      })),
    []
  );

  const handleUpdateClaimSymptom = React.useCallback(
    (payload) =>
      setState((prev) => ({
        ...prev,
        claimSymptomsState: {
          ...prev.claimSymptomsState,
          claim_symptoms: prev.claimSymptomsState.claim_symptoms.map(
            (symptom) => (symptom?._id === payload?._id ? payload : symptom)
          ),
        },
      })),
    []
  );

  const handleUpdateClaimSymptoms = React.useCallback(
    (payload = []) =>
      setState((prev) => ({
        ...prev,
        claimSymptomsState: {
          ...prev.claimSymptomsState,
          claim_symptoms: mergeAndOverrideSymptoms(
            prev.claimSymptomsState.claim_symptoms,
            payload
          ),
        },
      })),
    []
  );

  const handleUpdateClaimSymptomTags = React.useCallback(
    (payload) =>
      setState((prev) => {
        let newTags = [];
        const oldTags = prev.claimSymptomsState.claim_symptom_tags;
        switch (payload) {
          case PLAN_TAG_PREGNANT:
            newTags = oldTags.includes(PLAN_TAG_PREGNANT)
              ? oldTags.filter(
                  (t) =>
                    ![
                      PLAN_TAG_PREGNANT,
                      PLAN_TAG_PREGNANT_THIRD_TRIMESTER,
                    ].includes(t)
                )
              : [...oldTags, PLAN_TAG_PREGNANT];
            break;
          case PLAN_TAG_PREGNANT_THIRD_TRIMESTER:
          case PLAN_TAG_GASTRIC_ULCERS:
          default:
            newTags = oldTags.includes(payload)
              ? oldTags.filter((t) => payload !== t)
              : [...oldTags, payload];
            break;
        }

        return {
          ...prev,
          claimSymptomsState: {
            ...prev.claimSymptomsState,
            claim_symptom_tags: newTags,
          },
        };
      }),
    []
  );

  const handleUpdateQuestions = React.useCallback(async () => {
    if (assessmentBody.chief_findings.length > 0 && !isSessionDone) {
      const questionFunctions = [
        fetchNextQuestionWithAttribute,
        fetchNextQuestionWithoutAttribute,
      ];

      Promise.all(
        questionFunctions.map((questionFunction) =>
          questionFunction(assessmentBody).then((payload) =>
            parseQuestion(payload)
          )
        )
      ).then((payload) => {
        setState((prev) => {
          const [q1, q2] = payload;
          const isQ1New =
            Boolean(q1) &&
            !prev.sessionState.questions.find((question) => question === q1);
          const isQ2New =
            Boolean(q2) &&
            !prev.sessionState.questions.find((question) => question === q2);
          const nextQuestion = isQ1New ? q1 : isQ2New ? q2 : null;
          if (!Boolean(nextQuestion)) return prev;
          return {
            ...prev,
            sessionState: {
              ...prev.sessionState,
              questions: [
                ...prev.sessionState.questions.filter(
                  (question) => question !== nextQuestion
                ),
                nextQuestion,
              ],
            },
          };
        });
      });
    }
  }, [assessmentBody, isSessionDone]);

  const handleAddClaimDiagnosis = React.useCallback((payload) => {
    const { id } = payload;
    const name = getInstanceOptionLabel(payload);
    const object = { id, name };
    setState((prev) => ({
      ...prev,
      claimDiagnosesState: {
        ...prev.claimDiagnosesState,
        claim_diagnoses: [...prev.claimDiagnosesState.claim_diagnoses, object],
      },
    }));
  }, []);

  const handleRemoveClaimDiagnosis = React.useCallback(
    (index) =>
      setState((prev) => ({
        ...prev,
        claimDiagnosesState: {
          ...prev.claimDiagnosesState,
          claim_diagnoses: prev.claimDiagnosesState.claim_diagnoses.filter(
            (_, position) => index !== position
          ),
        },
      })),
    []
  );

  const handleReorderClaimDiagnoses = React.useCallback(
    (payload) =>
      setState((prev) => ({
        ...prev,
        claimDiagnosesState: {
          ...prev.claimDiagnosesState,
          claim_diagnoses: payload,
        },
      })),
    []
  );

  const handleSyncClaimDiagnoses = React.useCallback(
    () =>
      setState((prev) => ({
        ...prev,
        claimDiagnosesState: {
          ...prev.claimDiagnosesState,
          claim_diagnoses: prev.claimDiagnosesState.avey_claim_diagnoses.map(
            ({ disease: name, instance_id: id }) => ({ id, name })
          ),
        },
      })),
    []
  );

  const handleUpdateClaimAveyDiagnoses = React.useCallback(() => {
    if (assessmentBody.chief_findings.length > 0) {
      const localUpdatFn = (payload) =>
        setState((prev) => ({
          ...prev,
          claimDiagnosesState: {
            ...prev.claimDiagnosesState,
            ...payload,
          },
        }));
      localUpdatFn({ isLoading: true });
      fetchDiagnosis(assessmentBody)
        .then(({ payload }) => {
          localUpdatFn({
            isLoading: false,
            avey_claim_diagnoses: payload?.diagnosis?.diseases?.flat() || [],
          });
        })
        .catch(() => {
          localUpdatFn({
            isLoading: false,
            avey_claim_diagnoses: [],
          });
        });
    }
  }, [assessmentBody]);

  const handleAddClaimProcedure = React.useCallback(
    (payload) =>
      setState((prev) => ({
        ...prev,
        claimProceduresState: {
          ...prev.claimProceduresState,
          claim_procedures: [
            ...prev.claimProceduresState.claim_procedures,
            { ...emptyClaimProcedureFormData, organization_bundle: payload },
          ],
        },
      })),
    [emptyClaimProcedureFormData]
  );

  const handleRemoveClaimProcedure = React.useCallback(
    (index) =>
      setState((prev) => ({
        ...prev,
        claimProceduresState: {
          ...prev.claimProceduresState,
          claim_procedures: prev.claimProceduresState.claim_procedures.filter(
            (_, position) => index !== position
          ),
        },
      })),
    []
  );

  const handleSyncClaimProcedures = React.useCallback(
    () =>
      setState((prev) => ({
        ...prev,
        claimProceduresState: {
          ...prev.claimProceduresState,
          claim_procedures:
            prev.claimProceduresState.recommended_claim_procedures.map(
              (claim_procedure) => ({
                ...emptyClaimProcedureFormData,
                ...claim_procedure,
              })
            ),
        },
      })),
    [emptyClaimProcedureFormData]
  );

  const handleUpdateRecommendations = React.useCallback(() => {
    if (isSessionDone) {
      const { ageInt, phoenixGender } = parsedOrganizationPatient;
      const {
        claim_medications: recommendedMedications,
        claim_procedures: recommendedProcedures,
      } = getRecommendedPlan({
        ageInt,
        phoenixGender,
        claim_diagnoses: claimDiagnoses,
        claim_symptom_tags: claimSymptomTags,
      });
      setState((prev) => ({
        ...prev,
        claimProceduresState: {
          ...prev.claimProceduresState,
          recommended_claim_procedures: recommendedProcedures,
        },
        claimMedicationsState: {
          ...prev.claimMedicationsState,
          recommended_claim_medications: recommendedMedications,
        },
      }));
    }
  }, [
    isSessionDone,
    parsedOrganizationPatient,
    claimDiagnoses,
    claimSymptomTags,
  ]);

  const handleAddClaimMedication = React.useCallback(
    (payload) =>
      setState((prev) => ({
        ...prev,
        claimMedicationsState: {
          ...prev.claimMedicationsState,
          claim_medications: [
            ...prev.claimMedicationsState.claim_medications,
            { ...emptyClaimMedicationFormData, organization_bundle: payload },
          ],
        },
      })),
    [emptyClaimMedicationFormData]
  );

  const handleRemoveClaimMedication = React.useCallback(
    (index) =>
      setState((prev) => ({
        ...prev,
        claimMedicationsState: {
          ...prev.claimMedicationsState,
          claim_medications:
            prev.claimMedicationsState.claim_medications.filter(
              (_, position) => index !== position
            ),
        },
      })),
    []
  );

  const handleSyncClaimMedications = React.useCallback(
    () =>
      setState((prev) => ({
        ...prev,
        claimMedicationsState: {
          ...prev.claimMedicationsState,
          claim_medications:
            prev.claimMedicationsState.recommended_claim_medications.map(
              (claim_medication) => ({
                ...emptyClaimMedicationFormData,
                ...claim_medication,
              })
            ),
        },
      })),
    [emptyClaimMedicationFormData]
  );

  const handleUpdatePostSessionAutomation = React.useCallback(() => {
    setState((prev) => ({
      ...prev,
      sessionState: {
        ...prev.sessionState,
        hasTriggeredPostSessionAutomation: true,
      },
    }));
  }, []);

  const handleUpdateSubjectiveSummary = React.useCallback(() => {
    const { full_name, ageInt, phoenixGender } = parsedOrganizationPatient;
    if (subjectiveClaimSymptoms.length > 0) {
      const localUpdatFn = (payload) =>
        setState((prev) => ({
          ...prev,
          claimSummariesState: {
            ...prev.claimSummariesState,
            subjective: { ...prev.claimSummariesState.subjective, ...payload },
          },
        }));
      localUpdatFn({ isLoading: true });
      fetchSubjectiveSummary({
        name: full_name,
        age: ageInt,
        gender: phoenixGender,
        symptoms: subjectiveClaimSymptoms.map(({ name, status }) => ({
          name,
          status,
        })),
      })
        .then(({ payload }) => {
          localUpdatFn({
            summary: payload,
            isLoading: false,
            isTypeAnimation: true,
          });
        })
        .catch(() => localUpdatFn({ isLoading: false }));
    }
  }, [parsedOrganizationPatient, subjectiveClaimSymptoms]);

  const handleUpdateObjectiveSummary = React.useCallback(() => {
    const { full_name } = parsedOrganizationPatient;
    if (objectiveClaimSymptoms.length > 0) {
      const localUpdatFn = (payload) =>
        setState((prev) => ({
          ...prev,
          claimSummariesState: {
            ...prev.claimSummariesState,
            objective: { ...prev.claimSummariesState.objective, ...payload },
          },
        }));

      localUpdatFn({ isLoading: true });
      fetchObjectiveSummary({
        name: full_name,
        symptoms: objectiveClaimSymptoms.map(({ name, status }) => ({
          name,
          status,
        })),
      })
        .then(({ payload }) => {
          localUpdatFn({
            summary: payload,
            isLoading: false,
            isTypeAnimation: true,
          });
        })
        .catch(() => localUpdatFn({ isLoading: false }));
    }
  }, [parsedOrganizationPatient, objectiveClaimSymptoms]);

  const handleUpdatePlanSummary = React.useCallback(() => {
    const { full_name } = parsedOrganizationPatient;
    if (claimProcedures.length > 0 || claimMedications.length > 0) {
      const localUpdatFn = (payload) =>
        setState((prev) => ({
          ...prev,
          claimSummariesState: {
            ...prev.claimSummariesState,
            plan: { ...prev.claimSummariesState.plan, ...payload },
          },
        }));
      localUpdatFn({ isLoading: true });
      fetchPlanSummary({
        name: full_name,
        claim_procedures: claimProcedures,
        claim_medications: claimMedications,
      })
        .then(({ payload }) => {
          localUpdatFn({
            summary: payload,
            isLoading: false,
            isTypeAnimation: true,
          });
        })
        .catch(() => localUpdatFn({ isLoading: false }));
    }
  }, [parsedOrganizationPatient, claimProcedures, claimMedications]);

  const handleUpdateSummary = React.useCallback(({ type, value }) => {
    setState((prev) => ({
      ...prev,
      claimSummariesState: {
        ...prev.claimSummariesState,
        [type]: {
          ...prev.claimSummariesState[type],
          summary: value,
          isTypeAnimation: false,
        },
      },
    }));
  }, []);

  const updateSymptomsIncomingNotification = React.useCallback(
    (data) => {
      const parsedData = JSON.parse(data);
      const { type, payload } = parsedData;
      switch (type) {
        case WS_NOTIFICATION_TYPE_INCOMING_SYMPTOMS:
          const { symptoms } = payload;
          return handleUpdateClaimSymptoms(symptoms);
        case WS_NOTIFICATION_TYPE_END_SESSION:
          return handleChangeEndSession();
        case WS_NOTIFICATION_TYPE_ERROR:
          console.log("Error", payload);
          break;
        default:
          return;
      }
    },
    [handleChangeEndSession, handleUpdateClaimSymptoms]
  );

  React.useEffect(() => {
    handleUpdateQuestions();
  }, [handleUpdateQuestions]);

  React.useEffect(() => {
    handleUpdateRecommendations();
  }, [handleUpdateRecommendations]);

  React.useEffect(() => {
    const ws = new ReconnectingWebSocket(SERVER_WS_URL);
    ws.onmessage = ({ data }) => updateSymptomsIncomingNotification(data);
    ws.onerror = (e) => console.log("Server Websocket connection error", e);
    return () => ws.close();
  }, [updateSymptomsIncomingNotification]);

  React.useEffect(() => {
    if (isSessionDone && !hasTriggeredPostSessionAutomation) {
      handleUpdateSubjectiveSummary();
      handleUpdateObjectiveSummary();
      handleUpdateClaimAveyDiagnoses();
      handleUpdatePostSessionAutomation();
    }
  }, [
    isSessionDone,
    hasTriggeredPostSessionAutomation,
    handleUpdateSubjectiveSummary,
    handleUpdateObjectiveSummary,
    handleUpdateClaimAveyDiagnoses,
    handleUpdatePostSessionAutomation,
  ]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      sessionState: emptySessionState,
      claimSymptomsState: emptyClaimSymptomsState,
      claimDiagnosesState: emptyClaimDiagnosesState,
      claimProceduresState: emptyClaimProceduresState,
      claimMedicationsState: emptyClaimMedicationsState,
      claimSummariesState: emptyClaimSummariesState,
    }));
  }, [parsedOrganizationPatient?.patient_id]);

  return (
    <ClaimDemoContext.Provider
      value={{
        state,
        currency,
        handleChangeOrganizationPatient,
        handleChangeOrganizationClinician,
        handleChangeEndSession,
        handleAddClaimSymptom,
        handleRemoveClaimSymptom,
        handleUpdateClaimSymptom,
        handleUpdateClaimSymptoms,
        handleUpdateClaimSymptomTags,
        handleAddClaimDiagnosis,
        handleRemoveClaimDiagnosis,
        handleReorderClaimDiagnoses,
        handleSyncClaimDiagnoses,
        handleUpdateClaimAveyDiagnoses,
        handleAddClaimProcedure,
        handleRemoveClaimProcedure,
        handleSyncClaimProcedures,
        handleAddClaimMedication,
        handleRemoveClaimMedication,
        handleSyncClaimMedications,
        handleUpdateSubjectiveSummary,
        handleUpdateObjectiveSummary,
        handleUpdatePlanSummary,
        handleUpdateSummary,
      }}
    >
      {children}
    </ClaimDemoContext.Provider>
  );
}
