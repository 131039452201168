import React from "react";
import { getIntOrFloat } from "@helpers/parse/numbers";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";
import { AVEY_BLACK, AVEY_GREY_F2 } from "@styles/theme";
import { Navigate, NavLink } from "react-router-dom";
import CustomBox from "./CustomBox";
import { BLACK_PILL, OUTLINED_GREY_PILL } from "./CustomPills";

export const CustomTabs = styled(
  (props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
      variant="scrollable"
      scrollButtons={false}
    />
  ),
  { shouldForwardProp: (prop) => !["useUnderline"].includes(prop) }
)(({ useUnderline = true }) => ({
  boxShadow: useUnderline && `inset 0 -2px 0 0 ${AVEY_GREY_F2}`,
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    minHeight: "2px",
  },
  "& .MuiTabs-indicatorSpan": {
    margin: "0px 4px",
    width: "100%",
    backgroundColor: AVEY_BLACK,
    borderRadius: "4px",
  },
  "& .MuiTabs-flexContainer": {
    width: 0,
  },
}));

export const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    transition: "color 0.3s ease",
    minHeight: 52,
    fontWeight: "normal",
    fontSize: "14px",
    padding: "8px 12px",
    color: theme.palette.text.secondary,
    "&.Mui-selected": { color: theme.palette.text.primary },
    ":hover": { color: theme.palette.text.primary },
  })
);

export const CustomNavTabs = ({
  tabs = [],
  defaultTab = "",
  tabCountData = {},
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const tabParam = searchParams.get("tab");

  const [value, setValue] = React.useState(0);

  const handleChange = (e, newValue) => setValue(newValue);
  const handleDragStart = (e) => e.preventDefault();

  React.useEffect(() => {
    const index = tabs.findIndex(({ tab }) => tab === tabParam);
    setValue(index);
  }, [tabs, tabParam]);

  if (value < 0 && Boolean(defaultTab))
    return <Navigate to={`?tab=${defaultTab}`} />;

  return (
    <CustomTabs
      value={value}
      onChange={handleChange}
      role="navigation"
      useUnderline={false}
    >
      {tabs.map(({ label, tab, fieldname }, index) => {
        const isActive = tab === tabParam;
        const iconProps = isActive ? BLACK_PILL : OUTLINED_GREY_PILL;
        return (
          <CustomTab
            key={index}
            component={NavLink}
            value={index}
            label={label}
            to={`?tab=${tab}`}
            icon={
              <CustomBox {...iconProps}>
                {getIntOrFloat((tabCountData || {})[fieldname] || "0")}
              </CustomBox>
            }
            iconPosition="end"
            onDragStart={handleDragStart}
          />
        );
      })}
    </CustomTabs>
  );
};
