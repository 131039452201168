import React from "react";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import YourOrganizationHeader from "./YourOrganizationHeader";
import YourOrganizationBody from "./YourOrganizationBody";

export default function YourOrganization() {
  return (
    <>
      <YourOrganizationHeader />
      <CustomPage>
        <YourOrganizationBody />
      </CustomPage>
    </>
  );
}
