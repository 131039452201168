import React from "react";
import CustomSection, {
  customSectionMinifiedBodyPadding,
} from "@components/Custom/CustomSection";
import CustomThickDivider from "@components/Custom/CustomThickDivider";
import { CLAIM_SECTION_MEDICATIONS_TITLE } from "@constants/claims/claims";
import Box from "@mui/material/Box";
import ClaimMedicationData from "./ClaimMedicationData";

export default function ClaimMedicationsReviewAndConfirm({
  claim_medications = [],
}) {
  const numObjects = claim_medications.length;

  if (numObjects === 0) return null;

  return (
    <CustomSection
      title={CLAIM_SECTION_MEDICATIONS_TITLE}
      isMinified={true}
      bodyProps={{ p: 0 }}
    >
      {claim_medications.map((object, index) => (
        <React.Fragment key={index}>
          {index !== 0 && <CustomThickDivider />}
          <Box p={customSectionMinifiedBodyPadding}>
            <ClaimMedicationData object={object} useErrorFieldnames={false} />
          </Box>
        </React.Fragment>
      ))}
    </CustomSection>
  );
}
