import React from "react";
import CustomActionButtons from "@components/Custom/CustomActionButtons";
import { customSectionBodyPadding } from "@components/Custom/CustomSection";
import { getClaimMedicationSectionId } from "@constants/claims/claim-evaluation-results";
import {
  CLAIM_SECTION_MEDICATIONS,
  CLAIM_SECTION_RESULTS_MAPPER,
} from "@constants/claims/claims";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import ClaimEvaluationResults from "../Claim/ClaimEvaluationResults";
import ClaimMedicationData from "./ClaimMedicationData";

const section = CLAIM_SECTION_MEDICATIONS;

export default function ClaimMedication({
  index,
  object,
  isCreateOrUpdateMode,
  handleSelectClaimMedication = () => {},
  handleRemoveClaimMedication = () => {},
  handleMigrateClaimMedication = () => {},
}) {
  const { id, organization_bundle } = object;
  const results = object?.[CLAIM_SECTION_RESULTS_MAPPER[section]] || [];
  const sectionId = getClaimMedicationSectionId({ isCreateOrUpdateMode, id });
  const hasNoOrganizationBundle = !Boolean(organization_bundle);
  const hasResults = results.length > 0;

  const handleSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleSelectClaimMedication({ object, index });
  };
  const handleRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleRemoveClaimMedication({ index });
  };
  const handleMigrate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleMigrateClaimMedication({ index });
  };

  return (
    <Box
      id={sectionId}
      style={{ scrollMarginTop: 166 }}
      p={customSectionBodyPadding}
    >
      <Stack spacing={3}>
        <Stack direction="row" spacing={3} justifyContent="space-between">
          <ClaimMedicationData object={object} useErrorFieldnames={true} />
          {isCreateOrUpdateMode && (
            <CustomActionButtons
              handleEdit={handleSelect}
              handleRemove={handleRemove}
            >
              {hasNoOrganizationBundle && (
                <MenuItem onClick={handleMigrate}>
                  <span>
                    {"Move to "}
                    <b>{" Procedures"}</b>
                  </span>
                </MenuItem>
              )}
            </CustomActionButtons>
          )}
        </Stack>
        {hasResults && (
          <>
            <Divider />
            <ClaimEvaluationResults
              results={results}
              isCreateOrUpdateMode={isCreateOrUpdateMode}
              type={section}
            />
          </>
        )}
      </Stack>
    </Box>
  );
}
