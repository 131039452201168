import React from "react";
import CustomSection, {
  customSectionBodyPadding,
} from "@components/Custom/CustomSection";
import Stack from "@mui/material/Stack";
import {
  CLAIM_DEMO_AVEY_QUESTIONS_TITLE,
  selectorClaimDemoHasOrganizationPatient,
  selectorClaimDemoIsSessionDone,
  selectorClaimDemoQuestions,
  useClaimDemoContext,
} from "../context";
import ClaimDemoQuestion from "./ClaimDemoQuestion";

export default function ClaimDemoQuestions() {
  const bodyRef = React.useRef();
  const { state } = useClaimDemoContext();
  const hasOrganizationPatient = selectorClaimDemoHasOrganizationPatient(state);
  const isSessionDone = selectorClaimDemoIsSessionDone(state);
  const questions = selectorClaimDemoQuestions(state);
  const numQuestions = questions.length;

  React.useEffect(() => {
    if (bodyRef.current) {
      const scrollElement = bodyRef.current;
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  }, [questions]);

  return (
    <div className="blackBg borderRadius-16">
      <CustomSection
        color="black"
        title={CLAIM_DEMO_AVEY_QUESTIONS_TITLE}
        isOpenDefault={hasOrganizationPatient}
        isCollapsable={false}
        bodyProps={{ className: "blackBg borderRadiusBottom-16", p: 0 }}
      >
        <Stack
          spacing={2}
          ref={bodyRef}
          p={customSectionBodyPadding}
          style={{ height: 100, overflowY: "auto", scrollBehavior: "smooth" }}
        >
          {questions.map((question, index) => (
            <ClaimDemoQuestion
              key={index}
              index={index}
              question={question}
              numQuestions={numQuestions}
              isSessionDone={isSessionDone}
            />
          ))}
        </Stack>
      </CustomSection>
    </div>
  );
}
