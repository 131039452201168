import { DEFAULT_ERROR_MESSAGE } from "@constants/static/text";
import { AveyBloomApi, PayloadError } from "@helpers/api";

export async function sendSMS(data) {
  try {
    const response = await AveyBloomApi().post("/utils/send_sms/", data);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload:
        error?.response?.data?.detail ||
        error?.response.data?.reason ||
        DEFAULT_ERROR_MESSAGE,
    });
  }
}

export const getUniqueList = (list) => [...new Set(list)];
