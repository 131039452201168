import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import { getDrugName } from "@constants/avey/medications";
import {
  HEALTHCARE_CONFIGURATIONS_BASE_HREFS,
  MEDICATIONS_TITLE,
  getMedicationsHref,
} from "@constants/static/routing";
import { selectorAuthHasAdminPrivileges } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MedicationActivateForm from "../MedicationForms/MedicationActivateForm";
import MedicationUpdateForm from "../MedicationForms/MedicationUpdateForm";
import MedicationVersionHistory from "./MedicationVersionHistory";

export function MedicationHeaderTitle({ object, variant = "h3" }) {
  const { generic_name, trade_name, version, time_deleted } = object;
  const genericName = getDrugName({ drug_name: generic_name });
  const tradeName = getDrugName({ drug_name: trade_name });

  return (
    <CustomHeaderTitle
      variant={variant}
      title={genericName}
      subtitle={tradeName}
      version={version}
      time_deleted={time_deleted}
    />
  );
}

export default function MedicationHeader({ object }) {
  const { medication__id } = useParams();
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const {
    generic_name,
    is_submitter_organization = false,
    time_deleted,
  } = object || {};
  const isDeleted = Boolean(time_deleted);

  const title = getDrugName({ drug_name: generic_name });
  const canUpdateObject = hasAdminPrivileges && is_submitter_organization;

  const hrefs = [
    ...HEALTHCARE_CONFIGURATIONS_BASE_HREFS,
    {
      label: MEDICATIONS_TITLE,
      href: getMedicationsHref({}),
    },
    {
      label: title,
      href: getMedicationsHref({ medication__id }),
    },
  ];

  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={MedicationHeaderTitle}
      titleComponentProps={{ object }}
    >
      {canUpdateObject && !isDeleted && (
        <MedicationUpdateForm object={object} />
      )}
      <CustomMoreActions>
        <MedicationVersionHistory />
        {canUpdateObject && <MedicationActivateForm object={object} />}
      </CustomMoreActions>
    </CustomHeader>
  );
}
