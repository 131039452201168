import React from "react";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  CLAIM_DEMO_AVEY_ASSESSMENT_TITLE,
  selectorClaimDemoAveyDiagnoses,
  selectorClaimDemoIsLoadingAveyDiagnoses,
  selectorClaimDemoIsSessionDone,
  useClaimDemoContext,
} from "../context";
import ClaimDemoDiagnosisDisease from "./ClaimDemoDiagnosisDisease";

export default function ClaimDemoDiagnosisAvey() {
  const { state } = useClaimDemoContext();
  const isSessionDone = selectorClaimDemoIsSessionDone(state);
  const aveyClaimDiagnoses = selectorClaimDemoAveyDiagnoses(state);
  const isLoadingAveyClaimDiagnoses =
    selectorClaimDemoIsLoadingAveyDiagnoses(state);
  const hasAveyClaimDiagnoses = aveyClaimDiagnoses.length > 0;

  return (
    <Stack spacing={2}>
      <Typography fontWeight="bold">
        {CLAIM_DEMO_AVEY_ASSESSMENT_TITLE}
      </Typography>
      {hasAveyClaimDiagnoses ? (
        <Fade in={!isLoadingAveyClaimDiagnoses} timeout={500}>
          <div>
            {aveyClaimDiagnoses.map(
              ({ instance_id, disease, likelihood }, index) => (
                <ClaimDemoDiagnosisDisease
                  key={index}
                  instanceId={instance_id}
                  diseaseName={disease}
                  likelihood={likelihood}
                />
              )
            )}
          </div>
        </Fade>
      ) : (
        <Typography
          variant="body2"
          color="textSecondary"
          display="grid"
          alignItems="center"
          minHeight={40}
        >
          {isSessionDone
            ? "No differential diagnosis could be generated"
            : "No differential diagnosis"}
        </Typography>
      )}
    </Stack>
  );
}
