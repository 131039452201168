import React from "react";
import CustomSummaryCards from "@components/Custom/CustomSummaryCards";
import CustomTooltip from "@components/Custom/CustomTooltip";
import {
  ORGANIZATION_BUNDLE_TYPE_MEDICATIONS,
  ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES,
} from "@constants/organizations/organization-bundles";
import {
  getOrganizationBundlesHref,
  getOrganizationPatientsHref,
  getOrganizationPoliciesHref,
  ORGANIZATION_BUNDLE_MEDICATIONS_TITLE,
  ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_TITLE,
  ORGANIZATION_PATIENTS_TITLE,
  ORGANIZATION_POLICIES_TITLE,
} from "@constants/static/routing";
import useFetchOrganizationAgreement from "@helpers/hooks/useFetchObjects/useFetchOrganizationAgreement";
import IconButton from "@mui/material/IconButton";
import {
  selectorAuthHasAdminPrivileges,
  selectorAuthHasSubmitterPrivileges,
} from "@redux/selectors/auth";
import { ArrowCenterRightIcon } from "@rimads/assets";
import { AVEY_GREY_DARK } from "@styles/theme";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getIntOrFloat } from "@helpers/parse/numbers";

const CustomSummaryCardAction = ({ href, hasEditAccess = false }) => {
  const title = hasEditAccess ? "Manage" : "View";

  return (
    <CustomTooltip title={title}>
      <NavLink to={href}>
        <IconButton>
          <ArrowCenterRightIcon primary_fill={AVEY_GREY_DARK} dimension={10} />
        </IconButton>
      </NavLink>
    </CustomTooltip>
  );
};

const getClaimSubmissionOrganizationSummariesHrefs = ({
  organization__slug,
}) => {
  const proceduresAndConsumablesHref = getOrganizationBundlesHref({
    bundle_type: ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES,
    organization__slug,
  });
  const medicationsHref = getOrganizationBundlesHref({
    bundle_type: ORGANIZATION_BUNDLE_TYPE_MEDICATIONS,
    organization__slug,
  });
  const policiesHref = getOrganizationPoliciesHref({ organization__slug });
  const patientsHref = getOrganizationPatientsHref({ organization__slug });
  return {
    proceduresAndConsumablesHref,
    medicationsHref,
    policiesHref,
    patientsHref,
  };
};

export default function OrganizationAgreementSummary() {
  const { data, organization__slug, isLoading } =
    useFetchOrganizationAgreement();
  const hasAdminPriviledges = useSelector(selectorAuthHasAdminPrivileges);
  const hasSubmitterPrivileges = useSelector(
    selectorAuthHasSubmitterPrivileges
  );
  const { organization_agreement_related_object_counts } = data || {};
  const {
    num_procedures_and_consumables,
    num_medications,
    num_policies,
    num_patients,
  } = organization_agreement_related_object_counts || {};
  const {
    proceduresAndConsumablesHref,
    medicationsHref,
    policiesHref,
    patientsHref,
  } = getClaimSubmissionOrganizationSummariesHrefs({ organization__slug });

  const summaries = [
    {
      title: ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_TITLE,
      primary: getIntOrFloat(num_procedures_and_consumables || "0"),
      action: (
        <CustomSummaryCardAction
          href={proceduresAndConsumablesHref}
          hasEditAccess={hasAdminPriviledges && hasSubmitterPrivileges}
        />
      ),
      isLoading,
    },
    {
      title: ORGANIZATION_BUNDLE_MEDICATIONS_TITLE,
      primary: getIntOrFloat(num_medications || "0"),
      action: (
        <CustomSummaryCardAction
          href={medicationsHref}
          hasEditAccess={hasAdminPriviledges && hasSubmitterPrivileges}
        />
      ),
      isLoading,
    },
    {
      title: ORGANIZATION_POLICIES_TITLE,
      primary: getIntOrFloat(num_policies || "0"),
      action: (
        <CustomSummaryCardAction
          href={policiesHref}
          hasEditAccess={hasSubmitterPrivileges}
        />
      ),
      isLoading,
    },
    {
      title: ORGANIZATION_PATIENTS_TITLE,
      primary: getIntOrFloat(num_patients || "0"),
      action: (
        <CustomSummaryCardAction
          href={patientsHref}
          hasEditAccess={hasSubmitterPrivileges}
        />
      ),
      isLoading,
    },
  ];

  return <CustomSummaryCards summaries={summaries} />;
}
