import React from "react";
import Backdrop from "@mui/material/Backdrop";
import { AVEY_WHITE_LIGHT } from "@styles/theme";
import CustomBox from "./CustomBox";

const Animation = ({ dimension = "40px" }) => (
  <img
    src="/assets/animations/avey-loading-animation.gif"
    alt="Loading..."
    width={dimension}
  />
);

export default function CustomCircularProgress({
  position = "absolute",
  invisible = true,
  open = true,
  style = {},
  dimension = "40px",
  ...props
}) {
  if (!open) return null;

  return position === "absolute" ? (
    <Backdrop
      invisible={invisible}
      sx={{
        height: "inherit",
        minHeight: "inherit",
        position: position,
        zIndex: 10,
        borderRadius: "inherit",
      }}
      style={{ backgroundColor: !invisible && AVEY_WHITE_LIGHT, ...style }}
      variant="primary"
      open={true}
    >
      <Animation dimension={dimension} />
    </Backdrop>
  ) : (
    <CustomBox textAlign="center" {...props}>
      <Animation dimension={dimension} />
    </CustomBox>
  );
}
