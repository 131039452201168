export const ORGANIZATION_CARD_ACTION_MAKE_PRIMARY =
  "ORGANIZATION_CARD_ACTION_MAKE_PRIMARY";
export const ORGANIZATION_CARD_ACTION_DEACTIVATE =
  "ORGANIZATION_CARD_ACTION_DEACTIVATE";
export const ORGANIZATION_CARD_ACTION_REPLACE =
  "ORGANIZATION_CARD_ACTION_REPLACE";

export const ORGANIZATION_CARD_STATUS_VALID = "V";
export const ORGANIZATION_CARD_STATUS_EXPIRED = "E";
export const ORGANIZATION_CARD_STATUS_INVALID = "I";
export const ORGANIZATION_CARD_STATUS_DISABLED = "D";

export const ORGANIZATION_CARD_STATUS_MAPPER = {
  [ORGANIZATION_CARD_STATUS_VALID]: "Valid",
  [ORGANIZATION_CARD_STATUS_EXPIRED]: "Expired",
  [ORGANIZATION_CARD_STATUS_INVALID]: "Invalid",
  [ORGANIZATION_CARD_STATUS_DISABLED]: "Disabled",
};

export const cardTokenEmptyFormData = {
  cardNumber: "",
  cardHolder: "",
  cardExpiryMonth: "",
  cardExpiryYear: "",
  cardCVC: "",
};

export const cardTokenErrorEmptyFormData = {
  cardNumber: "",
  cardHolder: "",
  cardExpiry: "",
  cardCVC: "",
};

export const cardPaymentMethodEmptyFormData = {
  card_token_id: null,
  card_first_6_digits: null,
  organization_payment_id: null,
  processor_url: null,
};

export const anonymizeCardNumber = (card_last_4_digits) =>
  `•••• ${card_last_4_digits}`;
export const formatCardNumber = (cardNumber) =>
  cardNumber?.match(/.{1,4}/g)?.join(" ") || "";
export const unformatCardNumber = (cardNumber) =>
  cardNumber?.replace(/\s+/g, "") || "";

const isValidCardNumber = (cardNumber) =>
  /^\d{16}$/.test(unformatCardNumber(cardNumber));
const isValidCVC = (cvc) => /^\d{3,4}$/.test(cvc);
const isValidExpiry = (month, year) =>
  /^\d{2}$/.test(month) &&
  /^\d{2}$/.test(year) &&
  parseInt(month, 10) >= 1 &&
  parseInt(month, 10) <= 12;

export function validateCardFormData(formData) {
  let output = {};
  const { cardNumber, cardExpiryMonth, cardExpiryYear, cardCVC } = formData;

  !isValidCardNumber(cardNumber) &&
    (output["cardNumber"] = "Invalid card number");
  !isValidCVC(cardCVC) && (output["cardCVC"] = "Invalid CVC");
  !isValidExpiry(cardExpiryMonth, cardExpiryYear) &&
    (output["cardExpiry"] = "Invalid expiry date");

  return output;
}
