import React from "react";
import UserManagementEditProfile from "./UserManagementEditProfile";
import UserManagementDeactivateAccount from "./UserManagementDeactivateAccount";
import UserManagementReinviteUser from "./UserManagementReinviteUser";
import {
  USER_MANAGEMENT_ACTION_DEACTIVATE_ACCOUNT,
  USER_MANAGEMENT_ACTION_EDIT_PROFILE,
  USER_MANAGEMENT_ACTION_REINVITE_USER,
} from "@constants/users/users";

export default function UserManagementAction({ state, setState = () => {} }) {
  const { object, action, isOpen } = state;
  const handleClose = React.useCallback(
    (e) => {
      e?.preventDefault();
      setState((prev) => ({ ...prev, isOpen: false }));
    },
    [setState]
  );

  switch (action) {
    case USER_MANAGEMENT_ACTION_EDIT_PROFILE:
      return (
        <UserManagementEditProfile
          isOpen={isOpen}
          object={object}
          handleClose={handleClose}
        />
      );

    case USER_MANAGEMENT_ACTION_DEACTIVATE_ACCOUNT:
      return (
        <UserManagementDeactivateAccount
          isOpen={isOpen}
          object={object}
          handleClose={handleClose}
        />
      );

    case USER_MANAGEMENT_ACTION_REINVITE_USER:
      return (
        <UserManagementReinviteUser
          isOpen={isOpen}
          object={object}
          handleClose={handleClose}
        />
      );

    default:
      return null;
  }
}
