import React from "react";
import CustomLoader from "@components/Custom/CustomLoader";
import CustomPillButton from "@components/Custom/CustomPillButton";
import CustomSection from "@components/Custom/CustomSection";
import {
  CLAIM_DEMO_SUBJECTIVE_SYMPTOMS_TITLE,
  selectorClaimDemoHasOrganizationPatient,
  selectorClaimDemoHasSubjectiveSymptoms,
  selectorClaimDemoIsSessionDone,
  selectorClaimDemoSubjectiveSummary,
  useClaimDemoContext,
} from "../context";
import ClaimDemoSummarySharedContent from "./ClaimDemoSummarySharedContent";

export default function ClaimDemoSubjectiveSummary() {
  const { state, handleUpdateSummary, handleUpdateSubjectiveSummary } =
    useClaimDemoContext();
  const hasOrganizationPatient = selectorClaimDemoHasOrganizationPatient(state);
  const hasSubjectiveSymptoms = selectorClaimDemoHasSubjectiveSymptoms(state);
  const isSessionDone = selectorClaimDemoIsSessionDone(state);
  const subjective = selectorClaimDemoSubjectiveSummary(state);
  const { isLoading } = subjective;

  return (
    <CustomSection
      title={CLAIM_DEMO_SUBJECTIVE_SYMPTOMS_TITLE}
      isOpenDefault={hasOrganizationPatient && isSessionDone}
      isCollapsable={false}
      action={
        isSessionDone &&
        (isLoading ? (
          <CustomLoader label="Generating..." />
        ) : (
          hasSubjectiveSymptoms && (
            <CustomPillButton
              label="Generate"
              onClick={handleUpdateSubjectiveSummary}
            />
          )
        ))
      }
    >
      <ClaimDemoSummarySharedContent
        name="subjective"
        object={subjective}
        handleUpdateSummary={handleUpdateSummary}
      />
    </CustomSection>
  );
}
