import { getOptions } from "@helpers/hooks/objects";

export const UNIT_TYPE_CONCENTRATION = "C";
export const UNIT_TYPE_DOSAGE_FORM = "D";
export const UNIT_TYPE_PACKAGE_SIZE = "P";
export const UNIT_TYPE_PACKAGE_TYPE = "T";

export const UNIT_TYPE_MAPPER = {
  [UNIT_TYPE_CONCENTRATION]: "Concentration",
  [UNIT_TYPE_DOSAGE_FORM]: "Dosage Form",
  [UNIT_TYPE_PACKAGE_SIZE]: "Package Size",
  [UNIT_TYPE_PACKAGE_TYPE]: "Package Type",
};

export const UNIT_TYPE_OPTIONS = getOptions(UNIT_TYPE_MAPPER);
