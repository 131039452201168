import { v4 as uuidv4 } from "uuid";
import {
  SYMPTOM_STATUS_INITIAL_MAPPER,
  SYMPTOM_STATUS_PRESENT,
  SYMPTOM_TYPE_SUBJECTIVE,
} from "@constants/avey-cowriter";

export function mergeLists(list1, list2) {
  const merged = new Map();

  list1.forEach((item) => merged.set(item.id, item));
  list2.forEach((item) => merged.set(item.id, item));

  return Array.from(merged.values());
}

export function mergeAndOverrideSymptoms(prevSymptoms, payload) {
  const merged = new Map();

  prevSymptoms.forEach((item) => merged.set(item.id, item));
  payload
    ?.map(({ id, name, status, is_searchable, classification }) => {
      const prevSymptom = prevSymptoms.find((s) => s.id === id);
      return {
        _id: uuidv4(),
        id,
        name,
        status: SYMPTOM_STATUS_INITIAL_MAPPER[status],
        type: classification,
        is_chief: prevSymptom && status === true ? prevSymptom.is_chief : false,
        is_searchable,
      };
    })
    .forEach((item) => merged.set(item.id, item));

  const list3 = Array.from(merged.values());
  const hasChiefFinding = list3.find(
    ({ type, is_chief }) => type === SYMPTOM_TYPE_SUBJECTIVE && is_chief
  );
  return hasChiefFinding
    ? list3
    : list3.map((symptom) => {
        const { type, is_searchable, status } = symptom;
        return {
          ...symptom,
          is_chief:
            type === SYMPTOM_TYPE_SUBJECTIVE &&
            status === SYMPTOM_STATUS_PRESENT &&
            is_searchable,
        };
      });
}

export function concatenateFromLastQuestionMark(strings, delimiter = " ") {
  // Initialize a variable to store the index of the last question mark
  let lastIndex = -1;

  // Find the last index where the string contains a question mark
  strings.forEach((str, index) => {
    if (str.includes("?")) {
      lastIndex = index;
    }
  });

  // Determine the slice of the array to concatenate
  if (lastIndex !== -1) {
    // Concatenate from the last question mark found to the end
    return strings.slice(lastIndex).join(delimiter);
  } else {
    // If no question mark was found, concatenate the entire array
    return strings.join(delimiter);
  }
}
