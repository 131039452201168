import React from "react";
import Typography from "@mui/material/Typography";

export default function CustomPillButton({
  label = "",
  className = "blueBg",
  ...props
}) {
  return (
    <div style={{ minWidth: "max-content" }}>
      <Typography
        color="white"
        variant="body2"
        className={`${className} button opacityOnHover-50`}
        px={2}
        py={0.5}
        borderRadius={4}
        width="max-content"
        noWrap
        {...props}
      >
        {label}
      </Typography>
    </div>
  );
}
