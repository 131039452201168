import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import { CustomExternalLink } from "@components/Custom/CustomLink";
import CustomPie from "@components/Custom/CustomPie";
import CustomSection from "@components/Custom/CustomSection";
import { parseTimestamp } from "@helpers/parse/time";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { AVEY_BLUE, AVEY_RED } from "@styles/theme";
import ClaimSubmissionUploadDataExceptionJson from "./ClaimSubmissionUploadDataExceptionJson";

export default function ClaimSubmissionUploadData({ object = {} }) {
  const {
    submitter_user,
    file,
    preprocessing_time_start,
    preprocessing_time_end,
    exceptions_json,
    num_claims,
    num_claims_created_success,
    num_claims_created_failure,
  } = object || {};

  const { first_name, last_name } = submitter_user;
  const name = `${first_name} ${last_name}`.trim();
  const timeStarted = parseTimestamp({
    value: preprocessing_time_start,
    useSeconds: true,
  });
  const timeEnded = parseTimestamp({
    value: preprocessing_time_end,
    useSeconds: true,
  });
  const hasClaims = num_claims > 0;
  const hasExceptions = Boolean(exceptions_json) && exceptions_json?.length > 0;
  const data = [
    {
      value: num_claims_created_success,
      color: AVEY_BLUE,
      label: "Uploaded",
    },
    {
      value: num_claims_created_failure,
      color: AVEY_RED,
      label: "Failed to upload",
    },
  ].filter(({ value }) => value > 0);

  return (
    <Stack spacing={3}>
      <CustomSection title="Upload summary" isMinified={true}>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomGrid>
            <CustomGrid>
              <CustomLabeledText label="Submitted by" value={name} />
              <CustomLabeledText label="Uploaded file" useChildren={true}>
                <CustomExternalLink
                  href={file}
                  label="Download file"
                  wrapperProps={{ download: true }}
                  isTypography={true}
                />
              </CustomLabeledText>
            </CustomGrid>
            <CustomGrid>
              <CustomLabeledText label="Time started" value={timeStarted} />
              <CustomLabeledText label="Time ended" value={timeEnded} />
            </CustomGrid>
          </CustomGrid>
          {hasClaims && (
            <CustomPie
              data={data}
              caption="Claims"
              value={num_claims}
              unit=""
              useWholePie={true}
            />
          )}
        </Stack>
      </CustomSection>
      {hasExceptions && (
        <CustomSection title="Errors" color="red" isMinified={true}>
          <Stack spacing={4}>
            {exceptions_json.map((exception_json, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <Divider />}
                <ClaimSubmissionUploadDataExceptionJson
                  exception_json={exception_json}
                />
              </React.Fragment>
            ))}
          </Stack>
        </CustomSection>
      )}
    </Stack>
  );
}
