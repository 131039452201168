import React from "react";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import useFetchClaimSubmission from "@helpers/hooks/useFetchObjects/useFetchClaimSubmission";
import ClaimsSubmissionBody from "./ClaimSubmissionBody";
import ClaimSubmissionHeader from "./ClaimSubmissionHeader";

export default function ClaimSubmission() {
  const { data, hasData, isLoading } = useFetchClaimSubmission();

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  return (
    <>
      <ClaimSubmissionHeader object={data} />
      <CustomPage mt={0}>
        <ClaimsSubmissionBody object={data} />
      </CustomPage>
    </>
  );
}
