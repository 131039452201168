import React from "react";
import CustomChip from "@components/Custom/CustomChip";
import CustomDividedList from "@components/Custom/CustomDividedList";

export default function OrganizationBundleProceduresAndConsumablesSummary({
  organization_bundle_cpts = [],
  organization_bundle_consumables = [],
  divideAt = 1,
}) {
  const list = [
    organization_bundle_cpts.map(({ cpt, frequency }) => ({
      name: `${frequency} x ${cpt.code}`,
      title: cpt.description,
      color: "lightYellow",
    })),
    organization_bundle_consumables.map(({ consumable, frequency }) => ({
      name: `${frequency} x ${consumable.name}`,
      title: consumable.description,
      color: "grey",
    })),
  ].flat();

  return (
    <CustomDividedList
      list={list}
      divideAt={divideAt}
      TooltipObjectComponent={CustomChip}
    />
  );
}
