import React from "react";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomStatusTag from "@components/Custom/CustomStatusTag";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import {
  EXPORT_STATUS_PROPS_MAPPER,
  EXPORT_STATUS_TEXT_MAPPER,
} from "@constants/static/export";
import { parseTimestamp } from "@helpers/parse/time";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ConfigurationsBulkExportData from "./ConfigurationsBulkExportData";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { name, status } = object;

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <CustomStatusTag
        status={status}
        textMapper={EXPORT_STATUS_TEXT_MAPPER}
        propsMapper={EXPORT_STATUS_PROPS_MAPPER}
      />
      <Typography variant="body2" fontWeight="bold" className="ellipsis-1">
        {name || "Export"}
      </Typography>
    </Stack>
  );
};

const ConfigurationsBulkExportTitle = ({ object, variant = "h3" }) => {
  const { name, status, time_created } = object;
  const timeCreated = parseTimestamp({ value: time_created });
  return (
    <CustomHeaderTitle
      title={name || "Export"}
      subtitle={timeCreated}
      variant={variant}
    >
      <CustomStatusTag
        status={status}
        textMapper={EXPORT_STATUS_TEXT_MAPPER}
        propsMapper={EXPORT_STATUS_PROPS_MAPPER}
      />
    </CustomHeaderTitle>
  );
};

export default function ConfigurationsBulkExportHistory({
  drawerProps = {},
  useQueryFn = () => {},
  useQueryFnProps = {},
  describeFilters = () => {},
}) {
  return (
    <CustomVersionHistory
      menuItemLabel="View export history"
      drawerProps={drawerProps}
      useQueryFn={useQueryFn}
      useQueryFnProps={{
        sortQuery: "-time_updated",
        ...useQueryFnProps,
      }}
      VersionHistoryObjectDataComponent={ConfigurationsBulkExportData}
      versionHistoryObjectDataComponentProps={{ describeFilters }}
      VersionHistoryObjectTitleComponent={ConfigurationsBulkExportTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
