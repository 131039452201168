import React from "react";
import OrganizationAgreements from "@components/Authenticated/Configurations/OrganizationAgreements/OrganizationAgreements";
import { Navigate, Route, Routes } from "react-router-dom";
import InsuranceOrganizationConfigurationsRoutes from "./insurance-organization-configurations-routes";

export default function InsuranceConfigurationsRoutes() {
  return (
    <Routes>
      <Route path="" Component={OrganizationAgreements} />
      <Route
        path=":organization__slug/*"
        Component={InsuranceOrganizationConfigurationsRoutes}
      />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
