import React from "react";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";

export default function ClaimEvaluationResultLink({
  to,
  value = "",
  title = "",
  ...props
}) {
  const [isClick, setIsClick] = React.useState(false);

  const handleClick = () => setIsClick(new Date());

  React.useEffect(() => {
    if (isClick) {
      const element = document.getElementById(to);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        element.classList.add("elevated-16");
        const timeout = setTimeout(() => {
          element.classList.remove("elevated-16");
          setIsClick(false);
        }, 1000);
        return () => {
          clearTimeout(timeout);
          element.classList.remove("elevated-16");
          setIsClick(false);
        };
      }
    }
  }, [isClick, to]);

  return (
    <CustomTypographyButton
      value={value}
      display="inline"
      onClick={handleClick}
      title={title}
      fontWeight="normal"
      {...props}
    />
  );
}
