import React from "react";
import IconButton from "@mui/material/IconButton";
import CustomBox from "@components/Custom/CustomBox";
import { ArrowCenterRightIcon } from "@rimads/assets";
import { AVEY_BLACK } from "@styles/theme";
import { useRotateClassName } from "@helpers/hooks/useRotateClassName";

const iconTopPosition = 80;

export default function DrawerFixedIcon({
  handleIsOpen = () => {},
  isOpen,
  drawerWidthClosed,
  drawerWidthOpen,
}) {
  const iconLeftPosition = (isOpen ? drawerWidthOpen : drawerWidthClosed) - 14;
  const rotateClassName = useRotateClassName({ isOpen });

  return (
    <CustomBox
      zIndex={(theme) => theme.zIndex.drawer + 1}
      position="fixed"
      left={iconLeftPosition}
      top={iconTopPosition}
    >
      <IconButton className="elevated-8 whiteBg" onClick={handleIsOpen}>
        <ArrowCenterRightIcon
          primary_fill={AVEY_BLACK}
          className={rotateClassName}
        />
      </IconButton>
    </CustomBox>
  );
}
