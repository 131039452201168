import React from "react";
import CustomActionDialogTitle from "@components/Custom/CustomActionDialog/CustomActionDialogTitle";

export default function AccountActionDialogTitle({
  title,
  fullScreen = false,
  user = {},
}) {
  const { image } = user || {};

  return (
    <CustomActionDialogTitle
      image={image}
      title={title}
      fullScreen={fullScreen}
      variant=""
    />
  );
}
