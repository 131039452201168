import React from "react";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import { MODEL_TYPE_MEDICATION } from "@constants/static/globals";
import { getMedicationUploadsHref } from "@constants/static/routing";
import MedicationCreateForm from "../Medication/MedicationForms/MedicationCreateForm";

export default function MedicationsCreateForms() {
  const uploadHistoryHref = getMedicationUploadsHref({});
  return (
    <CustomAddOrUploadWrapper
      label="Add medications"
      uploadHistoryHref={uploadHistoryHref}
      model_type={MODEL_TYPE_MEDICATION}
    >
      <MedicationCreateForm />
    </CustomAddOrUploadWrapper>
  );
}
