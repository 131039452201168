import React from "react";
import { CustomQueryParams } from "@components/Custom/CustomAppliedFilters";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import { CustomExternalLink } from "@components/Custom/CustomLink";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { parseTimestamp } from "@helpers/parse/time";
import Stack from "@mui/material/Stack";

export default function ConfigurationsBulkExportData({
  object = {},
  describeFilters = () => {},
}) {
  const {
    time_start,
    time_end,
    requesting_user,
    file,
    query_params,
    exception_str,
    num_instances,
  } = object || {};

  const timeStart = parseTimestamp({ value: time_start, useSeconds: true });
  const timeEnd = parseTimestamp({ value: time_end, useSeconds: true });
  const numInstances = getIntOrFloat(num_instances);
  const hasFile = Boolean(file);
  const hasError = Boolean(exception_str);
  const full_name =
    `${requesting_user?.first_name} ${requesting_user?.last_name}`?.trim();

  return (
    <Stack spacing={4}>
      <CustomGrid>
        <CustomGrid>
          <CustomLabeledText label="Requesting user" value={full_name} />
          <CustomLabeledText
            wrapperProps={{ height: "inherit" }}
            label="Matches"
            value={numInstances}
          />
          {hasFile && (
            <CustomLabeledText
              label="Generated file"
              useChildren={true}
              wrapperProps={{ height: "inherit" }}
            >
              <CustomExternalLink
                href={file}
                label="Download file"
                wrapperProps={{ download: true }}
                isTypography={true}
              />
            </CustomLabeledText>
          )}
        </CustomGrid>
        {(timeStart || timeEnd) && (
          <CustomGrid>
            {timeStart && (
              <CustomLabeledText label="Time started" value={timeStart} />
            )}
            {timeEnd && (
              <CustomLabeledText label="Time ended" value={timeEnd} />
            )}
          </CustomGrid>
        )}
      </CustomGrid>
      <CustomGrid>
        <CustomQueryParams
          query_params={query_params}
          describeFilters={describeFilters}
        />
        {hasError && <CustomLabeledText label="Error" value={exception_str} />}
      </CustomGrid>
    </Stack>
  );
}
