import React from "react";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import {
  CLAIM_SECTION_ICD_TEXT_FIELDNAME_LABEL_MAPPER,
  CLAIM_SECTION_SYMPTOMS,
} from "@constants/claims/claims";
import Typography from "@mui/material/Typography";
import CustomNotProvidedText from "@components/Custom/CustomNotProvidedText";

export default function ClaimIcdsTextData({
  type = CLAIM_SECTION_SYMPTOMS,
  claim_icds_text = "",
}) {
  const label = CLAIM_SECTION_ICD_TEXT_FIELDNAME_LABEL_MAPPER[type];
  const hasClaimIcdsText = Boolean(claim_icds_text);

  return (
    <CustomListItemCollapse primary={label}>
      {hasClaimIcdsText ? (
        <Typography variant="body2">{claim_icds_text}</Typography>
      ) : (
        <CustomNotProvidedText title={label} />
      )}
    </CustomListItemCollapse>
  );
}
