import React from "react";
import CustomInputAdornment from "@components/Custom/CustomInputAdornment";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";

export default function OrganizationAgreementFollowupDiscountFormData({
  formData = {},
  setFormData = () => {},
}) {
  const { followup_consultation_num_days } = formData;
  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  return (
    <CustomOutlinedTextField
      label="Follow-up discount eligibility"
      value={followup_consultation_num_days}
      name="followup_consultation_num_days"
      onChange={handleChange}
      type="number"
      inputProps={{ min: 0, max: 365, step: 1 }}
      InputProps={{
        endAdornment: <CustomInputAdornment value="days" />,
      }}
    />
  );
}
