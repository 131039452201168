import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import { USER_ORGANIZATION_ROLE_OPTIONS } from "@constants/users/users";
import Grid from "@mui/material/Grid";

export default function AccountInformationFormData({
  errors = {},
  formData,
  setFormData = () => {},
  withRole = false,
}) {
  const {
    first_name,
    last_name,
    email,
    role,
    //  phone_number,
  } = formData;
  const {
    first_name: firstNameError,
    last_name: lastNameError,
    role: roleError,
  } = errors;

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomOutlinedTextField
            required
            label="Name"
            name="first_name"
            value={first_name}
            helperText={firstNameError}
            error={Boolean(firstNameError)}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomOutlinedTextField
            required
            label="Last name"
            name="last_name"
            value={last_name}
            helperText={lastNameError}
            error={Boolean(lastNameError)}
            onChange={handleChange}
          />
        </Grid>
        {withRole && (
          <Grid item xs={12}>
            <CustomOutlinedTextField
              select
              required
              label="Role"
              name="role"
              value={role}
              helperText={roleError}
              error={Boolean(roleError)}
              options={USER_ORGANIZATION_ROLE_OPTIONS}
              onChange={handleChange}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomLabeledText
            label="Email"
            value={email}
            typographyProps={{ color: "textSecondary" }}
          />
        </Grid>
        {/* {phone_number && (
        <Grid item xs={12}>
        <CustomLabeledText
          label="Mobile number"
          value={phone_number}
          typographyProps={{ color: "textSecondary" }}
        />
        </Grid>
      )} */}
      </Grid>
    </div>
  );
}
