import { getOptions } from "@helpers/hooks/objects";

export const ICD_TYPE_10_CM = "ICD10-CM";
export const ICD_TYPE_10_AM = "ICD10-AM";

const ICD_TYPE_MAPPER = {
  [ICD_TYPE_10_CM]: "ICD 10CM",
  [ICD_TYPE_10_AM]: "ICD 10AM",
};

export const ICD_TYPE_OPTIONS = getOptions(ICD_TYPE_MAPPER);
