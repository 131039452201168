import React from "react";
import ConsumableAutocomplete from "@components/Custom/CustomAutocomplete/ConsumableAutocomplete";
import CustomButton from "@components/Custom/CustomButton";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import CustomSection, {
  customSectionBodyPadding,
} from "@components/Custom/CustomSection";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import { emptyOrganizationBundleConsumableFormData } from "@constants/organizations/organization-bundles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

export default function OrganizationBundleConsumablesFormData({
  formData = [],
  handleChange = () => {},
}) {
  const hasConsumables = formData.length > 0;
  const handleAddOrganizationBundleConsumable = () =>
    handleChange([...formData, emptyOrganizationBundleConsumableFormData]);

  const handleChangeOrganizationBundleConsumable = ({ index, payload }) =>
    handleChange(
      formData.map((bundleCpt, position) =>
        position === index ? payload : bundleCpt
      )
    );

  const handleRemoveOrganizationBundleConsumable = ({ index }) =>
    handleChange(formData.filter((_, position) => position !== index));

  return (
    <CustomSection
      title="Consumables"
      isCollapsable={false}
      bodyProps={{ p: 0 }}
    >
      {formData.map((bundleConsumableFormData, index) => (
        <div key={index}>
          {index !== 0 && <Divider />}
          <Box p={customSectionBodyPadding}>
            <OrganizationBundleConsumableFormData
              index={index}
              formData={bundleConsumableFormData}
              handleChangeOrganizationBundleConsumable={
                handleChangeOrganizationBundleConsumable
              }
              handleRemoveOrganizationBundleConsumable={
                handleRemoveOrganizationBundleConsumable
              }
            />
          </Box>
        </div>
      ))}
      {hasConsumables && <Divider />}
      <Box p={customSectionBodyPadding}>
        <CustomTypographyButton
          value="+ Add consumable"
          onClick={handleAddOrganizationBundleConsumable}
        />
      </Box>
    </CustomSection>
  );
}

function OrganizationBundleConsumableFormData({
  index,
  formData,
  handleChangeOrganizationBundleConsumable = () => {},
  handleRemoveOrganizationBundleConsumable = () => {},
}) {
  const { consumable, frequency } = formData;

  const handleRemoveSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleRemoveOrganizationBundleConsumable({ index });
  };

  const handleChangeConsumable = (newValue) =>
    handleChangeOrganizationBundleConsumable({
      index,
      payload: { ...formData, consumable: newValue },
    });

  const handleChangeFrequency = (e) =>
    handleChangeOrganizationBundleConsumable({
      index,
      payload: { ...formData, frequency: e.target.value },
    });

  return (
    <Grid container columns={9} spacing={3} alignItems="flex-end">
      <Grid item xs={12} sm={6}>
        <ConsumableAutocomplete
          value={consumable}
          wrapperProps={{ flex: 1 }}
          handleChange={handleChangeConsumable}
          textFieldProps={{ required: true }}
        />
      </Grid>
      <Grid item xs>
        <CustomOutlinedTextField
          required
          label="Frequency"
          name="frequency"
          value={frequency}
          onChange={handleChangeFrequency}
          wrapperProps={{ flex: 1 }}
          type="number"
          inputProps={{ min: 1, step: 1 }}
        />
      </Grid>
      <Grid item>
        <CustomButton
          icon="CloseIcon"
          variant="outlined"
          isSquare={true}
          useIconAsLabel={true}
          onClick={handleRemoveSubmit}
        />
      </Grid>
    </Grid>
  );
}
