import React from "react";
import CustomButton from "@components/Custom/CustomButton";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import CustomNoResultsFound from "@components/Custom/CustomNoResultsFound";
import { INSURANCE_CONFIGURATIONS_FULL_HREF } from "@constants/static/routing";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  selectorAuthHasAdminPrivileges,
  selectorAuthHasSubmitterPrivileges,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export default function OrganizationsNoResults() {
  const hasSubmitterPrivileges = useSelector(
    selectorAuthHasSubmitterPrivileges
  );
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);

  const subtitle = hasSubmitterPrivileges ? (
    <Stack spacing={0.5}>
      <Typography variant="body2">
        {`You have no partner insurers yet.`}
      </Typography>
      {hasAdminPrivileges && (
        <Typography variant="body2" fontWeight="bold">
          {"Start by editing your partner insurers."}
        </Typography>
      )}
    </Stack>
  ) : (
    "You have no affiliations yet."
  );

  const disableSubtitleTypography = hasSubmitterPrivileges;

  const children = hasAdminPrivileges && hasSubmitterPrivileges && (
    <NavLink to={INSURANCE_CONFIGURATIONS_FULL_HREF}>
      <CustomButton
        label="Edit your partner insurers"
        icon="EditIcon"
        fullWidth
      />
    </NavLink>
  );

  return (
    <CustomPage mt="200px">
      <CustomNoResultsFound
        src="/assets/images/placeholders/no-results.png"
        wrapperProps={{ spacing: 2, textAlign: "center" }}
        subtitle={subtitle}
        children={children}
        disableSubtitleTypography={disableSubtitleTypography}
      />
    </CustomPage>
  );
}
