import React from "react";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import { MODEL_TYPE_CONSUMABLE } from "@constants/static/globals";
import { getConsumableUploadsHref } from "@constants/static/routing";
import ConsumableCreateForm from "../Consumable/ConsumableForms/ConsumableCreateForm";

export default function ConsumablesCreateForms() {
  const uploadHistoryHref = getConsumableUploadsHref({});
  return (
    <CustomAddOrUploadWrapper
      label="Add consumables"
      uploadHistoryHref={uploadHistoryHref}
      model_type={MODEL_TYPE_CONSUMABLE}
    >
      <ConsumableCreateForm />
    </CustomAddOrUploadWrapper>
  );
}
