export const INSTANCE_CORNEAL_ABRASION = {
  id: 93,
  name: "Corneal Abrasion #1",
};
export const INSTANCE_CORNEAL_LACERATION = {
  id: 488,
  name: "Corneal Laceration",
};
export const INSTANCE_SCIATICA = {
  id: 380,
  name: "Sciatica",
};
