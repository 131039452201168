import React from "react";
import {
  ErrorIconVariant,
  SuccessIconVariant,
} from "@components/SnackbarProvider/CustomSnackbarContent";
import CustomAvatar from "./CustomAvatar";

export default function CustomIconedAvatar({
  src,
  isErrorVariant = false,
  isSuccessVariant = false,
}) {
  const IconComponent =
    (isErrorVariant && ErrorIconVariant) ||
    (isSuccessVariant && SuccessIconVariant) ||
    null;
  return (
    <div style={{ position: "relative" }}>
      <CustomAvatar
        src={src}
        className="elevated-8"
        style={{ borderRadius: 4, height: 24, width: 24 }}
      />
      {Boolean(IconComponent) && (
        <IconComponent
          dimension={12}
          style={{ position: "absolute", bottom: -6, left: 6 }}
        />
      )}
    </div>
  );
}
