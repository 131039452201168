import { ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES } from "@constants/organizations/organization-bundles";
import {
  ORGANIZATION_PATIENT_VISIT_TYPE_SHORTHAND_OPTIONS,
  ORGANIZATION_PATIENT_VISIT_TYPE_SHORTHAND_TEXT_MAPPER,
} from "@constants/organizations/organization-patients";
import {
  searchQueryDescribeFilter,
  TIME_DELETED_ISNULL_FILTER,
  TIME_UPDATED_FILTER,
  timeDeletedIsNullApplyFilter,
  timeDeletedIsNullDescribeFilter,
  timeUpdatedApplyFilter,
  timeUpdatedDescribeFilter,
} from "@constants/static/export";
import { getIntOrFloat } from "@helpers/parse/numbers";

export const initialFilterState = {
  visit_type__in: [],
  undiscounted_price: ["", ""],
  inpatient_discount_rate: ["", ""],
  outpatient_discount_rate: ["", ""],
  is_preauthorization_required: [],
  is_consultation_procedure: [],
  is_second_procedure_discount: [],
  is_standalone: [],
  time_updated: [null, null],
  time_deleted__isnull: [],
};

export const getFilterOptions = ({ bundle_type, currency }) => {
  const filterOptions = {
    visit_type__in: {
      title: "Visit type",
      type: "option",
      options: ORGANIZATION_PATIENT_VISIT_TYPE_SHORTHAND_OPTIONS,
    },
    undiscounted_price: {
      title: "Undiscounted price",
      type: "range",
      unit: currency,
    },
    inpatient_discount_rate: { title: "IP discount", type: "range", unit: "%" },
    outpatient_discount_rate: {
      title: "OP discount",
      type: "range",
      unit: "%",
    },
    is_preauthorization_required: {
      title: "Preauthorization required",
      type: "boolean",
      options: [
        { key: "true", value: "Required" },
        { key: "false", value: "Not required" },
      ],
    },
    is_consultation_procedure: {
      title: "Consultation procedure",
      type: "boolean",
      options: [
        { key: "true", value: "Yes" },
        { key: "false", value: "No" },
      ],
    },
    is_second_procedure_discount: {
      title: "Second procedure discount",
      type: "boolean",
      options: [
        { key: "true", value: "Yes" },
        { key: "false", value: "No" },
      ],
    },
    is_standalone: {
      title: "Bundle",
      type: "boolean",
      options: [
        { key: "true", value: "No" },
        { key: "false", value: "Yes" },
      ],
    },
    time_updated: TIME_UPDATED_FILTER,
    time_deleted__isnull: TIME_DELETED_ISNULL_FILTER,
  };

  if (bundle_type !== ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES) {
    [
      "is_consultation_procedure",
      "is_second_procedure_discount",
      "is_standalone",
    ].forEach((key) => delete filterOptions[key]);
  }

  return filterOptions;
};

export const formatFilters = (filters = {}) => {
  let formattedFilters = {};
  const {
    visit_type__in = [],
    undiscounted_price = ["", ""],
    inpatient_discount_rate = ["", ""],
    outpatient_discount_rate = ["", ""],
    is_preauthorization_required = [],
    is_consultation_procedure = [],
    is_second_procedure_discount = [],
    is_standalone = [],
    time_updated = [null, null],
    time_deleted__isnull = [],
  } = filters;

  if (visit_type__in.length > 0)
    formattedFilters["visit_type__in"] = visit_type__in.join(",");

  if (undiscounted_price[0])
    formattedFilters["undiscounted_price__gte"] = undiscounted_price[0];

  if (undiscounted_price[1])
    formattedFilters["undiscounted_price__lt"] = undiscounted_price[1];

  if (inpatient_discount_rate[0])
    formattedFilters["inpatient_discount_rate__gte"] =
      inpatient_discount_rate[0];

  if (inpatient_discount_rate[1])
    formattedFilters["inpatient_discount_rate__lt"] =
      inpatient_discount_rate[1];

  if (outpatient_discount_rate[0])
    formattedFilters["outpatient_discount_rate__gte"] =
      outpatient_discount_rate[0];

  if (outpatient_discount_rate[1])
    formattedFilters["outpatient_discount_rate__lt"] =
      outpatient_discount_rate[1];

  if (is_preauthorization_required[0])
    formattedFilters["is_preauthorization_required"] =
      is_preauthorization_required[0];

  if (is_consultation_procedure[0])
    formattedFilters["is_consultation_procedure"] =
      is_consultation_procedure[0];

  if (is_second_procedure_discount[0])
    formattedFilters["is_second_procedure_discount"] =
      is_second_procedure_discount[0];

  if (is_standalone[0]) formattedFilters["is_standalone"] = is_standalone[0];

  formattedFilters = timeUpdatedApplyFilter({
    formattedFilters,
    time_updated,
  });

  formattedFilters = timeDeletedIsNullApplyFilter({
    formattedFilters,
    time_deleted__isnull,
  });

  return formattedFilters;
};

export const describeFilters = ({
  searchQuery = "",
  filters = {},
  currency,
}) => {
  let formattedOutput = [];
  const {
    visit_type__in = "",
    undiscounted_price__gte = "",
    undiscounted_price__lt = "",
    inpatient_discount_rate__gte = "",
    inpatient_discount_rate__lt = "",
    outpatient_discount_rate__gte = "",
    outpatient_discount_rate__lt = "",
    is_preauthorization_required = "",
    is_consultation_procedure = "",
    is_second_procedure_discount = "",
    is_standalone = "",
    time_updated__gte = "",
    time_updated__lt = "",
    time_deleted__isnull = "",
  } = filters;

  formattedOutput = searchQueryDescribeFilter({ formattedOutput, searchQuery });

  if (visit_type__in?.length > 0) {
    const label = "Visit type";
    const values = visit_type__in
      ?.split(",")
      ?.map(
        (key) => ORGANIZATION_PATIENT_VISIT_TYPE_SHORTHAND_TEXT_MAPPER[key]
      );
    formattedOutput.push({ label, values });
  }

  if (undiscounted_price__gte || undiscounted_price__lt) {
    const label = "Undiscounted price";
    const from = getIntOrFloat(undiscounted_price__gte);
    const to = getIntOrFloat(undiscounted_price__lt);
    const unit = currency;
    if (from && to) formattedOutput.push({ label, from, to, unit });
    else if (from) formattedOutput.push({ label, from, unit });
    else if (to) formattedOutput.push({ label, to, unit });
  }

  if (inpatient_discount_rate__gte || inpatient_discount_rate__lt) {
    const label = "IP discount";
    const from = getIntOrFloat(inpatient_discount_rate__gte);
    const to = getIntOrFloat(inpatient_discount_rate__lt);
    const unit = "%";
    if (from && to) formattedOutput.push({ label, from, to, unit });
    else if (from) formattedOutput.push({ label, from, unit });
    else if (to) formattedOutput.push({ label, to, unit });
  }

  if (outpatient_discount_rate__gte || outpatient_discount_rate__lt) {
    const label = "OP discount";
    const from = getIntOrFloat(outpatient_discount_rate__gte);
    const to = getIntOrFloat(outpatient_discount_rate__lt);
    const unit = "%";
    if (outpatient_discount_rate__gte && outpatient_discount_rate__lt)
      formattedOutput.push({ label, from, to, unit });
    else if (outpatient_discount_rate__gte)
      formattedOutput.push({ label, from, unit });
    else if (outpatient_discount_rate__lt)
      formattedOutput.push({ label, to, unit });
  }

  if (is_preauthorization_required)
    formattedOutput.push({
      label: "Preauthorization",
      value:
        is_preauthorization_required === "true" ? "Required" : "Not required",
    });

  if (is_consultation_procedure)
    formattedOutput.push({
      label: "Procedure type",
      value:
        is_consultation_procedure === "true"
          ? "Consultation"
          : "Not a consultation",
    });

  if (is_second_procedure_discount)
    formattedOutput.push({
      label: "Second procedure discount",
      value: is_second_procedure_discount === "true" ? "Applicable" : "None",
    });

  if (is_standalone)
    formattedOutput.push({
      label: "Bundle status",
      value: is_standalone === "true" ? "Not a bundle" : "Is a bundle",
    });

  formattedOutput = timeUpdatedDescribeFilter({
    formattedOutput,
    time_updated__gte,
    time_updated__lt,
  });

  formattedOutput = timeDeletedIsNullDescribeFilter({
    formattedOutput,
    time_deleted__isnull,
  });

  return formattedOutput;
};
