import React from "react";
import ConsumableData from "@components/Authenticated/Configurations/Consumables/Consumable/Consumable/ConsumableData";
import ConsumableFormData from "@components/Authenticated/Configurations/Consumables/Consumable/ConsumableForms/ConsumableFormData";
import MedicationData from "@components/Authenticated/Configurations/Medications/Medication/MedicationData";
import MedicationFormData from "@components/Authenticated/Configurations/Medications/Medication/MedicationFormData";
import OrganizationBundleData from "@components/Authenticated/Configurations/OrganizationBundles/OrganizationBundle/OrganizationBundleData";
import OrganizationBundleFormData from "@components/Authenticated/Configurations/OrganizationBundles/OrganizationBundle/OrganizationBundleFormData";
import OrganizationClinicianData from "@components/Authenticated/Configurations/OrganizationClinicians/OrganizationClinician/OrganizationClinicianData";
import OrganizationClinicianFormData from "@components/Authenticated/Configurations/OrganizationClinicians/OrganizationClinician/OrganizationClinicianFormData";
import OrganizationPatientData from "@components/Authenticated/Configurations/OrganizationPatients/OrganizationPatient/OrganizationPatientData";
import OrganizationPatientFormData from "@components/Authenticated/Configurations/OrganizationPatients/OrganizationPatient/OrganizationPatientFormData";
import OrganizationPolicyData from "@components/Authenticated/Configurations/OrganizationPolicies/OrganizationPolicy/OrganizationPolicyData";
import OrganizationPolicyFormData from "@components/Authenticated/Configurations/OrganizationPolicies/OrganizationPolicy/OrganizationPolicyFormData";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import {
  MODEL_TYPE_CONSUMABLE,
  MODEL_TYPE_MEDICATION,
  MODEL_TYPE_ORGANIZATION_BUNDLE,
  MODEL_TYPE_ORGANIZATION_CLINICIAN,
  MODEL_TYPE_ORGANIZATION_PATIENT,
  MODEL_TYPE_ORGANIZATION_POLICY,
} from "@constants/static/globals";
import { capitalizeFirstLetter } from "@helpers/parse/string";
import HealthcareOrganizationActionDialogTitle from "../../HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import InsuranceOrganizationActionDialogTitle from "../../InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";

const ENTRY_FORMDATA_COMPONENT_MAPPER = {
  [MODEL_TYPE_CONSUMABLE]: ConsumableFormData,
  [MODEL_TYPE_ORGANIZATION_CLINICIAN]: OrganizationClinicianFormData,
  [MODEL_TYPE_MEDICATION]: MedicationFormData,
  [MODEL_TYPE_ORGANIZATION_POLICY]: OrganizationPolicyFormData,
  [MODEL_TYPE_ORGANIZATION_PATIENT]: OrganizationPatientFormData,
  [MODEL_TYPE_ORGANIZATION_BUNDLE]: OrganizationBundleFormData,
};

const ENTRY_DATA_COMPONENT_MAPPER = {
  [MODEL_TYPE_CONSUMABLE]: ConsumableData,
  [MODEL_TYPE_ORGANIZATION_CLINICIAN]: OrganizationClinicianData,
  [MODEL_TYPE_MEDICATION]: MedicationData,
  [MODEL_TYPE_ORGANIZATION_POLICY]: OrganizationPolicyData,
  [MODEL_TYPE_ORGANIZATION_PATIENT]: OrganizationPatientData,
  [MODEL_TYPE_ORGANIZATION_BUNDLE]: OrganizationBundleData,
};

const ENTRY_DIALOG_PROPS_MAPPER = {
  [MODEL_TYPE_CONSUMABLE]: {
    name: "consumable",
    maxWidth: "xs",
    TitleComponent: HealthcareOrganizationActionDialogTitle,
  },
  [MODEL_TYPE_MEDICATION]: {
    name: "medication",
    maxWidth: "md",
    TitleComponent: HealthcareOrganizationActionDialogTitle,
  },
  [MODEL_TYPE_ORGANIZATION_CLINICIAN]: {
    name: "clinician",
    maxWidth: "xs",
    TitleComponent: HealthcareOrganizationActionDialogTitle,
  },
  [MODEL_TYPE_ORGANIZATION_BUNDLE]: {
    name: "code",
    maxWidth: "lg",
    TitleComponent: InsuranceOrganizationActionDialogTitle,
  },
  [MODEL_TYPE_ORGANIZATION_PATIENT]: {
    name: "patient",
    maxWidth: "lg",
    TitleComponent: InsuranceOrganizationActionDialogTitle,
  },
  [MODEL_TYPE_ORGANIZATION_POLICY]: {
    name: "policy",
    maxWidth: "lg",
    TitleComponent: InsuranceOrganizationActionDialogTitle,
  },
};

export const getConfigurationsBulkUploadEntryComponent = ({
  model_type,
  use_processing_status = false,
}) => {
  if (use_processing_status) {
    const DataComponent = ENTRY_DATA_COMPONENT_MAPPER[model_type];
    return (props) => DataComponent({ ...props, isMinified: true });
  }
  return ENTRY_FORMDATA_COMPONENT_MAPPER[model_type];
};

export const getConfigurationsBulkUploadEntryDialogProps = ({
  model_type,
  use_processing_status = false,
  handleCloseObject = () => {},
}) => {
  const { name, ...otherProps } = ENTRY_DIALOG_PROPS_MAPPER[model_type];
  if (use_processing_status)
    return {
      ...otherProps,
      handleSubmit: handleCloseObject,
      isOk: true,
      okText: "Close",
      okButtonProps: { color: "info" },
      titleComponentProps: { title: `${capitalizeFirstLetter(name)} entry` },
    };
  return {
    ...otherProps,
    titleComponentProps: { title: `Update ${name} entry` },
  };
};

export const renderConfigurationsBulkUploadEntry = ({
  payload_object,
  model_type,
}) => {
  switch (model_type) {
    case MODEL_TYPE_ORGANIZATION_PATIENT:
      const {
        first_name: patientFirstName,
        last_name: patientLastName,
        patient_id: patientId,
      } = payload_object || {};
      const patientName = `${patientFirstName} ${patientLastName}`?.trim();

      return (
        <CustomLabeledText
          wrapperProps={{ height: "inherit" }}
          value={patientName}
          helperText={patientId}
        />
      );

    case MODEL_TYPE_ORGANIZATION_POLICY:
      const { name: policyName, number: policyNumber } = payload_object || {};
      return (
        <CustomLabeledText
          wrapperProps={{ height: "inherit" }}
          value={policyName}
          helperText={policyNumber}
        />
      );

    case MODEL_TYPE_ORGANIZATION_BUNDLE:
      const {
        internal_code: bundleInternalCode,
        description: bundleDescription,
      } = payload_object || {};
      return (
        <CustomLabeledText
          wrapperProps={{ height: "inherit" }}
          value={bundleInternalCode}
          helperText={bundleDescription}
        />
      );

    case MODEL_TYPE_ORGANIZATION_CLINICIAN:
      const {
        first_name: clinicianFirstName,
        last_name: clinicianLastName,
        clinician_id: clinicianId,
      } = payload_object || {};
      const clinicianName =
        `${clinicianFirstName} ${clinicianLastName}`?.trim();
      return (
        <CustomLabeledText
          wrapperProps={{ height: "inherit" }}
          value={clinicianName}
          helperText={clinicianId}
        />
      );

    case MODEL_TYPE_MEDICATION:
      const { trade_name, generic_name } = payload_object || {};
      const { name: medicationTradeName } = trade_name || {};
      const { name: medicationGenericName } = generic_name || {};
      return (
        <CustomLabeledText
          wrapperProps={{ height: "inherit" }}
          value={medicationTradeName}
          helperText={medicationGenericName}
        />
      );

    case MODEL_TYPE_CONSUMABLE:
      const { name: consumableName, description: consumableDescription } =
        payload_object || {};
      return (
        <CustomLabeledText
          wrapperProps={{ height: "inherit" }}
          value={consumableName}
          helperText={consumableDescription}
        />
      );

    default:
      return (
        <div className="json ellipsis-2">{JSON.stringify(payload_object)}</div>
      );
  }
};
