import React from "react";
import OrganizationAffiliationActivateForm from "@components/Authenticated/Configurations/OrganizationAffiliations/OrganizationAffiliationActivateForm";
import {
  ORGANIZATION_AGREEMENT_ACTION_TOGGLE_ACTIVATE_AFFILIATION,
  ORGANIZATION_AGREEMENT_ACTION_TOGGLE_ACTIVATE_MAINTENANCE,
} from "@constants/organizations/organization-agreements";
import OrganizationAgreementActivateMaintenanceForm from "./OrganizationAgreementActivateMaintenanceForm";

export default function OrganizationAgreementAction({
  state,
  setState = () => {},
}) {
  const { object, action, isOpen } = state;
  const handleClose = React.useCallback(
    (e) => {
      e?.preventDefault();
      setState((prev) => ({ ...prev, isOpen: false }));
    },
    [setState]
  );

  switch (action) {
    case ORGANIZATION_AGREEMENT_ACTION_TOGGLE_ACTIVATE_MAINTENANCE:
      return (
        <OrganizationAgreementActivateMaintenanceForm
          object={object}
          useMenuItem={false}
          isOpenParent={isOpen}
          handleCloseParent={handleClose}
        />
      );

    case ORGANIZATION_AGREEMENT_ACTION_TOGGLE_ACTIVATE_AFFILIATION:
      return (
        <OrganizationAffiliationActivateForm
          object={object}
          isOpen={isOpen}
          handleClose={handleClose}
        />
      );

    default:
      return null;
  }
}
