import React from "react";
import CustomTooltip from "@components/Custom/CustomTooltip";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import CustomTypographyButton from "./CustomTypographyButton";

export default function CustomDividedList({
  list,
  divideAt = 2,
  TooltipObjectComponent = () => {},
}) {
  const firstList = divideAt === null ? list : list.slice(0, divideAt);
  const secondList = divideAt === null ? [] : list.slice(divideAt);
  const secondListLength = secondList.length;

  if (list.length === 0) return "-";

  return (
    <Grid container spacing={1} alignItems="center">
      {firstList.map((props, index) => (
        <Grid item key={index}>
          <TooltipObjectComponent {...props} />
        </Grid>
      ))}
      {secondListLength > 0 && (
        <Grid item>
          <CustomTooltip
            title={
              <Stack spacing={1} py={1}>
                {secondList.map((props, index) => (
                  <TooltipObjectComponent
                    key={index}
                    placement="right"
                    {...props}
                  />
                ))}
              </Stack>
            }
          >
            <CustomTypographyButton
              value={`+${secondListLength} more`}
              fontWeight="normal"
            />
          </CustomTooltip>
        </Grid>
      )}
    </Grid>
  );
}
