import { TOKEN_SHORT_NAME_PLURAL } from "@constants/static/payment";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { parseTimestamp } from "@helpers/parse/time";

const OrganizationConsumedTokensGridProps = {
  columns: [
    {
      flex: 1,
      field: "claim",
      headerName: "Claim ID",
    },
    {
      flex: 1,
      field: "time_consumed",
      headerName: "Time consumed",
      valueGetter: ({ value }) => parseTimestamp({ value }),
    },
    {
      flex: 1,
      field: "num_tokens_consumed",
      headerName: `Consumed ${TOKEN_SHORT_NAME_PLURAL}`,
      getValue: ({ value }) => getIntOrFloat(value),
    },
  ],
  disableRowSelectionOnClick: true,
};

export default OrganizationConsumedTokensGridProps;
