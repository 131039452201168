import React from "react";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import { USER_ORGANIZATION_ROLE_OPTIONS } from "@constants/users/users";
import Grid from "@mui/material/Grid";

export default function UserManagementInviteUserFormData({
  errors,
  formData,
  setFormData,
}) {
  const { email, first_name, last_name, role } = formData;
  const {
    email: emailError,
    first_name: firstNameError,
    last_name: lastNameError,
    role: roleError,
    detail: detailError,
  } = errors || {};

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <CustomOutlinedTextField
            required
            autoFocus
            autoComplete="given-name"
            label="Name"
            name="first_name"
            value={first_name}
            helperText={firstNameError}
            error={Boolean(firstNameError)}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomOutlinedTextField
            required
            autoComplete="family-name"
            label="Last name"
            name="last_name"
            value={last_name}
            helperText={lastNameError}
            error={Boolean(lastNameError)}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomOutlinedTextField
            required
            type="email"
            autoComplete="email"
            label="Email"
            name="email"
            value={email}
            helperText={emailError}
            error={Boolean(emailError)}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomOutlinedTextField
            select
            required
            label="Role"
            name="role"
            value={role}
            helperText={roleError || detailError}
            error={Boolean(roleError || detailError)}
            onChange={handleChange}
            options={USER_ORGANIZATION_ROLE_OPTIONS}
          />
        </Grid>
      </Grid>
    </div>
  );
}
