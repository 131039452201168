import React from "react";
import CustomActionDialogTitle from "@components/Custom/CustomActionDialog/CustomActionDialogTitle";
import { selectorAuthCurrentUserOrganization } from "@redux/selectors/auth";
import { useSelector } from "react-redux";

export default function HealthcareOrganizationActionDialogTitle({
  title,
  fullScreen = false,
}) {
  const organization = useSelector(selectorAuthCurrentUserOrganization);
  const { image } = organization || {};

  return (
    <CustomActionDialogTitle
      image={image}
      title={title}
      fullScreen={fullScreen}
    />
  );
}
