import React from "react";
import { LOGIN_HREF } from "@constants/static/routing";
import { logout } from "@redux/actions/users/auth";
import { closeSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { ReactQueryClient } from "..";

export default function Logout() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(logout());
    closeSnackbar();
    ReactQueryClient.clear();
  }, [dispatch]);

  return <Navigate to={LOGIN_HREF} />;
}
