import React from "react";
import { customActionDialogBodyPx } from "@components/Custom/CustomActionDialog";
import CustomListAvatarItem from "@components/Custom/CustomListAvatarItem";
import ListItemButton from "@mui/material/ListItemButton";
import { PlusIcon } from "@rimads/assets";

export default function OrganizationAffiliationsOrganization({
  object,
  handleAddOrganization = () => {},
}) {
  const { name, image } = object;
  const handleClick = () => handleAddOrganization(object);

  return (
    <ListItemButton onClick={handleClick} sx={{ px: customActionDialogBodyPx }}>
      <CustomListAvatarItem
        textProps={{ children: name, className: "ellipsis-1" }}
        imageProps={{ src: image, variant: "square", className: "sizeMd" }}
        wrapperProps={{ flexGrow: 1 }}
      />
      <PlusIcon style={{ paddingRight: 12 }} />
    </ListItemButton>
  );
}
