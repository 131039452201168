import { v4 as uuidv4 } from "uuid";

const CLAIM_META_COLUMN_NAME_META = "META";
const CLAIM_META_COLUMN_NAME_ROW_NUMBER = "row_number";
const CLAIM_META_COLUMN_NAME_RAW_JSON = "raw_json";

const hideMetaColumn = (key) =>
  [CLAIM_META_COLUMN_NAME_ROW_NUMBER, CLAIM_META_COLUMN_NAME_RAW_JSON].includes(
    key
  );

const formatMetaColumnName = (key) =>
  key.replace(CLAIM_META_COLUMN_NAME_META, "").trim();

export const convertClaimMetaDict = (data) => {
  const output = [];
  Object.entries(data).forEach(([prevKey, value]) => {
    const key = formatMetaColumnName(prevKey);
    const hidden = hideMetaColumn(key);
    output.push({ id: uuidv4(), key, hidden, value });
  });
  return output;
};

export const revertClaimMetaDict = (formData) => {
  const data = {};
  formData.forEach(({ key, value }) => {
    data[`${CLAIM_META_COLUMN_NAME_META} ${key}`] = value;
  });
  return data;
};

export const getMetaDataAllKeys = (object) =>
  object.filter(({ hidden }) => !hidden);

export const getMetaDataFilledKeys = (object) =>
  object.filter(({ hidden, value }) => !hidden && Boolean(value));
