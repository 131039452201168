import { DISABLED_ROW_CLASSNAME } from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomTooltip from "@components/Custom/CustomTooltip";
import CustomValueWithUnit from "@components/Custom/CustomValueWithUnit";
import {
  isOrganizationBundleTypeProcedure,
  ORGANIZATION_BUNDLE_IS_PREAUTHORIZATION_REQUIRED_MAPPER,
  ORGANIZATION_BUNDLE_TYPE_MEDICATIONS,
  ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES,
} from "@constants/organizations/organization-bundles";
import {
  isInpatientVisitType,
  isOutpatientVisitType,
  ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT_SHORTHAND,
  ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT_SHORTHAND,
} from "@constants/organizations/organization-patients";
import { getOrganizationBundlesHref } from "@constants/static/routing";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { parseTimeSince } from "@helpers/parse/time";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import OrganizationPatientVisitType from "../../OrganizationPatients/OrganizationPatient/OrganizationPatientData/OrganizationPatientVisitType";
import OrganizationBundleRelatedObjectsSummary from "../OrganizationBundle/OrganizationBundleData/OrganizationBundleRelatedObjectsSummary";
import { OrganizationBundleSecondProcedureDiscountTag } from "../OrganizationBundle/OrganizationBundleData/OrganizationBundleSecondProcedureDiscountTag";

const getOrganizationBundleHeader = (bundle_type) => {
  switch (bundle_type) {
    case ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES:
      return "CPTs & Consumables";
    case ORGANIZATION_BUNDLE_TYPE_MEDICATIONS:
      return "Medication";
    default:
      return "";
  }
};

export default function OrganizationBundlesGridProps({
  processor_organization__slug,
  bundle_type,
  hiddenColumns = [],
}) {
  const organizationBundleHeaderName = getOrganizationBundleHeader(bundle_type);
  const isProcedureAndConsumable =
    isOrganizationBundleTypeProcedure(bundle_type);

  return {
    columns: [
      {
        flex: 1,
        field: "internal_code",
        headerName: "Internal code",
        renderCell: (params) => {
          const { value } = params;
          const { description, is_second_procedure_discount } =
            params?.row || {};

          return (
            <Stack spacing={1} direction="row" alignItems="center">
              <CustomTooltip title={description}>
                <Typography variant="body2">{value}</Typography>
              </CustomTooltip>
              {isProcedureAndConsumable && (
                <OrganizationBundleSecondProcedureDiscountTag
                  is_second_procedure_discount={is_second_procedure_discount}
                />
              )}
            </Stack>
          );
        },
      },
      {
        flex: 1.5,
        sortable: false,
        field: "organization_bundle",
        headerName: organizationBundleHeaderName,
        renderCell: (params) => (
          <OrganizationBundleRelatedObjectsSummary object={params?.row} />
        ),
      },
      {
        flex: 1,
        field: "visit_type",
        headerName: "Visit type",
        renderCell: ({ value }) => (
          <OrganizationPatientVisitType visit_type={value} />
        ),
      },
      {
        flex: 1,
        field: "undiscounted_price",
        headerName: "Undiscounted price",
        valueGetter: ({ value }) => getIntOrFloat(value),
        renderCell: (params) => (
          <CustomValueWithUnit
            value={params?.value}
            unit={params?.row?.currency}
          />
        ),
      },
      {
        flex: 1,
        field: "inpatient_discount_rate",
        headerName: `${ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT_SHORTHAND} discount`,
        valueGetter: ({ value }) => getIntOrFloat(value),
        renderCell: (params) => {
          const isInpatient = isInpatientVisitType(params?.row?.visit_type);
          if (!isInpatient) return "-";
          return <CustomValueWithUnit value={params?.value} unit="%" />;
        },
      },
      {
        flex: 1,
        field: "outpatient_discount_rate",
        headerName: `${ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT_SHORTHAND} discount`,
        valueGetter: ({ value }) => getIntOrFloat(value),
        renderCell: (params) => {
          const isOutpatient = isOutpatientVisitType(params?.row?.visit_type);
          if (!isOutpatient) return "-";
          return <CustomValueWithUnit value={params?.value} unit="%" />;
        },
      },
      {
        flex: 1,
        field: "is_preauthorization_required",
        headerName: "Preauthorization",
        renderCell: ({ value }) =>
          ORGANIZATION_BUNDLE_IS_PREAUTHORIZATION_REQUIRED_MAPPER[value],
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ].filter(({ field }) => !hiddenColumns.includes(field)),
    getRowClassName: ({ row }) => row?.time_deleted && DISABLED_ROW_CLASSNAME,
    disableRowSelectionOnClick: true,
    navigateTo: ({ id }) =>
      Boolean(processor_organization__slug) &&
      getOrganizationBundlesHref({
        bundle_type,
        organization__slug: processor_organization__slug,
        organization_bundle__id: id,
      }),
  };
}
