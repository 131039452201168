import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const isOrganizationClinicianOptionEqualToValue = (option, value) =>
  option?.id === value?.id;

export const getOrganizationClinicianOptionLabel = (option) => {
  const { first_name, last_name, clinician_id } = option;
  const full_name = `${first_name} ${last_name}`.trim();
  return `${full_name} (${clinician_id})`;
};

export const renderOrganizationClinicianOption = (props, option) => {
  const { clinician_id, first_name, last_name } = option;
  const full_name = `${first_name} ${last_name}`.trim();
  return (
    <div {...props}>
      <Stack
        spacing={3}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flex={1}
      >
        <Typography variant="body2" fontWeight="bold">
          {full_name}
        </Typography>
        <Typography variant="body2">{`#${clinician_id}`}</Typography>
      </Stack>
    </div>
  );
};
