import React from "react";
import CustomPasswordTextField from "@components/Custom/CustomPasswordTextField";
import { STATIC_PASSWORD_HELPER_TEXT } from "@constants/static/auth";
import Stack from "@mui/material/Stack";

export default function AccountDetailsFormData({
  errors = {},
  formData = {},
  setFormData = () => {},
}) {
  const { current_password, new_password } = formData;
  const {
    current_password: currentPasswordError,
    new_password: newPasswordError,
  } = errors;

  const handleChange = (e) =>
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));

  return (
    <Stack spacing={3}>
      <CustomPasswordTextField
        variant="standard"
        required
        name="current_password"
        label="Current password"
        value={current_password}
        fullWidth
        InputLabelProps={{ required: false }}
        onChange={handleChange}
        autoComplete="off"
        helperText={currentPasswordError}
        error={Boolean(currentPasswordError)}
      />
      <CustomPasswordTextField
        variant="standard"
        required
        name="new_password"
        label="New password"
        value={new_password}
        fullWidth
        InputLabelProps={{ required: false }}
        onChange={handleChange}
        autoComplete="off"
        helperText={newPasswordError || STATIC_PASSWORD_HELPER_TEXT}
        error={Boolean(newPasswordError)}
      />
    </Stack>
  );
}
