import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomBulletPoints from "@components/Custom/CustomBulletPoints";
import CustomButton from "@components/Custom/CustomButton";
import {
  getUploadSummary,
  objectNameGivenModelType,
} from "@constants/static/upload";
import { getIntOrFloat } from "@helpers/parse/numbers";
import Typography from "@mui/material/Typography";
import {
  commitUpload,
  updateUploadObjectTSQ,
} from "@redux/actions/utils/uploads";
import { enqueueSnackbar } from "notistack";
import pluralize from "pluralize";
import { useParams } from "react-router-dom";
import HealthcareOrganizationActionDialogTitle from "../../HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import InsuranceOrganizationActionDialogTitle from "../../InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import { parseBulkUploadSnackbar } from "../ConfigurationsBulkUploads/ConfigurationsBulkUploadsSnackbar";

export default function ConfigurationsBulkUploadCommitForm({
  object,
  model_type,
}) {
  const { organization__slug } = useParams();
  const { id, num_instances } = object;
  const [summary, setSummary] = React.useState({});
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const objectName = objectNameGivenModelType({ model_type });
  const pluralizedObjectName = pluralize(objectName);
  const TitleComponent = Boolean(organization__slug)
    ? InsuranceOrganizationActionDialogTitle
    : HealthcareOrganizationActionDialogTitle;

  const uploadSummary = (
    <>
      {"The provided upload contains "}
      <b>{`${getIntOrFloat(num_instances)} ${pluralize(
        objectName,
        num_instances,
        false
      )}`}</b>
    </>
  );
  const bulletpoints = getUploadSummary({ summary });

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSubmit = ({ e, isCheck = true }) => {
    e?.preventDefault();
    if (isCheck) setIsOpen(true);
    setIsProcessing(true);
    commitUpload({
      id,
      is_check: isCheck,
      model_type,
    })
      .then(({ payload }) => {
        const { instance, summary } = payload;
        if (isCheck) {
          setSummary(summary);
        } else {
          enqueueSnackbar(parseBulkUploadSnackbar(instance));
          updateUploadObjectTSQ({ object: instance });
          setIsOpen(false);
        }
      })
      .catch(({ payload }) =>
        enqueueSnackbar({ message: payload, variant: "error" })
      )
      .finally(() => setIsProcessing(false));
  };

  const handleCheckData = (e) => handleSubmit({ e, isCheck: true });
  const handleSubmitData = (e) => handleSubmit({ e, isCheck: false });

  return (
    <>
      <CustomButton
        label={`Apply ${pluralizedObjectName}`}
        icon="TickIcon"
        onClick={handleCheckData}
      />
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        TitleComponent={TitleComponent}
        titleComponentProps={{ title: `Apply ${pluralizedObjectName}` }}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmitData}
        bodyProps={{ spacing: 1 }}
        yesText="Apply"
      >
        <Typography variant="body2">{uploadSummary}</Typography>
        <CustomBulletPoints bulletpoints={bulletpoints} />
      </CustomActionDialog>
    </>
  );
}
