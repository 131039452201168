import { decodeURLParams, useURLSearch } from "@helpers/api/getQueryParams";
import { useQueryFetchClaim } from "@redux/actions/claims/claims";
import { useParams } from "react-router-dom";

export default function useFetchClaim() {
  const urlSearch = useURLSearch();
  const queryParams = decodeURLParams(urlSearch);
  const { organization__slug, claim_submission__id, claim__id } = useParams();
  const { data, isLoading } = useQueryFetchClaim({
    ...queryParams,
    id: claim__id,
    claim_submission__id,
    organization__slug,
  });
  const hasData = Boolean(data);

  return {
    data,
    isLoading,
    hasData,
    organization__slug,
    claim_submission__id,
    claim__id,
    urlSearch,
  };
}
