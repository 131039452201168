import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import CustomSection from "@components/Custom/CustomSection";

const title = "Policy details";

export default function OrganizationPatientPolicyDetailsData({
  object = {},
  isMinified = false,
  useCustomSection = true,
}) {
  if (useCustomSection)
    return (
      <CustomSection title={title} isMinified={isMinified}>
        <OrganizationPatientPolicyDetailsDataContent object={object} />
      </CustomSection>
    );

  return (
    <CustomListItemCollapse primary={title}>
      <OrganizationPatientPolicyDetailsDataContent object={object} />
    </CustomListItemCollapse>
  );
}

const OrganizationPatientPolicyDetailsDataContent = ({ object }) => {
  const { patient_id, provider_file_number, organization_policy } = object;

  return (
    <CustomGrid>
      <CustomLabeledText
        label="Policy number"
        value={organization_policy?.number}
      />
      <CustomLabeledText
        label="Policy name"
        value={organization_policy?.name}
      />
      <CustomLabeledText label="Member ID" value={patient_id} />
      <CustomLabeledText
        label="Provider file number"
        value={provider_file_number}
      />
    </CustomGrid>
  );
};
