import React from "react";
import OrganizationsNoResults from "@components/Authenticated/Organizations/Organizations/OrganizationsNoResults";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import { getClaimSubmissionHref } from "@constants/static/routing";
import useFetchOrganizations from "@helpers/hooks/useFetchObjects/useFetchOrganizations";
import { Navigate, Route, Routes } from "react-router-dom";
import OrganizationRoutes from "./organization-routes";

export default function OrganizationsRoutes() {
  return (
    <Routes>
      <Route path=":organization__slug/*" Component={OrganizationRoutes} />
      <Route path="*" Component={OrganizationsRoutesFirstOrganization} />
    </Routes>
  );
}

const OrganizationsRoutesFirstOrganization = () => {
  const { infiniteQuery } = useFetchOrganizations({ searchQuery: "" });
  const {
    isFetching = true,
    isLoading = true,
    data = null,
  } = infiniteQuery || {};
  const first_slug = data?.pages?.[0]?.results?.[0]?.slug;

  if (isFetching || isLoading) return <CustomBackdrop />;

  if (Boolean(first_slug)) {
    const href = getClaimSubmissionHref({ organization__slug: first_slug });
    return <Navigate to={href} />;
  }

  return <OrganizationsNoResults />;
};
