import React from "react";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import Stack from "@mui/material/Stack";
import ClaimsGrid from "../../Claims/Claims/ClaimsGrid";
import ClaimsNoResults from "../../Claims/Claims/ClaimsNoResults";
import ClaimSubmissionUploadPending from "../../ClaimSubmissionUploads/ClaimSubmissionUploads/ClaimSubmissionUploadPending";
import ClaimSubmissionClaimsSummary from "./ClaimSubmissionClaimsSummary";

export default function ClaimsSubmissionBody({ object }) {
  const { claims_summary, latest_claim_submission_upload } = object;
  const { has_claims } = claims_summary || { has_claims: false };

  const hasContent = has_claims || Boolean(latest_claim_submission_upload);

  if (hasContent)
    return (
      <Stack spacing={2}>
        <CustomGrid rowSpacing={2} columnSpacing={2}>
          <ClaimSubmissionClaimsSummary claims_summary={claims_summary} />
          <ClaimSubmissionUploadPending />
        </CustomGrid>
        <ClaimsGrid />
      </Stack>
    );

  return (
    <Stack spacing={2}>
      <ClaimSubmissionUploadPending />
      <ClaimsNoResults />
    </Stack>
  );
}
