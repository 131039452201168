import React from "react";
import ConfigurationsBulkExportData from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkExportForm/ConfigurationsBulkExportData";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomStatusTag from "@components/Custom/CustomStatusTag";
import {
  EXPORT_STATUS_PROPS_MAPPER,
  EXPORT_STATUS_TEXT_MAPPER,
} from "@constants/static/export";
import Grid from "@mui/material/Grid";
import ConfigurationsBulkExportGridProps from "./ConfigurationsBulkExportGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

const ConfigurationBulkExportTitleComponent = ({
  TitleComponent,
  status,
  ...otherTitleComponentProps
}) => {
  return (
    <CustomGrid alignItems="center" columnSpacing={2}>
      {Boolean(TitleComponent) && (
        <TitleComponent {...otherTitleComponentProps} />
      )}
      {Boolean(status) && (
        <CustomStatusTag
          status={status}
          textMapper={EXPORT_STATUS_TEXT_MAPPER}
          propsMapper={EXPORT_STATUS_PROPS_MAPPER}
        />
      )}
    </CustomGrid>
  );
};

export default function ConfigurationsBulkExportGrid({
  useQueryFn = () => {},
  useQueryFnProps: parentUseQueryFnProps = {},
  TitleComponent: parentTitleComponent = undefined,
  describeFilters = () => {},
  model_type,
}) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [state, setState] = React.useState({ isOpen: false, object: null });
  const { isOpen, object } = state;
  const { name, status } = object || {};
  const useQueryFnProps = {
    searchQuery,
    ...parentUseQueryFnProps,
  };
  const handleClickRow = (row) =>
    setState((prev) => ({ ...prev, isOpen: true, object: row }));
  const handleClose = (e) => {
    e?.preventDefault();
    setState((prev) => ({ ...prev, isOpen: false }));
  };
  const dataGridProps = React.useMemo(
    () => ConfigurationsBulkExportGridProps({ handleClickRow, model_type }),
    [model_type]
  );

  return (
    <div>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item flexGrow={1}>
          <CustomContextSearchField setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item xs={12}>
          <CustomInfiniteDataGrid
            useQueryFn={useQueryFn}
            useQueryFnProps={useQueryFnProps}
            dataGridProps={dataGridProps}
            initialSortModel={initialSortModel}
          />
        </Grid>
      </Grid>
      <CustomActionDialog
        isOpen={isOpen}
        TitleComponent={ConfigurationBulkExportTitleComponent}
        titleComponentProps={{
          title: name,
          status,
          TitleComponent: parentTitleComponent,
        }}
        handleClose={handleClose}
        handleSubmit={handleClose}
        isOk={true}
        okText="Close"
        okButtonProps={{ color: "info" }}
        maxWidth="sm"
      >
        <ConfigurationsBulkExportData
          object={object}
          describeFilters={describeFilters}
        />
      </CustomActionDialog>
    </div>
  );
}
