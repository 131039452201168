import React from "react";
import InstanceAutocomplete from "@components/Custom/CustomAutocomplete/PhoenixAutocomplete/InstanceAutocomplete";
import CustomButton from "@components/Custom/CustomButton";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import { PHOENIX_GENDER_BOTH } from "@constants/organizations/organization-patients";
import Stack from "@mui/material/Stack";
import {
  selectorClaimDemoParsedOrganizationPatient,
  useClaimDemoContext,
} from "../context";

export default function ClaimDemoDiagnosisClinicianAddDisease() {
  const [isOpen, setIsOpen] = React.useState(false);
  const { state, handleAddClaimDiagnosis } = useClaimDemoContext();
  const parsedOrganizationPatient =
    selectorClaimDemoParsedOrganizationPatient(state);
  const { ageInt, phoenixGender } = parsedOrganizationPatient;

  const useQueryFnProps = {
    filters: {
      age_lower_limit__lte: ageInt,
      age_upper_limit__gte: ageInt,
      gender__in: [PHOENIX_GENDER_BOTH, phoenixGender],
    },
  };

  const handleIsOpen = () => setIsOpen(!isOpen);
  const handleChangeObject = (object) => {
    handleAddClaimDiagnosis(object);
    setIsOpen(false);
  };

  if (!isOpen)
    return (
      <CustomTypographyButton
        value="+ Add disease"
        onClick={handleIsOpen}
        style={{ minHeight: 40, alignItems: "center", display: "grid" }}
      />
    );

  return (
    <Stack spacing={1.5} direction="row" alignItems="center">
      <InstanceAutocomplete
        value={null}
        handleChange={handleChangeObject}
        useQueryFnProps={useQueryFnProps}
        textFieldProps={{ autoFocus: true }}
      />
      <CustomButton
        icon="CloseIcon"
        variant="outlined"
        isSquare={true}
        useIconAsLabel={true}
        onClick={handleIsOpen}
      />
    </Stack>
  );
}
