export default function useFetchPagination({
  useInfiniteWrapper = false,
  useQueryFn = () => {},
  queryFnProps = {},
}) {
  const query = useQueryFn({ ...queryFnProps, useInfiniteWrapper });
  let rows = [];
  const {
    data = {},
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
    isError,
  } = query;

  if (useInfiniteWrapper) {
    rows = [].concat(...(data?.pages || []).map(({ results }) => results));
    return {
      rows,
      rowCount: rows.length,
      loading: (isLoading || isFetching) && !isFetchingNextPage,
      isError,
      isFetchingNextPage,
      hasNextPage,
      fetchNextPage,
    };
  }

  rows = data?.results || [];
  return {
    rows,
    rowCount: data?.count || 0,
    loading: isLoading || isFetching,
    isError,
  };
}
