import {
  AlertedIcon,
  CheckboxSelectedIcon,
  RejectedIcon,
} from "@rimads/assets";
import { AVEY_BLUE, AVEY_RED, AVEY_YELLOW } from "@styles/theme";
import pluralize from "pluralize";
import { CLAIM_SECTION_DIAGNOSES, CLAIM_SECTION_SYMPTOMS } from "./claims";

export const CLAIM_EVALUATION_RESULT_STATUS_SUCCESS = "S";
export const CLAIM_EVALUATION_RESULT_STATUS_FAILURE = "F";
export const CLAIM_EVALUATION_RESULT_STATUS_ALERT = "A";

const CLAIM_SYMPTOMS_SECTION_EDIT_MODE = "edit-claim-symptoms";
const CLAIM_SYMPTOMS_SECTION_READ_MODE = "read-claim-symptoms";

const CLAIM_DIAGNOSES_SECTION_EDIT_MODE = "edit-claim-diagnoses";
const CLAIM_DIAGNOSES_SECTION_READ_MODE = "read-claim-diagnoses";

const CLAIM_PROCEDURE_SECTION_EDIT_MODE = "edit-claim-procedure";
const CLAIM_PROCEDURE_SECTION_READ_MODE = "read-claim-procedure";

const CLAIM_MEDICATION_SECTION_EDIT_MODE = "edit-claim-medication";
const CLAIM_MEDICATION_SECTION_READ_MODE = "read-claim-medication";

export const getClaimIcdsSectionId = ({ isCreateOrUpdateMode, type }) => {
  switch (type) {
    case CLAIM_SECTION_SYMPTOMS:
      return isCreateOrUpdateMode
        ? CLAIM_SYMPTOMS_SECTION_EDIT_MODE
        : CLAIM_SYMPTOMS_SECTION_READ_MODE;

    case CLAIM_SECTION_DIAGNOSES:
      return isCreateOrUpdateMode
        ? CLAIM_DIAGNOSES_SECTION_EDIT_MODE
        : CLAIM_DIAGNOSES_SECTION_READ_MODE;

    default:
      return null;
  }
};

export const getClaimSymptomsSectionId = ({ isCreateOrUpdateMode }) =>
  isCreateOrUpdateMode
    ? CLAIM_SYMPTOMS_SECTION_EDIT_MODE
    : CLAIM_SYMPTOMS_SECTION_READ_MODE;

export const getClaimDiagnosesSectionId = ({ isCreateOrUpdateMode }) =>
  isCreateOrUpdateMode
    ? CLAIM_DIAGNOSES_SECTION_EDIT_MODE
    : CLAIM_DIAGNOSES_SECTION_READ_MODE;

export const getClaimProcedureSectionId = ({ isCreateOrUpdateMode, id }) =>
  isCreateOrUpdateMode
    ? `${CLAIM_PROCEDURE_SECTION_EDIT_MODE}-${id}`
    : `${CLAIM_PROCEDURE_SECTION_READ_MODE}-${id}`;

export const getClaimMedicationSectionId = ({ isCreateOrUpdateMode, id }) =>
  isCreateOrUpdateMode
    ? `${CLAIM_MEDICATION_SECTION_EDIT_MODE}-${id}`
    : `${CLAIM_MEDICATION_SECTION_READ_MODE}-${id}`;

export const CLAIM_EVALUATION_RESULT_ICON_MAPPER = {
  [CLAIM_EVALUATION_RESULT_STATUS_SUCCESS]: (
    <CheckboxSelectedIcon primary_fill={AVEY_BLUE} dimension={14} />
  ),
  [CLAIM_EVALUATION_RESULT_STATUS_FAILURE]: (
    <RejectedIcon primary_fill={AVEY_RED} dimension={14} />
  ),
  [CLAIM_EVALUATION_RESULT_STATUS_ALERT]: (
    <AlertedIcon primary_fill={AVEY_YELLOW} dimension={14} />
  ),
};

export const isErrorFieldnames = (fieldnames = []) =>
  fieldnames.reduce((acc, fieldname) => {
    acc[fieldname] = true;
    return acc;
  }, {});

// CLAIM
export const CLAIM_ERROR_FIELDNAMES_CLAIM_FORM_NUMBER = "claim_form_number";
export const CLAIM_ERROR_FIELDNAMES_VISIT_TYPE = "visit_type";
export const CLAIM_ERROR_FIELDNAMES_ENCOUNTER_TYPE = "encounter_type";
export const CLAIM_ERROR_FIELDNAMES_VISIT_DATE = "visit_date";
export const CLAIM_ERROR_FIELDNAMES_PROCEDURE_OR_MEDICATION =
  "claim_procedures_and_claim_medications";
export const CLAIM_ERROR_FIELDNAMES_PRESENTATION_OR_DIAGNOSES =
  "claim_symptoms_and_claim_diagnoses";
export const CLAIM_ERROR_FIELDNAMES_ORGANIZATION_PATIENT =
  "organization_patient_id";
export const CLAIM_ERROR_FIELDNAMES_ORGANIZATION_CLINICIAN =
  "organization_clinician_id";

// CLAIM ICD CODES
export const CLAIM_ICD_CODE_ERROR_FIELDNAMES_ICD = "icd";
export const CLAIM_ICD_CODE_ERROR_FIELDNAMES_DESCRIPTION = "description";

// CLAIM PROCEDURES
export const CLAIM_PROCEDURE_ERROR_FIELDNAMES_ORGANIZATION_BUNDLE =
  "organization_bundle";
export const CLAIM_PROCEDURE_ERROR_FIELDNAMES_QUANTITY = "quantity";
export const CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_GROSS = "amount_gross";
export const CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_DISCOUNT =
  "amount_discount";
export const CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_COPAYMENT =
  "amount_copayment";
export const CLAIM_PROCEDURE_ERROR_FIELDNAMES_AMOUNT_REQUESTED =
  "amount_requested";
export const CLAIM_PROCEDURE_ERROR_FIELDNAMES_RELATED_DATE = "related_date";
export const CLAIM_PROCEDURE_ERROR_FIELDNAMES_PREAUTHORIZATION_NUMBER =
  "preauthorization_number";
export const CLAIM_PROCEDURE_ERROR_FIELDNAMES_INTERNAL_CODE = "internal_code";
export const CLAIM_PROCEDURE_ERROR_FIELDNAMES_INVOICE_NUMBER = "invoice_number";
export const CLAIM_PROCEDURE_ERROR_FIELDNAMES_TOOTH_NUMBER = "tooth_number";
export const CLAIM_PROCEDURE_ERROR_FIELDNAMES_NATURE_OF_CONCEPTION =
  "nature_of_conception";
export const CLAIM_PROCEDURE_ERROR_FIELDNAMES_LAST_MENSTRUAL_PERIOD =
  "last_menstrual_period";
export const CLAIM_PROCEDURE_ERROR_FIELDNAMES_PROCEDURE_TYPE = "procedure_type";

// CLAIM MEDICATIONS
export const CLAIM_MEDICATION_ERROR_FIELDNAMES_ORGANIZATION_BUNDLE =
  "organization_bundle";
export const CLAIM_MEDICATION_ERROR_FIELDNAMES_QUANTITY = "quantity";
export const CLAIM_MEDICATION_ERROR_FIELDNAMES_AMOUNT_GROSS = "amount_gross";
export const CLAIM_MEDICATION_ERROR_FIELDNAMES_AMOUNT_DISCOUNT =
  "amount_discount";
export const CLAIM_MEDICATION_ERROR_FIELDNAMES_AMOUNT_COPAYMENT =
  "amount_copayment";
export const CLAIM_MEDICATION_ERROR_FIELDNAMES_AMOUNT_REQUESTED =
  "amount_requested";
export const CLAIM_MEDICATION_ERROR_FIELDNAMES_RELATED_DATE = "related_date";
export const CLAIM_MEDICATION_ERROR_FIELDNAMES_PREAUTHORIZATION_NUMBER =
  "preauthorization_number";
export const CLAIM_MEDICATION_ERROR_FIELDNAMES_INTERNAL_CODE = "internal_code";
export const CLAIM_MEDICATION_ERROR_FIELDNAMES_INVOICE_NUMBER =
  "invoice_number";

export const getClaimEvaluationResultReportSummary = ({ results = [] }) => {
  let statusCounts = {
    [CLAIM_EVALUATION_RESULT_STATUS_FAILURE]: 0,
    [CLAIM_EVALUATION_RESULT_STATUS_SUCCESS]: 0,
    [CLAIM_EVALUATION_RESULT_STATUS_ALERT]: 0,
  };
  results.forEach(
    ({ status }) => status in statusCounts && statusCounts[status]++
  );

  const {
    [CLAIM_EVALUATION_RESULT_STATUS_FAILURE]: numFailure,
    [CLAIM_EVALUATION_RESULT_STATUS_SUCCESS]: numSuccess,
    [CLAIM_EVALUATION_RESULT_STATUS_ALERT]: numAlert,
  } = statusCounts;

  let summaryParts = [];
  numAlert > 0 && summaryParts.push(numAlert + " alerted");
  numFailure > 0 && summaryParts.push(numFailure + " failed");
  numSuccess > 0 && summaryParts.push(numSuccess + " passed");

  let summary = "";
  if (summaryParts.length > 1) {
    summary =
      summaryParts.slice(0, -1).join(", ") +
      " & " +
      summaryParts[summaryParts.length - 1];
  } else {
    summary = summaryParts.join("");
  }

  summary = summary + " " + pluralize("test", results.length, false);

  return summary;
};
