import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomSection from "@components/Custom/CustomSection";
import CustomValueWithUnit from "@components/Custom/CustomValueWithUnit";
import {
  getConcentrationUnitStr,
  getDrugName,
  getPackageSizeUnitStr,
} from "@constants/avey/medications";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

export default function MedicationActiveIngredientsData({
  medication_active_ingredients = [],
  isMinified = false,
}) {
  const numObjects = medication_active_ingredients.length;
  if (numObjects === 0) return null;

  return (
    <CustomSection title="Active ingredients" isMinified={isMinified}>
      <Stack spacing={2}>
        {medication_active_ingredients.map(
          (medication_active_ingredient, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <Divider />}
              <MedicationActiveIngredientData
                medication_active_ingredient={medication_active_ingredient}
              />
            </React.Fragment>
          )
        )}
      </Stack>
    </CustomSection>
  );
}

const MedicationActiveIngredientData = ({ medication_active_ingredient }) => {
  const {
    generic_name,
    concentration,
    concentration_unit,
    package_size,
    package_size_unit,
  } = medication_active_ingredient;
  const { description } = generic_name || {};
  const name = getDrugName({ drug_name: generic_name });
  const concentrationUnitStr = getConcentrationUnitStr({ concentration_unit });
  const packageSizeUnitStr = getPackageSizeUnitStr({ package_size_unit });

  return (
    <CustomGrid>
      <CustomLabeledText
        label="Active ingredient"
        value={name}
        helperText={description}
      />
      <CustomLabeledText label="Concentration" useChildren={true}>
        <CustomValueWithUnit
          value={concentration || "-"}
          unit={concentrationUnitStr}
        />
      </CustomLabeledText>
      <CustomLabeledText label="Package size" useChildren={true}>
        <CustomValueWithUnit
          value={package_size || "-"}
          unit={packageSizeUnitStr}
        />
      </CustomLabeledText>
    </CustomGrid>
  );
};
