import {
  CLAIM_MODE_AUDIT,
  CLAIM_MODE_SUBMIT,
} from "@constants/static/system-modes";
import { removeAccessTokens } from "@redux/actions/users/tokens";
import {
  AUTH_GET_SELF,
  AUTH_IS_AUTHENTICATED,
  AUTH_IS_LOADING,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_TOGGLE_CLAIM_MODE,
  AUTH_UPDATE_ORGANIZATION,
} from "../actionTypes/auth";

const DEFAULT_CLAIM_MODE = CLAIM_MODE_AUDIT;

const initialState = {
  user: {},
  isAuthenticated: false,
  isLoadingAuth: true,
  claimMode: DEFAULT_CLAIM_MODE,
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;
  let user = {};
  let claimMode = state.claimMode;

  switch (type) {
    case AUTH_IS_LOADING:
      return { ...state, isLoadingAuth: true };

    case AUTH_TOGGLE_CLAIM_MODE:
      claimMode = state?.user?.organization?.is_audit_only
        ? CLAIM_MODE_AUDIT
        : payload ||
          (claimMode === CLAIM_MODE_SUBMIT
            ? CLAIM_MODE_AUDIT
            : CLAIM_MODE_SUBMIT);
      return { ...state, claimMode };

    case AUTH_UPDATE_ORGANIZATION:
      user = { ...state.user, organization: payload };
      return { ...state, user };

    case AUTH_LOGIN:
      return {
        ...state,
        claimMode: DEFAULT_CLAIM_MODE,
        isAuthenticated: true,
        isLoadingAuth: true,
      };

    case AUTH_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
      };

    case AUTH_GET_SELF:
      if (payload?.organization?.is_audit_only) {
        claimMode = CLAIM_MODE_AUDIT;
      }
      return {
        ...state,
        user: payload,
        isLoadingAuth: false,
        claimMode,
      };

    case AUTH_LOGOUT:
      removeAccessTokens();
      return {
        ...state,
        claimMode: DEFAULT_CLAIM_MODE,
        user: null,
        isAuthenticated: false,
        isLoadingAuth: false,
      };
    default:
      return { ...state, isLoadingAuth: true };
  }
}
