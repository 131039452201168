import React from "react";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { useQueryFetchOrganizationPayments } from "@redux/actions/payments/organization-payments";
import { selectorAuthBillingConversion } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import OrganizationPaymentsGridProps from "./OrganizationPaymentsGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function OrganizationPayments({
  organization_payment_cycle__id,
}) {
  const billing_conversion = useSelector(selectorAuthBillingConversion);
  const useQueryFnProps = { filters: { organization_payment_cycle__id } };
  const dataGridProps = React.useMemo(
    () => OrganizationPaymentsGridProps({ billing_conversion }),
    [billing_conversion]
  );

  return (
    <CustomInfiniteDataGrid
      useQueryFn={useQueryFetchOrganizationPayments}
      useQueryFnProps={useQueryFnProps}
      dataGridProps={dataGridProps}
      initialSortModel={initialSortModel}
    />
  );
}
