import {
  getEmptyOrganizationBundleFormData,
  ORGANIZATION_BUNDLE_TYPE_MEDICATIONS,
  ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES,
} from "@constants/organizations/organization-bundles";
import {
  isInpatientVisitType,
  ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT,
  ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT,
} from "@constants/organizations/organization-patients";
import { parseIsoDate } from "@helpers/parse/time";
import { getOptions } from "../../helpers/hooks/objects";
import {
  CLAIM_STATUS_APPROVED,
  CLAIM_STATUS_PARTIALLY_APPROVED,
} from "./claim-status";

export const CLAIM_SECTION_WIDE = "CLAIM_SECTION_WIDE";
export const CLAIM_SECTION_VISIT_DETAILS = "CLAIM_SECTION_VISIT_DETAILS";
export const CLAIM_SECTION_ORGANIZATION_PATIENT =
  "CLAIM_SECTION_ORGANIZATION_PATIENT";
export const CLAIM_SECTION_SYMPTOMS = "CLAIM_SECTION_SYMPTOMS";
export const CLAIM_SECTION_DIAGNOSES = "CLAIM_SECTION_DIAGNOSES";
export const CLAIM_SECTION_PROCEDURES = "CLAIM_SECTION_PROCEDURES";
export const CLAIM_SECTION_MEDICATIONS = "CLAIM_SECTION_MEDICATIONS";

export const CLAIM_SECTION_VISIT_DETAILS_TITLE = "Visit details";
export const CLAIM_SECTION_ORGANIZATION_PATIENT_TITLE = "Patient details";
export const CLAIM_SECTION_ORGANIZATION_CLINICIAN_TITLE = "Clinician details";
export const CLAIM_SECTION_PROCEDURES_TITLE = "Procedures";
export const CLAIM_SECTION_MEDICATIONS_TITLE = "Medications";
export const CLAIM_SECTION_ATTACHMENTS_TITLE = "Attachments";
export const CLAIM_SECTION_ICD_TITLE_MAPPER = {
  [CLAIM_SECTION_SYMPTOMS]: "Patient presentation",
  [CLAIM_SECTION_DIAGNOSES]: "Diagnoses",
};

export const CLAIM_SECTION_ICD_TITLE_INVERSE_MAPPER = {
  [CLAIM_SECTION_SYMPTOMS]:
    CLAIM_SECTION_ICD_TITLE_MAPPER[CLAIM_SECTION_DIAGNOSES],
  [CLAIM_SECTION_DIAGNOSES]:
    CLAIM_SECTION_ICD_TITLE_MAPPER[CLAIM_SECTION_SYMPTOMS],
};

export const ICD_SYMPTOMS_TEXT_FIELDNAME = "symptoms_text";
export const ICD_DIAGNOSES_TEXT_FIELDNAME = "diagnoses_text";

export const CLAIM_SECTION_ICD_ICD_CODE_SUBTITLE = "ICD codes";

export const CLAIM_SECTION_ICD_TEXT_FIELDNAME_MAPPER = {
  [CLAIM_SECTION_SYMPTOMS]: ICD_SYMPTOMS_TEXT_FIELDNAME,
  [CLAIM_SECTION_DIAGNOSES]: ICD_DIAGNOSES_TEXT_FIELDNAME,
};

export const CLAIM_SECTION_ICD_TEXT_FIELDNAME_LABEL_MAPPER = {
  [CLAIM_SECTION_SYMPTOMS]: "Chief complaints",
  [CLAIM_SECTION_DIAGNOSES]: "Descriptions",
};

export const CLAIM_SECTION_ICD_FIELDNAME_MAPPER = {
  [CLAIM_SECTION_SYMPTOMS]: "claim_symptoms",
  [CLAIM_SECTION_DIAGNOSES]: "claim_diagnoses",
};

export const CLAIM_SECTION_ICD_FIELDNAME_INVERSE_MAPPER = {
  [CLAIM_SECTION_SYMPTOMS]:
    CLAIM_SECTION_ICD_FIELDNAME_MAPPER[CLAIM_SECTION_DIAGNOSES],
  [CLAIM_SECTION_DIAGNOSES]:
    CLAIM_SECTION_ICD_FIELDNAME_MAPPER[CLAIM_SECTION_SYMPTOMS],
};

export const CLAIM_SECTION_RESULTS_MAPPER = {
  [CLAIM_SECTION_WIDE]: "claim_evaluation_results",
  [CLAIM_SECTION_ORGANIZATION_PATIENT]:
    "claim_patient_details_evaluation_results",
  [CLAIM_SECTION_SYMPTOMS]: "claim_symptoms_evaluation_results",
  [CLAIM_SECTION_DIAGNOSES]: "claim_diagnoses_evaluation_results",
  [CLAIM_SECTION_PROCEDURES]: "claim_procedure_evaluation_results",
  [CLAIM_SECTION_MEDICATIONS]: "claim_medication_evaluation_results",
};

const getOrdinalSuffix = (num) =>
  ["st", "nd", "rd"][((((num + 90) % 100) - 10) % 10) - 1] || "th";

export const getIcdTtitle = ({ index, type }) => {
  switch (type) {
    case CLAIM_SECTION_SYMPTOMS:
      return { capitalized: "Symptom", lowercased: "symptom" };

    case CLAIM_SECTION_DIAGNOSES:
      if (index === 0)
        return { capitalized: "Primary ICD", lowercased: "primary ICD" };

      const prefix = `${index}${getOrdinalSuffix(index)}`;
      return {
        capitalized: `${prefix} Secondary ICD`,
        lowercased: `${prefix} secondary ICD`,
      };

    default:
      return { capitalized: "", lowercased: "" };
  }
};

const emptyIcdFormData = {
  code: null,
  description: "",
  icd_type: null,
};

export const formatClaimFormData = ({
  isCheck = true,
  formData = {},
  processor_organization__slug = undefined,
  claim_submission__id = undefined,
  submitter_user_note = undefined,
}) => {
  const {
    id = undefined,
    claim_form_number,
    visit_type,
    encounter_type,
    encounter_type_description,
    visit_date,
    discharge_date,
    admission_type,
    organization_patient = {},
    organization_clinician = {},
    claim_diagnoses = [],
    claim_procedures = [],
    claim_symptoms = [],
    claim_medications = [],
    claim_attachments = [],
    ...otherFormData
  } = formData;

  const symptoms_text = otherFormData[ICD_SYMPTOMS_TEXT_FIELDNAME];
  const diagnoses_text = otherFormData[ICD_DIAGNOSES_TEXT_FIELDNAME];

  const isInpatient = isInpatientVisitType(visit_type);
  const isOtherEncounter = isEncounterTypeOther(encounter_type);

  const emptyOrganizationProcedureFormData = getEmptyOrganizationBundleFormData(
    { bundle_type: ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES }
  );
  const emptyOrganizationMedicationFormData =
    getEmptyOrganizationBundleFormData({
      bundle_type: ORGANIZATION_BUNDLE_TYPE_MEDICATIONS,
    });

  return {
    id,
    is_check: isCheck,
    organization_patient__id: organization_patient?.id,
    organization_clinician__id: organization_clinician?.id,
    claim_form_number: claim_form_number?.trim(),
    visit_type,
    [ICD_SYMPTOMS_TEXT_FIELDNAME]: symptoms_text?.trim(),
    [ICD_DIAGNOSES_TEXT_FIELDNAME]: diagnoses_text?.trim(),
    admission_type: isInpatient ? admission_type : null,
    encounter_type,
    encounter_type_description: isOtherEncounter
      ? encounter_type_description?.trim()
      : null,
    visit_date: parseIsoDate(visit_date),
    discharge_date:
      isInpatient && discharge_date ? parseIsoDate(discharge_date) : null,
    claim_submission__id,
    processor_organization__slug,
    claim_diagnoses: claim_diagnoses.map(({ icd, ...other }) => ({
      icd: icd || emptyIcdFormData,
      ...other,
    })),
    claim_symptoms: claim_symptoms.map(({ icd, ...other }) => ({
      icd: icd || emptyIcdFormData,
      ...other,
    })),
    claim_procedures: claim_procedures.map(
      ({ organization_bundle, related_date, ...other }) => ({
        organization_bundle:
          organization_bundle || emptyOrganizationProcedureFormData,
        related_date: parseIsoDate(related_date),
        ...other,
      })
    ),
    claim_medications: claim_medications.map(
      ({ organization_bundle, related_date, ...other }) => ({
        organization_bundle:
          organization_bundle || emptyOrganizationMedicationFormData,
        related_date: parseIsoDate(related_date),
        ...other,
      })
    ),
    claim_attachments: claim_attachments.map(({ id, file }) =>
      Boolean(id) ? { id } : { file }
    ),
    submitter_user_note,
  };
};

export const getEmptyClaimFormData = ({ currency = "" }) => ({
  currency,
  organization_patient: null,
  organization_clinician: null,
  claim_form_number: "",
  [ICD_SYMPTOMS_TEXT_FIELDNAME]: "",
  [ICD_DIAGNOSES_TEXT_FIELDNAME]: "",
  visit_type: ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT,
  encounter_type: CLAIM_ENCOUNTER_TYPE_REGULAR,
  encounter_type_description: "",
  visit_date: new Date(),
  discharge_date: null,
  admission_type: CLAIM_ADMISSION_TYPE_MEDICAL,
  claim_procedures: [],
  claim_medications: [],
  claim_symptoms: [],
  claim_diagnoses: [],
  claim_attachments: [],
});

export const emptyClaimIcdFormData = {
  icd: null,
  description: "",
  note: "",
};

export const CLAIM_ENCOUNTER_TYPE_REGULAR = "R";
export const CLAIM_ENCOUNTER_TYPE_EMERGENCY = "E";
export const CLAIM_ENCOUNTER_TYPE_REFERRAL = "F";
export const CLAIM_ENCOUNTER_TYPE_OTHER = "O";

export const CLAIM_ENCOUNTER_TYPE_MAPPER = {
  [CLAIM_ENCOUNTER_TYPE_REGULAR]: "Regular",
  [CLAIM_ENCOUNTER_TYPE_EMERGENCY]: "Emergency",
  [CLAIM_ENCOUNTER_TYPE_REFERRAL]: "Referral",
  [CLAIM_ENCOUNTER_TYPE_OTHER]: "Other",
};

export const CLAIM_ENCOUNTER_TYPE_OPTIONS = getOptions(
  CLAIM_ENCOUNTER_TYPE_MAPPER
);

export const isEncounterTypeOther = (encounterType) =>
  encounterType === CLAIM_ENCOUNTER_TYPE_OTHER;

export const requireClaimAdmissionType = (visitType) =>
  visitType === ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT;

export const CLAIM_ADMISSION_TYPE_MEDICAL = "M";
export const CLAIM_ADMISSION_TYPE_SURGICAL = "S";

export const CLAIM_ADMISSION_TYPE_MAPPER = {
  [CLAIM_ADMISSION_TYPE_MEDICAL]: "Medical",
  [CLAIM_ADMISSION_TYPE_SURGICAL]: "Surgical",
};

export const CLAIM_ADMISSION_TYPE_OPTIONS = getOptions(
  CLAIM_ADMISSION_TYPE_MAPPER
);

export const CLAIM_VISIT_TYPE_MAPPER = {
  [ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT]: "Inpatient",
  [ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT]: "Outpatient",
};

export const CLAIM_VISIT_TYPE_OPTIONS = getOptions(CLAIM_VISIT_TYPE_MAPPER);

export const getOrganizationBundleBilledAmount = ({
  organization_bundle,
  visit_type,
  quantity = 1,
}) => {
  const {
    outpatient_discount_rate,
    inpatient_discount_rate,
    undiscounted_price,
  } = organization_bundle;
  const parsed_undiscounted_price = parseFloat(undiscounted_price) || 0;
  let price = parsed_undiscounted_price * quantity;

  if (visit_type === ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT) {
    if (inpatient_discount_rate)
      price *= (100 - parseFloat(inpatient_discount_rate)) / 100;
  } else if (visit_type === ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT) {
    if (outpatient_discount_rate)
      price *= (100 - parseFloat(outpatient_discount_rate)) / 100;
  }

  return price?.toFixed(2);
};

export const getClaimFinalStatus = ({ status, formData = {} }) => {
  const { claim_procedures = [], claim_medications = [] } = formData;
  const claimProcedureshavePartialApproval =
    claim_procedures.filter(
      ({ amount_approved, amount_requested }) =>
        parseFloat(amount_approved) !== parseFloat(amount_requested)
    ).length > 0;
  const claimMedicationsHavePartialApproval =
    claim_medications.filter(
      ({ amount_approved, amount_requested }) =>
        parseFloat(amount_approved) !== parseFloat(amount_requested)
    ).length > 0;

  if (
    status === CLAIM_STATUS_APPROVED &&
    (claimProcedureshavePartialApproval || claimMedicationsHavePartialApproval)
  )
    return CLAIM_STATUS_PARTIALLY_APPROVED;

  return status;
};

export const mdsMetaColsToMetaData = (meta_mds_cols = []) =>
  Object.fromEntries((meta_mds_cols || []).map((key) => [key, ""]));
