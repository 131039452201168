import React from "react";
import CustomTooltip from "@components/Custom/CustomTooltip";
import { LockIcon } from "@rimads/assets";
import { AVEY_BLACK } from "@styles/theme";

export default function ClaimSubmissionLockedTag({ time_locked = null }) {
  if (!Boolean(time_locked)) return null;

  return (
    <CustomTooltip
      title={
        <>
          <b>{"This claim submission is locked"}</b>
          <br />
          {"No more changes can be made to claims of this submission."}
        </>
      }
      placement="right"
    >
      <div style={{ display: "grid" }}>
        <LockIcon dimension={16} primary_fill={AVEY_BLACK} />
      </div>
    </CustomTooltip>
  );
}
