import React from "react";
import {
  ORGANIZATION_CARD_ACTION_DEACTIVATE,
  ORGANIZATION_CARD_ACTION_MAKE_PRIMARY,
  ORGANIZATION_CARD_ACTION_REPLACE,
} from "@constants/organizations/organization-cards";
import OrganizationCardDeactivateCard from "./OrganizationCardDeactivateCard";
import OrganizationCardMakePrimaryCard from "./OrganizationCardMakePrimaryCard";
import OrganizationCardReplaceCard from "./OrganizationCardReplaceCard";

export default function OrganizationCardAction({ state, setState = () => {} }) {
  const { object, action, isOpen } = state;
  const handleClose = React.useCallback(
    (e) => {
      e?.preventDefault();
      setState((prev) => ({ ...prev, isOpen: false }));
    },
    [setState]
  );

  switch (action) {
    case ORGANIZATION_CARD_ACTION_REPLACE:
      return (
        <OrganizationCardReplaceCard
          isOpen={isOpen}
          object={object}
          handleClose={handleClose}
        />
      );

    case ORGANIZATION_CARD_ACTION_DEACTIVATE:
      return (
        <OrganizationCardDeactivateCard
          isOpen={isOpen}
          object={object}
          handleClose={handleClose}
        />
      );

    case ORGANIZATION_CARD_ACTION_MAKE_PRIMARY:
      return (
        <OrganizationCardMakePrimaryCard
          isOpen={isOpen}
          object={object}
          handleClose={handleClose}
        />
      );

    default:
      return null;
  }
}
