import React from "react";
import CustomSection from "@components/Custom/CustomSection";
import {
  SYMPTOM_TYPE_OBJECTIVE,
  SYMPTOM_TYPE_SUBJECTIVE,
} from "@constants/avey-cowriter";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {
  CLAIM_DEMO_OBJECTIVE_SYMPTOMS_TITLE,
  CLAIM_DEMO_SUBJECTIVE_SYMPTOMS_TITLE,
  CLAIM_DEMO_SYMPTOMS_TITLE,
  selectorClaimDemoHasOrganizationPatient,
  selectorClaimDemoObjectiveSymptoms,
  selectorClaimDemoSubjectiveSymptoms,
  useClaimDemoContext,
} from "../context";
import ClaimDemoSymptomsListType from "./ClaimDemoSymptomsListType";

export default function ClaimDemoSymptoms() {
  const { state } = useClaimDemoContext();
  const subjectiveSymptoms = selectorClaimDemoSubjectiveSymptoms(state);
  const objectiveSymptoms = selectorClaimDemoObjectiveSymptoms(state);
  const hasOrganizationPatient = selectorClaimDemoHasOrganizationPatient(state);
  const isDownMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <CustomSection
      title={CLAIM_DEMO_SYMPTOMS_TITLE}
      isOpenDefault={hasOrganizationPatient}
      isCollapsable={false}
    >
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} md={5.5}>
          <ClaimDemoSymptomsListType
            title={CLAIM_DEMO_SUBJECTIVE_SYMPTOMS_TITLE}
            symptoms={subjectiveSymptoms}
            type={SYMPTOM_TYPE_SUBJECTIVE}
            addLabel="+ Add symptom"
            emptyLabel="No symptoms"
          />
        </Grid>
        <Grid item xs={12} md={1}>
          {isDownMd ? (
            <Divider />
          ) : (
            <div
              style={{
                height: "100%",
                display: "grid",
                justifyContent: "center",
              }}
            >
              <Divider orientation="vertical" />
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={5.5}>
          <ClaimDemoSymptomsListType
            title={CLAIM_DEMO_OBJECTIVE_SYMPTOMS_TITLE}
            symptoms={objectiveSymptoms}
            type={SYMPTOM_TYPE_OBJECTIVE}
            addLabel="+ Add sign or symptom"
            emptyLabel="No signs or symptoms"
          />
        </Grid>
      </Grid>
    </CustomSection>
  );
}
