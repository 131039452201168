import { ClockIcon } from "@rimads/assets";
import { getOptions } from "../../helpers/hooks/objects";
import { AVEY_GREY_DARK } from "@styles/theme";

export const USER_ORGANIZATION_ROLE_VIEWER = "V";
export const USER_ORGANIZATION_ROLE_EDITOR = "E";
export const USER_ORGANIZATION_ROLE_ADMIN = "Y";

export const USER_ORGANIZATION_ROLE_MAPPER = {
  [USER_ORGANIZATION_ROLE_VIEWER]: "Viewer",
  [USER_ORGANIZATION_ROLE_EDITOR]: "Editor",
  [USER_ORGANIZATION_ROLE_ADMIN]: "Admin",
};

export const USER_ORGANIZATION_ROLE_OPTIONS = getOptions(
  USER_ORGANIZATION_ROLE_MAPPER
);

export const USER_STATUS_ACTIVATED = "A";
export const USER_STATUS_DEACTIVATED = "D";
export const USER_STATUS_PENDING = "P";

export const USER_STATUS_MAPPER = {
  [USER_STATUS_ACTIVATED]: "Active",
  [USER_STATUS_DEACTIVATED]: "Deactivated",
  [USER_STATUS_PENDING]: "Pending",
};

export const USER_STATUS_OPTIONS = getOptions(USER_STATUS_MAPPER);

export const USER_STATUS_PROPS_MAPPER = {
  [USER_STATUS_ACTIVATED]: {
    color: "textPrimary",
  },
  [USER_STATUS_DEACTIVATED]: {
    color: "textSecondary",
  },
  [USER_STATUS_PENDING]: {
    color: "textSecondary",
    icon: <ClockIcon primary_fill={AVEY_GREY_DARK} />,
  },
};

export const USER_MANAGEMENT_ACTION_EDIT_PROFILE =
  "USER_MANAGEMENT_ACTION_EDIT_PROFILE";
export const USER_MANAGEMENT_ACTION_DEACTIVATE_ACCOUNT =
  "USER_MANAGEMENT_ACTION_DEACTIVATE_ACCOUNT";
export const USER_MANAGEMENT_ACTION_REINVITE_USER =
  "USER_MANAGEMENT_ACTION_REINVITE_USER";
