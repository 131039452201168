import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import {
  HOME_HREF,
  HOME_TITLE,
  USER_MANAGEMENT_FULL_HREF,
  USER_MANAGEMENT_TITLE,
} from "@constants/static/routing";

const hrefs = [
  {
    label: HOME_TITLE,
    href: HOME_HREF,
  },
  {
    label: USER_MANAGEMENT_TITLE,
    href: USER_MANAGEMENT_FULL_HREF,
  },
];

export default function UserManagementHeader() {
  return (
    <CustomHeader
      titleComponentProps={{ title: USER_MANAGEMENT_TITLE }}
      hrefs={hrefs}
    />
  );
}
