import React from "react";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import HealthcareOrganizationConfigurationsBody from "./HealthcareOrganizationConfigurationsBody";
import HealthcareOrganizationConfigurationsHeader from "./HealthcareOrganizationConfigurationsHeader";

export default function HealthcareOrganizationConfigurations() {
  return (
    <>
      <HealthcareOrganizationConfigurationsHeader />
      <CustomPage mt={0}>
        <HealthcareOrganizationConfigurationsBody />
      </CustomPage>
    </>
  );
}
