import React from "react";
import OrganizationPolicyAutocomplete from "@components/Custom/CustomAutocomplete/OrganizationPolicyAutocomplete";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import CustomSection from "@components/Custom/CustomSection";
import Grid from "@mui/material/Grid";

export default function OrganizationPatientPolicyDetailsFormData({
  formData = {},
  setFormData = () => {},
}) {
  const { patient_id, provider_file_number, organization_policy } = formData;

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeField = ({ fieldname, newValue }) =>
    setFormData((prev) => ({ ...prev, [fieldname]: newValue }));

  return (
    <CustomSection title="Policy details" isCollapsable={false}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <OrganizationPolicyAutocomplete
            value={organization_policy}
            handleChange={(newValue) =>
              handleChangeField({ fieldname: "organization_policy", newValue })
            }
            textFieldProps={{ required: false }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomOutlinedTextField
            required
            label="Member ID"
            name="patient_id"
            value={patient_id || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomOutlinedTextField
            label="Provider file number"
            name="provider_file_number"
            value={provider_file_number || ""}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </CustomSection>
  );
}
