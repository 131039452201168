import React from "react";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import useFetchClaimSubmission from "@helpers/hooks/useFetchObjects/useFetchClaimSubmission";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ClaimDemoAudio from "./ClaimDemoAudio";
import ClaimDemoDiagnosis from "./ClaimDemoDiagnosis";
import ClaimDemoHeader from "./ClaimDemoHeader";
import ClaimDemoOrganizationClinician from "./ClaimDemoOrganizationClinician";
import ClaimDemoOrganizationPatient from "./ClaimDemoOrganizationPatient";
import ClaimDemoPlan from "./ClaimDemoPlan";
import ClaimDemoQuestions from "./ClaimDemoQuestions";
import ClaimDemoReviewAndConfirm from "./ClaimDemoReviewAndConfirm";
import ClaimDemoObjectiveSummary from "./ClaimDemoSummaries/ClaimDemoObjectiveSummary";
import ClaimDemoSubjectiveSummary from "./ClaimDemoSummaries/ClaimDemoSubjectiveSummary";
import ClaimDemoSymptoms from "./ClaimDemoSymptoms";
import ClaimDemoContextWrapper from "./context";

export default function ClaimDemo() {
  const { data, hasData, isLoading } = useFetchClaimSubmission();

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  return (
    <>
      <ClaimDemoHeader object={data} />
      <CustomPage>
        <ClaimDemoContextWrapper object={data}>
          <Stack spacing={3}>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <ClaimDemoOrganizationClinician />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ClaimDemoOrganizationPatient />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ClaimDemoAudio />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ClaimDemoQuestions />
                </Grid>
              </Grid>
            </div>
            <ClaimDemoSymptoms />
            <ClaimDemoSubjectiveSummary />
            <ClaimDemoObjectiveSummary />
            <ClaimDemoDiagnosis />
            <ClaimDemoPlan />
            <ClaimDemoReviewAndConfirm />
          </Stack>
        </ClaimDemoContextWrapper>
      </CustomPage>
    </>
  );
}
