import React from "react";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import Typography from "@mui/material/Typography";
import { useQueryFetchOrganizationBundleHistory } from "@redux/actions/organizations/organization-bundles";
import { useParams } from "react-router-dom";
import OrganizationBundleData from "../OrganizationBundleData";
import { OrganizationBundleHeaderTitle } from "./OrganizationBundleHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { internal_code } = object;
  return (
    <Typography variant="body2" fontWeight="bold" className="ellipsis-1">
      {internal_code}
    </Typography>
  );
};

export default function OrganizationBundleVersionHistory() {
  const { organization_bundle__id: id } = useParams();
  const useQueryFnProps = { id };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchOrganizationBundleHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={OrganizationBundleData}
      VersionHistoryObjectTitleComponent={OrganizationBundleHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
