import React from "react";
import { CustomAmountApproved } from "@components/Custom/CustomAmountApproved";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomSection from "@components/Custom/CustomSection";
import {
  CLAIM_ERROR_FIELDNAMES_CLAIM_FORM_NUMBER,
  CLAIM_ERROR_FIELDNAMES_ENCOUNTER_TYPE,
  CLAIM_ERROR_FIELDNAMES_VISIT_DATE,
  CLAIM_ERROR_FIELDNAMES_VISIT_TYPE,
  isErrorFieldnames,
} from "@constants/claims/claim-evaluation-results";
import {
  CLAIM_ADMISSION_TYPE_MAPPER,
  CLAIM_ENCOUNTER_TYPE_MAPPER,
  CLAIM_SECTION_VISIT_DETAILS_TITLE,
  CLAIM_VISIT_TYPE_MAPPER,
  isEncounterTypeOther,
} from "@constants/claims/claims";
import { isInpatientVisitType } from "@constants/organizations/organization-patients";
import { parseDate } from "@helpers/parse/time";

export default function ClaimVisitData({
  object,
  useCustomSection = true,
  isMinified = false,
  useErrorFieldnames = false,
}) {
  const {
    claim_form_number,
    visit_type,
    encounter_type,
    encounter_type_description,
    visit_date,
    discharge_date,
    admission_type,
    // amount_gross,
    // amount_discount,
    // amount_copayment,
    amount_requested,
    amount_approved,
    currency,
    error_fieldnames = [],
  } = object;

  const errorFieldnames = isErrorFieldnames(
    useErrorFieldnames ? error_fieldnames || [] : []
  );

  const isInpatient = isInpatientVisitType(visit_type);
  const isOtherEncounter = isEncounterTypeOther(encounter_type);
  const visitType = CLAIM_VISIT_TYPE_MAPPER[visit_type] || "-";
  const encounterType = CLAIM_ENCOUNTER_TYPE_MAPPER[encounter_type] || "-";
  const visitDate = parseDate(visit_date) || "-";
  const encounterTypeDescription = encounter_type_description || "-";
  const dischargeDate = parseDate(discharge_date) || "-";
  const admissionType = CLAIM_ADMISSION_TYPE_MAPPER[admission_type] || "-";

  const {
    [CLAIM_ERROR_FIELDNAMES_CLAIM_FORM_NUMBER]: claim_form_number_error = false,
    [CLAIM_ERROR_FIELDNAMES_VISIT_TYPE]: visit_type_error = false,
    [CLAIM_ERROR_FIELDNAMES_ENCOUNTER_TYPE]: encounter_type_error = false,
    [CLAIM_ERROR_FIELDNAMES_VISIT_DATE]: visit_date_error = false,
  } = errorFieldnames;

  return (
    <CustomSection
      title={CLAIM_SECTION_VISIT_DETAILS_TITLE}
      useCustomSection={useCustomSection}
      isMinified={isMinified}
    >
      <CustomGrid>
        <CustomLabeledText
          label="Claim form number"
          value={claim_form_number}
          isErrorField={claim_form_number_error}
        />
        <CustomLabeledText label="Billed amount" useChildren={true}>
          <CustomAmountApproved
            object={{ amount_requested, amount_approved, currency }}
          />
        </CustomLabeledText>
        <CustomLabeledText
          label="Visit type"
          value={visitType}
          isErrorField={visit_type_error}
        />
        {isInpatient && (
          <CustomLabeledText label="Admission type" value={admissionType} />
        )}
        <CustomLabeledText
          label="Encounter type"
          value={encounterType}
          isErrorField={encounter_type_error}
        />
        {isOtherEncounter && (
          <CustomLabeledText
            label="Encounter type description"
            value={encounterTypeDescription}
          />
        )}
        <CustomLabeledText
          label="Visit date"
          value={visitDate}
          isErrorField={visit_date_error}
        />
        {isInpatient && (
          <CustomLabeledText label="Discharge date" value={dischargeDate} />
        )}
      </CustomGrid>
    </CustomSection>
  );
}
