import { getOptions } from "../../helpers/hooks/objects";
import { AVEY_BLUE, AVEY_YELLOW } from "@styles/theme";
import { TOKEN_SHORT_NAME_PLURAL } from "../static/payment";

export const PAYMENT_CARD_TYPE_MASTERCARD = "MASTERCARD";
export const PAYMENT_CARD_TYPE_VISA = "VISA";
export const PAYMENT_CARD_TYPE_AMEX = "AMEX";
export const PAYMENT_CARD_TYPE_OTHER = "OTHER";

export const PAYMENT_CARD_STATUS_VALID = "V";
export const PAYMENT_CARD_STATUS_EXPIRED = "E";
export const PAYMENT_CARD_STATUS_INVALID = "I";
export const PAYMENT_CARD_STATUS_DISABLED = "D";

export const PAYMENT_CARD_STATUS_MAPPER = {
  [PAYMENT_CARD_STATUS_VALID]: "Valid",
  [PAYMENT_CARD_STATUS_EXPIRED]: "Expired",
  [PAYMENT_CARD_STATUS_INVALID]: "Invalidated",
  [PAYMENT_CARD_STATUS_DISABLED]: "Disabled",
};

export const PAYMENT_PLAN_TIME_INTERVAL_ONE_WEEK = "W";
export const PAYMENT_PLAN_TIME_INTERVAL_TWO_WEEKS = "T";
export const PAYMENT_PLAN_TIME_INTERVAL_THREE_WEEKS = "S";
export const PAYMENT_PLAN_TIME_INTERVAL_ONE_MONTH = "M";

export const PAYMENT_PLAN_TIME_INTERVAL_MAPPER = {
  [PAYMENT_PLAN_TIME_INTERVAL_ONE_WEEK]: "once a week",
  [PAYMENT_PLAN_TIME_INTERVAL_TWO_WEEKS]: "once every 2 weeks",
  [PAYMENT_PLAN_TIME_INTERVAL_THREE_WEEKS]: "once every 3 weeks",
  [PAYMENT_PLAN_TIME_INTERVAL_ONE_MONTH]: "once a month",
};

export const PAYMENT_PLAN_TYPE_SUBSCRIPTION = "S";
export const PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO = "P";

export const PAYMENT_PLAN_TYPE_MAPPER = {
  [PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO]: "Pay as you go",
  [PAYMENT_PLAN_TYPE_SUBSCRIPTION]: "Subscription",
};

export const PAYMENT_PLAN_TYPE_DESCRIPTION_MAPPER = {
  [PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO]: (
    <>
      {`Consume ${TOKEN_SHORT_NAME_PLURAL} and `}
      <b>{"pay later"}</b>
      {"."}
    </>
  ),
  [PAYMENT_PLAN_TYPE_SUBSCRIPTION]: `Pay and consume ${TOKEN_SHORT_NAME_PLURAL}.`,
};

export const PAYMENT_PLAN_TYPE_TYPOGRAPHY_BG_COLOR_MAPPER = {
  [PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO]: "blue",
  [PAYMENT_PLAN_TYPE_SUBSCRIPTION]: "yellow",
};

export const PAYMENT_PLAN_TYPE_TOGGLE_COLOR_MAPPER = {
  [PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO]: AVEY_BLUE,
  [PAYMENT_PLAN_TYPE_SUBSCRIPTION]: AVEY_YELLOW,
};

export const PAYMENT_PLAN_TYPE_OPTIONS = getOptions(PAYMENT_PLAN_TYPE_MAPPER);

export const PAYMENT_PURPOSE_CARD_VERIFICATION = "CV";
export const PAYMENT_PURPOSE_MONTHLY_SUBSCRIPTION = "MS";
export const PAYMENT_PURPOSE_PAYMENT_PLAN_RENEWAL = "PL";
export const PAYMENT_PURPOSE_BILL_SETTLEMENT = "BS";
export const PAYMENT_PURPOSE_TOP_UP = "TU";
export const PAYMENT_PURPOSE_TOKEN_THRESHOLD_REACHED = "TT";

export const PAYMENT_PURPOSE_MAPPER = {
  [PAYMENT_PURPOSE_CARD_VERIFICATION]: "Card Verification",
  [PAYMENT_PURPOSE_MONTHLY_SUBSCRIPTION]: "Monthly Subscription",
  [PAYMENT_PURPOSE_PAYMENT_PLAN_RENEWAL]: "Plan Autorenewal",
  [PAYMENT_PURPOSE_BILL_SETTLEMENT]: "Bill settlement",
  [PAYMENT_PURPOSE_TOP_UP]: "Top up",
  [PAYMENT_PURPOSE_TOKEN_THRESHOLD_REACHED]: "Payout threshold reached",
};

export const PAYMENT_STATUS_SUCCESS = "S";
export const PAYMENT_STATUS_FAILURE = "F";
export const PAYMENT_STATUS_PENDING = "P";
export const PAYMENT_STATUS_UNKNOWN = "U";
export const PAYMENT_STATUS_REFUNDED = "R";

export const PAYMENT_STATUS_MAPPER = {
  [PAYMENT_STATUS_SUCCESS]: "Successful",
  [PAYMENT_STATUS_FAILURE]: "Failed",
  [PAYMENT_STATUS_PENDING]: "Pending",
  [PAYMENT_STATUS_UNKNOWN]: "Unknown",
  [PAYMENT_STATUS_REFUNDED]: "Refunded",
};
