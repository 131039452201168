import React from "react";
import HealthcareOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomButton from "@components/Custom/CustomButton";
import {
  cardPaymentMethodEmptyFormData,
  cardTokenEmptyFormData,
  cardTokenErrorEmptyFormData,
  validateCardFormData,
} from "@constants/organizations/organization-cards";
import useWindowPopupHook from "@helpers/hooks/useWindowPopupHook";
import {
  createCardToken,
  createOrReplaceOrganizationCard,
  invalidateOrganizationCardsTSQ,
} from "@redux/actions/organizations/organization-cards";
import { enqueueSnackbar } from "notistack";
import OrganizationCardTokenFormData from "./OrganizationCardTokenFormData";

const useEffectOrganizationCardCreateOrReplaceForm = ({
  isOpenReplace = undefined,
  objectIdToReplace = undefined,
  handleCloseCallback = undefined,
}) => {
  const [isOpen, setIsOpen] = React.useState(isOpenReplace || false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [cardTokenFormData, setCardTokenFormData] = React.useState(
    cardTokenEmptyFormData
  );
  const [cardPaymentMethodFormData, setCardFormData] = React.useState(
    cardPaymentMethodEmptyFormData
  );
  const [errors, setErrors] = React.useState(cardTokenErrorEmptyFormData);
  const { cardNumber } = cardTokenFormData;
  const { card_token_id, organization_payment_id, processor_url } =
    cardPaymentMethodFormData;

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmitPart1 = (e) => {
    e?.preventDefault();
    const potentialErrors = validateCardFormData(cardTokenFormData);
    if (Object.keys(potentialErrors).length > 0)
      return setErrors(potentialErrors);
    setIsProcessing(true);
    createCardToken(cardTokenFormData)
      .then(({ payload }) => {
        const { cardToken } = payload;
        const newCardPaymentFormData = {
          card_token_id: cardToken,
          card_first_6_digits: cardNumber?.substring(0, 6),
        };
        createOrReplaceOrganizationCard(
          newCardPaymentFormData,
          objectIdToReplace
        )
          .then(({ payload }) => {
            setIsProcessing(false);
            setCardFormData({
              ...newCardPaymentFormData,
              organization_payment_id: payload.id,
              processor_url: payload.processor_url,
            });
          })
          .catch(({ payload }) => {
            setIsProcessing(false);
            enqueueSnackbar({ variant: "error", message: payload });
          });
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        enqueueSnackbar({ variant: "error", message: payload });
      });
  };

  const handleSubmitPart2 = React.useCallback(() => {
    setIsProcessing(true);
    createOrReplaceOrganizationCard({
      card_token_id,
      organization_payment_id,
    })
      .then(() => {
        setIsProcessing(false);
        setIsOpen(false);
        enqueueSnackbar({
          variant: "success",
          message: "Payment method successfully added",
        });
        invalidateOrganizationCardsTSQ();
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        enqueueSnackbar({ variant: "error", message: payload });
      });
  }, [card_token_id, organization_payment_id]);

  React.useEffect(() => {
    setIsOpen(isOpenReplace || false);
  }, [isOpenReplace]);

  React.useEffect(() => {
    if (isOpen) {
      setErrors(cardTokenErrorEmptyFormData);
      setCardTokenFormData(cardTokenEmptyFormData);
    } else if (typeof handleCloseCallback === "function") {
      handleCloseCallback();
    }
    setCardFormData(cardPaymentMethodEmptyFormData);
  }, [isOpen, handleCloseCallback]);

  useWindowPopupHook({
    url: processor_url,
    title: "Set up payment method",
    handleCloseCallback: handleSubmitPart2,
  });

  return {
    isOpen,
    isProcessing,
    cardTokenFormData,
    errors,
    setErrors,
    setCardTokenFormData,
    handleSubmitPart1,
    handleIsOpen,
  };
};

export default function OrganizationCardCreateOrReplaceForm({
  dialogProps = {},
  withCreateButton = true,
  isOpenReplace = undefined,
  objectIdToReplace = undefined,
  handleCloseCallback = undefined,
}) {
  const {
    isOpen,
    isProcessing,
    cardTokenFormData,
    errors,
    setErrors,
    setCardTokenFormData,
    handleSubmitPart1,
    handleIsOpen,
  } = useEffectOrganizationCardCreateOrReplaceForm({
    isOpenReplace,
    objectIdToReplace,
    handleCloseCallback,
  });

  return (
    <>
      {withCreateButton && (
        <CustomButton
          fullWidth
          label="Add payment method"
          icon="PlusIcon"
          onClick={handleIsOpen}
        />
      )}
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmitPart1}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
        titleComponentProps={{ title: "New payment method" }}
        {...dialogProps}
      >
        <OrganizationCardTokenFormData
          errors={errors}
          formData={cardTokenFormData}
          setFormData={setCardTokenFormData}
          setErrors={setErrors}
        />
      </CustomActionDialog>
    </>
  );
}
