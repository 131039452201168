import React from "react";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  exportOrganizationPatients,
  useQueryFetchOrganizationPatientExports,
} from "@redux/actions/organizations/organization-patients";
import { useParams } from "react-router-dom";
import ConfigurationsBulkExportForm from "../../Configurations/ConfigurationsBulkExportForm";
import ConfigurationsBulkExportHistory from "../../Configurations/ConfigurationsBulkExportForm/ConfigurationsBulkExportHistory";
import InsuranceOrganizationActionDialogTitle from "../../Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import { describeFilters } from "./OrganizationPatientsFilters";

export default function OrganizationPatientsExports({ useQueryFnProps }) {
  const { organization__slug: processor_organization__slug } = useParams();
  return (
    <CustomMoreActions
      icon="DownloadIcon"
      useRotateProps={{ useRotate: false }}
    >
      <ConfigurationsBulkExportForm
        label="patients"
        submitFn={exportOrganizationPatients}
        useQueryFnProps={useQueryFnProps}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        describeFilters={describeFilters}
      />
      <ConfigurationsBulkExportHistory
        drawerProps={{
          TitleComponent: InsuranceOrganizationActionDialogTitle,
          titleComponentProps: { title: "Patient exports" },
        }}
        useQueryFn={useQueryFetchOrganizationPatientExports}
        useQueryFnProps={{ filters: { processor_organization__slug } }}
        describeFilters={describeFilters}
      />
    </CustomMoreActions>
  );
}
