import React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { CloseIcon } from "@rimads/assets";
import { AVEY_GREY } from "@styles/theme";
import CustomButton from "../CustomButton";
import CustomCircularProgress from "../CustomCircularProgress";
import CustomTitleComponent from "../CustomLayout/CustomTitleComponent";

const getHeaderPadding = (fullScreen = false) => ({
  xs: "40px 32px 0px 32px",
  sm: `${fullScreen ? "60px" : "40px"} 40px 0px 40px`,
});

const getFooterPadding = (fullScreen = false) => ({
  xs: "0px 32px 40px 32px",
  sm: `0px 40px ${fullScreen ? "60px" : "40px"} 40px`,
});

const fullScreenBoxProps = {
  maxWidth: 1380,
  m: "0px auto",
  width: "100%",
};

const iconP = { top: { xs: "16px" }, right: { xs: "16px" } };
export const customActionDialogBodyPy = { xs: "28px", sm: "32px" };
export const customActionDialogBodyPx = { xs: "32px", sm: "40px" };

const CustomDialogActions = ({
  isOk = false,
  okText = "Ok",
  noText = "Cancel",
  yesText = "Save",
  okButtonProps = {},
  noButtonProps = {},
  yesButtonProps = {},
  isProcessing = false,
  handleClose = () => {},
}) => {
  if (isOk)
    return (
      <CustomButton
        color="primary"
        variant="contained"
        type="submit"
        fullWidth
        disabled={isProcessing}
        label={okText}
        {...okButtonProps}
      />
    );

  return (
    <Grid
      container
      spacing={3}
      flexDirection={{ xs: "column-reverse", sm: "initial" }}
    >
      <Grid item xs={12} sm={6}>
        <CustomButton
          fullWidth
          variant="contained"
          color="info"
          onClick={handleClose}
          label={noText}
          {...noButtonProps}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomButton
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
          disabled={isProcessing}
          label={yesText}
          {...yesButtonProps}
        />
      </Grid>
    </Grid>
  );
};

const CloseIconComponent = ({
  handleClose = () => {},
  useCustomCloseIcon = false,
}) => {
  const boxProps = useCustomCloseIcon
    ? {}
    : {
        position: "absolute",
        display: "grid",
        justifyContent: "flex-end",
        zIndex: 2,
        sx: iconP,
      };
  return (
    <Box {...boxProps}>
      {useCustomCloseIcon ? (
        <CustomButton
          icon="CloseIcon"
          variant="outlined"
          isSquare={true}
          useIconAsLabel={true}
          onClick={handleClose}
        />
      ) : (
        <IconButton onClick={handleClose} size="small">
          <CloseIcon dimension={18} primary_fill={AVEY_GREY} />
        </IconButton>
      )}
    </Box>
  );
};

export default function CustomActionDialog({
  isOpen = false,
  isProcessing = false,
  maxWidth = "xs",
  TitleComponent = undefined,
  titleComponentProps = {},
  SubtitleComponent = undefined,
  subtitleComponentProps = {},
  bodyProps = {},
  dialogContentProps = {},
  handleSubmit = () => {},
  handleClose = () => {},
  isBodyEmpty = false,
  isOk = false,
  okText = "Ok",
  noText = "Cancel",
  yesText = "Save",
  okButtonProps = {},
  noButtonProps = {},
  yesButtonProps = {},
  children = null,
  fullScreen = false,
  disableEscapeKeyDown = false,
  displayActions = true,
  displayDialogContent = true,
  displayCloseIcon = true,
  useCustomCloseIcon = false,
  closeOnClickAway = false,
  useDivider = undefined,
}) {
  const memoizedTitle = React.useMemo(
    () => (
      <CustomTitleComponent
        TitleComponent={TitleComponent}
        titleComponentProps={{
          fontWeight: "bold",
          ...titleComponentProps,
        }}
      />
    ),
    [TitleComponent, titleComponentProps]
  );

  const memoizedSubtitle = React.useMemo(
    () => (
      <CustomTitleComponent
        TitleComponent={SubtitleComponent}
        titleComponentProps={{
          variant: "body2",
          fontWeight: "normal",
          ...subtitleComponentProps,
        }}
      />
    ),
    [SubtitleComponent, subtitleComponentProps]
  );

  const onClose = (e) => (closeOnClickAway ? handleClose(e) : null);

  const borderRadius = fullScreen ? 0 : "16px";
  const bodyPadding = isBodyEmpty
    ? { p: "16px" }
    : { px: customActionDialogBodyPx, py: customActionDialogBodyPy };
  const finalBodyProps = { ...bodyPadding, ...bodyProps };
  const headerP = getHeaderPadding(fullScreen);
  const footerP = getFooterPadding(fullScreen);
  const useSectionDivider =
    typeof useDivider === "undefined"
      ? fullScreen || maxWidth === "lg"
      : useDivider;

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onClose}
      maxWidth={maxWidth}
      PaperProps={{ sx: { borderRadius } }}
      fullScreen={fullScreen}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <DialogContent style={{ padding: 0 }} {...dialogContentProps}>
        {displayDialogContent ? (
          <>
            <Stack
              {...fullScreenBoxProps}
              position="sticky"
              className="gradient-to-bottom"
              top={0}
              zIndex={2}
              spacing={3}
            >
              <Stack
                spacing={2}
                p={headerP}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack spacing={2} flexGrow={1}>
                  {memoizedTitle}
                  {memoizedSubtitle}
                </Stack>
                {displayCloseIcon && (
                  <CloseIconComponent
                    handleClose={handleClose}
                    useCustomCloseIcon={useCustomCloseIcon}
                  />
                )}
              </Stack>
              {useSectionDivider && <Divider />}
            </Stack>
            <Stack
              {...fullScreenBoxProps}
              height="100%"
              component="form"
              onSubmit={handleSubmit}
            >
              <Stack spacing={3} flexGrow={1} {...finalBodyProps}>
                {children}
              </Stack>
              {displayActions && (
                <Stack
                  spacing={3}
                  position="sticky"
                  bottom={0}
                  zIndex={2}
                  className="gradient-to-top"
                >
                  {useSectionDivider && <Divider />}
                  <Box p={footerP}>
                    <CustomDialogActions
                      isOk={isOk}
                      okText={okText}
                      noText={noText}
                      yesText={yesText}
                      okButtonProps={okButtonProps}
                      noButtonProps={noButtonProps}
                      yesButtonProps={yesButtonProps}
                      isProcessing={isProcessing}
                      handleClose={handleClose}
                    />
                  </Box>
                </Stack>
              )}
            </Stack>
          </>
        ) : (
          children
        )}
      </DialogContent>
      <CustomCircularProgress invisible={false} open={isProcessing} />
    </Dialog>
  );
}
