import React from "react";
import CustomBox from "@components/Custom/CustomBox";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import {
  CLAIM_SECTION_MEDICATIONS_TITLE,
  CLAIM_SECTION_PROCEDURES_TITLE,
} from "@constants/claims/claims";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ClaimProcessorUpdateClaimMedicationFormData from "./ClaimProcessorUpdateClaimMedicationFormData";
import ClaimProcessorUpdateClaimProcedureFormData from "./ClaimProcessorUpdateClaimProcedureFormData";

const columnHeaders = (
  <div>
    <Grid container spacing={3} alignItems="center" columns={3}>
      {["Code", "Amount billed", "Amount approved"].map(
        (columnHeader, index) => (
          <Grid item xs={1} key={index}>
            <Typography
              variant="body2"
              color="textSecondary"
              key={columnHeader}
            >
              {columnHeader}
            </Typography>
          </Grid>
        )
      )}
    </Grid>
  </div>
);

export default function ClaimProcessorUpdateClaimFormData({
  showBillables = false,
  formData,
  handleChangeNote = () => {},
  handleChangeClaimProcedure = () => {},
  handleChangeClaimMedication = () => {},
}) {
  const {
    claim_procedures = [],
    claim_medications = [],
    processor_user_note,
  } = formData;

  const hasProcedures = claim_procedures.length > 0;
  const hasMedications = claim_medications.length > 0;

  return (
    <>
      {showBillables && (
        <>
          {hasProcedures && (
            <CustomLabeledText
              label={CLAIM_SECTION_PROCEDURES_TITLE}
              useChildren={true}
            >
              <CustomBox className="elevated-8 borderRadius-8" p="20px">
                <Stack spacing={1}>
                  {columnHeaders}
                  {claim_procedures.map((claim_procedure, index) => (
                    <ClaimProcessorUpdateClaimProcedureFormData
                      key={index}
                      claim_procedure={claim_procedure}
                      handleChangeClaimProcedure={handleChangeClaimProcedure}
                    />
                  ))}
                </Stack>
              </CustomBox>
            </CustomLabeledText>
          )}
          {hasMedications && (
            <CustomLabeledText
              label={CLAIM_SECTION_MEDICATIONS_TITLE}
              useChildren={true}
            >
              <CustomBox className="elevated-8 borderRadius-8" p="20px">
                <Stack spacing={1}>
                  {columnHeaders}
                  {claim_medications.map((claim_medication, index) => (
                    <ClaimProcessorUpdateClaimMedicationFormData
                      key={index}
                      claim_medication={claim_medication}
                      handleChangeClaimMedication={handleChangeClaimMedication}
                    />
                  ))}
                </Stack>
              </CustomBox>
            </CustomLabeledText>
          )}
        </>
      )}
      <CustomOutlinedTextField
        multiline
        minRows={3}
        autoComplete="off"
        label="Additional notes"
        placeholder="Write any notes"
        value={processor_user_note}
        onChange={handleChangeNote}
      />
    </>
  );
}
