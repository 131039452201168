import React from "react";
import CptAutocomplete from "@components/Custom/CustomAutocomplete/CptAutocomplete";
import CustomButton from "@components/Custom/CustomButton";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import CustomSection, {
  customSectionBodyPadding,
} from "@components/Custom/CustomSection";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import { emptyOrganizationBundleCptFormData } from "@constants/organizations/organization-bundles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

export default function OrganizationBundleCptsFormData({
  formData = [],
  handleChange = () => {},
}) {
  const hasCpts = formData.length > 0;
  const handleAddOrganizationBundleCpt = () =>
    handleChange([...formData, emptyOrganizationBundleCptFormData]);

  const handleChangeOrganizationBundleCpt = ({ index, payload }) =>
    handleChange(
      formData.map((bundleCpt, position) =>
        position === index ? payload : bundleCpt
      )
    );

  const handleRemoveOrganizationBundleCpt = ({ index }) => {
    handleChange(formData.filter((_, position) => position !== index));
  };

  return (
    <CustomSection
      title="Procedures"
      isCollapsable={false}
      bodyProps={{ p: 0 }}
    >
      {formData.map((bundleCptFormData, index) => (
        <div key={index}>
          {index !== 0 && <Divider />}
          <Box p={customSectionBodyPadding}>
            <OrganizationBundleCptFormData
              index={index}
              formData={bundleCptFormData}
              handleChangeOrganizationBundleCpt={
                handleChangeOrganizationBundleCpt
              }
              handleRemoveOrganizationBundleCpt={
                handleRemoveOrganizationBundleCpt
              }
            />
          </Box>
        </div>
      ))}
      {hasCpts && <Divider />}
      <Box p={customSectionBodyPadding}>
        <CustomTypographyButton
          value="+ Add procedure"
          onClick={handleAddOrganizationBundleCpt}
        />
      </Box>
    </CustomSection>
  );
}

function OrganizationBundleCptFormData({
  index,
  formData,
  handleChangeOrganizationBundleCpt = () => {},
  handleRemoveOrganizationBundleCpt = () => {},
}) {
  const { cpt, frequency } = formData;

  const handleRemoveSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleRemoveOrganizationBundleCpt({ index });
  };

  const handleChangeCpt = (newValue) =>
    handleChangeOrganizationBundleCpt({
      index,
      payload: { ...formData, cpt: newValue },
    });

  const handleChangeFrequency = (e) =>
    handleChangeOrganizationBundleCpt({
      index,
      payload: { ...formData, frequency: e.target.value },
    });

  return (
    <Grid container columns={9} spacing={3} alignItems="flex-end">
      <Grid item xs={12} sm={6}>
        <CptAutocomplete
          value={cpt}
          wrapperProps={{ flex: 1 }}
          handleChange={handleChangeCpt}
        />
      </Grid>
      <Grid item xs>
        <CustomOutlinedTextField
          required
          label="Frequency"
          name="frequency"
          value={frequency}
          onChange={handleChangeFrequency}
          wrapperProps={{ flex: 1 }}
          type="number"
          inputProps={{ min: 1, step: 1 }}
        />
      </Grid>
      <Grid item>
        <CustomButton
          icon="CloseIcon"
          variant="outlined"
          isSquare={true}
          useIconAsLabel={true}
          onClick={handleRemoveSubmit}
        />
      </Grid>
    </Grid>
  );
}
