import React from "react";
import CustomLabeledCheckbox from "@components/Custom/CustomLabeledCheckbox";

export const claimExportUseReportInitialFormData = { use_report: false };

export default function ClaimExportUseReportFormData({
  formData,
  setFormData,
}) {
  const { use_report } = formData;
  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, use_report: e.target.checked }));

  return (
    <CustomLabeledCheckbox
      checked={use_report}
      label="Include a report summary for each claim"
      onChange={handleChange}
    />
  );
}
