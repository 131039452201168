import React from "react";
import { ORGANIZATION_ROLE_SUBMITTER } from "@constants/organizations/organizations";
import { useQueryFetchOrganization } from "@redux/actions/organizations/organizations";
import { toggleClaimMode } from "@redux/actions/users/auth";
import { selectorAuthCurrentUserOrganizationRole } from "@redux/selectors/auth";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function useFetchOrganization() {
  const { organization__slug } = useParams();
  const dispatch = useDispatch();
  const organizationRole = useSelector(selectorAuthCurrentUserOrganizationRole);
  const { isLoading, data } = useQueryFetchOrganization({ organization__slug });
  const hasData = Boolean(data);

  React.useEffect(() => {
    if (
      !isLoading &&
      hasData &&
      organizationRole === ORGANIZATION_ROLE_SUBMITTER
    ) {
      const { claim_mode } = data;
      dispatch(toggleClaimMode(claim_mode));
    }
  }, [dispatch, isLoading, data, hasData, organizationRole]);

  return { isLoading, data, hasData, organization__slug };
}
