import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import CustomValueWithUnit from "./CustomValueWithUnit";
import { getIntOrFloat } from "@helpers/parse/numbers";

export default function CustomProgressBar({
  color = "secondary",
  nominator = 0,
  denominator = 1,
  wrapperProps = {},
}) {
  const percent = nominator === 0 ? 0 : (100 * nominator) / denominator;

  return (
    <Stack direction="row" alignItems="center" spacing={2} {...wrapperProps}>
      <LinearProgress
        style={{ flexGrow: 1 }}
        variant="determinate"
        value={percent}
        color={color}
        className="thick"
      />
      {denominator !== 0 && (
        <CustomValueWithUnit
          value={getIntOrFloat(nominator)}
          separator="/"
          unit={getIntOrFloat(denominator)}
        />
      )}
    </Stack>
  );
}
