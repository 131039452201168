import React from "react";
import { CustomDndListSingleton } from "@components/Custom/CustomDnd/CustomDnd";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  CLAIM_DEMO_CLINICIAN_ASSESSMENT_TITLE,
  selectorClaimDemoClinicianDiagnoses,
  useClaimDemoContext,
} from "../context";
import ClaimDemoDiagnosisClinicianAddDisease from "./ClaimDemoDiagnosisClinicianAddDisease";
import ClaimDemoDiagnosisClinicianDisease from "./ClaimDemoDiagnosisClinicianDisease";

export default function ClaimDemoDiagnosisClinician() {
  const { state, handleRemoveClaimDiagnosis, handleReorderClaimDiagnoses } =
    useClaimDemoContext();
  const claimDiagnoses = selectorClaimDemoClinicianDiagnoses(state);
  const hasClaimDiagnoses = claimDiagnoses.length > 0;

  return (
    <Stack spacing={2}>
      <Typography fontWeight="bold">
        {CLAIM_DEMO_CLINICIAN_ASSESSMENT_TITLE}
      </Typography>
      {hasClaimDiagnoses && (
        <div>
          <CustomDndListSingleton
            list={claimDiagnoses}
            setList={handleReorderClaimDiagnoses}
            renderObject={({ object, index }) => (
              <ClaimDemoDiagnosisClinicianDisease
                object={object}
                handleRemove={() => handleRemoveClaimDiagnosis(index)}
              />
            )}
          />
        </div>
      )}
      <ClaimDemoDiagnosisClinicianAddDisease />
    </Stack>
  );
}
