import React from "react";
import CustomButton from "@components/Custom/CustomButton";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import Stack from "@mui/material/Stack";

export default function CustomActionButtons({
  handleEdit = undefined,
  handleRemove = undefined,
  CustomEditComponent = undefined,
  customEditComponentProps = {},
  CustomRemoveComponent = undefined,
  customRemoveComponentProps = {},
  children = undefined,
  direction = "row",
}) {
  const hasChildren = Boolean(children);
  const hasEdit = Boolean(handleEdit);
  const hasRemove = Boolean(handleRemove);
  const hasCustomEditComponent = Boolean(CustomEditComponent);
  const hasCustomRemoveComponent = Boolean(CustomRemoveComponent);

  return (
    <Stack
      spacing={2}
      direction={direction}
      alignItems="center"
      textAlign="center"
    >
      {hasChildren && <CustomMoreActions>{children}</CustomMoreActions>}
      {hasEdit && (
        <CustomButton
          isSquare={true}
          icon="EditIcon"
          useIconAsLabel={true}
          variant="outlined"
          onClick={handleEdit}
        />
      )}
      {hasCustomEditComponent && (
        <CustomEditComponent {...customEditComponentProps} />
      )}
      {hasRemove && (
        <CustomButton
          isSquare={true}
          icon="DeleteIcon"
          useIconAsLabel={true}
          variant="outlined"
          onClick={handleRemove}
        />
      )}
      {hasCustomRemoveComponent && (
        <CustomRemoveComponent {...customRemoveComponentProps} />
      )}
      {CustomRemoveComponent}
    </Stack>
  );
}
