import React from "react";
import CustomSection, {
  getCustomSectionBodyPadding,
} from "@components/Custom/CustomSection";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import OrganizationBundleRelatedObjectData from "./OrganizationBundleRelatedObjectData";

export default function OrganizationBundleConsumablesData({
  organization_bundle_consumables = [],
  isMinified = false,
}) {
  const p = getCustomSectionBodyPadding(isMinified);
  if (organization_bundle_consumables.length === 0) return null;

  return (
    <CustomSection
      title="Consumables"
      bodyProps={{ p: 0 }}
      isMinified={isMinified}
    >
      {organization_bundle_consumables.map(
        ({ frequency, consumable }, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <Box p={p}>
              <OrganizationBundleRelatedObjectData
                label="Consumable"
                frequency={frequency}
                title={consumable.name}
                description={consumable.description}
              />
            </Box>
          </div>
        )
      )}
    </CustomSection>
  );
}
