import {
  PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO,
  PAYMENT_PLAN_TYPE_SUBSCRIPTION,
} from "@constants/payments/payments";
import { AVEY_BLOOM_BACKEND_CURRENCY_CODE } from "@constants/static/globals";
import { getIntOrFloat } from "./numbers";

export const tokensToMoney = ({
  num_tokens = 0,
  rate_per_token = 0,
  billing_conversion = {
    code: AVEY_BLOOM_BACKEND_CURRENCY_CODE,
    rate: 1,
  },
}) => {
  const amount = num_tokens * rate_per_token;
  return moneyWithCurrency({ amount, billing_conversion });
};

export const moneyWithCurrency = ({
  amount,
  billing_conversion = {
    code: AVEY_BLOOM_BACKEND_CURRENCY_CODE,
    rate: 1,
  },
}) => {
  const { rate, code } = billing_conversion;
  const finalAmount = amount * rate;
  return `${getIntOrFloat(finalAmount)} ${code}`.trim();
};

export const getPayAsYouGoConsumption = ({
  num_tokens,
  num_tokens_consumed,
  pay_as_you_go_rate_per_token,
  pay_as_you_go_usage_token_threshold,
  billing_conversion = {
    code: AVEY_BLOOM_BACKEND_CURRENCY_CODE,
    rate: 1,
  },
}) => {
  const { code, rate = 1 } = billing_conversion || {};
  const amount =
    num_tokens < 0 &&
    tokensToMoney({
      num_tokens: Math.abs(num_tokens),
      rate_per_token: pay_as_you_go_rate_per_token,
      billing_conversion: { code: "", rate },
    });

  return {
    amountNumerator: amount,
    amountDenominator: code,
    balanceNumerator: getIntOrFloat(num_tokens_consumed),
    balanceDenominator: `/ ${getIntOrFloat(
      pay_as_you_go_usage_token_threshold
    )}`,
  };
};

export const getSubscriptionConsumption = ({
  num_tokens,
  num_tokens_consumed,
}) => {
  return {
    balanceNumerator: getIntOrFloat(num_tokens_consumed),
    balanceDenominator: getIntOrFloat(`/ ${num_tokens_consumed + num_tokens}`),
  };
};

export const getOrganizationPaymentCycleConsumption = ({
  num_tokens,
  num_tokens_consumed,
  pay_as_you_go_rate_per_token,
  pay_as_you_go_usage_token_threshold,
  payment_plan_type,
  billing_conversion = {
    currency: AVEY_BLOOM_BACKEND_CURRENCY_CODE,
    rate: 1,
  },
}) => {
  switch (payment_plan_type) {
    case PAYMENT_PLAN_TYPE_SUBSCRIPTION:
      return getSubscriptionConsumption({
        num_tokens,
        num_tokens_consumed,
      });

    case PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO:
    default:
      return getPayAsYouGoConsumption({
        num_tokens,
        num_tokens_consumed,
        pay_as_you_go_rate_per_token,
        pay_as_you_go_usage_token_threshold,
        billing_conversion,
      });
  }
};
