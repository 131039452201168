import React from "react";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import { getDrugName } from "@constants/avey/medications";
import Typography from "@mui/material/Typography";
import { useQueryFetchMedicationHistory } from "@redux/actions/avey/medications";
import { useParams } from "react-router-dom";
import MedicationData from "../MedicationData";
import { MedicationHeaderTitle } from "./MedicationHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { code, trade_name, generic_name } = object;
  const tradeName = getDrugName({ drug_name: trade_name });
  const genericName = getDrugName({ drug_name: generic_name });
  return (
    <Typography variant="body2" className="ellipsis-1">
      <b>{code}</b> {`${tradeName} ${genericName}`}
    </Typography>
  );
};

export default function MedicationVersionHistory() {
  const { medication__id: id } = useParams();
  const useQueryFnProps = { id };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchMedicationHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={MedicationData}
      VersionHistoryObjectTitleComponent={MedicationHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
