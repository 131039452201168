import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import { updatePassword } from "@redux/actions/users/auth";
import { enqueueSnackbar } from "notistack";
import AccountActionDialogTitle from "../Account/AccountActionDialogTitle";
import AccountDetailsFormData from "./AccountDetailsFormData";

const initialFormData = {
  current_password: "",
  new_password: "",
};

const initialErrors = {
  current_password: "",
  new_password: "",
};

export default function AccountDetailsUpdateForm({ user }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(initialFormData);
  const [errors, setErrors] = React.useState(initialErrors);

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setErrors(initialErrors);
    updatePassword(formData)
      .then(() => {
        setIsProcessing(false);
        setIsOpen(false);
        enqueueSnackbar({
          message: "Password successfully updated",
          variant: "success",
        });
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        setErrors(payload);
      });
  };

  React.useEffect(() => {
    if (isOpen) {
      setFormData(initialFormData);
      setErrors(initialErrors);
    }
  }, [isOpen]);

  return (
    <>
      <CustomLabeledText
        label="Password"
        value="Change password"
        typographyProps={{
          className: "underlined button opacityOnHover-50",
          fontWeight: "bold",
          onClick: handleIsOpen,
        }}
      />
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        TitleComponent={AccountActionDialogTitle}
        titleComponentProps={{ title: "Change password", user }}
      >
        <AccountDetailsFormData
          errors={errors}
          formData={formData}
          setFormData={setFormData}
        />
      </CustomActionDialog>
    </>
  );
}
