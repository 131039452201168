import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomSection from "@components/Custom/CustomSection";

export default function IcdData({
  object,
  useCustomSection = true,
  isMinified = false,
}) {
  const { code, description } = object || {};

  return (
    <CustomSection
      title="ICD details"
      useCustomSection={useCustomSection}
      isMinified={isMinified}
    >
      <CustomGrid>
        <CustomLabeledText label="Code" value={code} />
        <CustomLabeledText label="Description" value={description} />
      </CustomGrid>
    </CustomSection>
  );
}
