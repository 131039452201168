import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import MenuItem from "@mui/material/MenuItem";
import CustomButton from "../CustomButton";
import CustomHeader from "../CustomLayout/CustomHeader";
import CustomNoResultsFound from "../CustomNoResultsFound";
import CustomVersionHistoryDrawer from "./CustomVersionHistoryDrawer";

export default function CustomVersionHistory({
  menuItemLabel = "Show version history",
  ...otherProps
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleIsOpen = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <MenuItem onClick={handleIsOpen}>{menuItemLabel}</MenuItem>
      <CustomActionDialog
        fullScreen={true}
        displayDialogContent={false}
        disableEscapeKeyDown={true}
        displayCloseIcon={false}
        displayActions={false}
        useCustomCloseIcon={true}
        useDivider={false}
        isOpen={isOpen}
        handleClose={handleIsOpen}
      >
        {isOpen && (
          <CustomVersionHistoryContent
            handleClose={handleIsOpen}
            {...otherProps}
          />
        )}
      </CustomActionDialog>
    </>
  );
}

function CustomVersionHistoryContent({
  drawerProps = {},
  handleClose = () => {},
  useQueryFn = () => {},
  useQueryFnProps = {},
  VersionHistoryObjectDataComponent = () => null,
  versionHistoryObjectDataComponentProps = {},
  VersionHistoryObjectTabComponent = () => null,
  versionHistoryObjectTabComponentProps = {},
  VersionHistoryObjectTitleComponent = () => null,
  versionHistoryObjectTitleComponentProps = {},
}) {
  const dialogRef = React.useRef();
  const [version, setVersion] = React.useState(null);
  const handleChangeVersion = React.useCallback((newVersion) => {
    setVersion(newVersion);
    dialogRef.current &&
      dialogRef.current.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const infiniteQuery = useQueryFn(useQueryFnProps);
  const { isLoading, data } = infiniteQuery;
  const hasContent = Boolean(version);

  React.useEffect(() => {
    if (!isLoading && Boolean(data)) {
      try {
        const first = data?.pages[0]?.results[0];
        Boolean(first) && handleChangeVersion(first);
      } catch (error) {}
    }
  }, [handleChangeVersion, isLoading, data]);

  return (
    <>
      <div style={{ display: "flex" }}>
        <CustomVersionHistoryDrawer
          version={version}
          handleChangeVersion={handleChangeVersion}
          infiniteQuery={infiniteQuery}
          VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
          objectComponentProps={versionHistoryObjectTabComponentProps}
          isLoading={isLoading}
          {...drawerProps}
        />
        <div style={{ width: "100%" }}>
          {hasContent ? (
            <>
              <CustomHeader
                TitleComponent={VersionHistoryObjectTitleComponent}
                titleComponentProps={{
                  object: version,
                  ...versionHistoryObjectTitleComponentProps,
                }}
                withDivider={true}
              >
                <CustomButton
                  variant="outlined"
                  icon="CloseIcon"
                  isSquare={true}
                  useIconAsLabel={true}
                  onClick={handleClose}
                />
              </CustomHeader>
              <CustomPage isPage={true}>
                <VersionHistoryObjectDataComponent
                  object={version}
                  {...versionHistoryObjectDataComponentProps}
                />
              </CustomPage>
            </>
          ) : (
            !isLoading && (
              <>
                <CustomHeader
                  titleComponentProps={{ title: "No results found." }}
                  withDivider={true}
                >
                  <CustomButton
                    variant="outlined"
                    icon="CloseIcon"
                    isSquare={true}
                    useIconAsLabel={true}
                    onClick={handleClose}
                  />
                </CustomHeader>
                <CustomPage isPage={true}>
                  <CustomNoResultsFound
                    wrapperProps={{ textAlign: "center" }}
                    src="/assets/images/placeholders/no-results.png"
                  />
                </CustomPage>
              </>
            )
          )}
        </div>
      </div>
    </>
  );
}
