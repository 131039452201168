import React from "react";
import { getMedicationOptionLabel } from "@components/Custom/CustomAutocomplete/MedicationAutocomplete/MedicationAutocompleteComponents";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CloseIcon, RadioButtonSelectedIcon } from "@rimads/assets";
import { AVEY_BLACK } from "@styles/theme";
import { TypeAnimation } from "react-type-animation";

export default function ClaimDemoMedication({
  claim_medication,
  handleRemove = () => {},
  isEditable = true,
  withTypeAnimation = false,
}) {
  const { medication } =
    claim_medication?.organization_bundle?.organization_bundle_medication;
  const medicationLabel = getMedicationOptionLabel(medication, true, false);

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      className="hoverOpacityParent"
      minHeight={28}
    >
      <div
        style={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          minWidth: 24,
        }}
      >
        <RadioButtonSelectedIcon dimension={7} primary_fill={AVEY_BLACK} />
      </div>
      <div style={{ flexGrow: 1 }}>
        <Typography
          variant="body2"
          className="ellipsis-1"
          title={medicationLabel}
        >
          {withTypeAnimation ? (
            <TypeAnimation
              key={medicationLabel}
              sequence={[medicationLabel]}
              wrapper="span"
              speed={50}
              repeat={false}
              cursor={false}
            />
          ) : (
            medicationLabel
          )}
        </Typography>
      </div>
      {isEditable && (
        <IconButton
          className="opacity-0 hoverOpacityChild"
          onClick={handleRemove}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
}
