import React from "react";
import { useRotateClassName } from "@helpers/hooks/useRotateClassName";
import CardActionArea from "@mui/material/CardActionArea";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ArrowDownIcon } from "@rimads/assets";
import { AVEY_BLACK, AVEY_WHITE } from "@styles/theme";
import CustomBox from "./CustomBox";

const getHeaderPadding = (isMinified = false) => ({
  xs: isMinified ? "12px 18px" : "12px 24px",
  sm: isMinified ? "12px 18px" : "12px 32px",
});

export const getCustomSectionBodyPadding = (isMinified = false) => ({
  xs: isMinified ? "18px" : "24px",
  sm: isMinified ? "18px" : "32px",
});

export const customSectionBodyPadding = getCustomSectionBodyPadding(false);
export const customSectionMinifiedBodyPadding =
  getCustomSectionBodyPadding(true);

const CustomSectionHeaderWrapper = ({
  isCollapsable = true,
  isCardActionArea = true,
  handleIsOpen = () => {},
  children,
}) => {
  if (isCollapsable && isCardActionArea)
    return <CardActionArea onClick={handleIsOpen}>{children}</CardActionArea>;

  return children;
};

const iconColorMapper = {
  "": AVEY_BLACK,
  black: AVEY_WHITE,
  yellow: AVEY_BLACK,
  red: AVEY_WHITE,
};

const CustomSectionIconButtonWrapper = ({
  color = "",
  isOpen = true,
  isMinified = false,
  isCollapsable = true,
  isCardActionArea = true,
  handleIsOpen = () => {},
}) => {
  const rotateClassName = useRotateClassName({ isOpen });
  const dimension = isMinified ? 10 : 12;
  const icon = (
    <ArrowDownIcon
      className={rotateClassName}
      primary_fill={iconColorMapper[color]}
      dimension={dimension}
    />
  );
  if (!isCollapsable) return null;
  if (isCardActionArea) return icon;
  return <IconButton onClick={handleIsOpen}>{icon}</IconButton>;
};

const headerColorMapper = {
  "": "greyBgF9",
  black: "blackBg",
  yellow: "yellowBg",
  red: "redBg",
};

export default function CustomSection({
  title,
  subtitle,
  children,
  isCollapsable = true,
  isCardActionArea = true,
  headerProps = {},
  color = "",
  bodyProps = {},
  action = null,
  isOpenDefault = true,
  isMinified = false,
  useCustomSection = true,
}) {
  const [isOpen, setIsOpen] = React.useState(isOpenDefault);
  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };
  const borderRadius = isMinified ? 8 : 16;
  const colorClassName = headerColorMapper[color] || headerColorMapper[""];
  const finalHeaderClassName = `${
    isOpen ? `borderRadiusTop-${borderRadius}` : `borderRadius-${borderRadius}`
  } ${colorClassName}`.trim();

  const titleVariant = isMinified ? "body1" : "h6";
  const subtitleVariant = isMinified ? "caption" : "body2";
  const finalHeaderPadding = getHeaderPadding(isMinified);
  const finalBodyPadding = getCustomSectionBodyPadding(isMinified);

  React.useEffect(() => {
    setIsOpen(isOpenDefault);
  }, [isOpenDefault]);

  if (!useCustomSection) return <div>{children}</div>;

  return (
    <div>
      <CustomSectionHeaderWrapper
        isCollapsable={isCollapsable}
        isCardActionArea={isCardActionArea}
        handleIsOpen={handleIsOpen}
      >
        <CustomBox
          className={finalHeaderClassName}
          p={finalHeaderPadding}
          {...headerProps}
        >
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack spacing={1} direction="row" alignItems="baseline">
              <Typography
                variant={titleVariant}
                fontWeight="bold"
                title={title}
                className="ellipsis-1"
              >
                {title}
              </Typography>
              <Typography variant={subtitleVariant}>{subtitle}</Typography>
            </Stack>
            {action}
            <CustomSectionIconButtonWrapper
              color={color}
              isOpen={isOpen}
              isMinified={isMinified}
              handleIsOpen={handleIsOpen}
              isCollapsable={isCollapsable}
              isCardActionArea={isCardActionArea}
            />
          </Stack>
        </CustomBox>
      </CustomSectionHeaderWrapper>
      <Collapse in={isOpen}>
        <CustomBox
          className={`borderRadiusBottom-${borderRadius} outlined-light`}
          minHeight={30}
          p={finalBodyPadding}
          {...bodyProps}
        >
          {children}
        </CustomBox>
      </Collapse>
    </div>
  );
}
