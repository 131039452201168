import React from "react";
import CustomPopper from "@components/Custom/CustomPopper";
import {
  ACCOUNT_FULL_HREF,
  ACCOUNT_TITLE,
  BILLING_MANAGEMENT_FULL_HREF,
  BILLING_MANAGEMENT_TITLE,
  getOrganizationCliniciansHref,
  HEALTHCARE_CONFIGURATIONS_TITLE,
  INSURANCE_CONFIGURATIONS_FULL_HREF,
  INSURANCE_CONFIGURATIONS_TITLE,
  LOGOUT_HREF,
  LOGOUT_TITLE,
  USER_MANAGEMENT_FULL_HREF,
  USER_MANAGEMENT_TITLE,
  YOUR_ORGANIZATION_FULL_HREF,
  YOUR_ORGANIZATION_TITLE,
} from "@constants/static/routing";
import { useRotateClassName } from "@helpers/hooks/useRotateClassName";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {
  selectorAuthHasAdminPrivileges,
  selectorAuthHasSubmitterPrivileges,
} from "@redux/selectors/auth";
import { ArrowDownIcon } from "@rimads/assets";
import { AVEY_BLACK } from "@styles/theme";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import NavigationTopAppBarHelpDialog from "./NavigationTopAppBarHelpDialog";
import NavigationTopAppBarSubmitFeedbackForm from "./NavigationTopAppBarSubmitFeedbackForm";

const getRoutes = ({ hasAdminPrivileges, hasSubmitterPrivileges }) =>
  [
    {
      label: ACCOUNT_TITLE,
      href: ACCOUNT_FULL_HREF,
    },
    {
      label: YOUR_ORGANIZATION_TITLE,
      href: YOUR_ORGANIZATION_FULL_HREF,
    },
    {
      label: HEALTHCARE_CONFIGURATIONS_TITLE,
      href: getOrganizationCliniciansHref({}),
      predicate: hasSubmitterPrivileges,
    },
    {
      label: INSURANCE_CONFIGURATIONS_TITLE,
      href: INSURANCE_CONFIGURATIONS_FULL_HREF,
      predicate: hasSubmitterPrivileges,
    },
    {
      label: USER_MANAGEMENT_TITLE,
      href: USER_MANAGEMENT_FULL_HREF,
      predicate: hasAdminPrivileges,
    },
    {
      label: BILLING_MANAGEMENT_TITLE,
      href: BILLING_MANAGEMENT_FULL_HREF,
      predicate: hasAdminPrivileges && hasSubmitterPrivileges,
    },
    { Component: NavigationTopAppBarSubmitFeedbackForm },
    { Component: NavigationTopAppBarHelpDialog },
    {
      label: LOGOUT_TITLE,
      href: LOGOUT_HREF,
    },
  ].filter(({ predicate = true }) => predicate);

export default function NavigationTopAppBarMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const rotateClassName = useRotateClassName({ isOpen: Boolean(anchorEl) });
  const handleMouseEnter = (e) => setAnchorEl(e.currentTarget);
  const handleMouseLeave = () => setAnchorEl(null);

  const hasSubmitterPrivileges = useSelector(
    selectorAuthHasSubmitterPrivileges
  );
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const routes = React.useMemo(
    () => getRoutes({ hasSubmitterPrivileges, hasAdminPrivileges }),
    [hasSubmitterPrivileges, hasAdminPrivileges]
  );

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseEnter}
    >
      <IconButton
        className="whiteBg"
        style={{ height: 40, width: 40, display: "grid" }}
      >
        <ArrowDownIcon primary_fill={AVEY_BLACK} className={rotateClassName} />
      </IconButton>
      <div>
        <CustomPopper anchorEl={anchorEl} handleAnchorEl={setAnchorEl}>
          {routes.map((route, index) => (
            <NavigationTopaAppBarMenuComponent
              key={index}
              route={route}
              handleClose={handleMouseLeave}
            />
          ))}
        </CustomPopper>
      </div>
    </div>
  );
}

const NavigationTopaAppBarMenuComponent = ({
  route = {},
  handleClose = () => {},
}) => {
  const { label, href, Component = undefined } = route;
  const isComponent = Boolean(Component);

  if (isComponent)
    return (
      <div>
        <Component />
      </div>
    );

  return (
    <NavLink to={href} onClick={handleClose}>
      {({ isActive }) => (
        <ListItemButton sx={{ px: 3 }}>
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              fontWeight: isActive ? "bold" : "normal",
              variant: "body2",
            }}
          />
        </ListItemButton>
      )}
    </NavLink>
  );
};
