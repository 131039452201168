import React from "react";
import CustomChip from "@components/Custom/CustomChip";
import CustomLabeledRow from "@components/Custom/CustomLabeledRow";
import CustomValueWithUnit from "@components/Custom/CustomValueWithUnit";
import {
  hasCoverageTypeCoverageRate,
  hasCoverageTypeFixedAmount,
  ORGANIZATION_POLICY_COVERAGE_TYPE_MAPPER,
} from "@constants/organizations/organization-policies";
import { getIntOrFloat } from "@helpers/parse/numbers";
import Stack from "@mui/material/Stack";

export default function OrganizationPolicyCoverageDataSummary({ object }) {
  if (!Boolean(object)) return null;

  const {
    coverage_type,
    fixed_amount,
    coverage_rate,
    preauthorization_limit,
    currency,
  } = object;

  const hasFixedAmount = hasCoverageTypeFixedAmount(coverage_type);
  const hasCoverageRate = hasCoverageTypeCoverageRate(coverage_type);

  return (
    <CustomChip
      title={
        <Stack spacing={0.5} style={{ padding: "8px" }}>
          {hasCoverageRate && (
            <CustomLabeledRow
              label="Coverage rate"
              disableValueTypography={true}
              value={
                <CustomValueWithUnit
                  value={getIntOrFloat(coverage_rate)}
                  unit="%"
                  valueTypographyProps={{ fontWeight: "bold" }}
                />
              }
            />
          )}
          {hasFixedAmount && (
            <CustomLabeledRow
              label="Fixed amount"
              disableValueTypography={true}
              value={
                <CustomValueWithUnit
                  value={getIntOrFloat(fixed_amount)}
                  unit={currency}
                  valueTypographyProps={{ fontWeight: "bold" }}
                />
              }
            />
          )}
          <CustomLabeledRow
            label="Preauthorization limit"
            disableValueTypography={true}
            value={
              <CustomValueWithUnit
                value={getIntOrFloat(preauthorization_limit)}
                unit={currency}
                valueTypographyProps={{ fontWeight: "bold" }}
              />
            }
          />
        </Stack>
      }
      name={ORGANIZATION_POLICY_COVERAGE_TYPE_MAPPER[coverage_type]}
      color="lightGrey"
    />
  );
}
