import React from "react";
import { CustomDndListSingleton } from "@components/Custom/CustomDnd/CustomDnd";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import CustomNotProvidedText from "@components/Custom/CustomNotProvidedText";
import CustomSection from "@components/Custom/CustomSection";
import { getClaimIcdsSectionId } from "@constants/claims/claim-evaluation-results";
import {
  CLAIM_SECTION_ICD_FIELDNAME_INVERSE_MAPPER,
  CLAIM_SECTION_ICD_FIELDNAME_MAPPER,
  CLAIM_SECTION_ICD_ICD_CODE_SUBTITLE,
  CLAIM_SECTION_ICD_TEXT_FIELDNAME_MAPPER,
  CLAIM_SECTION_ICD_TITLE_MAPPER,
  CLAIM_SECTION_RESULTS_MAPPER,
  CLAIM_SECTION_SYMPTOMS,
} from "@constants/claims/claims";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ClaimEvaluationResults from "../Claim/ClaimEvaluationResults";
import { useClaimContext } from "../Claim/context";
import ClaimIcdCreateForm from "./ClaimIcdCreateForm";
import ClaimIcdData from "./ClaimIcdData";
import ClaimIcdUpdateForm from "./ClaimIcdUpdateForm";
import ClaimIcdsTextData from "./ClaimIcdsText/ClaimIcdsTextData";
import ClaimIcdsTextFormData from "./ClaimIcdsText/ClaimIcdsTextFormData";

export default function ClaimIcds({ type = CLAIM_SECTION_SYMPTOMS }) {
  const {
    isCreateOrUpdateMode = false,
    claim = {},
    setFormData = () => {},
    icd_type,
  } = useClaimContext();
  const [state, setState] = React.useState({
    isOpen: false,
    object: null,
    index: 0,
  });
  const fieldname = CLAIM_SECTION_ICD_FIELDNAME_MAPPER[type];
  const inverse_fieldname = CLAIM_SECTION_ICD_FIELDNAME_INVERSE_MAPPER[type];
  const claimIcdsTextFieldname = CLAIM_SECTION_ICD_TEXT_FIELDNAME_MAPPER[type];
  const sectionId = getClaimIcdsSectionId({ isCreateOrUpdateMode, type });
  const title = CLAIM_SECTION_ICD_TITLE_MAPPER[type];
  const claim_icds = claim?.[fieldname] || [];
  const claim_icds_text = claim?.[claimIcdsTextFieldname] || "";
  const results = claim?.[CLAIM_SECTION_RESULTS_MAPPER[type]] || [];
  const numClaimIcds = claim_icds.length;
  const hasClaimIcds = numClaimIcds > 0;
  const hasResults = results.length > 0;
  const subtitle = hasClaimIcds > 0 && numClaimIcds;

  const handleSelectClaimIcd = React.useCallback(
    ({ object, index }) => setState({ isOpen: true, object, index }),
    []
  );

  const handleAddClaimIcd = React.useCallback(
    ({ payload }) => {
      setFormData((prev) => ({
        ...prev,
        [fieldname]: [...prev[fieldname], payload],
      }));
    },
    [fieldname, setFormData]
  );

  const handleUpdateClaimIcd = React.useCallback(
    ({ payload, index }) => {
      setFormData((prev) => ({
        ...prev,
        [fieldname]: prev[fieldname].map((object, position) =>
          index === position ? payload : object
        ),
      }));
    },
    [fieldname, setFormData]
  );

  const handleRemoveClaimIcd = React.useCallback(
    ({ index }) => {
      setFormData((prev) => ({
        ...prev,
        [fieldname]: prev[fieldname].filter(
          (_, position) => index !== position
        ),
      }));
    },
    [fieldname, setFormData]
  );

  const handleReorderClaimIcds = React.useCallback(
    (payload) => {
      setFormData((prev) => ({ ...prev, [fieldname]: payload }));
    },
    [fieldname, setFormData]
  );

  const handleMigrateClaimIcd = React.useCallback(
    ({ index }) => {
      setFormData((prev) => {
        const new_claim_icds = [...prev[fieldname]];
        const [claim_icd] = new_claim_icds.splice(index, 1);

        return {
          ...prev,
          [fieldname]: new_claim_icds,
          [inverse_fieldname]: [
            ...(prev?.[inverse_fieldname] || []),
            claim_icd,
          ],
        };
      });
    },
    [fieldname, inverse_fieldname, setFormData]
  );

  const handleBlurClaimIcdsText = (e) =>
    setFormData((prev) => ({
      ...prev,
      [claimIcdsTextFieldname]: e.target.value,
    }));

  return (
    <>
      <CustomSection
        title={title}
        subtitle={subtitle}
        isCollapsable={!isCreateOrUpdateMode}
        bodyProps={{ id: sectionId, style: { scrollMarginTop: 166 } }}
      >
        <Stack spacing={3}>
          {isCreateOrUpdateMode ? (
            <>
              <CustomListItemCollapse
                primary={CLAIM_SECTION_ICD_ICD_CODE_SUBTITLE}
              >
                <Stack spacing={3}>
                  {hasClaimIcds ? (
                    <CustomDndListSingleton
                      spacing={3}
                      list={claim_icds}
                      setList={handleReorderClaimIcds}
                      useDivider={true}
                      renderObject={({ object, index }) => (
                        <Box py={1} className="whiteBg">
                          <Box p={2} className="elevated-4 borderRadius-8">
                            <ClaimIcdData
                              index={index}
                              type={type}
                              object={object}
                              useErrorFieldnames={true}
                              isCreateOrUpdateMode={isCreateOrUpdateMode}
                              handleSelectClaimIcd={handleSelectClaimIcd}
                              handleRemoveClaimIcd={handleRemoveClaimIcd}
                              handleMigrateClaimIcd={handleMigrateClaimIcd}
                            />
                          </Box>
                        </Box>
                      )}
                    />
                  ) : (
                    <CustomNotProvidedText
                      title={CLAIM_SECTION_ICD_ICD_CODE_SUBTITLE}
                    />
                  )}
                  <ClaimIcdCreateForm
                    index={numClaimIcds}
                    type={type}
                    handleAddClaimIcd={handleAddClaimIcd}
                    icd_type={icd_type}
                  />
                </Stack>
              </CustomListItemCollapse>
              <Divider />
              <ClaimIcdsTextFormData
                type={type}
                claim_icds_text={claim_icds_text}
                handleBlur={handleBlurClaimIcdsText}
              />
            </>
          ) : (
            <>
              <CustomListItemCollapse
                primary={CLAIM_SECTION_ICD_ICD_CODE_SUBTITLE}
              >
                <Stack spacing={3}>
                  {hasClaimIcds ? (
                    claim_icds.map((object, index) => (
                      <React.Fragment key={index}>
                        {index !== 0 && <Divider />}
                        <ClaimIcdData
                          index={index}
                          type={type}
                          object={object}
                          useErrorFieldnames={true}
                          isCreateOrUpdateMode={isCreateOrUpdateMode}
                        />
                      </React.Fragment>
                    ))
                  ) : (
                    <CustomNotProvidedText
                      title={CLAIM_SECTION_ICD_ICD_CODE_SUBTITLE}
                    />
                  )}
                </Stack>
              </CustomListItemCollapse>
              <Divider />
              <ClaimIcdsTextData
                type={type}
                claim_icds_text={claim_icds_text}
              />
            </>
          )}
          {hasResults && (
            <>
              <Divider />
              <ClaimEvaluationResults
                results={results}
                isCreateOrUpdateMode={isCreateOrUpdateMode}
                type={type}
              />
            </>
          )}
        </Stack>
      </CustomSection>
      {isCreateOrUpdateMode && (
        <ClaimIcdUpdateForm
          type={type}
          state={state}
          setState={setState}
          handleUpdateClaimIcd={handleUpdateClaimIcd}
          icd_type={icd_type}
        />
      )}
    </>
  );
}
