import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import {
  BILLING_MANAGEMENT_FULL_HREF,
  BILLING_MANAGEMENT_TITLE,
  HOME_HREF,
  HOME_TITLE,
  PAYMENT_METHODS_FULL_HREF,
  PAYMENT_METHODS_TITLE,
} from "@constants/static/routing";

const hrefs = [
  {
    label: HOME_TITLE,
    href: HOME_HREF,
  },
  {
    label: BILLING_MANAGEMENT_TITLE,
    href: BILLING_MANAGEMENT_FULL_HREF,
  },
  {
    label: PAYMENT_METHODS_TITLE,
    href: PAYMENT_METHODS_FULL_HREF,
  },
];

export default function OrganizationCardsHeader() {
  return (
    <CustomHeader
      titleComponentProps={{ title: PAYMENT_METHODS_TITLE }}
      hrefs={hrefs}
    />
  );
}
