import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";

export default function CustomInputAdornment({ position = "end", value = "" }) {
  return (
    <InputAdornment position={position}>
      <Typography variant="caption">{value}</Typography>
    </InputAdornment>
  );
}
