import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import {
  AVEY_COWRITER_TITLE,
  getClaimDemoHref,
  getClaimSubmissionHref,
  HOME_HREF,
  HOME_TITLE,
} from "@constants/static/routing";
import { useParams } from "react-router-dom";

const title = AVEY_COWRITER_TITLE;

export default function ClaimDemoHeader({ object }) {
  const { organization__slug } = useParams();
  const {
    id,
    name,
    tpa_organization,
    submitter_organization,
    processor_organization,
  } = object;

  const organization = [
    tpa_organization,
    submitter_organization,
    processor_organization,
  ]
    .filter(Boolean)
    .find(({ slug }) => slug === organization__slug);

  const hrefs = [
    {
      label: HOME_TITLE,
      href: HOME_HREF,
    },
    {
      label: organization?.name || organization__slug,
      href: getClaimSubmissionHref({ organization__slug }),
    },
    {
      label: name,
      href: getClaimSubmissionHref({
        organization__slug,
        claim_submission__id: id,
      }),
    },
    {
      label: title,
      href: getClaimDemoHref({
        organization__slug,
        claim_submission__id: id,
      }),
    },
  ];

  return <CustomHeader titleComponentProps={{ title }} hrefs={hrefs} />;
}
