import React from "react";
import SnackbarProvider from "@components/SnackbarProvider";
import { HOME_HREF } from "@constants/static/routing";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import store from "@redux/store";
import theme from "@styles/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthenticatedRoutes from "./authenticated";
import UnauthenticatedRoutes from "./unauthenticated";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

dayjs.extend(utc);
dayjs.extend(advancedFormat);

export const ReactQueryClient = new QueryClient();

export default function Index() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ReduxProvider store={store}>
          <QueryClientProvider client={ReactQueryClient}>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            <SnackbarProvider>
              <BrowserRouter>
                <Routes>
                  <Route
                    path={`${HOME_HREF}/*`}
                    Component={AuthenticatedRoutes}
                  />
                  <Route path="*" Component={UnauthenticatedRoutes} />
                </Routes>
              </BrowserRouter>
            </SnackbarProvider>
          </QueryClientProvider>
        </ReduxProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
