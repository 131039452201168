import React from "react";
import HealthcareOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import {
  CustomSummaryDialog,
  initialSummaryState,
} from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import CustomButton from "@components/Custom/CustomButton";
import { formatOrganizationClinicianFormData } from "@constants/organizations/organization-clinicians";
import { getOrganizationCliniciansHref } from "@constants/static/routing";
import {
  invalidateOrganizationCliniciansTSQ,
  updateOrganizationClinician,
} from "@redux/actions/organizations/organization-clinicians";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import OrganizationClinicianData from "../OrganizationClinicianData";
import OrganizationClinicianFormData from "../OrganizationClinicianFormData";

export default function OrganizationClinicianUpdateForm({
  object,
  isSquare = false,
  withNavigate = true,
  handleSelectClinician = () => {},
}) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(object);
  const [summaryState, setSummaryState] = React.useState(initialSummaryState);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const { isOpen: isOpenSummary, summaries } = summaryState;
  const { isOpen: isOpenAlert } = alertState;
  const isOpenMainDialog = isOpen && !isOpenSummary && !isOpenAlert;
  const isOpenSummaryDialog = isOpenSummary && !isOpenAlert;

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e, isCheck = true) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProcessing(true);
    const formattedFormData = formatOrganizationClinicianFormData({
      isCheck,
      formData,
    });
    updateOrganizationClinician(formattedFormData)
      .then(({ payload }) => {
        const { instance, summaries, exceptions } = payload;
        if (Boolean(instance)) {
          setIsOpen(false);
          enqueueSnackbar({
            variant: "success",
            message: "Clinician successfully updated",
          });
          invalidateOrganizationCliniciansTSQ();
          if (withNavigate) {
            navigate(
              getOrganizationCliniciansHref({
                organization_clinician__id: instance.id,
              })
            );
          } else {
            handleSelectClinician(instance);
            setIsOpen(false);
          }
        } else if (exceptions?.length > 0) {
          setAlertState({ isOpen: true, errors: exceptions });
        } else {
          setSummaryState({ isOpen: true, summaries });
        }
      })
      .catch(({ payload }) => {
        setAlertState({ isOpen: true, errorStr: payload });
        setSummaryState((prev) => ({ ...prev, isOpen: false }));
      })
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitData = (e) => handleSubmit(e, false);
  const handleCheckData = (e) => handleSubmit(e, true);

  React.useEffect(() => {
    if (isOpen) {
      setFormData(object);
      setAlertState(initialAlertState);
    }
    setSummaryState(initialSummaryState);
  }, [isOpen, object]);

  return (
    <>
      <CustomButton
        isSquare={isSquare}
        useIconAsLabel={isSquare}
        variant={isSquare ? "outlined" : "contained"}
        label="Edit clinician"
        icon="EditIcon"
        onClick={handleIsOpen}
      />
      <CustomActionDialog
        isOpen={isOpenMainDialog}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleCheckData}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
        titleComponentProps={{ title: "Edit clinician" }}
      >
        <OrganizationClinicianFormData
          formData={formData}
          setFormData={setFormData}
        />
      </CustomActionDialog>
      <CustomSummaryDialog
        summaries={summaries}
        isOpen={isOpenSummaryDialog}
        isProcessing={isProcessing}
        setSummaryState={setSummaryState}
        handleSubmit={handleSubmitData}
        ContentComponent={OrganizationClinicianData}
        contentComponentProps={{
          object: formData,
          useCustomSection: true,
          isMinified: true,
        }}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
      />
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
    </>
  );
}
