import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomButton from "@components/Custom/CustomButton";
import {
  getClaimHref,
  getClaimSubmissionHref,
} from "@constants/static/routing";
import { SPARKLES_EMOJI } from "@constants/static/text";
import { useURLSearch } from "@helpers/api/getQueryParams";
import { NavLink, useNavigate, useParams } from "react-router-dom";

export default function ClaimRedirectToLatestVersion({
  latestVersionId = null,
  isCreateOrUpdateMode = false,
  handleResetForm = () => {},
}) {
  const { organization__slug, claim_submission__id, claim__id } = useParams();
  const urlSearch = useURLSearch();
  const claimHref = getClaimHref({
    organization__slug,
    claim_submission__id,
    claim__id: latestVersionId,
    urlSearch,
  });
  const claimSubmissionHref = getClaimSubmissionHref({
    organization__slug,
    claim_submission__id,
  });

  if (!latestVersionId || claim__id === latestVersionId) return null;

  if (isCreateOrUpdateMode)
    return (
      <ClaimRedirectToLatestVersionCreateMode
        handleResetForm={handleResetForm}
        claimHref={claimHref}
        claimSubmissionHref={claimSubmissionHref}
      />
    );

  return <ClaimRedirectToLatestVersionDefaultMode claimHref={claimHref} />;
}

function ClaimRedirectToLatestVersionCreateMode({
  handleResetForm = () => {},
  claimHref = "",
  claimSubmissionHref = "",
}) {
  return (
    <CustomActionDialog
      isOpen={true}
      displayActions={false}
      handleClose={handleResetForm}
      displayCloseIcon={false}
      titleComponentProps={{
        title: `Your claim is being processed ${SPARKLES_EMOJI}`,
        variant: "h4",
        textAlign: "center",
      }}
      bodyProps={{ spacing: 2 }}
    >
      <CustomButton component={NavLink} to={claimHref} label="View claim" />
      <CustomButton
        label="Add another claim"
        onClick={handleResetForm}
        variant="outlined"
      />
      <CustomButton
        component="a"
        href={claimSubmissionHref}
        color="info"
        label="Go back home"
      />
    </CustomActionDialog>
  );
}

function ClaimRedirectToLatestVersionDefaultMode({ claimHref = "" }) {
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e?.preventDefault();
    navigate(claimHref);
  };
  return (
    <CustomActionDialog
      isOpen={true}
      disableEscapeKeyDown={true}
      displayCloseIcon={false}
      handleSubmit={handleSubmit}
      titleComponentProps={{ title: "New version alert" }}
      subtitleComponentProps={{ title: "A newer version of this claim exists" }}
      isBodyEmpty={true}
      isOk={true}
      okText="Take me to it"
    />
  );
}
