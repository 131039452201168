import {
  TIME_DELETED_ISNULL_FILTER,
  TIME_UPDATED_FILTER,
  timeDeletedIsNullApplyFilter,
  timeUpdatedApplyFilter,
} from "@constants/static/export";

export const initialFilterState = {
  time_updated: [null, null],
  time_deleted__isnull: [],
};

export const filterOptions = {
  time_updated: TIME_UPDATED_FILTER,
  time_deleted__isnull: TIME_DELETED_ISNULL_FILTER,
};

export const formatFilters = (filters = {}) => {
  let formattedFilters = {};
  const { time_updated = [null, null], time_deleted__isnull = [] } = filters;

  formattedFilters = timeUpdatedApplyFilter({
    formattedFilters,
    time_updated,
  });

  formattedFilters = timeDeletedIsNullApplyFilter({
    formattedFilters,
    time_deleted__isnull,
  });

  return formattedFilters;
};
