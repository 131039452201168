export const ORGANIZATION_ROLE_TPA = "T";
export const ORGANIZATION_ROLE_SUBMITTER = "S";
export const ORGANIZATION_ROLE_PROCESSOR = "P";

export const ORGANIZATION_ROLE_MAPPER = {
  [ORGANIZATION_ROLE_TPA]: "TPA",
  [ORGANIZATION_ROLE_SUBMITTER]: "Healthcare provider",
  [ORGANIZATION_ROLE_PROCESSOR]: "Insurer",
};

export const getOrganizationNameGivenOrganizationRole = ({
  organizationRole,
  claim_submission,
}) => {
  const { processor_organization, submitter_organization } = claim_submission;

  switch (organizationRole) {
    case ORGANIZATION_ROLE_SUBMITTER:
      return processor_organization.name;

    case ORGANIZATION_ROLE_PROCESSOR:
    case ORGANIZATION_ROLE_TPA:
      return submitter_organization.name;

    default:
      return "";
  }
};
