import React from "react";
import CustomAnimatedCode from "@components/Custom/CustomAnimatedCode";
import CustomSection from "@components/Custom/CustomSection";
import {
  CLAIM_SECTION_SYMPTOMS,
  CLAIM_SECTION_ICD_TITLE_MAPPER,
} from "@constants/claims/claims";
import Stack from "@mui/material/Stack";

export default function ClaimDemoClaimIcdsReviewAndConfirm({
  objects = [],
  type = CLAIM_SECTION_SYMPTOMS,
}) {
  const title = CLAIM_SECTION_ICD_TITLE_MAPPER[type];
  const numObjects = objects.length;

  if (numObjects === 0) return null;

  return (
    <CustomSection title={title} isMinified={true}>
      <Stack spacing={2}>
        {objects.map(({ name, icd__code, isLoading = false }, index) => (
          <CustomAnimatedCode
            key={index}
            name={name}
            code={icd__code}
            isLoading={isLoading}
          />
        ))}
      </Stack>
    </CustomSection>
  );
}
