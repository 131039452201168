import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomPasswordTextField from "@components/Custom/CustomPasswordTextField";
import { STATIC_PASSWORD_HELPER_TEXT } from "@constants/static/auth";
import Stack from "@mui/material/Stack";

export default function SetPasswordFormData({
  email,
  password,
  passwordError,
  handleChange = () => {},
}) {
  return (
    <>
      <input
        name="email"
        type="email"
        value={email}
        style={{ display: "none" }}
        onChange={() => {}}
      />
      <Stack spacing={3} textAlign="start">
        <CustomLabeledText label="Email" value={email} />
        <CustomPasswordTextField
          required
          fullWidth
          autoFocus
          autoComplete="off"
          variant="standard"
          label="Password"
          name="password"
          onChange={handleChange}
          value={password}
          InputLabelProps={{ required: false }}
          helperText={passwordError || STATIC_PASSWORD_HELPER_TEXT}
          error={Boolean(passwordError)}
        />
      </Stack>
    </>
  );
}
