import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  getClaimSubmissionHref,
  HOME_HREF,
  HOME_TITLE,
} from "@constants/static/routing";
import { formatDateRange } from "@helpers/parse/time";
import { invalidateClaimsTSQ } from "@redux/actions/claims/claims";
import {
  selectorAuthHasProcessorPrivileges,
  selectorAuthHasSubmitterPrivileges,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ClaimSubmissionLockForm from "./ClaimSubmissionForms/ClaimSubmissionLockForm";
import ClaimSubmissionUpdateForm from "./ClaimSubmissionForms/ClaimSubmissionUpdateForm";
import ClaimSubmissionLockedTag from "./ClaimSubmissionLockedTag";

export default function ClaimSubmissionHeader({ object }) {
  const { organization__slug } = useParams();
  const hasProcessorPrivileges = useSelector(
    selectorAuthHasProcessorPrivileges
  );
  const hasSubmitterPrivileges = useSelector(
    selectorAuthHasSubmitterPrivileges
  );
  const {
    id,
    name,
    start_date,
    end_date,
    time_locked,
    tpa_organization,
    submitter_organization,
    processor_organization,
  } = object;
  const dateRange = formatDateRange({ start_date, end_date });
  const [timeLocked, setTimeLocked] = React.useState(time_locked);

  React.useEffect(() => {
    if (time_locked !== timeLocked) {
      invalidateClaimsTSQ({ organization__slug });
      setTimeLocked(time_locked);
    }
  }, [time_locked, timeLocked, organization__slug]);

  const organization = [
    tpa_organization,
    submitter_organization,
    processor_organization,
  ]
    .filter(Boolean)
    .find(({ slug }) => slug === organization__slug);

  const hrefs = [
    {
      label: HOME_TITLE,
      href: HOME_HREF,
    },
    {
      label: organization?.name || organization__slug,
      href: getClaimSubmissionHref({ organization__slug }),
    },
    {
      label: name,
      href: getClaimSubmissionHref({
        organization__slug,
        claim_submission__id: id,
      }),
    },
  ];

  const hasMoreActions = hasSubmitterPrivileges || hasProcessorPrivileges;

  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={CustomHeaderTitle}
      titleComponentProps={{
        title: name,
        subtitle: dateRange,
        children: <ClaimSubmissionLockedTag time_locked={time_locked} />,
      }}
    >
      {hasMoreActions && (
        <CustomMoreActions>
          {hasSubmitterPrivileges && (
            <ClaimSubmissionUpdateForm object={object} />
          )}
          {hasProcessorPrivileges && (
            <ClaimSubmissionLockForm object={object} />
          )}
        </CustomMoreActions>
      )}
    </CustomHeader>
  );
}
