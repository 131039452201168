import React from "react";
import Typography from "@mui/material/Typography";
import CustomBox from "./CustomBox";

export default function CustomOption({
  title,
  subtitle,
  checked,
  handleClick = () => {},
  wrapperProps = {},
}) {
  const className = checked
    ? "outlined-light borderRadius-8 button elevated-16"
    : "outlined-light borderRadius-8 button";

  const headerClassName =
    "transition borderRadiusTop-8 " + (checked ? "blackBg" : "greyBgF9");

  return (
    <CustomBox
      className={className}
      onClick={handleClick}
      textAlign="center"
      {...wrapperProps}
    >
      <Typography fontWeight="bold" className={headerClassName} py={1} px={2}>
        {title}
      </Typography>
      <Typography variant="body2" py={2} px={2}>
        {subtitle}
      </Typography>
    </CustomBox>
  );
}
