import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  CONSUMABLES_TITLE,
  getConsumablesHref,
  HEALTHCARE_CONFIGURATIONS_BASE_HREFS,
} from "@constants/static/routing";
import { selectorAuthHasAdminPrivileges } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ConsumableActivateForm from "../ConsumableForms/ConsumableActivateForm";
import ConsumableUpdateForm from "../ConsumableForms/ConsumableUpdateForm";
import ConsumableVersionHistory from "./ConsumableVersionHistory";

export function ConsumableHeaderTitle({ object, variant = "h3" }) {
  const { version, name, time_deleted } = object;

  return (
    <CustomHeaderTitle
      title={name}
      variant={variant}
      version={version}
      time_deleted={time_deleted}
    />
  );
}

export default function ConsumableHeader({ object }) {
  const { consumable__id } = useParams();
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const {
    code,
    name,
    is_submitter_organization = false,
    time_deleted,
  } = object || {};
  const title = code || name;
  const isDeleted = Boolean(time_deleted);
  const canUpdateObject = hasAdminPrivileges && is_submitter_organization;

  const hrefs = [
    ...HEALTHCARE_CONFIGURATIONS_BASE_HREFS,
    {
      label: CONSUMABLES_TITLE,
      href: getConsumablesHref({}),
    },
    {
      label: title,
      href: getConsumablesHref({ consumable__id }),
    },
  ];

  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={ConsumableHeaderTitle}
      titleComponentProps={{ object }}
    >
      {canUpdateObject && !isDeleted && (
        <ConsumableUpdateForm object={object} />
      )}
      <CustomMoreActions>
        <ConsumableVersionHistory />
        {canUpdateObject && <ConsumableActivateForm object={object} />}
      </CustomMoreActions>
    </CustomHeader>
  );
}
