import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomButton from "@components/Custom/CustomButton";
import CustomFileDropzone from "@components/Custom/CustomFileDropzone";
import { CustomExternalLink } from "@components/Custom/CustomLink";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import { getUploadsHref, getUploadsTemplate } from "@constants/static/routing";
import {
  formatUploadRawJson,
  objectNameGivenModelType,
  uploadRowsToObjects,
} from "@constants/static/upload";
import convertFileToJson from "@helpers/parse/files";
import { getIntOrFloat } from "@helpers/parse/numbers";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { createUpload } from "@redux/actions/utils/uploads";
import { selectorAuthCurrentUserOrganizationCurrency } from "@redux/selectors/auth";
import { enqueueSnackbar } from "notistack";
import pluralize from "pluralize";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import HealthcareOrganizationActionDialogTitle from "../../HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import InsuranceOrganizationActionDialogTitle from "../../InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import { parseBulkUploadSnackbar } from "./ConfigurationsBulkUploadsSnackbar";

const ConfigurationsBulkUploadsCreateFormUploadSummary = ({
  numEntries = 0,
  numRows = 0,
  objectName = "",
  template = "",
}) => {
  const hasEntries = numEntries > 0;
  const pluralizedObjectName =
    getIntOrFloat(numEntries) + " " + pluralize(objectName, numEntries, false);
  const pluralizedRows =
    getIntOrFloat(numRows) + " " + pluralize("row", numRows, false);

  if (!hasEntries)
    return (
      <div>
        <Typography variant="body2" display="inline">
          {"Please ensure the file is in CSV format and the data is properly "}
        </Typography>
        <CustomExternalLink
          display="inline"
          href={template}
          label="formatted"
          wrapperProps={{ download: true }}
        />
      </div>
    );

  if (numEntries === numRows)
    return (
      <Typography variant="body2">
        {"You’re uploading "}
        <b>{pluralizedObjectName}</b>
      </Typography>
    );

  return (
    <Typography variant="body2">
      {"You’re uploading "}
      <b>{pluralizedObjectName}</b>
      {" from "}
      <b>{pluralizedRows}</b>
    </Typography>
  );
};

export default function ConfigurationsBulkUploadsCreateForm({
  useMenuItem = false,
  model_type,
  bundle_type,
}) {
  const { organization__slug } = useParams();
  const navigate = useNavigate();
  const currency = useSelector(selectorAuthCurrentUserOrganizationCurrency);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [numRows, setNumRows] = React.useState(0);
  const [name, setName] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [rawJson, setRawJson] = React.useState([]);
  const objectName = objectNameGivenModelType({ model_type });
  const pluralizedObjectName = pluralize(objectName);
  const uploadObjectsText = `Upload ${pluralizedObjectName}`;
  const template = getUploadsTemplate({ model_type, bundle_type });
  const TitleComponent = Boolean(organization__slug)
    ? InsuranceOrganizationActionDialogTitle
    : HealthcareOrganizationActionDialogTitle;

  const numEntries = rawJson.length;
  const hasEntries = numEntries > 0;
  const hasFile = Boolean(file);
  const hasNoFileorEntries = !hasFile || !hasEntries;
  const isSubmitButtonDisabled = hasNoFileorEntries || !name || isProcessing;

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };
  const handleUploadFile = (file) => {
    setIsProcessing(true);
    convertFileToJson({
      file,
      callback: (data) => {
        const payload = uploadRowsToObjects({
          rows: data,
          model_type,
          bundle_type,
          currency,
        });
        setNumRows(data.length);
        setName(file?.name || "");
        setRawJson(payload);
        setFile(file);
        setIsProcessing(false);
      },
    });
  };
  const handleChangeName = (e) => setName(e.target.value);
  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    const raw_json = formatUploadRawJson({
      rawJson,
      model_type,
      bundle_type,
      organization__slug,
    });
    createUpload({
      data: {
        name: name?.trim(),
        raw_json,
        bundle_type,
        processor_organization__slug: organization__slug,
      },
      model_type,
    })
      .then(({ payload }) => {
        enqueueSnackbar(parseBulkUploadSnackbar(payload));
        navigate(
          getUploadsHref({
            upload__id: payload?.id,
            model_type,
            bundle_type,
            organization__slug,
          })
        );
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        enqueueSnackbar({ message: payload, variant: "error" });
      });
  };

  React.useEffect(() => {
    if (isOpen) {
      setName("");
      setNumRows(0);
      setFile(null);
      setRawJson([]);
    }
  }, [isOpen]);

  return (
    <>
      {useMenuItem ? (
        <MenuItem onClick={handleIsOpen}>{uploadObjectsText}</MenuItem>
      ) : (
        <CustomButton
          label={uploadObjectsText}
          icon="PublishIcon"
          variant="contained"
          onClick={handleIsOpen}
        />
      )}
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        TitleComponent={TitleComponent}
        titleComponentProps={{ title: uploadObjectsText }}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        yesButtonProps={{ disabled: isSubmitButtonDisabled }}
        yesText="Upload"
      >
        <CustomFileDropzone value={file} onChange={handleUploadFile} />
        {hasFile ? (
          <>
            <ConfigurationsBulkUploadsCreateFormUploadSummary
              numEntries={numEntries}
              numRows={numRows}
              objectName={objectName}
              template={template}
            />
            {!hasNoFileorEntries && (
              <CustomOutlinedTextField
                value={name}
                name="name"
                placeholder="Enter a name for the upload file"
                onChange={handleChangeName}
              />
            )}
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <CustomExternalLink
              isTypography={true}
              href={template}
              wrapperProps={{ download: true }}
              label="Download template"
            />
          </div>
        )}
      </CustomActionDialog>
    </>
  );
}
