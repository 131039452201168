import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomButton from "@components/Custom/CustomButton";
import { updateUser } from "@redux/actions/users/auth";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import AccountActionDialogTitle from "../Account/AccountActionDialogTitle";
import AccountInformationFormData from "./AccountInformationFormData";

const initialFormData = {
  first_name: "",
  last_name: "",
  email: "",
};

const initialErrors = {
  first_name: "",
  last_name: "",
  email: "",
};

export default function AccountInformationUpdateForm({ user }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(initialFormData);
  const [errors, setErrors] = React.useState(initialErrors);

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const { id, first_name, last_name } = formData;
    const formattedData = {
      id,
      first_name: first_name?.trim(),
      last_name: last_name?.trim(),
    };
    dispatch(updateUser(formattedData))
      .then(() => {
        setIsProcessing(false);
        setIsOpen(false);
        enqueueSnackbar({
          message: "Account information successfully updated",
          variant: "success",
        });
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        setErrors(payload);
      });
  };

  React.useEffect(() => {
    setFormData(user);
  }, [user]);

  return (
    <>
      <CustomButton
        label="Edit"
        icon="EditIcon"
        variant="outlined"
        useIconAsLabel={true}
        isSquare={true}
        onClick={handleIsOpen}
      />
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        TitleComponent={AccountActionDialogTitle}
        titleComponentProps={{ title: "Edit profile", user }}
      >
        <AccountInformationFormData
          errors={errors}
          formData={formData}
          setFormData={setFormData}
          withRole={false}
        />
      </CustomActionDialog>
    </>
  );
}
