import React from "react";
import { getIntOrFloat } from "@helpers/parse/numbers";
import Typography from "@mui/material/Typography";
import pluralize from "pluralize";

export const isClaimSubmissionOptionEqualToValue = (option, value) =>
  option?.id === value?.id;

export const getClaimSubmissionOptionLabel = (option) => option?.id;

export const renderClaimSubmissionOption = (props, option) => {
  const { name, claims_summary = {} } = option;
  const { num_claims } = claims_summary || {};

  return (
    <div {...props} title={name}>
      <div>
        <Typography variant="body2" fontWeight="bold">
          {name}
        </Typography>
        <Typography variant="body2" fontWeight="light">
          {getIntOrFloat(num_claims)} {pluralize("claim", num_claims)}
        </Typography>
      </div>
    </div>
  );
};
