import React from "react";
import CustomActionButtons from "@components/Custom/CustomActionButtons";
import { customSectionBodyPadding } from "@components/Custom/CustomSection";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ClaimAttachmentData from "./ClaimAttachmentData";

export default function ClaimAttachment({
  index,
  object,
  isCreateOrUpdateMode,
  handleSelectClaimAttachment = () => {},
  handleRemoveClaimAttachment = () => {},
}) {
  const handleSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleSelectClaimAttachment({ object, index });
  };
  const handleRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleRemoveClaimAttachment({ index });
  };

  return (
    <Box p={customSectionBodyPadding}>
      <Stack
        direction="row"
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <ClaimAttachmentData object={object} useLink={!isCreateOrUpdateMode} />
        {isCreateOrUpdateMode && (
          <CustomActionButtons
            handleEdit={handleSelect}
            handleRemove={handleRemove}
          />
        )}
      </Stack>
    </Box>
  );
}
