import React from "react";
import OrganizationBundleAutocomplete from "@components/Custom/CustomAutocomplete/OrganizationBundleAutocomplete";
import CustomInputAdornment from "@components/Custom/CustomInputAdornment";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import CustomMetaFormData from "@components/Custom/CustomMetaData/CustomMetaFormData";
import {
  CustomDatePicker,
  CustomOutlinedTextField,
} from "@components/Custom/CustomOutlinedField";
import {
  CLAIM_MEDICATION_ERROR_FIELDNAMES_INTERNAL_CODE,
  CLAIM_MEDICATION_ERROR_FIELDNAMES_INVOICE_NUMBER,
  CLAIM_MEDICATION_ERROR_FIELDNAMES_ORGANIZATION_BUNDLE,
  CLAIM_MEDICATION_ERROR_FIELDNAMES_PREAUTHORIZATION_NUMBER,
  CLAIM_MEDICATION_ERROR_FIELDNAMES_QUANTITY,
  CLAIM_MEDICATION_ERROR_FIELDNAMES_RELATED_DATE,
} from "@constants/claims/claim-evaluation-results";
import {
  CLAIM_MEDICATION_DURATION_OPTIONS,
  CLAIM_MEDICATION_FREQUENCY_OPTIONS,
} from "@constants/claims/claim-medications";
import { getOrganizationBundleBilledAmount } from "@constants/claims/claims";
import { ORGANIZATION_BUNDLE_TYPE_MEDICATIONS } from "@constants/organizations/organization-bundles";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SubclaimBillingDetailsFormData from "../Subclaim/SubclaimBillingDetailsFormData";

export default function ClaimMedicationFormData({
  formData,
  setFormData = () => {},
  visit_type,
  errorFieldnames = {},
}) {
  const {
    organization_bundle,
    quantity,
    dosage_quantity,
    frequency,
    frequency_unit,
    duration,
    duration_unit,
    related_date,
    preauthorization_number,
    invoice_number,
    meta_data,
    ...otherFormData
  } = formData;

  const {
    [CLAIM_MEDICATION_ERROR_FIELDNAMES_ORGANIZATION_BUNDLE]:
      organization_bundle_error = false,
    [CLAIM_MEDICATION_ERROR_FIELDNAMES_QUANTITY]: quantity_error = false,
    [CLAIM_MEDICATION_ERROR_FIELDNAMES_RELATED_DATE]:
      related_date_error = false,
    [CLAIM_MEDICATION_ERROR_FIELDNAMES_PREAUTHORIZATION_NUMBER]:
      preauthorization_number_error = false,
    [CLAIM_MEDICATION_ERROR_FIELDNAMES_INTERNAL_CODE]:
      internal_code_error = false,
    [CLAIM_MEDICATION_ERROR_FIELDNAMES_INVOICE_NUMBER]:
      invoice_number_error = false,
  } = errorFieldnames;

  const concentration_unit_name =
    organization_bundle?.organization_bundle_medication?.medication
      ?.concentration_unit?.name || "";
  const hasMetaData = Object.keys(meta_data).length > 0;

  const handleChangeOrganizationBundle = (newValue) => {
    const default_amount_requested = newValue
      ? getOrganizationBundleBilledAmount({
          organization_bundle: newValue,
          visit_type,
          quantity,
        })
      : "";
    setFormData((prev) => ({
      ...prev,
      organization_bundle: newValue,
      amount_requested: prev?.amount_requested || default_amount_requested,
    }));
  };

  const handleChangeValue = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeField = ({ fieldname, newValue }) =>
    setFormData((prev) => ({ ...prev, [fieldname]: newValue }));

  return (
    <Stack spacing={3}>
      <CustomListItemCollapse primary="Medication details" useCollapse={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OrganizationBundleAutocomplete
              label="Medication"
              value={organization_bundle}
              handleChange={handleChangeOrganizationBundle}
              error={organization_bundle_error || internal_code_error}
              bundle_type={ORGANIZATION_BUNDLE_TYPE_MEDICATIONS}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomOutlinedTextField
              type="number"
              label="Quantity"
              name="quantity"
              value={quantity}
              error={quantity_error}
              onChange={handleChangeValue}
              inputProps={{ min: 1, step: 1 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomDatePicker
              label="Dispensed date"
              wrapperProps={{ error: related_date_error }}
              value={related_date}
              onChange={(newValue) =>
                handleChangeField({ fieldname: "related_date", newValue })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomOutlinedTextField
              label="Invoice number"
              name="invoice_number"
              value={invoice_number || ""}
              error={invoice_number_error}
              onChange={handleChangeValue}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomOutlinedTextField
              label="Preauthorization number"
              name="preauthorization_number"
              value={preauthorization_number || ""}
              error={preauthorization_number_error}
              onChange={handleChangeValue}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} columns={11}>
              <Grid item xs={10} md={3}>
                <CustomOutlinedTextField
                  type="number"
                  label="Dosage quantity"
                  name="dosage_quantity"
                  value={dosage_quantity || ""}
                  // error={false}
                  onChange={handleChangeValue}
                  inputProps={{ min: 0, step: 0.000001 }}
                  InputProps={{
                    endAdornment: (
                      <CustomInputAdornment value={concentration_unit_name} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={10} md={4}>
                <CustomLabeledText useChildren={true} label="Frequency">
                  <Stack spacing={1} direction="row" alignItems="center">
                    <CustomOutlinedTextField
                      type="number"
                      name="frequency"
                      value={frequency || ""}
                      // error={false}
                      onChange={handleChangeValue}
                      inputProps={{ min: 0, step: 1 }}
                      wrapperProps={{ flex: 1 }}
                    />
                    <Typography variant="body2">{"/"}</Typography>
                    <CustomOutlinedTextField
                      name="frequency_unit"
                      select
                      options={CLAIM_MEDICATION_FREQUENCY_OPTIONS}
                      value={frequency_unit || ""}
                      // error={false}
                      onChange={handleChangeValue}
                      wrapperProps={{ flex: 1 }}
                    />
                  </Stack>
                </CustomLabeledText>
              </Grid>
              <Grid item xs={10} md={4}>
                <CustomLabeledText useChildren={true} label="Duration">
                  <Stack spacing={1} direction="row" alignItems="center">
                    <CustomOutlinedTextField
                      type="number"
                      name="duration"
                      value={duration || ""}
                      // error={false}
                      onChange={handleChangeValue}
                      inputProps={{ min: 0, step: 1 }}
                      wrapperProps={{ flex: 1 }}
                    />
                    <CustomOutlinedTextField
                      name="duration_unit"
                      select
                      options={CLAIM_MEDICATION_DURATION_OPTIONS}
                      // error={false}
                      value={duration_unit || ""}
                      onChange={handleChangeValue}
                      wrapperProps={{ flex: 1 }}
                    />
                  </Stack>
                </CustomLabeledText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomListItemCollapse>
      <Divider />
      <SubclaimBillingDetailsFormData
        formData={otherFormData}
        setFormData={setFormData}
      />
      {hasMetaData && (
        <>
          <Divider />
          <CustomMetaFormData
            meta_data={meta_data}
            handleChange={(newValue) =>
              handleChangeField({ fieldname: "meta_data", newValue })
            }
          />
        </>
      )}
    </Stack>
  );
}
