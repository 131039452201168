import React from "react";
import CustomBox from "@components/Custom/CustomBox";
import CustomInfiniteGrid from "@components/Custom/CustomDataGrid/CustomInfiniteGrid";
import useFetchOrganizations from "@helpers/hooks/useFetchObjects/useFetchOrganizations";
import useScrollOrganizationIntoView from "@helpers/hooks/useScrollOrganizationIntoView";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import OrganizationObject from "./OrganizationObject";
import OrganizationsSearchField from "./OrganizationsSearchField";

export default function Organizations({
  isOpen,
  handleClose = () => {},
  handleOpen = () => {},
  inputFieldId,
  useClaimModeToggle = false,
}) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const hasSearchQuery = Boolean(searchQuery);
  const { infiniteQuery, claimMode } = useFetchOrganizations({ searchQuery });
  const { isFetching } = infiniteQuery;

  useScrollOrganizationIntoView({ isFetching });

  const onClick = React.useCallback(() => {
    setSearchQuery("");
    handleClose();
  }, [handleClose]);

  return (
    <>
      <CustomBox px={3}>
        <OrganizationsSearchField
          isOpen={isOpen}
          handleOpen={handleOpen}
          inputFieldId={inputFieldId}
          setParentSearchQuery={setSearchQuery}
          isFetching={isFetching}
        />
      </CustomBox>
      <List className="overflowY" style={{ marginBottom: "auto" }}>
        <CustomInfiniteGrid
          infiniteQuery={infiniteQuery}
          ObjectComponent={OrganizationObject}
          objectComponentProps={{
            onClick,
            claimMode,
            hasSearchQuery,
            useClaimModeToggle,
          }}
          noResultsFoundProps={{ wrapperProps: { px: 3, mt: 2 } }}
          displayNoResultsFound={isOpen}
          errorProps={{ px: 3, color: "white" }}
          customLoadingIndicator={
            <Box px={3}>
              <LinearProgress color="success" />
            </Box>
          }
        />
      </List>
    </>
  );
}
