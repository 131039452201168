import {
  ORGANIZATION_PATIENT_GENDER_MAPPER,
  ORGANIZATION_PATIENT_GENDER_OPTIONS,
} from "@constants/organizations/organization-patients";
import {
  searchQueryDescribeFilter,
  TIME_DELETED_ISNULL_FILTER,
  TIME_UPDATED_FILTER,
  timeDeletedIsNullApplyFilter,
  timeDeletedIsNullDescribeFilter,
  timeUpdatedApplyFilter,
  timeUpdatedDescribeFilter,
} from "@constants/static/export";
import { parseDate, parseIsoDatetime } from "@helpers/parse/time";

export const initialFilterState = {
  gender__in: [],
  dob: [null, null],
  time_updated: [null, null],
  time_deleted__isnull: [],
};

export const filterOptions = {
  gender__in: {
    title: "Gender",
    type: "option",
    options: ORGANIZATION_PATIENT_GENDER_OPTIONS,
  },
  dob: { title: "Date of birth", type: "date-range" },
  time_updated: TIME_UPDATED_FILTER,
  time_deleted__isnull: TIME_DELETED_ISNULL_FILTER,
};

export const formatFilters = (filters = {}) => {
  let formattedFilters = {};
  const {
    gender__in = [],
    dob = [null, null],
    time_updated = [null, null],
    time_deleted__isnull = [],
  } = filters;

  if (gender__in.length > 0)
    formattedFilters["gender__in"] = gender__in.join(",");

  if (dob[0]) formattedFilters["dob__gte"] = parseIsoDatetime(dob[0]);

  if (dob[1]) formattedFilters["dob__lt"] = parseIsoDatetime(dob[1]);

  formattedFilters = timeUpdatedApplyFilter({
    formattedFilters,
    time_updated,
  });

  formattedFilters = timeDeletedIsNullApplyFilter({
    formattedFilters,
    time_deleted__isnull,
  });

  return formattedFilters;
};

export const describeFilters = ({ searchQuery = "", filters = {} }) => {
  let formattedOutput = [];
  const {
    gender__in = "",
    dob__gte = "",
    dob__lt = "",
    time_updated__gte = "",
    time_updated__lt = "",
    time_deleted__isnull = "",
  } = filters;

  formattedOutput = searchQueryDescribeFilter({ formattedOutput, searchQuery });

  if (gender__in?.length > 0) {
    const label = "Gender";
    const values = gender__in
      ?.split(",")
      ?.map((key) => ORGANIZATION_PATIENT_GENDER_MAPPER[key]);
    formattedOutput.push({ label, values });
  }

  if (dob__gte || dob__lt) {
    const label = "Date of birth";
    const from = parseDate(dob__gte);
    const to = parseDate(dob__lt);

    if (from && to) formattedOutput.push({ label, from, to });
    else if (from) formattedOutput.push({ label, from });
    else if (to) formattedOutput.push({ label, to });
  }

  formattedOutput = timeUpdatedDescribeFilter({
    formattedOutput,
    time_updated__gte,
    time_updated__lt,
  });
  formattedOutput = timeDeletedIsNullDescribeFilter({
    formattedOutput,
    time_deleted__isnull,
  });

  return formattedOutput;
};
