import React from "react";
import Typography from "@mui/material/Typography";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import { useQueryFetchConceptFindings } from "@redux/actions/server/phoenix";

const isOptionEqualToValue = (option, value) => option?.id === value?.id;

const getOptionLabel = (option) => option?.name;

const renderOption = (props, option) => {
  const { name } = option;
  return (
    <div {...props}>
      <Typography variant="body2">{name}</Typography>
    </div>
  );
};

export default function ConceptFindingAutocomplete({
  value = null,
  handleChange = () => {},
  useQueryFnProps = {},
  textFieldProps = {},
}) {
  return (
    <CustomInfiniteAutocomplete
      withSearchIcon={false}
      autocompleteProps={{
        disableClearable: true,
        style: { width: "100%" },
        renderOption,
      }}
      textFieldProps={textFieldProps}
      useQueryFn={useQueryFetchConceptFindings}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      value={value}
      handleChange={handleChange}
      useQueryFnProps={useQueryFnProps}
    />
  );
}
