import React from "react";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import Typography from "@mui/material/Typography";
import { useQueryFetchCptHistory } from "@redux/actions/avey/cpts";
import { useParams } from "react-router-dom";
import CptData from "./CptData";
import { CptHeaderTitle } from "./CptHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { code } = object;
  return (
    <Typography variant="body2" fontWeight="bold" className="ellipsis-1">
      {code}
    </Typography>
  );
};

export default function CptVersionHistory() {
  const { cpt__id: id } = useParams();
  const useQueryFnProps = { id };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchCptHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={CptData}
      VersionHistoryObjectTitleComponent={CptHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
