import React from "react";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomTooltip from "@components/Custom/CustomTooltip";
import {
  CLAIM_EVALUATION_RESULT_ICON_MAPPER,
  CLAIM_EVALUATION_RESULT_STATUS_ALERT,
  CLAIM_EVALUATION_RESULT_STATUS_FAILURE,
} from "@constants/claims/claim-evaluation-results";
import { getIntOrFloat } from "@helpers/parse/numbers";
import pluralize from "pluralize";

export default function ClaimNumEvaluationResultsTag({
  num_failure_evaluation_results = 0,
  num_alert_evaluation_results = 0,
  // num_success_evaluation_results = 0,
}) {
  const sum = num_failure_evaluation_results + num_alert_evaluation_results;
  // num_success_evaluation_results +

  if (sum === 0) return null;

  const counts = [
    // {
    //   status: CLAIM_EVALUATION_RESULT_STATUS_SUCCESS,
    //   count: num_success_evaluation_results,
    //   label: "passed test",
    // },
    {
      status: CLAIM_EVALUATION_RESULT_STATUS_FAILURE,
      count: num_failure_evaluation_results,
      label: "failed test",
    },
    {
      status: CLAIM_EVALUATION_RESULT_STATUS_ALERT,
      count: num_alert_evaluation_results,
      label: "alerted test",
    },
  ].filter(({ count }) => count > 0);

  return (
    <CustomGrid rowSpacing={1} columnSpacing={1.5} alignItems="center">
      {counts.map(({ status, count, label }, index) => (
        <CustomTooltip
          title={
            <>
              <b>{getIntOrFloat(count)}</b> {pluralize(label, count, false)}
            </>
          }
          key={index}
        >
          <div style={{ display: "grid", alignItems: "center" }}>
            {CLAIM_EVALUATION_RESULT_ICON_MAPPER[status]}
          </div>
        </CustomTooltip>
      ))}
    </CustomGrid>
  );
}
