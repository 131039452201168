import React from "react";
import {
  CustomToggle,
  CustomToggleOption,
} from "@components/Custom/CustomToggle";
import { ORGANIZATIONS_FULL_HREF } from "@constants/static/routing";
import {
  CLAIM_MODE_COLOR_MAPPER,
  CLAIM_MODE_DESCRIPTION_MAPPER,
  CLAIM_MODE_OPTIONS,
} from "@constants/static/system-modes";
import Tooltip from "@mui/material/Tooltip";
import { toggleClaimMode } from "@redux/actions/users/auth";
import { selectorAuthClaimMode } from "@redux/selectors/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function NavigationDrawerClaimModeToggle() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const claimMode = useSelector(selectorAuthClaimMode);
  const claimModeColor = CLAIM_MODE_COLOR_MAPPER[claimMode];
  const handleChange = () => {
    dispatch(toggleClaimMode());
    navigate(ORGANIZATIONS_FULL_HREF);
  };

  return (
    <Tooltip
      title={CLAIM_MODE_DESCRIPTION_MAPPER[claimMode]}
      placement="right"
      arrow
      slotProps={{
        arrow: { style: { color: claimModeColor } },
        tooltip: {
          style: {
            color: "white",
            backgroundColor: claimModeColor,
            borderRadius: 8,
            padding: "8px 16px",
            fontSize: "14px",
          },
        },
        popper: {
          modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
        },
      }}
    >
      <div>
        <CustomToggle
          color={claimModeColor}
          style={{ width: 99, margin: "16px auto" }}
          value={claimMode}
          onClick={handleChange}
        >
          {CLAIM_MODE_OPTIONS.map(({ key, value }, index) => (
            <CustomToggleOption
              key={index}
              disableRipple
              label={value}
              value={key}
            />
          ))}
        </CustomToggle>
      </div>
    </Tooltip>
  );
}
