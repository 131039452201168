import React from "react";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import Typography from "@mui/material/Typography";
import { useQueryFetchOrganizationPolicyHistory } from "@redux/actions/organizations/organization-policies";
import { useParams } from "react-router-dom";
import OrganizationPolicyData from "../OrganizationPolicyData";
import { OrganizationPolicyHeaderTitle } from "./OrganizationPolicyHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { number, name } = object || {};
  return (
    <Typography variant="body2" className="ellipsis-1">
      <b>{number}</b> {name}
    </Typography>
  );
};

export default function OrganizationPolicyVersionHistory() {
  const { organization_policy__id: id } = useParams();
  const useQueryFnProps = { id };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchOrganizationPolicyHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={OrganizationPolicyData}
      VersionHistoryObjectTitleComponent={OrganizationPolicyHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
