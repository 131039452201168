import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomStatusTag from "@components/Custom/CustomStatusTag";
import { getConfigurationsBulkUploadsHeader } from "@constants/static/routing";
import {
  UPLOAD_STATUS_PROPS_MAPPER,
  UPLOAD_STATUS_SUCCESS,
  UPLOAD_STATUS_TEXT_MAPPER,
} from "@constants/static/upload";
import { useQueryFetchOrganization } from "@redux/actions/organizations/organizations";
import { useParams } from "react-router-dom";
import ConfigurationsBulkUploadCommitForm from "./ConfigurationsBulkUploadCommitForm";

export default function ConfigurationsBulkUploadHeader({
  object,
  model_type,
  bundle_type,
}) {
  const { organization__slug } = useParams();
  const { data: organization } = useQueryFetchOrganization({
    organization__slug,
  });
  const { id, name, status } = object;
  const { hrefs } = getConfigurationsBulkUploadsHeader({
    model_type,
    bundle_type,
    organization,
    upload: { id, name },
  });
  const isSuccess = status === UPLOAD_STATUS_SUCCESS;

  return (
    <CustomHeader
      TitleComponent={CustomHeaderTitle}
      titleComponentProps={{
        title: name,
        children: (
          <CustomStatusTag
            status={status}
            textMapper={UPLOAD_STATUS_TEXT_MAPPER}
            propsMapper={UPLOAD_STATUS_PROPS_MAPPER}
          />
        ),
      }}
      hrefs={hrefs}
    >
      {isSuccess && (
        <ConfigurationsBulkUploadCommitForm
          object={object}
          model_type={model_type}
        />
      )}
    </CustomHeader>
  );
}
