import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { selectorAuthCurrentUserOrganization } from "@redux/selectors/auth";
import { useSelector } from "react-redux";

export default function YourOrganizationInformation() {
  const organization = useSelector(selectorAuthCurrentUserOrganization);
  const { name } = organization;
  return (
    <Stack spacing={2}>
      <Typography fontWeight="bold">{"Organization information"}</Typography>
      <CustomLabeledText label="Name" value={name} />
    </Stack>
  );
}
