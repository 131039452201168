import React from "react";
import CustomAnimatedCode from "@components/Custom/CustomAnimatedCode";
import { getMedicationOptionLabel } from "@components/Custom/CustomAutocomplete/MedicationAutocomplete/MedicationAutocompleteComponents";
import CustomSection from "@components/Custom/CustomSection";
import { CLAIM_SECTION_MEDICATIONS_TITLE } from "@constants/claims/claims";
import Stack from "@mui/material/Stack";

export default function ClaimDemoClaimMedicationsReviewAndConfirm({
  claim_medications = [],
}) {
  const numObjects = claim_medications.length;

  if (numObjects === 0) return null;

  return (
    <CustomSection title={CLAIM_SECTION_MEDICATIONS_TITLE} isMinified={true}>
      <Stack spacing={2}>
        {claim_medications.map((claim_medication, index) => {
          const { medication } =
            claim_medication?.organization_bundle
              ?.organization_bundle_medication;
          const { code } = medication;
          return (
            <CustomAnimatedCode
              key={index}
              name={getMedicationOptionLabel(medication, true, false)}
              code={code}
            />
          );
        })}
      </Stack>
    </CustomSection>
  );
}
