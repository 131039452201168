import React from "react";
import CustomButton from "@components/Custom/CustomButton";
import CustomCircularProgress from "@components/Custom/CustomCircularProgress";
import { STATIC_WELCOME_TO_AVEY_BLOOM_MESSAGE } from "@constants/static/auth";
import { HOME_HREF } from "@constants/static/routing";
import { DEFAULT_ERROR_MESSAGE } from "@constants/static/text";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  activateUser,
  login,
  resetPassword,
  verifyUidToken,
} from "@redux/actions/users/auth";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SetPasswordFormData from "./SetPasswordFormData";
import SetPasswordFormSuccess from "./SetPasswordFormSuccess";
import SetPasswordFormTokenError from "./SetPasswordFormTokenError";

export default function SetPasswordForm({ isNewUser = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uid, token } = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [tokenError, setTokenError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isPasswordSet, setIsPasswordSet] = React.useState(false);

  const handleChange = (e) => setPassword(e.target.value);
  const buttonLabel = isNewUser ? "Activate account" : "Set password";
  const setPasswordSuccessfullyText = isNewUser
    ? "Account successfully activated"
    : "Password successfully changed";

  React.useEffect(() => {
    setIsLoading(true);
    verifyUidToken({ uid, token })
      .then(({ payload }) =>
        Boolean(payload)
          ? setEmail(payload)
          : setTokenError(DEFAULT_ERROR_MESSAGE)
      )
      .catch(({ payload }) => setTokenError(payload))
      .finally(() => setIsLoading(false));
  }, [uid, token]);

  React.useEffect(() => {
    if (isPasswordSet) {
      const timeout = setTimeout(
        () =>
          dispatch(login({ email, password }))
            .then(() => {
              isNewUser &&
                enqueueSnackbar({
                  message: STATIC_WELCOME_TO_AVEY_BLOOM_MESSAGE,
                  variant: "info",
                });
              navigate(HOME_HREF);
            })
            .catch(({ payload }) =>
              enqueueSnackbar({ message: payload, variant: "error" })
            ),
        2000
      );
      return () => clearTimeout(timeout);
    }
  }, [isPasswordSet, email, password, isNewUser, navigate, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setPasswordError("");
    const fn = isNewUser ? activateUser : resetPassword;
    fn({
      new_password: password,
      uid,
      token,
    })
      .then(() => setIsPasswordSet(true))
      .catch(({ payload }) => setPasswordError(payload))
      .finally(() => setIsProcessing(false));
  };

  if (isLoading) return <CustomCircularProgress />;

  return (
    <Stack spacing={3}>
      <Box
        bgcolor="white"
        px={5}
        py={6}
        borderRadius="20px"
        position="relative"
      >
        <CustomCircularProgress open={isProcessing} />
        {tokenError ? (
          <SetPasswordFormTokenError
            tokenError={tokenError}
            isNewUser={isNewUser}
          />
        ) : isPasswordSet ? (
          <SetPasswordFormSuccess title={setPasswordSuccessfullyText} />
        ) : (
          <Stack spacing={3} component="form" onSubmit={handleSubmit}>
            <Typography variant="h5" fontWeight="bold">
              {"Set up your password"}
            </Typography>
            <SetPasswordFormData
              email={email}
              password={password}
              passwordError={passwordError}
              handleChange={handleChange}
            />
            <CustomButton
              fullWidth
              disabled={isProcessing}
              type="submit"
              variant="contained"
              color="primary"
              label={buttonLabel}
            />
          </Stack>
        )}
      </Box>
    </Stack>
  );
}
