import {
  apiWrapper,
  AveyBloomApi,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedResponse,
  PayloadError,
} from "@helpers/api";
import {
  invalidateTSQ,
  updateObjectTSQ,
  useQueryWrapper,
} from "@helpers/tanstack";

const endpointUrl = "/payments/organization_payment_cycles/";
const queryKeySingular = "organization_payment_cycle";
const queryKeyPlural = "organization_payment_cycles";
const queryKeySingularHistory = `${queryKeySingular}_history`;

export const useQueryFetchOrganizationPaymentCycles = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "-time_created",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: () =>
      apiWrapper({
        fn: fetchOrganizationPaymentCycles,
        ...apiWrapperProps,
      })({
        page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchOrganizationPaymentCycles({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(`${endpointUrl}?${queryParams}`);
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

async function fetchCurrentOrganizationPaymentCycle() {
  try {
    const response = await AveyBloomApi().get(
      `${endpointUrl}current_payment_cycle/`
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export const useQueryFetchCurrentOrganizationPaymentCycle = ({
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeySingular],
    queryFn: () =>
      apiWrapper({
        fn: fetchCurrentOrganizationPaymentCycle,
        ...apiWrapperProps,
      })(),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchOrganizationPaymentCycle({ id }) {
  try {
    const response = await AveyBloomApi().get(`${endpointUrl}${id}/`);
    return response.data;
  } catch (error) {
    return null;
  }
}

export const useQueryFetchOrganizationPaymentCycle = ({
  id = "",
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeySingular, { id }],
    queryFn: () =>
      apiWrapper({
        fn: fetchOrganizationPaymentCycle,
        ...apiWrapperProps,
      })({ id }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

export async function updateOrganizationCurrentPaymentCycle(data) {
  try {
    const response = await AveyBloomApi().put(
      `${endpointUrl}${data.id}/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: {
        reason: error?.response?.data?.reason,
        latest_version: error?.response?.data?.latest_version,
        to_settle: error?.response?.data?.to_settle,
      },
    });
  }
}

export function updateOrganizationCurrentPaymentCycleObjectTSQ({ object }) {
  updateObjectTSQ({
    predicate: ({ queryKey }) =>
      queryKey.length === 1 && queryKey[0] === queryKeySingular,
    object,
  });
}

export async function topUpOrganizationPaymentCycle(data) {
  try {
    const response = await AveyBloomApi().post(
      `${endpointUrl}${data.id}/top_up/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: {
        reason: error?.response?.data?.reason,
        latest_version: error?.response?.data?.latest_version,
      },
    });
  }
}

export async function settleAmountOrganizationPaymentCycle(data) {
  try {
    const response = await AveyBloomApi().post(
      `${endpointUrl}${data.id}/settle_bill/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: {
        reason: error?.response?.data?.reason,
        latest_version: error?.response?.data?.latest_version,
      },
    });
  }
}

export function invalidateOrganizationPaymentCyclesTSQ() {
  invalidateTSQ({
    predicate: ({ queryKey }) =>
      [queryKeySingular, queryKeyPlural, queryKeySingularHistory].includes(
        queryKey[0]
      ),
  });
}

export const useQueryFetchOrganizationPaymentCycleHistory = ({
  id,
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [queryKeySingularHistory, { id, filters }],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchOrganizationPaymentCycleHistory,
        ...apiWrapperProps,
      })({
        page: pageParam,
        id,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchOrganizationPaymentCycleHistory({
  page = 1,
  id,
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({ page, ...filters });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${id}/history/?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}
