import React from "react";
import CustomBox from "@components/Custom/CustomBox";
import { CustomExternalLink } from "@components/Custom/CustomLink";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AVEY_SUPPORT_EMAIL, SITE_NAME } from "./globals";
import { SPARKLES_EMOJI } from "./text";

export const STATIC_PASSWORD_HELPER_TEXT =
  "At least 8 characters with letters and numbers";

export const STATIC_WELCOME_TO_AVEY_BLOOM_MESSAGE = (
  <div>
    {"Welcome to"}
    <b>{` ${SITE_NAME} `}</b>
    {SPARKLES_EMOJI}
  </div>
);

export const LoginHeaderMessage = () => (
  <Typography color="white" variant="h6" fontWeight="normal" zIndex={2}>
    {"Welcome to "}
    <b>{`${SITE_NAME},`}</b>
    <br />
    {"your AI claim management system."}
  </Typography>
);

export const LoginFooterMessage = () =>
  Boolean(AVEY_SUPPORT_EMAIL) && (
    <Box zIndex={2}>
      <Typography color="white" variant="body2">
        {"If you don’t have an account yet,"}
      </Typography>
      <Typography color="white" variant="body2" display="inline">
        {"contact us at "}
      </Typography>
      <CustomExternalLink
        href={`mailto:${AVEY_SUPPORT_EMAIL}`}
        label={AVEY_SUPPORT_EMAIL}
        isTypography={true}
        wrapperProps={{ color: "white", display: "inline" }}
      />
    </Box>
  );

export const ForgotPasswordHeaderMessage = () => (
  <Stack spacing={3}>
    <Typography variant="h5" fontWeight="bold">
      {"Forgot password?"}
    </Typography>
    <Typography variant="body2" textAlign="start">
      {
        "Enter the email associated with your account, and we will send you a link to reset your password."
      }
    </Typography>
  </Stack>
);

export const ForgotPasswordEmailSent = ({ email }) => (
  <Stack spacing={2}>
    <Typography variant="h6" fontWeight="bold">
      {"Check your inbox"}
    </Typography>
    <CustomBox textAlign="start">
      <Typography variant="body2" display="inline">
        {"We’ve sent you an email at "}
      </Typography>
      <Typography
        variant="body2"
        display="inline"
        className="underlined"
        fontWeight="bold"
      >
        {email}
      </Typography>
      <Typography variant="body2" display="inline">
        {" to reset your password."}
      </Typography>
    </CustomBox>
  </Stack>
);
