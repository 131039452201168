import React from "react";
import ConfigurationsBulkUploadsCreateForm from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkUpload/ConfigurationsBulkUploads/ConfigurationsBulkUploadsCreateForm";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";

export default function CustomAddOrUploadWrapper({
  label,
  uploadHistoryHref,
  model_type,
  bundle_type,
  children,
}) {
  const hasModelType = Boolean(model_type);
  const hasUploadHistoryHref = Boolean(uploadHistoryHref);
  return (
    <CustomMoreActions
      useRotateProps={{ useRotate90: false }}
      buttonProps={{
        label,
        variant: "contained",
        icon: "ArrowDownIcon",
        isSquare: false,
        useIconAsLabel: false,
        isStartIcon: false,
      }}
    >
      {children}
      {hasModelType && (
        <ConfigurationsBulkUploadsCreateForm
          useMenuItem={true}
          model_type={model_type}
          bundle_type={bundle_type}
        />
      )}
      {hasUploadHistoryHref && (
        <NavLink to={uploadHistoryHref}>
          <MenuItem>{"View upload history"}</MenuItem>
        </NavLink>
      )}
    </CustomMoreActions>
  );
}
