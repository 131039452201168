import React from "react";
import CustomCircularProgress from "@components/Custom/CustomCircularProgress";
import LoginForm from "@components/Unauthenticated/LoginForm";
import { LoginFooterMessage, LoginHeaderMessage } from "@constants/static/auth";
import { HOME_HREF } from "@constants/static/routing";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { verifyAccessToken } from "@redux/actions/users/auth";
import { selectorAuth } from "@redux/selectors/auth";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export default function Login() {
  const dispatch = useDispatch();
  const { isAuthenticated, isLoadingAuth } = useSelector(selectorAuth);

  React.useEffect(() => {
    dispatch(verifyAccessToken())
      .then(() => {})
      .catch(() => {});
  }, [dispatch]);

  if (isAuthenticated) return <Navigate to={HOME_HREF} />;

  if (isLoadingAuth) return <CustomCircularProgress />;

  return (
    <Stack spacing={6}>
      <LoginHeaderMessage />
      <Box
        bgcolor="white"
        px={5}
        py={6}
        borderRadius="20px"
        position="relative"
      >
        <LoginForm />
      </Box>
      <LoginFooterMessage />
    </Stack>
  );
}
