import React from "react";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import ConfigurationsBulkUploadsGrid from "./ConfigurationsBulkUploadsGrid";
import ConfigurationsBulkUploadsHeader from "./ConfigurationsBulkUploadsHeader";

export default function ConfigurationsBulkUploads({ model_type, bundle_type }) {
  return (
    <>
      <ConfigurationsBulkUploadsHeader
        model_type={model_type}
        bundle_type={bundle_type}
      />
      <CustomPage mt={0}>
        <ConfigurationsBulkUploadsGrid
          model_type={model_type}
          bundle_type={bundle_type}
        />
      </CustomPage>
    </>
  );
}
