import React from "react";
import ForgotPasswordForm from "@components/Unauthenticated/ForgotPasswordForm";
import SetPasswordForm from "@components/Unauthenticated/SetPasswordForm";
import UnauthenticatedWrapper from "@components/Unauthenticated/UnauthenticatedWrapper";
import {
  ACTIVATE_USER_HREF,
  FORGOT_PASSWORD_HREF,
  HOME_HREF,
  LOGIN_HREF,
  LOGOUT_HREF,
  RESET_PASSWORD_HREF,
} from "@constants/static/routing";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./login";
import Logout from "./logout";

export default function UnauthenticatedRoutes() {
  return (
    <div style={{ backgroundColor: "black" }}>
      <UnauthenticatedWrapper>
        <Routes>
          <Route path={LOGIN_HREF} Component={Login} />
          <Route path={LOGOUT_HREF} Component={Logout} />
          <Route path={FORGOT_PASSWORD_HREF} Component={ForgotPasswordForm} />
          <Route
            path={`${RESET_PASSWORD_HREF}/:uid/:token`}
            element={<SetPasswordForm isNewUser={false} />}
          />
          <Route
            path={`${ACTIVATE_USER_HREF}/:uid/:token`}
            element={<SetPasswordForm isNewUser={true} />}
          />
          <Route path="*" element={<Navigate to={HOME_HREF} />} />
        </Routes>
      </UnauthenticatedWrapper>
    </div>
  );
}
