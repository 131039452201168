import React from "react";
import CustomButton from "@components/Custom/CustomButton";
import { invalidateOrganizationAgreementsTSQ } from "@redux/actions/organizations/organization-agreements";
import {
  invalidateOrganizationsTSQ,
  invalidatePartnerOrganizationsTSQ,
  updateOrganizationAffiliations,
  useQueryFetchPartnerOrganizations,
} from "@redux/actions/organizations/organizations";
import { enqueueSnackbar } from "notistack";
import OrganizationAffiliationsOrganizations from "./OrganizationAffiliationsOrganizations";
import OrganizationAffiliationsOrganizationsReviewAndConfirm from "./OrganizationAffiliationsOrganizationsReviewAndConfirm";

export default function OrganizationAffiliationsManagementForm() {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <CustomButton
        label="Add partners"
        icon="PlusIcon"
        onClick={handleIsOpen}
      />
      {isOpen && (
        <OrganizationAffiliationsManagementFormContent setIsOpen={setIsOpen} />
      )}
    </>
  );
}

const OrganizationAffiliationsManagementFormContent = ({
  setIsOpen = () => {},
}) => {
  const { data, isLoading } = useQueryFetchPartnerOrganizations({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState([]);
  const [summaryState, setSummaryState] = React.useState({
    isOpen: false,
    summary: "",
  });
  const { isOpen: isOpenSummary } = summaryState;

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen((prev) => !prev);
  };

  const handleAddOrganization = (payload) =>
    setFormData((prev) => [...prev, payload]);

  const handleRemoveOrganization = (id) =>
    setFormData((prev) => prev.filter((object) => object.id !== id));

  const handleSubmit = ({ e, isCheck = true }) => {
    e?.preventDefault();
    setIsProcessing(true);
    updateOrganizationAffiliations({
      is_check: isCheck,
      processor_organization__ids: formData.map(({ id }) => id),
    })
      .then(({ payload }) => {
        if (isCheck) {
          setSummaryState({ isOpen: true, summary: payload });
        } else {
          enqueueSnackbar({
            message: "Partners successfully updated",
            variant: "success",
          });
          setIsOpen(false);
          invalidateOrganizationsTSQ();
          invalidatePartnerOrganizationsTSQ();
          invalidateOrganizationAgreementsTSQ();
        }
      })
      .catch(({ payload }) =>
        enqueueSnackbar({ variant: "error", message: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  const handleCheckData = (e) => handleSubmit({ e, isCheck: true });
  const handleSubmitData = (e) => handleSubmit({ e, isCheck: false });

  React.useEffect(() => {
    setFormData(data || []);
  }, [data]);

  return (
    <>
      <OrganizationAffiliationsOrganizations
        isOpen={!isOpenSummary}
        isProcessing={isProcessing || isLoading}
        formData={formData}
        handleClose={handleIsOpen}
        handleSubmit={handleCheckData}
        handleAddOrganization={handleAddOrganization}
        handleRemoveOrganization={handleRemoveOrganization}
      />
      <OrganizationAffiliationsOrganizationsReviewAndConfirm
        summaryState={summaryState}
        isProcessing={isProcessing || isLoading}
        setSummaryState={setSummaryState}
        handleSubmit={handleSubmitData}
      />
    </>
  );
};
