import React from "react";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import Grid from "@mui/material/Grid";

export default function ConsumableFormData({
  formData = {},
  setFormData = () => {},
}) {
  const { name, description } = formData || {};

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomOutlinedTextField
            autoFocus
            required
            label="Name"
            value={name}
            name="name"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomOutlinedTextField
            label="Description"
            value={description}
            name="description"
            onChange={handleChange}
            multiline={true}
            minRows={3}
          />
        </Grid>
      </Grid>
    </div>
  );
}
