import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { submitFeedback } from "@redux/actions/users/auth";
import { enqueueSnackbar } from "notistack";

export default function NavigationTopAppBarSubmitFeedbackForm() {
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClose = () => setIsOpen(!isOpen);
  const handleChange = (e) => setFormData(e.target.value);

  React.useEffect(() => {
    if (isOpen) {
      setError(null);
      setFormData("");
    }
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    submitFeedback({ feedback: formData.trim() })
      .then(() => {
        setIsProcessing(false);
        setIsOpen(false);
        enqueueSnackbar({
          message: "Feedback successfully submitted",
          variant: "success",
        });
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        setError(payload);
      });
  };

  return (
    <>
      <ListItemButton onClick={handleClose} sx={{ px: 3 }}>
        <ListItemText
          primary="Submit feedback"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </ListItemButton>
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        titleComponentProps={{ title: "Submit feedback" }}
        subtitleComponentProps={{
          title: "Your feedback is always appreciated.",
        }}
      >
        <CustomOutlinedTextField
          required
          multiline
          minRows={5}
          name="issue"
          value={formData}
          placeholder="Enter your feedback"
          helperText={error}
          error={Boolean(error)}
          onChange={handleChange}
        />
      </CustomActionDialog>
    </>
  );
}
