import React from "react";
import CustomBox from "@components/Custom/CustomBox";

export default function CustomPage({
  children,
  isHeader = false,
  isPage = true,
  mt = 4,
  ...otherProps
}) {
  const pageProps = isHeader
    ? { pb: 0, display: "grid", alignItems: "center", minHeight: "80px" }
    : isPage
    ? { mt, pb: { xs: 3, sm: 6, md: 0, lg: 12 }, mb: "100px" }
    : {};

  return (
    <CustomBox
      zIndex={1}
      maxWidth={{ xs: "100%", xl: 1400 }}
      mx="auto"
      px={{ xs: 3, sm: 6, md: 9, lg: 12 }}
      {...pageProps}
      {...otherProps}
    >
      {children}
    </CustomBox>
  );
}
