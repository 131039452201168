import React from "react";
import NavigationBreadcrumbs from "@components/Authenticated/Navigation/NavigationBreadcrumbs";
import CustomBox from "@components/Custom/CustomBox";
import CustomStickyAppBar from "@components/Custom/CustomLayout/CustomStickyAppBar";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import CustomTitleComponent from "./CustomTitleComponent";

export default function CustomHeader({
  TitleComponent = undefined,
  titleComponentProps = {},
  hrefs = [],
  children,
  withDivider = false,
}) {
  return (
    <CustomStickyAppBar>
      {({ isSticky }) => (
        <CustomHeaderContent
          TitleComponent={TitleComponent}
          titleComponentProps={titleComponentProps}
          hrefs={hrefs}
          isSticky={isSticky}
          withDivider={withDivider}
        >
          {children}
        </CustomHeaderContent>
      )}
    </CustomStickyAppBar>
  );
}

function CustomHeaderContent({
  isSticky,
  TitleComponent = undefined,
  titleComponentProps = {},
  hrefs = [],
  children,
  withDivider = false,
}) {
  const variant = isSticky ? "h5" : "h3";
  const margin = withDivider && "24px";
  const hasChilden = children && React.Children.count(children) > 0;
  const minHeight = isSticky ? 0 : 40;

  return (
    <>
      <CustomBox mt={margin}>
        <Stack spacing={1}>
          {!isSticky && <NavigationBreadcrumbs hrefs={hrefs} />}
          <Stack
            spacing={1}
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            minHeight={minHeight}
          >
            <CustomTitleComponent
              TitleComponent={TitleComponent}
              titleComponentProps={{ variant, ...titleComponentProps }}
            />
            {hasChilden && !isSticky && (
              <Stack direction="row" alignItems="center" spacing={3}>
                {children}
              </Stack>
            )}
          </Stack>
        </Stack>
        {withDivider && <Divider sx={{ mt: margin }} />}
      </CustomBox>
      {hasChilden && isSticky && (
        <Fade in={true} timeout={250} easing="ease">
          <div
            style={{
              position: "absolute",
              top: 96,
              right: 16,
              padding: "12px 18px",
            }}
            className="whiteBg elevated-8 borderRadius-8"
          >
            <Stack direction="row" alignItems="center" spacing={3}>
              {children}
            </Stack>
          </div>
        </Fade>
      )}
    </>
  );
}
