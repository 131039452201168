import React from "react";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "./CustomTooltip";
import { SendBackIcon } from "@rimads/assets";

export default function CustomSyncButton({
  title = "Sync",
  style = {},
  onClick = () => {},
}) {
  return (
    <CustomTooltip title={title}>
      <IconButton onClick={onClick}>
        <SendBackIcon style={style} />
      </IconButton>
    </CustomTooltip>
  );
}
