import React from "react";
import CustomBox from "@components/Custom/CustomBox";
import Stack from "@mui/material/Stack";
import YourOrganizationAvatar from "./YourOrganizationAvatar";
import YourOrganizationInformation from "./YourOrganizationInformation";

export default function YourOrganizationBody() {
  return (
    <Stack spacing={3}>
      <YourOrganizationAvatar />
      <div>
        <CustomBox
          className="borderRadius-20 elevated-8"
          maxWidth={500}
          m="auto"
          p={{ xs: 3, md: 6 }}
        >
          <YourOrganizationInformation />
        </CustomBox>
      </div>
    </Stack>
  );
}
