import { serialize } from "object-to-formdata";
import { useLocation } from "react-router-dom";

export const getQueryParams = (obj) =>
  Object.entries(obj)
    .filter(([_, value]) => value !== "")
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

export const getSearchQueriesParams = (searchQueries) =>
  searchQueries.map((query) => `q=${query}`).join("&");

export const getFormData = (object) =>
  serialize(object, {
    indices: true,
    booleansAsIntegers: true,
    noAttributesWithArrayNotation: true,
  });

export const encodeURLParams = (params) => {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach((key) =>
    searchParams.append(key, JSON.stringify(params[key]))
  );
  return searchParams.toString();
};

export const decodeURLParams = (encodedString) => {
  const searchParams = new URLSearchParams(encodedString);
  const params = {};
  searchParams.forEach((value, key) => (params[key] = JSON.parse(value)));
  return params;
};

export const useURLSearch = () => {
  const { search } = useLocation();
  return search.startsWith("?") ? search.slice(1) : search;
};
