import React from "react";
import SpecialitiesAutocomplete from "@components/Custom/CustomAutocomplete/SpecialityAutocomplete/SpecialitiesAutocomplete";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import Grid from "@mui/material/Grid";

export default function OrganizationClinicianFormData({
  formData = {},
  setFormData = () => {},
}) {
  const {
    first_name,
    last_name,
    clinician_id,
    specialities = [],
  } = formData || {};

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeSpecialities = (payload) =>
    setFormData((prev) => ({ ...prev, specialities: payload }));

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomOutlinedTextField
            required
            label="Clinician ID"
            name="clinician_id"
            value={clinician_id}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomOutlinedTextField
            required
            label="First name"
            name="first_name"
            value={first_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomOutlinedTextField
            required
            label="Last name"
            name="last_name"
            value={last_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <SpecialitiesAutocomplete
            value={specialities}
            handleChange={handleChangeSpecialities}
          />
        </Grid>
      </Grid>
    </div>
  );
}
