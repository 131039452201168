import React from "react";
import { parseOrganizationPatient } from "@constants/organizations/organization-patients";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const isOrganizationPatientOptionEqualToValue = (option, value) =>
  option?.id === value?.id;

export const getOrganizationPatientOptionLabel = (option) => {
  const { patient_id, full_name } = parseOrganizationPatient(option);
  return `${full_name} (${patient_id})`;
};

export const renderOrganizationPatientOption = (props, option) => {
  const { patient_id, full_name, ageStr, genderStr } =
    parseOrganizationPatient(option);

  return (
    <div {...props}>
      <Stack
        spacing={3}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flex={1}
      >
        <div>
          <Typography variant="body2" fontWeight="bold">
            {full_name}
          </Typography>
          <Typography
            variant="body2"
            fontWeight="light"
          >{`${genderStr}, ${ageStr}`}</Typography>
        </div>
        <Typography variant="body2">{`#${patient_id}`}</Typography>
      </Stack>
    </div>
  );
};
