import { CLAIM_MEDICATION_FREQUERCY_HOURS } from "@constants/claims/claim-medications";

const CONCENTRATION_UNIT_MILLIGRAM = "mg";
const CONCENTRATION_UNIT_MILLIGRAM_GRAM_PER_GRAM = "mg/g";
const CONCENTRATION_UNIT_MILLIGRAM_PER_MILLILITER = "mg/mL";

const DOSAGE_FORM_TABLET = "Tablet";
const DOSAGE_FORM_TABLET_FILM_COATED = "Tablet, Film-Coated";
const DOSAGE_FORM_CAPSULE = "Capsule";
const DOSAGE_FORM_OINTMENT = "Ointment";
const DOSAGE_FOMR_OPTHALMIC_SOLUTION = "Ophthalmic Solution";

const PACKAGE_TYPE_BOTTLE = "Bottle";
const PACKAGE_TYPE_BLISTER_PACK = "Blister pack";
const PACKAGE_TYPE_TUBE = "Tube";

const PACKAGE_SIZE_UNIT_TABLET = "Tablet";
const PACKAGE_SIZE_UNIT_CAPSULE = "Capsule";
const PACKAGE_SIZE_UNIT_GRAM = "g";
const PACKAGE_SIZE_UNIT_MILLILITER = "mL";

const MEDICATION_PANADOL = {
  id: 1,
  code: "0135-0609",
  trade_name: "Panadol",
  generic_name: "Acetaminophen",
  concentration: "500",
  concentration_unit: CONCENTRATION_UNIT_MILLIGRAM,
  dosage_form: DOSAGE_FORM_TABLET_FILM_COATED,
  package_size: "1",
  package_size_unit: PACKAGE_SIZE_UNIT_TABLET,
  package_type: PACKAGE_TYPE_BLISTER_PACK,
};
const MEDICATION_NAPROXEN = {
  id: 2,
  code: "43063-920",
  trade_name: "Aleve",
  generic_name: "Naproxen",
  concentration: "500",
  concentration_unit: CONCENTRATION_UNIT_MILLIGRAM,
  dosage_form: DOSAGE_FORM_TABLET,
  package_size: "1",
  package_size_unit: PACKAGE_SIZE_UNIT_TABLET,
  package_type: PACKAGE_TYPE_BOTTLE,
};
const MEDICATION_IBUPROFEN = {
  id: 3,
  code: "0904-5853",
  trade_name: "Advil",
  generic_name: "Ibuprofen",
  concentration: "400",
  concentration_unit: CONCENTRATION_UNIT_MILLIGRAM,
  dosage_form: DOSAGE_FORM_TABLET,
  package_size: "1",
  package_size_unit: PACKAGE_SIZE_UNIT_TABLET,
  package_type: PACKAGE_TYPE_BOTTLE,
};
const MEDICATION_GABAPENTIN = {
  id: 4,
  code: "0615-8233",
  trade_name: "Neurontin",
  generic_name: "Gabapentin",
  concentration: "600",
  concentration_unit: CONCENTRATION_UNIT_MILLIGRAM,
  dosage_form: DOSAGE_FORM_CAPSULE,
  package_size: "1",
  package_size_unit: PACKAGE_SIZE_UNIT_CAPSULE,
  package_type: PACKAGE_TYPE_BOTTLE,
};
const MEDICATION_TIZANIDINE_2 = {
  id: 5,
  code: "0615-8468",
  trade_name: "Zanaflex",
  generic_name: "Tizanidine",
  concentration: "2",
  concentration_unit: CONCENTRATION_UNIT_MILLIGRAM,
  dosage_form: DOSAGE_FORM_TABLET,
  package_size: "1",
  package_size_unit: PACKAGE_SIZE_UNIT_TABLET,
  package_type: PACKAGE_TYPE_BOTTLE,
};
const MEDICATION_TIZANIDINE_4 = {
  id: 6,
  code: "43063-455",
  trade_name: "Zanaflex",
  generic_name: "Tizanidine",
  concentration: "4",
  concentration_unit: CONCENTRATION_UNIT_MILLIGRAM,
  dosage_form: DOSAGE_FORM_CAPSULE,
  package_size: "1",
  package_size_unit: PACKAGE_SIZE_UNIT_CAPSULE,
  package_type: PACKAGE_TYPE_BOTTLE,
};
const MEDICATION_ERYTHROMYCIN = {
  id: 7,
  code: "50090-1484",
  trade_name: "Ery-Tab",
  generic_name: "Erythromycin",
  concentration: "5",
  concentration_unit: CONCENTRATION_UNIT_MILLIGRAM_GRAM_PER_GRAM,
  dosage_form: DOSAGE_FORM_OINTMENT,
  package_size: "1",
  package_size_unit: PACKAGE_SIZE_UNIT_GRAM,
  package_type: PACKAGE_TYPE_TUBE,
};
const MEDICATION_SOLFACETAMIDE_SODIUM = {
  id: 8,
  code: "0574-4190",
  trade_name: "Bleph-10",
  generic_name: "Sulfacetamide Sodium",
  concentration: "100",
  concentration_unit: CONCENTRATION_UNIT_MILLIGRAM_GRAM_PER_GRAM,
  dosage_form: DOSAGE_FORM_OINTMENT,
  package_size: "1",
  package_size_unit: PACKAGE_SIZE_UNIT_GRAM,
  package_type: PACKAGE_TYPE_TUBE,
};
const MEDICATION_CIPROFLOXACIN = {
  id: 9,
  code: "63187-001",
  trade_name: "Cipro",
  generic_name: "Ciprofloxacin",
  concentration: "3",
  concentration_unit: CONCENTRATION_UNIT_MILLIGRAM_PER_MILLILITER,
  dosage_form: DOSAGE_FOMR_OPTHALMIC_SOLUTION,
  package_size: "1",
  package_size_unit: PACKAGE_SIZE_UNIT_MILLILITER,
  package_type: PACKAGE_TYPE_BOTTLE,
};
const MEDICATION_OFLOXACIN = {
  id: 10,
  code: "71205-420",
  trade_name: "Floxin",
  generic_name: "Ofloxacin",
  concentration: "3",
  concentration_unit: CONCENTRATION_UNIT_MILLIGRAM_PER_MILLILITER,
  dosage_form: DOSAGE_FOMR_OPTHALMIC_SOLUTION,
  package_size: "1",
  package_size_unit: PACKAGE_SIZE_UNIT_MILLILITER,
  package_type: PACKAGE_TYPE_BOTTLE,
};

export const CLAIM_MEDICATION_PANADOL = {
  organization_bundle: {
    organization_bundle_medication: {
      medication: MEDICATION_PANADOL,
    },
  },
  quantity: 1,
  dosage_quantity: 1,
  frequency: 6,
  frequency_unit: CLAIM_MEDICATION_FREQUERCY_HOURS,
};
export const CLAIM_MEDICATION_NAPROXEN = {
  organization_bundle: {
    organization_bundle_medication: {
      medication: MEDICATION_NAPROXEN,
    },
  },
  quantity: 1,
  dosage_quantity: 1,
  frequency: 12,
  frequency_unit: CLAIM_MEDICATION_FREQUERCY_HOURS,
};
export const CLAIM_MEDICATION_IBUPROFEN = {
  organization_bundle: {
    organization_bundle_medication: {
      medication: MEDICATION_IBUPROFEN,
    },
  },
  quantity: 1,
  dosage_quantity: 1,
  frequency: 8,
  frequency_unit: CLAIM_MEDICATION_FREQUERCY_HOURS,
};
export const CLAIM_MEDICATION_GABAPENTIN = {
  organization_bundle: {
    organization_bundle_medication: {
      medication: MEDICATION_GABAPENTIN,
    },
  },
  quantity: 1,
  dosage_quantity: 1,
  frequency: 12,
  frequency_unit: CLAIM_MEDICATION_FREQUERCY_HOURS,
};
export const CLAIM_MEDICATION_TIZANIDINE_2 = {
  organization_bundle: {
    organization_bundle_medication: {
      medication: MEDICATION_TIZANIDINE_2,
    },
  },
  quantity: 1,
  dosage_quantity: 1,
  frequency: 8,
  frequency_unit: CLAIM_MEDICATION_FREQUERCY_HOURS,
};
export const CLAIM_MEDICATION_TIZANIDINE_4 = {
  organization_bundle: {
    organization_bundle_medication: {
      medication: MEDICATION_TIZANIDINE_4,
    },
  },
  quantity: 1,
  dosage_quantity: 1,
  frequency: 8,
  frequency_unit: CLAIM_MEDICATION_FREQUERCY_HOURS,
};
export const CLAIM_MEDICATION_ERYTHROMYCIN = {
  organization_bundle: {
    organization_bundle_medication: {
      medication: MEDICATION_ERYTHROMYCIN,
    },
  },
  quantity: 1,
  dosage_quantity: 1,
  frequency: 6,
  frequency_unit: CLAIM_MEDICATION_FREQUERCY_HOURS,
};
export const CLAIM_MEDICATION_SOLFACETAMIDE_SODIUM = {
  organization_bundle: {
    organization_bundle_medication: {
      medication: MEDICATION_SOLFACETAMIDE_SODIUM,
    },
  },
  quantity: 1,
  dosage_quantity: 1,
  frequency: 4,
  frequency_unit: CLAIM_MEDICATION_FREQUERCY_HOURS,
};
export const CLAIM_MEDICATION_CIPROFLOXACIN = {
  organization_bundle: {
    organization_bundle_medication: {
      medication: MEDICATION_CIPROFLOXACIN,
    },
  },
  quantity: 1,
  dosage_quantity: 1,
  frequency: null,
  frequency_unit: null,
};
export const CLAIM_MEDICATION_OFLOXACIN = {
  organization_bundle: {
    organization_bundle_medication: {
      medication: MEDICATION_OFLOXACIN,
    },
  },
  quantity: 1,
  dosage_quantity: 1,
  frequency: null,
  frequency_unit: null,
};
