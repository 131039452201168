import React from "react";
import { CustomNavTabs } from "@components/Custom/CustomTabs";
import {
  CONSUMABLES_HREF,
  CONSUMABLES_TITLE,
  CPTS_HREF,
  CPTS_TITLE,
  ICDS_HREF,
  ICDS_TITLE,
  MEDICATIONS_HREF,
  MEDICATIONS_TITLE,
  ORGANIZATION_CLINICIANS_HREF,
  ORGANIZATION_CLINICIANS_TITLE,
} from "@constants/static/routing";
import Stack from "@mui/material/Stack";
import { useQueryFetchOrganizationRelatedObjectsCount } from "@redux/actions/organizations/organizations";
import ConsumablesGrid from "../../Consumables/Consumables/ConsumablesGrid";
import CptsGrid from "../../Cpts/Cpts/CptsGrid";
import IcdsGrid from "../../Icds/Icds/IcdsGrid";
import MedicationsGrid from "../../Medications/Medications/MedicationsGrid";
import OrganizationCliniciansGrid from "../../OrganizationClinicians/OrganizationClinicians/OrganizationCliniciansGrid";

const tabs = [
  {
    label: ORGANIZATION_CLINICIANS_TITLE,
    tab: ORGANIZATION_CLINICIANS_HREF,
    fieldname: "num_clinicians",
  },
  {
    label: CONSUMABLES_TITLE,
    tab: CONSUMABLES_HREF,
    fieldname: "num_consumables",
  },
  {
    label: MEDICATIONS_TITLE,
    tab: MEDICATIONS_HREF,
    fieldname: "num_medications",
  },
  {
    label: CPTS_TITLE,
    tab: CPTS_HREF,
    fieldname: "num_cpts",
  },
  {
    label: ICDS_TITLE,
    tab: ICDS_HREF,
    fieldname: "num_icds",
  },
];

const TabComponentMapper = {
  [ORGANIZATION_CLINICIANS_HREF]: OrganizationCliniciansGrid,
  [CONSUMABLES_HREF]: ConsumablesGrid,
  [MEDICATIONS_HREF]: MedicationsGrid,
  [CPTS_HREF]: CptsGrid,
  [ICDS_HREF]: IcdsGrid,
};

export default function HealthcareOrganizationConfigurationsBody() {
  const searchParams = new URLSearchParams(window.location.search);
  const tab = searchParams.get("tab");
  const Component = TabComponentMapper[tab] || React.Fragment;
  const { data } = useQueryFetchOrganizationRelatedObjectsCount({});

  return (
    <Stack spacing={2}>
      <CustomNavTabs
        tabs={tabs}
        defaultTab={ORGANIZATION_CLINICIANS_HREF}
        tabCountData={data}
      />
      <div>
        <Component />
      </div>
    </Stack>
  );
}
