import React from "react";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import OrganizationAgreementsGrid from "./OrganizationAgreementsGrid";
import OrganizationAgreementsHeader from "./OrganizationAgreementsHeader";

export default function OrganizationAgreements() {
  return (
    <>
      <OrganizationAgreementsHeader />
      <CustomPage>
        <OrganizationAgreementsGrid />
      </CustomPage>
    </>
  );
}
