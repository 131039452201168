import React from "react";
import CustomPage from "./CustomPage";
import CustomBox from "../CustomBox";

const boxId = "CustomStickyAppBarId";

export default function CustomStickyAppBar({ children, wrapperProps = {} }) {
  const [isSticky, setIsSticky] = React.useState(false);

  const className = isSticky ? "elevated-8 whiteBg" : "whiteBg";
  const customPageProps = isSticky ? { maxWidth: "100%", px: 3 } : {};

  React.useEffect(() => {
    const handleScroll = () => {
      const box = document.getElementById(boxId);
      box && setIsSticky(box.getBoundingClientRect().top <= 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <CustomBox
      id={boxId}
      zIndex={2}
      position="sticky"
      top={0}
      className={className}
      mt={10}
      mb={2}
      {...wrapperProps}
    >
      <CustomPage isHeader={true} {...customPageProps}>
        <CustomBox overflow="visible" py={2.5} my={-2.5}>
          {typeof children === "function" ? children({ isSticky }) : children}
        </CustomBox>
      </CustomPage>
    </CustomBox>
  );
}
