import React from "react";
import { getMedicationOptionLabel } from "@components/Custom/CustomAutocomplete/MedicationAutocomplete/MedicationAutocompleteComponents";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomSection from "@components/Custom/CustomSection";

export default function OrganizationBundleCodeDetailsData({
  object,
  isMinified = false,
}) {
  const { internal_code, description, organization_bundle_medication } = object;
  const hasOrganizationBundleMedication = Boolean(
    organization_bundle_medication
  );

  return (
    <CustomSection title="Code details" isMinified={isMinified}>
      <CustomGrid>
        <CustomLabeledText label="Internal code" value={internal_code} />
        <CustomLabeledText label="Description" value={description} />
        {hasOrganizationBundleMedication && (
          <CustomLabeledText
            label="Medication"
            value={getMedicationOptionLabel(
              organization_bundle_medication?.medication,
              false,
              true
            )}
          />
        )}
      </CustomGrid>
    </CustomSection>
  );
}
