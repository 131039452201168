import React from "react";
import CustomActionDialogTitle from "@components/Custom/CustomActionDialog/CustomActionDialogTitle";
import useFetchOrganization from "@helpers/hooks/useFetchObjects/useFetchOrganization";

export default function InsuranceOrganizationActionDialogTitle({
  title,
  fullScreen = false,
  image,
}) {
  const { data: organization } = useFetchOrganization();
  const { image: organizationImage } = organization || {};

  return (
    <CustomActionDialogTitle
      image={image || organizationImage}
      title={title}
      fullScreen={fullScreen}
    />
  );
}
