import { useQueryFetchOrganizations } from "@redux/actions/organizations/organizations";
import { selectorAuthClaimMode } from "@redux/selectors/auth";
import { useSelector } from "react-redux";

export default function useFetchOrganizations({ searchQuery = "" }) {
  const claimMode = useSelector(selectorAuthClaimMode);
  const infiniteQuery = useQueryFetchOrganizations({
    searchQuery,
    filters: {
      as_tpa_submitters_filter: "1",
      ...(Boolean(searchQuery)
        ? {}
        : { as_submitter_claim_mode_filter: claimMode }),
    },
    sortQuery: "name",
  });

  return { infiniteQuery, claimMode };
}
