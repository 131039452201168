import React from "react";
import HealthcareOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import CustomButton from "@components/Custom/CustomButton";
import CustomCard from "@components/Custom/CustomCard";
import {
  PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO,
  PAYMENT_PLAN_TYPE_SUBSCRIPTION,
} from "@constants/payments/payments";
import { TOKEN_SHORT_NAME_SINGULAR } from "@constants/static/payment";
import { tokensToMoney } from "@helpers/parse/tokens";
import Stack from "@mui/material/Stack";
import { invalidateOrganizationCardsTSQ } from "@redux/actions/organizations/organization-cards";
import {
  invalidateOrganizationPaymentCyclesTSQ,
  settleAmountOrganizationPaymentCycle,
  updateOrganizationCurrentPaymentCycle,
  updateOrganizationCurrentPaymentCycleObjectTSQ,
} from "@redux/actions/payments/organization-payment-cycles";
import { selectorAuthBillingConversion } from "@redux/selectors/auth";
import { enqueueSnackbar } from "notistack";
import pluralize from "pluralize";
import { useSelector } from "react-redux";
import OrganizationPaymentCycleFormData from "./OrganizationPaymentCycleFormData";
import OrganizationPaymentCyclePaymentPlanType from "./OrganizationPaymentCyclePaymentPlanType";
import {
  OrganizationPaymentConfirmNextCycle,
  OrganizationPaymentNextCycle,
} from "./OrganizationPaymentNextCycle";

const initialBillState = {
  isOpen: false,
  to_settle: { num_tokens: 0, rate_per_token: 0 },
};

export default function OrganizationPaymentCycleForm({ object }) {
  const { id, payment_plan_type } = object;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(object);
  const [formDataBeforeBill, setFormDataBeforeBill] = React.useState({});
  const [isConfirm, setIsConfirm] = React.useState(false);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const [bill, setBill] = React.useState(initialBillState);
  const billing_conversion = useSelector(selectorAuthBillingConversion);
  const { isOpen: isOpenAlert } = alertState;
  const { isOpen: isOpenBill, to_settle } = bill;

  const isMainDialogOpen = isOpen && !isOpenAlert && !isOpenBill;
  const isBillDialogOpen = isOpenBill && !isOpenAlert;
  const amountToSettle = (
    <>
      {"You must first settle your current bill of "}
      <b>{pluralize(TOKEN_SHORT_NAME_SINGULAR, to_settle?.num_tokens, true)}</b>
      {", equivalent to "}
      <b>{tokensToMoney({ ...to_settle, billing_conversion })}</b>
      {", before changing payment plans."}
    </>
  );

  const handleOpen = (e) => {
    e?.preventDefault();
    setIsOpen(true);
  };

  const handleClose = (e) => {
    e?.preventDefault();
    isConfirm ? setIsConfirm(false) : setIsOpen(!isOpen);
  };

  const handleCloseBill = (e) => {
    e?.preventDefault();
    setBill((prev) => ({ ...prev, isOpen: false }));
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    const {
      subscription_plan,
      pay_as_you_go_usage_token_threshold,
      payment_plan_type: formDataPaymentPlanType,
    } = formData;

    switch (formDataPaymentPlanType) {
      case PAYMENT_PLAN_TYPE_SUBSCRIPTION:
        if (!Boolean(subscription_plan))
          return setAlertState({
            isOpen: true,
            errorStr: "Please select a payment plan to proceed.",
          });

        break;

      case PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO:
        break;

      default:
        return setAlertState({
          isOpen: true,
          errorStr: "Invalid payment plan type",
        });
    }

    if (!isConfirm) {
      setIsConfirm(true);
    } else {
      setIsProcessing(true);
      updateOrganizationCurrentPaymentCycle({
        id,
        payment_plan_type: formDataPaymentPlanType,
        pay_as_you_go_usage_token_threshold,
        subscription_plan: subscription_plan?.id,
      })
        .then(({ payload }) => {
          enqueueSnackbar({
            variant: "success",
            message: "Plan successfully updated",
          });
          updateOrganizationCurrentPaymentCycleObjectTSQ({ object: payload });
          setIsOpen(false);
        })
        .catch(({ payload }) => {
          const { reason, latest_version, to_settle } = payload;
          if (Boolean(latest_version)) {
            updateOrganizationCurrentPaymentCycleObjectTSQ({
              object: latest_version,
            });
            setAlertState({
              isOpen: true,
              errorStr:
                "A new payment cycle is in place, please verify before proceeding with this purchase.",
            });
          } else if (Boolean(to_settle)) {
            setBill({ isOpen: true, to_settle });
          } else {
            setAlertState({ isOpen: true, errorStr: reason });
          }
        })
        .finally(() => {
          setIsProcessing(false);
          invalidateOrganizationPaymentCyclesTSQ();
          invalidateOrganizationCardsTSQ();
        });
    }
  };

  const handleSubmitSettleBill = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    settleAmountOrganizationPaymentCycle({
      id,
      to_settle,
    })
      .then(({ payload }) => {
        enqueueSnackbar({
          variant: "success",
          message: "Bill successfully settled",
        });
        updateOrganizationCurrentPaymentCycleObjectTSQ({ object: payload });
        setFormDataBeforeBill(formData);
        handleCloseBill(e);
      })
      .catch(({ payload }) => {
        const { reason, latest_version } = payload;
        if (Boolean(latest_version)) {
          updateOrganizationCurrentPaymentCycleObjectTSQ({
            object: latest_version,
          });
          setAlertState({
            isOpen: true,
            errorStr:
              "A new payment cycle is in place, please verify before proceeding with this purchase.",
          });
        } else {
          setAlertState({ isOpen: true, errorStr: reason });
        }
      })
      .finally(() => {
        setIsProcessing(false);
        invalidateOrganizationPaymentCyclesTSQ();
        invalidateOrganizationCardsTSQ();
      });
  };

  React.useEffect(() => {
    isOpen && setFormData({ ...object, ...formDataBeforeBill });
  }, [isOpen, object, formDataBeforeBill]);

  React.useEffect(() => {
    if (isOpen) {
      setFormDataBeforeBill({});
      setIsConfirm(false);
      setAlertState(initialAlertState);
      setBill(initialBillState);
    }
  }, [isOpen]);

  const memoizedDialogProps = isConfirm
    ? {
        maxWidth: "xs",
        TitleComponent: HealthcareOrganizationActionDialogTitle,
        titleComponentProps: { title: "Review & Confirm" },
        noText: "Change plan",
        yesText: "Confirm",
      }
    : {
        maxWidth: "sm",
        TitleComponent: HealthcareOrganizationActionDialogTitle,
        titleComponentProps: { title: "Change payment plan" },
        isOk: true,
        okText: "Proceed to confirmation",
      };

  return (
    <>
      <CustomCard
        title="Payment plan"
        action={<CustomButton label="Change" onClick={handleOpen} />}
      >
        <Stack spacing={1}>
          <div style={{ maxWidth: "max-content" }}>
            <OrganizationPaymentCyclePaymentPlanType
              payment_plan_type={payment_plan_type}
              withTooltip={true}
            />
          </div>
          <OrganizationPaymentNextCycle object={object} />
        </Stack>
      </CustomCard>
      <CustomActionDialog
        isOpen={isMainDialogOpen}
        isProcessing={isProcessing}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        {...memoizedDialogProps}
      >
        {isConfirm ? (
          <OrganizationPaymentConfirmNextCycle object={formData} />
        ) : (
          <OrganizationPaymentCycleFormData
            formData={formData}
            setFormData={setFormData}
          />
        )}
      </CustomActionDialog>
      <CustomActionDialog
        isOpen={isBillDialogOpen}
        isProcessing={isProcessing}
        handleClose={handleCloseBill}
        handleSubmit={handleSubmitSettleBill}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
        titleComponentProps={{ title: "Outstanding bill" }}
        subtitleComponentProps={{ title: amountToSettle }}
        isBodyEmpty={true}
        noText="Cancel"
        yesText="Settle bill"
      />
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
    </>
  );
}
