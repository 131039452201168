import React from "react";
import useFetchPagination from "@helpers/hooks/useFetchObjects/useFetchPagination";
import useSort from "@helpers/hooks/useSort";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import CustomNoResultsFound from "../CustomNoResultsFound";
import CustomDataGridPagination from "./CustomDataGridPagination";

export const DISABLED_ROW_CLASSNAME = "MuiDataGrid-row--deleted";

const initialPaginationModel = {
  page: 0,
  pageSize: 10,
};

const useCustomInfiniteDataGrid = ({
  initialSortModel = [],
  useQueryFn = () => {},
  useQueryFnProps = {},
  dataGridProps = {},
  useSortObject = undefined,
  isClientPagination = false,
}) => {
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = React.useState(
    initialPaginationModel
  );
  const { page, pageSize } = paginationModel;
  const { sortQuery, sortModel, onSortModelChange } = useSort({
    initialSortModel,
    useSortObject,
  });
  const { navigateTo = null, ...otherDataGridProps } = dataGridProps;
  const handleRowClick = (params, e) => {
    if (typeof navigateTo !== "function") return;
    const { row } = params;
    const url = navigateTo(row);
    if (Boolean(url))
      e.ctrlKey || e.metaKey ? window.open(url, "_blank") : navigate(url);
  };
  const paginationMode = isClientPagination ? "client" : "server";
  const queryFnProps = {
    ...useQueryFnProps,
    sortQuery,
    page: isClientPagination ? 1 : page + 1,
    page_size: pageSize,
  };
  const jsonifiedUseQueryFnProps = JSON.stringify(useQueryFnProps);
  const jsonifiedQueryFnProps = JSON.stringify(queryFnProps);
  const {
    rows = [],
    rowCount = 0,
    loading,
    isError,
  } = useFetchPagination({
    useQueryFn,
    queryFnProps,
  });
  const finalRows = isClientPagination
    ? rows.slice(page * pageSize, (page + 1) * pageSize)
    : rows;

  React.useEffect(() => {
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
  }, [jsonifiedUseQueryFnProps]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [jsonifiedQueryFnProps]);

  return {
    rows: finalRows,
    rowCount,
    loading,
    isError,
    paginationMode,
    sortModel,
    onSortModelChange,
    otherDataGridProps,
    paginationModel,
    setPaginationModel,
    handleRowClick,
  };
};

export default function CustomInfiniteDataGrid({
  initialSortModel = [],
  useQueryFn = () => {},
  useQueryFnProps = {},
  dataGridProps = {},
  useSortObject = undefined,
  isClientPagination = false,
  noResultsProps = { wrapperProps: { sx: { p: 3 } } },
  paginationActions = undefined,
}) {
  const {
    rows,
    rowCount,
    loading,
    isError,
    paginationMode,
    sortModel,
    onSortModelChange,
    otherDataGridProps,
    paginationModel,
    setPaginationModel,
    handleRowClick,
  } = useCustomInfiniteDataGrid({
    initialSortModel,
    useQueryFn,
    useQueryFnProps,
    dataGridProps,
    useSortObject,
    isClientPagination,
  });

  if (isError)
    return (
      <Typography variant="body2" color="secondary">
        {"An error has occured, please refresh the page."}
      </Typography>
    );

  return (
    <Stack>
      <DataGridPro
        className="stickyHeaders"
        autoHeight
        disableColumnMenu
        rows={rows}
        hideFooter
        rowCount={rowCount}
        sortingMode={paginationMode}
        paginationMode={paginationMode}
        disableColumnFilter
        rowHeight={83}
        loading={loading}
        slots={{
          loadingOverlay: LinearProgress,
          noResultsOverlay: CustomNoResultsFound,
          noRowsOverlay: CustomNoResultsFound,
        }}
        slotProps={{
          noResultsOverlay: noResultsProps,
          noRowsOverlay: noResultsProps,
        }}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        onRowClick={handleRowClick}
        {...otherDataGridProps}
      />
      <CustomDataGridPagination
        loading={loading}
        rowCount={rowCount}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        actions={paginationActions}
      />
    </Stack>
  );
}
