import React from "react";
import HealthcareOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import {
  CustomSummaryDialog,
  initialSummaryState,
} from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import CustomButton from "@components/Custom/CustomButton";
import { formatConsumableFormData } from "@constants/avey/consumables";
import { getConsumablesHref } from "@constants/static/routing";
import {
  invalidateConsumablesTSQ,
  updateConsumable,
} from "@redux/actions/avey/consumables";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { ConsumableDataReviewAndConfirm } from "../Consumable/ConsumableData";
import ConsumableFormData from "./ConsumableFormData";

export default function ConsumableUpdateForm({ object }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(object);
  const [summaryState, setSummaryState] = React.useState(initialSummaryState);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const { isOpen: isOpenSummary, summaries } = summaryState;
  const { isOpen: isOpenAlert } = alertState;

  const isOpenSummaryDialog = isOpenSummary && !isOpenAlert;
  const isOpenMainDialog = isOpen && !isOpenSummaryDialog && !isOpenAlert;

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e, isCheck = true) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProcessing(true);
    const formattedFormData = formatConsumableFormData({
      isCheck,
      formData,
    });
    updateConsumable(formattedFormData)
      .then(({ payload }) => {
        const { instance, summaries, exceptions } = payload;
        if (Boolean(instance)) {
          setIsOpen(false);
          enqueueSnackbar({
            variant: "success",
            message: "Consumable successfully updated",
          });
          invalidateConsumablesTSQ();
          navigate(getConsumablesHref({ consumable__id: instance.id }));
        } else if (exceptions?.length > 0) {
          setAlertState({ isOpen: true, errors: exceptions });
        } else {
          setSummaryState({ isOpen: true, summaries });
        }
      })
      .catch(({ payload }) => {
        setAlertState({ isOpen: true, errorStr: payload });
        setSummaryState((prev) => ({ ...prev, isOpen: false }));
      })
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitData = (e) => handleSubmit(e, false);
  const handleCheckData = (e) => handleSubmit(e, true);

  React.useEffect(() => {
    if (isOpen) {
      setFormData(object);
      setAlertState(initialAlertState);
    }
    setSummaryState(initialSummaryState);
  }, [isOpen, object]);

  return (
    <>
      <CustomButton
        variant="contained"
        label="Edit consumable"
        icon="EditIcon"
        onClick={handleIsOpen}
      />
      <CustomActionDialog
        isOpen={isOpenMainDialog}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleCheckData}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
        titleComponentProps={{ title: "Edit consumable" }}
      >
        <ConsumableFormData formData={formData} setFormData={setFormData} />
      </CustomActionDialog>
      <CustomSummaryDialog
        summaries={summaries}
        isOpen={isOpenSummaryDialog}
        isProcessing={isProcessing}
        setSummaryState={setSummaryState}
        handleSubmit={handleSubmitData}
        ContentComponent={ConsumableDataReviewAndConfirm}
        contentComponentProps={{ object: formData }}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
      />
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
    </>
  );
}
