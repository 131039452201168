import React from "react";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import OrganizationCardsGrid from "./OrganizationCardsGrid";
import OrganizationCardsHeader from "./OrganizationCardsHeader";

export default function OrganizationCards() {
  return (
    <>
      <OrganizationCardsHeader />
      <CustomPage>
        <OrganizationCardsGrid />
      </CustomPage>
    </>
  );
}
