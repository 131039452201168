import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Skeleton from "@mui/material/Skeleton";

export default function CustomBackdrop() {
  return (
    <Backdrop
      open={true}
      style={{ backgroundColor: "transparent", zIndex: 1000 }}
    >
      <Skeleton
        style={{ height: "100%", width: "100%" }}
        variant="rectangle"
        animation="wave"
      />
    </Backdrop>
  );
}
