import React from "react";
import CustomTooltip from "./CustomTooltip";
import { InfoIcon } from "@rimads/assets";

export default function CustomMoreInformationIcon({ children, ...props }) {
  return (
    <CustomTooltip title={children} {...props}>
      <InfoIcon />
    </CustomTooltip>
  );
}
