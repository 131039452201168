import React from "react";
import { getIntOrFloat } from "@helpers/parse/numbers";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AVEY_BLACK, AVEY_GREY, AVEY_WHITE_LIGHT } from "@styles/theme";
import CustomButton from "../CustomButton";
import { CustomOutlinedTextField } from "../CustomOutlinedField";

export default function CustomDataGridPagination({
  loading = false,
  rowCount = 0,
  paginationModel = {},
  setPaginationModel = () => {},
  actions = undefined,
}) {
  const { page, pageSize } = paginationModel;

  const onPageChange = (diff) =>
    setPaginationModel((prev) => ({ ...prev, page: prev?.page + diff }));

  const handlePageSizeChange = (e) =>
    setPaginationModel((prev) => ({
      ...prev,
      page: 0,
      pageSize: parseInt(e.target.value, 10),
    }));

  const handleNextPage = () => onPageChange(1);
  const handlePrevPage = () => onPageChange(-1);

  const from = page * pageSize + 1;
  const to = Math.min(rowCount, (page + 1) * pageSize);
  const isPrevDisabled = page === 0;
  const isNextDisabled = to >= rowCount;
  const hasRows = rowCount > 0;
  const spacing = hasRows ? 3 : 2;
  const fromStr = getIntOrFloat(from);
  const toStr = getIntOrFloat(to);
  const rowCountStr = getIntOrFloat(rowCount);
  const summary = hasRows && `${fromStr}-${toStr} of ${rowCountStr}`;

  return (
    <Box
      bottom={0}
      position="fixed"
      className="whiteBg elevated-8 borderRadiusTop-8"
      alignSelf="center"
      zIndex={2}
    >
      <Stack
        spacing={spacing}
        direction="row"
        alignItems="center"
        p="12px 18px"
      >
        {actions}
        <Stack spacing={2} direction="row" alignItems="center">
          <Typography variant="body2" noWrap>
            {"Rows per page"}
          </Typography>
          <CustomOutlinedTextField
            select
            value={pageSize}
            options={[
              { key: 10, value: 10 },
              { key: 25, value: 25 },
              { key: 50, value: 50 },
              { key: 100, value: 100 },
            ]}
            onChange={handlePageSizeChange}
          />
        </Stack>
        {summary && (
          <Typography variant="body2" noWrap>
            {summary}
          </Typography>
        )}
        <Stack spacing={2} direction="row" alignItems="center">
          <CustomButton
            isSquare={true}
            icon="ArrowCenterLeftIcon"
            useIconAsLabel={true}
            color={isPrevDisabled ? "primary" : "info"}
            variant={isPrevDisabled ? "outlined" : "contained"}
            iconProps={{
              dimension: 12,
              primary_fill: isPrevDisabled ? AVEY_GREY : AVEY_BLACK,
            }}
            onClick={handlePrevPage}
            disabled={isPrevDisabled}
          />
          <CustomButton
            isSquare={true}
            icon="ArrowCenterRightIcon"
            useIconAsLabel={true}
            color={isNextDisabled ? "primary" : "info"}
            variant={isNextDisabled ? "outlined" : "contained"}
            iconProps={{
              dimension: 12,
              primary_fill: isNextDisabled ? AVEY_GREY : AVEY_BLACK,
            }}
            onClick={handleNextPage}
            disabled={isNextDisabled}
          />
        </Stack>
      </Stack>
      <Backdrop
        open={loading}
        className="borderRadius-8"
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          backgroundColor: AVEY_WHITE_LIGHT,
        }}
      />
    </Box>
  );
}
