import React from "react";
import { initialAlertState } from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import { getClaimFinalStatus } from "@constants/claims/claims";
import {
  processClaim,
  updateClaimObjectTSQ,
} from "@redux/actions/claims/claims";
import { enqueueSnackbar } from "notistack";

const parseClaimProcedures = (claim_procedures = []) =>
  claim_procedures?.map((claim_procedure) => ({
    ...claim_procedure,
    amount_approved: claim_procedure?.amount_requested,
  }));

const parseClaimMedications = (claim_medications = []) =>
  claim_medications?.map((claim_medication) => ({
    ...claim_medication,
    amount_approved: claim_medication?.amount_requested,
  }));

export const ClaimProcessorHeaderActionsContext = React.createContext({
  isOpen: false,
  isProcessing: false,
  latestVersionId: null,
  status: null,
  finalStatus: null,
  formData: {},
  alertState: initialAlertState,
  setAlertState: () => {},
  handleClose: () => {},
  handleChangeStatus: () => {},
  handleChangeClaimProcedure: () => {},
  handleChangeClaimMedication: () => {},
  handleChangeNote: () => {},
  handleSubmit: () => {},
});

export const useClaimProcessorActionsContext = () =>
  React.useContext(ClaimProcessorHeaderActionsContext);

export function ClaimHeaderProcessorActionsContextProvider({
  object,
  children,
}) {
  const { id, claim_procedures = [], claim_medications = [] } = object;
  const [isOpen, setIsOpen] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState({
    claim_procedures: parseClaimProcedures(claim_procedures),
    claim_medications: parseClaimMedications(claim_medications),
    processor_user_note: "",
  });
  const [latestVersionId, setLatestVersionId] = React.useState(null);
  const [alertState, setAlertState] = React.useState(initialAlertState);

  const finalStatus = getClaimFinalStatus({ status, formData });

  React.useEffect(() => {
    if (isOpen) {
      setFormData({
        claim_procedures: parseClaimProcedures(claim_procedures),
        claim_medications: parseClaimMedications(claim_medications),
        processor_user_note: "",
      });
      setAlertState(initialAlertState);
    }
  }, [isOpen, claim_procedures, claim_medications]);

  const handleClose = (e) => {
    e?.preventDefault();
    setIsOpen(false);
  };

  const handleChangeStatus = (newValue) => {
    setStatus(newValue);
    setIsOpen(true);
  };

  const handleChangeClaimProcedure = React.useCallback(
    (payload) =>
      setFormData((prevFormData) => ({
        ...prevFormData,
        claim_procedures: prevFormData.claim_procedures.map((claim_procedure) =>
          claim_procedure.id === payload.id ? payload : claim_procedure
        ),
      })),
    [setFormData]
  );

  const handleChangeClaimMedication = React.useCallback(
    (payload) =>
      setFormData((prevFormData) => ({
        ...prevFormData,
        claim_medications: prevFormData.claim_medications.map(
          (claim_medication) =>
            claim_medication.id === payload.id ? payload : claim_medication
        ),
      })),
    [setFormData]
  );

  const handleChangeNote = React.useCallback(
    (e) =>
      setFormData((prevFormData) => ({
        ...prevFormData,
        processor_user_note: e.target.value,
      })),
    [setFormData]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const { claim_procedures = [], processor_user_note } = formData;
    setIsProcessing(true);
    processClaim({
      id,
      claim_procedures: claim_procedures.map(({ id, amount_approved }) => ({
        id,
        amount_approved,
      })),
      processor_user_note: processor_user_note?.trim(),
      status: finalStatus,
    })
      .then(({ payload }) => {
        setIsOpen(false);
        updateClaimObjectTSQ({ object: payload });
        enqueueSnackbar({
          message: "Claim successfully processed",
          variant: "success",
        });
      })
      .catch(({ payload }) => {
        const { reason, latest_version_id } = payload;
        if (Boolean(latest_version_id)) {
          setLatestVersionId(latest_version_id);
        } else {
          setAlertState({ isOpen: true, errorStr: reason });
        }
      })
      .finally(() => setIsProcessing(false));
  };

  return (
    <ClaimProcessorHeaderActionsContext.Provider
      value={{
        isOpen,
        isProcessing,
        latestVersionId,
        status,
        finalStatus,
        formData,
        alertState,
        setAlertState,
        handleClose,
        handleChangeStatus,
        handleChangeClaimProcedure,
        handleChangeClaimMedication,
        handleChangeNote,
        handleSubmit,
      }}
    >
      {children}
    </ClaimProcessorHeaderActionsContext.Provider>
  );
}
