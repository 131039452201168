import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const getCurrencyOptionLabel = (option) =>
  `${option?.code} ${option?.name}`;

export const isCurrencyOptionEqualToValue = (option, value) =>
  option?.code === value?.code;

export const renderCurrencyOption = (props, option) => {
  const { code, name } = option;
  return (
    <div {...props} title={name}>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        width="100%"
      >
        <Typography variant="body2" fontWeight="light" className="ellipsis-1">
          {name}
        </Typography>
        <Typography fontWeight="bold" variant="body2">
          {code}
        </Typography>
      </Stack>
    </div>
  );
};
