import React from "react";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import { useQueryFetchUpload } from "@redux/actions/utils/uploads";
import { useParams } from "react-router-dom";
import ConfigurationsBulkUploadEntriesGrid from "./ConfigurationsBulkUploadEntriesGrid";
import ConfigurationsBulkUploadHeader from "./ConfigurationsBulkUploadHeader";

export default function ConfigurationsBulkUpload({ model_type, bundle_type }) {
  const { upload__id: id } = useParams();
  const { isLoading, data } = useQueryFetchUpload({ id, model_type });

  const hasData = Boolean(data);

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  return (
    <>
      <ConfigurationsBulkUploadHeader
        object={data}
        model_type={model_type}
        bundle_type={bundle_type}
      />
      <CustomPage mt={0}>
        <ConfigurationsBulkUploadEntriesGrid
          object={data}
          model_type={model_type}
          bundle_type={bundle_type}
        />
      </CustomPage>
    </>
  );
}
