import React from "react";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  exportOrganizationClinicians,
  useQueryFetchOrganizationClinicianExports,
} from "@redux/actions/organizations/organization-clinicians";
import ConfigurationsBulkExportForm from "../../Configurations/ConfigurationsBulkExportForm";
import ConfigurationsBulkExportHistory from "../../Configurations/ConfigurationsBulkExportForm/ConfigurationsBulkExportHistory";
import HealthcareOrganizationActionDialogTitle from "../../Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import { describeFilters } from "./OrganizationCliniciansFilters";

export default function OrganizationCliniciansExports({ useQueryFnProps }) {
  return (
    <CustomMoreActions
      icon="DownloadIcon"
      useRotateProps={{ useRotate: false }}
    >
      <ConfigurationsBulkExportForm
        label="clinicians"
        submitFn={exportOrganizationClinicians}
        useQueryFnProps={useQueryFnProps}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
        describeFilters={describeFilters}
      />
      <ConfigurationsBulkExportHistory
        drawerProps={{
          TitleComponent: HealthcareOrganizationActionDialogTitle,
          titleComponentProps: { title: "Clinician exports" },
        }}
        useQueryFn={useQueryFetchOrganizationClinicianExports}
        describeFilters={describeFilters}
      />
    </CustomMoreActions>
  );
}
