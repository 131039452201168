import React from "react";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import Typography from "@mui/material/Typography";
import { useQueryFetchConsumableHistory } from "@redux/actions/avey/consumables";
import { useParams } from "react-router-dom";
import ConsumableData from "./ConsumableData";
import { ConsumableHeaderTitle } from "./ConsumableHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { code, name } = object;
  return (
    <Typography variant="body2" className="ellipsis-1">
      <b>{code}</b> {name}
    </Typography>
  );
};

export default function ConsumableVersionHistory() {
  const { consumable__id: id } = useParams();
  const useQueryFnProps = { id };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchConsumableHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={ConsumableData}
      VersionHistoryObjectTitleComponent={ConsumableHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
