import React from "react";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomFilters from "@components/Custom/CustomFilters";
import useFetchOrganization from "@helpers/hooks/useFetchObjects/useFetchOrganization";
import Grid from "@mui/material/Grid";
import { useQueryFetchOrganizationPolicies } from "@redux/actions/organizations/organization-policies";
import { selectorAuthCurrentUserOrganizationSlug } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import OrganizationPoliciesCreateForms from "./OrganizationPoliciesCreateForms";
import OrganizationPoliciesExports from "./OrganizationPoliciesExports";
import {
  filterOptions,
  formatFilters,
  initialFilterState,
} from "./OrganizationPoliciesFilters";
import OrganizationPoliciesGridProps from "./OrganizationPoliciesGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function OrganizationPoliciesGrid() {
  const { organization__slug: processor_organization__slug } = useParams();
  const { data: processor_organization } = useFetchOrganization();
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const { is_processor_tpa = false } = processor_organization || {};
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const useQueryFnProps = {
    searchQuery,
    filters: {
      newer_version__isnull: true,
      submitter_organization__slug,
      processor_organization__slug,
      ...filters,
    },
  };
  const dataGridProps = React.useMemo(
    () =>
      OrganizationPoliciesGridProps({
        processor_organization__slug,
        is_processor_tpa,
      }),
    [processor_organization__slug, is_processor_tpa]
  );

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
      <Grid item flexGrow={1}>
        <CustomContextSearchField setSearchQuery={setSearchQuery} />
      </Grid>
      <Grid item>
        <CustomFilters
          initialFilterState={initialFilterState}
          filterOptions={filterOptions}
          formatFilters={formatFilters}
          setFilters={setFilters}
        />
      </Grid>
      <Grid item>
        <OrganizationPoliciesCreateForms />
      </Grid>
      <Grid item>
        <OrganizationPoliciesExports useQueryFnProps={useQueryFnProps} />
      </Grid>
      <Grid item xs={12}>
        <CustomInfiniteDataGrid
          useQueryFn={useQueryFetchOrganizationPolicies}
          useQueryFnProps={useQueryFnProps}
          dataGridProps={dataGridProps}
          initialSortModel={initialSortModel}
        />
      </Grid>
    </Grid>
  );
}
