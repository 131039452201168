import React from "react";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import useFetchClaimSubmission from "@helpers/hooks/useFetchObjects/useFetchClaimSubmission";
import ClaimExportsGrid from "./ClaimExportsGrid";
import ClaimExportsHeader from "./ClaimExportsHeader";
import useFetchOrganization from "@helpers/hooks/useFetchObjects/useFetchOrganization";

export default function ClaimExports() {
  const {
    data: claim_submission,
    hasData: hasClaimSubmission,
    isLoading: isLoadingClaimSubmission,
    claim_submission__id,
  } = useFetchClaimSubmission();
  const {
    data: organization,
    hasData: hasOrganization,
    isLoading: isLoadingOrganization,
  } = useFetchOrganization();

  if (isLoadingClaimSubmission || isLoadingOrganization)
    return <CustomBackdrop />;

  if (
    !hasOrganization ||
    (Boolean(claim_submission__id) && !hasClaimSubmission)
  )
    return <Custom404 />;

  return (
    <>
      <ClaimExportsHeader
        claim_submission={claim_submission}
        organization={organization}
      />
      <CustomPage mt={0}>
        <ClaimExportsGrid />
      </CustomPage>
    </>
  );
}
