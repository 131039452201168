import { AlertedIcon, CloseIcon, TickIcon } from "@rimads/assets";
import { AVEY_BLUE, AVEY_RED, AVEY_YELLOW } from "@styles/theme";

export const SYMPTOM_STATUS_PRESENT = "present";
export const SYMPTOM_STATUS_ABSENT = "absent";
export const SYMPTOM_STATUS_OTHER = "other";

export const SYMPTOM_TYPE_SUBJECTIVE = "subjective";
export const SYMPTOM_TYPE_OBJECTIVE = "objective";

export const SYMPTOM_STATUS_INITIAL_MAPPER = {
  [true]: SYMPTOM_STATUS_PRESENT,
  [false]: SYMPTOM_STATUS_ABSENT,
  other: SYMPTOM_STATUS_OTHER,
};

export const SYMPTOM_STATUS_FINAL_MAPPER = {
  [SYMPTOM_STATUS_PRESENT]: true,
  [SYMPTOM_STATUS_ABSENT]: false,
  [SYMPTOM_STATUS_OTHER]: null,
};

export const SYMPTOM_STATUS_TOOLTIP_MAPPER = {
  [SYMPTOM_STATUS_PRESENT]: "Present",
  [SYMPTOM_STATUS_ABSENT]: "Absent",
  [SYMPTOM_STATUS_OTHER]: "Unsure",
};

export const SYMPTOM_STATUS_PRESENT_NEXT_STATUS_MAPPER = {
  [SYMPTOM_STATUS_PRESENT]: SYMPTOM_STATUS_ABSENT,
  [SYMPTOM_STATUS_ABSENT]: SYMPTOM_STATUS_OTHER,
  [SYMPTOM_STATUS_OTHER]: SYMPTOM_STATUS_PRESENT,
};

const TickIconComponent = <TickIcon dimension={14} primary_fill={AVEY_BLUE} />;
const CloseIconComponent = <CloseIcon dimension={14} primary_fill={AVEY_RED} />;
const WarnIconComponent = (
  <AlertedIcon dimension={14} primary_fill={AVEY_YELLOW} />
);

export const SYMPTOM_STATUS_ICON_MAPPER = {
  [SYMPTOM_STATUS_PRESENT]: TickIconComponent,
  [SYMPTOM_STATUS_ABSENT]: CloseIconComponent,
  [SYMPTOM_STATUS_OTHER]: WarnIconComponent,
};
