import React from "react";
import Box from "@mui/material/Box";
import { InView } from "react-intersection-observer";
import CustomCircularProgress from "../CustomCircularProgress";

export default function CustomInfiniteScroll({
  hasNextPage = false,
  isFetchingNextPage = false,
  fetchNextPage = () => {},
  inViewProps = {},
  useCircularProgress = true,
}) {
  const handleInViewChange = (inView) =>
    inView && hasNextPage && fetchNextPage();

  if (hasNextPage) {
    return (
      <InView
        threshold={0}
        as="div"
        onChange={handleInViewChange}
        rootMargin="0px"
        {...inViewProps}
      >
        {useCircularProgress && (
          <Box py={2}>
            <CustomCircularProgress
              position="relative"
              open={isFetchingNextPage}
            />
          </Box>
        )}
      </InView>
    );
  }

  return null;
}
