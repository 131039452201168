import React from "react";
import Stack from "@mui/material/Stack";
import OrganizationPolicyCoveragesFormData from "./OrganizationPolicyCoveragesFormData";
import OrganizationPolicyDetailsFormData from "./OrganizationPolicyDetailsFormData";

export default function OrganizationPolicyFormData({
  is_processor_tpa = false,
  formData = {},
  setFormData = () => {},
}) {
  const { organization_policy_coverages = [], ...otherFormData } =
    formData || {};
  const handleChangeOrganizationPolicyCoverages = (payload) =>
    setFormData((prev) => ({
      ...prev,
      organization_policy_coverages: payload,
    }));
  return (
    <Stack spacing={3}>
      <OrganizationPolicyDetailsFormData
        is_processor_tpa={is_processor_tpa}
        formData={otherFormData}
        setFormData={setFormData}
      />
      <OrganizationPolicyCoveragesFormData
        formData={organization_policy_coverages}
        handleChange={handleChangeOrganizationPolicyCoverages}
      />
    </Stack>
  );
}
