import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import {
  getClaimSubmissionHref,
  getClaimSubmissionUploadHref,
  HOME_HREF,
  HOME_TITLE,
} from "@constants/static/routing";
import { useParams } from "react-router-dom";

export default function ClaimSubmissionUploadsHeader({ object }) {
  const { organization__slug } = useParams();
  const {
    id: claim_submission__id,
    name,
    tpa_organization,
    submitter_organization,
    processor_organization,
  } = object;

  const organization = [
    tpa_organization,
    submitter_organization,
    processor_organization,
  ]
    .filter(Boolean)
    .find(({ slug }) => slug === organization__slug);

  const hrefs = [
    {
      label: HOME_TITLE,
      href: HOME_HREF,
    },
    {
      label: organization?.name || organization__slug,
      href: getClaimSubmissionHref({ organization__slug }),
    },
    {
      label: name,
      href: getClaimSubmissionHref({
        organization__slug,
        claim_submission__id,
      }),
    },
    {
      label: "Uploads",
      href: getClaimSubmissionUploadHref({
        claim_submission__id,
        organization__slug,
      }),
    },
  ];

  return <CustomHeader titleComponentProps={{ title: name }} hrefs={hrefs} />;
}
