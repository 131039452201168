import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import CustomMetaData from "@components/Custom/CustomMetaData";
import {
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_INTERNAL_CODE,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_INVOICE_NUMBER,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_LAST_MENSTRUAL_PERIOD,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_NATURE_OF_CONCEPTION,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_ORGANIZATION_BUNDLE,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_PREAUTHORIZATION_NUMBER,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_PROCEDURE_TYPE,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_QUANTITY,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_RELATED_DATE,
  CLAIM_PROCEDURE_ERROR_FIELDNAMES_TOOTH_NUMBER,
  isErrorFieldnames,
} from "@constants/claims/claim-evaluation-results";
import {
  CLAIM_PROCEDURE_TYPE_MAPPER,
  isProcedureTypeDental,
  isProcedureTypeMaternal,
} from "@constants/claims/claim-procedures";
import { parseDate } from "@helpers/parse/time";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import SubclaimBillingDetailsData from "../Subclaim/SubclaimBillingDetailsData";

export default function ClaimProcedureData({
  object,
  useErrorFieldnames = true,
}) {
  const {
    organization_bundle,
    quantity,
    related_date,
    procedure_type,
    tooth_number,
    nature_of_conception,
    last_menstrual_period,
    preauthorization_number,
    invoice_number,
    meta_data = {},
    error_fieldnames = [],
    ...otherObjectProps
  } = object;

  const errorFieldnames = isErrorFieldnames(
    useErrorFieldnames ? error_fieldnames : []
  );
  const {
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_ORGANIZATION_BUNDLE]:
      organization_bundle_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_QUANTITY]: quantity_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_RELATED_DATE]: related_date_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_PREAUTHORIZATION_NUMBER]:
      preauthorization_number_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_INTERNAL_CODE]:
      internal_code_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_INVOICE_NUMBER]:
      invoice_number_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_TOOTH_NUMBER]: tooth_number_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_NATURE_OF_CONCEPTION]:
      nature_of_conception_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_LAST_MENSTRUAL_PERIOD]:
      last_menstrual_period_error = false,
    [CLAIM_PROCEDURE_ERROR_FIELDNAMES_PROCEDURE_TYPE]:
      procedure_type_error = false,
  } = errorFieldnames;

  const { internal_code, description } = organization_bundle || {};
  const hasDescription = Boolean(description);
  const relatedDate = parseDate(related_date);
  const lastMenstrualPeriod = parseDate(last_menstrual_period);
  const procedureType = CLAIM_PROCEDURE_TYPE_MAPPER[procedure_type];
  const isMaternal = isProcedureTypeMaternal(procedure_type);
  const isDental = isProcedureTypeDental(procedure_type);

  return (
    <Stack spacing={3} flexGrow={1}>
      <CustomListItemCollapse primary="Procedure details">
        <CustomGrid>
          <CustomLabeledText
            label="Internal code"
            value={internal_code}
            isErrorField={organization_bundle_error || internal_code_error}
          />
          {hasDescription && (
            <CustomLabeledText
              label="Internal code description"
              value={description}
            />
          )}
          <CustomLabeledText
            label="Quantity"
            value={quantity}
            isErrorField={quantity_error}
          />
          <CustomLabeledText
            label="Procedure date"
            value={relatedDate}
            isErrorField={related_date_error}
          />
          <CustomLabeledText
            label="Procedure type"
            value={procedureType}
            isErrorField={procedure_type_error}
          />
          {isDental && (
            <CustomLabeledText
              label="Tooth number"
              value={tooth_number}
              isErrorField={tooth_number_error}
            />
          )}
          {isMaternal && (
            <>
              <CustomLabeledText
                label="Nature of conception"
                value={nature_of_conception}
                isErrorField={nature_of_conception_error}
              />
              <CustomLabeledText
                label="Last menstrual period"
                value={lastMenstrualPeriod}
                isErrorField={last_menstrual_period_error}
              />
            </>
          )}
          <CustomLabeledText
            label="Invoice number"
            value={invoice_number}
            isErrorField={invoice_number_error}
          />
          <CustomLabeledText
            label="Preauthorization number"
            value={preauthorization_number}
            isErrorField={preauthorization_number_error}
          />
          <CustomMetaData meta_data={meta_data} />
        </CustomGrid>
      </CustomListItemCollapse>
      <Divider />
      <SubclaimBillingDetailsData
        object={otherObjectProps}
        errorFieldnames={errorFieldnames}
      />
    </Stack>
  );
}
