import React from "react";
import {
  getClaimSubmissionUploadSnackbarKey,
  parseClaimSubmissionUploadSnackbar,
} from "@components/Authenticated/ClaimSubmissions/ClaimSubmissionUploads/ClaimSubmissionUpload/ClaimSubmissionUploadSnackbar";
import {
  getExportObjectSnackbarKey,
  parseExportSnackbar,
} from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkExportForm/ConfigurationsBulkExportSnackbar";
import {
  getBulkUploadSnackbarKey,
  parseBulkUploadSnackbar,
} from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkUpload/ConfigurationsBulkUploads/ConfigurationsBulkUploadsSnackbar";
import { CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING } from "@constants/claims/claim-submission-uploads";
import { getClaimSubmissionRevelantOrganization } from "@constants/claims/claim-submissions";
import { ORGANIZATION_ROLE_SUBMITTER } from "@constants/organizations/organizations";
import { EXPORT_STATUS_PENDING } from "@constants/static/export";
import {
  AVEY_BLOOM_BACKEND_WS_URL,
  LOG_WEBSOCKET_NOTIFICATIONS,
  MODEL_TYPE_CLAIM,
  MODEL_TYPE_CONSUMABLE,
  MODEL_TYPE_MEDICATION,
  MODEL_TYPE_ORGANIZATION_BUNDLE,
  MODEL_TYPE_ORGANIZATION_CLINICIAN,
  MODEL_TYPE_ORGANIZATION_PATIENT,
  MODEL_TYPE_ORGANIZATION_POLICY,
} from "@constants/static/globals";
import {
  UPLOAD_STATUS_COMMITTING,
  UPLOAD_STATUS_PENDING,
} from "@constants/static/upload";
import {
  WEBSOCKET_NOTIFICATION_TYPE_CLAIM,
  WEBSOCKET_NOTIFICATION_TYPE_CLAIM_SUBMISSION,
  WEBSOCKET_NOTIFICATION_TYPE_CLAIM_SUBMISSION_UPLOAD,
  WEBSOCKET_NOTIFICATION_TYPE_EXPORT,
  WEBSOCKET_NOTIFICATION_TYPE_UPLOAD,
  WEBSOCKET_NOTIFICATION_TYPE_UPLOAD_ENTRY,
} from "@constants/static/websockets";
import { updateConsumableExportObjectTSQ } from "@redux/actions/avey/consumables";
import { updateMedicationExportObjectTSQ } from "@redux/actions/avey/medications";
import { updateClaimSubmissionUploadObjectTSQ } from "@redux/actions/claims/claim-submission-uploads";
import { updateClaimSubmissionObjectTSQ } from "@redux/actions/claims/claim-submissions";
import {
  updateClaimExportObjectTSQ,
  updateClaimObjectsTSQ,
  updateClaimObjectTSQ,
} from "@redux/actions/claims/claims";
import { updateOrganizationBundleExportObjectTSQ } from "@redux/actions/organizations/organization-bundles";
import { updateOrganizationClinicianExportObjectTSQ } from "@redux/actions/organizations/organization-clinicians";
import { updateOrganizationPatientExportObjectTSQ } from "@redux/actions/organizations/organization-patients";
import { updateOrganizationPolicyExportObjectTSQ } from "@redux/actions/organizations/organization-policies";
import { updateOrganizationTSQ } from "@redux/actions/organizations/organizations";
import {
  updateUploadEntryObjectsTSQ,
  updateUploadEntryObjectTSQ,
  updateUploadObjectTSQ,
} from "@redux/actions/utils/uploads";
import {
  selectorAuth,
  selectorAuthCurrentUserOrganizationRole,
} from "@redux/selectors/auth";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import ReconnectingWebSocket from "reconnecting-websocket";

export default function useWebsocketHook() {
  const { isAuthenticated, user = {} } = useSelector(selectorAuth);
  const organizationRole = useSelector(selectorAuthCurrentUserOrganizationRole);
  const { id } = user || {};

  React.useEffect(() => {
    if (isAuthenticated && Boolean(id)) {
      const ws = new ReconnectingWebSocket(
        `${AVEY_BLOOM_BACKEND_WS_URL}/${id}/`
      );
      ws.onopen = () => console.log("Backend Websocket Connection Opened");
      ws.onmessage = ({ data }) => handleOnMessage({ data, organizationRole });
      ws.onerror = (e) => console.log("Backend Websocket Connection Error", e);
      ws.onclose = () => console.log("Backend Websocket Connection Closed");
      return () => ws.close();
    }
  }, [isAuthenticated, id, organizationRole]);

  return null;
}

const enqueuedSnackbarKeys = new Set(); // Set to track enqueued snackbar keys

function handleOnMessage({ data, organizationRole }) {
  const { ntype, payload: object } = JSON.parse(data || "{}") || {};
  let organization = null;
  let claim_submission = null;

  LOG_WEBSOCKET_NOTIFICATIONS &&
    console.log("Incoming Websocket Notification", ntype, object);

  switch (ntype) {
    case WEBSOCKET_NOTIFICATION_TYPE_CLAIM_SUBMISSION_UPLOAD:
      const {
        id: claimSubmissionUploadId,
        preprocessing_status,
        claims,
      } = object;
      claim_submission = object?.claim_submission;
      organization = getClaimSubmissionRevelantOrganization({
        object,
        organizationRole,
      });
      updateClaimSubmissionObjectTSQ({ object: claim_submission });
      updateClaimSubmissionUploadObjectTSQ({ object });

      if (
        organizationRole === ORGANIZATION_ROLE_SUBMITTER &&
        preprocessing_status !== CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING
      ) {
        closeSnackbar(
          getClaimSubmissionUploadSnackbarKey({
            id: claimSubmissionUploadId,
            preprocessing_status: CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING,
          })
        );
        const parsedClaimSubmissionUpload =
          parseClaimSubmissionUploadSnackbar(object);
        const { key } = parsedClaimSubmissionUpload;

        // Only enqueue snackbar if key doesn't already exist
        if (!enqueuedSnackbarKeys.has(key)) {
          enqueueSnackbar(parsedClaimSubmissionUpload);
          enqueuedSnackbarKeys.add(key);
        }
      }
      if (Boolean(claims)) updateClaimObjectsTSQ({ objects: claims });
      break;

    case WEBSOCKET_NOTIFICATION_TYPE_CLAIM_SUBMISSION:
      organization = getClaimSubmissionRevelantOrganization({
        object,
        organizationRole,
      });
      updateOrganizationTSQ({ object: organization });
      updateClaimSubmissionObjectTSQ({ object });
      break;

    case WEBSOCKET_NOTIFICATION_TYPE_CLAIM:
      claim_submission = object?.claim_submission;
      organization = getClaimSubmissionRevelantOrganization({
        object: claim_submission,
        organizationRole,
      });
      updateOrganizationTSQ({ object: organization });
      updateClaimSubmissionObjectTSQ({ object: claim_submission });
      updateClaimObjectTSQ({ object });
      break;

    case WEBSOCKET_NOTIFICATION_TYPE_EXPORT:
      const { id: exportObjectId, model_type } = object;
      closeSnackbar(
        getExportObjectSnackbarKey({
          id: exportObjectId,
          status: EXPORT_STATUS_PENDING,
        })
      );
      enqueueSnackbar(parseExportSnackbar(object));

      switch (model_type) {
        case MODEL_TYPE_CONSUMABLE:
          updateConsumableExportObjectTSQ({ object });
          break;

        case MODEL_TYPE_MEDICATION:
          updateMedicationExportObjectTSQ({ object });
          break;

        case MODEL_TYPE_ORGANIZATION_BUNDLE:
          updateOrganizationBundleExportObjectTSQ({ object });
          break;

        case MODEL_TYPE_ORGANIZATION_CLINICIAN:
          updateOrganizationClinicianExportObjectTSQ({ object });
          break;

        case MODEL_TYPE_ORGANIZATION_PATIENT:
          updateOrganizationPatientExportObjectTSQ({ object });
          break;

        case MODEL_TYPE_ORGANIZATION_POLICY:
          updateOrganizationPolicyExportObjectTSQ({ object });
          break;

        case MODEL_TYPE_CLAIM:
          updateClaimExportObjectTSQ({ object });
          break;

        default:
          break;
      }
      break;

    case WEBSOCKET_NOTIFICATION_TYPE_UPLOAD_ENTRY:
      updateUploadEntryObjectTSQ({ object });
      break;

    case WEBSOCKET_NOTIFICATION_TYPE_UPLOAD:
      const {
        id: uploadId,
        entries,
        status: uploadStatus,
        model_type: uploadModelType,
        use_processing_status,
      } = object;

      if (
        ![UPLOAD_STATUS_PENDING, UPLOAD_STATUS_COMMITTING].includes(
          uploadStatus
        )
      ) {
        closeSnackbar(
          getBulkUploadSnackbarKey({
            id: uploadId,
            status: use_processing_status
              ? UPLOAD_STATUS_COMMITTING
              : UPLOAD_STATUS_PENDING,
            model_type: uploadModelType,
          })
        );
        const parsedUpload = parseBulkUploadSnackbar(object);
        const { key } = parsedUpload;

        // Only enqueue snackbar if key doesn't already exist
        if (!enqueuedSnackbarKeys.has(key)) {
          enqueueSnackbar(parsedUpload);
          enqueuedSnackbarKeys.add(key);
        }
      }
      updateUploadObjectTSQ({ object });

      if (Boolean(entries)) updateUploadEntryObjectsTSQ({ objects: entries });
      break;

    default:
      break;
  }
}
