import React from "react";
import HealthcareOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import {
  CustomSummaryDialog,
  initialSummaryState,
} from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import CustomButtonVariant from "@components/Custom/CustomButtonVariant";
import {
  emptyOrganizationClinicianFormData,
  formatOrganizationClinicianFormData,
} from "@constants/organizations/organization-clinicians";
import {
  createOrganizationClinician,
  invalidateOrganizationCliniciansTSQ,
} from "@redux/actions/organizations/organization-clinicians";
import { enqueueSnackbar } from "notistack";
import OrganizationClinicianData from "../OrganizationClinicianData";
import OrganizationClinicianFormData from "../OrganizationClinicianFormData";

export default function OrganizationClinicianCreateForm({
  handleSelectClinician = () => {},
  isTypographyButton = false,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(
    emptyOrganizationClinicianFormData
  );
  const [summaryState, setSummaryState] = React.useState(initialSummaryState);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const { isOpen: isOpenSummary, summaries } = summaryState;
  const { isOpen: isOpenAlert } = alertState;
  const isOpenMainDialog = isOpen && !isOpenSummary && !isOpenAlert;
  const isOpenSummaryDialog = isOpenSummary && !isOpenAlert;

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e, isCheck = true) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProcessing(true);
    const formattedFormData = formatOrganizationClinicianFormData({
      isCheck,
      formData,
    });
    createOrganizationClinician(formattedFormData)
      .then(({ payload }) => {
        const { instance, summaries, exceptions } = payload;
        if (Boolean(instance)) {
          setIsOpen(false);
          enqueueSnackbar({
            variant: "success",
            message: "Clinician successfully created",
          });
          invalidateOrganizationCliniciansTSQ();
          handleSelectClinician(instance);
        } else if (exceptions?.length > 0) {
          setAlertState({ isOpen: true, errors: exceptions });
        } else {
          setSummaryState({ isOpen: true, summaries });
        }
      })
      .catch(({ payload }) => {
        setAlertState({ isOpen: true, errorStr: payload });
      })
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitData = (e) => handleSubmit(e, false);
  const handleCheckData = (e) => handleSubmit(e, true);

  React.useEffect(() => {
    isOpen && setFormData(emptyOrganizationClinicianFormData);
    setAlertState(initialAlertState);
    setSummaryState(initialSummaryState);
  }, [isOpen]);

  return (
    <>
      <CustomButtonVariant
        onClick={handleIsOpen}
        label="Add a clinician"
        isTypographyButton={isTypographyButton}
        isMenuItem={!isTypographyButton}
        typographyProps={{ prefix: "+" }}
        buttonProps={{ icon: "PlusIcon" }}
      />
      <CustomActionDialog
        isOpen={isOpenMainDialog}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleCheckData}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
        titleComponentProps={{ title: "New clinician" }}
      >
        <OrganizationClinicianFormData
          formData={formData}
          setFormData={setFormData}
        />
      </CustomActionDialog>
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
      <CustomSummaryDialog
        summaries={summaries}
        isOpen={isOpenSummaryDialog}
        isProcessing={isProcessing}
        setSummaryState={setSummaryState}
        handleSubmit={handleSubmitData}
        ContentComponent={OrganizationClinicianData}
        contentComponentProps={{
          object: formData,
          useCustomSection: true,
          isMinified: true,
        }}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
      />
    </>
  );
}
