import React from "react";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import CustomThickDivider from "@components/Custom/CustomThickDivider";
import Stack from "@mui/material/Stack";
import MedicationActiveIngredientsFormData from "./MedicationActiveIngredientsFormData";
import MedicationDetailsFormData from "./MedicationDetailsFormData";

export default function MedicationFormData({
  formData = {},
  setFormData = () => {},
}) {
  const { medication_active_ingredients = [], ...otherProps } = formData || {};

  return (
    <Stack spacing={3}>
      <CustomListItemCollapse primary="Medication details" useCollapse={false}>
        <MedicationDetailsFormData
          formData={otherProps}
          setFormData={setFormData}
        />
      </CustomListItemCollapse>
      <CustomThickDivider />
      <CustomListItemCollapse primary="Active ingredients" useCollapse={false}>
        <MedicationActiveIngredientsFormData
          formData={medication_active_ingredients}
          setFormData={setFormData}
        />
      </CustomListItemCollapse>
    </Stack>
  );
}
