import React from "react";
import ClaimExports from "@components/Authenticated/ClaimSubmissions/ClaimExports/ClaimExports";
import ClaimSubmissions from "@components/Authenticated/ClaimSubmissions/ClaimSubmissions/ClaimSubmissions";
import { CLAIM_EXPORTS_HREF } from "@constants/static/routing";
import { Navigate, Route, Routes } from "react-router-dom";
import ClaimSubmissionRoutes from "./claim-submission-routes";

export default function ClaimSubmissionsRoutes() {
  return (
    <Routes>
      <Route path="" Component={ClaimSubmissions} />
      <Route path={`${CLAIM_EXPORTS_HREF}/*`} Component={ClaimExports} />
      <Route path=":claim_submission__id/*" Component={ClaimSubmissionRoutes} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
