import React from "react";
import Typography from "@mui/material/Typography";

export const isIcdOptionEqualToValue = (option, value) =>
  option?.id === value?.id;

export const getIcdOptionLabel = (option) => {
  if (!Boolean(option)) return "";
  const { code = "", description = "" } = option;
  return `${code} ${description}`.trim();
};

export const renderIcdOption = (props, option) => {
  const { code, description } = option;
  return (
    <div {...props}>
      <div>
        <Typography variant="body2" fontWeight="bold">
          {code}
        </Typography>
        <Typography variant="body2" fontWeight="light">
          {description}
        </Typography>
      </div>
    </div>
  );
};
