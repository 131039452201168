import {
  AveyBloomApi,
  PayloadError,
  apiWrapper,
  emptyPaginatedResponse,
  getFormData,
  getQueryParams,
  parsePaginatedResponse,
} from "@helpers/api";
import { updatePaginatedTSQ, useQueryWrapper } from "@helpers/tanstack";

const endpointUrl = "/claims/claim_submission_uploads/";
const queryKeyPlural = "claim_submission_uploads";

export const useQueryFetchClaimSubmissionUploads = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchClaimSubmissionUploads, ...apiWrapperProps })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchClaimSubmissionUploads({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(`${endpointUrl}?${queryParams}`);
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export async function createClaimSubmissionUpload(data) {
  try {
    const formData = getFormData(data);
    const response = await AveyBloomApi({
      headers: { "Content-Type": "multipart/form-data" },
    }).post(endpointUrl, formData);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export function updateClaimSubmissionUploadObjectTSQ({ object }) {
  updatePaginatedTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeyPlural &&
      queryKey[1]?.filters?.claim_submission__id ===
        object?.claim_submission?.id,
    object,
  });
}
