import React from "react";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomFilters from "@components/Custom/CustomFilters";
import Grid from "@mui/material/Grid";
import { useQueryFetchUsers } from "@redux/actions/users/users";
import { selectorAuthCurrentUserId } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import UserManagementAction from "../UserManagementAction";
import UserManagementInviteUserForm from "../UserManagementInviteUserForm";
import {
  formatFilters,
  initialFilterState,
  filterOptions,
} from "./UserManagementFilters";
import UserManagementGridProps from "./UserManagementGridProps";

const initialSortModel = [];

export default function UserManagementGrid() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [state, setState] = React.useState({
    object: null,
    action: null,
    isOpen: false,
  });
  const userId = useSelector(selectorAuthCurrentUserId);
  const useQueryFnProps = { searchQuery, filters };

  const dataGridProps = React.useMemo(
    () => UserManagementGridProps({ userId, setState }),
    [setState, userId]
  );

  return (
    <>
      <div>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item flexGrow={1}>
            <CustomContextSearchField setSearchQuery={setSearchQuery} />
          </Grid>
          <Grid item>
            <CustomFilters
              initialFilterState={initialFilterState}
              filterOptions={filterOptions}
              formatFilters={formatFilters}
              setFilters={setFilters}
            />
          </Grid>
          <Grid item>
            <UserManagementInviteUserForm />
          </Grid>
          <Grid item xs={12}>
            <CustomInfiniteDataGrid
              useQueryFn={useQueryFetchUsers}
              useQueryFnProps={useQueryFnProps}
              dataGridProps={dataGridProps}
              initialSortModel={initialSortModel}
            />
          </Grid>
        </Grid>
      </div>

      <UserManagementAction state={state} setState={setState} />
    </>
  );
}
