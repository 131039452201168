import React from "react";
import Grid from "@mui/material/Grid";
import OrganizationPolicyCoverageData from "./OrganizationPolicyCoverageData";

export default function OrganizationPolicyCoveragesData({
  organization_policy_coverages = [],
  isMinified = false,
}) {
  const spacing = isMinified ? 2 : 4;
  const gridItemProps = isMinified ? { xs: 12 } : { xs: 12, md: 6 };

  if (organization_policy_coverages.length === 0) return null;

  return (
    <div>
      <Grid container spacing={spacing}>
        {organization_policy_coverages.map((object, index) => (
          <Grid item key={index} {...gridItemProps}>
            <OrganizationPolicyCoverageData
              object={object}
              isMinified={isMinified}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
