import React from "react";
import Typography from "@mui/material/Typography";

export const isOrganizationPolicyOptionEqualToValue = (option, value) =>
  option?.id === value?.id;

export const getOrganizationPolicyOptionLabel = (option) => {
  if (!Boolean(option)) return "";
  const { number = "", name = "", description = "" } = option;
  return `${number} ${name} ${description}`.trim();
};

export const renderOrganizationPolicyOption = (props, option) => {
  const { number, name, description } = option;
  return (
    <div {...props}>
      <div>
        <Typography
          variant="body2"
          fontWeight="bold"
        >{`${name} #${number}`}</Typography>
        <Typography variant="body2" fontWeight="light">
          {description}
        </Typography>
      </div>
    </div>
  );
};
