import React from "react";
import HealthcareOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomButton from "@components/Custom/CustomButton";
import { USER_ORGANIZATION_ROLE_VIEWER } from "@constants/users/users";
import { invalidateUsersTSQ, inviteUser } from "@redux/actions/users/users";
import { enqueueSnackbar } from "notistack";
import UserManagementInviteUserFormData from "./UserManagementInviteUserFormData";

const initialFormData = {
  email: "",
  first_name: "",
  last_name: "",
  role: USER_ORGANIZATION_ROLE_VIEWER,
};

const initialErrors = {
  email: "",
  first_name: "",
  last_name: "",
  role: "",
};

export default function UserManagementInviteUserForm() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(initialFormData);
  const [errors, setErrors] = React.useState(initialErrors);

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const { email, first_name, last_name, ...otherFormData } = formData;
    setErrors(initialErrors);
    inviteUser({
      email: email?.trim(),
      first_name: first_name?.trim(),
      last_name: last_name?.trim(),
      ...otherFormData,
    })
      .then(({ payload }) => {
        setIsProcessing(false);
        setIsOpen(false);
        enqueueSnackbar({
          message: (
            <div>
              {"Invitation successfully sent to "}
              <b>{payload.email}</b>
            </div>
          ),
          variant: "success",
        });
        invalidateUsersTSQ();
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        setErrors(payload);
      });
  };

  React.useEffect(() => {
    if (isOpen) {
      setFormData(initialFormData);
      setErrors(initialErrors);
    }
  }, [isOpen]);

  return (
    <>
      <CustomButton
        color="primary"
        variant="contained"
        onClick={handleIsOpen}
        label="Invite user"
        icon="PlusIcon"
      />
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        TitleComponent={HealthcareOrganizationActionDialogTitle}
        titleComponentProps={{ title: "Invite a user" }}
        subtitleComponentProps={{
          title:
            "Invite your team to collaborate by providing the following details.",
        }}
        yesText="Send invite"
      >
        <UserManagementInviteUserFormData
          formData={formData}
          errors={errors}
          setFormData={setFormData}
        />
      </CustomActionDialog>
    </>
  );
}
