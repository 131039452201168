import React from "react";
import CustomStatusTag from "@components/Custom/CustomStatusTag";
import {
  UPLOAD_ENTRY_PREPROCESSING_STATUS_TEXT_MAPPER,
  UPLOAD_ENTRY_PROCESSING_STATUS_PROPS_MAPPER,
  UPLOAD_ENTRY_PROCESSING_STATUS_TEXT_MAPPER,
  UPLOAD_ENTRY_STATUS_PENDING,
} from "@constants/static/upload";

export default function ConfigurationsBulkUploadEntryStatus({
  preprocessing_status,
  processing_status,
}) {
  if (processing_status !== UPLOAD_ENTRY_STATUS_PENDING) {
    return (
      <CustomStatusTag
        status={processing_status}
        textMapper={UPLOAD_ENTRY_PROCESSING_STATUS_TEXT_MAPPER}
        propsMapper={UPLOAD_ENTRY_PROCESSING_STATUS_PROPS_MAPPER}
      />
    );
  }

  return (
    <CustomStatusTag
      status={preprocessing_status}
      textMapper={UPLOAD_ENTRY_PREPROCESSING_STATUS_TEXT_MAPPER}
      propsMapper={UPLOAD_ENTRY_PROCESSING_STATUS_PROPS_MAPPER}
    />
  );
}
