import React from "react";
import CustomChip from "@components/Custom/CustomChip";
import {
  ORGANIZATION_PATIENT_VISIT_TYPE_BOTH,
  ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT,
  ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT,
  ORGANIZATION_PATIENT_VISIT_TYPE_SHORTHAND_TEXT_MAPPER,
  ORGANIZATION_PATIENT_VISIT_TYPE_TEXT_MAPPER,
} from "@constants/organizations/organization-patients";
import Stack from "@mui/material/Stack";

const OrganizationPatientVisitTypeTag = ({ visit_type }) => (
  <div style={{ width: "max-content" }}>
    <CustomChip
      name={ORGANIZATION_PATIENT_VISIT_TYPE_SHORTHAND_TEXT_MAPPER[visit_type]}
      title={ORGANIZATION_PATIENT_VISIT_TYPE_TEXT_MAPPER[visit_type]}
      color="grey"
    />
  </div>
);

export default function OrganizationPatientVisitType({ visit_type = {} }) {
  switch (visit_type) {
    case ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT:
    case ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT:
      return <OrganizationPatientVisitTypeTag visit_type={visit_type} />;

    case ORGANIZATION_PATIENT_VISIT_TYPE_BOTH:
      return (
        <Stack spacing={1} direction="row" alignItems="center">
          <OrganizationPatientVisitTypeTag
            visit_type={ORGANIZATION_PATIENT_VISIT_TYPE_INPATIENT}
          />
          <OrganizationPatientVisitTypeTag
            visit_type={ORGANIZATION_PATIENT_VISIT_TYPE_OUTPATIENT}
          />
        </Stack>
      );
    default:
      return null;
  }
}
