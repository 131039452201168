import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomActionDialogTitle from "@components/Custom/CustomActionDialog/CustomActionDialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { updateOrganizationAgreementObjectTSQ } from "@redux/actions/organizations/organization-agreements";
import {
  invalidateOrganizationsTSQ,
  invalidatePartnerOrganizationsTSQ,
  updateOrganizationAffiliation,
} from "@redux/actions/organizations/organizations";
import { enqueueSnackbar } from "notistack";

const ReactivateSubtitleComponent = ({ name }) => (
  <Stack spacing={2}>
    <Typography variant="body2">
      {`Reactivating your partnership with ${name} will allow you to continue creating claims for this insurer.`}
    </Typography>
    <Typography variant="body2" fontWeight="bold">
      {`Existing configurations have been saved and will be available once you reactivate ${name}.`}
    </Typography>
  </Stack>
);

const DeactivateSubtitleComponent = ({ name }) => (
  <Stack spacing={2}>
    <Typography variant="body2">
      {`Deactivating your partnership with ${name} will prevent you from creating new claims for this insurer.`}
    </Typography>
    <Typography variant="body2" fontWeight="bold">
      {`Existing configurations are safe and will be available if you choose to reactivate ${name} later.`}
    </Typography>
  </Stack>
);

export default function OrganizationAffiliationActivateForm({
  object,
  isOpen,
  handleClose = () => {},
}) {
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { processor_organization, time_deleted } = object || {};
  const { name, image } = processor_organization;
  const isDeleted = Boolean(time_deleted);
  const dialogProps = isDeleted
    ? {
        TitleComponent: CustomActionDialogTitle,
        titleComponentProps: {
          image,
          title: `Reactivate ${name} partner?`,
          fullScreen: false,
        },
        SubtitleComponent: ReactivateSubtitleComponent,
        subtitleComponentProps: { name },
        yesText: "Yes, reactivate",
        yesButtonProps: { color: "success", variant: "contained" },
      }
    : {
        TitleComponent: CustomActionDialogTitle,
        titleComponentProps: {
          image,
          title: `Deactivate ${name} partner?`,
          fullScreen: false,
        },
        SubtitleComponent: DeactivateSubtitleComponent,
        subtitleComponentProps: { name },
        yesText: "Yes, deactivate",
        yesButtonProps: { color: "secondary", variant: "contained" },
      };

  const handleSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProcessing(true);
    const { id, slug } = processor_organization;
    updateOrganizationAffiliation({
      processor_organization__id: id,
      time_deleted,
    })
      .then(({ payload }) => {
        const { time_deleted: newTimeDeleted } = payload;
        enqueueSnackbar({
          variant: "success",
          message: newTimeDeleted
            ? "Partner successfully deactivated"
            : "Partner successfully reactivated",
        });
        updateOrganizationAgreementObjectTSQ({
          object: payload,
          processor_organization__slug: slug,
        });
        invalidateOrganizationsTSQ();
        invalidatePartnerOrganizationsTSQ();
        handleClose();
      })
      .catch(({ payload }) =>
        enqueueSnackbar({ variant: "error", message: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  return (
    <CustomActionDialog
      isOpen={isOpen}
      isProcessing={isProcessing}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isBodyEmpty={true}
      {...dialogProps}
    />
  );
}
