import React from "react";
import CustomBox from "@components/Custom/CustomBox";
import CustomButton from "@components/Custom/CustomButton";
import CustomCircularProgress from "@components/Custom/CustomCircularProgress";
import { CustomTypographyLink } from "@components/Custom/CustomLink";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import {
  ForgotPasswordHeaderMessage,
  ForgotPasswordEmailSent,
} from "@constants/static/auth";
import { LOGIN_HREF } from "@constants/static/routing";
import Stack from "@mui/material/Stack";
import { forgotPassword } from "@redux/actions/users/auth";

const initialFormData = "";
const initialError = "";

export default function ForgotPasswordForm() {
  const [isEmailSent, setIsEmailSent] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [email, setEmail] = React.useState(initialFormData);
  const [error, setError] = React.useState(initialError);

  const handleChange = (e) => setEmail(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setError(initialError);
    forgotPassword({ email })
      .then(() => {
        setIsProcessing(false);
        setIsEmailSent(true);
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        setError(payload);
      });
  };

  return (
    <Stack spacing={3}>
      <CustomBox
        bgcolor="white"
        px={5}
        py={6}
        borderRadius="20px"
        position="relative"
      >
        <CustomCircularProgress open={isProcessing} invisible={false} />
        {isEmailSent ? (
          <ForgotPasswordEmailSent email={email} />
        ) : (
          <Stack
            spacing={3}
            component="form"
            textAlign="start"
            onSubmit={handleSubmit}
          >
            <ForgotPasswordHeaderMessage />
            <CustomOutlinedTextField
              required
              fullWidth
              label="Email"
              name="email"
              autoComplete="email"
              type="email"
              autoFocus
              value={email}
              onChange={handleChange}
              InputLabelProps={{ shrink: true, required: false }}
              helperText={error}
              error={Boolean(error)}
            />
            <CustomButton
              fullWidth
              disabled={isProcessing}
              type="submit"
              variant="contained"
              color="primary"
              label="Send link to reset password"
            />
          </Stack>
        )}
      </CustomBox>
      {isEmailSent && (
        <CustomTypographyLink href={LOGIN_HREF} color="white" zIndex={2}>
          {"Login here"}
        </CustomTypographyLink>
      )}
    </Stack>
  );
}
