import React from "react";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import { getOrganizationBundlesHref } from "@constants/static/routing";
import { useQueryFetchOrganizationBundle } from "@redux/actions/organizations/organization-bundles";
import { Navigate, useParams } from "react-router-dom";
import OrganizationBundleData from "../OrganizationBundleData";
import OrganizationBundleHeader from "./OrganizationBundleHeader";

export default function OrganizationBundle({ bundle_type }) {
  const { organization__slug, organization_bundle__id } = useParams();
  const { isLoading, data } = useQueryFetchOrganizationBundle({
    bundle_type,
    organization_bundle__id,
  });

  const hasData = Boolean(data);

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  const { latest_version_id = null } = data || {};
  if (
    Boolean(latest_version_id) &&
    latest_version_id !== organization_bundle__id
  ) {
    const latestVersionHref = getOrganizationBundlesHref({
      bundle_type,
      organization__slug,
      organization_bundle__id: latest_version_id,
    });
    return <Navigate to={latestVersionHref} />;
  }

  return (
    <>
      <OrganizationBundleHeader object={data} bundle_type={bundle_type} />
      <CustomPage>
        <OrganizationBundleData object={data} />
      </CustomPage>
    </>
  );
}
