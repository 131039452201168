import React from "react";
import CustomAvatar from "@components/Custom/CustomAvatar";
import CustomPie from "@components/Custom/CustomPie";
import CustomTooltip from "@components/Custom/CustomTooltip";
import { getClaimSubmissionHref } from "@constants/static/routing";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { formatDateRange } from "@helpers/parse/time";
import Box from "@mui/material/Box";
import CardActionArea from "@mui/material/CardActionArea";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { clearUserClaimSubmissionNotifications } from "@redux/actions/claims/claim-submissions";
import { AVEY_YELLOW } from "@styles/theme";
import pluralize from "pluralize";
import { NavLink, useParams } from "react-router-dom";

export default function ClaimSubmissionObject({
  object,
  isSubmitterOrganization,
  organizationRole,
  colorMapper = {},
  textMapper = {},
}) {
  const { organization__slug } = useParams();
  const {
    id,
    name,
    time_locked,
    user_claim_notifications_count = 0,
    claims_summary = {
      num_claims: 0,
      progress: 0,
      claim_counts: {},
    },
    start_date,
    end_date,
    submitter_user,
    processor_organization,
  } = object;
  const { num_claims, progress, claim_counts } = claims_summary;
  const data = Object.entries(claim_counts).map(([key, value]) => ({
    value,
    color: colorMapper[key],
    label: textMapper[key],
  }));

  const isLocked = Boolean(time_locked);
  const className =
    "elevated-8 elevateOnHover " + (isLocked ? "opacity-50" : "");

  const numClaims = pluralize("claim", getIntOrFloat(num_claims), true);

  const href = getClaimSubmissionHref({
    organization__slug,
    claim_submission__id: id,
  });

  const dateRange = formatDateRange({ start_date, end_date });

  const handleClick = () =>
    user_claim_notifications_count > 0 &&
    clearUserClaimSubmissionNotifications({ id, organizationRole });

  return (
    <CardActionArea
      onClick={handleClick}
      component={NavLink}
      to={href}
      className={className}
      sx={{ height: "100%" }}
    >
      <Box flex={1} display="flex" flexDirection="column" height="100%">
        <Stack
          p={2}
          spacing={1}
          direction="row"
          alignItems="center"
          height="100%"
        >
          <Stack
            spacing={1.5}
            justifyContent="space-between"
            flex={1}
            height="100%"
          >
            <div>
              <Typography className="ellipsis-1" variant="body2" title={name}>
                {name}
              </Typography>
              <Typography variant="body1" fontWeight="bold" title={numClaims}>
                {numClaims}
              </Typography>
              <Typography
                variant="caption"
                fontWeight="light"
                className="ellipsis-1"
                title={dateRange}
              >
                {dateRange}
              </Typography>
            </div>

            <Stack spacing={1} direction="row" alignItems="center">
              <CustomTooltip
                title={
                  <>
                    {"Created by "}
                    <b>{submitter_user?.email}</b>
                    {!isSubmitterOrganization && (
                      <>
                        {" at "}
                        <b>{processor_organization?.name}</b>
                      </>
                    )}
                  </>
                }
              >
                <CustomAvatar src={submitter_user?.image} className="sizeXs" />
              </CustomTooltip>
              {!isSubmitterOrganization && (
                <CustomAvatar
                  src={processor_organization?.image}
                  className="sizeXs"
                  variant="square"
                />
              )}
            </Stack>
          </Stack>
          <Box display="grid" justifyContent="flex-end">
            <Stack alignItems="center" flex={1}>
              <CustomPie
                data={data}
                value={Math.floor(progress)}
                unit="%"
                caption={isLocked ? "Locked" : "Progress"}
              />
              {user_claim_notifications_count > 0 && (
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  bgcolor={AVEY_YELLOW}
                  p="2px 8px"
                  width="max-content"
                  borderRadius="4px"
                  mt="4px"
                >
                  {pluralize("change", user_claim_notifications_count, true)}
                </Typography>
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>
    </CardActionArea>
  );
}
