import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  getOrganizationPoliciesHref,
  getOrganizationSetupDefaultHref,
  INSURANCE_CONFIGURATIONS_BASE_HREFS,
  ORGANIZATION_POLICIES_TITLE,
} from "@constants/static/routing";
import { useQueryFetchOrganization } from "@redux/actions/organizations/organizations";
import { useParams } from "react-router-dom";
import OrganizationPolicyActivateForm from "../OrganizationPolicyForms/OrganizationPolicyActivateForm";
import OrganizationPolicyUpdateForm from "../OrganizationPolicyForms/OrganizationPolicyUpdateForm";
import OrganizationPolicyVersionHistory from "./OrganizationPolicyVersionHistory";

export function OrganizationPolicyHeaderTitle({ object, variant = "h3" }) {
  const { version, name, number, time_deleted } = object;

  return (
    <CustomHeaderTitle
      title={number}
      subtitle={name}
      variant={variant}
      version={version}
      time_deleted={time_deleted}
    />
  );
}

export default function OrganizationPolicyHeader({ object }) {
  const { organization__slug, organization_policy__id } = useParams();
  const { data: organization } = useQueryFetchOrganization({
    organization__slug,
  });
  const { number, name, time_deleted } = object || {};
  const isDeleted = Boolean(time_deleted);
  const title = number || name;

  const hrefs = [
    ...INSURANCE_CONFIGURATIONS_BASE_HREFS,
    {
      label: organization?.name || organization__slug,
      href: getOrganizationSetupDefaultHref({ organization__slug }),
    },
    {
      label: ORGANIZATION_POLICIES_TITLE,
      href: getOrganizationPoliciesHref({ organization__slug }),
    },
    {
      label: title,
      href: getOrganizationPoliciesHref({
        organization__slug,
        organization_policy__id,
      }),
    },
  ];
  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={OrganizationPolicyHeaderTitle}
      titleComponentProps={{ object }}
    >
      {!isDeleted && <OrganizationPolicyUpdateForm object={object} />}
      <CustomMoreActions>
        <OrganizationPolicyVersionHistory />
        <OrganizationPolicyActivateForm object={object} />
      </CustomMoreActions>
    </CustomHeader>
  );
}
