import React from "react";
import CustomImageDropzone from "@components/Custom/CustomImageDropzone";
import CustomBox from "@components/Custom/CustomBox";
import { useDispatch } from "react-redux";
import { updateUser } from "@redux/actions/users/auth";
import AccountActionDialogTitle from "./AccountActionDialogTitle";

export default function AccountAvatar({ user }) {
  const dispatch = useDispatch();
  const { id, image } = user;

  const submitFn = (newImage) =>
    dispatch(updateUser({ id, image: newImage || "" }));

  return (
    <CustomBox display="grid" justifyContent="center">
      <CustomImageDropzone
        image={image}
        submitFn={submitFn}
        TitleComponent={AccountActionDialogTitle}
        titleComponentProps={{ user }}
      />
    </CustomBox>
  );
}
