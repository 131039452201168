import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import MenuItem from "@mui/material/MenuItem";
import { enqueueSnackbar } from "notistack";
import { capitalizeFirstLetter } from "@helpers/parse/string";

export default function CustomDeactivateObject({
  object,
  label = "object",
  deleteFn = () => {},
  handleDeleteFn = () => {},
  TitleComponent = undefined,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { id, time_deleted } = object;
  const isDeleted = Boolean(time_deleted);
  const menuItemText = isDeleted
    ? `Reactivate ${label}`
    : `Deactivate ${label}`;
  const title = `${menuItemText}?`;
  const capitalizedLabel = capitalizeFirstLetter(label);
  const dialogProps = isDeleted
    ? {
        subtitleComponentProps: {
          title: `Are you sure you want to reactivate this ${label}?`,
        },
        noText: "No, keep it",
        yesText: "Yes, reactivate",
        yesButtonProps: { color: "success", variant: "contained" },
      }
    : {
        subtitleComponentProps: {
          title: (
            <>
              {`Are you sure you want to deactivate this ${label}?`}
              <br />
              {"You can always reactivate it later."}
            </>
          ),
        },
        noText: "No, keep it",
        yesText: "Yes, deactivate",
        yesButtonProps: { color: "secondary", variant: "contained" },
      };

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProcessing(true);
    deleteFn({ id, time_deleted })
      .then(({ payload }) => {
        const { time_deleted: newTimeDeleted } = payload;
        setIsOpen(false);
        enqueueSnackbar({
          variant: "success",
          message: newTimeDeleted
            ? `${capitalizedLabel} successfully deactivated`
            : `${capitalizedLabel} successfully reactivated`,
        });
        handleDeleteFn({ payload });
      })
      .catch(({ payload }) =>
        enqueueSnackbar({ variant: "error", message: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  return (
    <>
      <MenuItem onClick={handleIsOpen}>{menuItemText}</MenuItem>
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        isBodyEmpty={true}
        TitleComponent={TitleComponent}
        titleComponentProps={{ title }}
        {...dialogProps}
      />
    </>
  );
}
