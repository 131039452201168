import React from "react";
import CustomBox from "@components/Custom/CustomBox";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function CustomStatusTag({
  status,
  textMapper = {},
  propsMapper = {},
  suffixIcon = null,
}) {
  const {
    icon = null,
    typographyProps = {},
    outerWrapperProps = {},
    innerWrapperProps = {},
  } = propsMapper[status] || {};
  const text = textMapper[status];

  return (
    <CustomBox
      minWidth="min-content"
      maxWidth="max-content"
      minHeight={30}
      display="grid"
      {...outerWrapperProps}
    >
      <CustomBox display="grid" alignItems="center" {...innerWrapperProps}>
        {text ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            {icon}
            <Typography
              variant="body2"
              className="ellipsis-1"
              {...typographyProps}
            >
              {text}
            </Typography>
            {suffixIcon}
          </Stack>
        ) : (
          <Skeleton
            title="Unknown"
            variant="rectangle"
            style={{ height: 32, borderRadius: 4 }}
          />
        )}
      </CustomBox>
    </CustomBox>
  );
}
