import React from "react";
import { useQueryFetchMedications } from "@redux/actions/avey/medications";
import {
  selectorAuthCurrentUserOrganizationNdcTagCodes,
  selectorAuthCurrentUserOrganizationSlug,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import {
  getMedicationOptionLabel,
  isMedicationOptionEqualToValue,
  renderMedicationOption,
} from "./MedicationAutocompleteComponents";

export default function MedicationAutocomplete({
  label = "Medication",
  error = false,
  value,
  handleChange = () => {},
  wrapperProps = {},
  textFieldProps = {},
  autocompleteProps = {},
}) {
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const ndc_tag_codes = useSelector(
    selectorAuthCurrentUserOrganizationNdcTagCodes
  );
  const useQueryFnProps = {
    filters: {
      time_deleted__isnull: true,
      newer_version__isnull: true,
      submitter_organization__slug,
      ndc_tag_codes,
    },
    useInfiniteWrapper: true,
  };

  return (
    <CustomInfiniteAutocomplete
      label={label}
      error={error}
      wrapperProps={wrapperProps}
      textFieldProps={{ required: true, ...textFieldProps }}
      autocompleteProps={{
        disableClearable: true,
        renderOption: (props, option) => renderMedicationOption(props, option),
        ...autocompleteProps,
      }}
      useQueryFn={useQueryFetchMedications}
      useQueryFnProps={useQueryFnProps}
      getOptionLabel={getMedicationOptionLabel}
      isOptionEqualToValue={isMedicationOptionEqualToValue}
      value={value}
      handleChange={handleChange}
    />
  );
}
