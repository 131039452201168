import React from "react";
import DrugNameAutocomplete from "@components/Custom/CustomAutocomplete/DrugNameAutocomplete";
import UnitAutocomplete from "@components/Custom/CustomAutocomplete/UnitAutocomplete";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import {
  DRUG_NAME_TYPE_GENERIC,
  DRUG_NAME_TYPE_TRADE,
} from "@constants/avey/drug-names";
import {
  UNIT_TYPE_CONCENTRATION,
  UNIT_TYPE_DOSAGE_FORM,
  UNIT_TYPE_PACKAGE_SIZE,
  UNIT_TYPE_PACKAGE_TYPE,
} from "@constants/avey/units";
import Grid from "@mui/material/Grid";

export default function MedicationDetailsFormData({
  formData = {},
  setFormData = () => {},
}) {
  const {
    trade_name,
    generic_name,
    concentration,
    concentration_unit,
    dosage_form,
    package_size,
    package_size_unit,
    package_type,
  } = formData;

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeField = ({ fieldname, newValue }) =>
    setFormData((prev) => ({ ...prev, [fieldname]: newValue }));

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <DrugNameAutocomplete
            label="Generic name"
            value={generic_name}
            handleChange={(newValue) =>
              handleChangeField({ fieldname: "generic_name", newValue })
            }
            placeholder="e.g. Paracetamol"
            drug_name_type={DRUG_NAME_TYPE_GENERIC}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DrugNameAutocomplete
            label="Trade name"
            value={trade_name}
            handleChange={(newValue) =>
              handleChangeField({ fieldname: "trade_name", newValue })
            }
            placeholder="e.g. Biogesic"
            drug_name_type={DRUG_NAME_TYPE_TRADE}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomOutlinedTextField
            required
            label="Concentration"
            name="concentration"
            value={concentration}
            onChange={handleChange}
            placeholder="e.g. 500"
            wrapperProps={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <UnitAutocomplete
            label="Concentration unit"
            value={concentration_unit}
            handleChange={(newValue) =>
              handleChangeField({ fieldname: "concentration_unit", newValue })
            }
            textFieldProps={{ placeholder: "e.g. mg" }}
            unit_type={UNIT_TYPE_CONCENTRATION}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomOutlinedTextField
            required
            label="Package size"
            name="package_size"
            value={package_size}
            onChange={handleChange}
            placeholder="e.g. 10"
            wrapperProps={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <UnitAutocomplete
            label="Package size unit"
            value={package_size_unit}
            handleChange={(newValue) =>
              handleChangeField({ fieldname: "package_size_unit", newValue })
            }
            textFieldProps={{ placeholder: "e.g. pcs" }}
            unit_type={UNIT_TYPE_PACKAGE_SIZE}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UnitAutocomplete
            label="Package type"
            value={package_type}
            handleChange={(newValue) =>
              handleChangeField({ fieldname: "package_type", newValue })
            }
            textFieldProps={{ placeholder: "e.g. Blister pack" }}
            unit_type={UNIT_TYPE_PACKAGE_TYPE}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UnitAutocomplete
            label="Dosage form"
            value={dosage_form}
            handleChange={(newValue) =>
              handleChangeField({ fieldname: "dosage_form", newValue })
            }
            textFieldProps={{ placeholder: "e.g. Tablet" }}
            unit_type={UNIT_TYPE_DOSAGE_FORM}
          />
        </Grid>
      </Grid>
    </div>
  );
}
