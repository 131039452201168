import React from "react";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import { getOrganizationPatientsHref } from "@constants/static/routing";
import { useQueryFetchOrganizationPatient } from "@redux/actions/organizations/organization-patients";
import { useQueryFetchOrganization } from "@redux/actions/organizations/organizations";
import { Navigate, useParams } from "react-router-dom";
import OrganizationPatientData from "../OrganizationPatientData";
import OrganizationPatientHeader from "./OrganizationPatientHeader";

export default function OrganizationPatient() {
  const { organization__slug, organization_patient__id } = useParams();
  const { isLoading: isLoadingPatient, data } =
    useQueryFetchOrganizationPatient({
      organization_patient__id,
    });
  const { isLoading: isLoadingOrganization, data: organization } =
    useQueryFetchOrganization({
      organization__slug,
    });

  const isLoading = isLoadingPatient || isLoadingOrganization;
  const hasData = Boolean(data) && Boolean(organization);

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  const { latest_version_id = null } = data || {};
  if (
    Boolean(latest_version_id) &&
    latest_version_id !== organization_patient__id
  ) {
    const latestVersionHref = getOrganizationPatientsHref({
      organization__slug,
      organization_patient__id: latest_version_id,
    });
    return <Navigate to={latestVersionHref} />;
  }

  return (
    <>
      <OrganizationPatientHeader object={data} />
      <CustomPage>
        <OrganizationPatientData object={data} />
      </CustomPage>
    </>
  );
}
