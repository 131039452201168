import React from "react";
import CustomSection, {
  customSectionBodyPadding,
} from "@components/Custom/CustomSection";
import CustomSkeleton from "@components/Custom/CustomSkeleton";
import CustomTimeElapsed from "@components/Custom/CustomTimeElapsed";
import CustomTooltip from "@components/Custom/CustomTooltip";
import {
  MICROSOFT_RECOGNITION_LANGUAGE,
  MICROSOFT_SPEECH_KEY,
  MICROSOFT_SPEECH_REGION,
} from "@constants/static/globals";
import { mergeLists } from "@helpers/parse/list";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { convertTranscriptionToSymptoms } from "@redux/actions/server/server";
import { AVEY_BLUE, AVEY_RED } from "@styles/theme";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import { TypeAnimation } from "react-type-animation";
import {
  CLAIM_DEMO_LIVE_TRANSCRIPTION_TITLE,
  selectorClaimDemoHasOrganizationPatient,
  selectorClaimDemoIsSessionDone,
  useClaimDemoContext,
} from "../context";

function ClaimDemoAudioActions({
  startTime = null,
  endTime = null,
  startRecognition = () => {},
  stopRecognition = () => {},
  endSession = () => {},
}) {
  const isRecording = Boolean(startTime) && !Boolean(endTime);
  const title = isRecording ? "Pause" : "Record";
  const handleClick = isRecording ? stopRecognition : startRecognition;
  const customSkeletonProps = isRecording ? {} : { animation: false };

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <CustomTimeElapsed startTime={startTime} endTime={endTime} />
      <Stack direction="row" alignItems="center">
        <CustomTooltip title={title}>
          <IconButton onClick={handleClick}>
            <CustomSkeleton
              backgroundColor={AVEY_BLUE}
              variant="circular"
              {...customSkeletonProps}
            />
          </IconButton>
        </CustomTooltip>
        <CustomTooltip title="End">
          <IconButton onClick={endSession}>
            <CustomSkeleton
              backgroundColor={AVEY_RED}
              variant="circular"
              animation={false}
            />
          </IconButton>
        </CustomTooltip>
      </Stack>
    </Stack>
  );
}

export default function ClaimDemoAudio() {
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [chunks, setChunks] = React.useState([]);
  const recognizerRef = React.useRef(null);
  const bodyRef = React.useRef();

  const { state, handleChangeEndSession, handleUpdateClaimSymptoms } =
    useClaimDemoContext();

  const hasOrganizationPatient = selectorClaimDemoHasOrganizationPatient(state);
  const isSessionDone = selectorClaimDemoIsSessionDone(state);

  const isActive = hasOrganizationPatient && !isSessionDone;

  const startRecognition = () => {
    recognizerRef?.current?.startContinuousRecognitionAsync();
    setStartTime(new Date());
    setEndTime(null);
  };

  const stopRecognition = () => {
    recognizerRef?.current?.stopContinuousRecognitionAsync();
    setEndTime(new Date());
  };

  const endSession = () => {
    stopRecognition();
    handleChangeEndSession();
  };

  React.useEffect(() => {
    const setupRecognizer = () => {
      const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
        MICROSOFT_SPEECH_KEY,
        MICROSOFT_SPEECH_REGION
      );
      speechConfig.speechRecognitionLanguage = MICROSOFT_RECOGNITION_LANGUAGE;
      speechConfig.setProfanity(SpeechSDK.ProfanityOption.Raw);
      const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
      recognizerRef.current = new SpeechSDK.SpeechRecognizer(
        speechConfig,
        audioConfig
      );

      // recognizerRef.current.recognizing = (s, e) => {
      //    const { text } = e.result;
      //    Boolean(text) && setChunks((prevChunks) => [...prevChunks, text]);
      // };

      recognizerRef.current.recognized = (s, e) => {
        const { text } = e.result;
        Boolean(text) && setChunks((prevChunks) => [...prevChunks, text]);
      };

      recognizerRef.current.canceled = (s, e) => {
        console.log(`CANCELED: Reason=${e.reason}`);
        setEndTime(new Date());
      };

      recognizerRef.current.sessionStopped = (s, e) => {
        recognizerRef.current.stopContinuousRecognitionAsync();
        setEndTime(new Date());
      };
    };

    setupRecognizer();

    return () => recognizerRef?.current?.close();
  }, [isActive]);

  React.useEffect(() => {
    const processChunks = async () => {
      if (chunks.length > 0) {
        const lastChunk = chunks[chunks.length - 1];
        const last2Chunks = chunks.length > 1 ? chunks.slice(-2).join(" ") : "";

        const symptoms1Promise = convertTranscriptionToSymptoms(lastChunk);
        const symptoms2Promise = convertTranscriptionToSymptoms(last2Chunks);

        try {
          const symptoms1 = await symptoms1Promise;
          try {
            const symptoms2 = await symptoms2Promise;
            const symptoms = mergeLists(symptoms1, symptoms2);
            handleUpdateClaimSymptoms(symptoms);
          } catch (error) {
            console.error(
              "Error processing symptoms from last two chunks:",
              error
            );
            handleUpdateClaimSymptoms(symptoms1);
          }
        } catch (error) {
          console.error("Error processing symptoms from last chunk:", error);
        }
      }
    };

    processChunks();
  }, [chunks, handleUpdateClaimSymptoms]);

  React.useEffect(() => {
    if (isActive) {
      setStartTime(null);
      setEndTime(null);
      setChunks([]);
    }
  }, [isActive]);

  React.useEffect(() => {
    if (bodyRef.current) {
      const scrollElement = bodyRef.current;
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  }, [chunks]);

  return (
    <CustomSection
      title={CLAIM_DEMO_LIVE_TRANSCRIPTION_TITLE}
      isOpenDefault={hasOrganizationPatient}
      isCollapsable={false}
      action={
        isActive && (
          <ClaimDemoAudioActions
            startTime={startTime}
            endTime={endTime}
            startRecognition={startRecognition}
            stopRecognition={stopRecognition}
            endSession={endSession}
          />
        )
      }
      bodyProps={{ p: 0 }}
    >
      <Stack
        spacing={2}
        ref={bodyRef}
        p={customSectionBodyPadding}
        style={{ height: 100, overflowY: "auto", scrollBehavior: "smooth" }}
      >
        {chunks.map((chunk, index) => (
          <div key={index}>
            <Typography variant="body2" minHeight={20}>
              <TypeAnimation
                key={index}
                sequence={[chunk]}
                wrapper="span"
                speed={50}
                repeat={false}
                cursor={false}
              />
            </Typography>
          </div>
        ))}
      </Stack>
    </CustomSection>
  );
}
