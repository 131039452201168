import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ClaimDemoSymptomsAddSymptom from "./ClaimDemoSymptomsAddSymptom";
import ClaimDemoSymptomsList from "./ClaimDemoSymptomsList";

export default function ClaimDemoSymptomsListType({
  title,
  symptoms = [],
  type,
  addLabel,
  emptyLabel,
}) {
  return (
    <Stack spacing={2} height="100%">
      <Typography fontWeight="bold">{title}</Typography>
      <ClaimDemoSymptomsList
        type={type}
        symptoms={symptoms}
        emptyLabel={emptyLabel}
      />
      <ClaimDemoSymptomsAddSymptom type={type} label={addLabel} />
    </Stack>
  );
}
