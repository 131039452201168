import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  CPTS_TITLE,
  getCptsHref,
  HEALTHCARE_CONFIGURATIONS_BASE_HREFS,
} from "@constants/static/routing";
import { useParams } from "react-router-dom";
import CptVersionHistory from "./CptVersionHistory";

export function CptHeaderTitle({ object, variant = "h3" }) {
  const { version, code, time_deleted } = object;

  return (
    <CustomHeaderTitle
      title={code}
      variant={variant}
      version={version}
      time_deleted={time_deleted}
    />
  );
}

export default function CptHeader({ object }) {
  const { cpt__id } = useParams();
  const title = object?.code || cpt__id;

  const hrefs = [
    ...HEALTHCARE_CONFIGURATIONS_BASE_HREFS,
    {
      label: CPTS_TITLE,
      href: getCptsHref({}),
    },
    {
      label: title,
      href: getCptsHref({ cpt__id }),
    },
  ];

  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={CptHeaderTitle}
      titleComponentProps={{ object }}
    >
      <CustomMoreActions>
        <CptVersionHistory />
      </CustomMoreActions>
    </CustomHeader>
  );
}
