import React from "react";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import { SITE_NAME } from "@constants/static/globals";
import { useQueryFetchCurrentOrganizationPaymentCycle } from "@redux/actions/payments/organization-payment-cycles";
import BillingManagementBody from "./BillingManagementBody";
import BillingManagementHeader from "./BillingManagementHeader";

export default function BillingManagement() {
  const { isLoading, data } = useQueryFetchCurrentOrganizationPaymentCycle({});
  const hasData = Boolean(data);

  if (isLoading) return <CustomBackdrop />;

  if (!hasData)
    return <Custom404 title={`Please contact ${SITE_NAME} customer service`} />;

  return (
    <>
      <BillingManagementHeader />
      <CustomPage>
        <BillingManagementBody object={data} />
      </CustomPage>
    </>
  );
}
