import { PayloadError, ServerApi } from "@helpers/api";

export async function fetchNextQuestionWithAttribute(body) {
  try {
    const response = await ServerApi().post(
      "/api/assessment/question-with-attribute",
      body
    );
    return response.data;
  } catch (error) {
    return {};
  }
}

export async function fetchNextQuestionWithoutAttribute(body) {
  try {
    const response = await ServerApi().post(
      "/api/assessment/question-without-attribute",
      body
    );
    return response.data;
  } catch (error) {
    return {};
  }
}

export async function fetchDiagnosis(body) {
  try {
    const response = await ServerApi().post("/api/assessment/diagnosis", body);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({ payload: error });
  }
}

export async function fetchSubjectiveSummary(body) {
  try {
    const response = await ServerApi().post(
      "/api/summarize/subjective-summary",
      body
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({ payload: error });
  }
}

export async function fetchObjectiveSummary(body) {
  try {
    const response = await ServerApi().post(
      "/api/summarize/objective-summary",
      body
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({ payload: error });
  }
}

export async function fetchPlanSummary(body) {
  try {
    const response = await ServerApi().post(
      "/api/summarize/plan-summary",
      body
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({ payload: error });
  }
}

export async function fetchIcdCodeConceptFinding(body) {
  try {
    const response = await ServerApi().post(
      "/api/map/icd-code/concept-finding",
      body
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({ payload: error });
  }
}

export async function fetchDiseaseInstanceIcdCode(body) {
  try {
    const response = await ServerApi().post(
      "/api/map/icd-code/disease-instance",
      body
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({ payload: error });
  }
}

export async function convertAudioFragmentToSymptoms(audiofile) {
  try {
    const formData = new FormData();
    formData.append("audiofile", audiofile);
    const response = await ServerApi({
      headers: { "Content-Type": "multipart/form-data" },
    }).post("/api/notify/audio-fragment-to-symptoms", formData);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({ payload: error });
  }
}

export async function convertTranscriptionToSymptoms(transcription) {
  try {
    console.log(transcription);
    if (!Boolean(transcription)) return [];
    const response = await ServerApi().post(
      "/api/notify/transcription-to-symptoms",
      { transcription }
    );
    return response.data;
  } catch (error) {
    throw new PayloadError({ payload: error });
  }
}
