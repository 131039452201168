import React from "react";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import CustomPasswordTextField from "@components/Custom/CustomPasswordTextField";
import Stack from "@mui/material/Stack";

export default function LoginFormData({
  error,
  formData,
  setFormData = () => {},
}) {
  const { email, password } = formData;

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <Stack spacing={3} textAlign="start">
      <CustomOutlinedTextField
        required
        fullWidth
        label="Email"
        name="email"
        autoComplete="email"
        type="email"
        autoFocus
        value={email}
        onChange={handleChange}
        InputLabelProps={{ shrink: true, required: false }}
        helperText={error}
        error={Boolean(error)}
      />
      <CustomPasswordTextField
        required
        fullWidth
        autoComplete="current-password"
        variant="standard"
        label="Password"
        name="password"
        onChange={handleChange}
        value={password}
        InputLabelProps={{ shrink: true, required: false }}
      />
    </Stack>
  );
}
