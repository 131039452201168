import React from "react";
import CustomAutocomplete from ".";
import CustomInfiniteAutocompleteScroll from "./CustomInfiniteAutocompleteScroll";

export default function CustomInfiniteAutocomplete({
  placeholder = "Search...",
  value = null,
  handleChange = () => {},
  getOptionLabel = (option) => option?.name,
  isOptionEqualToValue = () => false,
  useQueryFn = () => {},
  useQueryFnProps = { filters: {} },
  autocompleteProps = {},
  textFieldProps = {},
  withSearchIcon = true,
  label = "",
  error = false,
  helperText = "",
  wrapperProps = {},
}) {
  const [searchQuery, setSearchQuery] = React.useState("");

  const {
    data = {},
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
    fetchNextPage,
  } = useQueryFn({ searchQuery, ...useQueryFnProps });

  const { pages = [] } = data;
  const results = pages
    ?.map((page) => page?.results || [])
    .reduce((a, b) => a.concat(b), []);

  return (
    <CustomAutocomplete
      label={label}
      error={error}
      helperText={helperText}
      wrapperProps={wrapperProps}
      placeholder={placeholder}
      options={results}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      loading={isFetching}
      value={value}
      handleChange={handleChange}
      setSearchQuery={setSearchQuery}
      textFieldProps={textFieldProps}
      withSearchIcon={withSearchIcon}
      ListboxComponent={CustomInfiniteAutocompleteScroll}
      ListboxProps={{
        hasNextPage,
        isFetching: (isFetching || isLoading) && !isFetchingNextPage,
        isFetchingNextPage,
        fetchNextPage,
      }}
      {...autocompleteProps}
    />
  );
}
