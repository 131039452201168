import React from "react";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomFilters from "@components/Custom/CustomFilters";
import Grid from "@mui/material/Grid";
import { useQueryFetchOrganizationPatients } from "@redux/actions/organizations/organization-patients";
import { selectorAuthCurrentUserOrganizationSlug } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import OrganizationPatientsCreateForms from "./OrganizationPatientsCreateForms";
import OrganizationPatientsExports from "./OrganizationPatientsExports";
import {
  filterOptions,
  formatFilters,
  initialFilterState,
} from "./OrganizationPatientsFilters";
import OrganizationPatientsGridProps from "./OrganizationPatientsGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function OrganizationPatientsGrid() {
  const { organization__slug: processor_organization__slug } = useParams();
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const useQueryFnProps = {
    searchQuery,
    filters: {
      newer_version__isnull: true,
      submitter_organization__slug,
      processor_organization__slug,
      ...filters,
    },
  };
  const dataGridProps = React.useMemo(
    () => OrganizationPatientsGridProps({ processor_organization__slug }),
    [processor_organization__slug]
  );

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
      <Grid item flexGrow={1}>
        <CustomContextSearchField setSearchQuery={setSearchQuery} />
      </Grid>
      <Grid item>
        <CustomFilters
          initialFilterState={initialFilterState}
          filterOptions={filterOptions}
          formatFilters={formatFilters}
          setFilters={setFilters}
        />
      </Grid>
      <Grid item>
        <OrganizationPatientsCreateForms />
      </Grid>
      <Grid item>
        <OrganizationPatientsExports useQueryFnProps={useQueryFnProps} />
      </Grid>
      <Grid item xs={12}>
        <CustomInfiniteDataGrid
          useQueryFn={useQueryFetchOrganizationPatients}
          useQueryFnProps={useQueryFnProps}
          dataGridProps={dataGridProps}
          initialSortModel={initialSortModel}
        />
      </Grid>
    </Grid>
  );
}
