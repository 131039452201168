import React from "react";
import CustomSyncButton from "@components/Custom/CustomSyncButton";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useClaimDemoContext } from "../context";
import ClaimDemoClinicianProcedures from "./ClaimDemoClinicianProcedures";
import ClaimDemoRecommendedProcedures from "./ClaimDemoRecommendedProcedures";

export default function ClaimDemoProcedures() {
  const isDownMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { handleSyncClaimProcedures } = useClaimDemoContext();

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12} md={5.5}>
        <ClaimDemoClinicianProcedures />
      </Grid>
      <Grid item xs={12} md={1}>
        {isDownMd ? (
          <Divider>
            <CustomSyncButton
              style={{ transform: "rotate(90deg)" }}
              onClick={handleSyncClaimProcedures}
            />
          </Divider>
        ) : (
          <div
            style={{
              height: "100%",
              display: "grid",
              justifyContent: "center",
            }}
          >
            <Divider orientation="vertical">
              <CustomSyncButton onClick={handleSyncClaimProcedures} />
            </Divider>
          </div>
        )}
      </Grid>
      <Grid item xs={12} md={5.5}>
        <ClaimDemoRecommendedProcedures />
      </Grid>
    </Grid>
  );
}
