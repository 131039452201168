import React from "react";
import CustomSection, {
  customSectionBodyPadding,
} from "@components/Custom/CustomSection";
import { CLAIM_SECTION_ORGANIZATION_PATIENT_TITLE } from "@constants/claims/claims";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import OrganizationPatientDetailsFormData from "./OrganizationPatientDetailsFormData";
import OrganizationPatientIdentitiesFormData from "./OrganizationPatientIdentitiesFormData";
import OrganizationPatientPolicyDetailsFormData from "./OrganizationPatientPolicyDetailsFormData";

export default function OrganizationPatientFormData({
  formData = {},
  setFormData = () => {},
}) {
  const { organization_patient_identities = [], ...otherFormData } =
    formData || {};
  const handleChangeOrganizationPatientIdentities = (payload) =>
    setFormData((prev) => ({
      ...prev,
      organization_patient_identities: payload,
    }));

  return (
    <Stack spacing={3}>
      <CustomSection
        title={CLAIM_SECTION_ORGANIZATION_PATIENT_TITLE}
        isCollapsable={false}
        bodyProps={{ p: 0 }}
      >
        <Box p={customSectionBodyPadding}>
          <OrganizationPatientDetailsFormData
            formData={otherFormData}
            setFormData={setFormData}
          />
        </Box>
        <Divider />
        <OrganizationPatientIdentitiesFormData
          formData={organization_patient_identities}
          handleChange={handleChangeOrganizationPatientIdentities}
        />
      </CustomSection>
      <OrganizationPatientPolicyDetailsFormData
        formData={otherFormData}
        setFormData={setFormData}
      />
    </Stack>
  );
}
