import {
  ORGANIZATION_BUNDLE_TYPE_MEDICATIONS,
  ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES,
} from "@constants/organizations/organization-bundles";
import OrganizationBundleMedicationSummary from "./OrganizationBundleMedicationSummary";
import OrganizationBundleProceduresAndConsumablesSummary from "./OrganizationBundleProceduresAndConsumablesSummary";

export default function OrganizationBundleRelatedObjectsSummary({
  object = {},
}) {
  const {
    bundle_type,
    organization_bundle_cpts = [],
    organization_bundle_consumables = [],
    organization_bundle_medication = {},
  } = object;

  switch (bundle_type) {
    case ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES:
      return OrganizationBundleProceduresAndConsumablesSummary({
        organization_bundle_cpts,
        organization_bundle_consumables,
      });

    case ORGANIZATION_BUNDLE_TYPE_MEDICATIONS:
      return OrganizationBundleMedicationSummary({
        organization_bundle_medication,
      });

    default:
      return null;
  }
}
