import React from "react";
import {
  getConcentrationUnitStr,
  getDosageFormUnitStr,
  getDrugName,
  getPackageSizeUnitStr,
} from "@constants/avey/medications";
import Typography from "@mui/material/Typography";

export const isMedicationOptionEqualToValue = (option, value) =>
  option?.id === value?.id;

export const getMedicationOptionLabel = (
  object,
  onlyText = true,
  withCode = true
) => {
  if (!Boolean(object)) return "";
  const {
    code,
    trade_name = null,
    generic_name = null,
    concentration = "",
    concentration_unit = null,
    dosage_form = null,
    package_size = "",
    package_size_unit = null,
    package_type = null,
  } = object;

  const suffix = [
    getDrugName({ drug_name: generic_name }),
    getDrugName({ drug_name: trade_name }),
    getConcentrationUnitStr({
      concentration,
      concentration_unit,
    }),
    getPackageSizeUnitStr({
      package_size,
      package_size_unit,
      package_type,
    }),
    getDosageFormUnitStr({ dosage_form }),
  ]
    .filter(Boolean)
    .join(", ");

  if (onlyText) return withCode ? `${code || ""} ${suffix}`.trim() : suffix;

  return (
    <>
      {withCode && <b>{code || ""}</b>} {`${suffix}`}
    </>
  );
};

export const renderMedicationOption = (props, option) => {
  const { code } = option;
  return (
    <div {...props}>
      <div>
        <Typography variant="body2" fontWeight="bold">
          {code}
        </Typography>
        <Typography variant="body2" fontWeight="light">
          {getMedicationOptionLabel(option, false, false)}
        </Typography>
      </div>
    </div>
  );
};
