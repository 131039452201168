import React from "react";
import CustomBox from "@components/Custom/CustomBox";
import CustomInfiniteGrid from "@components/Custom/CustomDataGrid/CustomInfiniteGrid";
import Drawer from "@mui/material/Drawer";
import { AVEY_WHITE } from "@styles/theme";
import CustomBackdrop from "../CustomBackdrop";
import CustomTitleComponent from "../CustomLayout/CustomTitleComponent";
import CustomVersionHistoryObject from "./CustomVersionHistoryObject";

const width = 300;

export default function CustomVersionHistoryDrawer({
  TitleComponent = undefined,
  titleComponentProps = {},
  version = null,
  handleChangeVersion = () => {},
  infiniteQuery = {},
  VersionHistoryObjectTabComponent = () => "",
  objectComponentProps = {},
}) {
  return (
    <Drawer
      variant="permanent"
      open={true}
      style={{ width }}
      PaperProps={{ style: { width } }}
    >
      <CustomBox
        style={{ backgroundColor: AVEY_WHITE }}
        className="elevated-8"
        position="sticky"
        top={0}
        zIndex={100}
        p="24px 16px"
      >
        <CustomTitleComponent
          TitleComponent={TitleComponent}
          titleComponentProps={{
            title: "Version history",
            variant: "h5",
            fontWeight: "bold",
            ...titleComponentProps,
          }}
        />
      </CustomBox>
      <CustomInfiniteGrid
        infiniteQuery={infiniteQuery}
        ObjectComponent={CustomVersionHistoryObject}
        objectComponentProps={{
          selectedVersion: version,
          handleChangeVersion,
          VersionHistoryObjectTabComponent,
          ...objectComponentProps,
        }}
        noResultsFoundProps={{ wrapperProps: { p: "24px 16px" } }}
        wrapperProps={{ mb: 2 }}
        customLoadingIndicator={<CustomBackdrop />}
      />
    </Drawer>
  );
}
