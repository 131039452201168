import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import { ICD_TYPE_10_CM } from "@constants/avey/icds";
import {
  CLAIM_SECTION_SYMPTOMS,
  emptyClaimIcdFormData,
  getIcdTtitle,
} from "@constants/claims/claims";
import { v4 as uuidv4 } from "uuid";
import ClaimIcdFormData from "./ClaimIcdFormData";

export default function ClaimIcdCreateForm({
  index = 0,
  type = CLAIM_SECTION_SYMPTOMS,
  handleAddClaimIcd = () => {},
  icd_type = ICD_TYPE_10_CM,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [formData, setFormData] = React.useState(emptyClaimIcdFormData);
  const { capitalized, lowercased } = getIcdTtitle({ index, type });

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    handleAddClaimIcd({ payload: { ...formData, id: uuidv4() } });
    setIsOpen(false);
  };

  React.useEffect(() => {
    isOpen && setFormData(emptyClaimIcdFormData);
  }, [isOpen]);

  return (
    <>
      <CustomTypographyButton
        value={`+ Add ${lowercased}`}
        onClick={handleIsOpen}
      />
      <CustomActionDialog
        isOpen={isOpen}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        titleComponentProps={{ title: `Add ${lowercased}` }}
      >
        <ClaimIcdFormData
          label={capitalized}
          formData={formData}
          setFormData={setFormData}
          icd_type={icd_type}
        />
      </CustomActionDialog>
    </>
  );
}
