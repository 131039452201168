import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { AVEY_GREY_DARK } from "@styles/theme";

export default function CustomSkeleton({
  variant = "text",
  textVariant = "body2",
  backgroundColor = AVEY_GREY_DARK,
  ...props
}) {
  const { style, ...otherProps } = props;
  switch (variant) {
    case "text":
      return (
        <Skeleton
          variant={variant}
          width="100%"
          style={{ backgroundColor, ...style }}
          {...otherProps}
        >
          <Typography variant={textVariant}>{"."}</Typography>
        </Skeleton>
      );

    case "circular":
      return (
        <div>
          <Skeleton
            variant="circular"
            style={{ backgroundColor, width: 10, height: 10, ...style }}
            {...otherProps}
          />
        </div>
      );

    default:
      return <Skeleton style={{ backgroundColor, ...style }} {...otherProps} />;
  }
}
