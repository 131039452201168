import React from "react";
import YourOrganization from "@components/Authenticated/Organizations/YourOrganization";
import { Navigate, Route, Routes } from "react-router-dom";

export default function YourOrganizationRoutes() {
  return (
    <Routes>
      <Route path="" Component={YourOrganization} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
