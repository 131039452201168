import React from "react";
import { CustomExternalLink } from "@components/Custom/CustomLink";
import CustomStatusTag from "@components/Custom/CustomStatusTag";
import {
  EXPORT_STATUS_PROPS_MAPPER,
  EXPORT_STATUS_TEXT_MAPPER,
} from "@constants/static/export";
import {
  MODEL_TYPE_CLAIM,
  MODEL_TYPE_CONSUMABLE,
  MODEL_TYPE_MEDICATION,
  MODEL_TYPE_ORGANIZATION_BUNDLE,
  MODEL_TYPE_ORGANIZATION_CLINICIAN,
  MODEL_TYPE_ORGANIZATION_PATIENT,
  MODEL_TYPE_ORGANIZATION_POLICY,
} from "@constants/static/globals";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { parseTimeSince } from "@helpers/parse/time";

const HEADER_NAME_MODEL_TYPE_MAPPER = {
  [MODEL_TYPE_MEDICATION]: "Exported medications",
  [MODEL_TYPE_CLAIM]: "Exported claims",
  [MODEL_TYPE_CONSUMABLE]: "Exported consumables",
  [MODEL_TYPE_ORGANIZATION_BUNDLE]: "Exported codes",
  [MODEL_TYPE_ORGANIZATION_CLINICIAN]: "Exported clinicians",
  [MODEL_TYPE_ORGANIZATION_PATIENT]: "Exported patients",
  [MODEL_TYPE_ORGANIZATION_POLICY]: "Exported policies",
};

export default function ConfigurationsBulkExportGridProps({
  handleClickRow = () => {},
  model_type,
}) {
  return {
    columns: [
      {
        flex: 1,
        field: "name",
        headerName: "Name",
      },
      {
        flex: 1,
        field: "requesting_user__first_name",
        headerName: "Requested by",
        valueGetter: ({ row }) => row?.requesting_user?.full_name,
      },
      {
        flex: 1,
        field: "num_instances",
        headerName:
          HEADER_NAME_MODEL_TYPE_MAPPER[model_type] || "Exported objects",
        valueGetter: ({ value }) => getIntOrFloat(value),
      },
      {
        flex: 1,
        field: "file",
        sortable: false,
        headerName: "File",
        renderCell: ({ value }) => (
          <CustomExternalLink
            href={value}
            label="Download file"
            wrapperProps={{ download: true }}
            isTypography={true}
          />
        ),
      },
      {
        flex: 1,
        field: "status",
        headerName: "Status",
        renderCell: ({ value }) => (
          <CustomStatusTag
            status={value}
            textMapper={EXPORT_STATUS_TEXT_MAPPER}
            propsMapper={EXPORT_STATUS_PROPS_MAPPER}
          />
        ),
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last Updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ],
    disableRowSelectionOnClick: true,
    onRowClick: ({ row }) => handleClickRow(row),
  };
}
