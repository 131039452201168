import React from "react";
import CustomChip from "@components/Custom/CustomChip";
import { CLAIM_MODE_SUBMISSION_MAPPER } from "@constants/static/system-modes";
import { parseTimeSince } from "@helpers/parse/time";
import { selectorAuthClaimMode } from "@redux/selectors/auth";
import pluralize from "pluralize";
import { useSelector } from "react-redux";

export default function OrganizationAgreementTag({
  time_maintenance = null,
  useClaimMode = true,
}) {
  const claimMode = useSelector(selectorAuthClaimMode);
  const claimModeStr = pluralize(CLAIM_MODE_SUBMISSION_MAPPER[claimMode]);

  if (Boolean(time_maintenance))
    return (
      <CustomChip
        title={parseTimeSince({
          prefix: "Disabled",
          value: time_maintenance,
          suffix: "ago",
        })}
        name={useClaimMode ? `${claimModeStr} disabled` : "Disabled"}
        color="lightGrey"
      />
    );

  return (
    <CustomChip
      name={useClaimMode ? `${claimModeStr} enabled` : "Enabled"}
      color="lightBlue"
    />
  );
}
