import React from "react";
import CustomPaymentCardLogo from "@components/Custom/CustomPaymentCardLogo";
import {
  anonymizeCardNumber,
  ORGANIZATION_CARD_STATUS_EXPIRED,
  ORGANIZATION_CARD_STATUS_INVALID,
} from "@constants/organizations/organization-cards";
import { PAYMENT_CARD_TYPE_OTHER } from "@constants/payments/payments";
import { parseMMYY } from "@helpers/parse/time";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ExpiredCardTag, InvalidCardTag } from "./OrganizationCardTags";

export default function OrganizationCardContent({
  object = {},
  name = "",
  subtitle = undefined,
  isElevatedVersion = false,
  wrapperProps = {},
  withTooltip = false,
}) {
  const {
    card_type = PAYMENT_CARD_TYPE_OTHER,
    card_last_4_digits,
    card_expiry_date,
    status,
  } = object || {};

  const finalWrapperProps = {
    ...wrapperProps,
    ...(isElevatedVersion
      ? { className: "outlined-only borderRadius-8", p: 2 }
      : {}),
  };

  const expiryDate = parseMMYY(card_expiry_date);
  const isInvalid = status === ORGANIZATION_CARD_STATUS_INVALID;
  const isCardExpired = status === ORGANIZATION_CARD_STATUS_EXPIRED;
  const finalSubtitle = typeof subtitle === "undefined" ? expiryDate : subtitle;

  return (
    <Stack
      spacing={2}
      direction="row"
      flexGrow={1}
      alignItems="center"
      {...finalWrapperProps}
    >
      <CustomPaymentCardLogo card_type={card_type} />
      <Stack style={{ lineHeight: 1 }} flexGrow={1}>
        <Typography variant="body2" className="ellipsis-1">
          {name ? name : anonymizeCardNumber(card_last_4_digits)}
        </Typography>
        <div>
          {isInvalid ? (
            <InvalidCardTag withTooltip={withTooltip} />
          ) : isCardExpired ? (
            <ExpiredCardTag withTooltip={withTooltip} />
          ) : (
            finalSubtitle && (
              <Typography
                variant="caption"
                color="textSecondary"
                className="ellipsis-1"
              >
                {finalSubtitle}
              </Typography>
            )
          )}
        </div>
      </Stack>
    </Stack>
  );
}
