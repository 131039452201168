import React from "react";
import { customActionDialogBodyPx } from "@components/Custom/CustomActionDialog";
import CustomChip from "@components/Custom/CustomChip";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import pluralize from "pluralize";

export default function OrganizationAffiliationsSelectedOrganizations({
  formData = [],
  divideAt = 2,
  handleRemoveOrganization = () => {},
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleClick = () => setIsOpen(!isOpen);

  const firstList = formData.slice(0, divideAt);
  const hasPrimaryList = firstList.length > 0;
  const secondList = formData.slice(divideAt);
  const secondListLength = secondList.length;
  const hasSecondaryList = secondListLength > 0;
  const expandActionText = isOpen
    ? `Minimize selection`
    : `& ${pluralize("other", secondListLength, true)}`;

  return (
    <Box
      px={customActionDialogBodyPx}
      py={2}
      position="sticky"
      bottom={80}
      zIndex={100}
      className="gradient-to-top"
    >
      {hasPrimaryList ? (
        <CustomLabeledText
          wrapperProps={{ spacing: 1 }}
          label="Partner insurers"
          useChildren={true}
        >
          <div>
            <Grid container spacing={2}>
              {firstList.map((object) => (
                <Grid item key={object.id}>
                  <OrganizationAffiliationsSelectedOrganization
                    object={object}
                    handleRemoveOrganization={handleRemoveOrganization}
                  />
                </Grid>
              ))}
              {hasSecondaryList && (
                <>
                  {isOpen &&
                    secondList.map((object) => (
                      <Grid item key={object.id}>
                        <OrganizationAffiliationsSelectedOrganization
                          object={object}
                          handleRemoveOrganization={handleRemoveOrganization}
                        />
                      </Grid>
                    ))}
                  <Grid item display="grid" alignItems="center">
                    <CustomTypographyButton
                      value={expandActionText}
                      onClick={handleClick}
                      fontWeight="normal"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </CustomLabeledText>
      ) : (
        <Typography variant="caption">
          {"You have not selected any partner insurers."}
        </Typography>
      )}
    </Box>
  );
}

const OrganizationAffiliationsSelectedOrganization = ({
  object,
  handleRemoveOrganization = () => {},
}) => {
  const { id, name, image } = object;
  const handleClick = () => handleRemoveOrganization(id);
  return (
    <CustomChip
      name={name}
      image={image}
      handleClick={handleClick}
      color="white"
      hasCloseIcon={true}
    />
  );
};
