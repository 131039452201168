import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import { CustomSummaryCard } from "@components/Custom/CustomSummaryCards";
import MenuItem from "@mui/material/MenuItem";
import {
  updateOrganizationAgreement,
  updateOrganizationAgreementObjectTSQ,
} from "@redux/actions/organizations/organization-agreements";
import { enqueueSnackbar } from "notistack";

export default function OrganizationAgreementUpdateForm({
  object,
  formatFormData = () => {},
  successMessage = "",
  title = "",
  getSubtitle = () => "",
  FormDataComponent = () => {},
  summaryCardProps = {},
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(object);
  const { processor_organization } = object || {};
  const { name, slug } = processor_organization || {};
  const subtitle = getSubtitle({ name });

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    const formattedFormData = formatFormData(formData);
    updateOrganizationAgreement(formattedFormData)
      .then(({ payload }) => {
        const { instance } = payload;
        enqueueSnackbar({
          variant: "success",
          message: successMessage,
        });
        updateOrganizationAgreementObjectTSQ({
          object: instance,
          processor_organization__slug: slug,
        });
        setIsOpen(false);
      })
      .catch(({ payload }) =>
        enqueueSnackbar({ variant: "error", message: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  React.useEffect(() => {
    isOpen && setFormData(object);
  }, [isOpen, object]);

  return (
    <>
      <CustomSummaryCard
        title={title}
        moreActions={<MenuItem onClick={handleIsOpen}>{"Update"}</MenuItem>}
        {...summaryCardProps}
      />
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title }}
        subtitleComponentProps={{ title: subtitle }}
      >
        <FormDataComponent formData={formData} setFormData={setFormData} />
      </CustomActionDialog>
    </>
  );
}
