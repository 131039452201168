import React from "react";
import CustomTooltip from "@components/Custom/CustomTooltip";
import { DeleteIcon } from "@rimads/assets";
import { AVEY_BLACK } from "@styles/theme";

export default function ClaimDeletedTag({
  time_deleted = null,
  withTooltip = true,
}) {
  if (!Boolean(time_deleted)) return null;

  if (!withTooltip)
    return <DeleteIcon dimension={12} primary_fill={AVEY_BLACK} />;

  return (
    <CustomTooltip
      placement="right"
      title={
        <>
          <b>{"This claim is deleted"}</b>
          <br />
          {"No more changes can be made to this claim."}
        </>
      }
    >
      <div style={{ display: "grid" }}>
        <DeleteIcon dimension={16} primary_fill={AVEY_BLACK} />
      </div>
    </CustomTooltip>
  );
}
