import {
  UPLOAD_ENTRY_STATUS_CREATED,
  UPLOAD_ENTRY_STATUS_IGNORED,
  UPLOAD_ENTRY_STATUS_INVALID,
  UPLOAD_ENTRY_STATUS_PENDING,
  UPLOAD_ENTRY_STATUS_UPDATED,
} from "@constants/static/upload";
import { parseTimeSince } from "@helpers/parse/time";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import ConfigurationsBulkUploadEntryStatus from "./ConfigurationsBulkUploadEntryStatus";
import { renderConfigurationsBulkUploadEntry } from "./ConfigurationsBulkUploadEntryComponents";

const getActions = ({
  row = {},
  use_processing_status,
  handleEditRow = () => {},
  handleIgnoreRow = () => {},
}) => {
  const { preprocessing_status } = row;
  const handleEdit = () => handleEditRow(row);
  const handleIgnore = () => handleIgnoreRow(row);

  if (use_processing_status) return [];

  switch (preprocessing_status) {
    case UPLOAD_ENTRY_STATUS_INVALID:
    case UPLOAD_ENTRY_STATUS_CREATED:
    case UPLOAD_ENTRY_STATUS_UPDATED:
      return [
        <GridActionsCellItem
          showInMenu
          label="Edit entry"
          onClick={handleEdit}
        />,
        <GridActionsCellItem
          showInMenu
          label="Ignore entry"
          onClick={handleIgnore}
        />,
      ];

    case UPLOAD_ENTRY_STATUS_IGNORED:
      return [
        <GridActionsCellItem
          showInMenu
          label="Edit entry"
          onClick={handleEdit}
        />,
      ];

    case UPLOAD_ENTRY_STATUS_PENDING:
    default:
      return [];
  }
};

export default function ConfigurationsBulkUploadEntriesGridProps({
  model_type,
  use_processing_status = false,
  handleEditRow = () => {},
  handleIgnoreRow = () => {},
}) {
  return {
    columns: [
      {
        flex: 2,
        field: "payload_object",
        headerName: "Entry",
        sortable: false,
        renderCell: ({ value }) =>
          renderConfigurationsBulkUploadEntry({
            payload_object: value,
            model_type,
          }),
      },
      {
        flex: 1,
        field: "status",
        headerName: "Status",
        sortable: false,
        renderCell: ({ row }) => (
          <ConfigurationsBulkUploadEntryStatus
            preprocessing_status={row?.preprocessing_status}
            processing_status={row?.processing_status}
          />
        ),
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
      {
        flex: 0.5,
        field: "actions",
        type: "actions",
        getActions: ({ row }) =>
          getActions({
            row,
            use_processing_status,
            handleEditRow,
            handleIgnoreRow,
          }),
      },
    ],
    onRowClick: ({ row }) => handleEditRow(row),
    disableRowSelectionOnClick: true,
  };
}
