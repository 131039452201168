import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { alpha, styled } from "@mui/material/styles";
import { GPSIcon, RadioButtonUnselectedIcon } from "@rimads/assets";
import { AVEY_BLACK, AVEY_GREY } from "@styles/theme";

const checkboxDimension = 14;
const radioDimension = 16;
const checkboxIconDimension = 10;
const checkboxIconScale = 0.7;
const borderRadius = 4;
const borderWidth = 1;
const primaryFill = AVEY_BLACK;
const disabledFill = AVEY_GREY;

const CheckboxBpIcon = styled("span")(({ disabled = false }) => ({
  width: checkboxDimension,
  height: checkboxDimension,
  borderRadius,
  border: `${borderWidth}px solid ${disabled ? disabledFill : primaryFill}`,
}));

const CheckboxCheckedIcon = styled(CheckboxBpIcon)(({ disabled = false }) => ({
  width: checkboxDimension,
  height: checkboxDimension,
  backgroundColor: disabled ? disabledFill : primaryFill,
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  "&:before": {
    transform: `scale(${checkboxIconScale})`,
    width: checkboxIconDimension,
    height: checkboxIconDimension,
    backgroundImage: "url(/assets/svg/checked-icon.svg)",
    content: '""',
  },
  border: `${borderWidth}px solid transparent`,
}));

const CheckboxDashedIcon = styled(CheckboxBpIcon)({
  width: checkboxDimension,
  height: checkboxDimension,
  backgroundColor: alpha(primaryFill, 0.7),
  border: `${borderWidth}px solid transparent`,
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  "&:before": {
    width: checkboxIconDimension,
    height: checkboxIconDimension,
    backgroundImage: "url(/assets/svg/dashed-icon.svg)",
    content: '""',
  },
});

const RadioCheckedIcon = styled(GPSIcon)({
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
});

const RadioDashedIcon = styled(RadioButtonUnselectedIcon)({
  transition: "all 0.3s ease",
  borderRadius: "100%",
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
});

export function CustomCheckbox({
  sx = {},
  style = {},
  disabled = false,
  isRadio = false,
  ...other
}) {
  if (isRadio)
    return (
      <Checkbox
        sx={{ p: 0, ...sx }}
        style={style}
        disableRipple
        checkedIcon={
          <RadioCheckedIcon
            dimension={radioDimension}
            primary_fill={primaryFill}
          />
        }
        icon={
          <RadioDashedIcon
            dimension={radioDimension}
            primary_fill={primaryFill}
          />
        }
        {...other}
      />
    );

  return (
    <Checkbox
      sx={{ p: 0, ...sx }}
      style={style}
      disableRipple
      checkedIcon={<CheckboxCheckedIcon disabled={disabled} />}
      indeterminateIcon={<CheckboxDashedIcon disabled={disabled} />}
      icon={<CheckboxBpIcon disabled={disabled} />}
      {...other}
    />
  );
}

export default function CustomLabeledCheckbox({
  label,
  sx = {},
  disabled = false,
  onChange = () => {},
  isRadio = false,
  ...props
}) {
  const handleChange = (e) => (disabled ? null : onChange(e));
  return (
    <FormControlLabel
      sx={{ m: 0, py: "8px", userSelect: "none", ...sx }}
      control={
        <CustomCheckbox
          disabled={disabled}
          style={{ marginRight: 10 }}
          onChange={handleChange}
          isRadio={isRadio}
          {...props}
        />
      }
      label={label}
      slotProps={{ typography: { variant: "body2" } }}
      disabled={disabled}
    />
  );
}
