import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomBox from "./CustomBox";
import CustomPage from "./CustomLayout/CustomPage";

export default function Custom404({ title = "Oops! Page not found." }) {
  return (
    <CustomPage mt="200px" textAlign="center">
      <Stack spacing={3}>
        <div>
          {["4", "0", "4"].map((digit, index) => (
            <CustomBox
              component="img"
              key={index}
              src={`/assets/images/numbers/${digit}.png`}
              height="100px"
            />
          ))}
        </div>
        <Typography variant="body2">{title}</Typography>
      </Stack>
    </CustomPage>
  );
}
