import React from "react";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { CLAIM_MODE_SUBMISSION_MAPPER } from "@constants/static/system-modes";
import Grid from "@mui/material/Grid";
import { useQueryFetchOrganizationAgreements } from "@redux/actions/organizations/organization-agreements";
import { selectorAuthClaimMode } from "@redux/selectors/auth";
import pluralize from "pluralize";
import { useSelector } from "react-redux";
import OrganizationAffiliationsManagementForm from "../../OrganizationAffiliations/OrganizationAffiliationsManagementForm";
import OrganizationAgreementAction from "../OrganizationAgreement/OrganizationAgreementForms/OrganizationAgreementAction";
import OrganizationAgreementsGridProps from "./OrganizationAgreementsGridProps";

const initialSortModel = [];

export default function OrganizationAgreementsGrid() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [state, setState] = React.useState({
    object: null,
    action: null,
    isOpen: false,
  });
  const claimMode = useSelector(selectorAuthClaimMode);
  const claimModeStr = pluralize(CLAIM_MODE_SUBMISSION_MAPPER[claimMode]);
  const useQueryFnProps = {
    searchQuery,
    filters: { newer_version__isnull: true },
  };
  const dataGridProps = React.useMemo(
    () => OrganizationAgreementsGridProps({ setState, claimModeStr }),
    [setState, claimModeStr]
  );

  return (
    <>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item flexGrow={1}>
          <CustomContextSearchField setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item>
          <OrganizationAffiliationsManagementForm />
        </Grid>
        <Grid item xs={12}>
          <CustomInfiniteDataGrid
            useQueryFn={useQueryFetchOrganizationAgreements}
            useQueryFnProps={useQueryFnProps}
            dataGridProps={dataGridProps}
            initialSortModel={initialSortModel}
          />
        </Grid>
      </Grid>
      <OrganizationAgreementAction state={state} setState={setState} />
    </>
  );
}
